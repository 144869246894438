import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from 'i18next-http-backend';

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "english",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      english: {
        translation: {
          homePage: {
            quoteOne: `"This is game changing! It's important to my family member that he understands that he is heard. Center On Me is giving him the opportunity to put his words onto 
              paper. " - Family member`,
            quoteTwo: `"When people come into my brothers life, they don't know him. It's exhausting to put energy into making sure that people know what an amazing person he is! Center On Me puts 
              all the information people need to get to know him right in front of them. It's not a stale piece of paper that gets put into a file cabinet. It's a living, breathing plan that 
              he can update at any time." - Family Member`,
            quoteThree: `"It's amazing to get onto this platform, where you can interact, add pictures and earn badges. It's fun and vibrant and alive! I felt like I was in a video game, and was very 
              excited to use Center On Me!" - User of Center On Me`,
            quoteFour: `"Center On Me gives people the chance to say 'Check out who I am and the things I like doing!' My family members preferences are clearly outlined and can be updated at anytime.
            We don't have to guess anymore! We can see exactly what he wants and how to support him!" - Family Member`,
            quoteFive: `"I like the fact that I get to be the one who inputs information into Center On Me. I can add my own information, I get to be the one who clicks the buttons and people can hear 
            my voice!" - User of Center On Me`,
            quoteSix: `"I really liked the fact that the other people who support him will get to see what his idea of him is. I can tell people all about him, but he is the best at telling his own 
            story and Center On Me gives him a chance to be able to share his own story." - Staff Support`,
            quoteSeven: `"It warmed my heart to be invited to contribute to a plan on Center On Me. It meant a lot to me that he wanted me to be a part of his story." - Staff Support`,
            quoteEight: `"We live in such a remote location it is hard to get a planner to come to us, this allowed my daughter to create her own plan at the comfort of our home. As a mother I appreciated 
            the freedom it gave her. " - Mother`,
            slideOneTitle: "Welcome to Center on Me",
            slideOneDescription: `Center On Me is a virtual platform for person-centered planning that was created to assist you in planning your ideal life! Center On Me uses a person centered 
              approach to guide you in identifying your goals, the steps needed to achieve them, and the support you want as you reach towards the future YOU want!`,
            slideTwoTitle: "What is Person Centered Planning",
            slideTwoDescription:
              "Person Centered Planning is all about you directing what you want to see in your life. You will create goals focused on achieving your dreams. You will start by highlighting your gifts and talents, what’s currently happening in your life, then creating a path to reach your hopes and dreams. You decide who contributes to you plan.",
            slideTwoButton: "See What You Can Create",
            slideThreeTitle: "How Center on Me Works",
            slideThreeDescription:
              "Center on Me now offers a one-month free trial! Find out more information and see what purchasing options are available to you or to those in your organization.",
            slideThreeButton: "Purchasing Options",
            slideFourTitle: "Resources",
            slideFourDescription:
              "Looking to deepen your understanding of person centered practices? Find upcoming trainings, web-sites, resources and more here!",
            slideFourButton: "Resources",
            slideFiveTitle: "Contact Us",
            slideFiveDescription: "Have any questions? Contact us for any support!",
            slideFiveButton: "Reach Out Now",
          },
          howItWorks: {
            pageTitle: "How Center on Me Works",
            inControl: "YOU'RE IN CONTROL",
            voiceDreamsFuture: "YOUR VOICE, YOUR DREAMS, LET'S CREATE THE FUTURE!",
            onceRegistered: `Once registered, plan owners will have full access to create their own person centered plan! Not only will the plan 
              owner have complete control of what goes into the plan, they will be able to invite whomever they want to contribute 
              to the plan, anytime, anywhere!! Through Center on Me, plan owners will experience interactive, highly visual methods 
              to discover more about themselves and what they want to focus their goals on.`,
            gtkyHeader: "Getting to know you",
            gtkyBody: `We spend time getting to know you and what is important to you! Let's discover what you like to do for fun 
              and what a good day looks like to you!`,
            happeningHeader: "What is happening now?",
            happeningBody: `We spend time learning from you about what your life currently looks like within the areas of Work, 
              School, Health and Living in your Home. Let's discover the now before we begin to plan for your future!`,
            planningHeader: "Time to start planning",
            planningBody: `Your time to start planning for your future. Goals can be created within six different categories 
              including: Fun, Travel, Work, Health, Home and Relationships. Let's discover what the future looks 
              like for you!`,
            finalParagraph: `Once you have completed all three stages of the planning process Center on Me will gather all 
              your information and create a one of a kind Person Centered Plan that you can share with everyone! 
              Not only will the plan show everything you added, it will display all of your approved contributors 
              comments! Share what is important to you with whomever you want whenever you want!`,
            getStartedNow: "Get Started Now",
          },
          resources: {
            pageTitle: "Helpful Resources",
            websites: "Person-Centered Websites",
            articles: "Articles and Publications",
            advocacy: "Advocacy Resources",
            organization: "Organizational Resources",
            software: "Equipment and Software",
            tlcpcpText: "Learning Community for Person-Centered Practice",
            lifeCourseText: "Charting the LifeCourse",
            helenSandersonText: "Helen Sanderson Associates",
            inclusionText: "Inclusion Press",
            pcpTrainingText: "Increasing Person-Centered Thinking: Improving the Quality of Person-Centered Planning",
            ncappsText: "Five Competency Domains for Staff Who Facilitate Person-Centered Planning",
            pcpManualText: "Training for Person-Centered Planning Facilitators",
            scddText: "California State Council on Developmental Disabilities (SCDD)",
            dorText: "California Department of Rehabilitation",
            rcebText: "Regional Center of East Bay",
            cforatText: "The Center For Accessible Technology",
            askJanText: "Job Accommodation Network",
            askEarnText: "Employer Assistance and Resource Network on Disability Inclusion",
            retProjectText: "The Rehabilitation Engineering & Assistive Technology Project",
            transitionTAText: "National Technical Assistance Center on Transition",
            ncappsAssessmentText: "Person-Centered Practices: Self-Assessment",
            dycemText: "Dycem non-slip",
            drcIncText: "Disabled Resource Center - Assistive Technology",
            caReuseText: "California Assistive Technology Reuse Coalition",
            abilityToolsText: "Ability Tools",
          },
          contact: {
            contactUs: "Contact Us",
            message: "Message",
            send: "Send",
            sayHello: "Say Hello",
            saveTitle: "Your email was sent successfully!",
            saveMessage: "A representative from Center On Me will contact you shortly.",
            emailError: "Please enter a valid email address",
            phoneError: "Please enter a valid phone number",
          },
          loginPage: {
            email: "Email Address",
            password: "Password",
            login: "Log In",
            loading: "Loading...",
            forgotPassword: "Forgot Password?",
            errorEmail: "Invalid Email Format",
            errorInvalid: "Invalid Email or Password",
            errorAttempts: "You have exceeded the number of login attempts. Please reset your password",
          },
          registerPage: {
            confirmPassword: "Confirm Password",
            errorTitle: "Whoops!",
            errorAccountExists: "Looks like an account already exists with this email address. Please use another one",
            errorGeneral: "Whoops! Looks like there was an error. Please try again later",
          },
          password: {
            lowercase: "Contains a lowercase letter",
            uppercase: "Contains an uppercase letter",
            eightCharacters: "At least 8 characters long",
            number: "Contains a number",
            specialCharacter: "Contains a special character",
            passwordsMatch: "Passwords match",
            errorCannotMatch: "Old Password and New Password cannot match",
          },
          saveProgress: {
            uploadPhoto: "Uploading image",
            updateProfile: "Updating your profile",
          },
          purchasing: {
            pageTitle: "Membership Information",
            resourcesToThrive: "Resources to Thrive",
            introTextP1: `Center on Me was designed for ALL people to have the resources to THRIVE. Our hope 
              is to provide a low cost resource that promotes Person Centered Practices. Giving people the 
              ability to create and control their own Person Centered Plan.`,
            introTextP2: `Center on Me is an annual membership platform. Once registered, users will have the 
              ability to create as many plans as they would like, edit their plans and enroll as many 
              contributors as desired within the year.`,
            twoWays: "There are two ways you can register for Center On Me",
            singleUserTitle: "Single User Registration",
            singleUserExplanation: `You can try out a limited version of the site with a one-month free trial, or for a one time annual cost you will 
              receive unlimited plan access, endless contributor accounts and access to technical support and resources.`,
            singleUserPrice: "$250 / Annual Membership",
            registerNow: "Register Now",
            vendorService: "Register as a Vendorized Service or Organization",
            vendorServiceExplanation: `If you are a recipient of regional services, you may request your service/support 
              coordinator or organization to purchase a plan for you. Once they have done so, Center on Me will send you 
              a registration code to get started!`,
            seeHowItWorks: "See How It Works",
            testimonialQuote: `We live in such a remote location it is hard to get a planner to come to us, this allowed my 
              daughter to create her own plan at the comfort of our home. As a mother I appreciated the freedom it gave her.`,
            testimonialCredit: "JANICE THOMPSON - Mother",
            faqTitle: "Frequently Asked Questions",
            faqVendorized: "How do I get Center On Me to be a vendorized service?",
            vendorizedAnswer: `If you receive services from a regional center, contact your service/support coordinator and 
              request membership for Center On Me through Mains’l`,
            faqMultiplePlans: "Can an organization or state purchase multiple plans?",
            multiplePlansAnswer: `If your organization is interested in purchasing plans for the people you serve, contact us 
              at centeronme@mainsl.com for rates. Rates are on a tiered system based on organizational size.`,
            faqYearMembership: "What comes with a year's membership?",
            yearMembershipAnswer: `Once you purchase a membership through Center On Me, you will have full access to the site 
              for one year. You can create as many plans as you would like, invite unlimited contributors, and update plans as 
              often as you would like.`,
            faqCreateAccount: "What do I need to create an account?",
            createAccountAnswer: `All you need is an active e-mail address. If you are a recipient of regional center services, 
              talk to your service/support coordinator to set up authorization, and we will e-mail you a direct link to register. 
              If you want to purchase your plan, we will ask for a PayPal account during registration.`,
            faqContributor: "What is a contributor, and how do I add one?",
            contributorAnswer: `A contributor is a person in your life you would like to share and comment on your plan. You have 
              complete control over who you invite to be a part of your plan and what sections of the plan people can view or comment on. 
              Best of all, you review all comments contributors make and decide whether to include the words in your plan! You can add a 
              contributor when you create your relationship map or on the manage contributor page within your profile settings. YOU control 
              what your contributors see and comment on; we will guide you through how to do that when you are on the site.`,
            faqPlan: "What does my plan look like?",
            planAnswer: `Great question! Your person centered plan will highlight what is important to you, for you, your goals, and supports 
              to achieve those goals. You will have two versions of your person centered plan: a web version and a PDF version. Your web-version 
              plan will show you all the information in what we call "cards" similar to what you see on Netflix or Hulu. Your printable and sharable 
              plan will be a PDF you can download and share with your friends. To see a sneak peek of a printable plan, go to our "How does it work" 
              page and scroll to the bottom.`,
            faqOneYear: "What happens after a year or once my membership expires?",
            oneYearAnswer: `Center On Me will send you a reminder that your membership will be expiring 60 days before it expires. You can talk to 
              your service/support coordinator and request to renew your membership. If you do, your information will be carried over. If you forget 
              to renew, we will archive your plan for some time before erasing your account.`,
          },
          agreements: {
            pleaseRead: "Protecting your information is our priority. Please read our",
            privacyTitle: "Website Privacy",
            termsTitle: "Terms of Use",
            ifYouAgree: `If you agree, enter your First and Last Name and click "I Agree"`,
          },
          alerts: {
            expirationTitle: "Expiration Notification",
            goToButton: "Go to Subscription Page",
            expirationMessageDates: "Your plan is set to expire in {{daysUntilExpire}} days on {{expirationDate}}.",
            expirationMessage: "After this date you will no longer have access to your plan(s) unless you choose to renew your subscription.",
            speechToTextNoSpeech: "Sorry, we didn't catch that! Please try again.",
            speechToTextExpired: "Speech to text is currently unavailable. Please save your progress and refresh the page to continue using speech to text.",
          },
          settings: {
            pageTitle: "Settings",
            subTitle: "Your place to customize your plan the way you want it!",
            saveSettings: "Save Settings",
            planVideo: "My Planning Video",
            contributorPage: "Manage Contributors",
            colorPalette: "My Color Palette",
            managePlansContributor: "Go To Plan(s)",
            changePassword: {
              changePassword: "Change Password",
              subTitle: "Let's help you change your password!",
              currentPassword: "Current Password",
              newPassword: "New Password",
              confirmPassword: "Confirm Password",
              currentIncorrect: "Current password is incorrect",
              samePasswordError: "Whoops, please use a new password",
              successMessage: "Password Changed!",
            },
            colorPalettePage: {
              pageTitle: "Color Palette",
              subTitle: "Choose which colors you would like to see when creating your plan!",
              myColorPalette: "My Color Palette",
              select: "Select the color palette that you like",
              brainstormBlue: "Brainstorm Blue",
              planningPurple: "Planning in Purple",
              organizingOrange: "Organizing Orange",
              gamePlanGreen: "Game Plan Green",
              primaryColorTooltip: "Text, Buttons, and Icons",
              secondaryColorTooltip: "Headers, selected buttons, tables",
              contributorColorTooltip: "Contributor entries",
              textColorTooltip: "Text",
              saveMessage: "Your color palette has been updated!",
            },
            manageContributors: {
              sectionHeader: "Manage My Contributors and Notifications",
              subHeader: "Click on a contributor card to set or edit their permission",
              myContributors: "My Contributors",
              dialogConfirm: "Does Everything Look Correct?",
              dialogTitle: "Contributor Information",
              inviteSuccess: "An invitation has been sent to your contributor",
              updateSuccess: "Contributor Information has been updated and an email sent to notify the contributor",
              updateSuccessNoEmail: "Contributor Information has been updated!",
              lastContribution: "Last contribution:",
              noContributions: "No contributions yet",
              addContributor: "Add a Contributor",
              
            },
            contributorNotifications: {
              myNotifications: "My Notifications",
              letsSetPreferece: "Let's Set Your Preferences",
              howToNotify: "When a contributor adds to your plan, how would you like to be notified?",
              howOften: "How often would you like to be notified?",
              saveAlert: "Your notification settings have been updated",
            },
            managementPage: {
              sectionHeader: "My Contributors",
              noContributorsContext:
                "You can give contributors permission to different sections of your plan. They can then make comments and other additions to those sections!",
            },
            profile: {
              pageTitle: "Name and Details",
              sectionHeader: "Welcome to Center on Me",
              subHeader: "Here you can upload a profile picture and tell us about yourself",
              profilePicture: "Profile Picture",
              name: "Name",
              languageAndNumber: "Language & Phone Number",
              address: "Address",
              firstNameError: "First Name is required",
              lastNameError: "Last Name is required",
              phoneError: "Valid phone number is required",
              saveConfirmationMsg: "Does this look correct?",
              backToSettings: "Back to Settings",
              backToPlans: "Back to My Plan(s)",
              progressSavingPicture: "Uploading Profile Picture...",
              progressSavingData: "Saving Profile Information...",
              saveMessage: "Thanks for adding your info!",
              firstTimeSaveMessage: "It's great to get to know you! Now, let's start planning.",
              saveError: "Sorry, there was an error saving your profile",
              notQuite: "Not Quite",
              speechToText: "Speech To Text",
              speechToTextEnabled: "Yes, I want to use speech to text!",
              speechToTextDisabled: "No, I don't want to use speech to text.",
              speechToTextWarning: "Currently only available in English",
              languages: {
                english: "English",
                armenian: "Armenian",
                chineseSimple: "Chinese, Simplified",
                chineseTraditional: "Chinese, Traditional",
                farsi: "Farsi",
                hmong: "Hmong",
                korean: "Korean",
                spanish: "Spanish",
                tagalog: "Tagalog",
                vietnamese: "Vietnamese",
              },
            },
            account: {
              pageTitle: "Account",
              subTitle: "Your place to manage your subscription",
              benefitsTitle: "Paid Subscription Benefits",
              manageTitle: "Manage Subscription",
              benefits: {
                multiplePlans: "Create multiple plans",
                fullAccess: "Full access to plan pages",
                printablePDF: "Printable PDF plan summary",
                uploadPhotos: "Upload photos",
                planVideo: "Create a plan video to share with your contributors",
                backgroundImage: "Choose a custom background image",
                themeColor: "Choose your profile theme color",
              },
              currentPlan: "Current plan",
              trial: "30-day trial",
              subscription: "12-month subscription",
              noSubscription: "No current subscription",
              expirationDate: "Expiration date",
              expiresOn: "Your subscription will expire on",
              trialExpireOn: "Your trial will expire on",
              expiredOn: "Your subscription expired on",
              paymentMethod: "Payment method",
              supportContact: "Support contact",
              supportContactIs: "Your support contact is {{contactName}}",
              editSupportContact: "Edit Support Contact",
              supportContactLabel: "Support Contact",
              contactEmailLabel: "Contact Email Address",
              contactPhoneLabel: "Contact Phone Number",
              saveSupportMessage: "Your support contact has been updated.",
              supportContactError: "Support Contact is required",
              contactEmailError: "Support Contact Email is required",
              contactPhoneError: "Support Contact Phone Number is required",
              subscribeButton:	"Subscribe Now",
              renewButton:	"Renew Subscription",
              requestRenewalButton: "Request Renewal",
              requestRenewal:	"Request to renew your subscription",
              renewDialogTitle:	"Renew Your Subscription",
              subscribeDialogTitle: "Subscribe to Center on Me",
              selectedPlan:	"Selected plan",
              billed:	"Billed",
              payment: "Payment",
              howItWorks:	"How your subscription works",
              // today: "Today: Unlock full access to Center on Me",
              // tenMonths: "10 months: Get reminded when your plan is about to expire",
              // twelveMonths:	"12 months: Your subscription ends",
              billingInformation:	"Billing information",
              billingStartPlanning: "You can start planning as soon as your payment is accepted",
              expirationExplanation: "Upon the expiry of the subscription term, the subscription will automatically terminate without any further notice or action required by either party. There will be no additional charges, and you will no longer have access to the benefits associated with the subscription unless you purchase a new subscription.",
              termsConditions: "Terms & Conditions",
              trialToMemberMessage:	"Welcome to Center on Me! You now have access to all of the benefits of a Center on Me plan.",
              memberRenewalMessage:	"Your plan has been renewed for another 12 months",
              contactEmail: "Contact Email Address",
              contactPhone:	"Contact Phone Number",
              contactSuccessMessage: "Your support contact has been updated",
              paymentSuccessMessage: "Thank you! We hope you enjoy Center on Me",
              today: "Today",
              todayDescription: "Unlock full access to Center on Me",
              tenMonths: "10 Months",
              tenMonthDescription: "Get reminded when your plan is about to expire",
              twelveMonths: "12 Months",
              twelveMonthDescription: "Your subscription ends",
              showPaymentOptions: "Show Payment Options",
            },
            profilePictureDialog: {
              title: "Add Your Profile Picture",
              uploadPhoto: "Upload Photo",
            },
            backgroundImage: {
              pageTitle: "Background Image",
              subTitle: "Select a background image or upload your own",
              hideGrid: "Hide Image Grid",
              showGrid: "Show Image Grid",
              selectImage: "Select a Background Image",
              noImage: "No Image",
              newImageWarning: "Choosing a new image will remove your uploaded image. Do you want to continue?",
              warningConfirm: "Yes, I want a new image",
              warningCancel: "No, keep my image",
              successMessage: "You're background image has been updated",
              uploadError: "Sorry, there was an error updating your background image",
            },
            planVideoPage: {
              sectionHeader: "My Planning Video",
              subHeader: "Create a video to introduce your plan to contributors",
              saveAlertText: "Thank you for your uploading your plan video!",
              explainTextGreeting: "Hello {{firstName}},",
              explainTextP1: `
              This space is for you to upload a video that your contributors will
              see as soon as they log into Center On Me to contribute to your plan. 
              This video is a chance for you to explain why you are creating this 
              plan and give contributors an example of the future you imagine 
              for yourself in creating this plan.`,
              explainTextP2: `
              Record a video on your phone, computer or tablet and upload it 
              here. Your video needs to be less than ten (10) minutes. For tips 
              on how to make your video, please watch the example video or select 
              "Tips for creating my video".`,
              explainTextEnding: "Thank you,",
              explainTextSignature: "Center On Me",
              tipsButton: "Tips for creating my video",
              exampleVideoTitle: "Example Video",
              alvaVideoTitle: "Alva Example",
              uploadButton: "Upload your video",
              removeButton: "Remove My Video",
              videoError: "Your browser does not support the video tag.",
              lengthError: "Error: Maximum Video Length is 10 minutes",
              tips: {
                dialogTitle: "Tips to create your video",
                tipsTitle: "Tips for recording & uploading your video:",
                tipOne: "Record your video in lighting where your entire face can be seen",
                tipTwo: "Think about what you would like to say before hitting record",
                tipThree: "Keep the video under ten (10) minutes",
                tipFour: `If after uploading your video you experience issues viewing it, please email centeronme@mainsl.com 
                  and provide your username (email address) and name of the video file you uploaded (e.g. “Myplanvideo.mov)`,
                examplesTitle: "Example questions to answer in your video:",
                exampleOne: "Why are you inviting people to contribute to your plan?",
                exampleTwo: "What are you hoping to accomplish with your plan?",
                exampleThree: "What do you want to plan for?",
                exampleFour: "How can your contributors support you with creating your plan?",
              },
              contributor: {
                sectionHeader: "Their Planning Video",
                subHeader: "",
                noVideo: "Plan Owner has not added a Plan Video",
              },
            },
            knowledgeCenter: {
              pageTitle: "Knowledge Center",
              subTitle: "Click on a link to learn more about using our site",
              videoTab: "Videos",
              faqTab: "FAQ",
              contactTab: "Contact Us",
              videos: {
                gettingStartedTitle: "Getting Started",
                profileSettings: "Profile Settings",
                landingPageAndBadges: "Landing Page and Badges",
                contributorsTitle: "Contributors",
                addingContributors: "Adding Contributors",
                managingContributors: "Managing Contributors",
                howToContribute: "How to Contribute",
                gettingToKnowYouTitle: "Getting to Know You",
                likeAndAdmire: "Like & Admire",
                people: "People in my Life",
                favorites: "My Favorite Things",
                thingsIDo: "Things I do for Fun",
                routines: "My Routines",
                historyMap: "History Map",
                travelPlan: "Travel",
                happeningNowTitle: "What's Happening Now",
                healthAndSafety: "Health & Safety",
                livingInHome: "Living In My Home",
                work: "Work",
                school: "School",
                startPlanningTitle: "Let's Start Planning",
                workGoals: "Work Goals",
                healthGoals: "Health Goals",
                livingGoals: "Living Goals",
                travelGoals: "Travel Goals",
                funGoals: "Fun Goals",
                relationshipGoals: "Relationship Goals",
                planSummaryTitle: "Plan Summary",
                webVersion: "Web-Version & Exporting to PDF",
                dialogTitle: "Instructional Video",
              },
              faq: {
                pageTitle: "Frequently Asked Questions",
                usingTheKnowledgeCenter: "Using the Knowledge Center",
                passwordAndUsername: "Password and Username",
                managingPlans: "Managing Plans",
                planOwnersAndContributors: "Plan Owners & Contributors",
                usingTheSite: "Using the Site",
                accessingSupport: "Accessing Support",
                registration: "Registration & Subscription",
                howToUse: "How do I use the knowledge center?",
                whoToContact: "Who can I contact if I have questions that are not referenced in the video?",
                videoLanguage: "Can I watch these videos in a language other than English?",
                findVideo: "How can I find the video I am looking for?",
                passwordRequirements: "What are the password requirements?",
                changePassword: "How do I change my password?",
                changeUsername: "What if I want to change my login / email address?",
                howManyPlans: "How many plans can I create once I am registered?",
                sharePlan: "Can I share my plan with anyone? Is there a way to email my plan in the platform? ",
                deletePlan: "What do I do if I accidentally deleted a plan?",
                whatIsPlanOwner: 'Who / what is a "Plan Owner"',
                whatIsContributor: 'Who / what is a "Contributor?"',
                contributorLimit: "Is there a limit to contributors I can add to my plan?",
                approveComment: "If I approve a contributor comment, will they be notified?",
                denyComment: "If I deny a contributor comment, will they be notified?",
                updateProfile: "Can I update my Profile Details at any time?",
                howManyActivities: "How many activities can I add under each section?",
                listSomeoneAsResponsible: "If I list someone as responsible for achieving a goal, will they be notified?",
                editActivity: "How do I edit an activity?",
                deleteActivity: "How do I delete an activity?",
                accessibilityFeatures: "What accessibility features does the Platform offer?",
                forgetToSave: "What happens if I forget to save what I am working on?",
                useOnTablet: "Can I work on my plan using my tablet?",
                useOnPhone: "Can I work on my plan using my phone?",
                phoneApp: "Is there a phone app I need to download?",
                needInternet: "Do I need to have internet access to work on my plan? Is there an offline mode?",
                whatAreBadges: "What are the badges?",
                helpWithPlan: "Who do I contact if I need help with my plan?",
                issuesAccessingPlan: "Who do I contact if I have issues accessing my plan?",
                issueWithPlanVideo: "Who do I contact if I cannot get my Plan Video to upload?",
                forgotUsername: "What if I forget my login username? Who do I contact?",
                wasRegistrationSuccessful: "How will I know my registration was successful?",
                renewPlan: "How will I know I need to renew my plan?",
                howToCancel: "How do I cancel my subscription?",
              },
              faqAnswers: { 
                howToUse: `Click on the section or link you would like to learn more about. You will be taken to a separate  page and you will be able to watch a 
                  video that will walk you through that portion of the site.`,
                whoToContact: `Please reach out to us at any time using the, “Contact Us” tab! In that tab you will find a form to complete that will send us an email. 
                  In your email please include the page and video you have questions about. We will respond to you shortly.`,
                videoLanguage: `For the time being, these videos are only available in English. Closed captioning has been provided for all of the videos.`,
                findVideo: `Please type the page you are looking for into the search bar and a link to the video will pop up. The videos are organized by the name of 
                  the page. If you cannot find a video you are looking for, please reach out to us using the, “Contact Us” tab.`,
                passwordRequirements: `Your password must include: A lowercase letter, an uppercase letter, a number, a special character, and be at least 8 characters.`,
                changePassword: `You can change your password at any time by going to your profile setting and selecting the, “Change Password” button.`,
                changeUsername: `You will not have the ability to change your email address.`,
                howManyPlans: `There is no limit to how many plans you can create. However, the information you enter into plans including plan contributors will not carry 
                  over from one plan to another.`,
                sharePlan: `A plan owner can share their plan with anyone at any time. In the upper right-hand corner of the plan summary page, there is a button 
                  that reads, “Export to PDF”. When that button is clicked, your plan will be downloaded and can be printed or  emailed to any person of your choosing.`,
                deletePlan: `When you try to delete a plan, Center On Me will provide a prompt to make sure that you really want to delete your plan. If you still 
                  elect to delete your plan there is nothing that can be done and you must re-create your plan from the start.`,
                whatIsPlanOwner: `A “Plan Owner” is the focus person of the plan, the person who is creating the plan. The Plan Owner should be the focus of this plan at 
                  all times!`,
                whatIsContributor: `A contributor is a person who a “Plan Owner” has invited to contribute to their plan. The Plan Owner has complete control over which 
                  part of their plan a contributor can view and/or comment on. All contributor comments must be reviewed and approved before being included in a plan.`,
                contributorLimit: `There is no limit to how many contributors you can have. For each plan you create you will need to add each contributor separately.`,
                approveComment: `No, Contributors will not be notified when a comment has been approved.`,
                denyComment: `No, Contributors will not be notified when a comment has been denied.`,
                updateProfile: `You can update your profile details at any time by going to the profile settings. Within the profile settings you will have the 
                  ability to change your Name & Details, your Profile Picture, your background and your color palette.`,
                howManyActivities: `There is no limit to the number of activities or goals you can add under each section.`,
                listSomeoneAsResponsible: `They will not be notified if you list someone as responsible for achieving a goal. If the plan owner wants support with a 
                  goal, they will need to communicate with that person and share their plan if they feel comfortable doing so.`,
                editActivity: `You can edit an activity or goal at any time by clicking on the card. The form will open and any information can be changed or updated.`,
                deleteActivity: `You can delete an activity or goal at any time by clicking on the card. The form will open and the goal can be deleted by 
                  scrolling all the way down to the bottom and selecting the red “Delete” button.`,
                accessibilityFeatures: `Center On Me uses Userway to offer accessibility features within the site. Some of those accessibility features are 
                  changing the contrast, font size, cursor guidance, and text or space alignment. You can choose your features by selecting the round “Person” 
                  icon that exists on every page on the left side.`,
                forgetToSave: `It is very important to hit save as you are creating different pages within the site. The information will not be auto-saved.`,
                useOnTablet: `Yes! Center On Me can be accessed from a Tablet or from a Smart Phone.`,
                useOnPhone: `Yes! Center On Me can be accessed from a Smart Phone. The site has been created to be mobile responsive, so some data may 
                  not be as easily legible as on a tablet or using your computer.`,
                phoneApp: `No, Center On Me does not have an App. You can access Center On Me from your smart phone by opening your web browser app and 
                  going to www.centeronme.com.`,
                needInternet: `Yes, Center On Me requires the internet to be able to work on a plan. There is not an offline mode of Center On Me.`,
                whatAreBadges: `The badges are a way for the user and Center On Me to track your progress within the site. You earn a badge each time you 
                  complete a page, and badges can be viewed on the landing pages. Click on the badges for a fun surprise!`,
                helpWithPlan: `If you need support, please visit the, "Contact Us" tab within the knowledge center. An email will be sent to the Center 
                  On Me team and we will respond shortly.`,
                issuesAccessingPlan: `If you need support, please visit the, “Contact Us” tab within the knowledge center. An email will be sent to the 
                  Center On Me team and we will respond shortly.`,
                issueWithPlanVideo: `First, check the video upload tips to ensure you meet the video requirements. If you still need support, please visit 
                  the, "Contact Us" tab within the knowledge center. An email will be sent to the Center On Me team and we will respond shortly.`,
                forgotUsername: `Your username is your email address. If you need support, please visit the, "Contact Us" tab within the knowledge center. 
                  An email will be sent to the Center On Me team and we will respond shortly.`,
                wasRegistrationSuccessful: `If your payment was successful, you will receive an email receipt to your linked PayPal account. You will 
                  also find a receipt in your PayPal app. See below example of an email receipt for payment.`,
                renewPlan: `Users will receive email reminders at 3 months, 6 months and 10 months to remind a user when your subscription is up and 
                  instructions will be provided on how to renew your plan.`,
                howToCancel: `A subscription is purchased for one year. You cannot cancel the one-year subscription. An email will be sent to you when 
                  it is time to renew your plan and you can choose not to renew your plan at that time.`,
              },
            },
          },
          lockedFeatures: {
            photoLock: "Subscription required to upload photos",
            videoLock: "Subscription required to upload videos",
            pdfLock: "Subscription required to download PDF",
            sectionLock: "Choose a subscription option to access this feature.",
            sectionLockSaveMessage: "This section cannot be modified by trial users",
            createPlanLock: "Choose a subscription option to create multiple plans",
            expiredLock: 'Choose a subscription option to access this feature',
            expiredAlert:  'Your subscription is expired. Please renew to view this section.',
          },
          permissions: {
            theContributor: "The contributor",
            cannotView: "cannot view",
            canView: "can view",
            theSection: "the section",
            toTheSection: "to the section",
            and: "and",
            but: "but",
            cannotComment: "cannot add comments",
            canComment: "can add comments",
            gtkyTitle: "Getting To Know You",
            happeningTitle: "What's Happening In Your Life",
            planningTitle: "Planning",
          },
          breadcrumbs: {
            home: "Home",
            planning: "Let's Start Planning",
            happening: "Happening",
            gettingToKnowYou: "Getting to Know You",
            myRoutines: "My Routines",
            myPeople: "People In My Life",
            likeAndAdmire: "Like & Admire",
            work: "Work",
            school: "School",
            fun: "Fun",
            travel: "Travel",
            health: "Health",
            relationships: "Relationships",
            goal: "My Goal",
            historyMap: "History Map",
            favoriteThings: "My Favorite Things",
            add: "Add New",
            profile: "Profile",
            settings: "Settings",
            contributors: "Contributors & Notifications",
            thingsIDo: "Things I Do For Fun",
            planningVideo: "My Planning Video",
            planSummary: "Plan Summary",
            changePassword: "Change Password",
            knowledgeBase: "Knowledge Center",
            colorPalette: "Color Palette",
            myActivity: "My Activity",
          },
          userMenu: {
            allPlans: "All Plans",
            planDashboard: "Plan Dashboard",
            planSummary: "Plan Summary",
            settings: "Settings",
            subscribe: "Subscribe",
            logout: "Logout"
          },
          timeoutAlert: {
            attention: "Attention",
            description: "You are about to be logged out",
            stillPlanning: "I'm Still Planning!",
            logMeOut: "Log Me Out",
            warningOnLogout: "Unsaved data will be lost",
          },
          allPlans: {
            pageTitle: "A Quick Glance of Your Plans",
            subTitle: "Select one to get started",
            iContributeTo: "Plans I Contribute To",
            myPlans: "My Plans",
            newContributeTo: "Contribute to a new plan",
            createNewPlan: "Create a new plan",
            planOwner: "Plan Owner",
            planCreated: "Plan Created",
            lastViewed: "Last Viewed",
            planVideo: "Plan Video",
            manageContributors: "Manage Contributors & Notifications",
            planSummary: "Plan Summary",
            deletePlan: "Delete Plan",
            createPlanDialog: {
              dialogTitle: "Create a New Plan",
              explainerText: "Choose a name for your new plan to get started",
              inputLabel: "Plan Name",
              createMessage: "Starting a new plan will not carry over any of your work or contributors from previous plans"
            },
            contributeToPlanDialog: {
              dialogTitle: "Contribute to a Plan",
              explainerText: "Enter the code you received from the Plan Owner",
              inputLabel: "Access Code",
              errorMessage: "Access code did not match any plans. Please try again"
            }
          },
          deletePlan: {
            dialogTitle: "Delete My Plan",
            explanation: "You are trying to delete your plan",
            confirmMessage: "Are you sure you want to delete this plan?",
            noteToDownload: "Note: You may want to download and save your plan before deleting it as it cannot be recovered.",
            downloadButton: "Download My Plan",
            keepPlanButton: "Keep My Plan",
            deleteButton: "Delete My Plan",
            deleteSuccessMessage: "Deleted!",
          },
          planLandingPage: {
            videoTitle: "Welcome to your Center on Me Plan",
            gtky: "Getting to Know You",
            whatIsHappening: "What is Happening in Your Life",
            startPlanning: "Start Planning",
            newBadge: "New Badge",
            getStarted: "Get Started",
            leftOff: "Where you left off",
            happeningButton: "Happening",
            planningButton: "Goal Planning",
            recentBadges: "Recent Badges Earned",
            gtkyNoCaps: "Getting to know you",
            happeningShort: "What's happening",
            planning: "Planning",
            noBadgeMessage: "No Badges yet! Start completing the sections to get badges",
            contributorGreeting: "Welcome",
            contributorMessage: `We see that you are joining as a contributor, which means someone thinks you're pretty important! 
              You play an essential role while contributing to this play, we ask that you uphold the purpose of this site and keep 
              the plan owner at the center of their plan while utilizing your time to add rich and meaningful information. When 
              contributing to a plan, we also ask that you take the time to reflect and capture specific information on who the 
              person is, what is important to them, and how you can help them reach their hopes and dreams!`,
            contributorQuote: `"Every single person has capacities, abilities, and gifts. Living a good life depends on whether 
              those capacitates can be used, abilities expressed and gifts given. If they are, the person will be valued, feel 
              powerful and well connected to the people around them, and the community around the person will feel more powerful 
              because of the contributions the person is making."`,
            quote: `"Your goals are the road maps that guide you and show you what is possible for your life." - Les Brown`,
            profileBadges: {
              addContributor: "Badge: Add a Contributor to your plan",
              colorPalette: "Badge: Choose a Color Palette",
              approveContributor: "Badge: Approve a Contribution",
              planningVideo: "Badge: Create a Planning Video",
              profilePicture: "Badge: Upload a Profile Picture",
              backgroundImage: "Badge: Upload a new Wallpaper",
              profileComplete: "Badge: Profile Complete!",
            },
          },
          badges: {
            badgeSectionTitle: "Badges I've Earned",
            badgesPlanningExplanation: `Start earning badges by completing each section! When you're done with a section, your badge will appear here. 
            Let's have some fun while planning for your future!`,
            badgesHappeningExplanation: `Start earning badges by completing each section! When you're done with a section, your badge will appear here. 
            Let's have some fun while learning what is happening in your life!`,
            badgesGTKYExplanation: `Start earning badges by completing each section! When you're done with a section, your badge will appear here. 
            Let's have some fun while getting to know you!`,
          },
          sections: {
            likeAndAdmire: "Like & Admire",
            people: "People In My Life",
            historyMap: "History Map",
            favorites: "Favorite Things",
            travelPlan: "Travel",
            thingsIDo: "Things I Do For Fun",
            dailyRoutines: "Daily Routines",
            weeklyRoutines: "Weekly Routines",
            routines: "My Routines",
            happeningHome: "Happening Home",
            happeningWork: "Happening Work",
            happeningSchool: "Happening School",
            happeningHealth: "Happening Health",
            planningHome: "Planning Home",
            planningWork: "Planning Work",
            planningTravel: "Planning Travel",
            planningFun: "Planning Fun",
            planningHealth: "Planning Health",
            planningRelationships: "Planning Relationships",
          },
          planningPage: {
            pageTitle: "Let's Start Planning",
            planningVideo: "Planning Video",
            housing: "Housing",
            work: "Work",
            health: "Health",
            fun: "Fun",
            relationships: "Relationships",
            travel: "Travel",
            addAnotherGoal: "Add Another Goal"
          },
          planningHealth: {
            sectionName: "Planning Health",
            sectionHeader: "Health Goals",
            subHeader: "Let's create a plan for a healthy future",
            videoTitle: "Health Goals - Helpful Video",
            goalTitle: "What would you like to title this goal?",
            goalAroundHealth: "What is your goal around health?",
            workedOnThisGoal: "Have you ever worked on this goal before?",
            whatWorked: "What worked when you worked on this goal?",
            didntWork: "What didn't work when you worked on this goal?",
            healthTableHeader: "As you think about this goal, what do you think you should...",
            doYouHaveSupport: "Is there anyone in your life that can you support you with this goal?",
            attachedPeopleMessage: "Please select who can support you",
            anyOneElseWhoCanSupport: "Is there anyone else who can help you achieve your goal and why?",
            goalCommit: "What can you commit to doing to help create this future?",
            increase: "Increase",
            decrease: "Decrease",
            doMore: "Do More Of",
            doLess: "Do Less Of",
            increaseEx: "Ex. How much time I focus on myself",
            decreaseEx: "Ex. How much time I spend on Facebook",
            doMoreEx: "Ex. Time I spend on my breathing",
            doLessEx: "Ex. Eating sugary foods",
            saveMessage: "Awesome, good luck on this goal! Would you like to add another health goal?",
            deleteMessage: "Are you sure you want to delete this table row?",
            deleteConfirm: "Ok, the row has been removed",
            saveError: "Sorry, there was an error saving your goal. Please try again later",
            summary: {
              goalTitle: "My goal around health",
              whatWorked: "When I worked on this goal before here is what worked",
              didntWork: "When I worked on this goal before here is what didn't work",
              healthTableHeader: "When I think about my goal, I think I can do the following to help me",
              doYouHaveSupport: "People in my circle of support that can help me with this goal are",
              anyOneElseWhoCanSupport: "Other people that I think could help are",
              goalCommit: "I can commit to",
            },
          },
          planningRelationships: {
            sectionName: "Planning Relationships",
            sectionHeader: "Relationship Goals",
            subHeader: "Let’s start creating connections with people!",
            newOrStrengthen:
              "Do you want to plan for a new relationship or would you like to strengthen a current relationship?",
            saveMessage: "Sounds like a great relationship goal! Would you like to add another goal?",
            newText: "New",
            strengthenText: "Strengthen",
            relationship: "Relationship",
            strengthen: {
              videoTitle: "Strengthen Relationships - Helpful Video",
              goal: "Who do you want to strengthen a relationship with?",
              currentStatus: "What is the current status of this relationship?",
              romantic: "Romantic",
              friend: "Friend",
              professional: "Professional",
              family: "Family",
              whatToStrengthen: "What about this relationship do you want to strengthen?",
              frequency: "Frequency",
              communication: "Communication",
              desiredStatus: "Desired Status",
              howOften: "How often do you currently interact with this person?",
              howToStrengthen: "How do you want to strengthen the frequency",
              strengthenCommunication: "What about communication do you want to strengthen?",
              commExample1: "Ex: I tried calling them more often",
              commExample2: "Ex: I was able to talk to them when I wanted to",
              commExample3: "Ex: I felt heard and connected",
              commExample4: "Ex: They wouldn't answer all the time",
              commTableHeader1: "What have you tried when strengthening your communication?",
              statusTableHeader1: "What have you tried to do to change this status of this relationship?",
              tableHeader2: "What did you learn when you tried that?",
              tableHeader3: "What were you pleased about when you tried that?",
              tableHeader4: "What are you concerned about?",
              commDoNext: "Look at what you just listed, what can you do next?",
              preferredStatus: "What would you like this person's status to be?",
              theyAreCurrently: "They are currently:",
              statusExample1: "Ex: I tried asking them on a date",
              statusExample2: "Ex: That they are very kind and I enjoy talking to them",
              statusExample3: "Ex: They said yes!",
              statusExample4: "Ex: I haven't called them since",
              finalDoNext: "Looking at what you just listed, what can you do next?",
              deleteRowConfirm: "Are you sure you want to remove this table row?",
              deleteRowSuccess: "Ok, the row has been removed",
            },
            new: {
              videoTitle: "Planning New Relationship - Helpful Video",
              relationshipType: "What type of relationship are you looking for?",
              characteristics: "What characteristics are you looking for in someone you want to connect with?",
              where: "Where might you meet this person?",
              placeOfWorship: "Place of Worship",
              family: "Family",
              friend: "Friend",
              professional: "Professional",
              romantic: "Romantic",
              sharedInterests: "Through Shared Interests",
              online: "Online",
              school: "School",
              shops: "Shops",
              work: "Work",
              whenYouThinkAbout: "When you think about creating a new connection",
              atChurch: "at a place of worship",
              throughFriend: "through a friend",
              throughInterests: "through shared interests",
              atOnline: "online",
              atSchool: "at school",
              atLocalShop: "at a local shop",
              atWork: "at work",
              likeToStart: ", where would you like to start?",
              churchStart: "When you think about creating a new connection at a place of worship, where would you like to start?",
              friendStart: "When you think about creating a new connection through a friend, where would you like to start?",
              interestsStart: "When you think about creating a new connection through shared interests, where would you like to start?",
              onlineStart: "When you think about creating a new connection online, where would you like to start?",
              schoolStart: "When you think about creating a new connection at school, where would you like to start?",
              shopsStart: "When you think about creating a new connection at a local shop, where would you like to start?",
              workStart: "When you think about creating a new connection at work, where would you like to start?",
              howOften: "How often do you want to connect in this way?",
            },
            characteristics: {
              organized: "Organized",
              strong: "Strong",
              dreamer: "A Dreamer",
              determined: "Determined",
              smart: "Smart",
              artist: "An Artist",
              considerate: "Considerate",
              helpful: "Helpful",
              creative: "Creative",
              thoughtful: "Thoughtful",
              talented: "Talented",
              outgoing: "Outgoing",
              leader: "A Leader",
              intelligent: "Intelligent",
              loving: "Loving",
              understanding: "Understanding",
              spiritual: "Spiritual",
              joyful: "Joyful",
              fixer: "A Fixer",
              liberal: "Liberal",
              patient: "Patient",
              respectful: "Respectful",
              willing: "Willing",
              intuitive: "Intuitive",
              gentle: "Gentle",
              generous: "Generous",
              spontaneous: "Spontaneous",
              independent: "Independent",
              supportive: "Supportive",
              resourceful: "Resourceful",
              experiential: "Experiential",
              fun: "Fun",
              funny: "Funny",
              excited: "Excited",
              accepting: "Accepting",
              busy: "Busy",
              pretty: "Pretty",
              openMinded: "Open-minded",
              hardWorking: "Hard-working",
              doer: "A Doer",
              involved: "Involved",
              flexible: "Flexible",
              happy: "Happy",
              careful: "Careful",
              hopeful: "Hopeful",
              tinkerer: "A Tinkerer",
              riskTaker: "A Risk-Taker",
              lucky: "Lucky",
              active: "Active",
              efficient: "Efficient",
              visionary: "A Visionary",
              explorer: "An Explorer",
              responsible: "Responsible",
              driven: "Driven",
              confident: "Confident",
              connector: "A Connector",
              wise: "Wise",
              expressive: "Expressive",
              sensitive: "Sensitive",
              talkative: "Talkative",
              energized: "Energized",
              giving: "Giving",
              observant: "Observant",
              caring: "Caring",
              peaceful: "Peaceful",
              cool: "Cool",
              fashionable: "Fashionable",
              actor: "An Actor",
              writer: "A Writer",
              vibrant: "Vibrant",
              goodCook: "A Good Cook",
              goodWithAnimals: "Good With Animals",
            },
            summary: {
              newGoalPdfTitle: "New Relationship",
              strengthenGoalPdfTitle: "Strengthen Relationship",
              relationshipType: "The type of relationship I want to create is",
              characteristics: "I want this person to have the following characteristics",
              where: "I can meet this person",
              whereToStart: "Where I want to start",
              howOften: "How often",
              beingInPerson: "Being in person",
              talkingMore: "Talking more",
              facetiming: "Facetiming",
              otherMeans: "Other means",
              comments: "Comments",
              strengthenGoal: "The person I want to strengthen my relationship with is",
              currentStatus: "The current status of our relationship is",
              whatToStrengthen: "What I want to strengthen",
              strengthenBy: "I want to strengthen by...",
              strengthenWhy: "Why?",
              whyAnswer: "This will help me achieve my goal",
              becoming: "Becoming",
            },
          },
          planningHome: {
            sectionName: "Planning Home",
            sectionHeader: "Living Goals",
            subHeader: "Let’s see what you like or dislike about your current living situation",
            wantToLive: "Where do you want to live?",
            idealHome: "What does your ideal home look like?",
            homeAmenities: "What amenities do you want your home to have?",
            homeCloseTo: "What do you want your home to be close to?",
            liveWith: "Is there someone you would like to live with?",
            roommateName: "Who?",
            roommateQuality: "Why do you think living with this person would be a good fit?",
            askedRoommate: "Have you asked about living together?",
            liveWithPets: "Are there any pets in your future?",
            petType: "What type of animal?",
            finalThoughts: "Is there anything else you would like in your ideal home that we haven't discussed?",
            whatILike: "What I Like",
            whatIDislike: "What I Dislike",
            saveMessage: "Great, thank you for planning for your home! Would you like to add another?",
            homeOptions: {
              singleStory: "Single Story",
              apartment: "Apartment",
              duplex: "Duplex",
              mobileHome: "Mobile Home",
              tinyHome: "Tiny Home",
            },
            amenities: {
              laundry: "Laundry",
              internet: "Wireless Internet",
              recreation: "Recreation Facility",
              garden: "Garden",
              deck: "Deck",
              patio: "Patio",
              land: "Land",
              ramp: "Ramp",
              pool: "Pool",
              bigDoors: "Big Doors",
            },
            proximities: {
              shopping: "Shopping",
              bus: "Bus Stop",
              friends: "Friends",
              restaurants: "Restaurants",
              entertainment: "Entertainment",
              publicTransit: "Public Transportation",
              family: "Family",
            },
            summary: {
              wantToLive: "Where I want to live",
              wantToLiveAnswer: "{{place}} in a {{homeType}}",
              homeAmenities: "In my ideal home I want",
              otherAmenities: "Other things in my ideal home",
              homeCloseTo: "I want my home to be close to",
              roommateName: "I want to live with",
              liveAlone: "I want to live alone",
              roommateBecause: "because {{why}}",
              roommateQuality: "Why do you think living with this person would be a good fit?",
              petType: "Pets",
              finalThoughts: "Other things in my ideal home",
            },
          },
          planningFun: {
            sectionName: "Planning Fun",
            sectionHeader: "Fun Goals",
            subHeader: "Let's start having some fun!",
            goal: "When you think about planning something fun, what do you want to do?",
            goalWhy: "What makes this appealing to you? Or why do you want to do this?",
            goalWhen: "When would you like to do this?",
            goalHowOften: "How often would you like to do this?",
            otherPeople: "Is there anyone you would like to do this with?",
            otherPeopleWhoCanSupport: "Please select who can support you",
            otherPeopleWhereToFind:
              "Please select groups or places that might help you connect to people who have a shared interest",
            needToSave: "Do you need to save money to do this?",
            startedSaving: "Have you started saving?",
            amountToSave: "How much money do you still need to save?",
            attachedPeople: "string",
            saveMessage: "Sounds like fun! Would you like to add another fun goal?",
            asap: "As soon as possible",
            sixMonths: "Next six months",
            oneYear: "Six months to a year",
            twoYears: "1-2 years",
            twoPlusYears: "2+ years",
            oneTime: "One Time Event",
            daily: "Daily",
            weekly: "Weekly",
            monthly: "Monthly",
            quarterly: "Quarterly",
            facebook: "Facebook",
            groupMeet: "Group Meet Up",
            newspaper: "Local Newspaper",
            coffeeShop: "Coffee Shop Bulletin Board",
            communityCenter: "Community Center",
            summary: {
              goal: "When thinking about planning something fun, I want to",
              goalWhy: "This is appealing to me because",
              goalWhen: "I would like to do this",
              goalHowOften: "and as often as",
              otherPeople: "I would like to do this with",
              peopleInMyLife: "The people in my life I want to do this with are",
              actionSteps: "Action Steps:",
              comments: "Comments",
              helpMeAchieve: "This will help me achieve my goal",
              research: "Research ways to plan for this activity through",
              needToSave: "Do I need to save for this trip?",
              needToSaveYes: "Yes, I need to save {{amount}}",
            },
          },
          planningTravel: {
            sectionName: "Planning Travel",
            subHeader: "Let’s start planning your next adventure",
            destination: "Where would you like to travel?",
            travelTo: "Have you traveled to {{location}} before?",
            like: "What did you like about {{location}}",
            appealingWhy: "What makes {{location}} appealing to you?",
            travelWhen: "When do you want to travel to {{location}}?",
            withOthers: "Is there anyone you would like to travel with?",
            travelWithWho: "Please select who you would like to travel with",
            toDoAtDestination: "What do you want to do while you are visiting?",
            toSave: "Do you need to save money to do this?",
            startedToSave: "Have you started saving?",
            amountToSave: "How much money do you still need to save?",
            accommodations: "Do you have any accommodation needs when you travel?",
            needsAndWhy: "Are there things you want or need to travel with? If so, what are they?",
            goal: "What do you want to name this goal?",
            saveMessage: "Adventure awaits! Would you like to add another travel goal?",
            saveError: "Sorry, there was an error creating your goal. Please try again later",
            summary: {
              destination: "I want to travel to",
              appealingWhy: "Why I want to travel here",
              travelWhen: "I want to travel here",
              withOthers: "Do I want to travel with people",
              withOthersYes: "Yes, I want to travel with {{people}}",
              withOthersAlone: "I want to travel alone",
              toDoAtDestination: "While I am visiting I want to",
              accommodations: "Accommodations I will need when traveling include",
              needsAndWhy: "Other things I want to bring when I travel include",
              needToSave: "Do I need to save for this trip?",
              needToSaveYes: "Yes, I need to save {{amount}}",
            },
          },
          planningWork: {
            sectionName: "Planning Work",
            sectionHeader: "Work Goals",
            addSubHeader: "Let's plan for a new future",
            whatILiked: "What I liked",
            whatIDisliked: "What I disliked",
            formSubHeader: "Let’s see what you liked or disliked about your work experience",
            workGoal: "What is your goal for work?",
            profession: "What attracts you to this profession?",
            excites: "What excites you the most about this career?",
            goalLookLike: "When you think about your goal, what does it look like?",
            salary: "How much would you like to make a year?",
            preferredWorkDays: "What are your preferred work days?",
            preferredWorkHours: "What are your preferred work hours?",
            haveNow: "What do you have now that will get you to your future?",
            priorExperience: "What experience will support you getting to your future?",
            haveReference: "Do you know someone in this profession?",
            referenceName: "Who are they?",
            referenceWhere: "Where do they work?",
            inLifeSupport: "Who in your life can support you in this goal?",
            support: "Is there anyone else who can help you achieve your goal?",
            commit: "What can you commit to doing to help create this future?",
            placeholderBudgetNeed: "Insert what you need to budget for",
            placeholderResponsible: "Insert a name",
            placeholderHowOften: "Insert how often it will be done",
            placeholderImportant: "Insert why this is important",
            workPreferences: "My Work Preferences",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
            morning: "Morning",
            afternoon: "Afternoon",
            evening: "Evenings",
            overnight: "Overnight",
            anytime: "Anytime",
            saveMessage: "Sounds like a great work goal! Would you like to add another work goal?",
            budgetTable: {
              title: "What do you need to save money for to help you achieve your goal?",
              actionStep: "Action Step",
              saveFor: "Save money for",
              saveMoneyFor: "What do I need to save money for?",
              responsible: "Who is responsible?",
              howOften: "How often?",
              important: "Why is this important?",
              why: "Why?",
              contact: "Contact",
              me: "Me",
              theyCanSupport: "They can support me in my goal",
              thisWillHelpMe: "This will help me achieve my goal",
              deleteConfirm: "Are you sure you want to remove this budget row?",
              deleteSuccess: "Ok, the row has been removed",
            },
            summary: {
              workGoal: "My goal for work",
              profession: "What attracts me to this profession",
              excites: "What excites me most about this is",
              goalLookLike: "When I think about my goal it looks like",
              haveNow: "What will help me achieve my future",
              preferredWorkDays: "Preferred Work days",
              preferredWorkHours: "Preferred Work hours",
              salary: "Desired salary",
              haveReference: "Do you know someone in this profession?",
              referenceName: "Who are they?",
              referenceWhere: "Where do they work?",
              inLifeSupport: "Who in your life can support you in this goal?",
              support: "Is there anyone else who can help you achieve your goal?",
              commit: "What can you commit to doing to help create this future?",
            },
          },
          GTKYPage: {
            pageTitle: "Getting To Know You",
            GTKYVideo: "Getting To Know You Video",
            likeAdmire: "Like & Admire",
            peopleInMyLife: "People in My Life",
            thingsIDo: "Things I Like To Do For Fun",
            favoriteThings: "My Favorite Things",
            myRoutines: "My Routines",
            historyMap: "History Map",
            travel: "Travel",
            viewComments: "View comments"
          },
          myRoutines: {
            sectionName: "My Routines",
            subHeader: "Tell us all about what your routines look like",
            videoTitle: "My Routines - Helpful Video",
            successMessage: "Thanks! Your routines have been added",
            dailyRoutines: "What are your daily routines?",
            weeklyRoutines: "What are your weekly routines or rituals?",
            time: "Time",
            noTime: "No Time",
            day: "Day",
            noDay: "No Day",
            dailyPlaceholder: "Add something you do each day and when you do it",
            weeklyPlaceholder: "Add something you do each week",
            dayStickyTitle: "My Day",
            weekStickyTitle: "My Week",
            dailyError: "Please enter a time & description",
            weeklyError: "Please enter a day & description",
            deleteMessage: "Are you sure you want to remove this routine?",
            deleteSuccess: "Ok, the routine has been removed",
            contributor: {
              subHeader: "Tell us all about what their routines look like",
              dailyRoutines: "What are their daily routines?",
              weeklyRoutines: "What are their weekly routines or rituals?",
              dailyPlaceholder: "Add something they do each day and when they do it",
              weeklyPlaceholder: "Add something they do each week",
              dayStickyTitle: "{{name}}'s Day",
              weekStickyTitle: "{{name}}'s Week",
            },
            days: {
              mon: "Monday",
              tues: "Tuesday",
              wed: "Wednesday",
              thurs: "Thursday",
              fri: "Friday",
              sat: "Saturday",
              sun: "Sunday"
            }
          },
          thingsIDo: {
            sectionName: "Things I Like To Do For Fun",
            subHeader: "Select your activity or make a new one",
            indoors: "Indoors",
            outdoors: "Outdoors",
            frequencyCard: "I Like Doing This:",
            likeMostCard: "What I like most about this activity:",
            tellUsWhatYouDo: "Tell us all about what you like to do for fun!",
            indoor: "Indoor",
            outdoor: "Outdoor",
            community: "In my community",
            whereToHaveFun: "Where do you like to have fun?",
            yourActivity: "What do you like to do?",
            chosenActivity: "The activity you chose was",
            howOften: "How often do you like to do this?",
            aloneOrWithOthers: "Do you like to do this activity alone or with others?",
            attachedPeople: "Who do you like to do this with?",
            addThingIDo: "Add another activity",
            otherPeopleInvolved: "Is there anyone else you want to do this activity with?",
            anyPhotos: "Do you have any fun pictures of {{activity}} you want to share?",
            like: "What do you like most about {{activity}}?",
            youSelected: "Your activity:",
            saveMessage: "What an adventure! Would you like to add another?",
            saveError: "Sorry, there was an error saving your entry. Please try again later.",
            deleteConfirm: "Are you sure you want to delete this activity?",
            deleteSuccess: "Your activity has successfully been deleted",
            daily: "Daily",
            weekly: "Weekly",
            monthly: "Monthly",
            oncePerYear: "Once a year",
            fewTimes: "Few times a year",
            whenever: "Whenever I can",
            contributorQuestions: {
              frequencyCard: "They Like Doing This:",
              likeMostCard: "What they like most about this activity:",
              tellUsWhatYouDo: "Tell us all about what they like to do for fun!",
              community: "In my community",
              whereToHaveFun: "Where do they like to have fun?",
              yourActivity: "What do they like to do?",
              chosenActivity: "The activity they chose was",
              howOften: "How often do they like to do this?",
              aloneOrWithOthers: "Do they like to do this activity alone or with others?",
              attachedPeople: "Who do they like to do this with?",
              otherPeopleInvolved: "Is there anyone else they want to do this activity with?",
              anyPhotos: "Do they have any fun pictures of {{activity}} you want to share?",
              like: "What do they like most about {{activity}}?",
              youSelected: "Their activity:",
              saveMessage: "",
            },
            activities: {
              reading: "Reading",
              writing: "Writing",
              yoga: "Yoga",
              pilates: "Pilates",
              videoGames: "Video Games",
              cardGames: "Card Games",
              boardGames: "Board Games",
              painting: "Painting",
              instruments: "Instruments",
              drawing: "Drawing",
              art: "Art",
              replicaModels: "Replica Models",
              movies: "Movies",
              tv: "TV",
              movieTheater: "Movie Theater",
              diningOut: "Dining Out",
              yardSales: "Yard Sales",
              antiquing: "Antiquing",
              mall: "Going to the Mall",
              shopping: "Shopping",
              sports: "Sports",
              walks: "Walks",
              birdWatching: "Bird Watching",
              hiking: "Hiking",
              fishing: "Fishing",
              gardening: "Gardening",
              camping: "Camping",
              boating: "Boating",
              botany: "Botany"
            },
            summary: {
              activity: "What I like to do",
              howOften: "I like to do this activity",
              withWho: "I like to do this with",
              withWhoAlone: "I do this",
              byMyself: "by myself",
              like: "What I like most is",
              buttonText: "Go To What I Like To Do For Fun",
            },
          },
          likeAndAdmire: {
            sectionName: "Like and admire",
            sectionTitle: "Head, Heart, Hand",
            subTitle: "Drag and drop your gifts into the category of your choice or create your own",
            videoTitle: "Like and Admire - Helpful Video",
            filterAll: "All things liked & admired (Default)",
            filterOthers: "What others like & admire about me",
            filterMine: "What I like & admire about myself",
            resetAll: "Reset All",
            addNew: "Add new word",
            saveMessage: "Great job {{firstName}}, it was wonderful hearing all the great things about you!",
            head: "Head",
            heart: "Heart",
            hand: "Hand",
            headExplanation: "Gifts of the mind, brain, or thought; those gifts that you know",
            heartExplanation: "Gifts you feel connected to or passionate about",
            handExplanation: "Gifts you can physically provide by action or by making",
            resetMessage: "Are you sure you want to reset all?",
            resetSuccess: "Ok, the items have been reset",
            deleteMessage: "Are you sure you want to delete this gift?",
            deleteSuccess: "Gift was successfully deleted",
            saveError: "Sorry, there was an error saving. Please try again later.",
            contributor: {
              subTitle: "Drag and drop their gifts into the category of your choice or create your own",
              filterOthers: "What others like & admire about them",
              filterMine: "What they like & admire about themselves",
            },
            summary: {
              head: "Head",
              heart: "Heart",
              hand: "Hand",
            },
          },
          peopleInMyLife: {
            sectionName: "People In My Life",
            subHeader: "Add a person or click on the person’s name to add more details about them",
            adaptionDisclaimer: "Adaption of Relationship Map",
            acquaintance: "Acquaintance",
            acquaintances: "Acquaintances",
            add: "Add Person",
            addAsContributor: "Add as a contributor to my plan",
            close: "Close",
            contributor: "Contributor",
            contributorAdditions: "Contributor Additions",
            coWorker: "Co-Worker",
            coWorkers: "Co-Workers",
            deletePerson: "Are you sure you want to remove this person from the People in your life?",
            edit: "Edit Person",
            family: "Family",
            favoritePerson: "Favorite Person of the Month",
            friend: "Friend",
            friends: "Friends",
            howClose: "How close are you to this person?",
            importantWhy: "Why is this person important to you",
            involvedInSupport: "Involved in my support",
            mapPlace: "This will determine their place on your People In My Life Map",
            me: "Me",
            notClose: "Not Close",
            paidSupport: "Is this person paid support?",
            paidSupportShort: "Paid support",
            personIs: "This Person is...",
            relationshipToYou: "Relationship to you",
            removePerson: "Remove Person",
            weAre: "We are...",
            saveMessage: "All the people in your life have been saved, great work.",
            addNewErrorMessage: "Sorry, there was an error adding this person. Please try again later.",
            contributorQuestions: {
              howClose: "How close are they to this person?",
              importantWhy: "Why is this person important to them",
              mapPlace: "This will determine their place on the People In My Life Map",
              relationshipToYou: "Relationship to them",
              theyAre: "They are...",
              involvedInSupport: "Involved in their support",
            },
            dialog: {
              nameError: "Please enter a name",
              personIsError: "Please choose an option",
              emailError: "Must enter an email address for contributors",
              emailValidError: "Please enter a valid email address",
              sendInvitation: "Send Invitation",
            },
          },
          favoriteThings: {
            sectionName: "My Favorite Things",
            subHeader: "Add your favorite things by first selecting a category",
            contributorSubHeader: "Please select a category to start adding their favorite things",
            viewOnlySubHeader: "Take a look at their favorite things",
            addDialogTitle: "Add a Favorite Thing",
            editDialogTitle: "Edit Your Favorite Thing",
            categories: "Categories",
            categoryList: "Category list",
            myFavorites: "My Favorites",
            theirFavorites: "Their Favorites",
            place: "Place",
            placeToEat: "Place to Eat",
            food: "Food",
            movie: "Movie",
            book: "Book",
            videoGame: "Video Game",
            game: "Game",
            sport: "Sport",
            animal: "Animal",
            music: "Music",
            memory: "Memory",
            other: "Other",
            placeLabel: "Your favorite place to go",
            placeToEatLabel: "Your favorite place to eat",
            foodLabel: "Your favorite food",
            movieLabel: "Your favorite movie",
            bookLabel: "Your favorite book",
            videoGameLabel: "Your favorite video game",
            gameLabel: "Your favorite game",
            sportLabel: "Your favorite sport or sports team",
            animalLabel: "Your favorite pet or type of animal ",
            musicLabel: "Your favorite band, song or genre ",
            memoryLabel: "One of your favorite memories ",
            otherLabel: "Any favorite thing",
            chooseAView: "Choose a view",
            allThings: "All Favorite Things (Default)",
            thingsIAdded: "Favorite things I have added",
            thingsOthersAdded: "What others have added",
            treasureChestAlt: "Favorite Things Treasure Chest",
            deleteConfirm: "Are you sure you want to delete this favorite thing?",
            deleteSuccess: "Favorite thing has been deleted",
            saveAlertText: "Thank you {{name}}, it is great learning about your favorite things!",
            saveError: "Sorry, there was an error saving your favorite things. Please try again later.",
            plurals: {
              place: "Places",
              placeToEat: "Places to Eat",
              movie: "Movies",
              book: "Books",
              videoGame: "Video Games",
              game: "Games",
              sport: "Sports",
              animal: "Animals",
              memory: "Memories",
            }
          },
          travelPlan: {
            sectionName: "Travel",
            sectionHeader: "Travel",
            subHeader: "Share your favorite place to travel or somewhere you really want to go",
            travelLocally: "Do you like to travel within the United States or Internationally?",
            local: "Within the United States",
            international: "Internationally",
            frequencyCard: "I like doing this:",
            likeMostCard: "What I like most about this place:",
            pageSubHeader: "Tell us all about where you like to travel",
            travelWhere: "Where do you like to travel?",
            likeMost: "What do you like most about traveling to {{location}}?",
            likeToTravel: "How do you like to travel?",
            alone: "Do you like to do this alone or with others?",
            howOften: "How often do you like to travel here?",
            anyoneElse: "Is there anyone else you would like to travel with?",
            anyPictures: "Do you have any fun pictures of {{location}} that you want to share?",
            deleteMessage: "Are you sure you want to delete this location?",
            addTravelPlan: "Add another place you like to travel",
            addTravelContributor: "Add another place you think they would like to travel",
            attachedPeople: "Who do you like to travel with?",
            boat: "Boat",
            bus: "Bus",
            car: "Car",
            plane: "Plane",
            train: "Train",
            saveText: "What an adventure! Would you like to add another?",
            deleteConfirm: "Are you sure you want to delete this activity?",
            deleteSuccess: "Your activity has successfully been deleted",
            saveError: "Sorry, there was an error saving your entry. Please try again later",
            contributorQuestions: {
              subHeader: "Share their favorite place to travel or somewhere they really want to go",
              frequencyCard: "They like doing this:",
              likeMostCard: "What they like most about this place:",
              pageSubHeader: "Tell us all about where they like to travel",
              travelLocally: "Do they like to travel within the United States or Internationally?",
              travelWhere: "Where do they like to travel?",
              likeMost: "What do they like most about traveling to {{location}}?",
              likeToTravel: "How do they like to travel?",
              alone: "Do they like to do this alone or with others?",
              howOften: "How often do they like to travel here?",
              anyoneElse: "Is there anyone else they would like to travel with?",
              anyPictures: "Do they have any fun pictures of {{location}} that you want to share?",
              attachedPeople: "Who do they like to travel with?",
            },
            summary: {
              place: "I like to travel to",
              travelWithSolo: "I like to travel here",
              byMyself: "By Myself",
              travelWith: "I like to travel here with",
              frequency: "I go there",
              frequencyAnswer: "{{frequency}} and I travel by {{transportation}}",
              favoriteThing: "My favorite thing about traveling to {{place}} is",
              buttonText: "Go To Travel",
            },
          },
          historyMap: {
            sectionHeader: "My History Map",
            subHeader: "Tell us about the important milestones, anniversaries or events in your life",
            addMilestone: "Add Milestone",
            editMilestone: "Edit Milestone",
            sectionName: "History Map",
            category: "Category",
            familyLife: "Family Life",
            school: "School",
            activities: "Activities",
            friends: "Friends",
            housing: "Housing",
            work: "Work",
            relationships: "Relationships",
            chooseDate: "Choose a date",
            milestone: "Milestone",
            details: "Details",
            back: "Back to Getting to Know You",
            saveMessage: "It was great learning all about you, thank you for sharing!",
            validDateError: "Please enter a valid date",
            rangeDateError: "Date is out of range",
            deleteMessage: "Are you sure you want to delete this milestone?",
            deleteSuccess: "Your milestone has successfully been deleted",
          },
          contributor: {
            addToRelationshipMap: "Add to my Relationship Map",
            thisPersonIs: "This Person is a....",
            howClose: "How close are you to this person?",
            close: "Close",
            notClose: "Not Close",
            setPermissions: "Set Contributor Permissions",
            hasBeenEmailed: "An invitation has been sent to your contributor",
          },
          happeningPage: {
            pageTitle: "Happening In My Life",
            living: "Living in My Home",
            work: "Work",
            school: "School",
            health: "Health & Safety",
            happeningVideo: "Happening In My Life Video",
          },
          happeningHome: {
            pageTitle: "Living In My Home",
            subTitle: "Let’s find out more about where you live!",
            singleStory: "Single Story Home",
            multiLevel: "Multi-Level Home",
            apartment: "Apartment",
            facility: "Facility",
            homeless: "Homeless",
            groupHome: "Group Home",
            car: "Car",
            rvCamper: "RV/Camper",
            currentlyLive: "Where do you currently live?",
            currentlyLiveWith: "Who do you currently live with?",
            parents: "Parent(s)",
            guardians: "Guardian(s)",
            siblings: "Sibling(s)",
            friends: "Friend(s)",
            roommates: "Roommate(s)",
            spouse: "Spouse",
            children: "Child/Children",
            alone: "I live alone",
            dogs: "Dog(s)",
            cats: "Cat(s)",
            other: "Other, please explain",
            explain: "Please explain",
            tableTitle: "What do you like and dislike about where you live?",
            whatILike: "What I like about where I currently live",
            whatIDislike: "What I don’t like about where I currently live",
            amenityHeader: "Amenities",
            tellUsWhy: "Please tell us why here",
            selectHeader: "Please select like or dislike",
            commentHeader: "Comments",
            commentsLikes: "Comments about my likes",
            commentsDislikes: "Comments about my dislikes",
            amenities: {
              publicTransportation: "Public Transportation",
              accessibility: "Accessibility",
              neighborhood: "Neighborhood",
              friendsFamily: "Close to Friends and Family",
              shopping: "Close to Shopping",
              furniture: "Furniture",
              homeType: "Type Of Home",
              squareFootage: "Square Footage",
              housemates: "Housemates",
              livingAlone: "Living by myself",
              neighbors: "Neighbors",
              management: "Property Managment",
              support: "Support Staff",
              tellUsWhy: "Please tell us why",
            },
            summary: {
              currentlyLive: "Where I live",
              currentlyLiveWith: "Who I live with",
            },
          },
          happeningSchool: {
            header: "School",
            subHeader: "We can’t wait to hear more about school, tell us about your experience",
            enrolledInSchool: "Are you currently in school?",
            schoolNameOOS: "What school did you go to?",
            gradeLevelOOS: "What grade did you finish?",
            gradYearOOS: "When did you graduate school?",
            schoolLikeOOS: "What did you like most about school?",
            schoolDislikeOOS: "What did you not like about school?",
            volunteerOOS: "While in school did you volunteer anywhere?",
            volunteerWhereOOS: "Where did you volunteer?",
            volunteerDislikeOOS: "What didn’t you like?",
            volunteerLikeOOS: "What did you like about volunteering?",
            graduationGoalOOS: "What was it?",
            gradYearOtherOOS: "",
            goalYesOOS: "Is there something you wanted to do once you graduated?",
            schoolName: "What school do you go to?",
            gradeLevel: "What grade are you in?",
            gradYear: "When are you done with school?",
            schoolLike: "What do you like most about school?",
            schoolDislike: "What do you not like about school?",
            volunteer: "Do you volunteer anywhere?",
            volunteerWhere: "Where do you volunteer?",
            volunteerDislike: "What do  you dislike?",
            volunteerLike: "What do you like about volunteering?",
            graduationGoal: "What is it?",
            gradYearOther: "",
            goalYes: "Is there something you want to do once you graduate?",
            summary: {
              schoolName: "The school I go to is called",
              schoolNameOOS: "The school I went to is called",
              gradeLevel: "I am in grade",
              gradeLevelOOS: "I finished grade",
              gradYear: "I will be done with school in",
              gradYearOOS: "I was done with school in",
              schoolLike: "What I like about school",
              schoolLikeOOS: "What I liked about school",
              schoolDislike: "What I dislike about school",
              schoolDislikeOOS: "What I disliked about school",
              volunteerWhere: "In school I volunteer at",
              volunteerWhereOOS: "In school I volunteered at",
              volunteerLike: "What I like about volunteering",
              volunteerLikeOOS: "What I liked about volunteering",
              volunteerDislike: "What I don't like about volunteering",
              volunteerDislikeOOS: "What I didn't like about volunteering",
              graduationGoal: "When I graduate, I want to",
              graduationGoalOOS: "When I graduated, I wanted to",
            },
          },
          happeningWork: {
            sectionName: "Work Experience",
            subHeader: "Tell us more about your work experience, we can’t wait to hear!",
            pastExperience: "Please share your past work experience",
            pastExperienceLike: "What did you like about your past work experience?",
            pastExperienceDislike: "What did you dislike about your past work experience?",
            hasCurrentEmployment: "Are you currently working?",
            currentEmployment: "Where are you currently working?",
            currentDuration: "How long have you worked there?",
            currentLike: "What do you like about your current work?",
            currentDislike: "What do you dislike about your current work?",
            summary: {
              pastExperience: "Where I've worked in the past",
              pastExperienceLike: "What I like about where I've worked in the past",
              pastExperienceDislike: "What I dislike about where I've worked in the past",
              currentEmployment: "Currently I am working at",
              currentDuration: "I have worked here for",
              currentLike: "What I like about where I work",
              currentDislike: "What I dislike about where I work",
            },
          },
          happeningHealth: {
            header: "Health & Safety",
            subHeader: "Let’s get to know you a little more, please share what you are comfortable with",
            contributorAdditionsToApprove:
              "Your contributors have added DME's and/or Supports to your plan. Would you like to approve them?",
            medInfo: "Is there anything health related that impacts your life?",
            allergies: "Please list allergies you would like us to know about",
            dmeSectionTitle: "What type of Durable Medical Equipment and supports do you use?",
            dmeContributorTitle: "What type of Durable Medical Equipment and supports do they use?",
            sectionSubTitle: "Select a button to add it to your list, click the 'x' in your list to remove it",
            supportsSectionTitle: "How can others support you in feeling and staying healthy?",
            supportContributorTitle: "How can others support them in feeling and staying healthy?",
            dmePostItTitle: "What good supports looks like",
            supportPostItTitle: "How others can support me",
            dmeOptions: {
              bloodSugarMonitors: "Blood sugar monitors",
              bloodSugarTestStrips: "Blood sugar test strips",
              canes: "Canes",
              commodeChairs: "Commode chairs",
              continuousPassiveDevices: "Continuous passive motion devices",
              continuousPassiveAirwayPressureDevices: "Continuous Positive Airway Pressure (CPAP) devices",
              crutches: "Crutches",
              hospitalBeds: "Hospital beds",
              infusionPumps: "Infusion pumps & supplies",
              lancetDevices: "Lancet devices & lancets",
              nebulizerMedications: "Nebulizers & nebulizer medications",
              oxygenEquipment: "Oxygen equipment & accessories",
              patientLifts: "Patient lifts",
              supportSurfaces: "Pressure-reducing support surfaces",
              suctionPumps: "Suction pumps",
              tractionEquipment: "Traction equipment",
              walkers: "Walkers",
              rollator: "Rollator",
              wheelchairMotorized: "Wheelchair - motorized",
              wheelchairManual: "Wheelchair - manual",
              wheelchairStanding: "Wheelchair - Reclining/Standing",
              scooterMotorized: "Scooter - Motorized",
              scooterManual: "Scooter - Manual",
              assistiveTech: "Assistive Technology",
              translator: "Translator",
              brailleKeyboard: "Braille Keyboard",
              hearingAid: "Hearing Aid",
            },
            supportOptions: {
              cookMeals: "Cook my meals",
              prepareMeals: "Help me prepare my meals",
              prepareMedication: "Help me prep my medication",
              medicationSchedule: "Help me create a medication schedule",
              doctorTransportation: "Help me get to and from the doctors",
              createBudget: "Help me create a budget",
              beActive: "Help motivate me to be active",
            },
            summary: {
              medInfo: "The health conditions that I would like others to know about include",
              allergies: "The allergies I would like others to know about include",
              dmeSectionTitle: "The Durable Medical Support that I use include",
              supportsSectionTitle: "The people in my life can support me in the following ways",
            },
          },
          happeningGeneral: {
            sectionSave:
              "Thank you for the information {{userFirstName}}, you can move on to another section of What is Happening in Your Life!",
          },
          planSummary: {
            generatePdf: "Generate PDF",
            downloadPdf: "Download PDF",
            selectTravelPlan: "Select your travel plan",
            selectActivity: "Select your activity",
            selectGoal: "Select a goal",
            sections: {
              importantToMe: "What is Important to Me",
              importantForMe: "What is Important For Me",
              goals: "My Goals",
            },
            cardTitles: {
              likeAdmire: "What People Like and Admire About Me",
              people: "Important People in My Life",
              milestones: "Important Milestones in My Life",
              favoriteThings: "A Few of My Favorite Things",
              thingsIDo: "What I Like to do for Fun",
              travelPlan: "Places I Would Like to Travel",
              dailyRoutines: "My Daily Routines",
              weeklyRoutines: "My Weekly Routines",
              happeningHome: "Living In My Home",
              happeningWork: "My Work Experience",
              happeningSchool: "My School Experience",
              happeningHealth: "My Health & Safety",
              homeGoals: "My Housing Goals",
              workGoals: "My Work Goals",
              healthGoals: "My Health Goals",
              funGoals: "My Fun Goals",
              relationshipGoals: "My Relationship Goals",
              travelGoals: "My Travel Goals",
            },
            cardDescriptions: {
              likeAdmire: "Here are the things I like and admire about myself and what my contributors appreciate about me",
              people: "Here are the most important people in my life.",
              milestones: "Discover all the important milestones in my life that I am most proud of!",
              favoriteThings: "These are just a few of my favorite things",
              thingsIDo: "Here are some of the things I like to do for fun!",
              travelPlan: "Find out where I like to travel, with whom, and what I like to travel with",
              dailyRoutines: "Discover what is important to me each day and what makes a good day!",
              weeklyRoutines: "Find out more about my preferences, rituals, and routines!",
              happeningHome: "It’s important for me to share my current living situation, what I like and dislike about it.",
              happeningWork: "Find out more about my past experiences with work, what I liked and disliked about it!",
              happeningSchool: "Find out a little bit more about me and my experience at school!",
              happeningHealth: "Here is what I feel comfortable sharing about my health, safety, and what others can do to support me.",
              homeGoals: "Find out more about where I want to live",
              workGoals: "Learn about my dream job",
              healthGoals: "Find out how I want to plan for my health",
              funGoals: "Find out what I want to do for fun in the future",
              relationshipGoals: "Learn more about how I want to connect with others",
              travelGoals: "Find out where I hope to travel",
            },
            noContent: {
              milestones: "No Milestones have been added. Click the button below to get started!",
              favoriteThings: "",
              thingsIDo: "No Things I Do for Fun have been created. Click the button below to create one!",
              travelPlan: "No Places to Travel have been created. Click the button below to create one!",
              dailyRoutines: "No Daily Routines have been added. Click the button below to get started!",
              weeklyRoutines: "No Weekly Routines have been added. Click the button below to get started!",
              happeningHome: "No Living in My Home information has been recorded. Click the button below to get started!",
              happeningWork: "No Work Experience information has been recorded. Click the button below to get started!",
              happeningSchool: "No School information has been recorded. Click the button below to get started!",
              happeningHealth: "No Health & Safety information has been recorded. Click the button below to get started!",
              homeGoals: "No Housing goals have been created. Click the button below to create one!",
              workGoals: "No Work goals have been created. Click the button below to create one!",
              healthGoals: "No Health goals have been created. Click the button below to create one!",
              funGoals: "No Fun goals have been created. Click the button below to create one!",
              relationshipGoals: "No Relationship Goals have been created. Click the button below to create one!",
              travelGoals: "No Travel goals have been created. Click the button below to create one!",
            },
          },
          contributorGeneric: {
            entries: "Contributor Entries",
            noAccess: "You do not have access to this section",
            includedByPlanOwner: "Included By Plan Owner",
            notIncluded: "Not included by plan owner",
            sectionNotComplete: "The Plan Owner has not completed this section",
            thankYou: "Thank you for your contribution! Your additions have been sent to the plan owner for approval",
          },
          contributorComments: {
            addComment: "Add a comment",
            allComments: "All Comments",
            commentPrompt:
              "Do you have anything you would like to comment on based on what you see? Leave them a fun comment or note!",
            yourComment: "Your Comment",
            approved: "Approved Comment",
            notIncluded: "Not Included",
            notApprovedMessage: "Your comment was not approved by the plan owner. You can edit your comment and re-submit it, or remove your comment.",
            pendingApproval: "Thank you for your contribution! Your comment has been sent to the plan owner for approval",
            pendingNoThankYou: "Your comment has been sent to the plan owner for approval",
            deleteConfirm: "Are you sure you want to remove this comment?",
            deleteSuccess: "Ok, the comment has been removed",
            comments: "Comments",
            toCheck: "to check",
          },
          durationDropdown: {
            notSelected: "Not Selected",
            zeroMonths: "0 - 6 Months",
            sixMonths: "6 - 12 Months",
            oneYear: "1 - 2 years",
            twoYears: "2+ years",
          },
          navigationButtons: {
            gtky: "Return to Getting to Know You",
            thingsIDo: "Return to Things I Do",
            happening: "Return to Happening In My Life",
            planningFun: "Return to Planning Fun",
            planningHealth: "Return to Planning Health",
            planningHome: "Return to Planning Home",
            planningRelationships: "Return to Planning Relationships",
            planningTravel: "Return to Planning Travel",
            planningWork: "Return to Planning Work",
          },
          generic: {
            add: "Add",
            addAnother: "Add Another",
            addComment: "Add a Comment",
            addNew: "Add New",
            addNewEntry: "Add New Entry",
            addYourOwn: "Add Your Own",
            addedBy: "Added by",
            addressPrimary: "Address 1",
            addressSecondary: "Suite, Apt, Unit #",
            alone: "Alone",
            approve: "Approve",
            back: "Back",
            backToHomeScreen: "Back to Home Screen",
            backToDashboard: "Back to Plan Dashboard",
            cancel: "Cancel",
            cancelled: "Cancelled",
            city: "City",
            clear: "Clear",
            country: "Country",
            comments: "Contributor Comments",
            createdBy: "Created by",
            createdOn: "Created on",
            dailyWithCapital: "Daily",
            date: "Date",
            delete: "Delete",
            deleted: "Deleted",
            deleteGoalConfirm: "Are you sure you want to delete this goal?",
            deleteGoalSuccess: "Your goal has successfully been deleted",
            details: "Details",
            disapprove: "Disapprove",
            dislike: "Dislike",
            dismiss: "Dismiss",
            edit: "Edit",
            editGoal: "Edit Goal",
            email: "Email",
            emailWithCapitial: "E-Mail",
            error: "Error",
            errorSaving: "Sorry, there was an error saving your entry. Please try again later.",
            faceTime: "FaceTime",
            firstName: "First Name",
            addGeneralComment: "Click to add a general comment",
            generalComments: "General Comments",
            goal: "Goal",
            goToItem: "Go To Item",
            goToGoal: "Go To Goal",
            goToSection: "Go To {{section}}",
            inPerson: "In Person",
            instructionalVideo: "Instructional Video",
            language: "Language Preference",
            lastName: "Last Name",
            like: "Like",
            loading: "Loading...",
            middleName: "Middle Name",
            name: "Name",
            newAdditions: "Contributors have made new additions",
            no: "No",
            noNotifications: "No Notifications",
            noPeopleMessage: "Add friends and family to your People In My Life to select them here!",
            noThanks: "No Thank You",
            okay: "Okay",
            other: "Other",
            pleaseExplain: "Please Explain",
            phoneNumber: "Phone Number",
            remove: "Remove",
            removeImage: "Remove Image",
            review: "Review",
            save: "Save",
            saveAndSendUpdates: "Save & Send Updates",
            saveAndSendInvite: "Save & Send Invite",
            saveAnother: "Save & Add Another",
            saveNoUpdates: "Do Not Send Updates",
            search: "Search",
            selectAGoal: "Select a goal",
            state: "State",
            submit: "Submit",
            success: "Success",
            talking: "Talking",
            thankYou: "Thank You",
            title: "Title",
            uploadImage: "Upload a Picture",
            viewComment: "View Comment",
            weeklyWithCapitial: "Weekly",
            with: "with",
            withOthers: "With Others",
            yes: "Yes",
            zip: "Zip Code",
            include: "Include",
            notInclude: "Do Not Include",
            approveAll: "Approve All",
            denyAll: "Deny All",
            removeFromPlan: "Remove From Plan",
            said: "said",
            errors: {
              errorPageTitle: "There has been an error!",
              errorPageSubTitle: "Sorry about that. Please come back later.",
              error404: "404 - Page Not Found",
              saveError: "Sorry, there was an error saving your entry. Please try again later.",
              deleteError: "Sorry, there was an error deleting your entry. Please try again later.",
              createPlanError: "Sorry, there was an error creating your plan. Please try again later.",
            },
          },
        },
      },
      spanish: {
        translation: {
          contact: {
            contactUs: "Contáctenos",
            message: "Mensaje",
            send: 'Enviar',
            sayHello: 'Di hola',
            saveTitle: 'Tu correo electrónico fue enviado con éxito!',
            saveMessage: 'Un representante de Center On Me se comunicará con usted en breve.',
            emailError: 'Por favor, introduce una dirección de correo electrónico válida',
            phoneError: 'Por favor ingrese un número de teléfono válido',
          },
          password: {
            lowercase: "Contiene una letra minúscula",
            uppercase: "Contiene una letra mayúscula",
            eightCharacters: "Contiene una letra mayúscula",
            number: "Contiene un número",
            specialCharacter: "Contiene un carácter especial.",
            passwordsMatch: "Las contraseñas coincide",
            errorCannotMatch: "La contraseña anterior y la contraseña nueva no pueden coincidir",
          },
          saveProgress: {
            uploadPhoto: 'Imagen de carga',
            updateProfile: 'Actualización de su perfil',
          },
          agreements: {
            pleaseRead: "Proteger su información es nuestra prioridad. Por favor, lea nuestro",
            privacyTitle: "Privacidad del sitio web",
            termsTitle: "Términos de Uso",
            ifYouAgree: `Si está de acuerdo, ingrese su nombre y apellido y haga clic en "Acepto"`,
          },
          settings: {
            pageTitle: "Ajustes",
            subTitle: "¡Tu lugar para personalizar tu plan como tú quieras!",
            saveSettings: "Guardar ajustes",
            planVideo: "Mi vídeo de planificación",
            contributorPage: "Administrar colaboradores",
            colorPalette: "Mi paleta de colores",
            changePassword: {
              changePassword: "Cambia la contraseña",
              subTitle: "¡Te ayudamos a cambiar tu contraseña!",
              currentPassword: "Contraseña actual",
              newPassword: "Nueva contraseña",
              confirmPassword: "Confirmar contraseña",
              currentIncorrect: "La contraseña actual es incorrecta",
              samePasswordError: "Ups, por favor usa una nueva contraseña",
              successMessage: "¡Contraseña cambiada!",
            },
            colorPalettePage: {
              pageTitle: "Paleta de colores",
              subTitle: "¡Elige qué colores te gustaría ver al crear tu plan!",
              myColorPalette: "Mi paleta de colores",
              select: "Selecciona la paleta de colores que más te guste",
              brainstormBlue: "Lluvia de Ideas azul",
              planningPurple: "Planificación en Púrpura",
              organizingOrange: "Naranja Organizadora",
              gamePlanGreen: "Plan de Juego Verde",
              primaryColorTooltip: "Texto, botones e iconos",
              secondaryColorTooltip: "Encabezados, botones seleccionados, tablas",
              contributorColorTooltip: "Entradas de colaboradores",
              textColorTooltip: "Texto",
              saveMessage: "¡Tu paleta de colores ha sido actualizada!"
            },
            manageContributors: {
              sectionHeader: "Gestionar Mis Colaboradores y Notificaciones",
              subHeader: "Haga clic en una tarjeta de colaborador para establecer o editar su permiso",
              myContributors: "Mis Colaboradores",
              dialogConfirm: "¿Todo parece correcto?",
              dialogTitle: "Información del colaborador",
              inviteSuccess: "Se ha enviado una invitación a su colaborador",
              updateSuccess:
                "La información del colaborador ha sido actualizada y se envió un correo electrónico para notificar al colaborador",
              updateSuccessNoEmail: "¡La información del colaborador ha sido actualizada!",
              lastContribution: "Última contribución:",
              noContributions: "Sin contribuciones todavía",
              addContributor: "Agregar un colaborador",
            },
            contributorNotifications: {
              myNotifications: "Mis Notificaciones",
              letsSetPreferece: "Vamos a establecer sus preferencias",
              howToNotify: "Cuando un colaborador se agrega a su plan, ¿cómo le gustaría recibir una notificación?",
              howOften: "¿Con qué frecuencia le gustaría ser notificado?",
              saveAlert: "Su configuración de notificaciones ha sido actualizada",
            },
            managementPage: {
              sectionHeader: "Mis Colaboradores",
              noContributorsContext:
                "Puede dar permiso a los contribuyentes para diferentes secciones de su plan. ¡Entonces pueden hacer comentarios y otras adiciones a esas secciones!",
            },
            profile: {
              pageTitle: "Nombre y detalles",
              sectionHeader: "Bienvenido a Centro en Mí",
              subHeader: "Por favor, tómese un momento para contarnos un poco sobre usted",
              profilePicture: 'Foto de perfil',
              name: "Nombre",
              languageAndNumber: "Idioma y número de teléfono",
              address: "Dirección",
              firstNameError: "Se requiere el primer nombre",
              lastNameError: "Se requiere apellido",
              phoneError: "Se requiere un número de teléfono válido",
              saveConfirmationMsg: "¿Esto parece correcto?",
              backToSettings: "Volver a Ajustes",
              backToPlans: "Volver a mis planes",
              saveMessage: "¡Gracias por agregar tu información!",
              firstTimeSaveMessage: '¡Es genial conocerte! Ahora, comencemos a planificar.',
              notQuite: "No exactamente",
              speechToText: 'Dictado a texto',
              speechToTextEnabled: '¡Sí, quiero usar el discurso en el texto!',
              speechToTextDisabled: 'No, no quiero usar el discurso en el texto.',
              speechToTextWarning: 'Actualmente solo disponible en inglés',
              languages: {
                english: "Inglés",
                armenian: "Armenio",
                chineseSimple: "Chino simplificado",
                chineseTraditional: "Chino tradicional",
                farsi: "farsi",
                hmong: "hmong",
                korean: "Coreano",
                spanish: "Español",
                tagalog: "tagalo",
                vietnamese: "Vietnamita",
              },
            },
            account: {
              pageTitle: 'Cuenta',
              subTitle: 'Su lugar para administrar su suscripción',
              benefitsTitle: 'Beneficios de suscripción pagados',
              manageTitle: 'Administrar suscripción',
              benefits: {
                multiplePlans: 'Crear múltiples planes',
                fullAccess: 'Acceso completo a las páginas de plan',
                printablePDF: 'Resumen del plan PDF imprimible',
                uploadPhotos: 'Subir fotos',
                planVideo: 'Cree un video de plan para compartir con sus colaboradores',
                backgroundImage: 'Elija una imagen de fondo personalizada',
                themeColor: 'Elija el color del tema de su perfil',
              },
              currentPlan: 'Plan actual',
              trial: 'prueba de 30 días',
              subscription: 'Suscripción de 12 meses',
              noSubscription: 'Sin suscripción actual',
              expirationDate: 'Fecha de caducidad',
              expiresOn: 'Su suscripción caducará en',
              paymentMethod: 'Método de pago',
              supportContact: 'Soporte de contacto',
              supportContactIs: 'Su contacto de soporte es {{contactName}}',
              editSupportContact: 'Editar contacto de soporte',
              supportContactLabel: 'Soporte de contacto',
              contactEmailLabel: 'Correo electrónico de contacto',
              contactPhoneLabel: 'Teléfono de contacto',
              saveSupportMessage: 'Su contacto de soporte ha sido actualizado.',
              supportContactError: 'Se requiere contacto de soporte',
              contactEmailError: 'Se requiere un correo electrónico de contacto de soporte',
              contactPhoneError: 'Se requiere un número de teléfono de contacto de soporte',
              subscribeButton: 'Suscribir',
              renewButton: 'Renovar la suscripción',
              requestRenewal: 'Solicitud para renovar su suscripción',
              renewDialogTitle: 'Renovar su suscripción',
              selectedPlan: 'Plan seleccionado',
              billed: 'Facturado',
              payment: 'Pago',
              howItWorks: 'Cómo funciona su suscripción',
              billingInformation: 'Información de facturación',
              billingStartPlanning: 'Puede comenzar a planificar tan pronto como se acepte su pago',
              expirationExplanation: 'Al vencimiento del término de suscripción, la suscripción terminará automáticamente sin ninguna notificación o acción más requerida por cualquiera de las partes. No habrá cargos adicionales, y ya no tendrá acceso a los beneficios asociados con la suscripción a menos que compre una nueva suscripción.',
              termsConditions: 'Términos y condiciones',
              trialToMemberMessage: '¡Bienvenido al centro de mí! Ahora tiene acceso a todos los beneficios de un Centro en el Plan de mí.',
              memberRenewalMessage: 'Su plan se ha renovado por otros 12 meses.',
              contactEmail:  'Correo electrónico de contacto',
              contactPhone: 'Teléfono de contacto',
              contactSuccessMessage: 'Su contacto de soporte se ha actualizado',
              subscribeDialogTitle: 'Suscríbete al centro',
              trialExpireOn: 'Su prueba caducará en',
              expiredOn: 'Su suscripción expiró en',
              paymentSuccessMessage: '¡Gracias! Esperamos que disfrutes del centro de mí',
              today: "Hoy",
              todayDescription: "desbloquee el acceso completo al centro de mí",
              tenMonths: "10 meses",
              tenMonthDescription: "Recuerde cuándo su plan está a punto de expirar",
              twelveMonths: "12 meses",
              twelveMonthDescription: "su suscripción termina",
              showPaymentOptions: 'Mostrar opciones de pago',
            },
            profilePictureDialog: {
              title: "Agrega tu foto de perfil",
              uploadPhoto: "Subir foto",
            },
            backgroundImage: {
              pageTitle: 'Imagen de fondo',
              subTitle: 'Seleccione una imagen de fondo o cargue la suya',
              hideGrid: 'Ocultar cuadrícula de imagen',
              showGrid: 'Mostrar cuadrícula de imagen',
              selectImage: "Seleccione una imagen de fondo",
              noImage: "Sin imágen",
              newImageWarning: "Si elige una nueva imagen, se eliminará la imagen cargada. ¿Quieres continuar?",
              warningConfirm: "Sí, quiero una nueva imagen.",
              warningCancel: "No, mantener mi imagen",
              uploadError: "Lo sentimos, hubo un error al actualizar la imagen de fondo",
            },
            planVideoPage: {
              sectionHeader: "Mi vídeo de planificación",
              subHeader: "Cree un video para presentar su plan a los colaboradores",
              saveAlertText: "¡Gracias por subir el video de tu plan!",
              explainTextGreeting: "Hola {{nombre}},",
              explainTextP1: `				
                Este espacio es para que cargues un video que tus colaboradores				
                ver tan pronto como inicien sesión en Center On Me para contribuir a su plan.				
                Este video es una oportunidad para que expliques por qué estás creando este				
                planificar y dar a los contribuyentes un ejemplo del futuro que imaginas				
                usted mismo en la creación de este plan.`,
              explainTextP2: `				
                Grabe un video en su teléfono, computadora o tableta y cárguelo				
                aquí. Su video debe durar menos de diez (10) minutos. Para consejos				
                sobre cómo hacer su video, mire el video de ejemplo o seleccione, "Consejos para crear mi video.`,
              explainTextEnding: "Gracias,",
              explainTextSignature: "Céntrate en mí",
              tipsButton: "Consejos para crear mi video",
              exampleVideoTitle: "Vídeo de ejemplo",
              alvaVideoTitle: "Ejemplo Alba",
              uploadButton: "Sube tu vídeo",
              removeButton: "Eliminar mi vídeo",
              videoError: "Su navegador no soporta la etiqueta de vídeo.",
              lengthError: "Error: la duración máxima del video es de 10 minutos",
              tips: {
                dialogTitle: "Consejos para crear tu video",
                tipsTitle: "Consejos para grabar y subir tu video:",
                tipOne: "Graba tu video con iluminación donde se vea toda tu cara",
                tipTwo: "Piensa en lo que te gustaría decir antes de darle a grabar",
                tipThree: "Mantener el video por debajo de los diez (10) minutos",
                tipFour: `Si después de cargar su video tiene problemas para verlo, envíe un correo electrónico a centeronme@mainsl.com ,
                y proporcione su nombre de usuario (dirección de correo electrónico) y el nombre del archivo de video que cargó (por ejemplo, "Myplanvideo.mov)"`,
                examplesTitle: "Ejemplos de preguntas para responder en tu video:",
                exampleOne: "¿Por qué estás invitando a la gente a contribuir con tu plan?",
                exampleTwo: "¿Qué esperas lograr con tu plan?",
                exampleThree: "¿Qué quieres planear?",
                exampleFour: "¿Cómo pueden sus colaboradores ayudarlo a crear su plan?",
              },
              contributor: {
                sectionHeader: "Su video de planificación",
                subHeader: "",
                noVideo: "El propietario del plan no ha agregado un video del plan",
              },
            },
            knowledgeCenter: {
              pageTitle: 'Centro de Conocimiento',
              subTitle: 'Haga clic en un enlace para obtener más información sobre el uso de nuestro sitio',
              videoTab: 'Vídeos',
              faqTab: 'Preguntas más frecuentes',
              contactTab: 'Contáctenos',
              videos: {
                gettingStartedTitle: 'Empezando',
                profileSettings: 'Configuración de perfil',
                landingPageAndBadges: 'Página de destino e insignias',
                contributorsTitle: 'Colaboradores',
                addingContributors: 'Agregar colaboradores',
                managingContributors: 'Gestión de colaboradores',
                howToContribute: 'Cómo contribuir',
                gettingToKnowYouTitle: 'llegar a conocerte',
                likeAndAdmire: 'Me gusta y admirar',
                people: 'Personas en mi vida',
                favorites: 'Mis cosas favoritas',
                thingsIDo: 'Cosas que hago para divertirme',
                routines: 'mis rutinas',
                historyMap: 'Mapa histórico',
                travelPlan: 'Viaje',
                happeningNowTitle: 'Qué está pasando ahora',
                healthAndSafety: 'Salud y Seguridad',
                livingInHome: 'viviendo en mi casa',
                work: 'Trabajar',
                school: 'Escuela',
                startPlanningTitle: 'Comencemos a planificar',
                workGoals: 'Objetivos de trabajo',
                healthGoals: 'Objetivos de salud',
                livingGoals: 'Metas vivas',
                travelGoals: 'Objetivos de viaje',
                funGoals: 'Metas divertidas',
                relationshipGoals: 'Metas de la relación',
                planSummaryTitle: 'Resumen del plan',
                webVersion: 'Versión web y exportación a PDF',
                dialogTitle: 'Vídeo instructivo',
              },
              faq: {
                pageTitle: 'Preguntas frecuentes',
                usingTheKnowledgeCenter: 'Uso del Centro de conocimiento',
                passwordAndUsername: 'Contraseña y nombre de usuario',
                managingPlans: 'Gestión de planes',
                planOwnersAndContributors: 'Propietarios y contribuyentes del plan',
                usingTheSite: 'Uso del sitio',
                accessingSupport: 'Acceso a soporte',
                registration: 'Registro y suscripción',
                howToUse: '¿Cómo uso el centro de conocimiento?',
                whoToContact: '¿A quién puedo contactar si tengo preguntas que no se mencionan en el video?',
                videoLanguage: '¿Puedo ver estos videos en un idioma que no sea inglés?',
                findVideo: '¿Cómo puedo encontrar el video que estoy buscando?',
                passwordRequirements: '¿Cuáles son los requisitos de la contraseña?',
                changePassword: '¿Como puedo cambiar mi contraseña?',
                changeUsername: '¿Qué pasa si quiero cambiar mi nombre de usuario/dirección de correo electrónico?',
                howManyPlans: '¿Cuántos planes puedo crear una vez registrado?',
                sharePlan: '¿Puedo compartir mi plan con alguien? ¿Hay alguna forma de enviar mi plan por correo electrónico en la plataforma?',
                deletePlan: '¿Qué hago si eliminé un plan por accidente?',
                whatIsPlanOwner: '¿Quién / qué es un "propietario del plan"?',
                whatIsContributor: '¿Quién / qué es un "Contribuidor?"',
                contributorLimit: '¿Hay un límite de contribuyentes que puedo agregar a mi plan?',
                approveComment: 'Si apruebo el comentario de un colaborador, ¿serán notificados?',
                denyComment: 'Si rechazo el comentario de un colaborador, ¿serán notificados?',
                updateProfile: '¿Puedo actualizar los detalles de mi perfil en cualquier momento?',
                howManyActivities: '¿Cuántas actividades puedo agregar en cada sección?',
                listSomeoneAsResponsible: 'Si incluyo a alguien como responsable de lograr una meta, ¿será notificado?',
                editActivity: '¿Cómo edito una actividad?',
                deleteActivity: '¿Cómo elimino una actividad?',
                accessibilityFeatures: '¿Qué características de accesibilidad ofrece la Plataforma?',
                forgetToSave: '¿Qué sucede si olvido guardar lo que estoy trabajando?',
                useOnTablet: '¿Puedo trabajar en mi plan usando mi tableta?',
                useOnPhone: '¿Puedo trabajar en mi plan usando mi teléfono?',
                phoneApp: '¿Hay alguna aplicación de teléfono que deba descargar?',
                needInternet: '¿Necesito tener acceso a Internet para trabajar en mi plan? ¿Hay un modo fuera de línea?',
                whatAreBadges: '¿Qué son las insignias?',
                helpWithPlan: '¿Con quién me comunico si necesito ayuda con mi plan?',
                issuesAccessingPlan: '¿Con quién me comunico si tengo problemas para acceder a mi plan?',
                issueWithPlanVideo: '¿Con quién me comunico si no puedo subir mi video del plan?',
                forgotUsername: '¿Qué sucede si olvido mi nombre de usuario de inicio de sesión? ¿A quién contacto?',
                wasRegistrationSuccessful: '¿Cómo sabré que mi registro fue exitoso?',
                renewPlan: '¿Cómo sabré que necesito renovar mi plan?',
                howToCancel: '¿Cómo cancelo mi suscripción?',
              },
              faqAnswers: {
                howToUse: `Haga clic en la sección o enlace sobre el que desea obtener más información. Será llevado a una página separada y podrá ver un video que lo guiará a través de esa parte del sitio.`,
                whoToContact: `¡Comuníquese con nosotros en cualquier momento usando la pestaña "Contáctenos"! En esa pestaña encontrarás un formulario para completar que nos enviará un correo electrónico. En su correo electrónico, incluya la página y el video sobre los que tiene preguntas. Le responderemos a la brevedad.`,
                videoLanguage: `Por el momento, estos videos solo están disponibles en inglés. Se han proporcionado subtítulos para todos los videos.`,
                findVideo: `Por favor escriba la página que está buscando en la barra de búsqueda y aparecerá un enlace al video. Los videos están organizados por el nombre de la página. Si no puede encontrar el video que está buscando, comuníquese con nosotros mediante la pestaña "Contáctenos".`,
                passwordRequirements: `Tu contraseña debe incluir: Una letra minúscula, una letra mayúscula, un número, un carácter especial y tener al menos 8 caracteres.`,
                changePassword: `Puede cambiar su contraseña en cualquier momento yendo a la configuración de su perfil y seleccionando el botón "Cambiar contraseña".`,
                changeUsername: `No podrá cambiar su dirección de correo electrónico.`,
                howManyPlans: `No hay límite para la cantidad de planes que puede crear. Sin embargo, la información que ingrese en los planes, incluidos los contribuyentes del plan, no se transferirá de un plan a otro.`,
                sharePlan: `El propietario de un plan puede compartir su plan con cualquier persona en cualquier momento. En la esquina superior derecha de la página de resumen del plan, hay un botón que dice "Exportar a PDF". Cuando se hace clic en ese botón, su plan se descargará y podrá imprimirse o enviarse por correo electrónico a cualquier persona que elija.`,
                deletePlan: `Cuando intente eliminar un plan, Center On Me proporcionará un aviso para asegurarse de que realmente desea eliminar su plan. Si aún elige eliminar su plan, no se puede hacer nada y debe volver a crear su plan desde el principio.`,
                whatIsPlanOwner: `Un “Propietario del Plan” es la persona de enfoque del plan, la persona que está creando el plan. ¡El propietario del plan debe ser el centro de atención de este plan en todo momento!`,
                whatIsContributor: `Un contribuyente es una persona a la que un “Propietario del plan” ha invitado a contribuir a su plan. El propietario del plan tiene control total sobre qué parte de su plan puede ver y/o comentar un colaborador. Todos los comentarios de los contribuyentes deben ser revisados y aprobados antes de ser incluidos en un plan.`,
                contributorLimit: `No hay límite para el número de colaboradores que puede tener. Para cada plan que cree, deberá agregar cada colaborador por separado.`,
                approveComment: `No, los Colaboradores no serán notificados cuando se haya aprobado un comentario.`,
                denyComment: `No, no se notificará a los Colaboradores cuando se haya denegado un comentario.`,
                updateProfile: `Puede actualizar los detalles de su perfil en cualquier momento yendo a la configuración del perfil. Dentro de la configuración del perfil, podrá cambiar su nombre y detalles, su imagen de perfil, su fondo y su paleta de colores.`,
                howManyActivities: `No hay límite para la cantidad de actividades u objetivos que puede agregar en cada sección.`,
                listSomeoneAsResponsible: `No serán notificados si enumera a alguien como responsable de lograr una meta. Si el propietario del plan quiere apoyo con un objetivo, deberá comunicarse con esa persona y compartir su plan si se siente cómodo haciéndolo.`,
                editActivity: `Puedes editar una actividad o un objetivo en cualquier momento haciendo clic en la tarjeta. El formulario se abrirá y cualquier información se puede cambiar o actualizar.`,
                deleteActivity: `Puede eliminar una actividad u objetivo en cualquier momento haciendo clic en la tarjeta. Se abrirá el formulario y se puede eliminar el objetivo desplazándose hasta el final y seleccionando el botón rojo "Eliminar".`,
                accessibilityFeatures: `Center On Me utiliza Userway para ofrecer funciones de accesibilidad dentro del sitio. Algunas de esas características de accesibilidad están cambiando el contraste, el tamaño de fuente, la guía del cursor y la alineación del texto o el espacio. Puede elegir sus características seleccionando el ícono redondo de "Persona" que existe en cada página en el lado izquierdo.`,
                forgetToSave: `Es muy importante hacer clic en Guardar ya que está creando diferentes páginas dentro del sitio. La información no se guardará automáticamente.`,
                useOnTablet: `¡Sí! Se puede acceder a Center On Me desde una tableta o desde un teléfono inteligente.`,
                useOnPhone: `¡Sí! Se puede acceder a Center On Me desde un teléfono inteligente. El sitio se ha creado para adaptarse a dispositivos móviles, por lo que es posible que algunos datos no sean tan legibles como en una tableta o usando su computadora.`,
                phoneApp: `No, Center On Me no tiene una aplicación. Puede acceder a Center On Me desde su teléfono inteligente abriendo su aplicación de navegador web e ingresando a www.centeronme.com.`,
                needInternet: `Sí, Center On Me requiere Internet para poder trabajar en un plan. No hay un modo fuera de línea de Center On Me.`,
                whatAreBadges: `Las insignias son una forma para que el usuario y Center On Me realicen un seguimiento de su progreso dentro del sitio. Obtiene una insignia cada vez que completa una página, y las insignias se pueden ver en las páginas de destino. ¡Haz clic en las insignias para recibir una sorpresa divertida!`,
                helpWithPlan: `Si necesita asistencia, visite la pestaña "Contáctenos" en el centro de conocimiento. Se enviará un correo electrónico al equipo de Center On Me y le responderemos en breve.`,
                issuesAccessingPlan: `Si necesita asistencia, visite la pestaña "Contáctenos" en el centro de conocimiento. Se enviará un correo electrónico al equipo de Center On Me y le responderemos en breve.`,
                issueWithPlanVideo: `Primero, consulte los consejos para cargar videos para asegurarse de que cumple con los requisitos de video. Si aún necesita asistencia, visite la pestaña "Contáctenos" dentro del centro de conocimiento. Se enviará un correo electrónico al equipo de Center On Me y le responderemos en breve.`,
                forgotUsername: `Su nombre de usuario es su dirección de correo electrónico. Si necesita ayuda, visite la pestaña "Contáctenos" dentro del centro de conocimiento. Se enviará un correo electrónico al equipo de Center On Me y le responderemos en breve.`,
                wasRegistrationSuccessful: `Si su pago fue exitoso, recibirá un recibo por correo electrónico en su cuenta de PayPal vinculada. También encontrará un recibo en su aplicación de PayPal. Vea a continuación el ejemplo de un recibo de pago por correo electrónico.`,
                renewPlan: `Los usuarios recibirán recordatorios por correo electrónico a los 3 meses, 6 meses y 10 meses para recordarle a un usuario cuándo finaliza su suscripción y se le proporcionarán instrucciones sobre cómo renovar su plan.`,
                howToCancel: `Se compra una suscripción por un año. No puede cancelar la suscripción de un año. Se le enviará un correo electrónico cuando sea el momento de renovar su plan y puede optar por no renovar su plan en ese momento.`,
              },
            },
          },
          lockedFeatures: {
            photoLock: 'Suscripción requerida para cargar fotos',
            videoLock: 'Suscripción requerida para cargar videos',
            pdfLock: 'Suscripción requerida para descargar PDF',
            sectionLock: 'Elija una opción de suscripción para acceder a esta función.',
            sectionLockSaveMessage: 'Esta sección no puede ser modificada por usuarios de prueba',
            createPlanLock: 'Elija una opción de suscripción para crear múltiples planes',
            expiredLock: 'Elija una opción de suscripción para acceder a esta función',
            expiredAlert:  'Su suscripción está caducada. Renovar para ver esta sección.',
          },
          permissions: {
            theContributor: "El colaborador",
            cannotView: "no puede ver",
            canView: "puede ver",
            theSection: "la sección",
            toTheSection: "a la sección",
            and: "y",
            but: "pero",
            cannotComment: "no puede agregar comentarios",
            canComment: "puede agregar comentarios",
            gtkyTitle: "Llegar a Conocerte",
            happeningTitle: "Qué está pasando en tu vida",
            planningTitle: "Planificación",
          },
          breadcrumbs: {
            home: "Página principal",
            planning: "Comencemos a planificar",
            happening: "Sucediendo",
            gettingToKnowYou: "Llegar a conocerte",
            myRoutines: "Mis rutinas",
            myPeople: "Personas en mi vida",
            likeAndAdmire: "Me gusta y admirar",
            work: "Trabajar",
            school: "Escuela",
            fun: "Divertida",
            travel: "Viaje",
            health: "Salud",
            relationships: "Relaciones",
            goal: "Mi meta",
            historyMap: "Mapa histórico",
            favoriteThings: "Mis cosas favoritas",
            add: "Agregar nuevo",
            profile: "Perfil",
            settings: "Ajustes",
            contributors: "Colaboradores y notificaciones",
            thingsIDo: "Cosas que hago para divertirme",
            planningVideo: "Mi vídeo de planificación",
            planSummary: "Resumen del plan",
            changePassword: "Cambia la contraseña",
            knowledgeBase: "Centro de Conocimiento",
            colorPalette: "Paleta de color",
            myActivity: "Mi actividad",
          },
          userMenu: {
            allPlans: "Todos los Planes",
            planDashboard: "Tablero de Planes",
            planSummary: "Resumen del Plan",
            settings: "Ajustes",
            subscribe: "Suscribir",
            logout: "Cerrar Sesión"
          },
          timeoutAlert: {
            attention: "Atención",
            description: "Estás a punto de ser desconectado",
            stillPlanning: "¡Todavía estoy planeando!",
            logMeOut: "Desconéctame",
            warningOnLogout: "Los datos no guardados se perderán",
          },
          allPlans: {
            pageTitle: "Un vistazo rápido a sus planes",
            subTitle: "Seleccione uno para comenzar",
            iContributeTo: "Planes a los que contribuyo",
            myPlans: "Mis planes",
            newContributeTo: "Contribuir a un nuevo plan",
            createNewPlan: "Crear un nuevo plan",
            planOwner: "Propietario del plan",
            planCreated: "Plan creado",
            lastViewed: "Visto por última vez",
            planVideo: "Planificar vídeo",
            manageContributors: "Gestionar colaboradores y notificaciones",
            planSummary: "Resumen del plan",
            deletePlan: "Borrar Plan",
            createPlanDialog: {
              dialogTitle: "Crear un nuevo plan",
              explainerText: "Elija un nombre para su nuevo plan para comenzar",
              inputLabel: "Nombre del plan",
              createMessage: "Comenzar un nuevo plan no transferirá ninguno de sus trabajos o colaboradores de planes anteriores"
            },
            contributeToPlanDialog: {
              dialogTitle: "Contribuir a un plan",
              explainerText: "Ingresa el código que recibiste del Titular del Plan",
              inputLabel: "Código de acceso",
              errorMessage: "El código de acceso no coincidía con ningún plan. Inténtalo de nuevo"
            }

          },
          deletePlan: {
            dialogTitle: "Eliminar mi plan",
            explanation: "Está intentando eliminar su plan.",
            confirmMessage: "¿Está seguro de que desea eliminar este plan?",
            noteToDownload: "Nota: Es posible que desee descargar y guardar su plan antes de eliminarlo, ya que no se puede recuperar.",
            downloadButton: "Descargar Mi Plan",
            keepPlanButton: "Mantener mi plan",
            deleteButton: "Eliminar mi plan",
            deleteSuccessMessage: "¡Eliminado!",
          },
          planLandingPage: {
            videoTitle: "Bienvenido a su Plan Center on Me",
            gtky: "Llegar a Conocerte",
            whatIsHappening: "Qué está pasando en tu vida",
            startPlanning: "Empezar a planificar",
            newBadge: "Nueva insignia",
            getStarted: "Empezar",
            leftOff: "Donde lo dejaste",
            happeningButton: "Sucediendo",
            planningButton: "Planificación de objetivos",
            gtkyNoCaps: "llegar a conocerte",
            happeningShort: "Qué esta pasando",
            planning: "Planificación",
            recentBadges: "Insignias ganadas recientemente",
            noBadgeMessage: "¡No hay insignias todavía! Comienza a completar las secciones para obtener insignias.",
            contributorGreeting: "Bienvenido",
            contributorMessage: `¡Vemos que te estás uniendo como colaborador, lo que significa que alguien piensa 
              que eres muy importante! Usted juega un papel esencial mientras contribuye a este juego, le pedimos 
              que mantenga el propósito de este sitio y mantenga al propietario del plan en el centro de su plan mientras 
              utiliza su tiempo para agregar información rica y significativa. Al contribuir a un plan, también le pedimos 
              que se tome el tiempo para reflexionar y capturar información específica sobre quién es la persona, qué es 
              importante para ella y cómo puede ayudarla a alcanzar sus sueños y esperanzas.`,
            contributorQuote: `"Cada persona tiene capacidades, habilidades y dones. Vivir una buena vida depende de si esas 
              capacidades se pueden usar, las habilidades se expresan y los dones se dan. Si lo son, la persona será valorada, 
              se sentirá poderosa y bien conectada con las personas que la rodean. ellos, y la comunidad alrededor de la 
              persona se sentirá más poderosa debido a las contribuciones que la persona está haciendo".`,
            quote: `"Tus objetivos son los mapas de ruta que te guían y te muestran lo que es posible para tu vida". -Les Brown`,
            profileBadges: {
              addContributor: 'Insignia: Agregar una contribuyente a su plan',
              colorPalette: 'Insignia: elija una paleta de colores',
              approveContributor: 'Insignia: Aprobar una contribución',
              planningVideo: 'Insignia: Crear un video de planificación',
              profilePicture: 'Insignia: Subir una foto de perfil',
              backgroundImage: 'Insignia: Sube un nuevo fondo de pantalla',
              profileComplete: 'Insignia: ¡Perfil completo!',
            },
          },
          badges: {
            badgeSectionTitle: "Insignias que he ganado",
            badgesPlanningExplanation: `¡Empiece a ganar insignias completando cada sección! Cuando haya terminado con una sección, su insignia aparecerá aquí.				
                ¡Vamos a divertirnos mientras planificamos tu futuro!`,
            badgesHappeningExplanation: `¡Empieza a ganar insignias completando cada sección! Cuando haya terminado con una sección, su insignia aparecerá aquí.				
                ¡Divirtámonos mientras aprendemos lo que está sucediendo en tu vida!`,
            badgesGTKYExplanation: `¡Empieza a ganar insignias completando cada sección! Cuando haya terminado con una sección, su insignia aparecerá aquí.				
                ¡Vamos a divertirnos mientras te conocemos!`,
          },
          sections: {
            likeAndAdmire: "Me gusta y admirar",
            people: "Personas en mi vida",
            historyMap: "Hitos",
            favorites: "Cosas favoritas",
            travelPlan: "Viaje",
            thingsIDo: "Cosas que hago para divertirme",
            dailyRoutines: "Rutinas diarias",
            weeklyRoutines: "Rutinas Semanales",
            routines: "Rutinas",
            happeningHome: "Pasando a casa",
            happeningWork: "Obra que sucede",
            happeningSchool: "Escuela de sucesos",
            happeningHealth: "Salud pasando",
            planningHome: "Planificación del hogar",
            planningWork: "Trabajo de planificación",
            planningTravel: "Planificación de viajes",
            planningFun: "Planificación divertida",
            planningHealth: "Planificación de la Salud",
            planningRelationships: "Planificación de relaciones",
          },
          planningPage: {
            pageTitle: "Comencemos a planificar",
            planningVideo: "Vídeo de planificación",
            housing: "Alojamiento",
            work: "Trabajar",
            health: "Salud",
            fun: "Divertida",
            relationships: "Relaciones",
            travel: "Viaje",
            addAnotherGoal: "Agregar otro objetivo",
          },
          planningHealth: {
            sectionName: "Planificación de la Salud",
            sectionHeader: "Objetivos de Salud",
            subHeader: "Creemos un plan para un futuro saludable",
            videoTitle: "Objetivos de salud: video útil",
            goalTitle: "¿Cómo te gustaría titular este gol?",
            goalAroundHealth: "¿Cuál es su objetivo con respecto a la salud?",
            workedOnThisGoal: "¿Alguna vez has trabajado en este objetivo antes?",
            whatWorked: "¿Qué funcionó cuando trabajaste en este objetivo?",
            didntWork: "¿Qué no funcionó cuando trabajaste en este objetivo?",
            healthTableHeader: "Mientras piensas en este objetivo, ¿qué crees que deberías...",
            doYouHaveSupport: "¿Hay alguien en tu vida que pueda apoyarte con este objetivo?",
            attachedPeopleMessage: "Seleccione quién puede apoyarlo",
            anyOneElseWhoCanSupport: "¿Hay alguien más que pueda ayudarte a lograr tu objetivo y por qué?",
            goalCommit: "¿Qué puedes comprometerte a hacer para ayudar a crear este futuro?",
            increase: "Aumentar",
            decrease: "Disminuir",
            doMore: "Haz más de",
            doLess: "Haz menos de",
            increaseEx: "Ej. Cuánto tiempo me concentro en mí mismo",
            decreaseEx: "Ej. Cuánto tiempo paso en Facebook",
            doMoreEx: "Ej. Tiempo que dedico a mi respiración",
            doLessEx: "Ej. Comer alimentos azucarados",
            saveMessage: "¡Impresionante, buena suerte con este objetivo! ¿Le gustaría agregar otro objetivo de salud?",
            deleteMessage: "¿Está seguro de que desea eliminar esta fila de la tabla?",
            deleteConfirm: "Ok, la fila ha sido eliminada",
            summary: {
              goalTitle: "Mi objetivo en torno a la salud",
              whatWorked: "Cuando trabajé en este objetivo antes, esto es lo que funcionó",
              didntWork: "Cuando trabajé en este objetivo antes aquí está lo que no funcionó",
              healthTableHeader: "Cuando pienso en mi objetivo, creo que puedo hacer lo siguiente para ayudarme",
              doYouHaveSupport: "Las personas de mi círculo de apoyo que me pueden ayudar con este objetivo son",
              anyOneElseWhoCanSupport: "Otras personas que creo que podrían ayudar son",
              goalCommit: "Me puedo comprometer a",
            },
          },
          planningRelationships: {
            sectionName: "Planificación de relaciones",
            sectionHeader: "Metas de la relación",
            subHeader: "¡Comencemos a crear conexiones con la gente!",
            newOrStrengthen: "¿Quieres planificar una nueva relación o te gustaría fortalecer una relación actual?",
            saveMessage: "¡Suena como una gran meta de relación! ¿Te gustaría agregar otra meta?",
            newText: "Nuevo",
            strengthenText: "Fortalecer",
            relationship: "Relación",
            strengthen: {
              videoTitle: "Fortalecer las relaciones - Video útil",
              goal: "¿Con quién quieres fortalecer una relación?",
              currentStatus: "¿Cuál es el estado actual de esta relación?",
              romantic: "Romántico",
              friend: "Amigo",
              professional: "Profesional",
              family: "Familia",
              whatToStrengthen: "¿Qué hay de esta relación que quieres fortalecer?",
              frequency: "Frecuencia",
              communication: "Comunicación",
              desiredStatus: "Estado deseado",
              howOften: "¿Con qué frecuencia interactúa actualmente con esta persona?",
              howToStrengthen: "¿Cómo quieres fortalecer la frecuencia?",
              strengthenCommunication: "¿Qué hay de la comunicación que quieres fortalecer?",
              commExample1: "Ej: Traté de llamarlos más a menudo",
              commExample2: "Ej: pude hablar con ellos cuando quise",
              commExample3: "Ej: me sentí escuchada y conectada",
              commExample4: "Ej: No contestaban todo el tiempo",
              commTableHeader1: "¿Qué has intentado al fortalecer tu comunicación?",
              statusTableHeader1: "¿Qué has intentado hacer para cambiar este estado de esta relación?",
              tableHeader2: "¿Qué aprendiste cuando intentaste eso?",
              tableHeader3: "¿Qué te agradó cuando probaste eso?",
              tableHeader4: "¿Qué te preocupa?",
              commDoNext: "Mira lo que acabas de enumerar, ¿qué puedes hacer a continuación?",
              preferredStatus: "¿Cuál te gustaría que fuera el estado de esta persona?",
              theyAreCurrently: "Actualmente son:",
              statusExample1: "Ej: Traté de pedirles una cita",
              statusExample2: "Ej: Que son muy amables y disfruto hablar con ellos?",
              statusExample3: "Ej: ¡Dijeron que sí!",
              statusExample4: "Ej: No los he llamado desde entonces",
              finalDoNext: "Mirando lo que acaba de enumerar, ¿qué puede hacer a continuación?",
              deleteRowConfirm: "¿Está seguro de que desea eliminar esta fila de la tabla?",
              deleteRowSuccess: "Ok, la fila ha sido eliminada",
            },
            new: {
              videoTitle: "Planificación de una nueva relación: video útil",
              relationshipType: "¿Qué tipo de relación buscas?",
              characteristics: "¿Qué características buscas en alguien con quien quieres conectar?",
              where: "¿Dónde podrías conocer a esta persona?",
              placeOfWorship: "Lugar de adoración",
              family: "Familia",
              friend: "Amigo",
              professional: "Profesional",
              romantic: "Romántico",
              sharedInterests: "A través de intereses compartidos",
              online: "En línea",
              school: "Escuela",
              shops: "Tiendas",
              work: "Trabajar",
              whenYouThinkAbout: "Cuando piensas en crear una nueva conexión",
              atChurch: "en un lugar de culto",
              throughFriend: "a través de un amigo",
              throughInterests: "a través de intereses compartidos",
              atOnline: "en línea",
              atSchool: "en la escuela",
              atLocalShop: "en una tienda local",
              atWork: "en el trabajo",
              likeToStart: "¿Por dónde te gustaría empezar?",
              churchStart: "Cuando piensas en crear una nueva conexión en un lugar de culto, ¿por dónde te gustaría empezar?",
              friendStart: "Cuando piensas en crear una nueva conexión a través de un amigo, ¿por dónde te gustaría empezar?",
              interestsStart: "Cuando piensas en crear una nueva conexión a través de intereses compartidos, ¿por dónde te gustaría empezar?",
              onlineStart: "Cuando piensas en crear una nueva conexión en línea, ¿por dónde te gustaría empezar?",
              schoolStart: "Cuando piensas en crear una nueva conexión en la escuela, ¿por dónde te gustaría empezar?",
              shopsStart: "Cuando piensas en crear una nueva conexión en una tienda local, ¿por dónde te gustaría empezar?",
              workStart: "Cuando piensas en crear una nueva conexión en el trabajo, ¿por dónde te gustaría empezar?",
              howOften: "¿Con qué frecuencia desea conectarse de esta manera?",
            },
            characteristics: {
              organized: "Ordenado",
              strong: "Fuerte",
              dreamer: "Un soñador",
              determined: "Determinado",
              smart: "Inteligente",
              artist: "Un artista",
              considerate: "Considerado",
              helpful: "Útil",
              creative: "Creativo",
              thoughtful: "Reflexivo",
              talented: "Talentoso",
              outgoing: "Extrovertido",
              leader: "Un líder",
              intelligent: "Inteligente",
              loving: "Cariñoso",
              understanding: "Comprensión",
              spiritual: "Espiritual",
              joyful: "Alegre",
              fixer: "Un reparador",
              liberal: "Liberal",
              patient: "Paciente",
              respectful: "Respetuoso",
              willing: "Deseoso",
              intuitive: "Intuitivo",
              gentle: "Amable",
              generous: "Generoso",
              spontaneous: "Espontáneo",
              independent: "Independiente",
              supportive: "Apoyo",
              resourceful: "Ingenioso",
              experiential: "Experimental",
              fun: "Divertida",
              funny: "Gracioso",
              excited: "Entusiasmado",
              accepting: "Aceptando",
              busy: "Ocupado",
              pretty: "Bonita",
              openMinded: "Mente abierta",
              hardWorking: "Trabajo duro",
              doer: "Un hacedor",
              involved: "Involucrado",
              flexible: "Flexible",
              happy: "Contento",
              careful: "Cuidadoso",
              hopeful: "Esperanzado",
              tinkerer: "Un chapucero",
              riskTaker: "Un arriesgado",
              lucky: "Afortunado",
              active: "Activo",
              efficient: "Eficiente",
              visionary: "Un visionario",
              explorer: "Un explorador",
              responsible: "Responsable",
              driven: "Impulsado",
              confident: "Confidente",
              connector: "Un conector",
              wise: "Sabio",
              expressive: "Expresivo",
              sensitive: "Sensible",
              talkative: "Hablador",
              energized: "Energizado",
              giving: "Donación",
              observant: "Observante",
              caring: "Cuidando",
              peaceful: "Pacífico",
              cool: "Enfriar",
              fashionable: "De moda",
              actor: "Un actor",
              writer: "Un escritor",
              vibrant: "Vibrante",
              goodCook: "Un buen cocinero",
              goodWithAnimals: "Bueno con los animales",
            },
            summary: {
              newGoalPdfTitle: "Nueva relación",
              strengthenGoalPdfTitle: "Fortalecer la relación",
              relationshipType: "El tipo de relación que quiero crear es",
              characteristics: "¿Quiero que esta persona tenga las siguientes características?",
              where: "Puedo conocer a esta persona",
              whereToStart: "Por donde quiero empezar",
              howOften: "Con qué frecuencia",
              beingInPerson: "Estar en persona",
              talkingMore: "Hablando más",
              facetiming: "Facetiming",
              otherMeans: "Otros medios",
              comments: "Comentarios",
              strengthenGoal: "La persona con la que quiero fortalecer mi relación es",
              currentStatus: "El estado actual de nuestra relación es",
              whatToStrengthen: "Lo que quiero fortalecer",
              strengthenBy: "Quiero fortalecer con...",
              strengthenWhy: "¿Por qué?",
              whyAnswer: "Esto me ayudará a lograr mi objetivo",
              becoming: "Convirtiéndose",
            },
          },
          planningHome: {
            sectionName: "Planificación del hogar",
            sectionHeader: "Metas vivas",
            subHeader: "Veamos qué te gusta o no te gusta de tu situación de vida actual",
            wantToLive: "¿Donde quieres vivir?",
            idealHome: "¿Cómo es tu casa ideal?",
            homeAmenities: "¿Qué comodidades quieres que tenga tu casa?",
            homeCloseTo: "¿De qué quieres que esté cerca tu casa?",
            liveWith: "¿Hay alguien con quien te gustaría vivir?",
            roommateName: "¿Quién?",
            roommateQuality: "¿Por qué crees que vivir con esta persona sería una buena opción?",
            askedRoommate: "¿Has preguntado sobre la convivencia?",
            liveWithPets: "¿Hay alguna mascota en tu futuro?",
            petType: "¿Qué tipo de animal?",
            finalThoughts: "¿Hay algo más que le gustaría en su hogar ideal que no hayamos discutido?",
            whatILike: "Lo que me gusta",
            whatIDislike: "Lo que no me gusta",
            saveMessage: "¡Genial gracias por planificar tu hogar! ¿Te gustaría agregar otro?",
            homeOptions: {
              singleStory: "Historia única",
              apartment: "Departamento",
              duplex: "Dúplex",
              mobileHome: "Casa móvil",
              tinyHome: "Pequeña casa",
            },
            amenities: {
              laundry: "Ropa sucia",
              internet: "Conexión inalámbrica a internet",
              recreation: "Instalación recreativa",
              garden: "Jardín",
              deck: "Plataforma",
              patio: "Patio",
              land: "Tierra",
              ramp: "Rampa",
              pool: "Piscina",
              bigDoors: "Puertas Grandes",
            },
            proximities: {
              shopping: "Compras",
              bus: "Parada de autobús",
              friends: "Amigos",
              restaurants: "Restaurantes",
              entertainment: "Entretenimiento",
              publicTransit: "Transporte público",
              family: "Familia",
            },
            summary: {
              wantToLive: "Donde quiero vivir",
              wantToLiveAnswer: "{{place}} en un {{homeType}}",
              homeAmenities: "En mi hogar ideal quiero",
              otherAmenities: "Otras cosas en mi casa ideal",
              homeCloseTo: "Quiero que mi casa esté cerca",
              roommateName: "Quiero vivir contigo",
              liveAlone: "Quiero vivir solo",
              roommateBecause: "pero {{why}}",
              roommateQuality: "¿Por qué crees que vivir con esta persona sería una buena opción?",
              petType: "Mascotas",
              finalThoughts: "Otras cosas en mi casa ideal",
            },
          },
          planningFun: {
            sectionName: "Planificación divertida",
            sectionHeader: "Metas divertidas",
            subHeader: "¡Empecemos a divertirnos!",
            goal: "Cuando piensas en planear algo divertido, ¿qué quieres hacer?",
            goalWhy: "¿Qué hace que esto te atraiga? ¿O por qué quieres hacer esto?",
            goalWhen: "¿Cuándo te gustaría hacer esto?",
            goalHowOften: "¿Con qué frecuencia le gustaría hacer esto?",
            otherPeople: "¿Hay alguien con quien te gustaría hacer esto?",
            otherPeopleWhoCanSupport: "Seleccione quién puede apoyarlo",
            otherPeopleWhereToFind:
              "Seleccione grupos o lugares que puedan ayudarlo a conectarse con personas que tienen un interés compartido",
            needToSave: "¿Necesitas ahorrar dinero para hacer esto?",
            startedSaving: "¿Has empezado a ahorrar?",
            amountToSave: "¿Cuánto dinero te falta ahorrar?",
            attachedPeople: "cuerda",
            saveMessage: "¡Suena divertido! ¿Te gustaría agregar otro objetivo divertido?",
            asap: "Lo antes posible",
            sixMonths: "Próximos seis meses",
            oneYear: "Seis meses a un año",
            twoYears: "1-2 años",
            twoPlusYears: "2+ años",
            oneTime: "Evento de una vez",
            daily: "Diariamente",
            weekly: "Semanalmente",
            monthly: "Mensual",
            quarterly: "Trimestral",
            facebook: "Facebook",
            groupMeet: "Reunión de grupo",
            newspaper: "Periódico local",
            coffeeShop: "Tablón de anuncios de la cafetería",
            communityCenter: "Centro Comunitario",
            summary: {
              goal: "Cuando pienso en planear algo divertido, quiero",
              goalWhy: "Esto me atrae porque",
              goalWhen: "Me gustaría hacer esto",
              goalHowOften: "y tantas veces como",
              otherPeople: "Me gustaría hacer esto con",
              peopleInMyLife: "Las personas en mi vida con las que quiero hacer esto son",
              actionSteps: "Pasos de acción:",
              comments: "Comentarios",
              helpMeAchieve: "Esto me ayudará a lograr mi objetivo",
              research: "Buscar formas de planificar esta actividad a través de",
              needToSave: "¿Necesito ahorrar para este viaje?",
              needToSaveYes: "Sí, necesito ahorrar {{amount}}",
            },
          },
          planningTravel: {
            sectionName: "Planificación de viajes",
            subHeader: "Empecemos a planificar tu próxima aventura",
            destination: "¿A dónde te gustaría viajar?",
            travelTo: "¿Has viajado a {{location}} antes?",
            like: "¿Qué te gustó de {{location}}",
            appealingWhy: "¿Qué hace que {{location}} te atraiga?",
            travelWhen: "¿Cuándo quieres viajar a {{location}}?",
            withOthers: "¿Hay alguien con quien te gustaría viajar?",
            travelWithWho: "Seleccione con quién le gustaría viajar",
            toDoAtDestination: "¿Qué quieres hacer mientras estás de visita?",
            toSave: "¿Necesitas ahorrar dinero para hacer esto?",
            startedToSave: "¿Has empezado a ahorrar?",
            amountToSave: "¿Cuánto dinero te falta ahorrar?",
            accommodations: "¿Tienes alguna necesidad de alojamiento cuando viajas?",
            needsAndWhy: "¿Hay cosas con las que quieras o necesites viajar? Si es así, ¿cuáles son?",
            goal: "¿Cómo quieres llamar a este gol?",
            saveMessage: "¡La aventura te espera! ¿Te gustaría agregar otro objetivo de viaje?",
            summary: {
              destination: "Quiero viajar a",
              appealingWhy: "Por qué quiero viajar aquí",
              travelWhen: "Quiero viajar aquí",
              withOthers: "¿Quiero viajar con gente?",
              withOthersYes: "Sí, quiero viajar con {{people}}",
              withOthersAlone: "Quiero viajar solo",
              toDoAtDestination: "Mientras estoy de visita quiero",
              accommodations: "Los alojamientos que necesitaré cuando viaje incluyen",
              needsAndWhy: "Otras cosas que quiero llevar cuando viajo incluyen",
              needToSave: "¿Necesito ahorrar para este viaje?",
              needToSaveYes: "Sí, necesito ahorrar {{amount}}",
            },
          },
          planningWork: {
            sectionName: "Planificación del trabajo",
            sectionHeader: "Objetivos de trabajo",
            addSubHeader: "Planeemos un nuevo futuro",
            whatILiked: "Lo que me gustó",
            whatIDisliked: "Lo que no me gustó",
            formSubHeader: "Veamos qué te gustó o no te gustó de tu experiencia laboral",
            workGoal: "¿Cuál es tu objetivo para el trabajo?",
            profession: "¿Qué te atrae de esta profesión?",
            excites: "¿Qué es lo que más te emociona de esta carrera?",
            goalLookLike: "Cuando piensas en tu objetivo, ¿cómo se ve?",
            salary: "¿Cuánto te gustaría ganar al año?",
            preferredWorkDays: "¿Cuáles son tus días de trabajo preferidos?",
            preferredWorkHours: "¿Cuáles son tus horas de trabajo preferidas?",
            haveNow: "¿Qué tienes ahora que te llevará a tu futuro?",
            priorExperience: "¿Qué experiencia te ayudará a llegar a tu futuro?",
            haveReference: "¿Conoces a alguien en esta profesión?",
            referenceName: "¿Quiénes son?",
            referenceWhere: "¿Dónde trabajan?",
            inLifeSupport: "¿Quién en tu vida puede apoyarte en esta meta?",
            support: "¿Hay alguien más que pueda ayudarte a lograr tu objetivo?",
            commit: "¿Qué puedes comprometerte a hacer para ayudar a crear este futuro?",
            placeholderBudgetNeed: "Inserte lo que necesita presupuestar",
            placeholderResponsible: "Insertar un nombre",
            placeholderHowOften: "Insertar con qué frecuencia se realizará",
            placeholderImportant: "Inserte por qué esto es importante",
            workPreferences: "Mis preferencias de trabajo",
            monday: "Lunes",
            tuesday: "Martes",
            wednesday: "Miércoles",
            thursday: "Jueves",
            friday: "Viernes",
            saturday: "Sábado",
            sunday: "Domingo",
            morning: "Mañana",
            afternoon: "Tarde",
            evening: "Tardes",
            overnight: "Durante la noche",
            anytime: "Cualquier momento",
            saveMessage: "¡Suena como un gran objetivo de trabajo! ¿Le gustaría agregar otro objetivo de trabajo?",
            budgetTable: {
              title: "¿Para qué necesitas ahorrar dinero para ayudarte a lograr tu objetivo?",
              actionStep: "Paso de acción",
              saveFor: "Ahorra dinero para",
              saveMoneyFor: "¿Para qué necesito ahorrar dinero?",
              responsible: "¿Quién es responsable?",
              howOften: "¿Con qué frecuencia?",
              important: "¿Porque es esto importante?",
              why: "¿Por qué?",
              contact: "Contacto",
              me: "Yo",
              theyCanSupport: "Me pueden apoyar en mi objetivo",
              thisWillHelpMe: "Esto me ayudará a lograr mi objetivo",
              deleteConfirm: "¿Está seguro de que desea eliminar esta fila de presupuesto?",
              deleteSuccess: "Ok, la fila ha sido eliminada",
            },
            summary: {
              workGoal: "Mi meta para el trabajo",
              profession: "Qué me atrae de esta profesión",
              excites: "Lo que más me emociona de esto es",
              goalLookLike: "Cuando pienso en mi objetivo se parece",
              haveNow: "Qué me ayudará a lograr mi futuro",
              preferredWorkDays: "Días de trabajo preferidos",
              preferredWorkHours: "Horario de trabajo preferido",
              salary: "Salario deseado",
              haveReference: "¿Conoces a alguien en esta profesión?",
              referenceName: "¿Quiénes son?",
              referenceWhere: "¿Dónde trabajan?",
              inLifeSupport: "¿Quién en tu vida puede apoyarte en esta meta?",
              support: "¿Hay alguien más que pueda ayudarte a lograr tu objetivo?",
              commit: "¿Qué puedes comprometerte a hacer para ayudar a crear este futuro?",
            },
          },
          GTKYPage: {
            pageTitle: "Llegar a Conocerte",
            GTKYVideo: "Vídeo Conociéndote",
            likeAdmire: "Me gusta y admirar",
            peopleInMyLife: "Personas en mi vida",
            thingsIDo: "Cosas que me gusta hacer para divertirme",
            favoriteThings: "Mis cosas favoritas",
            myRoutines: "Mis Rutinas",
            historyMap: "Mapa histórico",
            travel: "Viaje",
            viewComments: "Ver comentarios"
          },
          myRoutines: {
            sectionName: "Mis Rutinas",
            subHeader: "Cuéntanos cómo son tus rutinas",
            videoTitle: "Mis Rutinas - Video Útil",
            time: "Tiempo",
            noTime: "No hay tiempo",
            day: "Día",
            noDay: "Ningún día",
            dailyError: "Por favor, introduzca una hora y una descripción",
            weeklyError: "Por favor ingrese un día y una descripción",
            successMessage: "¡Gracias! Tus rutinas han sido añadidas",
            dailyRoutines: "¿Cuáles son tus rutinas diarias?",
            weeklyRoutines: "¿Cuáles son tus rutinas o rituales semanales?",
            dailyPlaceholder: "Agrega algo que haces cada día y cuándo lo haces",
            weeklyPlaceholder: "Agrega algo que hagas cada semana",
            dayStickyTitle: "Mi día",
            weekStickyTitle: "Mi semana",
            deleteMessage: "¿Seguro que quieres eliminar esta rutina?",
            deleteSuccess: "Ok, la rutina ha sido eliminada.",
            days: {
              mon: "Lunes",
              tues: "Martes",
              wed: "Miércoles",
              thurs: "Jueves",
              fri: "Viernes",
              sat: "Sábado",
              sun: "Domingo",
            },
            contributor: {
              subHeader: "Cuéntanos cómo son sus rutinas",
              dailyRoutines: "¿Cuáles son sus rutinas diarias?",
              weeklyRoutines: "¿Cuáles son sus rutinas o rituales semanales?",
              dailyPlaceholder: "Agregue algo que hacen todos los días y cuándo lo hacen",
              weeklyPlaceholder: "Agregar algo que hacen cada semana",
              dayStickyTitle: "Día de {{name}}",
              weekStickyTitle: "Semana de {{name}}",
            },
          },
          thingsIDo: {
            sectionName: "Cosas que me gusta hacer para divertirme",
            subHeader: "Selecciona tu actividad o crea una nueva",
            indoors: "Adentro",
            outdoors: "Al aire libre",
            frequencyCard: "Me gusta hacer esto:",
            likeMostCard: "Lo que más me gusta de esta actividad:",
            tellUsWhatYouDo: "¡Cuéntanos todo lo que te gusta hacer para divertirte!",
            indoor: "Interior",
            outdoor: "Exterior",
            community: "En mi comunidad",
            whereToHaveFun: "¿Dónde te gusta divertirte?",
            yourActivity: "¿Qué te gusta hacer?",
            chosenActivity: "La actividad que elegiste fue",
            howOften: "¿Con qué frecuencia te gusta hacer esto?",
            aloneOrWithOthers: "¿Te gusta hacer esta actividad solo o con otros?",
            attachedPeople: "¿Con quién te gusta hacer esto?",
            addThingIDo: "Añadir otra actividad",
            otherPeopleInvolved: "¿Hay alguien más con quien quieras hacer esta actividad?",
            anyPhotos: "¿Tienes alguna foto divertida de {{activity}} que quieras compartir?",
            like: "¿Qué es lo que más te gusta de {{activity}}?",
            youSelected: "Tu actividad:",
            saveMessage: "¡Qué aventura! ¿Te gustaría añadir otra?",
            deleteConfirm: "¿Está seguro de que desea eliminar esta actividad?",
            deleteSuccess: "Su actividad ha sido eliminada con éxito",
            daily: "Diariamente",
            weekly: "Semanalmente",
            monthly: "Mensual",
            oncePerYear: "Una vez al año",
            fewTimes: "Pocas veces al año",
            whenever: "Cuando pueda",
            activities: {
              reading: "Lectura",
              writing: "Escritura",
              pilates: "Pilates",
              yoga: "Yoga",
              videoGames: "Videojuegos",
              cardGames: "Juegos de cartas",
              boardGames: "Juegos de mesa",
              instruments: "Instrumentos",
              painting: "Cuadro",
              drawing: "Dibujo",
              art: "Arte",
              replicaModels: "Modelos de réplica",
              movies: "Películas",
              tv: "TV",
              movieTheater: "Cine",
              diningOut: "Salir a cenar",
              yardSales: "Ventas de jardin",
              antiquing: "Antigüedades",
              mall: "Yendo al centro comercial",
              shopping: "Compras",
              sports: "Deportes",
              walks: "Camina",
              birdWatching: "Observación de Aves",
              hiking: "Senderismo",
              fishing: "Pescar",
              gardening: "Jardinería",
              camping: "Cámping",
              boating: "Paseo en barco",
              botany: "Botánica",
            },
            contributorQuestions: {
              frequencyCard: "Les gusta hacer esto:",
              likeMostCard: "Lo que más les gusta de esta actividad:",
              tellUsWhatYouDo: "¡Cuéntanos todo lo que les gusta hacer para divertirse!",
              community: "En mi comunidad",
              whereToHaveFun: "¿Dónde les gusta divertirse?",
              yourActivity: "¿Qué les gusta hacer?",
              chosenActivity: "La actividad que eligieron fue",
              howOften: "¿Con qué frecuencia les gusta hacer esto?",
              aloneOrWithOthers: "¿Les gusta hacer esta actividad solos o con otros?",
              attachedPeople: "¿Con quién les gusta hacer esto?",
              otherPeopleInvolved: "¿Hay alguien más con quien quieran hacer esta actividad?",
              anyPhotos: "¿Tienen alguna imagen divertida de {{activity}} que quieras compartir?",
              like: "¿Qué es lo que más les gusta de {{activity}}?",
              youSelected: "Su actividad:",
              saveMessage: "",
            },
            summary: {
              activity: "Lo que me gusta hacer",
              howOften: "Me gusta hacer esta actividad",
              withWho: "Me gusta hacer esto con",
              withWhoAlone: "Hago esto",
              byMyself: "por mi mismo",
              like: "Lo que más me gusta es",
              buttonText: "Ir a lo que me gusta hacer para divertirme",
            },
          },
          likeAndAdmire: {
            sectionName: "Me gusta y admirar",
            sectionTitle: "Cabeza, Corazón, Mano",
            subTitle: "Arrastra y suelta tus regalos en la categoría que elijas o crea los tuyos propios",
            videoTitle: "Me gusta y admirar - Video útil",
            filterAll: "Todo lo que me gusta y lo admiro (predeterminado)",
            filterOthers: "Lo que a otros les gusta y admiran de mí",
            filterMine: "Lo que me gusta y admiro de mí mismo",
            resetAll: "Resetear todo",
            addNew: "Añadir nueva palabra",
            saveMessage: "Buen trabajo {{firstName}}, ¡fue maravilloso escuchar todas las cosas buenas sobre ti!",
            head: "Cabeza",
            heart: "Corazón",
            hand: "Mano",
            headExplanation: "Dones de la mente, del cerebro o del pensamiento; esos dones que conoces",
            heartExplanation: "Regalos con los que te sientes conectado o apasionado",
            handExplanation: "Regalos que puedes proporcionar físicamente por acción o por hacer",
            deleteMessage: "¿Seguro que quieres eliminar este regalo?",
            deleteSuccess: "El regalo se eliminó con éxito",
            resetMessage: "¿Estás seguro de que quieres restablecer todo?",
            resetSuccess: "Ok, los elementos han sido reiniciados",
            contributor: {
              subTitle: "Arrastra y suelta sus regalos en la categoría que elijas o crea los tuyos propios",
              filterOthers: "Lo que a otros les gusta y admiran de ellos",
              filterMine: "Lo que les gusta y admiran de sí mismos",
            },
            summary: {
              head: "Cabeza",
              heart: "Corazón",
              hand: "Mano",
            },
          },
          peopleInMyLife: {
            sectionName: "Personas en mi vida",
            subHeader: "Agregue una persona o haga clic en el nombre de la persona para agregar más detalles sobre ella",
            adaptionDisclaimer: "Adaptación del Mapa de Relaciones",
            acquaintance: "Conocido",
            acquaintances: "Conocidos",
            add: "Añadir persona",
            addAsContributor: "Agregar como colaborador a mi plan",
            close: "Cerca",
            contributor: "Contribuyente",
            contributorAdditions: "Adiciones de colaboradores",
            coWorker: "Compañero de trabajo",
            coWorkers: "Compañeros de trabajo",
            deletePerson: "¿Estás seguro de que quieres eliminar a esta persona de las Personas de tu vida?",
            edit: "Editar Persona",
            family: "Familia",
            favoritePerson: "Persona favorita del mes",
            friend: "Amigo",
            friends: "Amigos",
            howClose: "¿Qué tan cerca estás de esta persona?",
            importantWhy: "¿Por qué esta persona es importante para ti?",
            involvedInSupport: "Involucrado en mi apoyo",
            mapPlace: "Esto determinará su lugar en su Mapa de Personas en Mi Vida",
            me: "Yo",
            notClose: "No está cerca",
            paidSupport: "¿Esta persona recibe manutención pagada?",
            paidSupportShort: "Soporte pagado",
            personIs: "Esta persona es...",
            relationshipToYou: "Relación contigo",
            removePerson: "Eliminar persona",
            weAre: "Estamos...",
            saveMessage: "Todas las personas en tu vida se han salvado, gran trabajo.",
            contributorQuestions: {
              howClose: "¿Qué tan cerca están de esta persona?",
              importantWhy: "¿Por qué esta persona es importante para ellos?",
              mapPlace: "Esto determinará su lugar en el Mapa de personas en mi vida",
              relationshipToYou: "Relación con ellos",
              theyAre: "Están...",
              involvedInSupport: "Implicados en su apoyo",
            },
            dialog: {
              nameError: "Por favor ingrese un nombre",
              personIsError: "Por favor, elija una opción",
              emailError: "Debe ingresar una dirección de correo electrónico para posibles colaboradores",
              emailValidError: "Por favor, introduce una dirección de correo electrónico válida",
              sendIvitation: "Enviar invitacion",
            },
          },
          favoriteThings: {
            sectionName: "Mis cosas favoritas",
            subHeader: "Agregue sus cosas favoritas seleccionando primero una categoría",
            contributorSubHeader: "Seleccione una categoría para comenzar a agregar sus cosas favoritas",
            viewOnlySubHeader: "Echa un vistazo a sus cosas favoritas",
            addDialogTitle: "Agregar una cosa favorita",
            editDialogTitle: "Edita tu cosa favorita",
            categories: "Categorías",
            categoryList: "Lista de categoría",
            myFavorites: "Mis favoritos",
            theirFavorites: "Sus Favoritos",
            place: "Lugar",
            placeToEat: "Lugar para comer",
            food: "Alimento",
            movie: "Película",
            book: "Libro",
            videoGame: "Videojuego",
            game: "Juego",
            sport: "Deporte",
            animal: "Animal",
            music: "Música",
            memory: "Memoria",
            other: "Otro",
            placeLabel: "Tu lugar favorito para ir",
            placeToEatLabel: "Tu lugar favorito para comer",
            foodLabel: "Tu comida favorita",
            movieLabel: "Tu película favorita",
            bookLabel: "Tu libro favorito",
            videoGameLabel: "Tu videojuego favorito",
            gameLabel: "Tu juego favorito",
            sportLabel: "Tu deporte o equipo deportivo favorito",
            animalLabel: "Tu mascota favorita o tipo de animal",
            musicLabel: "Tu banda, canción o género favorito",
            memoryLabel: "Uno de tus recuerdos favoritos",
            otherLabel: "Cualquier cosa favorita",
            chooseAView: "Elija una vista",
            allThings: "Todas las cosas favoritas (predeterminado)",
            thingsIAdded: "Cosas favoritas que he añadido",
            thingsOthersAdded: "Lo que otros han agregado",
            treasureChestAlt: "Cofre del tesoro de cosas favoritas",
            deleteConfirm: "¿Estás seguro de que quieres eliminar esta cosa favorita?",
            deleteSuccess: "Se ha eliminado lo favorito",
            saveAlertText: "Gracias {{name}}, ¡es genial aprender sobre tus cosas favoritas!",
            plurals: {
              place: "Lugares",
              placeToEat: "Lugares para comer",
              movie: "Películas",
              book: "Libros",
              videoGame: "Videojuegos",
              game: "Juegos",
              sport: "Deportes",
              animal: "Animales",
              memory: "Recuerdos",
            }
          },
          travelPlan: {
            sectionName: "Viaje",
            sectionHeader: "Viaje",
            subHeader: "Comparte tu lugar favorito para viajar o algún lugar al que realmente quieras ir",
            travelLocally: "¿Te gusta viajar dentro de los Estados Unidos o internacionalmente?",
            local: "Dentro de los Estados Unidos",
            international: "Internacionalmente",
            frequencyCard: "Me gusta hacer esto:",
            likeMostCard: "Lo que más me gusta de este lugar:",
            pageSubHeader: "Cuéntanos todo a dónde te gusta viajar",
            travelWhere: "¿A dónde te gusta viajar?",
            likeMost: "¿Qué es lo que más te gusta de viajar a {{location}}?",
            likeToTravel: "¿Cómo te gusta viajar?",
            alone: "¿Te gusta hacer esto solo o con otros?",
            howOften: "¿Con qué frecuencia te gusta viajar aquí?",
            anyoneElse: "¿Hay alguien más con quien te gustaría viajar?",
            anyPictures: "¿Tienes alguna foto divertida de {{location}} que quieras compartir?",
            deleteMessage: "¿Está seguro de que desea eliminar esta ubicación?",
            addTravelPlan: "Agrega otro lugar al que te guste viajar",
            addTravelContributor: "Agrega otro lugar al que creas que les gustaría viajar",
            attachedPeople: "¿Con quién te gusta viajar?",
            boat: "Barco",
            bus: "Autobús",
            car: "Coche",
            plane: "Plano",
            train: "Tren",
            saveText: "¡Qué aventura! ¿Te gustaría añadir otra?",
            deleteConfirm: "¿Está seguro de que desea eliminar esta actividad?",
            deleteSuccess: "Su actividad ha sido eliminada con éxito",
            contributorQuestions: {
              subHeader: "Compartan su lugar favorito para viajar o algún lugar al que realmente quieran ir",
              frequencyCard: "Les gusta hacer esto:",
              likeMostCard: "Lo que más les gusta de este lugar:",
              pageSubHeader: "Cuéntanos todo a dónde les gusta viajar",
              travelLocally: "¿Les gusta viajar dentro de los Estados Unidos o internacionalmente?",
              travelWhere: "¿A dónde les gusta viajar?",
              likeMost: "¿Qué es lo que más les gusta de viajar a {{location}}?",
              likeToTravel: "¿Cómo les gusta viajar?",
              alone: "¿Les gusta hacer esto solos o con otros?",
              howOften: "¿Con qué frecuencia les gusta viajar aquí?",
              anyoneElse: "¿Hay alguien más con quien les gustaría viajar?",
              anyPictures: "¿Tienen alguna foto divertida de {{location}} que quieras compartir?",
              attachedPeople: "¿Con quién les gusta viajar?",
            },
            summary: {
              place: "Me gusta viajar a",
              travelWithSolo: "Me gusta viajar aquí",
              byMyself: "Por mi mismo",
              travelWith: "Me gusta viajar aquí con",
              frequency: "Voy allí",
              frequencyAnswer: "{{frequency}} y viajo en {{transportation}}",
              favoriteThing: "Lo que más me gusta de viajar a {{place}} es",
              buttonText: "Ir a viajar",
            },
          },
          historyMap: {
            sectionHeader: "Mi Mapa de Historia",
            subHeader: "Cuéntenos sobre los hitos, aniversarios o eventos importantes en su vida",
            addMilestone: "Añadir hito",
            editMilestone: "Editar hito",
            sectionName: "Mapa histórico",
            category: "Categoría",
            familyLife: "Vida familiar",
            school: "Escuela",
            activities: "Actividades",
            friends: "Amigos",
            housing: "Alojamiento",
            work: "Trabajar",
            relationships: "Relaciones",
            chooseDate: "Elige una fecha",
            milestone: "Hito",
            details: "Detalles",
            back: "Volver a conocerte",
            saveMessage: "Fue genial aprender todo sobre ti, ¡gracias por compartir!",
            validDateError: "Por favor introduzca una fecha valida",
            rangeDateError: "La fecha está fuera de rango",
            deleteMessage: "¿Está seguro de que desea eliminar este hito?",
            deleteSuccess: "Su hito se ha eliminado con éxito",
          },
          contributor: {
            addToRelationshipMap: "Agregar a mi Mapa de Relaciones",
            thisPersonIs: "Esta Persona es un...",
            howClose: "¿Qué tan cerca estás de esta persona?",
            close: "Cerca",
            notClose: "No está cerca",
            setPermissions: "Establecer permisos de colaborador",
            hasBeenEmailed: "Se ha enviado una invitación a su colaborador",
          },
          happeningPage: {
            pageTitle: "Pasando en mi vida",
            living: "Vivir en mi hogar",
            work: "Trabajar",
            school: "Escuela",
            health: "Salud y Seguridad",
            happeningVideo: "Sucediendo en mi vida",
          },
          happeningHome: {
            pageTitle: "Vivir en mi casa",
            subTitle: "¡Averigüemos más sobre dónde vives!",
            singleStory: "Casa de una sola planta",
            multiLevel: "Casa de varios niveles",
            apartment: "Departamento",
            facility: "Instalaciones",
            homeless: "Sin hogar",
            groupHome: "Casa de grupo",
            car: "Coche",
            rvCamper: "RV/caravana",
            currentlyLive: "¿Donde vives actualmente?",
            currentlyLiveWith: "¿Con quién vives actualmente?",
            parents: "Padre(s)",
            guardians: "Guardián(es)",
            siblings: "Hermano(s)",
            friends: "Amigo(s)",
            roommates: "Compañero(s) de cuarto",
            spouse: "Esposa",
            children: "Niños",
            alone: "Vivo solo",
            dogs: "Perro(s)",
            cats: "Gato(s)",
            other: "Otro, por favor explique",
            explain: "Por favor explique",
            tableTitle: "¿Qué es lo que te gusta y lo que no te gusta del lugar donde vives?",
            amenityHeader: "Comodidades",
            selectHeader: "Por favor seleccione me gusta o no me gusta",
            commentHeader: "Comentarios",
            commentsLikes: "Comentarios sobre mis gustos",
            commentsDislikes: "Comentarios sobre mis disgustos",
            tellUsWhy: "Por favor díganos por qué aquí",
            whatILike: 'Lo que me gusta de dónde vivo actualmente',
            whatIDislike: 'Lo que no me gusta de dónde vivo actualmente',
            amenities: {
              publicTransportation: "Transporte público",
              accessibility: "Accesibilidad",
              neighborhood: "Vecindario",
              friendsFamily: "Cerca de amigos y familiares",
              shopping: "Cerca de las tiendas",
              furniture: "Muebles",
              homeType: "Tipo de hogar",
              squareFootage: "Pies cuadrados",
              housemates: "compañeros de casa",
              livingAlone: "Vivir solo",
              neighbors: "vecinos",
              management: "Administración de la propiedad",
              support: "Personal de apoyo",
              tellUsWhy: "Por favor díganos por qué",
            },
            summary: {
              currentlyLive: "Donde yo vivo",
              currentlyLiveWith: "Con quién vivo",
            },
          },
          happeningSchool: {
            header: "Escuela",
            subHeader: "Estamos deseando saber más sobre la escuela, cuéntanos tu experiencia",
            enrolledInSchool: "¿Estas Actualmente en la escuela?",
            schoolNameOOS: "¿A que escuela fuiste?",
            gradeLevelOOS: "¿Qué grado terminaste?",
            gradYearOOS: "¿Cuándo te graduaste de la escuela?",
            schoolLikeOOS: "¿Qué es lo que más te gustaba de la escuela?",
            schoolDislikeOOS: "¿Qué no te gustó de la escuela?",
            volunteerOOS: "¿Mientras estabas en la escuela fuiste voluntario en algún lugar?",
            volunteerWhereOOS: "¿Dónde te ofreciste como voluntario?",
            volunteerDislikeOOS: "¿Qué no te gustó?",
            volunteerLikeOOS: "¿Qué te gustó del voluntariado?",
            graduationGoalOOS: "¿Qué era?",
            gradYearOtherOOS: "¿Cuándo te graduaste de la escuela?",
            goalYesOOS: "¿Hay algo que quisieras hacer una vez que te graduaras?",
            schoolName: "¿A qué escuela vas?",
            gradeLevel: "¿En que grado estás?",
            gradYear: "¿Cuándo has terminado con la escuela?",
            schoolLike: "¿Qué es lo que más te gusta de la escuela?",
            schoolDislike: "¿Qué no te gusta de la escuela?",
            volunteer: "¿Eres voluntario en algún lugar?",
            volunteerWhere: "¿Dónde eres voluntario?",
            volunteerDislike: "¿Que es lo que te disgusta?",
            volunteerLike: "¿Qué te gusta del voluntariado?",
            graduationGoal: "¿Qué es?",
            gradYearOther: "",
            goalYes: "¿Hay algo que quieras hacer una vez que te gradúes?",
            summary: {
              schoolName: "La escuela a la que voy se llama",
              schoolNameOOS: "La escuela a la que fui se llama",
              gradeLevel: "Estoy en grado",
              gradeLevelOOS: "Terminé el grado",
              gradYear: "Voy a terminar con la escuela en",
              gradYearOOS: "Terminé con la escuela en",
              schoolLike: "Lo que me gusta de la escuela",
              schoolLikeOOS: "Lo que me gustaba de la escuela",
              schoolDislike: "Lo que no me gusta de la escuela",
              schoolDislikeOOS: "Lo que no me gustaba de la escuela",
              volunteerWhere: "En la escuela soy voluntario en",
              volunteerWhereOOS: "En la escuela en la que me ofrecí como voluntario",
              volunteerLike: "Lo que me gusta del voluntariado",
              volunteerLikeOOS: "Lo que me gustó del voluntariado",
              volunteerDislike: "Lo que no me gusta del voluntariado",
              volunteerDislikeOOS: "Lo que no me gustó del voluntariado",
              graduationGoal: "Cuando me gradúe, quiero",
              graduationGoalOOS: "Cuando me gradué, yo quería",
            },
          },
          happeningWork: {
            sectionName: "Experiencia laboral",
            subHeader: "Cuéntanos más sobre tu experiencia laboral, ¡estamos ansiosos por saberlo!",
            pastExperience: "Por favor, comparta su experiencia laboral pasada",
            pastExperienceLike: "¿Qué le gustó de su experiencia laboral pasada?",
            pastExperienceDislike: "¿Qué no le gustó de su experiencia laboral pasada?",
            hasCurrentEmployment: "¿Estas trabajando?",
            currentEmployment: "¿Dónde estás trabajando actualmente?",
            currentDuration: "¿Cuanto tiempo has trabajado allí?",
            currentLike: "¿Qué te gusta de tu trabajo actual?",
            currentDislike: "¿Qué es lo que no te gusta de tu trabajo actual?",
            summary: {
              pastExperience: "Donde he trabajado en el pasado",
              pastExperienceLike: "Lo que me gusta de donde he trabajado en el pasado",
              pastExperienceDislike: "Lo que no me gusta de donde he trabajado en el pasado",
              currentEmployment: "Actualmente estoy trabajando en",
              currentDuration: "He trabajado aquí para",
              currentLike: "Lo que me gusta de donde trabajo",
              currentDislike: "Lo que no me gusta de donde trabajo",
            },
          },
          happeningHealth: {
            header: "Salud y Seguridad",
            subHeader: "Vamos a conocerte un poco más, por favor comparte con qué te sientes cómodo",
            contributorAdditionsToApprove:
              "Sus colaboradores han agregado DME's y/o Supports a su plan. ¿Le gustaría aprobarlos?",
            medInfo: "¿Hay algo relacionado con la salud que impacte en tu vida?",
            allergies: "Por favor, enumere las alergias que le gustaría que supiéramos",
            dmeSectionTitle: "¿Qué tipo de equipo médico duradero y soportes utiliza?",
            dmeContributorTitle: "¿Qué tipo de equipo médico duradero y soportes utilizan?",
            sectionSubTitle:
              "Seleccione un botón para agregarlo a su lista, haga clic en la 'x' en su lista para eliminarlo",
            supportsSectionTitle: "¿Cómo pueden los demás ayudarlo a sentirse y mantenerse saludable?",
            supportContributorTitle: "¿Cómo pueden los demás ayudarlos a sentirse y mantenerse saludables?",
            dmePostItTitle: "Cómo se ven los buenos soportes",
            supportPostItTitle: "Cómo otros pueden apoyarme",
            dmeOptions: {
              bloodSugarMonitors: "Monitores de azúcar en la sangre",
              bloodSugarTestStrips: "Tiras de prueba de azúcar en la sangre",
              canes: "Bastones",
              commodeChairs: "Sillas cómodas",
              continuousPassiveDevices: "Dispositivos de movimiento pasivo continuo",
              continuousPassiveAirwayPressureDevices:
                "Dispositivos de presión positiva continua en las vías respiratorias (CPAP)",
              crutches: "Muletas",
              hospitalBeds: "Camas de hospital",
              infusionPumps: "Bombas de infusión y suministros",
              lancetDevices: "Dispositivos de lanceta y lancetas",
              nebulizerMedications: "Nebulizadores y medicamentos nebulizadores",
              oxygenEquipment: "Equipos de oxígeno y accesorios",
              patientLifts: "Ascensores de pacientes",
              supportSurfaces: "Superficies de apoyo reductoras de presión",
              suctionPumps: "Bombas de succión",
              tractionEquipment: "Equipos de tracción",
              walkers: "Caminantes",
              rollator: "Andador",
              wheelchairMotorized: "Silla de ruedas - motorizada",
              wheelchairManual: "Silla de ruedas - manual",
              wheelchairStanding: "Silla de ruedas - Reclinable/De pie",
              scooterMotorized: "Scooter - Motorizado",
              scooterManual: "Scooter - Manual",
              assistiveTech: "Tecnología de asistencia",
              translator: "Traductor",
              brailleKeyboard: "Teclado Braille",
              hearingAid: "Audífono",
            },
            supportOptions: {
              cookMeals: "Cocinar mis comidas",
              prepareMeals: "Ayúdame a preparar mis comidas",
              prepareMedication: "Ayúdame a preparar mi medicación",
              medicationSchedule: "Ayúdame a crear un horario de medicamentos",
              doctorTransportation: "Ayúdame a ir y venir de los médicos",
              createBudget: "Ayúdame a crear un presupuesto",
              beActive: "Ayúdame a motivarme a ser activo",
            },
            summary: {
              medInfo: "Las condiciones de salud que me gustaría que otros supieran incluyen",
              allergies: "Las alergias que me gustaría que otros supieran incluyen",
              dmeSectionTitle: "El Soporte Médico Duradero que utilizo incluye",
              supportsSectionTitle: "Las personas en mi vida pueden apoyarme de las siguientes maneras",
            },
          },
          happeningGeneral: {
            sectionSave:
              "Gracias por la información {{userFirstName}}, puedes pasar a otra sección de ¡Qué está pasando en tu vida!",
          },
          planSummary: {
            generatePdf: "Generar PDF",
            downloadPdf: "Descargar PDF",
            selectTravelPlan: "Seleccione su plan de viaje",
            selectActivity: "Selecciona tu actividad",
            selectGoal: "Seleccione una meta",
            sections: {
              importantToMe: "Qué es importante para mi",
              importantForMe: "Lo que es importante para mí",
              goals: "Planificación de objetivos",
            },
            cardTitles: {
              likeAdmire: "Lo que a la gente le gusta y admira de mí",
              people: "Personas importantes en mi vida",
              milestones: "Hitos importantes en mi vida",
              favoriteThings: "Algunas de mis cosas favoritas",
              thingsIDo: "Lo que me gusta hacer para divertirme",
              travelPlan: "Lugares a los que me gustaría viajar",
              dailyRoutines: "Mis rutinas diarias",
              weeklyRoutines: "Mis Rutinas Semanales",
              happeningHome: "Vivir en mi casa",
              happeningWork: "Mi experiencia de trabajo",
              happeningSchool: "Mi experiencia escolar",
              happeningHealth: "Mi salud y seguridad",
              homeGoals: "Mis objetivos de vivienda",
              workGoals: "Mis objetivos de trabajo",
              healthGoals: "Mis objetivos de salud",
              funGoals: "Mis objetivos divertidos",
              relationshipGoals: "Mis objetivos de relación",
              travelGoals: "Mis objetivos de viaje",
            },
            cardDescriptions: {
              likeAdmire: "Estas son las cosas que me gustan y admiro de mí y lo que mis colaboradores aprecian de mí",
              people: "Aquí están las personas más importantes en mi vida.",
              milestones: "¡Descubre todos los hitos importantes en mi vida de los que estoy más orgulloso!",
              favoriteThings: "Estas son solo algunas de mis cosas favoritas.",
              thingsIDo: "¡Estas son algunas de las cosas que me gusta hacer para divertirme!",
              travelPlan: "Descubre dónde me gusta viajar, con quién y con qué me gusta viajar",
              dailyRoutines: "¡Descubre lo que es importante para mí cada día y lo que hace un buen día!",
              weeklyRoutines: "¡Descubre más sobre mis preferencias, rituales y rutinas!",
              happeningHome: "Es importante para mí compartir mi situación de vida actual, lo que me gusta y lo que no me gusta de ella.",
              happeningWork: "¡Obtenga más información sobre mis experiencias pasadas con el trabajo, lo que me gustó y lo que no me gustó!",
              happeningSchool: "¡Descubre un poco más sobre mí y mi experiencia en la escuela!",
              happeningHealth: "Esto es lo que me siento cómodo compartiendo sobre mi salud, seguridad y lo que otros pueden hacer para apoyarme.",
              homeGoals: "Más información sobre dónde quiero vivir",
              workGoals: "Aprende sobre el trabajo de mis sueños",
              healthGoals: "Descubra cómo quiero planificar mi salud",
              funGoals: "Averiguar lo que quiero hacer para divertirme en el futuro",
              relationshipGoals: "Más información sobre cómo quiero conectarme con los demás",
              travelGoals: "Averigüe dónde espero viajar",
            },
            noContent: {
              milestones: "No se han añadido hitos. ¡Presiona el botón de abajo para iniciar!",
              favoriteThings: "",
              thingsIDo: "No se han creado Things I Do for Fun. ¡Haz clic en el botón de abajo para crear uno!",
              travelPlan: "No se han creado lugares para viajar. ¡Haz clic en el botón de abajo para crear uno!",
              dailyRoutines: "No se han añadido rutinas diarias. ¡Presiona el botón de abajo para iniciar!",
              weeklyRoutines: "No se han añadido rutinas semanales. ¡Presiona el botón de abajo para iniciar!",
              happeningHome: "No se ha registrado información de Vivir en mi hogar. ¡Presiona el botón de abajo para iniciar!",
              happeningWork: "No se ha registrado información de Experiencia Laboral. ¡Presiona el botón de abajo para iniciar!",
              happeningSchool: "No se ha registrado información de la Escuela. ¡Presiona el botón de abajo para iniciar!",
              happeningHealth: "No se ha registrado información de Salud y Seguridad. ¡Presiona el botón de abajo para iniciar!",
              homeGoals: "No se han creado metas de Vivienda. ¡Haz clic en el botón de abajo para crear uno!",
              workGoals: "No se han creado objetivos de trabajo. ¡Haz clic en el botón de abajo para crear uno!",
              healthGoals: "No se han creado objetivos de salud. ¡Haz clic en el botón de abajo para crear uno!",
              funGoals: "No se han creado objetivos divertidos. ¡Haz clic en el botón de abajo para crear uno!",
              relationshipGoals: "No se han creado objetivos de relación. ¡Haz clic en el botón de abajo para crear uno!",
              travelGoals: "No se han creado objetivos de viaje. ¡Haz clic en el botón de abajo para crear uno!",
            },
          },
          contributorGeneric: {
            entries: "Entradas de colaboradores",
            noAccess: "No tienes acceso a esta sección",
            includedByPlanOwner: 'Incluido por el propietario del plan',
            notIncluded: "No incluido por el propietario del plan",
            sectionNotComplete: "El propietario del plan no ha completado esta sección.",
            thankYou:
              `¡Gracias por su contribución! Sus adiciones se han enviado al propietario del plan para su aprobación`,
          },
          contributorComments: {
            addComment: "Añadir un comentario",
            allComments: "Todos los comentarios",
            commentPrompt:
              "¿Tienes algo que te gustaría comentar basado en lo que ves? ¡Déjales un comentario o una nota divertida!",
            yourComment: "Tu comentario",
            approved: "Comentario aprobado",
            pendingApproval:
              "¡Gracias por su contribución! Su comentario ha sido enviado al propietario del plan para su aprobación",
            pendingNoThankYou: "Su comentario ha sido enviado al propietario del plan para su aprobación",
            comments: "Comentarios",
            toCheck: "verificar",
            deleteConfirm: '¿Estas seguro de que quieres eliminar este comentario?',
            deleteSuccess: "Ok, el comentario ha sido eliminado.",
            notApprovedMessage: 'Su comentario no fue aprobado por el propietario del plan. Puede editar su comentario y volver a enviarlo, o eliminar su comentario.',
            notIncluded: 'No incluido',
          },
          durationDropdown: {
            notSelected: "No seleccionado",
            zeroMonths: "0 - 6 meses",
            sixMonths: "6 - 12 meses",
            oneYear: "1 - 2 años",
            twoYears: "2+ años",
          },
          navigationButtons: {
            gtky: "Volver a Llegar a conocerte",
            thingsIDo: "Volver a Cosas que hago para divertirme",
            happening: "Volver a Pasando en mi vida",
            planningFun: "Volver a Planificación divertida",
            planningHealth: "Volver a Planificación de la Salud",
            planningHome: "Volver a Planificación del hogar",
            planningRelationships: "Volver a Planificación de relaciones",
            planningTravel: "Volver a Planificación de viajes",
            planningWork: "Volver a Trabajo de planificación",
          },
          generic: {
            add: "Agregar",
            addAnother: "Agrega otro",
            addComment: "Añadir un comentario",
            addNew: "Agregar nuevo",
            addNewEntry: "Agregar nueva entrada",
            addYourOwn: "Agrega el tuyo",
            addedBy: "Añadido por",
            addressPrimary: "Dirección 1",
            addressSecondary: "Suite, chav tsev #",
            alone: "Solo",
            approve: "Aprobar",
            back: "Atrás",
            backToDashboard: "Volver al tablero del plan",
            cancel: "Cancelar",
            cancelled: "Cancelado",
            city: "Ciudad",
            clear: "Claro",
            country: "País",
            comments: "Comentarios de colaboradores",
            createdBy: "Creado por",
            createdOn: "Creado en",
            dailyWithCapital: "Diariamente",
            date: "Fecha",
            delete: "Borrar",
            deleted: "Eliminado",
            deleteGoalConfirm: "¿Está seguro de que desea eliminar este objetivo?",
            deleteGoalSuccess: "Su objetivo ha sido eliminado con éxito",
            details: "Detalles",
            disapprove: "Desaprobar",
            dislike: "disgusto",
            edit: "Editar",
            editGoal: "Editar objetivo",
            email: "Correo electrónico",
            emailWithCapitial: "Email",
            error: "Error",
            faceTime: "FaceTime",
            firstName: "Primer nombre",
            addGeneralComment: "Haga clic para agregar un comentario general",
            generalComments: "Comentarios Generales",
            goal: "Meta",
            goToItem: "Ir al artículo",
            goToGoal: "Ir a la meta",
            goToSection: "Ir a {{section}}",
            inPerson: "En persona",
            instructionalVideo: "Video instructivo",
            language: "Preferencia de idioma",
            lastName: "Apellido",
            like: "me gusta",
            loading: "Cargando...",
            middleName: "Segundo nombre",
            name: "Nombre",
            newAdditions: "Los colaboradores han hecho nuevas incorporaciones.",
            no: "No",
            noNotifications: "No Notificaciones",
            noPeopleMessage: "¡Agregue amigos y familiares a su Gente en mi vida para seleccionarlos aquí!",
            noThanks: "No gracias",
            okay: 'Okey',
            other: "Otro",
            pleaseExplain: "Por favor explique",
            phoneNumber: "Número de teléfono",
            remove: "Remover",
            removeImage: "Quita la imagen",
            review: "Revisar",
            save: "Ahorrar",
            saveAndSendUpdates: "Guardar y enviar actualizaciones",
            saveAndSendInvite: "Guardar y enviar invitación",
            saveAnother: "Guardar y agregar otro",
            saveNoUpdates: "No enviar actualizaciones",
            search: "Búsqueda",
            selectAGoal: "Seleccione un objetivo",
            state: "Estado",
            submit: "Enviar",
            success: "Éxito",
            talking: "Hablando",
            thankYou: 'Gracias',
            title: "Título",
            uploadImage: "Subir una imagen",
            viewComment: "Ver comentario",
            weeklyWithCapitial: "Semanalmente",
            with: "con",
            withOthers: "Con otros",
            yes: "Sí",
            zip: "Código postal",
            include: "Incluir",
            notInclude: "No incluye",
            approveAll: "Aprobar todo",
            denyAll: "Negar todo",
            removeFromPlan: "Quitar del plan",
            said: "dijo",
            errors: {
              errorPageTitle: '¡Ha habido un error!,',
              errorPageSubTitle: 'Lo lamento. Por favor regresa más tarde.,',
              error404: '404 Pagina no encontrada,',
              saveError: 'Lo sentimos, hubo un error para guardar su entrada. Por favor, inténtelo de nuevo más tarde.,',
              deleteError: 'Lo sentimos, hubo un error que eliminaba su entrada. Por favor, inténtelo de nuevo más tarde.,',
              createPlanError: 'Lo siento, hubo un error para crear su plan. Por favor, inténtelo de nuevo más tarde.',
            },
          },
        },
      },
      armenian: {
        translation: {
          contact: {
            contactUs: 'Կապ մեզ հետ',
            message: 'Հաղորդագրություն',
            send: 'Ուղարկել',
            sayHello: 'Ասա բարեւ',
            saveTitle: 'Ձեր էլփոստը հաջողությամբ ուղարկվեց:',
            saveMessage: '«Centre On Me»-ի ներկայացուցիչը շուտով կկապվի ձեզ հետ:',
            emailError: 'Խնդրում ենք մուտքագրել գործող էլեկտրոնային հասցե',
            phoneError: 'Խնդրում ենք մուտքագրել վավեր հեռախոսահամար',
          },
          password: {
            lowercase: "Պարունակում է փոքրատառ",
            uppercase: "Պարունակում է մեծատառ",
            eightCharacters: "Առնվազն 8 նիշ երկարություն",
            number: "Պարունակում է թիվ",
            specialCharacter: "Պարունակում է հատուկ բնույթ",
            passwordsMatch: "Գաղտնաբառերը համընկնում են",
            errorCannotMatch: "Հին գաղտնաբառը և Նոր գաղտնաբառը չեն կարող համընկնել",
          },
          saveProgress: {
            uploadPhoto: 'Պատկեր վերբեռնումը',
            updateProfile: 'Ձեր պրոֆիլը թարմացնելը',
          },
          agreements: {
            pleaseRead: "Ձեր տեղեկատվության պաշտպանությունը մեր առաջնահերթությունն է: Խնդրում ենք կարդալ մեր",
            privacyTitle: "Կայքի գաղտնիություն",
            termsTitle: "Օգտվելու կանոններ",
            ifYouAgree: `Եթե ​​համաձայն եք, մուտքագրեք Ձեր Անունը և Ազգանունը և սեղմեք «Համաձայն եմ»`,
          },
          settings: {
            pageTitle: "Կարգավորումներ",
            subTitle: "Ձեր տեղը ձեր պլանն այնպես հարմարեցնելու համար, ինչպես ցանկանում եք",
            saveSettings: "Պահպանել կարգավորումները",
            planVideo: "Իմ պլանավորման տեսանյութը",
            contributorPage: "Կառավարել ներդրողներին",
            colorPalette: "Իմ գույների ներկապնակը",
            changePassword: {
              changePassword: "Փոխել գաղտնաբառը",
              subTitle: "Եկեք փոխենք այդ գաղտնաբառը։",
              currentPassword: "Ընթացիկ գաղտնաբառը",
              newPassword: "Նոր ծածկագիր",
              confirmPassword: "Հաստատել գաղտնաբառը",
              currentIncorrect: "Մուտքագրված գաղտնաբառը սխալ է",
              samePasswordError: "Վա՜յ, խնդրում եմ օգտագործեք նոր գաղտնաբառ",
              successMessage: "Գաղտնաբառը փոխվել է"
            },
            colorPalettePage: {
              pageTitle: "Գունավոր գունապնակ",
              subTitle: "Ընտրեք, թե որ գույները կցանկանայիք տեսնել ձեր պլանը ստեղծելիս:",
              myColorPalette: "Իմ Գունավոր Ներկապնակ",
              select: "Ընտրեք գույների գունապնակը, որը ձեզ դուր է գալիս",
              brainstormBlue: "Բրեյնսթոր կապույտ",
              planningPurple: "Պլանավորում մանուշակագույնով",
              organizingOrange: "Նարնջագույնի կազմակերպում",
              gamePlanGreen: "Խաղի պլան կանաչ",
              primaryColorTooltip: "Տեքստ, կոճակներ և սրբապատկերներ",
              secondaryColorTooltip: "Վերնագրեր, ընտրված կոճակներ, աղյուսակներ",
              contributorColorTooltip: "Աջակցողների գրառումները",
              textColorTooltip: "Տեքստ",
              saveMessage: "Ձեր գունային գունապնակը թարմացվել է!"
            },
            manageContributors: {
              sectionHeader: "Կառավարեք իմ ներդրողները և ծանուցումները",
              subHeader: "Սեղմեք ներդրողի քարտի վրա՝ նրանց թույլտվությունը սահմանելու կամ խմբագրելու համար",
              myContributors: "Իմ ներդրողները",
              dialogConfirm: "Ամեն ինչ ճի՞շտ է թվում",
              dialogTitle: "Աջակցի տեղեկատվություն",
              inviteSuccess: "Ձեր մասնակցին հրավեր է ուղարկվել",
              updateSuccess: "Աջակցողի տեղեկատվությունը թարմացվել է, և նամակ է ուղարկվել ներդրողին ծանուցելու համար",
              updateSuccessNoEmail: "Աջակցողի տեղեկատվությունը թարմացվել է",
              lastContribution: "Վերջին ներդրում:",
              noContributions: "Դեռևս ներդրումներ չկան",
              addContributor: "Ավելացրեք ներդրող",
            },
            contributorNotifications: {
              myNotifications: "Իմ ծանուցումները",
              letsSetPreferece: "Եկեք սահմանենք ձեր նախապատվությունները",
              howToNotify: "Երբ ներդրողն ավելացնում է ձեր պլանը, ինչպե՞ս կցանկանայիք ծանուցել ձեզ",
              howOften: "Որքա՞ն հաճախ եք ցանկանում, որ ձեզ ծանուցեն",
              saveAlert: "Ձեր ծանուցման կարգավորումները թարմացվել են",
            },
            managementPage: {
              sectionHeader: "Իմ ներդրողները",
              noContributorsContext:
                "Դուք կարող եք մասնակիցներին թույլտվություն տալ ձեր ծրագրի տարբեր բաժիններին: Նրանք կարող են այնուհետև մեկնաբանություններ և այլ լրացումներ կատարել այդ բաժիններին",
            },
            profile: {
              pageTitle: "Անուն և մանրամասներ",
              sectionHeader: "Բարի գալուստ Կենտրոն ինձ վրա",
              subHeader: "Խնդրում եմ մի պահ տրամադրեք և մի փոքր պատմեք ձեր մասին",
              profilePicture: 'Անձնագրի նկարը',
              name: "Անուն",
              languageAndNumber: "Լեզու և հեռախոսահամար",
              address: "Հասցե",
              firstNameError: "Անունը պարտադիր է",
              lastNameError: "Ազգանունը պարտադիր է",
              phoneError: "Պահանջվում է վավեր հեռախոսահամար",
              saveConfirmationMsg: "Սա ճի՞շտ է թվում",
              backToSettings: "Վերադառնալ Կարգավորումներ",
              backToPlans: "Վերադառնալ իմ պլան(ներ)ին",
              saveMessage: "Շնորհակալություն ձեր տեղեկությունները ավելացնելու համար",
              firstTimeSaveMessage: 'Հիանալի է ձեզ ծանոթանալ: Հիմա եկեք սկսենք պլանավորել:',
              notQuite: "Ոչ այնքան",
              speechToText: 'Խոսքը տեքստի համար',
              speechToTextEnabled: 'Այո, ես ուզում եմ օգտագործել խոսքը տեքստի համար:',
              speechToTextDisabled: 'Ոչ, ես չեմ ուզում տեքստ գրել',
              speechToTextWarning: 'Ներկայումս հասանելի է միայն անգլերեն լեզվով',
              languages: {
                english: "Անգլերեն",
                armenian: "Հայերեն",
                chineseSimple: "Չինական, պարզեցված",
                chineseTraditional: "Չինական, ավանդական",
                farsi: "Ֆարսի",
                hmong: "Հմոնգ",
                korean: "Կորեական",
                spanish: "Իսպաներեն",
                tagalog: "Տագալոգ",
                vietnamese: "Վիետնամերեն",
              },
            },
            account: {
              pageTitle: 'Հաշիվ',
              subTitle: 'Ձեր տեղը կառավարելու ձեր բաժանորդագրությունը',
              benefitsTitle: 'Վճարովի բաժանորդային նպաստներ',
              manageTitle: 'Կառավարեք բաժանորդագրությունը',
              benefits: {
                multiplePlans: 'Ստեղծեք բազմաթիվ ծրագրեր',
                fullAccess: 'Ծրագրի էջերը լրիվ հասանելիություն',
                printablePDF: 'PLF PLF պլանի ամփոփում',
                uploadPhotos: 'Վերբեռնեք լուսանկարները',
                planVideo: 'Ստեղծեք ծրագրի տեսանյութ `ձեր ներդրողների հետ կիսվելու համար',
                backgroundImage: 'Ընտրեք պատվերով ֆոնային պատկեր',
                themeColor: 'Ընտրեք ձեր պրոֆիլի թեման գույնը',
              },
              currentPlan: 'Ընթացիկ պլան',
              trial: '30-օրյա դատավարություն',
              subscription: '12-ամսյա բաժանորդագրություն',
              noSubscription: 'Ընթացիկ բաժանորդագրություն չկա',
              expirationDate: 'Գործողության ժամկետը',
              expiresOn: 'Ձեր բաժանորդագրությունը լրանում է',
              paymentMethod: 'Վճարման եղանակ',
              supportContact: 'Աջակցության կապ',
              supportContactIs: 'Ձեր աջակցության շփումը {{contactName}} անուն է',
              editSupportContact: 'Խմբագրել աջակցության կոնտակտը',
              supportContactLabel: 'Աջակցության կապ',
              contactEmailLabel: 'Կոնտակտային էլփոստի հասցե',
              contactPhoneLabel: 'Կոնտակտային հեռախոսահամար',
              saveSupportMessage: 'Ձեր աջակցության շփումը թարմացվել է:',
              supportContactError: 'Պահանջվում է աջակցության կոնտակտ',
              contactEmailError: 'Պահանջվում է աջակցության կոնտակտային էլ',
              contactPhoneError: 'Պահանջվում է աջակցության կոնտակտային հեռախոսահամար',                            
              subscribeButton: 'Բաժանորդագրվել',
              renewButton: 'Վերականգնել բաժանորդագրությունը',
              requestRenewal: 'Խնդրեք թարմացնել ձեր բաժանորդագրությունը',
              renewDialogTitle: 'Նորացնել ձեր բաժանորդագրությունը',
              selectedPlan: 'Ընտրված պլան',
              billed: 'Խանձված',
              payment: 'Վճարում',
              howItWorks: 'Ինչպես է աշխատում ձեր բաժանորդագրումը',
              billingInformation: 'Տեղեկատվություն գանձելու մասին',
              billingStartPlanning: 'Կարող եք սկսել պլանավորել հնարավորունը ձեր վճարումը',
              expirationExplanation: 'Բաժանորդագրության ժամկետի ավարտից հետո բաժանորդագրությունը ինքնաբերաբար կդադարեցվի առանց որեւէ կուսակցության պահանջվող այլ ծանուցման կամ գործողության: Լրացուցիչ գանձումներ չեն լինի, եւ դուք այլեւս չեք ունենա բաժանորդագրության հետ կապված առավելությունները, քանի դեռ նոր բաժանորդագրություն չեք գնում:',
              termsConditions: 'Պայմաններ եւ դրույթներ',
              trialToMemberMessage: 'Բարի գալուստ կենտրոն ինձ վրա: Այժմ դուք ունեք մուտք ինձ վրա կենտրոնի բոլոր օգուտները:',
              memberRenewalMessage: 'Ձեր ծրագիրը նորացվել է եւս 12 ամիս',
              contactEmail:  'Կոնտակտային էլփոստի հասցե',
              contactPhone: 'Կոնտակտային հեռախոսահամար',
              contactSuccessMessage: 'Ձեր աջակցության շփումը թարմացվել է',
              subscribeDialogTitle: 'Բաժանորդագրվեք կենտրոնում ինձ վրա',
              trialExpireOn: 'Ձեր դատավարությունը կավարտվի',
              expiredOn: 'Ձեր բաժանորդագրությունն ավարտվել է',
              paymentSuccessMessage: 'Շնորհակալություն! Հուսով ենք, որ դուք կվայելեք կենտրոնը ինձ վրա',
              today: "Այսօր",
              todayDescription: "Բացեք ինձ կենտրոնի ամբողջական հասանելիությունը",
              tenMonths: "10 ամիս",
              tenMonthDescription: "Հիշեցրեք, թե երբ է ձեր ծրագիրը լրանում",
              twelveMonths: "12 ամիս",
              twelveMonthDescription: "Ձեր բաժանորդագրությունն ավարտվում է",
              showPaymentOptions: 'Show ույց տալ վճարման ընտրանքները',
            },
            profilePictureDialog: {
              title: "Ավելացրեք ձեր պրոֆիլի նկարը",
              uploadPhoto: "Վերբեռնել լուսանկարը",
            },
            backgroundImage: {
              pageTitle: 'Ֆոնային պատկեր',
              subTitle: 'Ընտրեք ֆոնի պատկեր կամ վերբեռնեք ձեր սեփականը',
              hideGrid: 'Թաքցնել պատկերի ցանցը',
              showGrid: 'Show ույց տալ պատկերի ցանց',
              selectImage: "Ընտրեք ֆոնային պատկեր",
              noImage: "Պատկեր չկա",
              newImageWarning: "Նոր պատկեր ընտրելով ձեր վերբեռնված պատկերը կհեռացվի: Ցանկանու՞մ եք շարունակել։",
              warningConfirm: "Այո, ես նոր կերպար եմ ուզում",
              warningCancel: "Չէ, պահպանիր իմ կերպարը",
              uploadError: "Ներողություն, ձեր ֆոնային պատկերը թարմացնելիս սխալ տեղի ունեցավ",
            },
            planVideoPage: {
              sectionHeader: "Իմ պլանավորման տեսանյութը",
              subHeader: "Ստեղծեք տեսանյութ՝ ձեր ծրագիրը ներդրողներին ներկայացնելու համար",
              saveAlertText: "Շնորհակալություն ձեր պլանի տեսանյութը վերբեռնելու համար",
              explainTextGreeting: "Բարև, {{firstName}}",
              explainTextP1: `				
                Այս տարածքը ձեզ համար է, որպեսզի վերբեռնեք տեսանյութ, որը կներկայացնեն ձեր մասնակիցները				
                 տեսեք, հենց որ նրանք մուտք գործեն Center On Me՝ ձեր ծրագրին նպաստելու համար:				
                 Այս տեսանյութը ձեզ համար հնարավորություն է բացատրելու, թե ինչու եք դա ստեղծում				
                 պլանավորեք և մասնակիցներին տվեք ձեր պատկերացրած ապագայի օրինակ				
                ինքներդ ձեզ համար՝ այս պլանը ստեղծելիս։'`,
              explainTextP2: `				
                 Ձայնագրեք տեսանյութ ձեր հեռախոսում, համակարգչում կամ պլանշետում և վերբեռնեք այն				
                 այստեղ. Ձեր տեսանյութը պետք է տաս (10) րոպեից պակաս լինի: Խորհուրդների համար				
                 այն մասին, թե ինչպես պատրաստել ձեր տեսանյութը, խնդրում ենք դիտել տեսանյութի օրինակը կամ ընտրել				
                        "Խորհուրդներ իմ տեսանյութը ստեղծելու համար":`,
              explainTextEnding: "Շնորհակալություն,",
              explainTextSignature: "Կենտրոն իմ վրա",
              tipsButton: "Իմ տեսանյութը ստեղծելու խորհուրդներ",
              exampleVideoTitle: "Օրինակ տեսանյութ",
              alvaVideoTitle: "Ալվա օրինակ",
              uploadButton: "Վերբեռնեք ձեր տեսանյութը",
              removeButton: "Հեռացնել իմ տեսանյութը",
              videoError: "Ձեր զննարկիչը չի աջակցում տեսանյութի պիտակին",
              lengthError: "Սխալ․ տեսանյութի առավելագույն տևողությունը 10 րոպե է",
              tips: {
                dialogTitle: "Ձեր տեսանյութը ստեղծելու խորհուրդներ",
                tipsTitle: "Ձեր տեսանյութը ձայնագրելու և վերբեռնելու խորհուրդներ.",
                tipOne: "Ձայնագրեք ձեր տեսանյութը լուսավորության մեջ, որտեղ երևում է ձեր ամբողջ դեմքը",
                tipTwo: "Մտածեք այն մասին, թե ինչ կցանկանայիք ասել նախքան ռեկորդը հասնելը",
                tipThree: "Տեսանյութը պահեք տասը (10) րոպեից պակաս",
                tipFour:
                  "Եթե ձեր տեսանյութը վերբեռնելուց հետո այն դիտելու հետ կապված խնդիրներ ունեք, խնդրում ենք էլփոստով ուղարկել centeronme@mainsl.com,	  և տրամադրեք ձեր օգտանունը (էլ․ հասցեն) և ձեր վերբեռնած վիդեո ֆայլի անունը (օրինակ՝ Myplanvideo.mov)",
                examplesTitle: "Օրինակ հարցերի պատասխաններ ձեր տեսանյութում.",
                exampleOne: "Ինչո՞ւ եք մարդկանց հրավիրում ձեր ծրագրում ներդրում ունենալու համար",
                exampleTwo: "Ի՞նչ եք ակնկալում իրականացնել ձեր ծրագրով",
                exampleThree: "Ի՞նչ եք ուզում պլանավորել",
                exampleFour: "Ինչպե՞ս կարող են ձեր ներդրողները աջակցել ձեզ ձեր պլանը ստեղծելու հարցում",
              },
              contributor: {
                sectionHeader: "Նրանց պլանավորման տեսանյութը",
                subHeader: "",
                noVideo: "Ծրագրի սեփականատերը պլանի տեսանյութ չի ավելացրել",
              },
            },
            knowledgeCenter: {
              pageTitle: 'Գիտելիքի կենտրոն',
              subTitle: 'Սեղմեք հղման վրա՝ մեր կայքի օգտագործման մասին ավելին իմանալու համար',
              videoTab: 'Տեսանյութեր',
              faqTab: 'ՀՏՀ',
              contactTab: 'Կապ մեզ հետ',
              videos: {
                gettingStartedTitle: 'Սկսել',
                profileSettings: 'Պրոֆիլի կարգավորումներ',
                landingPageAndBadges: 'Վայրէջքի էջ և կրծքանշաններ',
                contributorsTitle: 'Աջակցողներ',
                addingContributors: 'Աջակցողների ավելացում',
                managingContributors: 'Կառավարող ներդրողներ',
                howToContribute: 'Ինչպես նպաստել',
                gettingToKnowYouTitle: 'Ծանոթանալ քեզ',
                likeAndAdmire: 'Հավանել և հիանալ',
                people: 'Մարդիկ իմ կյանքում',
                favorites: 'Իմ սիրելի իրերը',
                thingsIDo: 'Գործեր, որոնք ես անում եմ զվարճանքի համար',
                routines: 'Իմ առօրյան',
                historyMap: 'Պատմության քարտեզ',
                travelPlan: 'Ճամփորդություն',
                happeningNowTitle: 'Ինչ է կատարվում հիմա',
                healthAndSafety: 'Առողջություն և անվտանգություն',
                livingInHome: 'Ապրելով իմ տանը',
                work: 'Աշխատանք',
                school: 'Դպրոց',
                startPlanningTitle: 'Եկեք սկսենք պլանավորել',
                workGoals: 'Աշխատանքային նպատակներ',
                healthGoals: 'Առողջության նպատակներ',
                livingGoals: 'Կենդանի նպատակներ',
                travelGoals: 'Ճամփորդության նպատակներ',
                funGoals: 'Զվարճալի գոլեր',
                relationshipGoals: 'Հարաբերությունների նպատակներ',
                planSummaryTitle: 'Պլանի ամփոփում',
                webVersion: 'Վեբ-տարբերակ և արտահանում PDF',
                dialogTitle: 'Ուսումնական տեսանյութ',
              },
              faq: {
                pageTitle: 'Հաճախակի տրվող հարցեր',
                usingTheKnowledgeCenter: 'Օգտագործելով Գիտելիքի կենտրոնը',
                passwordAndUsername: 'Գաղտնաբառ և օգտանուն',
                managingPlans: 'Կառավարման պլաններ',
                planOwnersAndContributors: 'Ծրագրի սեփականատերեր և ներդրողներ',
                usingTheSite: 'Կայքի օգտագործումը',
                accessingSupport: 'Աջակցություն մուտք գործելը',
                registration: 'Գրանցում և բաժանորդագրություն',
                howToUse: 'Ինչպե՞ս կարող եմ օգտագործել գիտելիքի կենտրոնը:',
                whoToContact: 'Ո՞ւմ կարող եմ կապվել, եթե հարցեր ունեմ, որոնց մասին նշված չէ տեսանյութում:',
                videoLanguage: 'Կարո՞ղ եմ այս տեսանյութերը դիտել անգլերենից այլ լեզվով:',
                findVideo: 'Ինչպե՞ս կարող եմ գտնել այն տեսանյութը, որը փնտրում եմ:',
                passwordRequirements: 'Որո՞նք են գաղտնաբառի պահանջները:',
                changePassword: 'Ինչպե՞ս կարող եմ փոխել իմ գաղտնաբառը:',
                changeUsername: 'Ի՞նչ անել, եթե ես ուզում եմ փոխել իմ մուտքի / էլփոստի հասցեն:',
                howManyPlans: 'Քանի՞ պլան կարող եմ ստեղծել, երբ գրանցվեմ:',
                sharePlan: 'Կարո՞ղ եմ որևէ մեկի հետ կիսվել իմ պլանով: Իմ պլանը հարթակում էլեկտրոնային փոստով ուղարկելու միջոց կա՞:',
                deletePlan: 'Ի՞նչ անել, եթե պատահաբար ջնջել եմ պլանը:',
                whatIsPlanOwner: 'Ով / ինչ է «պլանի սեփականատերը»',
                whatIsContributor: 'Ո՞վ / ի՞նչ է «Աջակցողը»:',
                contributorLimit: 'Կա՞ սահմանափակում այն մասնակիցներին, որոնք կարող եմ ավելացնել իմ ծրագրին:',
                approveComment: 'Եթե ես հաստատեմ մասնակցի մեկնաբանությունը, նրանք կտեղեկացվե՞ն:',
                denyComment: 'Եթե ես մերժեմ մասնակցի մեկնաբանությունը, նրանք կտեղեկացվե՞ն:',
                updateProfile: 'Կարո՞ղ եմ ցանկացած ժամանակ թարմացնել իմ պրոֆիլի տվյալները:',
                howManyActivities: 'Քանի՞ գործունեություն կարող եմ ավելացնել յուրաքանչյուր բաժնի տակ:',
                listSomeoneAsResponsible: 'Եթե ես ինչ-որ մեկին նշեմ որպես նպատակին հասնելու պատասխանատու, արդյոք նա կծանուցվի՞:',
                editActivity: 'Ինչպե՞ս կարող եմ խմբագրել գործունեությունը:',
                deleteActivity: 'Ինչպե՞ս ջնջել գործունեությունը:',
                accessibilityFeatures: 'Մատչելիության ի՞նչ հնարավորություններ է առաջարկում Պլատֆորմը:',
                forgetToSave: 'Ի՞նչ կլինի, եթե մոռանամ պահպանել այն, ինչի վրա աշխատում եմ:',
                useOnTablet: 'Կարո՞ղ եմ աշխատել պլանշետիս վրա՝ օգտագործելով իմ պլանշետը:',
                useOnPhone: 'Կարո՞ղ եմ աշխատել իմ ծրագրի վրա՝ օգտագործելով իմ հեռախոսը:',
                phoneApp: 'Կա՞ հեռախոսի հավելված, որը պետք է ներբեռնեմ:',
                needInternet: 'Պե՞տք է արդյոք ունենալ ինտերնետ հասանելիություն՝ իմ պլանի վրա աշխատելու համար: Կա՞ անցանց ռեժիմ:',
                whatAreBadges: 'Որոնք են կրծքանշանները:',
                helpWithPlan: 'Ո՞ւմ հետ կապվեմ, եթե իմ ծրագրի հետ կապված օգնության կարիք ունեմ:',
                issuesAccessingPlan: 'Ո՞ւմ հետ կապվեմ, եթե խնդիրներ ունեմ իմ ծրագրին մուտք գործելու հետ կապված:',
                issueWithPlanVideo: 'Ո՞ւմ հետ կապվեմ, եթե չկարողանամ վերբեռնել իմ պլանի տեսանյութը:',
                forgotUsername: 'Ի՞նչ անել, եթե մոռանամ իմ մուտքի օգտանունը: Ո՞ւմ հետ կապվեմ:',
                wasRegistrationSuccessful: 'Ինչպե՞ս կարող եմ իմանալ, որ գրանցումը հաջող է եղել:',
                renewPlan: 'Ինչպե՞ս իմանամ, որ պետք է թարմացնեմ իմ ծրագիրը:',
                howToCancel: 'Ինչպե՞ս կարող եմ չեղարկել իմ բաժանորդագրությունը:',
              },
              faqAnswers: {
                howToUse: "Սեղմեք այն բաժնի կամ հղման վրա, որի մասին կցանկանայիք ավելին իմանալ: Դուք կտեղափոխվեք առանձին էջ և կկարողանաք դիտել տեսանյութ, որը ձեզ կուղեկցի կայքի այդ հատվածով:",
                whoToContact: "Խնդրում ենք ցանկացած պահի կապվել մեզ հետ՝ օգտագործելով `Կապ մեզ հետ» ներդիրը: Այդ ներդիրում դուք կգտնեք լրացման ձև, որը մեզ էլեկտրոնային նամակ կուղարկի: Ձեր էլ.փոստում խնդրում ենք ներառել այն էջը և տեսանյութը, որի վերաբերյալ հարցեր ունեք: Մենք ձեզ շուտով կպատասխանենք",
                videoLanguage: "Առայժմ այս տեսանյութերը հասանելի են միայն անգլերենով։ Բոլոր տեսանյութերի համար տրամադրվել են փակ ենթագրեր:",
                findVideo: "Խնդրում ենք մուտքագրեք ձեր փնտրած էջը որոնման տողում, և տեսանյութի հղումը կհայտնվի: Տեսանյութերը կազմակերպված են էջի անունով։ Եթե չեք կարողանում գտնել ձեր փնտրած տեսանյութը, դիմեք մեզ՝ օգտագործելով `Կապ մեզ հետ» ներդիրը։",
                passwordRequirements: "Ձեր գաղտնաբառը պետք է ներառի` փոքրատառ, մեծատառ, թիվ, հատուկ նիշ և բաղկացած լինի առնվազն 8 նիշից:",
                changePassword: "Դուք ցանկացած պահի կարող եք փոխել ձեր գաղտնաբառը՝ անցնելով ձեր պրոֆիլի կարգավորում և ընտրելով `Փոխել գաղտնաբառը» կոճակը։",
                changeUsername: `Դուք հնարավորություն չեք ունենա փոխելու ձեր էլ.փոստի հասցեն»:`,
                howManyPlans: "Չկա սահմանափակում, թե քանի պլան կարող եք ստեղծել: Այնուամենայնիվ, այն տեղեկատվությունը, որը դուք մուտքագրում եք պլանների մեջ, ներառյալ պլանի ներդրողները, չեն փոխանցվի մի պլանից մյուսը»:",
                sharePlan: "Ծրագրի սեփականատերը կարող է ցանկացած ժամանակ կիսվել իր պլանով ցանկացածի հետ: Պլանի ամփոփման էջի վերևի աջ անկյունում կա կոճակ, որը կարդում է՝ `Արտահանել PDF»: Երբ այդ կոճակը սեղմվի, ձեր պլանը կներբեռնվի և կարող է տպագրվել կամ էլփոստով ուղարկվել ձեր ընտրած ցանկացած անձի:",
                deletePlan: "Երբ դուք փորձում եք ջնջել պլանը, `Center On Me»-ը հուշում է տրամադրելու՝ համոզվելու, որ դուք իսկապես ցանկանում եք ջնջել ձեր պլանը: Եթե դուք դեռ ընտրում եք ջնջել ձեր պլանը, ոչինչ հնարավոր չէ անել, և դուք պետք է նորից ստեղծեք ձեր ծրագիրը հենց սկզբից»:",
                whatIsPlanOwner: "Պլանի սեփականատերը» պլանի կենտրոնական անձն է, այն անձը, ով ստեղծում է ծրագիրը: Ծրագրի սեփականատերը պետք է մշտապես լինի այս պլանի ուշադրության կենտրոնում»:",
                whatIsContributor: "Անձնագիրն այն անձն է, ում `Ծրագրի սեփականատերը» հրավիրել է ներդրում ունենալ իր ծրագրին: Ծրագրի սեփականատերը լիովին վերահսկում է իր ծրագրի այն մասը, որը մասնակիցը կարող է դիտել և/կամ մեկնաբանել: Բոլոր ներդրողների մեկնաբանությունները պետք է վերանայվեն և հաստատվեն նախքան պլանում ներառվելը:",
                contributorLimit: "Չկա սահմանափակում, թե քանի մասնակից կարող եք ունենալ: Ձեր ստեղծած յուրաքանչյուր պլանի համար ձեզ հարկավոր է յուրաքանչյուր ներդրողին առանձին ավելացնել:",
                approveComment: "Ոչ, մասնակիցները չեն ծանուցվի, երբ մեկնաբանությունը հաստատվի»:",
                denyComment: "Ոչ, մասնակիցները չեն ծանուցվի, երբ մեկնաբանությունը մերժվի»:",
                updateProfile: "Դուք կարող եք ցանկացած ժամանակ թարմացնել ձեր պրոֆիլի տվյալները՝ անցնելով պրոֆիլի կարգավորումներ: Պրոֆիլի կարգավորումների շրջանակներում դուք հնարավորություն կունենաք փոխել ձեր անունը և մանրամասները, ձեր պրոֆիլի նկարը, ձեր ֆոնը և ձեր գունային գունապնակը:",
                howManyActivities: "Գործողությունների կամ նպատակների քանակի սահմանափակում չկա, որը կարող եք ավելացնել յուրաքանչյուր բաժնի տակ»:",
                listSomeoneAsResponsible: "Նրանք չեն ծանուցվի, եթե որևէ մեկին նշեք որպես նպատակին հասնելու պատասխանատու: Եթե պլանի սեփականատերը ցանկանում է աջակցություն ունենալ նպատակի համար, ապա նա պետք է շփվի այդ անձի հետ և կիսվի իր պլանով, եթե իրեն հարմար է դա անել:",
                editActivity: "Դուք կարող եք ցանկացած պահի խմբագրել գործունեությունը կամ նպատակը՝ սեղմելով քարտի վրա: Ձևը կբացվի, և ցանկացած տեղեկություն կարող է փոփոխվել կամ թարմացվել:",
                deleteActivity: "Դուք կարող եք ցանկացած պահի ջնջել գործունեությունը կամ նպատակը՝ սեղմելով քարտի վրա: Ձևը կբացվի, և նպատակը կարող է ջնջվել՝ ոլորելով մինչև ներքև և ընտրելով կարմիր `Ջնջել» կոճակը:",
                accessibilityFeatures: "Center On Me-ն օգտագործում է `Userway»-ը՝ կայքի ներսում մատչելիության հնարավորություններ առաջարկելու համար: Մատչելիության որոշ գործառույթներ են փոխում հակադրությունը, տառաչափը, կուրսորի ուղղորդումը և տեքստի կամ տարածության հավասարեցումը: Դուք կարող եք ընտրել ձեր հնարավորությունները՝ ընտրելով կլոր `Անձ» պատկերակը, որն առկա է ձախ կողմում գտնվող յուրաքանչյուր էջում:",
                forgetToSave: "Շատ կարևոր է սեղմել `Save»-ը, քանի որ դուք տարբեր էջեր եք ստեղծում կայքի ներսում: Ինֆորմացիան ինքնաբերաբար չի պահպանվի:",
                useOnTablet: "Այո! `Centre On Me» կարելի է մուտք գործել պլանշետից կամ խելացի հեռախոսից:",
                useOnPhone: "Այո! `Center On Me» կարելի է մուտք գործել Smart Phone-ից: Կայքը ստեղծվել է բջջային սարքերի համար պատասխանատու լինելու համար, ուստի որոշ տվյալներ կարող են այնքան հեշտ ընթեռնելի չլինել, որքան պլանշետում կամ ձեր համակարգչից օգտվելիս:",
                phoneApp: "Ոչ, `Center On Me»-ը հավելված չունի: Դուք կարող եք մուտք գործել Center On Me ձեր խելացի հեռախոսից՝ բացելով ձեր վեբ բրաուզերի հավելվածը և այցելելով www.centeronme.com:",
                needInternet: "Այո, `Center On Me»-ը պահանջում է, որ ինտերնետը կարողանա աշխատել պլանի վրա: Center On Me-ի անցանց ռեժիմ չկա:",
                whatAreBadges: "Բեյջերը օգտատիրոջ և `Center On Me»-ի համար միջոց են՝ հետևելու ձեր առաջընթացին կայքում: Դուք վաստակում եք կրծքանշան յուրաքանչյուր էջ լրացնելիս, և կրծքանշանները կարող են դիտվել վայրէջքի էջերում: Կտտացրեք կրծքանշաններին զվարճալի անակնկալի համար",
                helpWithPlan: "Եթե աջակցության կարիք ունեք, այցելեք `Կապ մեզ հետ» ներդիրը գիտելիքի կենտրոնում: Նամակ կուղարկվի Center On Me թիմին, և մենք շուտով կպատասխանենք:",
                issuesAccessingPlan: "Եթե աջակցության կարիք ունեք, այցելեք `Կապ մեզ հետ» ներդիրը գիտելիքի կենտրոնում: Նամակ կուղարկվի Center On Me թիմին, և մենք շուտով կպատասխանենք:",
                issueWithPlanVideo: "Նախ, ստուգեք տեսանյութի վերբեռնման խորհուրդները՝ համոզվելու համար, որ համապատասխանում եք տեսանյութի պահանջներին: Եթե դեռ աջակցության կարիք ունեք, այցելեք `Կապ մեզ հետ» ներդիրը գիտելիքի կենտրոնում: Նամակ կուղարկվի Center On Me թիմին, և մենք շուտով կպատասխանենք:",
                forgotUsername: "Ձեր օգտանունը ձեր էլ.փոստի հասցեն է: Եթե աջակցության կարիք ունեք, այցելեք `Կապ մեզ հետ» ներդիրը գիտելիքի կենտրոնում: Նամակ կուղարկվի Center On Me թիմին, և մենք շուտով կպատասխանենք:",
                wasRegistrationSuccessful: "Եթե ձեր վճարումը հաջող է եղել, դուք էլփոստով անդորրագիր կստանաք ձեր կապված PayPal հաշվին: Դուք նաև կգտնեք անդորրագիր ձեր PayPal հավելվածում: Ստորև տե՛ս վճարման էլեկտրոնային անդորրագրի օրինակը:",
                renewPlan: "Օգտատերերը կստանան էլփոստի հիշեցումներ 3 ամսում, 6 ամսում և 10 ամսում` օգտատիրոջը հիշեցնելու, թե երբ է ավարտվել ձեր բաժանորդագրությունը, և կտրամադրվեն հրահանգներ, թե ինչպես թարմացնել ձեր պլանը»:",
                howToCancel: "Բաժանորդագրությունը գնվում է մեկ տարով։ Դուք չեք կարող չեղարկել մեկ տարվա բաժանորդագրությունը: Ձեզ նամակ կուղարկվի, երբ ձեր պլանը թարմացնելու ժամանակը գա, և դուք կարող եք ընտրել չերկարաձգել ձեր պլանն այդ պահին:",
              },
            },
          },
          lockedFeatures: {
            photoLock: 'Նկարները վերբեռնելու համար անհրաժեշտ է բաժանորդագրություն',
            videoLock: 'Տեսանյութերը վերբեռնելու համար անհրաժեշտ է բաժանորդագրություն',
            pdfLock: 'PDF ներբեռնելու համար պահանջվող բաժանորդագրություն',
            sectionLock: 'Ընտրեք բաժանորդագրության տարբերակ `այս հատկությունը մուտք ունենալու համար:',
            sectionLockSaveMessage: 'Այս բաժինը չի կարող փոփոխվել դատական ​​օգտագործողների կողմից',
            createPlanLock: 'Ընտրեք բաժանորդագրության տարբերակ `բազմաթիվ ծրագրեր ստեղծելու համար',
            expiredLock: 'Ընտրեք բաժանորդագրության տարբերակ `այս հատկությունը մուտք ունենալու համար',
            expiredAlert:  'Ձեր բաժանորդագրությունն ավարտված է: Խնդրում ենք թարմացնել այս բաժինը դիտելու համար:',
          },
          permissions: {
            theContributor: "Աջակցողը",
            cannotView: "չի կարող դիտել",
            canView: "կարող է դիտել",
            theSection: "բաժինը",
            toTheSection: "հատվածին",
            and: "և",
            but: "բայց",
            cannotComment: "չի կարող մեկնաբանություններ ավելացնել",
            canComment: "կարող է մեկնաբանություններ ավելացնել",
            gtkyTitle: "Ծանոթանալ քեզ",
            happeningTitle: "Ինչ է կատարվում ձեր կյանքում",
            planningTitle: "Պլանավորում",
          },
          breadcrumbs: {
            planning: "Եկեք սկսենք պլանավորել",
            happening: "Տեղի է ունենում",
            gettingToKnowYou: "Ծանոթանալ քեզ",
            myRoutines: "Իմ առօրյան",
            myPeople: "Մարդիկ իմ կյանքում",
            likeAndAdmire: "Հավանել և հիանալ",
            work: "Աշխատանք",
            school: "Դպրոց",
            home: "տուն",
            fun: "Զվարճանք",
            travel: "Ճամփորդություն",
            health: "Առողջություն",
            relationships: "Հարաբերություններ",
            goal: "Իմ նպատակը",
            historyMap: "Պատմության քարտեզ",
            favoriteThings: "Իմ սիրելի իրերը",
            add: "Ավելացնել նոր",
            profile: "Անձնագիր",
            settings: "Կարգավորումներ",
            contributors: "Աջակցողներ և ծանուցումներ",
            thingsIDo: "Գործեր, որոնք ես անում եմ զվարճանքի համար",
            planningVideo: "Իմ պլանավորման տեսանյութը",
            planSummary: "Պլանի ամփոփում",
            changePassword: "Փոխել գաղտնաբառը",
            knowledgeBase: "Գիտելիքի կենտրոն",
            colorPalette: "Գունավոր գունապնակ",
            myActivity: "Իմ գործունեությունը",
          },
          userMenu: {
            allPlans: "Բոլոր պլանները",
            planDashboard: "Պլանի վահանակ",
            planSummary: "Պլանի ամփոփում",
            settings: "Կարգավորումներ",
            subscribe: "Բաժանորդագրվել",
            logout: "Դուրս գալ"
          },
          timeoutAlert: {
            attention: "Ուշադրություն",
            description: "Դուք պատրաստվում եք դուրս գալ համակարգից",
            stillPlanning: "Ես դեռ պլանավորում եմ:",
            logMeOut: "Դուրս գրիր ինձ",
            warningOnLogout: "Չպահված տվյալները կկորչեն",
          },
          allPlans: {
            pageTitle: "Ձեր ծրագրերի արագ հայացքը",
            subTitle: "Սկսելու համար ընտրեք մեկը",
            iContributeTo: "Պլաններ, որոնց ես նպաստում եմ",
            myPlans: "Իմ ծրագրերը",
            newContributeTo: "Նպաստել նոր ծրագրին",
            createNewPlan: "Ստեղծել նոր պլան",
            planOwner: "Պլանի սեփականատեր",
            planCreated: "Ստեղծված պլան",
            lastViewed: "Վերջին դիտում",
            planVideo: "Պլանային տեսանյութ",
            manageContributors: "Կառավարեք ներդրողներին և ծանուցումները",
            planSummary: "Պլանի ամփոփում",
            deletePlan: "Ջնջել պլանը",
            createPlanDialog: {
              dialogTitle: 'Ստեղծեք նոր ծրագիր',
              explainerText: 'Ընտրեք անուն, սկսելու ձեր նոր պլանի համար',
              inputLabel: 'Պլանի անուն',
              createMessage: 'Նոր պլանից սկսելը չի ​​տանի ձեր որեւէ աշխատանք կամ ներդրողներ նախորդ ծրագրերից',
            },
            contributeToPlanDialog: {
              dialogTitle: 'Նպաստել ծրագրին',
              explainerText: 'Մուտքագրեք ծրագրի սեփականատիրոջ կողմից ստացված կոդը',
              inputLabel: 'Մուտքի կոդ',
              errorMessage: 'Մուտքի ծածկագիրը որեւէ պլանավորված չէ: Խնդրում եմ կրկին փորձեք',
            }
          },
          deletePlan: {
            dialogTitle: "Ջնջել իմ ծրագիրը",
            explanation: "Դուք փորձում եք ջնջել ձեր պլանը:",
            confirmMessage: "Իսկապե՞ս ուզում եք ջնջել այս պլանը:",
            noteToDownload: "Նշում. Դուք կարող եք ներբեռնել և պահպանել ձեր ծրագիրը նախքան այն ջնջելը, քանի որ այն հնարավոր չէ վերականգնել:",
            downloadButton: "Ներբեռնեք Իմ ծրագիրը",
            keepPlanButton: "Պահպանիր իմ ծրագիրը",
            deleteButton: "Ջնջել իմ ծրագիրը",
            deleteSuccessMessage: "Ջնջված է",
          },
          planLandingPage: {
            videoTitle: "Բարի գալուստ ձեր Centre on Me Plan",
            gtky: "Ծանոթանալ քեզ",
            whatIsHappening: "Ինչ է կատարվում ձեր կյանքում",
            startPlanning: "Սկսեք պլանավորել",
            newBadge: "Նոր կրծքանշան",
            getStarted: "Սկսել",
            leftOff: "Այնտեղ, որտեղ դուք դադարեցիք",
            happeningButton: "Տեղի է ունենում",
            planningButton: "Նպատակների պլանավորում",
            recentBadges: "Վերջին վաստակած կրծքանշանները",
            gtkyNoCaps: "Ձեզ հետ ծանոթանալը",
            happeningShort: "Ինչ է կատարվում",
            planning: "Պլանավորում",
            noBadgeMessage: "Նշանակներ դեռ չկան: Սկսեք լրացնել բաժինները՝ կրծքանշաններ ստանալու համար",
            contributorGreeting: "Բարի գալուստ",
            contributorMessage: `Մենք տեսնում ենք, որ դուք միանում եք որպես մասնակից, ինչը նշանակում է, որ ինչ-որ մեկը կարծում է, որ դուք բավականին կարևոր եք: Դուք էական դեր եք խաղում 
              այս ներկայացմանը նպաստելիս: Մենք խնդրում ենք, որ դուք պահպանեք այս կայքի նպատակը և պլանի սեփականատիրոջը պահեք իր պլանի կենտրոնում՝ միաժամանակ օգտագործելով ձեր 
              ժամանակը հարուստ և բովանդակալից տեղեկատվություն ավելացնելու համար: Ծրագրին նպաստելիս մենք նաև խնդրում ենք, որ դուք ժամանակ հատկացնեք՝ խորհելու և յուրացնելու կոնկրետ 
              տեղեկություններ այն մասին, թե ով է այդ մարդը, ինչն է կարևոր նրա համար և ինչպես կարող եք օգնել նրան հասնելու իրենց հույսերին ու երազանքներին:
            `,
            contributorQuote: `«Յուրաքանչյուր մարդ ունի կարողություններ, կարողություններ և շնորհներ: Լավ կյանքով ապրելը կախված է նրանից, թե արդյոք այդ կարողությունները կարող են օգտագործվել, 
              արտահայտվել կարողությունները և շնորհվել նվերներ: Եթե դրանք լինեն, մարդը կգնահատվի, կզգա հզոր և լավ կապված շրջապատի մարդկանց հետ: դրանք, և անձի շուրջը գտնվող համայնքը 
              իրեն ավելի հզոր կզգա այն ներդրումների շնորհիվ, որոնք մարդը կատարում է»:
            `,
            quote: `«Ձեր նպատակները ճանապարհային քարտեզներն են, որոնք առաջնորդում են ձեզ և ցույց են տալիս, թե ինչ է հնարավոր ձեր կյանքի համար»: - Լես Բրաուն`,
            profileBadges: {
              addContributor: 'Նշանակ. Ավելացրեք ներդրող ձեր պլանին',
              colorPalette: 'Նշանակ. Ընտրեք գունային գունապնակ',
              approveContributor: 'Նշանակ. Հաստատել ներդրումը',
              planningVideo: 'Նշանակ. Ստեղծեք պլանավորման տեսանյութ',
              profilePicture: 'Նշանակ. Վերբեռնեք պրոֆիլի նկար',
              backgroundImage: 'Նշանակ․ վերբեռնեք նոր պաստառ',
              profileComplete: 'Նշանակ՝ պրոֆիլն ավարտված է:',
            }
          },
          badges: {
            badgeSectionTitle: "Վաստակած կրծքանշաններ",
            badgesPlanningExplanation:
              "Սկսեք վաստակել կրծքանշաններ՝ լրացնելով յուրաքանչյուր բաժին: Երբ ավարտեք մի հատվածը, ձեր կրծքանշանը կհայտնվի այստեղ	Եկեք մի քիչ զվարճանանք ձեր ապագայի համար պլանավորելիս",
            badgesHappeningExplanation:
              "Սկսեք վաստակել կրծքանշաններ՝ լրացնելով յուրաքանչյուր բաժին: Երբ ավարտեք մի հատվածը, ձեր կրծքանշանը կհայտնվի այստեղ		 Եկեք մի քիչ զվարճանանք՝ սովորելով, թե ինչ է կատարվում ձեր կյանքում",
            badgesGTKYExplanation:
              "Սկսեք վաստակել կրծքանշաններ՝ լրացնելով յուրաքանչյուր բաժին: Երբ ավարտեք մի հատվածը, ձեր կրծքանշանը կհայտնվի այստեղ: Եկեք մի քիչ զվարճանանք՝ ծանոթանալով ձեզ հետ։",
          },
          sections: {
            likeAndAdmire: "Հավանել և հիանալ",
            people: "Մարդիկ իմ կյանքում",
            historyMap: "Միջնաքարեր",
            favorites: "Սիրելի բաներ",
            travelPlan: "Ճամփորդություն",
            thingsIDo: "Այն, ինչ ես անում եմ զվարճանքի համար",
            dailyRoutines: "Առօրյա ռեժիմներ",
            weeklyRoutines: "Շաբաթական ռեժիմներ",
            routines: "Ռոուտիններ",
            happeningHome: "Հեփենինգի տուն",
            happeningWork: "Հեփենինգ աշխատանք",
            happeningSchool: "Հեփենինգ դպրոց",
            happeningHealth: "Առողջություն պատահող",
            planningHome: "Պլանավորման տուն",
            planningWork: "Պլանավորման աշխատանքներ",
            planningTravel: "Ճամփորդության պլանավորում",
            planningFun: "Պլանավորման զվարճանք",
            planningHealth: "Առողջության պլանավորում",
            planningRelationships: "Պլանավորման հարաբերություններ",
          },
          planningPage: {
            pageTitle: "Սկսենք պլանավորել",
            planningVideo: "Պլանավորման տեսանյութ",
            housing: "Բնակարան",
            work: "Աշխատանք",
            health: "Առողջություն",
            fun: "Զվարճանք",
            relationships: "Հարաբերություններ",
            travel: "Ճամփորդություն",
            addAnotherGoal: "Ավելացնել ևս մեկ նպատակ"
          },
          planningHealth: {
            sectionName: "Առողջության պլանավորում",
            sectionHeader: "Առողջության նպատակներ",
            subHeader: "Եկեք ստեղծենք առողջ ապագայի ծրագիր",
            videoTitle: "Առողջության նպատակներ - Օգտակար տեսանյութ",
            goalTitle: "Ի՞նչ կուզենայիք վերնագրել այս նպատակը",
            goalAroundHealth: "Ո՞րն է ձեր նպատակը առողջության շուրջ",
            workedOnThisGoal: "Դուք երբևէ աշխատել եք այս նպատակի վրա նախկինում",
            whatWorked: "Ի՞նչն աշխատեց, երբ աշխատում էիր այս նպատակի վրա",
            didntWork: "Ի՞նչը չստացվեց, երբ աշխատում էիր այս նպատակի վրա",
            healthTableHeader: "Երբ մտածում ես այս նպատակի մասին, ի՞նչ ես կարծում, որ պետք է...",
            doYouHaveSupport: "Կա՞ որևէ մեկը ձեր կյանքում, ով կարող է աջակցել ձեզ այս նպատակի համար",
            attachedPeopleMessage: "Խնդրում ենք ընտրել, թե ով կարող է աջակցել ձեզ",
            anyOneElseWhoCanSupport: "Կա՞ մեկ ուրիշը, ով կարող է օգնել ձեզ հասնել ձեր նպատակին և ինչու",
            goalCommit: "Ի՞նչ կարող եք պարտավորվել անել այս ապագան ստեղծելու համար",
            increase: "Աճ",
            decrease: "Նվազում",
            doMore: "Ավելն անել",
            doLess: "Ավել քիչ",
            increaseEx: "Օրինակ. Որքան ժամանակ եմ կենտրոնանում ինքս ինձ վրա",
            decreaseEx: "Օրինակ. Որքա՞ն ժամանակ եմ անցկացնում Facebook-ում",
            doMoreEx: "Օրինակ. ժամանակը, որը ես ծախսում եմ իմ շնչառության վրա",
            doLessEx: "Օրինակ՝ քաղցր սնունդ ուտելը",
            saveMessage: "Հրաշալի է, հաջողություն այս գոլում: Կցանկանա՞ք ավելացնել առողջության ևս մեկ նպատակ",
            deleteMessage: "Վստա՞հ եք, որ ցանկանում եք ջնջել այս աղյուսակի տողը",
            deleteConfirm: "Լավ, շարքը հանվել է",
            summary: {
              goalTitle: "Իմ նպատակը առողջության շուրջ",
              whatWorked: "Երբ ես նախկինում աշխատում էի այս նպատակի վրա, ահա թե ինչ ստացվեց",
              didntWork: "Երբ ես նախկինում աշխատում էի այս նպատակի վրա, ահա թե ինչ չստացվեց",
              healthTableHeader: "Երբ մտածում եմ իմ նպատակի մասին, կարծում եմ, որ կարող եմ անել հետևյալը ինձ օգնելու համար",
              doYouHaveSupport:
                "Իմ աջակցության շրջանակում գտնվող մարդիկ, ովքեր կարող են օգնել ինձ այս նպատակին հասնելու հարցում, հետևյալն են.",
              anyOneElseWhoCanSupport: "Այլ մարդիկ, որոնք, կարծում եմ, կարող են օգնել",
              goalCommit: "Ես կարող եմ պարտավորվել",
            },
          },
          planningRelationships: {
            sectionName: "Պլանավորման հարաբերություններ",
            sectionHeader: "Հարաբերությունների նպատակներ",
            subHeader: "Եկեք սկսենք կապեր ստեղծել մարդկանց հետ",
            newOrStrengthen:
              "Ցանկանու՞մ եք պլանավորել նոր հարաբերություններ, թե՞ կցանկանայիք ամրապնդել ներկայիս հարաբերությունները",
            saveMessage: "Հարաբերությունների հիանալի նպատակ է թվում: Կցանկանա՞ք ևս մեկ նպատակ ավելացնել",
            newText: "Նոր",
            strengthenText: "Ուժեղացնել",
            relationship: 'Հարաբերություն',
            strengthen: {
              videoTitle: "Ամրապնդեք հարաբերությունները - Օգտակար տեսանյութ",
              goal: "Ո՞ւմ հետ եք ուզում ամրապնդել հարաբերությունները",
              currentStatus: "Ինչպիսի՞ն է այս հարաբերությունների ներկա վիճակը",
              romantic: "Ռոմանտիկ",
              friend: "Ընկեր",
              professional: "Պրոֆեսիոնալ",
              family: "Ընտանիք",
              whatToStrengthen: "Ի՞նչ կասեք այս հարաբերությունների մասին, որոնք ցանկանում եք ամրապնդել",
              frequency: "Հաճախականություն",
              communication: "Հաղորդակցություն",
              desiredStatus: "Ցանկալի կարգավիճակ",
              howOften: "Որքա՞ն հաճախ եք շփվում այս մարդու հետ",
              howToStrengthen: "Ինչպես եք ուզում ուժեղացնել հաճախականությունը",
              strengthenCommunication: "Ինչ վերաբերում է հաղորդակցությանը, դուք ցանկանում եք ամրապնդել",
              commExample1: "Օրինակ. Ես փորձեցի ավելի հաճախ զանգահարել նրանց",
              commExample2: "Օրինակ. ես կարողացա խոսել նրանց հետ, երբ ուզում էի",
              commExample3: "Օրինակ. Ես զգացի, որ լսված և կապված եմ",
              commExample4: "Օրինակ. նրանք անընդհատ չէին պատասխանում",
              commTableHeader1: "Ի՞նչ եք փորձել ձեր հաղորդակցությունն ամրապնդելիս",
              statusTableHeader1: "Ի՞նչ եք փորձել անել այս հարաբերությունների այս կարգավիճակը փոխելու համար",
              tableHeader2: "Ի՞նչ սովորեցիր, երբ փորձեցիր դա",
              tableHeader3: "Ինչի՞ց էիր գոհ, երբ փորձեցիր դա",
              tableHeader4: "Ինչի՞ն եք մտահոգում",
              commDoNext: "Տեսեք, թե ինչ եք հենց նոր թվարկել, ի՞նչ կարող եք անել հետո",
              preferredStatus: "Ինչպիսի՞ն կուզենայիք լինել այս մարդու կարգավիճակը",
              theyAreCurrently: "Նրանք ներկայումս.",
              statusExample1: "Օրինակ. Ես փորձեցի նրանց ժամադրության հարցնել",
              statusExample2: "Նախ. Որ նրանք շատ բարի են, և ես հաճույք եմ ստանում նրանց հետ խոսելուց",
              statusExample3: "Օրինակ. Նրանք ասացին այո",
              statusExample4: "Օրինակ՝ այդ ժամանակվանից ես նրանց չեմ զանգել",
              finalDoNext: "Նայելով ձեր թվարկածին, ի՞նչ կարող եք անել հաջորդը",
              deleteRowConfirm: "Վստա՞հ եք, որ ցանկանում եք հեռացնել այս աղյուսակի տողը",
              deleteRowSuccess: "Լավ, շարքը հանվել է",
            },
            new: {
              videoTitle: "Պլանավորել նոր հարաբերություններ - Օգտակար տեսանյութ",
              relationshipType: "Ի՞նչ տեսակի հարաբերություններ եք փնտրում",
              characteristics: "Ի՞նչ հատկանիշներ եք փնտրում մեկի մեջ, ում հետ ցանկանում եք կապ հաստատել",
              where: "Որտե՞ղ կարող եք հանդիպել այս մարդուն",
              placeOfWorship: "Երկրպագության վայր",
              family: "Ընտանիք",
              friend: "Ընկեր",
              professional: "Պրոֆեսիոնալ",
              romantic: "Ռոմանտիկ",
              sharedInterests: "Ընդհանուր շահերի միջոցով",
              online: "Առցանց",
              school: "Դպրոց",
              shops: "Խանութներ",
              work: "Աշխատանք",
              whenYouThinkAbout: "Երբ մտածում ես նոր կապ ստեղծելու մասին",
              atChurch: "պաշտամունքի վայրում",
              throughFriend: "ընկերոջ միջոցով",
              throughInterests: "ընդհանուր շահերի միջոցով",
              atOnline: "առցանց",
              atSchool: "դպրոցում",
              atLocalShop: "տեղական խանութում",
              atWork: "աշխատանքի վայրում",
              likeToStart: ", որտեղի՞ց կուզենայիք սկսել",
              churchStart: "Երբ մտածում եք երկրպագության վայրում նոր կապ ստեղծելու մասին, որտեղի՞ց կցանկանայիք սկսել:",
              friendStart: "Երբ մտածում եք ընկերոջ միջոցով նոր կապ ստեղծելու մասին, որտեղի՞ց կցանկանայիք սկսել",
              interestsStart: "Երբ մտածում եք ընդհանուր հետաքրքրությունների միջոցով նոր կապ ստեղծելու մասին, որտեղի՞ց կցանկանայիք սկսել",
              onlineStart: "Երբ մտածում եք առցանց նոր կապ ստեղծելու մասին, որտեղի՞ց կցանկանայիք սկսել",
              schoolStart: "Երբ մտածում եք դպրոցում նոր կապ ստեղծելու մասին, որտեղի՞ց կցանկանայիք սկսել",
              shopsStart: "Երբ մտածում եք տեղական խանութում նոր կապ ստեղծելու մասին, որտեղի՞ց կցանկանայիք սկսել",
              workStart: "Երբ մտածում եք աշխատավայրում նոր կապ ստեղծելու մասին, որտեղի՞ց կցանկանայիք սկսել",
              howOften: "Որքա՞ն հաճախ եք ուզում միանալ այս ձևով",
            },
            characteristics: {
              organized: "Կազմակերպված",
              strong: "Ուժեղ",
              dreamer: "Երազող",
              determined: "Որոշվել է",
              smart: "Խելացի",
              artist: "Արտիստ",
              considerate: "Ուշադիր",
              helpful: "Օգտակար",
              creative: "Ստեղծագործական",
              thoughtful: "Մտածված",
              talented: "Տաղանդավոր",
              outgoing: "Արտագնա",
              leader: "Առաջնորդ",
              intelligent: "Խելացի",
              loving: "Սիրող",
              understanding: "Հասկանալով",
              spiritual: "Հոգևոր",
              joyful: "Ուրախալի",
              fixer: "Ամրագրող",
              liberal: "Լիբերալ",
              patient: "Հիվանդ",
              respectful: "Հարգալից",
              willing: "Պատրաստակամ",
              intuitive: "Ինտուիտիվ",
              gentle: "Նուրբ",
              generous: "Մեծահոգի",
              spontaneous: "Ինքնաբուխ",
              independent: "Անկախ",
              supportive: "Աջակցող",
              resourceful: "Հնարամիտ",
              experiential: "Փորձառական",
              fun: "Զվարճանք",
              funny: "Զվարճալի",
              excited: "Հուզված",
              accepting: "Ընդունելով",
              busy: "Զբաղված",
              pretty: "Գեղեցիկ",
              openMinded: "Բաց հայացքների տեր",
              hardWorking: "աշխատասեր",
              doer: "Կատարող",
              involved: "Ներգրավված",
              flexible: "Ճկուն",
              happy: "Երջանիկ",
              careful: "Զգույշ",
              hopeful: "Հուսադրող",
              tinkerer: "Թիթեռագործ",
              riskTaker: "Ռիսկ վերցնող",
              lucky: "Բախտավոր",
              active: "Ակտիվ",
              efficient: "Արդյունավետ",
              visionary: "Տեսլական",
              explorer: "Հետախույզ",
              responsible: "Պատասխանատու",
              driven: "Քշված",
              confident: "Վստահ",
              connector: "Միակցիչ",
              wise: "Իմաստուն",
              expressive: "Արտահայտիչ",
              sensitive: "Զգայուն",
              talkative: "Շատախոս",
              energized: "Լիցքավորված",
              giving: "Տալ",
              observant: "Դիտարկող",
              caring: "Հոգատար",
              peaceful: "Խաղաղ",
              cool: "Թույն",
              fashionable: "Նորաձև",
              actor: "Դերասան",
              writer: "Գրող",
              vibrant: "Կենսունակ",
              goodCook: "Լավ խոհարար",
              goodWithAnimals: "Լավ է կենդանիների հետ",
            },
            summary: {
              newGoalPdfTitle: "Նոր հարաբերություններ",
              strengthenGoalPdfTitle: "Նոր հարաբերություններ",
              relationshipType: "Հարաբերությունների այն տեսակն է, որը ես ուզում եմ ստեղծել",
              characteristics: "Ես ուզում եմ, որ այս մարդն ունենա հետևյալ հատկանիշները",
              where: "Ես կարող եմ հանդիպել այս մարդուն",
              whereToStart: "Այնտեղ, որտեղ ես ուզում եմ սկսել",
              howOften: "Որքա՞ն հաճախ",
              beingInPerson: 'Անձամբ լինելը',
              talkingMore: 'Ավելին խոսելը',
              facetiming: 'Երեսապատում',
              otherMeans: 'Այլ միջոցներ',
              comments: "Մեկնաբանություններ",
              strengthenGoal: "Այն մարդն է, ում հետ ես ուզում եմ ամրապնդել իմ հարաբերությունները",
              currentStatus: "Մեր հարաբերությունների ներկայիս կարգավիճակն է",
              whatToStrengthen: "Այն, ինչ ես ուզում եմ ուժեղացնել",
              strengthenBy: "Ուզում եմ ուժեղանալ…",
              strengthenWhy: "Ինչո՞ւ",
              whyAnswer: "Սա կօգնի ինձ հասնել իմ նպատակին",
              becoming: "Դառնալ",
            },
          },
          planningHome: {
            sectionName: "Պլանավորման տուն",
            sectionHeader: "Կենդանի նպատակներ",
            subHeader: "Եկեք տեսնենք, թե ինչն է ձեզ դուր գալիս կամ ինչ դուր չի գալիս ձեր ներկայիս կյանքի իրավիճակում",
            wantToLive: "Որտե՞ղ ես ուզում ապրել",
            idealHome: "Ինչպիսի՞ն է ձեր իդեալական տունը",
            homeAmenities: "Ի՞նչ հարմարություններ եք ուզում ունենալ ձեր տունը",
            homeCloseTo: "Ինչի՞ն եք ուզում, որ ձեր տունը մոտ լինի",
            liveWith: "Կա՞ մեկը, ում հետ կուզենայիք ապրել",
            roommateName: "ԱՀԿ?",
            roommateQuality: "Ինչո՞ւ եք կարծում, որ այս մարդու հետ ապրելը լավ կլիներ",
            askedRoommate: "Դուք հարցրե՞լ եք միասին ապրելու մասին",
            liveWithPets: "Ձեր ապագայում ընտանի կենդանիներ կա՞ն",
            petType: "Ի՞նչ տեսակի կենդանիներ",
            finalThoughts: "Կա՞ որևէ այլ բան, որը կցանկանայիք ձեր իդեալական տանը, որը մենք չենք քննարկել",
            whatILike: "Այն, ինչ ինձ դուր է գալիս",
            whatIDislike: "Այն, ինչ ես չեմ սիրում",
            saveMessage: "Հիանալի է, շնորհակալություն ձեր տան համար պլանավորելու համար: Կցանկանա՞ք ավելացնել ևս մեկը:",
            homeOptions: {
              singleStory: "Միայն պատմություն",
              apartment: "Բնակարան",
              duplex: "Դուպլեքս",
              mobileHome: "Շարժական տուն",
              tinyHome: "Փոքրիկ տուն",
            },
            amenities: {
              laundry: "Լվացք",
              internet: "Անլար ինտերնետ",
              recreation: "Հանգստի հաստատություն",
              garden: "Այգի",
              deck: "Տախտակամած",
              patio: "Բակում",
              land: "Հող",
              ramp: "Թեքահարթակ",
              pool: "Լողավազան",
              bigDoors: "Մեծ դռներ",
            },
            proximities: {
              shopping: "Գնումներ",
              bus: "Կանգառ",
              friends: "Ընկերներ",
              restaurants: "Ռեստորաններ",
              entertainment: "Ժամանց",
              publicTransit: "Հասարակական տրանսպորտ",
              family: "Ընտանիք",
            },
            summary: {
              wantToLive: "Այնտեղ, որտեղ ես ուզում եմ ապրել",
              wantToLiveAnswer: "{{place}} {{homeType}}-ում",
              homeAmenities: "Իմ իդեալական տանը ես ուզում եմ",
              otherAmenities: "Այլ բաներ իմ իդեալական տանը",
              homeCloseTo: "Ես ուզում եմ, որ իմ տունը մոտ լինի",
              roommateName: "Ես ուզում եմ ապրել",
              liveAlone: "Ես ուզում եմ մենակ ապրել",
              roommateBecause: "քանի որ {{why}}",
              roommateQuality: "Ինչո՞ւ եք կարծում, որ այս մարդու հետ ապրելը լավ կլիներ",
              petType: "Ընտանի կենդանիներ",
              finalThoughts: "Այլ բաներ իմ իդեալական տանը",
            },
          },
          planningFun: {
            sectionName: "Պլանավորման զվարճանք",
            sectionHeader: "Զվարճալի նպատակներ",
            subHeader: "Եկեք սկսենք զվարճանալ",
            goal: "Երբ մտածում ես ինչ-որ զվարճալի բան պլանավորելու մասին, ի՞նչ ես ուզում անել",
            goalWhy: "Ի՞նչն է սա գրավիչ դարձնում ձեզ համար: Կամ ինչո՞ւ եք ուզում դա անել",
            goalWhen: "Ե՞րբ կցանկանայիք դա անել",
            goalHowOften: "Որքա՞ն հաճախ կուզենայիք դա անել",
            otherPeople: "Կա՞ որևէ մեկը, ում հետ կցանկանայիք դա անել",
            otherPeopleWhoCanSupport: "Խնդրում ենք ընտրել, թե ով կարող է աջակցել ձեզ",
            otherPeopleWhereToFind:
              "Խնդրում ենք ընտրել խմբեր կամ վայրեր, որոնք կարող են օգնել ձեզ կապվել մարդկանց հետ, ովքեր ունեն ընդհանուր հետաքրքրություն",
            needToSave: "Դա անելու համար պետք է գումար խնայե՞լ",
            startedSaving: "Սկսել եք խնայել",
            amountToSave: "Որքա՞ն գումար է պետք դեռ խնայելու համար",
            attachedPeople: "լար",
            saveMessage: "Հնչում է զվարճալի: Կցանկանա՞ք ավելացնել ևս մեկ զվարճալի նպատակ",
            asap: "Հնարավորինս շուտ",
            sixMonths: "Հաջորդ վեց ամիսը",
            oneYear: "Վեց ամսից մինչև մեկ տարի",
            twoYears: "1-2 տարի",
            twoPlusYears: "2+ տարի",
            oneTime: "Մեկ անգամյա իրադարձություն",
            daily: "Ամենօրյա",
            weekly: "Շաբաթական",
            monthly: "Ամսական",
            quarterly: "Եռամսյակային",
            facebook: "Ֆեյսբուք",
            groupMeet: "Խմբային հանդիպում",
            newspaper: "Տեղական թերթ",
            coffeeShop: "Սուրճի տեղեկատու տախտակ",
            communityCenter: "Համայնքային կենտրոն",
            summary: {
              goal: "Երբ մտածում եմ ինչ-որ զվարճալի բան պլանավորելու մասին, ես ուզում եմ",
              goalWhy: "Սա ինձ գրավիչ է, քանի որ",
              goalWhen: "Ես կցանկանայի դա անել",
              goalHowOften: "և այնքան հաճախ, որքան",
              otherPeople: "Ես կցանկանայի դա անել",
              peopleInMyLife: "Իմ կյանքում այն մարդիկ, որոնց հետ ես ուզում եմ դա անել, նրանք են",
              actionSteps: "Գործողությունների քայլեր.",
              comments: "Մեկնաբանություններ",
              helpMeAchieve: "Սա կօգնի ինձ հասնել իմ նպատակին",
              research: "Այս գործունեությունը պլանավորելու ուղիների հետազոտման միջոցով",
              needToSave: "Պետք է խնայե՞լ այս ճանապարհորդության համար",
              needToSaveYes: "Այո, ես պետք է խնայեմ {{amount}}",
            },
          },
          planningTravel: {
            sectionName: "Ճամփորդության պլանավորում",
            subHeader: "Եկեք սկսենք պլանավորել ձեր հաջորդ արկածը",
            destination: "Որտե՞ղ կցանկանայիք ճանապարհորդել",
            travelTo: "Դուք նախկինում ճանապարհորդե՞լ եք դեպի {{location}}",
            like: "Ի՞նչն է ձեզ դուր եկել {{location}}-ում",
            appealingWhy: "Ի՞նչն է դարձնում {{location}}-ը ձեզ գրավիչ",
            travelWhen: "Ե՞րբ եք ուզում ճանապարհորդել դեպի {{location}}",
            withOthers: "Կա՞ որևէ մեկը, ում հետ կցանկանայիք ճանապարհորդել",
            travelWithWho: "Խնդրում ենք ընտրել, թե ում հետ կցանկանայիք ճանապարհորդել",
            toDoAtDestination: "Ի՞նչ ես ուզում անել, երբ այցելում ես",
            toSave: "Դա անելու համար պետք է գումար խնայե՞լ",
            startedToSave: "Սկսել եք խնայել",
            amountToSave: "Որքա՞ն գումար է պետք դեռ խնայելու համար",
            accommodations: "Ճամփորդելիս կացարանի կարիք ունե՞ք",
            needsAndWhy: "Կա՞ն բաներ, որոնց հետ դուք ցանկանում եք կամ պետք է ճանապարհորդեք: Եթե այո, ապա որո՞նք են դրանք",
            goal: "Ի՞նչ եք ուզում անվանել այս նպատակը",
            saveMessage: "Սպասվում է արկածներ: Կցանկանա՞ք ավելացնել ևս մեկ ճանապարհորդական նպատակ",
            summary: {
              destination: "Ես ուզում եմ ճանապարհորդել",
              appealingWhy: "Ինչու եմ ուզում ճանապարհորդել այստեղ",
              travelWhen: "Ես ուզում եմ ճանապարհորդել այստեղ",
              withOthers: "Ուզու՞մ եմ մարդկանց հետ ճամփորդել",
              withOthersYes: "Այո, ես ուզում եմ ճանապարհորդել {{people}}-ի հետ,",
              withOthersAlone: "Ես ուզում եմ մենակ ճանապարհորդել",
              toDoAtDestination: "Երբ ես այցելում եմ, ուզում եմ",
              accommodations: "Հյուրատեղերը, որոնք ինձ անհրաժեշտ կլինեն ճանապարհորդելիս, ներառում են",
              needsAndWhy: "Այլ բաներ, որոնք ես ուզում եմ բերել, երբ ես ճանապարհորդում եմ, ներառում են",
              needToSave: "Պետք է խնայե՞լ այս ճանապարհորդության համար",
              needToSaveYes: "Այո, ես պետք է խնայեմ {{amount}}",
            },
          },
          planningWork: {
            sectionName: "Պլանավորման աշխատանք",
            sectionHeader: "Աշխատանքային նպատակներ",
            addSubHeader: "Պլանավորենք նոր ապագա",
            whatILiked: "Այն, ինչ ինձ դուր եկավ",
            whatIDisliked: "Այն, ինչ ինձ դուր չեկավ",
            formSubHeader: "Եկեք տեսնենք, թե ինչն է ձեզ դուր եկել կամ դուր չի եկել ձեր աշխատանքային փորձը",
            workGoal: "Ո՞րն է ձեր աշխատանքի նպատակը",
            profession: "Ի՞նչն է ձեզ գրավում այս մասնագիտության մեջ",
            excites: "Ի՞նչն է ձեզ ամենաշատը հուզում այս կարիերայում",
            goalLookLike: "Երբ մտածում ես քո նպատակի մասին, ի՞նչ տեսք ունի այն",
            salary: "Որքա՞ն կուզենայիք վաստակել մեկ տարի",
            preferredWorkDays: "Որո՞նք են ձեր նախընտրած աշխատանքային օրերը",
            preferredWorkHours: "Որո՞նք են ձեր նախընտրած աշխատանքային ժամերը",
            haveNow: "Ի՞նչ ունես հիմա, որը քեզ կտանի դեպի քո ապագան",
            priorExperience: "Ի՞նչ փորձը կօգնի ձեզ հասնել ձեր ապագային",
            haveReference: "Այս մասնագիտությամբ մեկին ճանաչո՞ւմ եք",
            referenceName: "Ովքեր են նրանք?",
            referenceWhere: "Որտե՞ղ են նրանք աշխատում",
            inLifeSupport: "Ո՞վ կարող է ձեր կյանքում աջակցել ձեզ այս նպատակին հասնելու համար",
            support: "Կա՞ ուրիշ մեկը, ով կարող է օգնել ձեզ հասնել ձեր նպատակին",
            commit: "Ի՞նչ կարող եք պարտավորվել անել այս ապագան ստեղծելու համար",
            placeholderBudgetNeed: "Ներդիր այն, ինչի համար պետք է բյուջե",
            placeholderResponsible: "Մուտքագրեք անուն",
            placeholderHowOften: "Մուտքագրեք, թե որքան հաճախ է դա արվելու",
            placeholderImportant: "Ներդիր, թե ինչու է սա կարևոր",
            workPreferences: 'Իմ աշխատանքային նախասիրությունները',
            monday: "Երկուշաբթի",
            tuesday: "Երեքշաբթի",
            wednesday: "Չորեքշաբթի",
            thursday: "Հինգշաբթի",
            friday: "Ուրբաթ",
            saturday: "Շաբաթ",
            sunday: "Կիրակի",
            morning: "Առավոտ",
            afternoon: "Կեսօր",
            evening: "Երեկոներ",
            overnight: "Գիշերակաց",
            anytime: "Ցանկացած ժամանակ",
            saveMessage: "Հնչում է հիանալի աշխատանքային նպատակ: Կցանկանա՞ք ավելացնել ևս մեկ աշխատանքային նպատակ",
            budgetTable: {
              title: "Ինչի՞ համար է անհրաժեշտ գումար խնայել, որպեսզի օգնի հասնել ձեր նպատակին",
              actionStep: "Գործողությունների քայլ",
              saveFor: "Խնայեք գումար",
              saveMoneyFor: "Ինչի՞ համար է ինձ պետք գումար խնայել",
              responsible: "Ո՞վ է պատասխանատու",
              howOften: "Որքա՞ն հաճախ",
              important: "Ինչո՞ւ է սա կարևոր",
              why: "Ինչո՞ւ",
              contact: "Կապ",
              me: "Ես",
              theyCanSupport: "Նրանք կարող են ինձ աջակցել իմ նպատակին",
              thisWillHelpMe: "Սա կօգնի ինձ հասնել իմ նպատակին",
              deleteConfirm: "Վստա՞հ եք, որ ցանկանում եք հեռացնել բյուջեի այս տողը",
              deleteSuccess: "Լավ, շարքը հանվել է",
            },
            summary: {
              workGoal: "Իմ նպատակը աշխատանքի համար",
              profession: "Ինչն է ինձ գրավում այս մասնագիտության մեջ",
              excites: "Սրա մեջ ինձ ամենաշատը հուզում է",
              goalLookLike: "Երբ ես մտածում եմ իմ նպատակի մասին, կարծես թե",
              haveNow: "Ի՞նչը կօգնի ինձ հասնել իմ ապագային",
              preferredWorkDays: "Նախընտրելի աշխատանքային օրեր",
              preferredWorkHours: "Նախընտրելի աշխատանքային ժամեր",
              salary: "Ցանկալի աշխատավարձ",
              haveReference: "Այս մասնագիտությամբ մեկին ճանաչո՞ւմ եք",
              referenceName: "Ովքեր են նրանք?",
              referenceWhere: "Որտե՞ղ են նրանք աշխատում",
              inLifeSupport: "Ո՞վ կարող է ձեր կյանքում աջակցել ձեզ այս նպատակին հասնելու համար",
              support: "Կա՞ ուրիշ մեկը, ով կարող է օգնել ձեզ հասնել ձեր նպատակին",
              commit: "Ի՞նչ կարող եք պարտավորվել անել այս ապագան ստեղծելու համար",
            },
          },
          GTKYPage: {
            pageTitle: "Ճանաչել քեզ",
            GTKYVideo: "Ձեզ հետ ծանոթանալու տեսանյութ",
            likeAdmire: "Հավանել և հիանալ",
            peopleInMyLife: "Մարդիկ իմ կյանքում",
            thingsIDo: "Այն, ինչ ես սիրում եմ անել զվարճանքի համար",
            favoriteThings: "Իմ սիրելի իրերը",
            myRoutines: "Իմ առօրյան",
            historyMap: "Պատմության քարտեզ",
            travel: "Ճամփորդություն",
            viewComments: "Դիտել մեկնաբանությունները"
          },
          myRoutines: {
            sectionName: "Իմ առօրյան",
            subHeader: "Պատմեք մեզ բոլորիս այն մասին, թե ինչպիսին է ձեր առօրյան",
            videoTitle: "Իմ առօրյաները՝ օգտակար տեսանյութ",
            time: "Ժամանակը",
            noTime: "Ժամանակ չկա",
            day: "Օր",
            noDay: "Ոչ մի օր",
            dailyError: "Խնդրում ենք մուտքագրել ժամանակ և նկարագրություն",
            weeklyError: "Խնդրում ենք մուտքագրել օր և նկարագրություն",
            successMessage: "Շնորհակալություն: Ձեր առօրյան ավելացվել է",
            dailyRoutines: "Որո՞նք են ձեր առօրյան",
            weeklyRoutines: "Որո՞նք են ձեր շաբաթական առօրյանները կամ ծեսերը",
            dailyPlaceholder: "Ավելացրե՛ք մի բան, որ անում եք ամեն օր և երբ անեք դա",
            weeklyPlaceholder: "Ավելացրեք մի բան, որ անում եք ամեն շաբաթ",
            dayStickyTitle: "Իմ օրը",
            weekStickyTitle: "Իմ շաբաթը",
            deleteMessage: "Իսկապե՞ս ուզում եք հեռացնել այս ռեժիմը:",
            deleteSuccess: "Լավ, ռեժիմը հեռացվել է",
            contributor: {
              subHeader: "Պատմե՛ք մեզ բոլորիս այն մասին, թե ինչպիսին է նրանց առօրյան",
              dailyRoutines: "Որո՞նք են նրանց առօրյան",
              weeklyRoutines: "Որո՞նք են նրանց շաբաթական առօրյանները կամ ծեսերը",
              dailyPlaceholder: "Ավելացրեք ինչ-որ բան, որ նրանք անում են ամեն օր և երբ անում են դա",
              weeklyPlaceholder: "Ավելացրեք մի բան, որ նրանք անում են ամեն շաբաթ",
              dayStickyTitle: "{{name}}-ի օր",
              weekStickyTitle: "{{name}}-ի շաբաթ",
            },
            days: {
              mon: "Երկուշաբթի",
              tues: "Երեքշաբթի",
              wed: "չորեքշաբթի",
              thurs: "հինգշաբթի",
              fri: "Ուրբաթ",
              sat: "շաբաթ օրը",
              sun: "Կիրակի"
            }
          },
          thingsIDo: {
            sectionName: "Այն, ինչ ես սիրում եմ անել զվարճանքի համար",
            subHeader: "Ընտրեք ձեր գործունեությունը կամ ստեղծեք նորը",
            indoors: "Ներսում",
            outdoors: "Դրսում",
            frequencyCard: "Ինձ դուր է գալիս դա անել.",
            likeMostCard: "Այն, ինչ ինձ ամենաշատն է դուր գալիս այս գործունեության մեջ.",
            tellUsWhatYouDo: "Ասա մեզ բոլորին այն մասին, թե ինչ ես սիրում անել զվարճանալու համար",
            indoor: "Փակ",
            outdoor: "Բացօթյա",
            community: "Իմ համայնքում",
            whereToHaveFun: "Որտե՞ղ ես սիրում զվարճանալ",
            yourActivity: "Ինչով ես սիրում զբաղվել?",
            chosenActivity: "Ձեր ընտրած գործունեությունն էր",
            howOften: "Որքա՞ն հաճախ եք սիրում դա անել",
            aloneOrWithOthers: "Ձեզ դուր է գալիս այս գործունեությունը միայնակ անել, թե ուրիշների հետ",
            attachedPeople: "Ո՞ւմ հետ եք սիրում դա անել",
            addThingIDo: "Նոր գործունեություն",
            otherPeopleInvolved: "Կա՞ մեկ ուրիշը, ում հետ ցանկանում եք զբաղվել այս գործունեությունը",
            anyPhotos: "Ունե՞ք {{activity}} զվարճալի նկարներ, որոնք ցանկանում եք կիսվել",
            like: "Ի՞նչն է ձեզ ամենաշատը դուր գալիս {{activity}} մասին",
            youSelected: "Ձեր գործունեությունը:",
            saveMessage: "Ի՞նչ արկածախնդրություն է: Կցանկանայի՞ք ավելացնել ևս մեկը",
            deleteConfirm: "Վստա՞հ եք, որ ցանկանում եք ջնջել այս գործունեությունը",
            deleteSuccess: "Ձեր գործունեությունը հաջողությամբ ջնջվել է",
            daily: "Ամենօրյա",
            weekly: "Շաբաթական",
            monthly: "Ամսական",
            oncePerYear: "Տարին մեկ անգամ",
            fewTimes: "Տարին մի քանի անգամ",
            whenever: "Երբ ես կարող եմ",
            contributorQuestions: {
              frequencyCard: "Նրանք սիրում են դա անել",
              likeMostCard: "Այն, ինչ նրանց ամենաշատն է դուր գալիս այս գործունեության մեջ.",
              tellUsWhatYouDo: "Ասա մեզ բոլորիս այն մասին, թե ինչ են նրանք սիրում անել զվարճանալու համար",
              community: "Իմ համայնքում",
              whereToHaveFun: "Որտե՞ղ են նրանք սիրում զվարճանալ",
              yourActivity: "Ի՞նչ են նրանք սիրում անել",
              chosenActivity: "Իրենց ընտրած գործունեությունը եղել է",
              howOften: "Որքա՞ն հաճախ են նրանք սիրում դա անել",
              aloneOrWithOthers: "Նրանք սիրում են այս գործունեությունը միայնակ անել, թե ուրիշների հետ",
              attachedPeople: "Ո՞ւմ հետ են նրանք սիրում դա անել",
              otherPeopleInvolved: "Կա՞ մեկ ուրիշը, ում հետ ցանկանում են զբաղվել այս գործունեությունը",
              anyPhotos: "Նրանք ունե՞ն {{activity}} զվարճալի նկարներ, որոնք ցանկանում եք կիսվել",
              like: "Ի՞նչն է նրանց ամենաշատը դուր գալիս {{activity}} մասին",
              youSelected: "Նրանց գործունեությունը.",
              saveMessage: "",
            },
            activities: {
              reading: "Ընթերցանություն",
              writing: "Գրել",
              yoga: "Յոգա",
              pilates: "Պիլատես",
              videoGames: "Վիդեո Խաղեր",
              cardGames: "Թղթախաղեր",
              boardGames: "Սեղանի խաղեր",
              painting: "Նկարչություն",
              instruments: "Գործիքներ",
              drawing: "Նկարչություն",
              art: "Արվեստ",
              replicaModels: "Replica մոդելներ",
              movies: "Ֆիլմեր",
              tv: "Հեռուստացույց",
              movieTheater: "Կինոթատրոն",
              diningOut: "Ընթրել դրսում",
              yardSales: "Բակային վաճառք",
              antiquing: "Հնաոճություն",
              mall: "Գնալ դեպի Մոլ",
              shopping: "Գնումներ",
              sports: "Սպորտաձեւեր",
              walks: "Զբոսանքներ",
              birdWatching: "Թռչունների դիտում",
              hiking: "Քայլարշավ",
              fishing: "Ձկնորսություն",
              gardening: "Այգեգործություն",
              camping: "Արշավ",
              boating: "Թիավարություն",
              botany: "Բուսաբանություն"
            },
            summary: {
              activity: "Այն, ինչ ես սիրում եմ անել",
              howOften: "Ես սիրում եմ զբաղվել այս գործունեությունը",
              withWho: "Ես սիրում եմ դա անել",
              withWhoAlone: "Ես դա անում եմ",
              byMyself: "ինքս",
              like: "Ինձ ամենաշատն է դուր գալիս",
              buttonText: "Գնալ դեպի այն, ինչ ես սիրում եմ անել զվարճանքի համար",
            },
          },
          likeAndAdmire: {
            sectionName: "Հավանել և հիանալ",
            sectionTitle: "Գլուխ, սիրտ, ձեռք",
            subTitle: "Քաշեք և թողեք ձեր նվերները ձեր ընտրած կատեգորիայի մեջ կամ ստեղծեք ձեր սեփականը",
            videoTitle: "Հավանել և հիանալ՝ օգտակար տեսանյութ",
            filterAll: "Բոլոր բաները, որոնք հավանում և հիանում են (կանխադրված)",
            filterOthers: "Այն, ինչ սիրում և հիանում են ինձնով",
            filterMine: "Այն, ինչ ես սիրում և հիանում եմ ինքս իմ մեջ",
            resetAll: "Վերականգնել բոլորը",
            addNew: "Ավելացնել նոր բառ",
            saveMessage: "Հիանալի աշխատանք {{firstName}}, հրաշալի էր լսել քո մասին բոլոր հիանալի բաները:	",
            head: "Գլուխ",
            heart: "Սիրտ",
            hand: "Ձեռք",
            headExplanation: "Մտքի, ուղեղի կամ մտքի նվերներ, այն նվերները, որոնք դուք գիտեք",
            heartExplanation: "Նվերներ, որոնց հետ կապված եք կամ կրքոտ եք զգում",
            handExplanation: "Նվերներ, որոնք դուք կարող եք ֆիզիկապես տրամադրել գործով կամ կատարելով",
            resetMessage: "Իսկապե՞ս ուզում եք վերակայել բոլորը:",
            resetSuccess: "Լավ, տարրերը վերակայվել են",
            deleteMessage: "Իսկապե՞ս ուզում եք ջնջել այս նվերը:",
            deleteSuccess: "Նվերը հաջողությամբ ջնջվեց",
            contributor: {
              subTitle: "Քաշեք և թողեք նրանց նվերները ձեր ընտրած կատեգորիայի մեջ կամ ստեղծեք ձերը",
              filterOthers: "Այն, ինչ ուրիշները սիրում և հիանում են իրենցով",
              filterMine: "Այն, ինչ նրանք սիրում և հիանում են իրենց մեջ",
            },
            summary: {
              head: "Գլուխ",
              heart: "Սիրտ",
              hand: "Ձեռք",
            },
          },
          peopleInMyLife: {
            sectionName: "Մարդիկ իմ կյանքում",
            subHeader: "Ավելացրեք անձ կամ սեղմեք նրա անվան վրա՝ նրա մասին ավելի շատ մանրամասներ ավելացնելու համար",
            adaptionDisclaimer: "Հարաբերությունների քարտեզի հարմարեցում",
            acquaintance: "Ծանոթություն",
            acquaintances: "Ծանոթներ",
            add: "Ավելացնել անձ",
            addAsContributor: "Ավելացրու իմ ծրագրին որպես մասնակից",
            close: "Փակել",
            contributor: "Աջակցող",
            contributorAdditions: "Աջակցողի հավելումներ",
            coWorker: "Համագործակից",
            coWorkers: "Համագործակիցներ",
            deletePerson: "Դուք վստա՞հ եք, որ ցանկանում եք հեռացնել այս մարդուն ձեր կյանքի մարդկանցից",
            edit: "Խմբագրել անձը",
            family: "Ընտանիք",
            favoritePerson: "Ամսվա սիրելի մարդ",
            friend: "Ընկեր",
            friends: "Ընկերներ",
            howClose: "Որքա՞ն եք մտերիմ այս մարդու հետ",
            importantWhy: "Ինչու է այս մարդը կարևոր ձեզ համար",
            involvedInSupport: "Ներգրավված է իմ աջակցության մեջ",
            mapPlace: "Սա կորոշի նրանց տեղը ձեր 'Մարդիկ իմ կյանքում' քարտեզի վրա",
            notClose: "Ոչ մոտ",
            paidSupport: "Արդյո՞ք այս անձը վճարովի աջակցություն է ստանում",
            paidSupportShort: "Վճարովի աջակցություն",
            personIs: "Այս անձը…",
            relationshipToYou: "Քեզ հետ հարաբերություններ",
            removePerson: "Հեռացնել անձին",
            weAre: "Մենք ենք...",
            saveMessage: "Քո կյանքի բոլոր մարդիկ փրկվել են, մեծ գործ",
            me: 'Ես',
            contributorQuestions: {
              howClose: "Որքա՞ն մտերիմ են նրանք այս մարդու հետ",
              importantWhy: "Ինչու է այս մարդը կարևոր նրանց համար",
              mapPlace: "Սա կորոշի նրանց տեղը 'Մարդիկ իմ կյանքում' քարտեզի վրա",
              relationshipToYou: "Հարաբերություններ նրանց հետ",
              theyAre: "Նրանք են...",
              involvedInSupport: "Նրանց աջակցության մեջ ներգրավված",
            },
            dialog: {
              nameError: "Խնդրում ենք մուտքագրել անուն",
              personIsError: "Խնդրում եմ ընտրել տարբերակ",
              emailError: "Պետք է մուտքագրեք էլփոստի հասցե հնարավոր ներդրողների համար",
              emailValidError: "Խնդրում ենք մուտքագրել գործող էլեկտրոնային հասցե",
              sendInvitation: "Ուղարկել հրավեր",
            },
          },
          favoriteThings: {
            sectionName: "Իմ սիրելի իրերը",
            subHeader: "Ավելացրեք ձեր նախընտրած իրերը՝ նախ ընտրելով կատեգորիա",
            contributorSubHeader: "Խնդրում ենք ընտրել կատեգորիա, որպեսզի սկսեն ավելացնել իրենց սիրելի իրերը",
            viewOnlySubHeader: "Նայեք նրանց սիրելի իրերին",
            addDialogTitle: "Ավելացնել սիրելի բան",
            editDialogTitle: "Խմբագրել ձեր սիրելի բանը",
            categories: "Կատեգորիաներ",
            categoryList: "Կատեգորիաների ցանկ",
            myFavorites: "Իմ սիրելիները",
            theirFavorites: "Նրանց սիրելիները",
            place: "Տեղ",
            placeToEat: "Սնվելու տեղ",
            food: "Սնունդ",
            movie: "Ֆիլմ",
            book: "Գիրք",
            videoGame: "Վիդեո - խաղ",
            game: "Խաղ",
            sport: "Սպորտ",
            animal: "Կենդանի",
            music: "Երաժշտություն",
            memory: "Հիշողություն",
            other: "Այլ",
            placeLabel: "Ձեր ամենասիրելի վայրը",
            placeToEatLabel: "Քո սիրելի ուտելու վայրը",
            foodLabel: "Ձեր սիրելի ուտելիքը",
            movieLabel: "Ձեր սիրելի ֆիլմը",
            bookLabel: "Ձեր սիրելի գիրքը",
            videoGameLabel: "Ձեր սիրելի տեսախաղը",
            gameLabel: "Ձեր սիրելի խաղը",
            sportLabel: "Ձեր սիրելի սպորտը կամ սպորտային թիմը",
            animalLabel: "Ձեր սիրելի ընտանի կենդանուն կամ կենդանու տեսակը",
            musicLabel: "Ձեր սիրելի խումբը, երգը կամ ժանրը",
            memoryLabel: "Ձեր սիրելի հիշողություններից մեկը",
            otherLabel: "Ցանկացած սիրելի բան",
            chooseAView: "Ընտրեք տեսարան",
            allThings: "Բոլոր սիրելի բաները (կանխադրված)",
            thingsIAdded: "Սիրելի բաները, որոնք ես ավելացրել եմ",
            thingsOthersAdded: "Այն, ինչ ավելացրել են մյուսները",
            treasureChestAlt: "Սիրելի իրերի գանձատուփ",
            deleteConfirm: "Դուք վստա՞հ եք, որ ցանկանում եք ջնջել այս սիրելի իրը",
            deleteSuccess: "Սիրվածը ջնջվել է",
            saveAlertText: "Շնորհակալ եմ, {{name}}, շատ լավ է սովորել ձեր սիրած բաների մասին",
            plurals: {
              place: "Տեղերը",
              placeToEat: "Ուտելու վայրեր",
              movie: "Ֆիլմեր",
              book: "Գրքեր",
              videoGame: "Վիդեո Խաղեր",
              game: "Խաղեր",
              sport: "Սպորտաձեւեր",
              animal: "Կենդանիներ",
              memory: "Հիշողություններ",
            }
          },
          travelPlan: {
            sectionName: "Ճամփորդություն",
            sectionHeader: "Ճամփորդություն",
            subHeader: "Կիսեք ձեր սիրած վայրը ճամփորդելու կամ այնտեղ, որտեղ իսկապես ցանկանում եք գնալ",
            travelLocally: "Սիրու՞մ եք ճանապարհորդել Միացյալ Նահանգներում, թե՞ միջազգային",
            local: "Միացյալ Նահանգների ներսում",
            international: "Միջազգային",
            frequencyCard: "Ինձ դուր է գալիս դա անել.",
            likeMostCard: "Այն, ինչ ինձ ամենից շատ դուր է գալիս այս վայրի մասին.",
            pageSubHeader: "Ասա մեզ, թե որտեղ ես սիրում ճանապարհորդել",
            travelWhere: "Որտե՞ղ եք սիրում ճանապարհորդել",
            likeMost: "Ի՞նչն է ձեզ ամենաշատը դուր գալիս դեպի {{location}} ճանապարհորդելիս",
            likeToTravel: "Ինչպե՞ս եք սիրում ճանապարհորդել",
            alone: "Դուք սիրում եք դա անել միայնակ, թե ուրիշների հետ",
            howOften: "Որքա՞ն հաճախ եք սիրում ճանապարհորդել այստեղ",
            anyoneElse: "Կա՞ ուրիշ մեկը, ում հետ կցանկանայիք ճանապարհորդել",
            anyPictures: "Ունե՞ք {{location}}-ի զվարճալի նկարներ, որոնք ցանկանում եք կիսվել",
            deleteMessage: "Վստա՞հ եք, որ ցանկանում եք ջնջել այս տեղադրությունը",
            addTravelPlan: "Նոր ճանապարհորդական ուղղություն",
            addTravelContributor: "Ավելացրեք ևս մեկ վայր, որը կարծում եք, որ նրանք կցանկանային ճանապարհորդել",
            attachedPeople: "Ո՞ւմ հետ եք սիրում ճանապարհորդել",
            boat: "Նավակ",
            bus: "Ավտոբուս",
            car: "Ավտոմեքենա",
            plane: "Ինքնաթիռ",
            train: "Գնացք",
            saveText: "Ի՞նչ արկածախնդրություն է: Կցանկանայի՞ք ավելացնել ևս մեկը",
            deleteConfirm: "Վստա՞հ եք, որ ցանկանում եք ջնջել այս գործունեությունը",
            deleteSuccess: "Ձեր գործունեությունը հաջողությամբ ջնջվել է",
            contributorQuestions: {
              subHeader: "Կիսվեք ճանապարհորդելու իրենց սիրելի վայրով կամ այնտեղ, որտեղ նրանք իսկապես ցանկանում են գնալ",
              frequencyCard: "Նրանք սիրում են դա անել",
              likeMostCard: "Ինչն է ամենից շատ դուր գալիս այս վայրի մասին.",
              pageSubHeader: "Ասա մեզ բոլորին այն մասին, թե որտեղ են նրանք սիրում ճանապարհորդել",
              travelLocally: "Արդյո՞ք նրանք սիրում են ճանապարհորդել Միացյալ Նահանգներում, թե՞ միջազգային",
              travelWhere: "Որտե՞ղ են նրանք սիրում ճանապարհորդել",
              likeMost: "Ի՞նչն է նրանց ամենաշատը դուր գալիս դեպի {{location}} ճանապարհորդելիս",
              likeToTravel: "Ինչպե՞ս են նրանք սիրում ճանապարհորդել",
              alone: "Նրանք սիրում են դա անել միայնակ, թե ուրիշների հետ",
              howOften: "Որքա՞ն հաճախ են նրանք սիրում ճանապարհորդել այստեղ",
              anyoneElse: "Կա՞ ուրիշ մեկը, ում հետ կցանկանային ճանապարհորդել",
              anyPictures: "Նրանք ունե՞ն {{location}}-ի զվարճալի նկարներ, որոնք դուք ցանկանում եք կիսել",
              attachedPeople: "Ո՞ւմ հետ են նրանք սիրում ճանապարհորդել",
            },
            summary: {
              place: "Ես սիրում եմ ճանապարհորդել",
              travelWithSolo: "Ես սիրում եմ ճանապարհորդել այստեղ",
              byMyself: "Ինքս",
              travelWith: "Ես սիրում եմ ճանապարհորդել այստեղ",
              frequency: "Ես գնում եմ այնտեղ",
              frequencyAnswer: "{{frequency}} և ես ճանապարհորդում ենք {{transportation}}-ով",
              favoriteThing: "Իմ ամենասիրած բանը դեպի {{place}} ճանապարհորդելն է",
              buttonText: "Գնալ ճանապարհորդելու",
            },
          },
          historyMap: {
            sectionHeader: "Իմ պատմության քարտեզը",
            subHeader: "Պատմեք մեզ ձեր կյանքի կարևոր իրադարձությունների, տարեդարձերի կամ իրադարձությունների մասին",
            addMilestone: "Ավելացնել Հենակետ",
            editMilestone: "Խմբագրել Հենակետ",
            sectionName: "Պատմության քարտեզ",
            category: "Կատեգորիա",
            familyLife: "Ընտանեկան կյանք",
            school: "Դպրոց",
            activities: "Գործունեություն",
            friends: "Ընկերներ",
            housing: "Բնակարան",
            work: "Աշխատանք",
            relationships: "Հարաբերություններ",
            chooseDate: "Ընտրիր ամսաթիվ",
            milestone: "Միլեթոն",
            details: "Մանրամասներ",
            back: "Վերադարձ դեպի Քեզ ճանաչել",
            saveMessage: "Հիանալի էր ձեր մասին ամեն ինչ սովորելը, շնորհակալություն կիսվելու համար",
            validDateError: "Խնդրում ենք մուտքագրել վավեր ամսաթիվ",
            rangeDateError: "Ամսաթիվը տիրույթից դուրս է",
            deleteMessage: "Իսկապե՞ս ուզում եք ջնջել այս նշաձողը:",
            deleteSuccess: "Ձեր նշաձողը հաջողությամբ ջնջվել է",
          },
          contributor: {
            addToRelationshipMap: "Ավելացնել իմ հարաբերությունների քարտեզին",
            thisPersonIs: "Այս անձը…",
            howClose: "Որքա՞ն եք մտերիմ այս մարդու հետ",
            close: "Փակել",
            notClose: "Ոչ մոտ",
            setPermissions: "Սահմանել ներդրողի թույլտվությունները",
            hasBeenEmailed: "Ձեր մասնակցին հրավեր է ուղարկվել",
          },
          happeningPage: {
            pageTitle: "Իմ կյանքում տեղի է ունենում",
            living: "Ապրել իմ տանը",
            work: "Աշխատանք",
            school: "Դպրոց",
            health: "Առողջություն և անվտանգություն",
            happeningVideo: "Իմ կյանքում տեղի ունեցող տեսահոլովակ",
          },
          happeningHome: {
            pageTitle: "Ապրել իմ տանը",
            subTitle: "Եկեք ավելին իմանալ, թե որտեղ եք ապրում",
            singleStory: "Միհարկանի տուն",
            multiLevel: "Բազմաստիճան տուն",
            apartment: "Բնակարան",
            facility: "Օբյեկտ",
            homeless: "Անօթևան",
            groupHome: "Խմբային տուն",
            car: "Ավտոմեքենա",
            rvCamper: "RV/Camper",
            currentlyLive: "Որտե՞ղ եք ապրում ներկայումս",
            currentlyLiveWith: "Ո՞ւմ հետ եք այժմ ապրում",
            parents: "Ծնող(ներ)",
            guardians: "Պահապան(ներ)",
            siblings: "Քույր (եղբայրներ)",
            friends: "Ընկերներ)",
            roommates: "Սենյակ(ներ)",
            spouse: "Ամուսին",
            children: "Երեխա Երեխաներ",
            alone: "Ես ապրում եմ մենակ",
            dogs: "Շուն(ներ)",
            cats: "Կատու(ներ)",
            other: "Ուրիշ, խնդրում եմ բացատրեք",
            explain: "Խնդրում եմ բացատրեք",
            tableTitle: "Ի՞նչն է ձեզ դուր գալիս և ի՞նչը չեք սիրում ձեր բնակության վայրում",
            amenityHeader: "Հարմարություններ",
            selectHeader: "Խնդրում ենք ընտրել հավանել կամ չհավանել",
            commentHeader: "Մեկնաբանություններ",
            commentsLikes: "Մեկնաբանություններ իմ հավանումների մասին",
            commentsDislikes: "Մեկնաբանություններ իմ չհավանումների մասին",
            tellUsWhy: "Խնդրում եմ, ասեք մեզ, թե ինչու այստեղ",
            whatILike: 'Այն, ինչ ինձ դուր է գալիս այնտեղ, որտեղ ես ներկայումս ապրում եմ',
            whatIDislike: 'Այն, ինչ ինձ դուր չի գալիս այնտեղ, որտեղ ես ներկայումս ապրում եմ',
            amenities: {
              publicTransportation: "Հասարակական տրանսպորտ",
              accessibility: "Մատչելիություն",
              neighborhood: "Հարեւանություն",
              friendsFamily: "Մոտ ընկերների և ընտանիքի հետ",
              shopping: "Գնումների մոտ",
              furniture: "Կահույք",
              homeType: "Տան տեսակը",
              squareFootage: "Քառակուսի կադրեր",
              housemates: "Տնեցիներ",
              livingAlone: "Ապրել միայնակ",
              neighbors: "Հարեւաններ",
              management: "Գույքի կառավարում",
              support: "Աջակցող անձնակազմ",
              tellUsWhy: "Խնդրում եմ, ասեք մեզ ինչու",
            },
            summary: {
              currentlyLive: "Որտեղ ես ապրում եմ",
              currentlyLiveWith: "Ում հետ ես ապրում եմ",
            },
          },
          happeningSchool: {
            header: "Դպրոց",
            subHeader: "Մենք անհամբերությամբ սպասում ենք դպրոցի մասին ավելին լսելուն, պատմեք մեզ ձեր փորձի մասին",
            enrolledInSchool: "Դուք այժմ դպրոցում եք",
            schoolNameOOS: "Ի՞նչ դպրոց եք սովորել",
            gradeLevelOOS: "Ի՞նչ դասարան եք ավարտել",
            gradYearOOS: "Ե՞րբ եք ավարտել դպրոցը",
            schoolLikeOOS: "Ի՞նչն է ձեզ ամենաշատը դուր եկել դպրոցում",
            schoolDislikeOOS: "Ի՞նչը ձեզ դուր չեկավ դպրոցում",
            volunteerOOS: "Դպրոցում ինչ-որ տեղ կամավոր էիր աշխատում",
            volunteerWhereOOS: "Որտե՞ղ ես կամավոր աշխատել",
            volunteerDislikeOOS: "Ի՞նչը չհավանեցիր",
            volunteerLikeOOS: "Ի՞նչն է ձեզ դուր եկել կամավորության մեջ",
            graduationGoalOOS: "Ի՞նչ էր դա",
            gradYearOtherOOS: "",
            goalYesOOS: "Կա՞ ինչ-որ բան, որ ուզում էիր անել, երբ ավարտել ես",
            schoolName: "Ի՞նչ դպրոց ես հաճախում",
            gradeLevel: "Ո՞ր դասարանում ես",
            gradYear: "Ե՞րբ եք ավարտել դպրոցը",
            schoolLike: "Ի՞նչն եք ամենաշատը սիրում դպրոցում",
            schoolDislike: "Ի՞նչը ձեզ դուր չի գալիս դպրոցում",
            volunteer: "Դուք որևէ տեղ կամավոր ե՞ք աշխատում",
            volunteerWhere: "Որտե՞ղ եք կամավոր աշխատում",
            volunteerDislike: "Ի՞նչը քեզ դուր չի գալիս",
            volunteerLike: "Ի՞նչն է ձեզ դուր գալիս կամավորության մեջ",
            graduationGoal: "Ի՞նչ է դա",
            gradYearOther: "",
            goalYes: "Կա՞ ինչ-որ բան, որ ուզում ես անել, երբ ավարտես",
            summary: {
              schoolName: "Դպրոցը, որտեղ ես հաճախում եմ, կոչվում է",
              schoolNameOOS: "Դպրոցը, որտեղ ես սովորել եմ, կոչվում է",
              gradeLevel: "Ես դասարանում եմ",
              gradeLevelOOS: "Ես ավարտեցի դասարանը",
              gradYear: "Ես կավարտեմ դպրոցը",
              gradYearOOS: "Ես ավարտեցի դպրոցը",
              schoolLike: "Ինչն է ինձ դուր գալիս դպրոցում",
              schoolLikeOOS: "Այն, ինչ ինձ դուր եկավ դպրոցում",
              schoolDislike: "Այն, ինչ ինձ դուր չի գալիս դպրոցում",
              schoolDislikeOOS: "Այն, ինչ ինձ դուր չեկավ դպրոցում",
              volunteerWhere: "Դպրոցում ես կամավոր եմ աշխատում",
              volunteerWhereOOS: "Դպրոցում ես կամավոր եմ եղել",
              volunteerLike: "Ինչն է ինձ դուր գալիս կամավորության մեջ",
              volunteerLikeOOS: "Այն, ինչ ինձ դուր եկավ կամավորության մեջ",
              volunteerDislike: "Այն, ինչ ինձ դուր չի գալիս կամավորության մեջ",
              volunteerDislikeOOS: "Այն, ինչ ինձ դուր չեկավ կամավորության մեջ",
              graduationGoal: "Երբ ավարտում եմ, ուզում եմ",
              graduationGoalOOS: "Երբ ավարտեցի, ուզում էի",
            },
          },
          happeningWork: {
            sectionName: "Աշխատանքային փորձ",
            subHeader: "Պատմեք մեզ ավելին ձեր աշխատանքային փորձի մասին, մենք անհամբերությամբ սպասում ենք լսելու",
            pastExperience: "Խնդրում եմ կիսվեք ձեր անցյալ աշխատանքային փորձով",
            pastExperienceLike: "Ի՞նչն է ձեզ դուր եկել ձեր անցյալ աշխատանքային փորձից",
            pastExperienceDislike: "Ի՞նչը ձեզ դուր չեկավ ձեր անցյալ աշխատանքային փորձից",
            hasCurrentEmployment: "Դուք այժմ աշխատում եք",
            currentEmployment: "Որտե՞ղ եք այժմ աշխատում",
            currentDuration: "Որքա՞ն ժամանակ եք աշխատել այնտեղ",
            currentLike: "Ի՞նչն է ձեզ դուր գալիս ձեր ընթացիկ աշխատանքում",
            currentDislike: "Ի՞նչը ձեզ դուր չի գալիս ձեր ընթացիկ աշխատանքում",
            summary: {
              pastExperience: "Այնտեղ, որտեղ ես աշխատել եմ անցյալում",
              pastExperienceLike: "Ինչն է ինձ դուր գալիս, որտեղ ես աշխատել եմ անցյալում",
              pastExperienceDislike: "Այն, ինչ ես չեմ սիրում, որտեղ ես աշխատել եմ անցյալում",
              currentEmployment: "Ներկայումս աշխատում եմ",
              currentDuration: "Ես այստեղ աշխատել եմ",
              currentLike: "Ինչն է ինձ դուր գալիս, որտեղ ես աշխատում եմ",
              currentDislike: "Այն, ինչ ես չեմ սիրում, որտեղ ես աշխատում եմ",
            },
          },
          happeningHealth: {
            header: "Առողջություն և անվտանգություն",
            subHeader: "Եկեք ծանոթանանք ձեզ հետ, խնդրում եմ կիսվեք, թե ինչով եք հարմար",
            contributorAdditionsToApprove:
              "Ձեր ներդրողները ձեր ծրագրին ավելացրել են DME-ներ և/կամ Աջակցություններ: Կցանկանա՞ք հաստատել դրանք",
            medInfo: "Կա՞ առողջության հետ կապված որևէ բան, որն ազդում է ձեր կյանքի վրա",
            allergies: "Խնդրում եմ նշեք ալերգիաները, որոնց մասին կցանկանայիք, որ մենք իմանանք",
            dmeSectionTitle: "Ի՞նչ տեսակի երկարակյաց բժշկական սարքավորում և հենարաններ եք օգտագործում",
            dmeContributorTitle: "Ի՞նչ տեսակի երկարակյաց բժշկական սարքավորումներ և հենարաններ են նրանք օգտագործում",
            sectionSubTitle:
              "Ընտրեք կոճակ՝ այն ձեր ցուցակում ավելացնելու համար, սեղմեք 'x' ձեր ցանկում՝ այն հեռացնելու համար",
            supportsSectionTitle: "Ինչպե՞ս կարող են ուրիշները աջակցել ձեզ առողջ զգալու և մնալու համար",
            supportContributorTitle: "Ինչպե՞ս կարող են ուրիշները աջակցել նրանց առողջ զգալու և մնալու հարցում",
            dmePostItTitle: "Ինչ տեսք ունի լավ հենարանները",
            supportPostItTitle: "Ինչպես կարող են ուրիշները աջակցել ինձ",
            dmeOptions: {
              bloodSugarMonitors: "Արյան շաքարի մոնիտորներ",
              bloodSugarTestStrips: "Արյան շաքարի թեստի շերտեր",
              canes: "Ձեռնափայտ",
              commodeChairs: "Կոմոդի աթոռներ",
              continuousPassiveDevices: "Շարունակական պասիվ շարժման սարքեր",
              continuousPassiveAirwayPressureDevices: "Շարունակական դրական օդուղիների ճնշման (CPAP) սարքեր",
              crutches: "Հենակներ",
              hospitalBeds: "Հիվանդանոցային մահճակալներ",
              infusionPumps: "Ինֆուզիոն պոմպեր և պարագաներ",
              lancetDevices: "Lancet սարքեր և նիզակներ",
              nebulizerMedications: "Nebulizers & nebulizer դեղեր",
              oxygenEquipment: "Թթվածնային սարքավորումներ և պարագաներ",
              patientLifts: "Հիվանդների վերելակներ",
              supportSurfaces: "Ճնշումը նվազեցնող օժանդակ մակերեսներ",
              suctionPumps: "ներծծող պոմպեր",
              tractionEquipment: "Քարշակային սարքավորումներ",
              walkers: "Քայլողներ",
              rollator: "Rollator",
              wheelchairMotorized: "Անվասայլակ՝ շարժիչով",
              wheelchairManual: "Սայլակ - ձեռնարկ",
              wheelchairStanding: "Անվասայլակ՝ պառկած/կանգնած",
              scooterMotorized: "Սկուտեր - Շարժիչ",
              scooterManual: "Սկուտեր - ձեռնարկ",
              assistiveTech: "Օժանդակ տեխնոլոգիա",
              translator: "Թարգմանիչ",
              brailleKeyboard: "Բրայլյան ստեղնաշար",
              hearingAid: "Լսողական ապարատ",
            },
            supportOptions: {
              cookMeals: "Եփել իմ ճաշերը",
              prepareMeals: "Օգնիր ինձ պատրաստել իմ կերակուրները",
              prepareMedication: "Օգնիր ինձ պատրաստել իմ դեղորայքը",
              medicationSchedule: "Օգնիր ինձ ստեղծել դեղորայքի ժամանակացույց",
              doctorTransportation: "Օգնիր ինձ մոտենալ և ետ գնալ բժիշկներին",
              createBudget: "Օգնիր ինձ ստեղծել բյուջե",
              beActive: "Օգնիր ինձ դրդել ակտիվ լինել",
            },
            summary: {
              medInfo: "Առողջական պայմանները, որոնց մասին ես կցանկանայի, որ ուրիշները իմանային, ներառում են",
              allergies: "Այն ալերգիաները, որոնց մասին ես կցանկանայի, որ ուրիշներն իմանային, ներառում են",
              dmeSectionTitle: "Երկարատև բժշկական աջակցությունը, որը ես օգտագործում եմ, ներառում է",
              supportsSectionTitle: "Իմ կյանքում մարդիկ կարող են ինձ աջակցել հետևյալ կերպ",
            },
          },
          happeningGeneral: {
            sectionSave:
              "բաժին Պահպանել. 'Շնորհակալություն {{userFirstName}} տեղեկատվության համար, կարող եք անցնել Ինչ է կատարվում ձեր կյանքում",
          },
          planSummary: {
            generatePdf: "Ստեղծեք PDF",
            downloadPdf: "Ներբեռնեք PDF",
            selectTravelPlan: "Ընտրեք ձեր ճանապարհորդական պլանը",
            selectActivity: "Ընտրեք ձեր գործունեությունը",
            selectGoal: "Ընտրեք նպատակ",
            sections: {
              importantToMe: "Ինչն է ինձ համար կարևոր",
              importantForMe: "Ինչն է ինձ համար կարևոր",
              goals: "Նպատակների պլանավորում",
            },
            cardTitles: {
              likeAdmire: "Այն, ինչ մարդիկ սիրում և հիանում են իմ մասին",
              people: "Կարևոր մարդիկ իմ կյանքում",
              milestones: "Կարևոր իրադարձություններ իմ կյանքում",
              favoriteThings: "Իմ սիրելի բաներից մի քանիսը",
              thingsIDo: "Այն, ինչ ես սիրում եմ անել զվարճանքի համար",
              travelPlan: "Վայրեր, որտեղ ես կցանկանայի ճանապարհորդել",
              dailyRoutines: "Իմ առօրյան",
              weeklyRoutines: "Իմ շաբաթական առօրյաները",
              happeningHome: "Ապրել իմ տանը",
              happeningWork: "Իմ աշխատանքային փորձը",
              happeningSchool: "Իմ դպրոցական փորձը",
              happeningHealth: "Իմ առողջությունը և անվտանգությունը",
              homeGoals: "Իմ բնակարանային նպատակները",
              workGoals: "Իմ աշխատանքային նպատակները",
              healthGoals: "Իմ առողջության նպատակները",
              funGoals: "Իմ զվարճալի նպատակները",
              relationshipGoals: "Իմ հարաբերությունների նպատակները",
              travelGoals: "Իմ ճանապարհորդական նպատակները",
            },
            cardDescriptions: {
              likeAdmire: "Ահա այն բաները, որոնք ինձ դուր են գալիս և հիանում իմ մեջ, և ինչ են գնահատում իմ հեղինակները",
              people: "Ահա իմ կյանքում ամենակարևոր մարդիկ.",
              milestones: "Բացահայտեք իմ կյանքի բոլոր կարևոր իրադարձությունները, որոնցով ես ամենից շատ հպարտ եմ:",
              favoriteThings: "Սրանք իմ սիրելի բաներից ընդամենը մի քանիսն են",
              thingsIDo: "Ահա մի քանի բաներ, որոնք ես սիրում եմ անել հաճույքի համար:",
              travelPlan: "Պարզեք, թե որտեղ եմ սիրում ճանապարհորդել, ում հետ և ինչի հետ եմ սիրում ճանապարհորդել",
              dailyRoutines: "Բացահայտեք, թե ինչն է ինձ համար կարևոր ամեն օր և ինչը լավ օր է դարձնում:",
              weeklyRoutines: "Իմացեք ավելին իմ նախասիրությունների, ծեսերի և առօրյայի մասին:",
              happeningHome: "Ինձ համար կարևոր է կիսվել իմ ներկայիս կենսապայմաններով, թե ինչն է ինձ դուր գալիս և ինչ չի սիրում դրանում:",
              happeningWork: "Իմացեք ավելին աշխատանքի հետ կապված իմ անցյալ փորձառությունների մասին, թե ինչն է ինձ դուր եկել և ինչ չէ:",
              happeningSchool: "Պարզեք մի քիչ ավելին իմ և իմ դպրոցական փորձի մասին:",
              happeningHealth: "Ահա թե ինչով եմ ես հարմարավետ կիսվում իմ առողջության, անվտանգության և այն մասին, թե ինչ կարող են անել մյուսները՝ ինձ աջակցելու համար:",
              homeGoals: "Իմացեք ավելին, թե որտեղ եմ ուզում ապրել",
              workGoals: "Իմացեք իմ երազանքի աշխատանքի մասին",
              healthGoals: "Պարզեք, թե ինչպես եմ ես ուզում պլանավորել իմ առողջությունը",
              funGoals: "Պարզեք, թե ինչ եմ ուզում անել ապագայում զվարճանալու համար",
              relationshipGoals: "Իմացեք ավելին այն մասին, թե ինչպես եմ ուզում կապվել ուրիշների հետ",
              travelGoals: "Պարզեք, թե որտեղ եմ ես հույս ունեմ ճանապարհորդել",
            },
            noContent: {
              milestones: "Ոչ մի նշաձող չի ավելացվել: Սկսելու համար սեղմեք ստորև բերված կոճակը:",
              favoriteThings: "",
              thingsIDo: "Ոչ մի Things I Do for Fun չի ստեղծվել: Կտտացրեք ստորև բերված կոճակին` մեկը ստեղծելու համար:",
              travelPlan: "Ճամփորդելու վայրեր չեն ստեղծվել: Կտտացրեք ստորև բերված կոճակին` մեկը ստեղծելու համար:",
              dailyRoutines: "Առօրյա ռեժիմներ չեն ավելացվել: Սկսելու համար սեղմեք ստորև բերված կոճակը:",
              weeklyRoutines: "Շաբաթական ռեժիմներ չեն ավելացվել: Սկսելու համար սեղմեք ստորև բերված կոճակը:",
              happeningHome: "Իմ տանը բնակվելու մասին տեղեկություն չի գրանցվել: Սկսելու համար սեղմեք ստորև բերված կոճակը:",
              happeningWork: "Աշխատանքային փորձի մասին տեղեկություն չի գրանցվել: Սկսելու համար սեղմեք ստորև բերված կոճակը:",
              happeningSchool: "Դպրոցի որևէ տեղեկություն չի գրանցվել: Սկսելու համար սեղմեք ստորև բերված կոճակը:",
              happeningHealth: "Առողջության և անվտանգության վերաբերյալ որևէ տեղեկություն չի գրանցվել: Սկսելու համար սեղմեք ստորև բերված կոճակը:",
              homeGoals: "Բնակարանային նպատակներ չեն ստեղծվել: Կտտացրեք ստորև բերված կոճակին` մեկը ստեղծելու համար:",
              workGoals: "Աշխատանքային նպատակներ չեն ստեղծվել: Կտտացրեք ստորև բերված կոճակին` մեկը ստեղծելու համար:",
              healthGoals: "Առողջապահական նպատակներ չեն ստեղծվել։ Կտտացրեք ստորև բերված կոճակին` մեկը ստեղծելու համար:",
              funGoals: "Զվարճալի նպատակներ չեն ստեղծվել: Կտտացրեք ստորև բերված կոճակին` մեկը ստեղծելու համար:",
              relationshipGoals: "Հարաբերությունների նպատակներ չեն ստեղծվել: Կտտացրեք ստորև բերված կոճակին` մեկը ստեղծելու համար:",
              travelGoals: "Ճամփորդական նպատակներ չեն ստեղծվել: Կտտացրեք ստորև բերված կոճակին` մեկը ստեղծելու համար:",
            },
          },
          contributorGeneric: {
            entries: "Աջակցողների գրառումներ",
            noAccess: "Դուք մուտք չունեք այս բաժինը",
            includedByPlanOwner: 'Ներառված է ծրագրի սեփականատիրոջ կողմից',
            notIncluded: "Ծրագրի սեփականատիրոջ կողմից ներառված չէ",
            sectionNotComplete: "Ծրագրի սեփականատերը չի լրացրել այս բաժինը",
            thankYou:
              "Շնորհակալություն ձեր ներդրման համար: Ձեր լրացումները ուղարկվել են պլանի սեփականատիրոջը հաստատման համար",
          },
          contributorComments: {
            addComment: "Ավելացնել մեկնաբանություն",
            allComments: "Բոլոր մեկնաբանությունները",
            commentPrompt:
              "մեկնաբանության հուշում.	Ունե՞ք որևէ բան, որը կցանկանայիք մեկնաբանել ձեր տեսածի հիման վրա: Թողեք նրանց զվարճալի մեկնաբանություն կամ նշում",
            yourComment: "Ձեր մեկնաբանությունը",
            approved: "Հաստատված մեկնաբանություն",
            pendingApproval:
              "Շնորհակալություն ձեր ներդրման համար: Ձեր մեկնաբանությունն ուղարկվել է պլանի սեփականատիրոջը հաստատման համար",
            pendingNoThankYou: "Ձեր մեկնաբանությունն ուղարկվել է պլանի սեփականատիրոջը հաստատման համար",
            comments: "Մեկնաբանություններ",
            toCheck: 'ստուգել',
            deleteConfirm: 'Համոզված եք, որ ցանկանում եք հեռացնել այս մեկնաբանությունը:',
            deleteSuccess: "Լավ, մեկնաբանությունը հեռացվել է",
            notApprovedMessage: 'Ձեր մեկնաբանությունը չի հաստատվել ծրագրի սեփականատիրոջ կողմից: Կարող եք խմբագրել ձեր մեկնաբանությունը եւ կրկին ներկայացնել այն կամ հեռացնել ձեր մեկնաբանությունը:',
            notIncluded: 'Ներառված չէ',
          },
          durationDropdown: {
            notSelected: "Ընտրված չէ",
            zeroMonths: "0-6 ամիս",
            sixMonths: "6-12 ամիս",
            oneYear: "1-2 տարի",
            twoYears: "2+ տարի",
          },
          navigationButtons: {
            gtky: "Վերադարձ դեպի Ձեզ ճանաչելը",
            thingsIDo: "Վերադարձ դեպի այն, ինչ անում եմ",
            happening: "Վերադարձ դեպի Իմ կյանքում տեղի ունեցող իրադարձություն",
            planningFun: "Վերադարձ դեպի Պլանավորման զվարճանք",
            planningHealth: "Վերադարձ դեպի Պլանավորման առողջություն",
            planningHome: "Վերադարձ դեպի Պլանավորման տուն",
            planningRelationships: "Վերադարձ դեպի Պլանավորման հարաբերություններ",
            planningTravel: "Վերադարձ դեպի Ճամփորդության պլանավորում",
            planningWork: "Վերադարձ դեպի Պլանավորման աշխատանքներ",
          },
          generic: {
            add: "Ավելացնել",
            addAnother: "Ավելացնել ևս մեկ",
            addComment: "Ավելացնել մեկնաբանություն",
            addNew: "Ավելացնել նոր",
            addNewEntry: "Ավելացնել նոր մուտք",
            addYourOwn: "Ավելացրու քո սեփականը",
            addedBy: 'Ավելացրեց',
            addressPrimary: "Հասցե 1",
            addressSecondary: "Սուիթ, Բնակարան, Միավոր #",
            alone: "Մենակ",
            approve: "Հաստատել",
            back: "Հետ",
            backToDashboard: "Վերադառնալ պլանի վահանակին",
            cancel: "Չեղարկել",
            cancelled: "Չեղարկված է",
            city: "Քաղաք",
            clear: "Մաքուր",
            country: "Երկիր",
            comments: "Մեկնաբանություններ",
            createdBy: "Ստեղծվել է",
            createdOn: "Ստեղծվել է",
            dailyWithCapital: "Ամենօրյա",
            date: "Ամսաթիվ",
            delete: "Ջնջել",
            deleteGoalConfirm: "Վստա՞հ եք, որ ցանկանում եք ջնջել այս նպատակը",
            deleteGoalSuccess: "Ձեր նպատակը հաջողությամբ ջնջվել է",
            details: "Մանրամասներ",
            disapprove: "Չհավանել",
            dislike: "չհավանել",
            edit: "Խմբագրել",
            editGoal: "Խմբագրել նպատակը",
            email: "փոստ",
            emailWithCapitial: "Էլեկտրոնային փոստ",
            error: 'Սխալ',
            faceTime: "FaceTime",
            firstName: "Անուն",
            addGeneralComment: "Սեղմեք՝ ընդհանուր մեկնաբանություն ավելացնելու համար",
            generalComments: "Ընդհանուր մեկնաբանություններ",
            goal: "նպատակ",
            goToItem: "Գնալ դեպի կետ",
            goToGoal: "Գնալ դեպի նպատակ",
            goToSection: "Գնալ դեպի {{section}}",
            inPerson: "Անձամբ",
            instructionalVideo: "Ուսումնական տեսանյութ",
            language: "Լեզվի նախապատվություն",
            lastName: "Ազգանուն",
            like: "նման",
            loading: "բեռնում...",
            middleName: "Միջին անուն",
            name: "Անուն",
            newAdditions: "Աջակցողները նոր լրացումներ են կատարել",
            no: "Ոչ",
            noNotifications: "Ծանուցումներ չկան",
            noPeopleMessage: "Ավելացրեք ընկերներին և ընտանիքին ձեր «Մարդիկ իմ կյանքում»՝ նրանց այստեղ ընտրելու համար:",
            noThanks: "Ոչ, շնորհակալ եմ",
            okay: "Լավ",
            other: "Այլ",
            pleaseExplain: "Խնդրում եմ բացատրեք",
            phoneNumber: "Հեռախոսահամար",
            remove: "Հեռացնել",
            removeImage: "Հեռացնել պատկերը",
            review: "Վերանայում",
            save: "Պահպանել",
            saveAndSendUpdates: "Պահպանել և ուղարկել թարմացումները",
            saveAndSendInvite: "Պահպանել և ուղարկել հրավեր",
            saveAnother: "Պահպանել և ավելացնել մեկ ուրիշը",
            saveNoUpdates: "Թարմացումներ մի ուղարկիր",
            search: "Որոնում",
            selectAGoal: "Ընտրիր նպատակ",
            state: "Պետություն",
            submit: "Ներկայացնել",
            success: "Հաջողություն",
            talking: "Խոսում",
            thankYou: 'Շնորհակալություն',
            title: "Կոչում",
            uploadImage: "Վերբեռնել նկար",
            viewComment: "Դիտել մեկնաբանությունը",
            weeklyWithCapitial: "Շաբաթական",
            with: 'հետ',
            withOthers: "Ուրիշների հետ",
            yes: "Այո",
            zip: "Փոստային ինդեքս",
            include: "Ներառում",
            notInclude: "Չներառել",
            approveAll: "Հաստատել բոլորը",
            denyAll: "Հերքել բոլորը",
            removeFromPlan: "Հեռացնել պլանից",
            said: "ասաց",
            errors: {
              errorPageTitle: 'Եղել է սխալ:',
              errorPageSubTitle: 'Ցավում եմ. Խնդրում ենք ավելի ուշ վերադառնալ:',
              error404: '404 - էջը չի գտնվել,',
              saveError: 'Ներեցեք, ձեր մուտքը խնայելու սխալ տեղի ունեցավ: Խնդրում եմ փորձեք մի փոքր ուշ.,',
              deleteError: 'Ներեցեք, ձեր մուտքը ջնջելու սխալ տեղի ունեցավ: Խնդրում եմ փորձեք մի փոքր ուշ.,',
              createPlanError: 'Ներեցեք, ձեր պլանը ստեղծելու սխալ տեղի ունեցավ: Խնդրում եմ փորձեք մի փոքր ուշ.',
            },
          },
        },
      },
      chineseSimple: {
        translation: {
          contact: {
            contactUs: '联系我们',
            message: '信息',
            send: '发送',
            sayHello: '问好',
            saveTitle: '您的电子邮件已成功发送！',
            saveMessage: 'Center On Me 的代表将尽快与您联系。',
            emailError: '请输入有效的电子邮件地址',
            phoneError: '请输入一个有效的电话号码',
          },
          password: {
            lowercase: "包含一个小写字母",
            uppercase: "包含一个大写字母",
            eightCharacters: "至少 8 个字符长",
            number: "包含一个数字",
            specialCharacter: "包含特殊字符",
            passwordsMatch: "密码匹配",
            errorCannotMatch: "旧密码和新密码不能匹配",
          },
          saveProgress: {
            uploadPhoto: '上传图像',
            updateProfile: '更新您的个人资料',
          },
          agreements: {
            pleaseRead: "保护您的信息是我们的首要任务。请阅读我们的",
            privacyTitle: "网站隐私",
            termsTitle: "使用条款",
            ifYouAgree: `如果您同意，请输入您的名字和姓氏，然后单击“我同意”`,
          },
          settings: {
            pageTitle: "设置",
            subTitle: "您可以按照自己的方式定制您的计划！",
            saveSettings: "保存设置",
            planVideo: "我的计划视频",
            contributorPage: "管理贡献者",
            colorPalette: "我的调色板",
            changePassword: {
              changePassword: "更改密码",
              subTitle: "让我们更改密码！",
              currentPassword: "当前密码",
              newPassword: "新密码",
              confirmPassword: "确认密码",
              currentIncorrect: "输入的密码不正确",
              samePasswordError: "哎呀,请使用新密码",
              successMessage: "密码已更改！"
            },
            colorPalettePage: {
              pageTitle: "颜色调色板",
              subTitle: "在創建計劃時選擇您希望看到的顏色！",
              myColorPalette: "我的調色板",
              select: "選擇您喜歡的調色板",
              brainstormBlue: "藍色頭腦風暴",
              planningPurple: "紫色規劃",
              organizingOrange: "組織橙",
              gamePlanGreen: "遊戲計劃綠色",
              primaryColorTooltip: "文本、按钮和图标",
              secondaryColorTooltip: "标题、选定按钮、表格",
              contributorColorTooltip: "贡献者条目",
              textColorTooltip: "文本",
              saveMessage: "您的調色板已更新！"
            },
            manageContributors: {
              sectionHeader: "管理我的贡献者和通知",
              subHeader: "点击贡献者卡片设置或编辑他们的权限",
              myContributors: "我的贡献者",
              dialogConfirm: "一切看起来都正确吗？",
              dialogTitle: "贡献者信息",
              inviteSuccess: "已向您的投稿人发送了邀请",
              updateSuccess: "贡献者信息已更新,并已发送电子邮件通知贡献者",
              updateSuccessNoEmail: "贡献者信息已更新！",
              lastContribution: "最后贡献：",
              noContributions: "还没有贡献",
              addContributor: "添加贡献者",
            },
            contributorNotifications: {
              myNotifications: "我的通知",
              letsSetPreferece: "让我们设置您的偏好",
              howToNotify: "当贡献者添加到您的计划中时,您希望如何收到通知？",
              howOften: "您希望多久收到一次通知？",
              saveAlert: "您的通知设置已更新",
            },
            managementPage: {
              sectionHeader: "我的贡献者",
              noContributorsContext: "您可以授予贡献者对您计划的不同部分的权限,然后他们可以对这些部分进行评论和其他补充！",
            },
            profile: {
              pageTitle: "名称和详细信息",
              sectionHeader: "欢迎以我为中心",
              subHeader: "请花点时间告诉我们一些关于你自己的事情",
              profilePicture: '个人资料图片',
              name: "姓名",
              languageAndNumber: "语言和电话号码",
              address: "地址",
              firstNameError: "名字是必填项",
              lastNameError: "姓氏是必填项",
              phoneError: "需要有效的电话号码",
              saveConfirmationMsg: "这看起来对吗？",
              backToSettings: "返回设置",
              backToPlans: "回到我的计划",
              saveMessage: "感谢您添加信息！",
              firstTimeSaveMessage: '认识你真是太好了！现在，让我们开始计划。',
              notQuite: "不完全的",
              speechToText: '对文字的讲话',
              speechToTextEnabled: '是的，我想用语音进行文字！',
              speechToTextDisabled: '不，我不想用语音来发短信',
              speechToTextWarning: '目前仅提供英语',
              languages: {
                english: "英语",
                armenian: "亚美尼亚",
                chineseSimple: "简体中文",
                chineseTraditional: "中国传统的",
                farsi: "波斯语",
                hmong: "苗族",
                korean: "韩国人",
                spanish: "西班牙语",
                tagalog: "他加禄语",
                vietnamese: "越南语",
              },
            },
            account: {
              pageTitle: '帐户',
              subTitle: '您管理订阅的地方',
              benefitsTitle: '付费订阅福利',
              manageTitle: '管理订阅',
              benefits: {
                multiplePlans: '创建多个计划',
                fullAccess: '完全访问计划页面',
                printablePDF: '可打印的PDF计划摘要',
                uploadPhotos: '上传照片',
                planVideo: '创建计划视频以与您的贡献者分享',
                backgroundImage: '选择自定义背景图像',
                themeColor: '选择您的个人资料主题颜色',
              },
              currentPlan: '当前计划',
              trial: '30天试用',
              subscription: '12个月订阅',
              noSubscription: '没有当前订阅',
              expirationDate: '截止日期',
              expiresOn: '您的订阅将过期',
              paymentMethod: '付款方式',
              supportContact: '支持联系',
              supportContactIs: '您的支持联系人为{{contactName}}',
              editSupportContact: '编辑支持联系人',
              supportContactLabel: '支持联系',
              contactEmailLabel: '联系电子邮件地址',
              contactPhoneLabel: '联系电话',
              saveSupportMessage: '您的支持联系已更新。', 
              supportContactError: '需要支持联系',
              contactEmailError: '需要支持联系电子邮件',
              contactPhoneError: '支持联系人电话号码',                           
              subscribeButton: '订阅',
              renewButton: '续订订阅',
              requestRenewal: '请求续订您的订阅',
              renewDialogTitle: '更新您的订阅',
              selectedPlan: '选定计划',
              billed: '计费',
              payment: '支付',
              howItWorks: '您的订阅如何工作',
              billingInformation: '账单信息',
              billingStartPlanning: '您可以在接受付款后立即开始计划',
              expirationExplanation: '订阅期限到期后，订阅将自动终止，而无需任何一方要求的任何进一步的通知或诉讼。将没有额外的费用，除非您购买新订阅，否则您将无法访问与订阅相关的利益。',
              termsConditions: '条款和条件',
              trialToMemberMessage: '欢迎来到我的中心！现在，您可以访问我计划中心的所有好处。',
              memberRenewalMessage: '您的计划已续签了12个月',
              contactEmail:  '联系电子邮件地址',
              contactPhone: '联系电话',
              contactSuccessMessage: '您的支持联系已更新',
              subscribeDialogTitle: '订阅我的中心',
              trialExpireOn: '您的审判将在',
              expiredOn: '您的订阅过期',
              paymentSuccessMessage: '谢谢你！希望您喜欢我的中心',
              today: "今天",
              todayDescription: "解锁我的中心完全访问我",
              tenMonths: "10个月",
              tenMonthDescription: "提醒您的计划即将到期",
              twelveMonths: "12个月",
              twelveMonthDescription: "您的订阅结束",
              showPaymentOptions: '显示付款选项',
            },
            profilePictureDialog: {
              title: "添加您的个人资料图片",
              uploadPhoto: "上传照片",
            },
            backgroundImage: {
              pageTitle: '背景图',
              subTitle: '选择背景图像或上传您的',
              hideGrid: '隐藏图像网格',
              showGrid: '显示图像网格',
              selectImage: "选择背景图像",
              noImage: "没有图像",
              newImageWarning: "选择新图像将删除您上传的图像。你想继续吗？",
              warningConfirm: "是的，我想要一张新图片",
              warningCancel: "不，保持我的形象",
              uploadError: "抱歉，更新背景图片时出错",
            },
            planVideoPage: {
              sectionHeader: "我的计划视频",
              subHeader: "制作视频向贡献者介绍您的计划",
              saveAlertText: "感谢您上传计划视频！",
              explainTextGreeting: "你好 {{firstName}},",
              explainTextP1: `		
                这个空间是供您上传您的贡献者将要上传的视频				
                一旦他们登录 Center On Me 为您的计划做出贡献,请立即查看,				
                该视频是您解释为什么要创建此视频的机会				
                计划并给贡献者一个你想象的未来的例子				
                为自己制定这个计划,`,
              explainTextP2: `			
                在手机、电脑或平板电脑上录制视频并上传				
                这里,您的视频必须少于十 (10) 分钟,提示				
                关于如何制作视频,请观看示例视频或选择				
                     "创建我的视频的提示",`,
              explainTextEnding: "谢谢,",
              explainTextSignature: "以我为中心",
              tipsButton: "创建我的视频的提示",
              exampleVideoTitle: "示例视频",
              alvaVideoTitle: "阿尔瓦示例",
              uploadButton: "上传您的视频",
              removeButton: "删除我的视频",
              videoError: "您的浏览器不支持 video 标签,",
              lengthError: "错误：最大视频长度为 10 分钟",
              tips: {
                dialogTitle: "创建视频的提示",
                tipsTitle: "录制和上传视频的提示：",
                tipOne: "在可以看到整张脸的灯光下录制视频",
                tipTwo: "在创纪录之前想想你想说什么",
                tipThree: "将视频保持在十 (10) 分钟以内",
                tipFour: `如果您在上传视频后遇到问题,请发送电子邮件至 centeronme@mainsl.com				
                并提供您的用户名（电子邮件地址）和您上传的视频文件的名称（例如"Myplanvideo.mov）`,
                examplesTitle: "在您的视频中回答的示例问题：",
                exampleOne: "你为什么邀请人们为你的计划做出贡献？",
                exampleTwo: "你希望通过你的计划完成什么？",
                exampleThree: "你想计划什么？",
                exampleFour: "您的贡献者如何支持您制定计划？",
              },
              contributor: {
                sectionHeader: "他们的计划视频",
                subHeader: "",
                noVideo: "计划所有者尚未添加计划视频",
              },
            },
            knowledgeCenter: {
              pageTitle: '知识中心',
              subTitle: '单击链接以了解有关使用我们网站的更多信息',
              videoTab: '视频',
              faqTab: '常问问题',
              contactTab: '联系我们',
              videos: {
                gettingStartedTitle: '入门',
                profileSettings: '配置文件设置',
                landingPageAndBadges: '登陆页面和徽章',
                contributorsTitle: '贡献者',
                addingContributors: '添加贡献者',
                managingContributors: '管理贡献者',
                howToContribute: '如何贡献',
                gettingToKnowYouTitle: '开始了解你',
                likeAndAdmire: '喜欢和欣赏',
                people: '我生命中的人',
                favorites: '我最喜欢的东西',
                thingsIDo: '我为了好玩而做的事情',
                routines: '我的日常',
                historyMap: '历史地图',
                travelPlan: '旅行',
                happeningNowTitle: '现在发生了什么事',
                healthAndSafety: '健康和安全',
                livingInHome: '住在我家',
                work: '工作',
                school: '学校',
                startPlanningTitle: '让我们开始计划',
                workGoals: '工作目标',
                healthGoals: '健康目标',
                livingGoals: '生活目标',
                travelGoals: '旅行目标',
                funGoals: '有趣的目标',
                relationshipGoals: '关系目标',
                planSummaryTitle: '计划摘要',
                webVersion: '网络版和导出为 PDF',
                dialogTitle: '教学视频',
              },
              faq: {
                pageTitle: '经常问的问题',
                usingTheKnowledgeCenter: '使用知识中心',
                passwordAndUsername: '密码和用户名',
                managingPlans: '管理计划',
                planOwnersAndContributors: '计划所有者和贡献者',
                usingTheSite: '使用网站',
                accessingSupport: '访问支持',
                registration: '注册和订阅',
                howToUse: '如何使用知识中心？',
                whoToContact: '如果我有视频中未提及的问题，我可以联系谁？',
                videoLanguage: '我可以用英语以外的语言观看这些视频吗？',
                findVideo: '如何找到我正在寻找的视频？',
                passwordRequirements: '密码要求是什么？',
                changePassword: '如何更改我的密码？',
                changeUsername: '如果我想更改我的登录名/电子邮件地址怎么办？',
                howManyPlans: '注册后可以创建多少个计划？',
                sharePlan: '我可以与任何人分享我的计划吗？有没有办法在平台上通过电子邮件发送我的计划？',
                deletePlan: '如果我不小心删除了计划怎么办？',
                whatIsPlanOwner: '谁/什么是“计划所有者”',
                whatIsContributor: '谁/什么是“贡献者”？',
                contributorLimit: '我可以添加到我的计划中的贡献者是否有限制？',
                approveComment: '如果我批准投稿人评论，他们会收到通知吗？',
                denyComment: '如果我拒绝投稿人的评论，他们会收到通知吗？',
                updateProfile: '我可以随时更新我的个人资料详情吗？',
                howManyActivities: '我可以在每个部分下添加多少活动？',
                listSomeoneAsResponsible: '如果我将某人列为实现目标的责任人，他们会收到通知吗？',
                editActivity: '如何编辑活动？',
                deleteActivity: '如何删除活动？',
                accessibilityFeatures: '该平台提供哪些可访问性功能？',
                forgetToSave: '如果我忘记保存我正在处理的内容会怎样？',
                useOnTablet: '我可以使用平板电脑制定计划吗？',
                useOnPhone: '我可以使用手机制定我的计划吗？',
                phoneApp: '有我需要下载的手机应用程序吗？',
                needInternet: '我是否需要访问互联网才能制定我的计划？有离线模式吗？',
                whatAreBadges: '什么是徽章？',
                helpWithPlan: '如果我的计划需要帮助，我应该联系谁？',
                issuesAccessingPlan: '如果我在访问我的计划时遇到问题，我应该联系谁？',
                issueWithPlanVideo: '如果我无法上传我的计划视频，我应该联系谁？',
                forgotUsername: '如果我忘记了登录用户名怎么办？我该联系谁？',
                wasRegistrationSuccessful: '我怎么知道我的注册成功了？',
                renewPlan: '我怎么知道我需要更新我的计划？',
                howToCancel: '如何取消订阅？',
              },
              faqAnswers: {
                howToUse: `单击您想了解更多信息的部分或链接。您将被带到一个单独的页面，您将能够观看一段视频，引导您浏览网站的该部分。`,
                whoToContact: `请随时使用“联系我们”选项卡与我们联系！在该选项卡中，您会找到要填写的表格，该表格将向我们发送电子邮件。请在您的电子邮件中包含您有疑问的页面和视频。我们会尽快回复您。`,
                videoLanguage: `目前，这些视频仅提供英文版本。已为所有视频提供隐藏式字幕。`,
                findVideo: `请在搜索栏中输入您要查找的页面，然后会弹出视频链接。视频按页面名称组织。如果您找不到您正在寻找的视频，请使用“联系我们”选项卡与我们联系。`,
                passwordRequirements: `您的密码必须包括：一個小写字母、一個大写字母、一個数字、一個特殊字符，并且至少为 8 個字符。`,
                changePassword: `您可以随时通过进入个人资料设置并选择“更改密码”按钮来更改密码。`,
                changeUsername: `您将无法更改您的电子邮件地址。`,
                howManyPlans: `您可以创建多少个计划没有限制。但是，您在计划中输入的信息（包括计划贡献者）不会从一个计划转移到另一个计划。`,
                sharePlan: `计划所有者可以随时与任何人分享他们的计划。在计划摘要页面的右上角，有一个按钮，上面写着“导出为 PDF”。单击该按钮时，您的计划将被下载并可以打印或通过电子邮件发送给您选择的任何人。`,
                deletePlan: `当您尝试删除计划时，Center On Me 将提供提示以确保您确实要删除您的计划。如果您仍然选择删除您的计划，则无能为力，您必须从头开始重新创建您的计划。`,
                whatIsPlanOwner: `“计划所有者”是计划的焦点人物，是制定计划的人。计划所有者应始终是该计划的重点！`,
                whatIsContributor: `贡献者是“计划所有者”邀请为其计划做出贡献的人。计划所有者可以完全控制贡献者可以查看和/或评论其计划的哪一部分。所有贡献者的评论在被纳入计划之前必须经过审查和批准。`,
                contributorLimit: `您可以拥有多少个贡献者是没有限制的。对于您创建的每个计划，您需要分别添加每个贡献者。`,
                approveComment: `不，当评论被批准时，不会通知贡献者。`,
                denyComment: `不，当评论被拒绝时，不会通知贡献者。`,
                updateProfile: `您可以通过转到个人资料设置随时更新您的个人资料详细信息。在个人资料设置中，您将能够更改您的姓名和详细信息、个人资料图片、背景和调色板。`,
                howManyActivities: `您可以在每个部分下添加的活动或目标的数量没有限制。`,
                listSomeoneAsResponsible: `如果您将某人列为实现目标的责任人，他们将不会收到通知。如果计划所有者需要有目标的支持，他们将需要与该人沟通并分享他们的计划，如果他们觉得这样做很舒服。`,
                editActivity: `您可以随时通过单击卡片来编辑活动或目标。表格将打开，任何信息都可以更改或更新。`,
                deleteActivity: `您可以随时通过单击卡片删除活动或目标。表格将打开，可以通过一直向下滚动到底部并选择红色的“删除”按钮来删除目标。`,
                accessibilityFeatures: `Center On Me 使用 Userway 在网站内提供辅助功能。其中一些辅助功能正在更改对比度、字体大小、光标引导以及文本或空间对齐方式。您可以通过选择左侧每个页面上存在的圆形“人”图标来选择您的功能。`,
                forgetToSave: `当您在站点内创建不同的页面时，点击保存非常重要。信息不会自动保存。`,
                useOnTablet: `是的！可以从平板电脑或智能手机访问 Center On Me。`,
                useOnPhone: `是的！可以从智能手机访问 Center On Me。该网站已创建为移动响应，因此某些数据可能不像平板电脑或使用您的计算机那样容易阅读。`,
                phoneApp: `不，Center On Me 没有应用程序。您可以通过打开网络浏览器应用程序并访问 www.centeronme.com 从智能手机访问 Center On Me。`,
                needInternet: `是的，Center On Me 需要互联网才能制定计划。 Center On Me 没有离线模式。`,
                whatAreBadges: `徽章是用户和 Center On Me 跟踪您在站点内的进度的一种方式。每次完成一个页面，您都会获得一个徽章，并且可以在登录页面上查看徽章。单击徽章以获得有趣的惊喜！`,
                helpWithPlan: `如果您需要支持，请访问知识中心内的“联系我们”选项卡。将向 Center On Me 团队发送一封电子邮件，我们会尽快回复。`,
                issuesAccessingPlan: `如果您需要支持，请访问知识中心内的“联系我们”选项卡。将向 Center On Me 团队发送一封电子邮件，我们会尽快回复。`,
                issueWithPlanVideo: `首先，检查视频上传提示，确保符合视频要求。如果您仍需要支持，请访问知识中心内的“联系我们”选项卡。将向 Center On Me 团队发送一封电子邮件，我们会尽快回复。`,
                forgotUsername: `您的用户名是您的电子邮件地址。如果您需要支持，请访问知识中心内的“联系我们”选项卡。将向 Center On Me 团队发送一封电子邮件，我们会尽快回复。`,
                wasRegistrationSuccessful: `如果您的付款成功，您将收到一封电子邮件收据到您关联的 PayPal 帐户。您还将在 PayPal 应用程序中找到收据。请参阅下面的付款电子邮件收据示例。`,
                renewPlan: `用户将在 3 个月、6 个月和 10 个月时收到电子邮件提醒，以提醒用户您的订阅何时到期，并将提供有关如何续订计划的说明。`,
                howToCancel: `订阅为期一年。您不能取消为期一年的订阅。当需要更新您的计划时，我们会向您发送一封电子邮件，您可以选择届时不更新您的计划。`,
              }
            },
          },
          lockedFeatures: {
            photoLock: '上传照片所需的订阅',
            videoLock: '上传视频所需的订阅',
            pdfLock: '下载PDF所需的订阅',
            sectionLock: '选择订阅选项以访问此功能。',
            sectionLockSaveMessage: '本节不能由试用用户修改',
            createPlanLock: '选择一个创建多个计划的订阅选项',
            expiredLock: '选择订阅选项以访问此功能',
            expiredAlert:  '您的订阅过期。请续订查看本节。',            
          },
          permissions: {
            theContributor: "贡献者",
            cannotView: "无法查看",
            canView: "可以查看",
            theSection: "这部分",
            toTheSection: "到该部分",
            and: "和",
            but: "但",
            cannotComment: "无法添加评论",
            canComment: "可以添加评论",
            gtkyTitle: "开始了解你",
            happeningTitle: "你的生活中发生了什么",
            planningTitle: "规划",
          },
          breadcrumbs: {
            planning: "让我们开始计划",
            happening: "正在发生",
            gettingToKnowYou: "开始了解你",
            myRoutines: "我的日常",
            myPeople: "我生命中的人",
            likeAndAdmire: "喜欢和欣赏",
            work: "工作",
            school: "学校",
            home: "家",
            fun: "乐趣",
            travel: "旅行",
            health: "健康",
            relationships: "关系",
            goal: "我的目标",
            historyMap: "历史地图",
            favoriteThings: "我最喜欢的东西",
            add: "添新",
            profile: "轮廓",
            settings: "设置",
            contributors: "贡献者和通知",
            thingsIDo: "我为了好玩而做的事情",
            planningVideo: "我的计划视频",
            planSummary: "计划摘要",
            changePassword: "更改密码",
            knowledgeBase: "知识中心",
            colorPalette: "调色板",
            myActivity: "我的活动",
          },
          userMenu: {
            allPlans: "所有计划",
            planDashboard: "计划仪表板",
            planSummary: "计划摘要",
            settings: "设置",
            subscribe: "订阅",
            logout: "登出"
          },
          timeoutAlert: {
            attention: "注意力",
            description: "您即将退出",
            stillPlanning: "我还在计划中！",
            logMeOut: "注销我",
            warningOnLogout: "未保存的数据将丢失",
          },
          allPlans: {
            pageTitle: "快速浏览您的计划",
            subTitle: "选择一个开始",
            iContributeTo: "我参与的计划",
            myPlans: "我的计划",
            newContributeTo: "为新计划做出贡献",
            createNewPlan: "制定新计划",
            planOwner: "计划所有者",
            planCreated: "创建计划",
            lastViewed: "上次查看",
            planVideo: "计划视频",
            manageContributors: "管理贡献者和通知",
            planSummary: "计划摘要",
            deletePlan: "删除计划",
            createPlanDialog: {
              dialogTitle: '创建一个新计划',
              explainerText: '为您的新计划选择一个名称',
              inputLabel: '计划名称',
              createMessage: '启动新计划将不会涵盖您以前计划的任何工作或贡献者',
            },
            contributeToPlanDialog: {
              dialogTitle: '为计划做出贡献',
              explainerText: '输入您从计划所有者那里收到的代码',
              inputLabel: '访问代码',
              errorMessage: '访问代码与任何计划都不匹配。请再试一次',
            }
          },
          deletePlan: {
            dialogTitle: "删除我的计划",
            explanation: "您正试图删除您的计划。",
            confirmMessage: "您确定要删除此计划吗？",
            noteToDownload: "注意：您可能需要在删除之前下载并保存您的计划，因为它无法恢复。",
            downloadButton: "下载我的计划",
            keepPlanButton: "保持我的计划",
            deleteButton: "删除我的计划",
            deleteSuccessMessage: "已删除！",
          },
          planLandingPage: {
            videoTitle: "欢迎来到你的我计划中心",
            gtky: "开始了解你",
            whatIsHappening: "你的生活中发生了什么",
            startPlanning: "开始规划",
            newBadge: "新徽章",
            getStarted: "开始使用",
            leftOff: "你离开的地方",
            happeningButton: "正在发生",
            planningButton: "目标规划",
            recentBadges: "最近获得的徽章",
            gtkyNoCaps: "开始了解你",
            happeningShort: "发生了什么",
            planning: "规划",
            noBadgeMessage: "还没有徽章！开始完成部分以获得徽章",
            contributorGreeting: "欢迎",
            contributorMessage: `我们看到您作为贡献者加入，这意味着有人认为您非常重要！您在为这场比赛做出贡献的同时发挥着重要作用，
              我们要求您坚持本网站的宗旨，并将计划所有者置于他们计划的中心，同时利用您的时间添加丰富而有意义的信息。在为计划做出贡献时，
              我们还要求您花时间反思和获取有关此人是谁、对他们来说什么是重要的以及如何帮助他们实现他们的希望和梦想的具体信息
            `,
            contributorQuote: `“每个人都有能力、能力和天赋。过上美好的生活取决于是否可以使用这些能力、表达能力和给予礼物。如果是，
              这个人就会受到重视，感觉强大并与周围的人建立良好的联系他们，以及这个人周围的社区会因为这个人所做的贡献而感到更加强大。”
            `,
            quote: `“您的目标是指导您并向您展示生活可能的路线图。” - 莱斯布朗`,
            profileBadges: {
              addContributor: '徽章：将贡献者添加到您的计划',
              colorPalette: '徽章：选择一个调色板',
              approveContributor: '徽章：批准贡献',
              planningVideo: '徽章：创建规划视频',
              profilePicture: '徽章：上传个人资料图片',
              backgroundImage: '徽章：上传新壁纸',
              profileComplete: '徽章：个人资料完成！',
            }
          },
          badges: {
            badgeSectionTitle: "我获得的徽章",
            badgesPlanningExplanation: `完成每个部分即可开始获得徽章！完成某个部分后,您的徽章将出现在此处,				
                让我们在规划您的未来时玩得开心！`,
            badgesHappeningExplanation: `完成每个部分即可开始获得徽章！完成某个部分后,您的徽章将出现在此处,				
                让我们在学习生活中发生的事情的同时获得一些乐趣！`,
            badgesGTKYExplanation: `通过完成每个部分开始获得徽章！完成某个部分后,您的徽章将出现在此处,				
                让我们在认识你的同时获得一些乐趣！`,
          },
          sections: {
            likeAndAdmire: "喜欢和欣赏",
            people: "我生命中的人",
            historyMap: "里程碑",
            favorites: "最喜欢的东西",
            travelPlan: "旅行",
            thingsIDo: "我为了好玩而做的事情",
            dailyRoutines: "日常生活",
            weeklyRoutines: "每周例行公事",
            routines: "例行公事",
            happeningHome: "幸福的家",
            happeningWork: "正在发生的工作",
            happeningSchool: "发生学校",
            happeningHealth: "正在发生的健康",
            planningHome: "规划家园",
            planningWork: "规划工作",
            planningTravel: "规划旅行",
            planningFun: "规划乐趣",
            planningHealth: "规划健康",
            planningRelationships: "规划关系",
          },
          planningPage: {
            pageTitle: "让我们开始计划吧",
            planningVideo: "规划视频",
            housing: "住房",
            work: "工作",
            health: "健康",
            fun: "乐趣",
            relationships: "关系",
            travel: "旅行",
            addAnotherGoal: "添加另一个目标"
          },
          planningHealth: {
            sectionName: "规划健康",
            sectionHeader: "健康目标",
            subHeader: "让我们为健康的未来制定计划",
            videoTitle: "健康目标 - 有用的视频",
            goalTitle: "你想给这个目标起什么名字？",
            goalAroundHealth: "您的健康目标是什么？",
            workedOnThisGoal: "你以前有没有为这个目标工作过？",
            whatWorked: "当你为这个目标工作时,什么有效？",
            didntWork: "当你为这个目标工作时,什么没有奏效？",
            healthTableHeader: "当你考虑这个目标时,你认为你应该......",
            doYouHaveSupport: "你生命中是否有人可以支持你实现这个目标？",
            attachedPeopleMessage: "请选择谁可以支持你",
            anyOneElseWhoCanSupport: "还有其他人可以帮助您实现目标吗？为什么？",
            goalCommit: "你能承诺做些什么来帮助创造这个未来？",
            increase: "增加",
            decrease: "减少",
            doMore: "做更多",
            doLess: "少做",
            increaseEx: "例如,我有多少时间专注于自己",
            decreaseEx: "例如,我在 Facebook 上花了多少时间",
            doMoreEx: "例如,我花在呼吸上的时间",
            doLessEx: "例如,吃含糖食物",
            saveMessage: "太棒了,祝这个目标好运！您要添加另一个健康目标吗？",
            deleteMessage: "您确定要删除此表行吗？",
            deleteConfirm: "好的,该行已被删除",
            summary: {
              goalTitle: "我的健康目标",
              whatWorked: "当我在此之前为这个目标工作时,这就是有效的",
              didntWork: "当我在此之前为这个目标工作时,这是行不通的",
              healthTableHeader: "当我想到我的目标时,我认为我可以做以下事情来帮助我",
              doYouHaveSupport: "我的支持圈子里可以帮助我实现这个目标的人是",
              anyOneElseWhoCanSupport: "我认为可以帮助的其他人是",
              goalCommit: "我可以承诺",
            },
          },
          planningRelationships: {
            sectionName: "规划关系",
            sectionHeader: "关系目标",
            subHeader: "让我们开始与人建立联系吧！",
            newOrStrengthen: "你是想规划一段新的关系,还是想加强现有的关系？",
            saveMessage: "听起来是一个很棒的关系目标！您要添加另一个目标吗？",
            newText: "新的",
            strengthenText: "加强",
            relationship: '关系',
            strengthen: {
              videoTitle: "加强关系 - 有用的视频",
              goal: "你想和谁加强关系？",
              currentStatus: "这段关系的现状如何？",
              romantic: "浪漫的",
              friend: "朋友",
              professional: "专业的",
              family: "家庭",
              whatToStrengthen: "你想加强这段关系怎么样？",
              frequency: "频率",
              communication: "沟通",
              desiredStatus: "期望的状态",
              howOften: "您目前与此人互动的频率如何？",
              howToStrengthen: "你想如何加强频率",
              strengthenCommunication: "你想加强什么沟通？",
              commExample1: "例如：我试着更频繁地给他们打电话",
              commExample2: "例如：我可以随时与他们交谈",
              commExample3: "例如：我感到被倾听和联系",
              commExample4: "例如：他们不会一直回答",
              commTableHeader1: "你在加强沟通时有什么尝试？",
              statusTableHeader1: "你试图做些什么来改变这种关系的状态？",
              tableHeader2: "当你尝试这样做时,你学到了什么？",
              tableHeader3: "当你尝试这样做时,你对什么感到满意？",
              tableHeader4: "你在担心什么？",
              commDoNext: "看看你刚刚列出的,接下来你能做什么？",
              preferredStatus: "你希望这个人的身份是什么？",
              theyAreCurrently: "他们目前是：",
              statusExample1: "例如：我试着在约会时问他们",
              statusExample2: "例如：他们非常友善,我喜欢和他们交谈",
              statusExample3: "例如：他们说是的！",
              statusExample4: "例如：从那以后我没有给他们打电话",
              finalDoNext: "看看你刚刚列出的内容,接下来你能做什么？",
              deleteRowConfirm: "您确定要删除此表格行吗？",
              deleteRowSuccess: "好的,该行已被删除",
            },
            new: {
              videoTitle: "规划新的关系 - 有用的视频",
              relationshipType: "你在寻找什么样的关系？",
              characteristics: "你在寻找想要联系的人的哪些特征？",
              where: "你会在哪里见到这个人？",
              placeOfWorship: "礼拜的场所",
              family: "家庭",
              friend: "朋友",
              professional: "专业的",
              romantic: "浪漫的",
              sharedInterests: "通过共同的利益",
              online: "在线的",
              school: "学校",
              shops: "商店",
              work: "工作",
              whenYouThinkAbout: "当您考虑创建新连接时",
              atChurch: "在礼拜场所",
              throughFriend: "通过朋友",
              throughInterests: "通过共同的利益",
              atOnline: "在线的",
              atSchool: "在学校",
              atLocalShop: "在当地商店",
              atWork: "在工作",
              likeToStart: ",你想从哪里开始？",
              churchStart: "当您考虑在礼拜场所建立新的联系时，您想从哪里开始？",
              friendStart: "当您考虑通过朋友建立新联系时，您想从哪里开始？",
              interestsStart: "当你考虑通过共同的兴趣建立新的联系时，你想从哪里开始？",
              onlineStart: "当您考虑在线创建新连接时，您想从哪里开始？",
              schoolStart: "当你考虑在学校建立新的联系时，你想从哪里开始？",
              shopsStart: "当您考虑在当地商店建立新的联系时，您想从哪里开始？",
              workStart: "当你考虑在工作中建立新的联系时，你想从哪里开始？",
              howOften: "您希望多久以这种方式连接一次？",
            },
            characteristics: {
              organized: "有组织的",
              strong: "强的",
              dreamer: "梦想家",
              determined: "决定",
              smart: "聪明的",
              artist: "一个艺术家",
              considerate: "周到",
              helpful: "有帮助",
              creative: "有创造力的",
              thoughtful: "周到",
              talented: "才华横溢",
              outgoing: "传出",
              leader: "一位领袖",
              intelligent: "聪明的",
              loving: "爱的",
              understanding: "理解",
              spiritual: "精神",
              joyful: "快乐",
              fixer: "固定器",
              liberal: "自由主义的",
              patient: "病人",
              respectful: "尊敬的",
              willing: "愿意的",
              intuitive: "直觉的",
              gentle: "温和的",
              generous: "慷慨的",
              spontaneous: "自发的",
              independent: "独立的",
              supportive: "支持的",
              resourceful: "足智多谋",
              experiential: "体验式",
              fun: "乐趣",
              funny: "有趣的",
              excited: "兴奋的",
              accepting: "接受",
              busy: "忙碌的",
              pretty: "漂亮的",
              openMinded: "思想开明的",
              hardWorking: "任劳任怨",
              doer: "实干家",
              involved: "涉及",
              flexible: "灵活的",
              happy: "快乐的",
              careful: "小心",
              hopeful: "满怀希望",
              tinkerer: "修补匠",
              riskTaker: "冒险者",
              lucky: "幸运的",
              active: "积极的",
              efficient: "高效的",
              visionary: "有远见的人",
              explorer: "探险者",
              responsible: "负责任的",
              driven: "驱动",
              confident: "自信的",
              connector: "一个连接器",
              wise: "明智的",
              expressive: "富有表现力",
              sensitive: "敏感的",
              talkative: "健谈",
              energized: "充满活力",
              giving: "给予",
              observant: "细心的",
              caring: "关怀",
              peaceful: "平静的",
              cool: "凉爽的",
              fashionable: "时髦",
              actor: "演员",
              writer: "一个作家",
              vibrant: "充满活力",
              goodCook: "好厨师",
              goodWithAnimals: "善待动物",
            },
            summary: {
              newGoalPdfTitle: "新关系",
              strengthenGoalPdfTitle: "加强关系",
              relationshipType: "我想要建立的关系类型是",
              characteristics: "我希望这个人具备以下特点",
              where: "我能遇到这个人",
              whereToStart: "我想从哪里开始",
              howOften: "多常",
              beingInPerson: '亲自',
              talkingMore: '说更多',
              facetiming: '方面',
              otherMeans: '其他手段',
              comments: "注释",
              strengthenGoal: "我想加强关系的人是",
              currentStatus: "我们关系的现状是",
              whatToStrengthen: "我想加强的",
              strengthenBy: "我想通过...加强",
              strengthenWhy: "为什么？",
              whyAnswer: "这将帮助我实现我的目标",
              becoming: "变得",
            },
          },
          planningHome: {
            sectionName: "规划家园",
            sectionHeader: "生活目标",
            subHeader: "让我们看看你喜欢或不喜欢你目前的生活状况",
            wantToLive: "你要在哪里住？",
            idealHome: "你理想中的家是什么样子的？",
            homeAmenities: "你希望你的家有什么设施？",
            homeCloseTo: "你希望你的家靠近什么地方？",
            liveWith: "有没有想和你一起生活的人？",
            roommateName: "谁？",
            roommateQuality: "为什么你认为和这个人住在一起很合适？",
            askedRoommate: "你问过同居吗？",
            liveWithPets: "你以后有宠物吗？",
            petType: "什么动物？",
            finalThoughts: "在你理想的家中还有什么我们没有讨论过的吗？",
            whatILike: "我喜欢什么",
            whatIDislike: "我不喜欢什么",
            saveMessage: "太好了，谢谢你为你的家做计划！您要添加另一个吗？",
            homeOptions: {
              singleStory: "单一故事",
              apartment: "公寓",
              duplex: "复式",
              mobileHome: "移动之家",
              tinyHome: "小家",
            },
            amenities: {
              laundry: "洗衣店",
              internet: "无线互联网",
              recreation: "娱乐设施",
              garden: "花园",
              deck: "甲板",
              patio: "露台",
              land: "土地",
              ramp: "坡道",
              pool: "水池",
              bigDoors: "大门",
            },
            proximities: {
              shopping: "购物",
              bus: "巴士车站",
              friends: "朋友们",
              restaurants: "餐厅",
              entertainment: "娱乐",
              publicTransit: "公共交通工具",
              family: "家庭",
            },
            summary: {
              wantToLive: "我想住的地方",
              wantToLiveAnswer: "{{homeType}} 中的 {{place}}",
              homeAmenities: "在我想要的理想家园",
              otherAmenities: "我理想中的家中的其他东西",
              homeCloseTo: "我希望我的家靠近",
              roommateName: "我想一起生活",
              liveAlone: "我想一个人住",
              roommateBecause: "因为{{为什么}}",
              roommateQuality: "为什么你认为和这个人住在一起很合适？",
              petType: "宠物",
              finalThoughts: "我理想中的家中的其他东西",
            },
          },
          planningFun: {
            sectionName: "规划乐趣",
            sectionHeader: "有趣的目标",
            subHeader: "让我们开始玩乐吧！",
            goal: "当您考虑计划一些有趣的事情时,您想做什么？",
            goalWhy: "是什么让这对你有吸引力？或者你为什么要这样做？",
            goalWhen: "你想什么时候做这个？",
            goalHowOften: "你想多久做一次？",
            otherPeople: "有没有你想和谁一起做这件事的人？",
            otherPeopleWhoCanSupport: "请选择谁可以支持你",
            otherPeopleWhereToFind: "请选择可以帮助您与志趣相投的人建立联系的群组或地点",
            needToSave: "你需要存钱来做这个吗？",
            startedSaving: "你开始存钱了吗？",
            amountToSave: "你还需要存多少钱？",
            attachedPeople: "细绳",
            saveMessage: "听起来很有趣！您要添加另一个有趣的目标吗？",
            asap: "尽早",
            sixMonths: "未来六个月",
            oneYear: "六个月到一年",
            twoYears: "1-2年",
            twoPlusYears: "2年以上",
            oneTime: "一次事件",
            daily: "日常的",
            weekly: "每周",
            monthly: "月刊",
            quarterly: "季刊",
            facebook: "Facebook",
            groupMeet: "小组聚会",
            newspaper: "当地报纸",
            coffeeShop: "咖啡店公告栏",
            communityCenter: "社区中心",
            summary: {
              goal: "当考虑计划一些有趣的事情时,我想",
              goalWhy: "这对我很有吸引力,因为",
              goalWhen: "我想做这个",
              goalHowOften: "而且经常",
              otherPeople: "我想这样做",
              peopleInMyLife: "我想和我一起做这件事的人是",
              actionSteps: "操作步骤：",
              comments: "注释",
              helpMeAchieve: "这将帮助我实现我的目标",
              research: "研究计划这项活动的方法",
              needToSave: "我需要为这次旅行存钱吗？",
              needToSaveYes: "是的,我需要节省 {{amount}}",
            },
          },
          planningTravel: {
            sectionName: "规划旅行",
            subHeader: "让我们开始计划你的下一次冒险吧",
            destination: "你想去哪里旅游？",
            travelTo: "你以前去过{{location}}吗？",
            like: "您喜欢 {{location}} 的哪些方面",
            appealingWhy: "是什么让 {{location}} 对您有吸引力？",
            travelWhen: "你想什么时候去{{location}}？",
            withOthers: "有想一起旅行的人吗？",
            travelWithWho: "请选择您想和谁一起旅行",
            toDoAtDestination: "你来访的时候想做什么？",
            toSave: "你需要存钱来做这个吗？",
            startedToSave: "你开始存钱了吗？",
            amountToSave: "你还需要存多少钱？",
            accommodations: "你旅行时有住宿需求吗？",
            needsAndWhy: "有什么你想要或需要旅行的东西吗？如果有,它们是什么？",
            goal: "你想给这个目标起什么名字？",
            saveMessage: "冒险等待！您要添加另一个旅行目标吗？",
            summary: {
              destination: "我想前往",
              appealingWhy: "我为什么要来这里旅行",
              travelWhen: "我想在这里旅行",
              withOthers: "我想和人一起旅行吗",
              withOthersYes: "是的,我想和 {{people}} 一起旅行",
              withOthersAlone: "我想一个人旅行",
              toDoAtDestination: "当我访问时,我想",
              accommodations: "旅行时我需要的住宿包括",
              needsAndWhy: "我旅行时想带的其他东西包括",
              needToSave: "我需要为这次旅行存钱吗？",
              needToSaveYes: "是的,我需要节省 {{amount}}",
            },
          },
          planningWork: {
            sectionName: "规划工作",
            sectionHeader: "工作目标",
            addSubHeader: "让我们规划新的未来",
            whatILiked: "我喜欢什么",
            whatIDisliked: "我不喜欢的",
            formSubHeader: "让我们看看你喜欢或不喜欢你的工作经历",
            workGoal: "你的工作目标是什么？",
            profession: "是什么吸引你进入这个职业？",
            excites: "这个职业最让你兴奋的是什么？",
            goalLookLike: "当你考虑你的目标时,它是什么样的？",
            salary: "你想一年赚多少钱？",
            preferredWorkDays: "你喜欢什么工作日？",
            preferredWorkHours: "你喜欢的工作时间是什么时候？",
            haveNow: "你现在拥有什么可以让你走向未来？",
            priorExperience: "什么样的经历会支持你走向未来？",
            haveReference: "你认识这个专业的人吗？",
            referenceName: "他们是谁？",
            referenceWhere: "他们在哪里工作？",
            inLifeSupport: "你生命中谁能支持你实现这个目标？",
            support: "还有其他人可以帮助您实现目标吗？",
            commit: "你能承诺做些什么来帮助创造这个未来？",
            placeholderBudgetNeed: "插入您需要预算的内容",
            placeholderResponsible: "插入一个名字",
            placeholderHowOften: "插入多久执行一次",
            placeholderImportant: "插入为什么这很重要",
            workPreferences: '我的工作偏好',
            monday: "周一",
            tuesday: "周二",
            wednesday: "周三",
            thursday: "周四",
            friday: "星期五",
            saturday: "周六",
            sunday: "星期日",
            morning: "早晨",
            afternoon: "下午",
            evening: "晚上",
            overnight: "过夜",
            anytime: "任何时候",
            saveMessage: "听起来是一个很棒的工作目标！您要添加另一个工作目标吗？",
            budgetTable: {
              title: "你需要存什么钱来帮助你实现你的目标？",
              actionStep: "行动步骤",
              saveFor: "省钱",
              saveMoneyFor: "我需要存钱做什么？",
              responsible: "谁是负责的人？",
              howOften: "多常？",
              important: "为什么这很重要？",
              why: "为什么？",
              contact: "接触",
              me: "我",
              theyCanSupport: "他们可以支持我实现我的目标",
              thisWillHelpMe: "这将帮助我实现我的目标",
              deleteConfirm: "您确定要删除此预算行吗？",
              deleteSuccess: "好的,该行已被删除",
            },
            summary: {
              workGoal: "我的工作目标",
              profession: "是什么吸引我进入这个职业",
              excites: "最让我兴奋的是",
              goalLookLike: "当我想到我的目标时,它看起来像",
              haveNow: "什么将帮助我实现我的未来",
              preferredWorkDays: "首选工作日",
              preferredWorkHours: "首选工作时间",
              salary: "期望薪水",
              haveReference: "你认识这个专业的人吗？",
              referenceName: "他们是谁？",
              referenceWhere: "他们在哪里工作？",
              inLifeSupport: "你生命中谁能支持你实现这个目标？",
              support: "还有其他人可以帮助您实现目标吗？",
              commit: "你能承诺做些什么来帮助创造这个未来？",
            },
          },
          GTKYPage: {
            pageTitle: "开始了解你",
            GTKYVideo: "认识你的视频",
            likeAdmire: "喜欢和欣赏",
            peopleInMyLife: "我生命中的人",
            thingsIDo: "我喜欢做的有趣的事情",
            favoriteThings: "我最喜欢的东西",
            myRoutines: "我的日常",
            historyMap: "历史地图",
            travel: "旅行",
            viewComments: "查看评论"
          },
          myRoutines: {
            sectionName: "我的日常",
            subHeader: "告诉我们你的日常生活是什么样的",
            videoTitle: "我的日常 - 有用的视频",
            time: "时间",
            noTime: "没时间",
            day: "天",
            noDay: "没有一天",
            dailyError: "请输入时间和描述",
            weeklyError: "请输入日期和描述",
            successMessage: "谢谢！您的例程已添加",
            dailyRoutines: "你的日常生活是什么？",
            weeklyRoutines: "你每周的例行公事或仪式是什么？",
            dailyPlaceholder: "添加您每天以及何时执行的操作",
            weeklyPlaceholder: "添加你每周做的事情",
            dayStickyTitle: "我的一天",
            weekStickyTitle: "我的星期",
            deleteMessage: "您确定要删除此例程吗？",
            deleteSuccess: "好的，例程已删除",
            contributor: {
              subHeader: "告诉我们他们的日常生活是什么样的",
              dailyRoutines: "他们的日常生活是什么？",
              weeklyRoutines: "他们每周的例行活动或仪式是什么？",
              dailyPlaceholder: "添加他们每天和何时做的事情",
              weeklyPlaceholder: "添加他们每周做的事情",
              dayStickyTitle: "{{name}} 节",
              weekStickyTitle: "{{name}} 周",
            },
            days: {
              mon: "周一",
              tues: "周二",
              wed: "周三",
              thurs: "周四",
              fri: "星期五",
              sat: "周六",
              sun: "星期日"
            }
          },
          thingsIDo: {
            sectionName: "我喜欢做的有趣的事情",
            subHeader: "选择您的活动或创建一个新活动",
            indoors: "在室内",
            outdoors: "户外",
            frequencyCard: "我喜欢这样做：",
            likeMostCard: "我最喜欢这个活动的地方：",
            tellUsWhatYouDo: "告诉我们你喜欢做什么来娱乐！",
            indoor: "室内的",
            outdoor: "户外的",
            community: "在我的社区",
            whereToHaveFun: "你喜欢去哪里玩？",
            yourActivity: "你有什么喜欢做的事？",
            chosenActivity: "你选择的活动是",
            howOften: "你喜欢多久做一次？",
            aloneOrWithOthers: "你喜欢单独做这个活动还是和别人一起做这个活动？",
            attachedPeople: "你喜欢和谁一起做这个？",
            addThingIDo: "新活动",
            otherPeopleInvolved: "还有其他人想和你一起做这个活动吗？",
            anyPhotos: "你有什么有趣的 {{activity}}图片想分享吗？",
            like: "你最喜欢 {{activity}} 的什么？",
            youSelected: "您的活动：",
            saveMessage: "多么冒险！你想再添加一个吗？",
            deleteConfirm: "您确定要删除此活动吗？",
            deleteSuccess: "您的活动已成功删除",
            daily: "日常的",
            weekly: "每周",
            monthly: "月刊",
            oncePerYear: "一年一次",
            fewTimes: "一年几次",
            whenever: "只要我能",
            contributorQuestions: {
              frequencyCard: "他们喜欢这样做：",
              likeMostCard: "他们最喜欢这项活动的地方：",
              tellUsWhatYouDo: "告诉我们他们喜欢做什么来娱乐！",
              community: "在我的社区",
              whereToHaveFun: "他们喜欢在哪里玩乐？",
              yourActivity: "他们喜欢做什么？",
              chosenActivity: "他们选择的活动是",
              howOften: "他们喜欢多久做一次？",
              aloneOrWithOthers: "他们喜欢单独做这个活动还是和别人一起做这个活动？",
              attachedPeople: "他们喜欢和谁一起做这件事？",
              otherPeopleInvolved: "他们想和其他人一起做这个活动吗？",
              anyPhotos: "他们有你想分享的 {{activity}} 的有趣图片吗？",
              like: "他们最喜欢 {{activity}} 的什么？",
              youSelected: "他们的活动：",
              saveMessage: "",
            },
            activities: {
              reading: "阅读",
              writing: "写作",
              yoga: "瑜伽",
              pilates: "普拉提",
              videoGames: "视频游戏",
              cardGames: "纸牌游戏",
              boardGames: "棋盘游戏",
              painting: "绘画",
              instruments: "仪器",
              drawing: "绘画",
              art: "艺术",
              replicaModels: "复制模型",
              movies: "电影",
              tv: "电视",
              movieTheater: "电影院",
              diningOut: "外出就餐",
              yardSales: "庭院销售",
              antiquing: "仿古",
              mall: "去商场",
              shopping: "购物",
              sports: "运动的",
              walks: "散步",
              birdWatching: "观鸟",
              hiking: "远足",
              fishing: "钓鱼",
              gardening: "园艺",
              camping: "野营",
              boating: "划船",
              botany: "植物学"
            },
            summary: {
              activity: "我喜欢做什么",
              howOften: "我喜欢做这个活动",
              withWho: "我喜欢这样做",
              withWhoAlone: "我这样做",
              byMyself: "我自己",
              like: "我最喜欢的是",
              buttonText: "去做我喜欢做的事",
            },
          },
          likeAndAdmire: {
            sectionName: "喜欢和欣赏",
            sectionTitle: "头,心,手",
            subTitle: "将您的礼物拖放到您选择的类别中或创建您自己的类别",
            videoTitle: "喜欢和欣赏 - 有用的视频",
            filterAll: "所有喜欢和钦佩的东西（默认）",
            filterOthers: "别人喜欢和钦佩我的地方",
            filterMine: "我喜欢和欣赏自己的地方",
            resetAll: "重置全部",
            addNew: "添加新词",
            saveMessage: "干得好 {{firstName}},听到关于你的所有伟大的事情真是太好了！",
            head: "头",
            heart: "心",
            hand: "手",
            headExplanation: "头脑、大脑或思想的礼物；你知道的那些礼物",
            heartExplanation: "你觉得有联系或热爱的礼物",
            handExplanation: "您可以通过行动或制作实际提供的礼物",
            resetMessage: "您确定要全部重置吗？",
            resetSuccess: "好的，项目已重置",
            deleteMessage: "确定要删除此礼物吗？",
            deleteSuccess: "礼品已成功删除",
            contributor: {
              subTitle: "将他们的礼物拖放到您选择的类别或创建您自己的类别中",
              filterOthers: "别人喜欢和钦佩他们的地方",
              filterMine: "他们喜欢和欣赏自己的地方",
            },
            summary: {
              head: "头",
              heart: "心",
              hand: "手",
            },
          },
          peopleInMyLife: {
            sectionName: "我生命中的人",
            subHeader: "添加一个人或单击该人的姓名以添加有关他们的更多详细信息",
            adaptionDisclaimer: "关系图的适应",
            acquaintance: "熟人",
            acquaintances: "熟人",
            add: "添加人",
            addAsContributor: "添加为我的计划的贡献者",
            close: "关",
            contributor: "贡献者",
            contributorAdditions: "贡献者补充",
            coWorker: "同事",
            coWorkers: "合作伙伴",
            deletePerson: "您确定要将此人从您生活中的人中删除吗？",
            edit: "编辑人",
            family: "家庭",
            favoritePerson: "本月最喜欢的人",
            friend: "朋友",
            friends: "朋友们",
            howClose: "你和这个人有多近？",
            importantWhy: "为什么这个人对你很重要",
            involvedInSupport: "参与我的支持",
            mapPlace: "这将决定他们在你的人生地图上的位置",
            notClose: "不熟",
            paidSupport: "这个人有报酬吗？",
            paidSupportShort: "有偿支持",
            personIs: "这个人是……",
            relationshipToYou: "与你的关系",
            removePerson: "删除人",
            weAre: "我们是...",
            saveMessage: "你生命中的所有人都得救了,干得好,",
            me: '我',
            contributorQuestions: {
              howClose: "他们离这个人有多近？",
              importantWhy: "为什么这个人对他们很重要",
              mapPlace: "这将决定他们在我生活中的人物地图上的位置",
              relationshipToYou: "与他们的关系",
              theyAre: "他们是...",
              involvedInSupport: "参与他们的支持",
            },
            dialog: {
              nameError: "请输入姓名",
              personIsError: "请选择一个选项",
              emailError: "必须输入潜在贡献者的电子邮件地址",
              emailValidError: "请输入有效的电子邮件地址",
              sendInvitation: "发送邀请",
            },
          },
          favoriteThings: {
            sectionName: "我最喜欢的东西",
            subHeader: "首先选择一个类别来添加您喜欢的东西",
            contributorSubHeader: "请选择一个类别开始添加自己喜欢的东西",
            viewOnlySubHeader: "看看他们最喜欢的东西",
            addDialogTitle: "添加最喜欢的东西",
            editDialogTitle: "编辑你最喜欢的东西",
            categories: "类别",
            categoryList: "类别列表",
            myFavorites: "我最喜爱的",
            theirFavorites: "他们的最爱",
            place: "地方",
            placeToEat: "吃饭的地方",
            food: "食物",
            movie: "电影",
            book: "书",
            videoGame: "视频游戏",
            game: "游戏",
            sport: "运动",
            animal: "动物",
            music: "音乐",
            memory: "记忆",
            other: "其他",
            placeLabel: "你最喜欢去的地方",
            placeToEatLabel: "你最喜欢吃的地方",
            foodLabel: "你最爱的食物",
            movieLabel: "你最喜欢的电影",
            bookLabel: "你最喜欢的书",
            videoGameLabel: "你最喜欢的电子游戏",
            gameLabel: "你最喜欢的游戏",
            sportLabel: "你最喜欢的运动或运动队",
            animalLabel: "您最喜欢的宠物或动物类型",
            musicLabel: "你最喜欢的乐队、歌曲或流派",
            memoryLabel: "你最喜欢的回忆之一",
            otherLabel: "任何喜欢的东西",
            chooseAView: "选择一个视图",
            allThings: "所有喜欢的东西（默认）",
            thingsIAdded: "我添加的最喜欢的东西",
            thingsOthersAdded: "其他人添加了什么",
            treasureChestAlt: "最喜欢的东西宝箱",
            deleteConfirm: "你确定要删除这个最喜欢的东西吗？",
            deleteSuccess: "最喜欢的东西已被删除",
            saveAlertText: "谢谢你{{name}},了解你最喜欢的东西真是太好了！",
            plurals: {
              place: "地方",
              placeToEat: "吃饭的地方",
              movie: "电影",
              book: "图书",
              videoGame: "视频游戏",
              game: "游戏",
              sport: "运动的",
              animal: "动物",
              memory: "回忆",
            }
          },
          travelPlan: {
            sectionName: "旅行",
            sectionHeader: "旅行",
            subHeader: "分享您最喜欢的旅行地点或您真正想去的地方",
            travelLocally: "你喜欢在美国境内旅行还是国际旅行？",
            local: "在美国境内",
            international: "国际",
            frequencyCard: "我喜欢这样做：",
            likeMostCard: "我最喜欢这个地方的地方：",
            pageSubHeader: "告诉我们你喜欢去哪里旅行",
            travelWhere: "你喜欢去哪里旅游？",
            likeMost: "你最喜欢去 {{location}} 的什么地方？",
            likeToTravel: "你喜欢怎样旅行？",
            alone: "你喜欢一个人做还是和别人一起做？",
            howOften: "你喜欢多久来这里旅行一次？",
            anyoneElse: "还有其他人想和你一起旅行吗？",
            anyPictures: "你有什么有趣的{{location}}照片想要分享吗？",
            deleteMessage: "你确定要删除这个位置？",
            addTravelPlan: "新旅游目的地",
            addTravelContributor: "添加另一个您认为他们想去的地方",
            attachedPeople: "你喜欢和谁一起旅行？",
            boat: "船",
            bus: "公共汽车",
            car: "车",
            plane: "飞机",
            train: "火车",
            saveText: "多么冒险！你想再添加一个吗？",
            deleteConfirm: "您确定要删除此活动吗？",
            deleteSuccess: "您的活动已成功删除",
            contributorQuestions: {
              subHeader: "分享他们最喜欢的旅行地点或他们真正想去的地方",
              frequencyCard: "他们喜欢这样做：",
              likeMostCard: "他们最喜欢这个地方的地方：",
              pageSubHeader: "告诉我们他们喜欢去哪里旅行",
              travelLocally: "他们喜欢在美国境内旅行还是国际旅行？",
              travelWhere: "他们喜欢去哪里旅行？",
              likeMost: "他们最喜欢去 {{location}} 的什么地方？",
              likeToTravel: "他们喜欢怎样旅行？",
              alone: "他们喜欢单独做还是和别人一起做？",
              howOften: "他们喜欢多久来这里旅行一次？",
              anyoneElse: "还有其他人想和他们一起旅行吗？",
              anyPictures: "他们有你想分享的 {{location}} 的有趣照片吗？",
              attachedPeople: "他们喜欢和谁一起旅行？",
            },
            summary: {
              place: "我喜欢去旅行",
              travelWithSolo: "我喜欢在这里旅行",
              byMyself: "我自己",
              travelWith: "我喜欢和这里一起旅行",
              frequency: "我去那里",
              frequencyAnswer: "{{frequency}} 和我乘坐 {{transportation}}",
              favoriteThing: "我最喜欢去 {{place}} 旅游的是",
              buttonText: "去旅行",
            },
          },
          historyMap: {
            sectionHeader: "我的历史地图",
            subHeader: "告诉我们您生活中的重要里程碑、纪念日或事件",
            addMilestone: "添加里程碑",
            editMilestone: "编辑里程碑",
            sectionName: "历史地图",
            category: "类别",
            familyLife: "家庭生活",
            school: "学校",
            activities: "活动",
            friends: "朋友们",
            housing: "住房",
            work: "工作",
            relationships: "关系",
            chooseDate: "选择日期",
            milestone: "里程碑",
            details: "细节",
            back: "回到认识你",
            saveMessage: "很高兴了解你,谢谢分享！",
            validDateError: "请输入一个有效的日期",
            rangeDateError: "日期超出范围",
            deleteMessage: "确定要删除此里程碑吗？",
            deleteSuccess: "您的里程碑已成功删除",
          },
          contributor: {
            addToRelationshipMap: "添加到我的关系图",
            thisPersonIs: "这个人是一个......",
            howClose: "你和这个人有多近？",
            close: "关",
            notClose: "不熟",
            setPermissions: "设置贡献者权限",
            hasBeenEmailed: "已向您的投稿人发送了邀请",
          },
          happeningPage: {
            pageTitle: "发生在我的生活中",
            living: "住在我家",
            work: "工作",
            school: "学校",
            health: "健康和安全",
            happeningVideo: "发生在我的生活视频",
          },
          happeningHome: {
            pageTitle: "住在我家",
            subTitle: "让我们了解更多关于你住在哪里的信息！",
            singleStory: "单层住宅",
            multiLevel: "多层住宅",
            apartment: "公寓",
            facility: "设施",
            homeless: "无家可归",
            groupHome: "集团之家",
            car: "车",
            rvCamper: "房车/露营车",
            currentlyLive: "你现在住在哪？",
            currentlyLiveWith: "你现在和谁住在一起？",
            parents: "父母）",
            guardians: "监护人",
            siblings: "兄弟姐妹）",
            friends: "朋友们）",
            roommates: "室友",
            spouse: "伴侣",
            children: "小孩儿",
            alone: "我一个人住",
            dogs: "小狗）",
            cats: "猫）",
            other: "其他,请解释",
            explain: "请解释",
            tableTitle: "你喜欢和不喜欢你住的地方吗？",
            amenityHeader: "设施",
            selectHeader: "请选择喜欢或不喜欢",
            commentHeader: "注释",
            commentsLikes: "关于我喜欢的评论",
            commentsDislikes: "关于我不喜欢的评论",
            tellUsWhy: "请告诉我们为什么在这里",
            whatILike: '我喜欢我目前居住的地方',
            whatIDislike: '我不喜欢目前居住的地方',
            amenities: {
              publicTransportation: "公共交通工具",
              accessibility: "可访问性",
              neighborhood: "邻里",
              friendsFamily: "亲近亲友",
              shopping: "靠近购物",
              furniture: "家具",
              homeType: "家的类型",
              squareFootage: "方形镜头",
              housemates: "室友",
              livingAlone: "一个人生活",
              neighbors: "邻居",
              management: "物业管理",
              support: "技术支持人员",
              tellUsWhy: "请告诉我们原因",
            },
            summary: {
              currentlyLive: "我生活的地方",
              currentlyLiveWith: "我和谁住在一起",
            },
          },
          happeningSchool: {
            header: "学校",
            subHeader: "我们迫不及待想听到更多关于学校的信息,告诉我们你的经历",
            enrolledInSchool: "你现在在学校吗？",
            schoolNameOOS: "你上的是什么学校？",
            gradeLevelOOS: "你读了几级？",
            gradYearOOS: "你什么时候毕业的？",
            schoolLikeOOS: "你最喜欢学校的什么？",
            schoolDislikeOOS: "你不喜欢学校的什么地方？",
            volunteerOOS: "在学校期间,你在任何地方做过志愿者吗？",
            volunteerWhereOOS: "你在哪里做志愿者？",
            volunteerDislikeOOS: "你不喜欢什么？",
            volunteerLikeOOS: "你喜欢志愿服务的什么？",
            graduationGoalOOS: "它以前如何？",
            gradYearOtherOOS: "",
            goalYesOOS: "毕业后有什么想做的事情吗？",
            schoolName: "你念哪所学校？",
            gradeLevel: "你几年级？",
            gradYear: "你什么时候放学？",
            schoolLike: "你最喜欢学校的什么？",
            schoolDislike: "你不喜欢学校的什么？",
            volunteer: "你在任何地方做志愿者吗？",
            volunteerWhere: "你在哪里做志愿者？",
            volunteerDislike: "你不喜欢什么？",
            volunteerLike: "你喜欢志愿服务的哪些方面？",
            graduationGoal: "它是什么？",
            gradYearOther: "",
            goalYes: "毕业后有什么想做的吗？",
            summary: {
              schoolName: "我去的学校叫",
              schoolNameOOS: "我去的学校叫",
              gradeLevel: "我在年级",
              gradeLevelOOS: "我完成了年级",
              gradYear: "我将在学校完成",
              gradYearOOS: "我在学校完成了",
              schoolLike: "我喜欢学校的什么",
              schoolLikeOOS: "我喜欢学校的什么",
              schoolDislike: "我不喜欢学校的地方",
              schoolDislikeOOS: "我不喜欢学校的地方",
              volunteerWhere: "我在学校做志愿者",
              volunteerWhereOOS: "在我自愿参加的学校",
              volunteerLike: "我喜欢志愿服务",
              volunteerLikeOOS: "我喜欢志愿服务的原因",
              volunteerDislike: "我不喜欢志愿服务",
              volunteerDislikeOOS: "我不喜欢志愿服务",
              graduationGoal: "当我毕业时,我想",
              graduationGoalOOS: "当我毕业时,我想",
            },
          },
          happeningWork: {
            sectionName: "工作经验",
            subHeader: "告诉我们更多关于你的工作经历,我们迫不及待地想听到！",
            pastExperience: "请分享您过去的工作经验",
            pastExperienceLike: "你喜欢你过去的工作经历吗？",
            pastExperienceDislike: "你不喜欢你过去的工作经历的什么地方？",
            hasCurrentEmployment: "你现在在工作吗？",
            currentEmployment: "你现在在哪里工作？",
            currentDuration: "你在那里工作多久了？",
            currentLike: "你喜欢你目前的工作吗？",
            currentDislike: "你不喜欢你目前的工作什么？",
            summary: {
              pastExperience: "我过去工作过的地方",
              pastExperienceLike: "我喜欢我过去工作过的地方",
              pastExperienceDislike: "我不喜欢我过去工作过的地方",
              currentEmployment: "目前我在工作",
              currentDuration: "我在这里工作过",
              currentLike: "我喜欢我工作的地方",
              currentDislike: "我不喜欢我工作的地方",
            },
          },
          happeningHealth: {
            header: "健康和安全",
            subHeader: "让我们多了解你一点,请分享你喜欢的东西",
            contributorAdditionsToApprove: "您的贡献者已将 DME 和/或支持添加到您的计划中,您要批准他们吗？",
            medInfo: "有什么与健康有关的事情会影响你的生活吗？",
            allergies: "请列出您希望我们了解的过敏症",
            dmeSectionTitle: "您使用什么类型的耐用医疗设备和支架？",
            dmeContributorTitle: "他们使用什么类型的耐用医疗设备和支架？",
            sectionSubTitle: "选择一个按钮将其添加到您的列表中,单击列表中的 'x' 将其删除",
            supportsSectionTitle: "其他人如何支持你感觉和保持健康？",
            supportContributorTitle: "其他人如何支持他们感受和保持健康？",
            dmePostItTitle: "好的支持是什么样的",
            supportPostItTitle: "别人如何支持我",
            dmeOptions: {
              bloodSugarMonitors: "血糖监测仪",
              bloodSugarTestStrips: "血糖试纸",
              canes: "拐杖",
              commodeChairs: "马桶椅",
              continuousPassiveDevices: "连续被动运动装置",
              continuousPassiveAirwayPressureDevices: "持续气道正压通气 (CPAP) 设备",
              crutches: "拐杖",
              hospitalBeds: "病床",
              infusionPumps: "输液泵和用品",
              lancetDevices: "柳叶刀装置和柳叶刀",
              nebulizerMedications: "雾化器和雾化药物",
              oxygenEquipment: "氧气设备及配件",
              patientLifts: "病人电梯",
              supportSurfaces: "减压支撑面",
              suctionPumps: "吸泵",
              tractionEquipment: "牵引设备",
              walkers: "步行者",
              rollator: "滚轮",
              wheelchairMotorized: "轮椅 - 机动",
              wheelchairManual: "轮椅 - 手册",
              wheelchairStanding: "轮椅 - 斜倚/站立",
              scooterMotorized: "踏板车 - 机动",
              scooterManual: "踏板车 - 手册",
              assistiveTech: "辅助技术",
              translator: "翻译",
              brailleKeyboard: "盲文键盘",
              hearingAid: "助听器",
            },
            supportOptions: {
              cookMeals: "给我做饭",
              prepareMeals: "帮我准备饭菜",
              prepareMedication: "帮我准备药物",
              medicationSchedule: "帮我制定用药时间表",
              doctorTransportation: "帮我往返医生",
              createBudget: "帮我制定预算",
              beActive: "帮助激励我变得活跃",
            },
            summary: {
              medInfo: "我希望其他人了解的健康状况包括",
              allergies: "我想让别人知道的过敏包括",
              dmeSectionTitle: "我使用的持久医疗支持包括",
              supportsSectionTitle: "我生命中的人可以通过以下方式支持我",
            },
          },
          happeningGeneral: {
            sectionSave: "感谢您提供的信息 {{userFirstName}},您可以继续阅读您生活中发生的事情的另一部分！",
          },
          planSummary: {
            generatePdf: "生成 PDF",
            downloadPdf: "下载PDF",
            selectTravelPlan: "选择您的旅行计划",
            selectActivity: "选择您的活动",
            selectGoal: "选择一个目标",
            sections: {
              importantToMe: "什么对我很重要",
              importantForMe: "什么对我很重要",
              goals: "目标规划",
            },
            cardTitles: {
              likeAdmire: "人们喜欢和钦佩我的地方",
              people: "我生命中的重要人物",
              milestones: "我生命中的重要里程碑",
              favoriteThings: "我最喜欢的一些东西",
              thingsIDo: "我喜欢做的事情",
              travelPlan: "我想去的地方",
              dailyRoutines: "我的日常",
              weeklyRoutines: "我的每周例行公事",
              happeningHome: "住在我家",
              happeningWork: "我的工作经历",
              happeningSchool: "我的学校经历",
              happeningHealth: "我的健康与安全",
              homeGoals: "我的住房目标",
              workGoals: "我的工作目标",
              healthGoals: "我的健康目标",
              funGoals: "我的有趣目标",
              relationshipGoals: "我的关系目标",
              travelGoals: "我的旅行目标",
            },
            cardDescriptions: {
              likeAdmire: "以下是我喜欢和欣赏自己的地方，以及我的投稿人欣赏我的地方",
              people: "这是我生命中最重要的人。",
              milestones: "发现我一生中最引以为豪的所有重要里程碑！",
              favoriteThings: "这些只是我最喜欢的一些东西",
              thingsIDo: "以下是我喜欢做的一些有趣的事情！",
              travelPlan: "找出我喜欢去哪里旅行，和谁一起旅行，以及我喜欢和什么一起旅行",
              dailyRoutines: "发现什么对我来说每天都很重要，什么才是美好的一天！",
              weeklyRoutines: "了解更多关于我的偏好、仪式和惯例的信息！",
              happeningHome: "分享我目前的生活状况，我喜欢和不喜欢什么对我来说很重要。",
              happeningWork: "详细了解我过去的工作经历，我喜欢和不喜欢的地方！",
              happeningSchool: "了解更多关于我和我在学校的经历！",
              happeningHealth: "以下是我觉得很舒服的关于我的健康、安全以及其他人可以做些什么来支持我的分享。",
              homeGoals: "了解更多关于我想住在哪里",
              workGoals: "了解我的梦想工作",
              healthGoals: "了解我想如何规划我的健康",
              funGoals: "找出我将来想做的有趣的事情",
              relationshipGoals: "详细了解我想如何与他人联系",
              travelGoals: "找出我希望去哪里旅行",
            },
            noContent: {
              milestones: "没有添加任何里程碑。点击下面的按钮开始吧！",
              favoriteThings: "",
              thingsIDo: "没有创建我为乐趣而做的事情。单击下面的按钮创建一个！",
              travelPlan: "尚未创建任何旅行地点。单击下面的按钮创建一个！",
              dailyRoutines: "没有添加每日例行程序。点击下面的按钮开始吧！",
              weeklyRoutines: "没有添加每周例行程序。点击下面的按钮开始吧！",
              happeningHome: "没有记录住在我家的信息。点击下面的按钮开始吧！",
              happeningWork: "没有记录工作经验信息。点击下面的按钮开始吧！",
              happeningSchool: "没有记录学校信息。点击下面的按钮开始吧！",
              happeningHealth: "未记录任何健康与安全信息。点击下面的按钮开始吧！",
              homeGoals: "没有创建住房目标。单击下面的按钮创建一个！",
              workGoals: "未创建任何工作目标。单击下面的按钮创建一个！",
              healthGoals: "未创建任何健康目标。单击下面的按钮创建一个！",
              funGoals: "没有创建有趣的目标。单击下面的按钮创建一个！",
              relationshipGoals: "未创建任何关系目标。单击下面的按钮创建一个！",
              travelGoals: "未创建任何旅行目标。单击下面的按钮创建一个！",
            },
          },
          contributorGeneric: {
            entries: "贡献者条目",
            noAccess: "您无权访问此部分",
            includedByPlanOwner: '包括计划所有者',
            notIncluded: "计划所有者不包括在内",
            sectionNotComplete: "计划所有者尚未完成此部分",
            thankYou: "感谢您的贡献！您的添加已发送给计划所有者以供批准",
          },
          contributorComments: {
            addComment: "添加评论",
            allComments: "所有评论",
            commentPrompt: "根据你所看到的,你有什么想要评论的吗？给他们留下一个有趣的评论或注释！",
            yourComment: "你的评论",
            approved: "批准的评论",
            pendingApproval: "感谢您的贡献！您的评论已发送给计划所有者审批",
            pendingNoThankYou: "您的评论已发送给计划所有者以供批准",
            comments: "注释",
            toCheck: '去检查',
            deleteConfirm: '你确定要删除此评论？',
            deleteSuccess: "好的，评论已删除",
            notApprovedMessage: '您的评论未被计划所有者批准。您可以编辑评论并重新提交评论，或删除您的评论。',
            notIncluded: '不包含',
          },
          durationDropdown: {
            notSelected: "未选中的",
            zeroMonths: "0 - 6 个月",
            sixMonths: "6 - 12 个月",
            oneYear: "1 - 2年",
            twoYears: "2年以上",
          },
          navigationButtons: {
            gtky: "回到认识你",
            thingsIDo: "回到我做的事情",
            happening: "回到发生在我的生活中",
            planningFun: "返回规划乐趣",
            planningHealth: "返回规划健康",
            planningHome: "返回规划主页",
            planningRelationships: "返回规划关系",
            planningTravel: "返回计划旅行",
            planningWork: "返回规划工作",
          },
          generic: {
            add: "添加",
            addAnother: "加上另一个",
            addComment: "添加评论",
            addNew: "添新",
            addNewEntry: "添加新条目",
            addYourOwn: "添加你自己的",
            addedBy: '添加',
            addressPrimary: "地址1",
            addressSecondary: "套房、公寓、单元#",
            alone: "独自的",
            approve: "批准",
            back: "后退",
            backToDashboard: "返回计划仪表板",
            cancel: "取消",
            cancelled: "取消",
            city: "城市",
            clear: "清除",
            country: "国家",
            comments: "贡献者评论",
            createdBy: "由...制作",
            createdOn: "创建于",
            dailyWithCapital: "日常的",
            date: "日期",
            delete: "删除",
            deleteGoalConfirm: "您确定要删除此目标吗？",
            deleteGoalSuccess: "您的目标已成功删除",
            details: "细节",
            disapprove: "不赞成",
            dislike: "不喜欢",
            edit: "编辑",
            editGoal: "编辑目标",
            email: "电子邮件",
            emailWithCapitial: "电子邮件",
            error: '错误',
            faceTime: "视频对话",
            firstName: "名",
            addGeneralComment: "单击以添加一般评论",
            generalComments: "普通的留言",
            generalComment: "点击添加一般评论",
            goal: "目标",
            goToItem: "转到项目",
            goToGoal: "去目标",
            goToSection: "转到 {{section}}",
            inPerson: "亲自",
            instructionalVideo: "教学视频",
            language: "语言首选项",
            lastName: "姓",
            like: "喜欢",
            loading: "加载中...",
            middleName: "中间名字",
            name: "姓名",
            newAdditions: "贡献者添加了新内容",
            no: "不",
            noNotifications: "没有通知",
            noPeopleMessage: "将朋友和家人添加到您的“我生命中的人”中以在此处选择他们！",
            noThanks: "不，谢谢",
            okay: "好的",
            other: "其他",
            pleaseExplain: "请解释",
            phoneNumber: "电话号码",
            remove: "消除",
            removeImage: "删除图像",
            review: "审查",
            save: "节省",
            saveAndSendUpdates: "保存并发送更新",
            saveAndSendInvite: "保存并发送邀请",
            saveAnother: "保存并添加另一个",
            saveNoUpdates: "不发送更新",
            search: "搜索",
            selectAGoal: "选择一个目标",
            state: "状态",
            submit: "提交",
            success: "成功",
            talking: "说",
            thankYou: '谢谢你',
            title: "标题",
            uploadImage: "上传图片",
            viewComment: "查看评论",
            weeklyWithCapitial: "每周",
            with: '和',
            withOthers: "和其他人",
            yes: "是的",
            zip: "邮政编码",
            include: "包括",
            notInclude: "不包括",
            approveAll: "全部批准",
            denyAll: "全部拒绝",
            removeFromPlan: "从计划中删除",
            said: "说",
            errors: {
              errorPageTitle: '有一个错误！',
              errorPageSubTitle: '对于那个很抱歉。请待会再过来。，',
              error404: '404页面不存在，',
              saveError: '抱歉，保存您的条目存在错误。请稍后再试。，',
              deleteError: '抱歉，删除您的条目存在错误。请稍后再试。，',
              createPlanError: '抱歉，创建您的计划存在错误。请稍后再试。',              
            },
          },
        },
      },
      chineseTraditional: {
        translation: {
          contact: {
            contactUs: '聯繫我們',
            message: '信息',
            send: '發送',
            sayHello: '問好',
            saveTitle: '您的電子郵件已成功發送！',
            saveMessage: 'Center On Me 的代表將盡快與您聯繫。',
            emailError: '請輸入有效的電子郵件地址',
            phoneError: '請輸入一個有效的電話號碼',
          },
          password: {
            lowercase: "包含一個小寫字母",
            uppercase: "包含一個大寫字母",
            eightCharacters: "至少 8 個字符長",
            number: "包含一個數字",
            specialCharacter: "包含特殊字符",
            passwordsMatch: "密碼匹配",
            errorCannotMatch: "舊密碼和新密碼不能匹配",
          },
          saveProgress: {
            uploadPhoto: '上傳圖像',
            updateProfile: '更新您的個人資料',
          },
          agreements: {
            pleaseRead: "保護您的信息是我們的首要任務。請閱讀我們的",
            privacyTitle: "網站隱私",
            termsTitle: "使用條款",
            ifYouAgree: `如果您同意，請輸入您的名字和姓氏，然後單擊“我同意”`,
          },
          settings: {
            pageTitle: "設置",
            subTitle: "您可以按照自己的方式定制您的計劃！",
            saveSettings: "保存設置",
            planVideo: "我的計劃視頻",
            contributorPage: "管理貢獻者",
            colorPalette: "我的調色板",
            changePassword: {
              changePassword: "更改密碼",
              subTitle: "讓我們更改密碼！",
              currentPassword: "當前密碼",
              newPassword: "新密碼",
              confirmPassword: "確認密碼",
              currentIncorrect: "輸入的密碼不正確",
              samePasswordError: "哎呀,請使用新密碼",
              successMessage: "密碼已更改！"
            },
            colorPalettePage: {
              pageTitle: "颜色调色板",
              subTitle: "在創建計劃時選擇您希望看到的顏色！",
              myColorPalette: "我的調色板",
              select: "選擇您喜歡的調色板",
              brainstormBlue: "藍色頭腦風暴",
              planningPurple: "紫色規劃",
              organizingOrange: "組織橙",
              gamePlanGreen: "遊戲計劃綠色",
              primaryColorTooltip: "文本、按鈕和圖標",
              secondaryColorTooltip: "標題、選定按鈕、表格",
              contributorColorTooltip: "貢獻者條目",
              textColorTooltip: "文本",
              saveMessage: "您的調色板已更新！"
            },
            manageContributors: {
              sectionHeader: "管理我的貢獻者和通知",
              subHeader: "點擊貢獻者卡片設置或編輯他們的權限",
              myContributors: "我的貢獻者",
              dialogConfirm: "一切看起來都正確嗎？",
              dialogTitle: "貢獻者信息",
              inviteSuccess: "已向您的投稿人發送了邀請",
              updateSuccess: "貢獻者信息已更新,並已發送電子郵件通知貢獻者",
              updateSuccessNoEmail: "貢獻者信息已更新！",
              lastContribution: "最後貢獻：",
              noContributions: "還沒有貢獻",
              addContributor: "添加貢獻者",
            },
            contributorNotifications: {
              myNotifications: "我的通知",
              letsSetPreferece: "讓我們設置您的偏好",
              howToNotify: "當貢獻者添加到您的計劃中時,您希望如何收到通知？",
              howOften: "您希望多久收到一次通知？",
              saveAlert: "您的通知設置已更新",
            },
            managementPage: {
              sectionHeader: "我的貢獻者",
              noContributorsContext: "您可以授予貢獻者對您計劃的不同部分的權限,然後他們可以對這些部分進行評論和其他補充！",
            },
            profile: {
              pageTitle: "名稱和詳細信息",
              sectionHeader: "歡迎以我為中心",
              subHeader: "請花點時間告訴我們一些關於你自己的事情",
              profilePicture: '個人資料圖片',
              name: "姓名",
              languageAndNumber: "語言和電話號碼",
              address: "地址",
              firstNameError: "名字是必填項",
              lastNameError: "姓氏是必填項",
              phoneError: "需要有效的電話號碼",
              saveConfirmationMsg: "這看起來對嗎？",
              backToSettings: "返回設置",
              backToPlans: "回到我的計劃",
              saveMessage: "感謝您添加信息！",
              firstTimeSaveMessage: '認識你真是太好了！現在，讓我們開始計劃。',
              notQuite: "不完全的",
              speechToText: '對文字的講話',
              speechToTextEnabled: '是的，我想用語音進行文字！',
              speechToTextDisabled: '不，我不想用語音來發短信',
              speechToTextWarning: '目前僅提供英語',              
              languages: {
                english: "英語",
                armenian: "亞美尼亞",
                chineseSimple: "簡體中文",
                chineseTraditional: "中國傳統的",
                farsi: "波斯語",
                hmong: "苗族",
                korean: "韓國人",
                spanish: "西班牙語",
                tagalog: "他加祿語",
                vietnamese: "越南語",
              },
            },
            account: {
              pageTitle: '帳戶',
              subTitle: '您管理訂閱的地方',
              benefitsTitle: '付費訂閱福利',
              manageTitle: '管理訂閱',
              benefits: {
                multiplePlans: '創建多個計劃',
                fullAccess: '完全訪問計劃頁面',
                printablePDF: '可打印的PDF計劃摘要',
                uploadPhotos: '上傳照片',
                planVideo: '創建計劃視頻以與您的貢獻者分享',
                backgroundImage: '選擇自定義背景圖像',
                themeColor: '選擇您的個人資料主題顏色',
              },
              currentPlan: '當前計劃',
              trial: '30天試用',
              subscription: '12個月訂閱',
              noSubscription: '沒有當前訂閱',
              expirationDate: '截止日期',
              expiresOn: '您的訂閱將過期',
              paymentMethod: '付款方式',
              supportContact: '支持聯繫',
              supportContactIs: '您的支持聯繫人為{{contactName}}',
              editSupportContact: '編輯支持聯繫人',
              supportContactLabel: '支持聯繫',
              contactEmailLabel: '聯繫電子郵件地址',
              contactPhoneLabel: '聯繫電話',
              saveSupportMessage: '您的支持聯繫已更新。',
              supportContactError: '需要支持聯繫',
              contactEmailError: '需要支持聯繫電子郵件',
              contactPhoneError: '支持聯繫人電話號碼',                
              subscribeButton: '訂閱',
              renewButton: '續訂訂閱',
              requestRenewal: '請求續訂您的訂閱',
              renewDialogTitle: '更新您的訂閱',
              selectedPlan: '選定計劃',
              billed: '計費',
              payment: '支付',
              howItWorks: '您的訂閱如何工作',
              billingInformation: '賬單信息',
              billingStartPlanning: '您可以在接受付款後立即開始計劃',
              expirationExplanation: '訂閱期限到期後，訂閱將自動終止，而無需任何一方要求的任何進一步的通知或訴訟。將沒有額外的費用，除非您購買新訂閱，否則您將無法訪問與訂閱相關的利益。',
              termsConditions: '條款和條件',
              trialToMemberMessage: '歡迎來到我的中心！現在，您可以訪問我計劃中心的所有好處。',
              memberRenewalMessage: '您的計劃已續簽了12個月',
              contactEmail:  '聯繫電子郵件地址',
              contactPhone: '聯繫電話',
              contactSuccessMessage: '您的支持聯繫已更新',
              subscribeDialogTitle: '訂閱我的中心',
              trialExpireOn: '您的審判將在',
              expiredOn: '您的訂閱過期',
              paymentSuccessMessage: '謝謝你！希望您喜歡我的中心',
              today: "今天",
              todayDescription: "解鎖我的中心完全訪問我",
              tenMonths: "10個月",
              tenMonthDescription: "提醒您的計劃即將到期",
              twelveMonths: "12個月",
              twelveMonthDescription: "您的訂閱結束",
              showPaymentOptions: '顯示付款選項',
            },
            profilePictureDialog: {
              title: "添加您的個人資料圖片",
              uploadPhoto: "上傳照片",
            },
            backgroundImage: {
              pageTitle: '背景圖',
              subTitle: '選擇背景圖像或上傳您的',
              hideGrid: '隱藏圖像網格',
              showGrid: '顯示圖像網格',
              selectImage: "選擇背景圖像",
              noImage: "沒有圖像",
              newImageWarning: "選擇新圖像將刪除您上傳的圖像。你想繼續嗎？",
              warningConfirm: "是的，我想要一張新圖片",
              warningCancel: "不，保持我的形象",
              uploadError: "抱歉，更新背景圖片時出錯",
            },
            planVideoPage: {
              sectionHeader: "我的計劃視頻",
              subHeader: "製作視頻向貢獻者介紹您的計劃",
              saveAlertText: "感謝您上傳計劃視頻！",
              explainTextGreeting: "你好 {{firstName}},",
              explainTextP1: `				
                這個空間是供您上傳您的貢獻者將要上傳的視頻				
                一旦他們登錄 Center On Me 為您的計劃做出貢獻,請立即查看,				
                該視頻是您解釋為什麼要創建此視頻的機會				
                計劃並給貢獻者一個你想像的未來的例子				
                為自己制定這個計劃,`,
              explainTextP2: `				
                在手機、電腦或平板電腦上錄製視頻並上傳				
                這裡,您的視頻必須少於十 (10) 分鐘,提示				
                關於如何製作視頻,請觀看示例視頻或選擇				
                        "創建我的視頻的提示",`,
              explainTextEnding: "謝謝,",
              explainTextSignature: "以我為中心",
              tipsButton: "創建我的視頻的提示",
              exampleVideoTitle: "示例視頻",
              alvaVideoTitle: "阿爾瓦示例",
              uploadButton: "上傳您的視頻",
              removeButton: "刪除我的視頻",
              videoError: "您的瀏覽器不支持 video 標籤,",
              lengthError: "錯誤：最大視頻長度為 10 分鐘",
              tips: {
                dialogTitle: "創建視頻的提示",
                tipsTitle: "錄製和上傳視頻的提示：",
                tipOne: "在可以看到整張臉的燈光下錄製視頻",
                tipTwo: "在創紀錄之前想想你想說什麼",
                tipThree: "將視頻保持在十 (10) 分鐘以內",

                tipFour: `如果您在上傳視頻後遇到問題,請發送電子郵件至 centeronme@mainsl.com 並提供您的用戶名（電子郵件地址）和您上傳的視頻文件的名稱（例如"Myplanvideo.mov)`,

                examplesTitle: "在您的視頻中回答的示例問題：",
                exampleOne: "你為什麼邀請人們為你的計劃做出貢獻？",
                exampleTwo: "你希望通過你的計劃完成什麼？",
                exampleThree: "你想計劃什麼？",
                exampleFour: "您的貢獻者如何支持您制定計劃？",
              },
              contributor: {
                sectionHeader: "他們的計劃視頻",
                subHeader: "",
                noVideo: "計劃所有者尚未添加計劃視頻",
              },
            },
            knowledgeCenter: {
              pageTitle: '知識中心',
              subTitle: '單擊鏈接以了解有關使用我們網站的更多信息',
              videoTab: '視頻',
              faqTab: '常問問題',
              contactTab: '聯繫我們',
              videos: {
                gettingStartedTitle: '入門',
                profileSettings: '配置文件設置',
                landingPageAndBadges: '登陸頁面和徽章',
                contributorsTitle: '貢獻者',
                addingContributors: '添加貢獻者',
                managingContributors: '管理貢獻者',
                howToContribute: '如何貢獻',
                gettingToKnowYouTitle: '開始了解你',
                likeAndAdmire: '喜歡和欣賞',
                people: '我生命中的人',
                favorites: '我最喜歡的東西',
                thingsIDo: '我為了好玩而做的事情',
                routines: '我的日常',
                historyMap: '歷史地圖',
                travelPlan: '旅行',
                happeningNowTitle: '現在發生了什麼事',
                healthAndSafety: '健康和安全',
                livingInHome: '住在我家',
                work: '工作',
                school: '學校',
                startPlanningTitle: '讓我們開始計劃',
                workGoals: '工作目標',
                healthGoals: '健康目標',
                livingGoals: '生活目標',
                travelGoals: '旅行目標',
                funGoals: '有趣的目標',
                relationshipGoals: '關係目標',
                planSummaryTitle: '計劃摘要',
                webVersion: '網絡版和導出為 PDF',
                dialogTitle: '教學視頻',
              },
              faq: {
                pageTitle: '經常問的問題',
                usingTheKnowledgeCenter: '使用知識中心',
                passwordAndUsername: '密碼和用戶名',
                managingPlans: '管理計劃',
                planOwnersAndContributors: '計劃所有者和貢獻者',
                usingTheSite: '使用網站',
                accessingSupport: '訪問支持',
                registration: '註冊和訂閱',
                howToUse: '如何使用知識中心？',
                whoToContact: '如果我有視頻中未提及的問題，我可以聯繫誰？',
                videoLanguage: '我可以用英語以外的語言觀看這些視頻嗎？',
                findVideo: '如何找到我正在尋找的視頻？',
                passwordRequirements: '密碼要求是什麼？',
                changePassword: '如何更改我的密碼？',
                changeUsername: '如果我想更改我的登錄名/電子郵件地址怎麼辦？',
                howManyPlans: '註冊後可以創建多少個計劃？',
                sharePlan: '我可以與任何人分享我的計劃嗎？有沒有辦法在平台上通過電子郵件發送我的計劃？',
                deletePlan: '如果我不小心刪除了計劃怎麼辦？',
                whatIsPlanOwner: '誰/什麼是“計劃所有者”',
                whatIsContributor: '誰/什麼是“貢獻者”？',
                contributorLimit: '我可以添加到我的計劃中的貢獻者是否有限制？',
                approveComment: '如果我批准投稿人評論，他們會收到通知嗎？',
                denyComment: '如果我拒絕投稿人的評論，他們會收到通知嗎？',
                updateProfile: '我可以隨時更新我的個人資料詳情嗎？',
                howManyActivities: '我可以在每個部分下添加多少活動？',
                listSomeoneAsResponsible: '如果我將某人列為實現目標的責任人，他們會收到通知嗎？',
                editActivity: '如何編輯活動？',
                deleteActivity: '如何刪除活動？',
                accessibilityFeatures: '該平台提供哪些可訪問性功能？',
                forgetToSave: '如果我忘記保存我正在處理的內容會怎樣？',
                useOnTablet: '我可以使用平板電腦制定計劃嗎？',
                useOnPhone: '我可以使用手機制定我的計劃嗎？',
                phoneApp: '有我需要下載的手機應用程序嗎？',
                needInternet: '我是否需要訪問互聯網才能製定我的計劃？有離線模式嗎？',
                whatAreBadges: '什麼是徽章？',
                helpWithPlan: '如果我的計劃需要幫助，我應該聯繫誰？',
                issuesAccessingPlan: '如果我在訪問我的計劃時遇到問題，我應該聯繫誰？',
                issueWithPlanVideo: '如果我無法上傳我的計劃視頻，我應該聯繫誰？',
                forgotUsername: '如果我忘記了登錄用戶名怎麼辦？我該聯繫誰？',
                wasRegistrationSuccessful: '我怎麼知道我的註冊成功了？',
                renewPlan: '我怎麼知道我需要更新我的計劃？',
                howToCancel: '如何取消訂閱？',
              },
              faqAnswers: {
                howToUse: `單擊您想了解更多信息的部分或鏈接。您將被帶到一個單獨的頁面，您將能夠觀看一段視頻，引導您瀏覽網站的該部分。`,
                whoToContact: `請隨時使用“聯繫我們”選項卡與我們聯繫！在該選項卡中，您會找到要填寫的表格，該表格將向我們發送電子郵件。請在您的電子郵件中包含您有疑問的頁面和視頻。我們會盡快回复您。`,
                videoLanguage: `目前，這些視頻僅提供英文版本。已為所有視頻提供隱藏式字幕。`,
                findVideo: `請在搜索欄中輸入您要查找的頁面，然後會彈出視頻鏈接。視頻按頁面名稱組織。如果您找不到您正在尋找的視頻，請使用“聯繫我們”選項卡與我們聯繫。`,
                passwordRequirements: `您的密碼必須包括：一個小寫字母、一個大寫字母、一個數字、一個特殊字符，並且至少為 8 個字符。`,
                changePassword: `您可以隨時通過進入個人資料設置並選擇“更改密碼”按鈕來更改密碼。`,
                changeUsername: `您將無法更改您的電子郵件地址。`,
                howManyPlans: `您可以創建多少個計劃沒有限制。但是，您在計劃中輸入的信息（包括計劃貢獻者）不會從一個計劃轉移到另一個計劃。`,
                sharePlan: `計劃所有者可以隨時與任何人分享他們的計劃。在計劃摘要頁面的右上角，有一個按鈕，上面寫著“導出為 PDF”。單擊該按鈕時，您的計劃將被下載並可以打印或通過電子郵件發送給您選擇的任何人。`,
                deletePlan: `當您嘗試刪除計劃時，Center On Me 將提供提示以確保您確實要刪除您的計劃。如果您仍然選擇刪除您的計劃，則無能為力，您必須從頭開始重新創建您的計劃。`,
                whatIsPlanOwner: `“計劃所有者”是計劃的焦點人物，是製定計劃的人。計劃所有者應始終是該計劃的重點！`,
                whatIsContributor: `貢獻者是“計劃所有者”邀請為其計劃做出貢獻的人。計劃所有者可以完全控制貢獻者可以查看和/或評論其計劃的哪一部分。所有貢獻者的評論在被納入計劃之前必須經過審查和批准。`,
                contributorLimit: `您可以擁有多少個貢獻者是沒有限制的。對於您創建的每個計劃，您需要分別添加每個貢獻者。`,
                approveComment: `不，當評論被批准時，不會通知貢獻者。`,
                denyComment: `不，當評論被拒絕時，不會通知貢獻者。`,
                updateProfile: `您可以通過轉到個人資料設置隨時更新您的個人資料詳細信息。在個人資料設置中，您將能夠更改您的姓名和詳細信息、個人資料圖片、背景和調色板。`,
                howManyActivities: `您可以在每個部分下添加的活動或目標的數量沒有限制。`,
                listSomeoneAsResponsible: `如果您將某人列為實現目標的責任人，他們將不會收到通知。如果計劃所有者需要有目標的支持，他們將需要與該人溝通並分享他們的計劃，如果他們覺得這樣做很舒服。`,
                editActivity: `您可以隨時通過單擊卡片來編輯活動或目標。表格將打開，任何信息都可以更改或更新。`,
                deleteActivity: `您可以隨時通過單擊卡片刪除活動或目標。表格將打開，可以通過一直向下滾動到底部並選擇紅色的“刪除”按鈕來刪除目標。`,
                accessibilityFeatures: `Center On Me 使用 Userway 在網站內提供輔助功能。其中一些輔助功能正在更改對比度、字體大小、光標引導以及文本或空間對齊方式。您可以通過選擇左側每個頁面上存在的圓形“人”圖標來選擇您的功能。`,
                forgetToSave: `當您在站點內創建不同的頁面時，點擊保存非常重要。信息不會自動保存。`,
                useOnTablet: `是的！可以從平板電腦或智能手機訪問 Center On Me。`,
                useOnPhone: `是的！可以從智能手機訪問 Center On Me。該網站已創建為移動響應，因此某些數據可能不像平板電腦或使用您的計算機那樣容易閱讀。`,
                phoneApp: `不，Center On Me 沒有應用程序。您可以通過打開網絡瀏覽器應用程序並訪問 www.centeronme.com 從智能手機訪問 Center On Me。`,
                needInternet: `是的，Center On Me 需要互聯網才能製定計劃。 Center On Me 沒有離線模式。`,
                whatAreBadges: `徽章是用戶和 Center On Me 跟踪您在站點內的進度的一種方式。每次完成一個頁面，您都會獲得一個徽章，並且可以在登錄頁面上查看徽章。單擊徽章以獲得有趣的驚喜！`,
                helpWithPlan: `如果您需要支持，請訪問知識中心內的“聯繫我們”選項卡。將向 Center On Me 團隊發送一封電子郵件，我們會盡快回复。`,
                issuesAccessingPlan: `如果您需要支持，請訪問知識中心內的“聯繫我們”選項卡。將向 Center On Me 團隊發送一封電子郵件，我們會盡快回复。`,
                issueWithPlanVideo: `首先，檢查視頻上傳提示，確保符合視頻要求。如果您仍需要支持，請訪問知識中心內的“聯繫我們”選項卡。將向 Center On Me 團隊發送一封電子郵件，我們會盡快回复。`,
                forgotUsername: `您的用戶名是您的電子郵件地址。如果您需要支持，請訪問知識中心內的“聯繫我們”選項卡。將向 Center On Me 團隊發送一封電子郵件，我們會盡快回复。`,
                wasRegistrationSuccessful: `如果您的付款成功，您將收到一封電子郵件收據到您關聯的 PayPal 帳戶。您還將在 PayPal 應用程序中找到收據。請參閱下面的付款電子郵件收據示例。`,
                renewPlan: `用戶將在 3 個月、6 個月和 10 個月時收到電子郵件提醒，以提醒用戶您的訂閱何時到期，並將提供有關如何續訂計劃的說明。`,
                howToCancel: `訂閱為期一年。您不能取消為期一年的訂閱。當需要更新您的計劃時，我們會向您發送一封電子郵件，您可以選擇屆時不更新您的計劃。`,
              }
            },
          },
          lockedFeatures: {
            photoLock: '上傳照片所需的訂閱',
            videoLock: '上傳視頻所需的訂閱',
            pdfLock: '下載PDF所需的訂閱',
            sectionLock: '選擇訂閱選項以訪問此功能。',
            sectionLockSaveMessage: '本節不能由試用用戶修改',
            createPlanLock: '選擇一個創建多個計劃的訂閱選項',
            expiredLock: '選擇訂閱選項以訪問此功能',
            expiredAlert:  '您的訂閱過期。請續訂查看本節。',            
          },
          permissions: {
            theContributor: "貢獻者",
            cannotView: "無法查看",
            canView: "可以查看",
            theSection: "這部分",
            toTheSection: "到該部分",
            and: "和",
            but: "但",
            cannotComment: "無法添加評論",
            canComment: "可以添加評論",
            gtkyTitle: "開始了解你",
            happeningTitle: "你的生活中發生了什麼",
            planningTitle: "規劃",
          },
          breadcrumbs: {
            planning: "讓我們開始計劃",
            happening: "正在發生",
            gettingToKnowYou: "開始了解你",
            myRoutines: "我的日常",
            myPeople: "我生命中的人",
            likeAndAdmire: "喜歡和欣賞",
            work: "工作",
            school: "學校",
            home: "家",
            fun: "樂趣",
            travel: "旅行",
            health: "健康",
            relationships: "關係",
            goal: "我的目標",
            historyMap: "歷史地圖",
            favoriteThings: "我最喜歡的東西",
            add: "添新",
            profile: "輪廓",
            settings: "設置",
            contributors: "貢獻者和通知",
            thingsIDo: "我為了好玩而做的事情",
            planningVideo: "我的計劃視頻",
            planSummary: "計劃摘要",
            changePassword: "更改密碼",
            knowledgeBase: "知識中心",
            colorPalette: "調色板",
            myActivity: "我的活動",
          },
          userMenu: {
            allPlans: "所有計劃",
            planDashboard: "計劃儀表板",
            planSummary: "計劃摘要",
            settings: "設置",
            subscribe: "訂閱",
            logout: "登出"
          },
          timeoutAlert: {
            attention: "注意力",
            description: "您即將退出",
            stillPlanning: "我還在計劃中！",
            logMeOut: "註銷我",
            warningOnLogout: "未保存的數據將丟失",
          },
          allPlans: {
            pageTitle: "快速瀏覽您的計劃",
            subTitle: "選擇一個開始",
            iContributeTo: "我參與的計劃",
            myPlans: "我的計劃",
            newContributeTo: "為新計劃做出貢獻",
            createNewPlan: "制定新計劃",
            planOwner: "計劃所有者",
            planCreated: "創建計劃",
            lastViewed: "上次查看",
            planVideo: "計劃視頻",
            manageContributors: "管理貢獻者和通知",
            planSummary: "計劃摘要",
            deletePlan: "刪除計劃",
            createPlanDialog: {
              dialogTitle: '创建一个新计划',
              explainerText: '为您的新计划选择一个名称',
              inputLabel: '计划名称',
              createMessage: '启动新计划将不会涵盖您以前计划的任何工作或贡献者',
            },
            contributeToPlanDialog: {
              dialogTitle: '为计划做出贡献',
              explainerText: '输入您从计划所有者那里收到的代码',
              inputLabel: '访问代码',
              errorMessage: '访问代码与任何计划都不匹配。请再试一次',
            }
          },
          deletePlan: {
            dialogTitle: "刪除我的計劃",
            explanation: "您正試圖刪除您的計劃。",
            confirmMessage: "您確定要刪除此計劃嗎？",
            noteToDownload: "注意：您可能需要在刪除之前下載並保存您的計劃，因為它無法恢復。",
            downloadButton: "下載我的計劃",
            keepPlanButton: "保持我的計劃",
            deleteButton: "刪除我的計劃",
            deleteSuccessMessage: "已刪除！",
          },
          planLandingPage: {
            videoTitle: "歡迎來到你的我計劃中心",
            gtky: "開始了解你",
            whatIsHappening: "你的生活中發生了什麼",
            startPlanning: "開始計劃",
            newBadge: "新徽章",
            getStarted: "開始使用",
            leftOff: "你離開的地方",
            happeningButton: "正在發生",
            planningButton: "目標規劃",
            recentBadges: "最近獲得的徽章",
            gtkyNoCaps: "開始了解你",
            happeningShort: "發生了什麼",
            planning: "規劃",
            noBadgeMessage: "還沒有徽章！開始完成部分以獲得徽章",
            contributorGreeting: "歡迎",
            contributorMessage: `我們看到您作為貢獻者加入，這意味著有人認為您非常重要！您在為這場比賽做出貢獻的同時發揮著重要作用，
              我們要求您堅持本網站的宗旨，並將計劃所有者置於他們計劃的中心，同時利用您的時間添加豐富而有意義的信息。在為計劃做出貢獻時，
              我們還要求您花時間反思和獲取有關此人是誰、對他們來說什麼是重要的以及如何幫助他們實現他們的希望和夢想的具體信息`,
            contributorQuote: `“每個人都有能力、能力和天賦。過上美好的生活取決於是否可以使用這些能力、表達能力和給予禮物。如果是，
              這個人就會受到重視，感覺強大並與周圍的人建立良好的聯繫他們，以及這個人周圍的社區會因為這個人所做的貢獻而感到更加強大。”`,
            quote: `“您的目標是指導您並向您展示生活可能的路線圖。” - 萊斯布朗`,
            profileBadges: {
              addContributor: '徽章：將貢獻者添加到您的計劃',
              colorPalette: '徽章：選擇一個調色板',
              approveContributor: '徽章：批准貢獻',
              planningVideo: '徽章：創建規劃視頻',
              profilePicture: '徽章：上傳個人資料圖片',
              backgroundImage: '徽章：上傳新壁紙',
              profileComplete: '徽章：個人資料完成！',
            }
          },
          badges: {
            badgeSectionTitle: "我獲得的徽章",
            badgesPlanningExplanation: `完成每個部分即可開始獲得徽章！完成某個部分後,您的徽章將出現在此處,				
                讓我們在規劃您的未來時玩得開心！`,
            badgesHappeningExplanation: `完成每個部分即可開始獲得徽章！完成某個部分後,您的徽章將出現在此處,				
                讓我們在學習生活中發生的事情的同時獲得一些樂趣！`,
            badgesGTKYExplanation: `通過完成每個部分開始獲得徽章！完成某個部分後,您的徽章將出現在此處, 讓我們在認識你的同時獲得一些樂趣`,
          },
          sections: {
            likeAndAdmire: "喜歡和欣賞",
            people: "我生命中的人",
            historyMap: "里程碑",
            favorites: "最喜歡的東西",
            travelPlan: "旅行",
            thingsIDo: "我為了好玩而做的事情",
            dailyRoutines: "日常生活",
            weeklyRoutines: "每週例行公事",
            routines: "例行公事",
            happeningHome: "幸福的家",
            happeningWork: "正在發生的工作",
            happeningSchool: "發生學校",
            happeningHealth: "正在發生的健康",
            planningHome: "規劃家園",
            planningWork: "規劃工作",
            planningTravel: "規劃旅行",
            planningFun: "規劃樂趣",
            planningHealth: "規劃健康",
            planningRelationships: "規劃關係",
          },
          planningPage: {
            pageTitle: "讓我們開始計劃吧",
            planningVideo: "規劃視頻",
            housing: "住房",
            work: "工作",
            health: "健康",
            fun: "樂趣",
            relationships: "關係",
            travel: "旅行",
            addAnotherGoal: "添加另一個目標"
          },
          planningHealth: {
            sectionName: "規劃健康",
            sectionHeader: "健康目標",
            subHeader: "讓我們為健康的未來製定計劃",
            videoTitle: "健康目標 - 有用的視頻",
            goalTitle: "你想給這個目標起什麼名字？",
            goalAroundHealth: "您的健康目標是什麼？",
            workedOnThisGoal: "你以前有沒有為這個目標工作過？",
            whatWorked: "當你為這個目標工作時,什麼有效？",
            didntWork: "當你為這個目標工作時,什麼沒有奏效？",
            healthTableHeader: "當你考慮這個目標時,你認為你應該......",
            doYouHaveSupport: "你生命中是否有人可以支持你實現這個目標？",
            attachedPeopleMessage: "請選擇誰可以支持你",
            anyOneElseWhoCanSupport: "還有其他人可以幫助您實現目標嗎？為什麼？",
            goalCommit: "你能承諾做些什麼來幫助創造這個未來？",
            increase: "增加",
            decrease: "減少",
            doMore: "做更多",
            doLess: "少做",
            increaseEx: "例如,我有多少時間專注於自己",
            decreaseEx: "例如,我在 Facebook 上花了多少時間",
            doMoreEx: "例如,我花在呼吸上的時間",
            doLessEx: "例如,吃含糖食物",
            saveMessage: "太棒了,祝這個目標好運！您要添加另一個健康目標嗎？",
            deleteMessage: "您確定要刪除此表行嗎？",
            deleteConfirm: "好的,該行已被刪除",
            summary: {
              goalTitle: "我的健康目標",
              whatWorked: "當我在此之前為這個目標工作時,這就是有效的",
              didntWork: "當我在此之前為這個目標工作時,這是行不通的",
              healthTableHeader: "當我想到我的目標時,我認為我可以做以下事情來幫助我",
              doYouHaveSupport: "我的支持圈子裡可以幫助我實現這個目標的人是",
              anyOneElseWhoCanSupport: "我認為可以幫助的其他人是",
              goalCommit: "我可以承諾",
            },
          },
          planningRelationships: {
            sectionName: "規劃關係",
            sectionHeader: "關係目標",
            subHeader: "讓我們開始與人建立聯繫吧",
            newOrStrengthen: "你是想規劃一段新的關係,還是想加強現有的關係",
            saveMessage: "聽起來是一個很棒的關係目標！您要添加另一個目標嗎",
            newText: "新的",
            strengthenText: "加強",
            relationship: '关系',
            strengthen: {
              videoTitle: "加強關係 - 有用的視頻",
              goal: "你想和誰加強關係",
              currentStatus: "這段關係的現狀如何",
              romantic: "浪漫的",
              friend: "朋友",
              professional: "專業的",
              family: "家庭",
              whatToStrengthen: "你想加強這段關係怎麼樣",
              frequency: "頻率",
              communication: "溝通",
              desiredStatus: "期望的狀態",
              howOften: "您目前與此人互動的頻率如何",
              howToStrengthen: "你想如何加強頻率",
              strengthenCommunication: "你想加強什麼溝通",
              commExample1: "例如：我試著更頻繁地給他們打電話",
              commExample2: "例如：我可以隨時與他們交談",
              commExample3: "例如：我感到被傾聽和聯繫",
              commExample4: "例如：他們不會一直回答",
              commTableHeader1: "你在加強溝通時有什麼嘗試？",
              statusTableHeader1: "你試圖做些什麼來改變這種關係的狀態",
              tableHeader2: "當你嘗試這樣做時,你學到了什麼？",
              tableHeader3: "當你嘗試這樣做時,你對什麼感到滿意？",
              tableHeader4: "你在擔心什麼？",
              commDoNext: "看看你剛剛列出的,接下來你能做什麼？",
              preferredStatus: "你希望這個人的身份是什麼？",
              theyAreCurrently: "他們目前是：",
              statusExample1: "例如：我試著在約會時問他們",
              statusExample2: "例如：他們非常友善,我喜歡和他們交談",
              statusExample3: "例如：他們說是的！",
              statusExample4: "例如：從那以後我沒有給他們打電話",
              finalDoNext: "看看你剛剛列出的內容,接下來你能做什麼？",
              deleteRowConfirm: "您確定要刪除此表格行嗎？",
              deleteRowSuccess: "好的,該行已被刪除",
            },
            new: {
              videoTitle: "規劃新的關係 - 有用的視頻",
              relationshipType: "你在尋找什麼樣的關係？",
              characteristics: "你在尋找想要聯繫的人的哪些特徵？",
              where: "你會在哪裡見到這個人？",
              placeOfWorship: "禮拜的場所",
              family: "家庭",
              friend: "朋友",
              professional: "專業的",
              romantic: "浪漫的",
              sharedInterests: "通過共同的利益",
              online: "在線的",
              school: "學校",
              shops: "商店",
              work: "工作",
              whenYouThinkAbout: "當您考慮創建新連接時",
              atChurch: "在禮拜場所",
              throughFriend: "通過朋友",
              throughInterests: "通過共同的利益",
              atOnline: "在線的",
              atSchool: "在學校",
              atLocalShop: "在當地商店",
              atWork: "在工作",
              likeToStart: ",你想從哪裡開始？",
              churchStart: "當您考慮在禮拜場所建立新的聯繫時，您想從哪裡開始？",
              friendStart: "當您考慮通過朋友建立新聯繫時，您想從哪裡開始？",
              interestsStart: "當你考慮通過共同的興趣建立新的聯繫時，你想從哪裡開始？",
              onlineStart: "當您考慮在線創建新連接時，您想從哪裡開始？",
              schoolStart: "當你考慮在學校建立新的聯繫時，你想從哪裡開始？",
              shopsStart: "當您考慮在當地商店建立新的聯繫時，您想從哪裡開始？",
              workStart: "當您考慮在工作中建立新的聯繫時，您想從哪裡開始？",
              howOften: "您希望多久以這種方式連接一次？",
            },
            characteristics: {
              organized: "有組織的",
              strong: "強的",
              dreamer: "夢想家",
              determined: "決定",
              smart: "聰明的",
              artist: "一個藝術家",
              considerate: "周到",
              helpful: "有幫助",
              creative: "有創造力的",
              thoughtful: "周到",
              talented: "才華橫溢",
              outgoing: "傳出",
              leader: "一位領袖",
              intelligent: "聰明的",
              loving: "愛的",
              understanding: "理解",
              spiritual: "精神",
              joyful: "快樂",
              fixer: "固定器",
              liberal: "自由主義的",
              patient: "病人",
              respectful: "尊敬的",
              willing: "願意的",
              intuitive: "直覺的",
              gentle: "溫和的",
              generous: "慷慨的",
              spontaneous: "自發的",
              independent: "獨立的",
              supportive: "支持的",
              resourceful: "足智多謀",
              experiential: "體驗式",
              fun: "樂趣",
              funny: "有趣的",
              excited: "興奮的",
              accepting: "接受",
              busy: "忙碌的",
              pretty: "漂亮的",
              openMinded: "思想開明的",
              hardWorking: "任勞任怨",
              doer: "實干家",
              involved: "涉及",
              flexible: "靈活的",
              happy: "快樂的",
              careful: "小心",
              hopeful: "滿懷希望",
              tinkerer: "修補匠",
              riskTaker: "冒險者",
              lucky: "幸運的",
              active: "積極的",
              efficient: "高效的",
              visionary: "有遠見的人",
              explorer: "探險者",
              responsible: "負責任的",
              driven: "驅動",
              confident: "自信的",
              connector: "一個連接器",
              wise: "明智的",
              expressive: "富有表現力",
              sensitive: "敏感的",
              talkative: "健談",
              energized: "充滿活力",
              giving: "給予",
              observant: "細心的",
              caring: "關懷",
              peaceful: "平靜的",
              cool: "涼爽的",
              fashionable: "時髦",
              actor: "演員",
              writer: "一個作家",
              vibrant: "充滿活力",
              goodCook: "好廚師",
              goodWithAnimals: "善待動物",
            },
            summary: {
              newGoalPdfTitle: "新關係",
              strengthenGoalPdfTitle: "加強關係",
              relationshipType: "我想要建立的關係類型是",
              characteristics: "我希望這個人具備以下特點",
              where: "我能遇到這個人",
              whereToStart: "我想從哪裡開始",
              howOften: "多常",
              beingInPerson: '亲自',
              talkingMore: '说更多',
              facetiming: '方面',
              otherMeans: '其他手段',
              comments: "註釋",
              strengthenGoal: "我想加強關係的人是",
              currentStatus: "我們關係的現狀是",
              whatToStrengthen: "我想加強的",
              strengthenBy: "我想通過...加強",
              strengthenWhy: "為什麼？",
              whyAnswer: "這將幫助我實現我的目標",
              becoming: "變得",
            },
          },
          planningHome: {
            sectionName: "規劃家園",
            sectionHeader: "生活目標",
            subHeader: "讓我們看看你喜歡或不喜歡你目前的生活狀況",
            wantToLive: "你要在哪裡住？",
            idealHome: "你理想中的家是什麼樣子的？",
            homeAmenities: "你希望你的家有什麼設施？",
            homeCloseTo: "你希望你的家靠近什麼地方？",
            liveWith: "有沒有想和你一起生活的人？",
            roommateName: "誰？",
            roommateQuality: "為什麼你認為和這個人住在一起很合適？",
            askedRoommate: "你問過同居嗎？",
            liveWithPets: "你以後有寵物嗎？",
            petType: "什麼動物？",
            finalThoughts: "在你理想的家中還有什麼我們沒有討論過的嗎？",
            whatILike: "我喜歡什麼",
            whatIDislike: "我不喜歡什麼",
            saveMessage: "太好了，謝謝你為你的家做計劃！您要添加另一個嗎？",
            homeOptions: {
              singleStory: "單篇故事",
              apartment: "公寓",
              duplex: "雙工",
              mobileHome: "移動房屋",
              tinyHome: "小家",
            },
            amenities: {
              laundry: "洗衣店",
              internet: "無線互聯網",
              recreation: "娛樂設施",
              garden: "花園",
              deck: "甲板",
              patio: "露台",
              land: "土地",
              ramp: "坡道",
              pool: "水池",
              bigDoors: "大門",
            },
            proximities: {
              shopping: "購物",
              bus: "巴士車站",
              friends: "朋友們",
              restaurants: "餐廳",
              entertainment: "娛樂",
              publicTransit: "公共交通工具",
              family: "家庭",
            },
            summary: {
              wantToLive: "我想住的地方",
              wantToLiveAnswer: "{{homeType}} 中的 {{place}}",
              homeAmenities: "在我想要的理想家園",
              otherAmenities: "我理想中的家中的其他東西",
              homeCloseTo: "我希望我的家靠近",
              roommateName: "我想一起生活",
              liveAlone: "我想一個人住",
              roommateBecause: "因為{{為什麼}}",
              roommateQuality: "為什麼你認為和這個人住在一起很合適？",
              petType: "寵物",
              finalThoughts: "我理想中的家中的其他東西",
            },
          },
          planningFun: {
            sectionName: "規劃樂趣",
            sectionHeader: "有趣的目標",
            subHeader: "讓我們開始玩樂吧！",
            goal: "當您考慮計劃一些有趣的事情時,您想做什麼？",
            goalWhy: "是什麼讓這對你有吸引力？或者你為什麼要這樣做？",
            goalWhen: "你想什麼時候做這個？",
            goalHowOften: "你想多久做一次？",
            otherPeople: "有沒有你想和誰一起做這件事的人？",
            otherPeopleWhoCanSupport: "請選擇誰可以支持你",
            otherPeopleWhereToFind: "請選擇可以幫助您與志趣相投的人建立聯繫的群組或地點",
            needToSave: "你需要存錢來做這個嗎？",
            startedSaving: "你開始存錢了嗎？",
            amountToSave: "你還需要存多少錢？",
            attachedPeople: "細繩",
            saveMessage: "聽起來很有趣！您要添加另一個有趣的目標嗎？",
            asap: "儘早",
            sixMonths: "未來六個月",
            oneYear: "六個月到一年",
            twoYears: "1-2年",
            twoPlusYears: "2年以上",
            oneTime: "一次事件",
            daily: "日常的",
            weekly: "每週",
            monthly: "月刊",
            quarterly: "季刊",
            facebook: "Facebook",
            groupMeet: "小組聚會",
            newspaper: "當地報紙",
            coffeeShop: "咖啡店公告欄",
            communityCenter: "社區中心",
            summary: {
              goal: "當考慮計劃一些有趣的事情時,我想",
              goalWhy: "這對我很有吸引力,因為",
              goalWhen: "我想做這個",
              goalHowOften: "而且經常",
              otherPeople: "我想這樣做",
              peopleInMyLife: "我想和我一起做這件事的人是",
              actionSteps: "操作步驟：",
              comments: "註釋",
              helpMeAchieve: "這將幫助我實現我的目標",
              research: "研究計劃這項活動的方法",
              needToSave: "我需要為這次旅行存錢嗎？",
              needToSaveYes: "是的,我需要節省 {{amount}}",
            },
          },
          planningTravel: {
            sectionName: "規劃旅行",
            subHeader: "讓我們開始計劃你的下一次冒險吧",
            destination: "你想去哪裡旅遊？",
            travelTo: "你以前去過{{location}}嗎？",
            like: "您喜歡 {{location}} 的哪些方面",
            appealingWhy: "是什麼讓 {{location}} 對您有吸引力？",
            travelWhen: "你想什麼時候去{{location}}？",
            withOthers: "有想一起旅行的人嗎？",
            travelWithWho: "請選擇您想和誰一起旅行",
            toDoAtDestination: "你來訪的時候想做什麼？",
            toSave: "你需要存錢來做這個嗎？",
            startedToSave: "你開始存錢了嗎？",
            amountToSave: "你還需要存多少錢？",
            accommodations: "你旅行時有住宿需求嗎？",
            needsAndWhy: "有什麼你想要或需要旅行的東西嗎？如果有,它們是什麼？",
            goal: "你想給這個目標起什麼名字？",
            saveMessage: "冒險等待！您要添加另一個旅行目標嗎？",
            summary: {
              destination: "我想前往",
              appealingWhy: "我為什麼要來這裡旅行",
              travelWhen: "我想在這裡旅行",
              withOthers: "我想和人一起旅行嗎",
              withOthersYes: "是的,我想和 {{people}} 一起旅行",
              withOthersAlone: "我想一個人旅行",
              toDoAtDestination: "當我訪問時,我想",
              accommodations: "旅行時我需要的住宿包括",
              needsAndWhy: "我旅行時想帶的其他東西包括",
              needToSave: "我需要為這次旅行存錢嗎？",
              needToSaveYes: "是的,我需要節省 {{amount}}",
            },
          },
          planningWork: {
            sectionName: "規劃工作",
            sectionHeader: "工作目標",
            addSubHeader: "讓我們規劃新的未來",
            whatILiked: "我喜歡什麼",
            whatIDisliked: "我不喜歡的",
            formSubHeader: "讓我們看看你喜歡或不喜歡你的工作經歷",
            workGoal: "你的工作目標是什麼？",
            profession: "是什麼吸引你進入這個職業？",
            excites: "這個職業最讓你興奮的是什麼？",
            goalLookLike: "當你考慮你的目標時,它是什麼樣的？",
            salary: "你想一年賺多少錢？",
            preferredWorkDays: "你喜歡什麼工作日？",
            preferredWorkHours: "你喜歡的工作時間是什麼時候？",
            haveNow: "你現在擁有什麼可以讓你走向未來？",
            priorExperience: "什麼樣的經歷會支持你走向未來？",
            haveReference: "你認識這個專業的人嗎？",
            referenceName: "他們是誰？",
            referenceWhere: "他們在哪里工作？",
            inLifeSupport: "你生命中誰能支持你實現這個目標？",
            support: "還有其他人可以幫助您實現目標嗎？",
            commit: "你能承諾做些什麼來幫助創造這個未來？",
            placeholderBudgetNeed: "插入您需要預算的內容",
            placeholderResponsible: "插入一個名字",
            placeholderHowOften: "插入多久執行一次",
            placeholderImportant: "插入為什麼這很重要",
            workPreferences: '我的工作偏好',
            monday: "週一",
            tuesday: "週二",
            wednesday: "週三",
            thursday: "週四",
            friday: "星期五",
            saturday: "週六",
            sunday: "星期日",
            morning: "早晨",
            afternoon: "下午",
            evening: "晚上",
            overnight: "過夜",
            anytime: "任何時候",
            saveMessage: "聽起來是一個很棒的工作目標！您要添加另一個工作目標嗎？",
            budgetTable: {
              title: "你需要存什麼錢來幫助你實現你的目標？",
              actionStep: "行動步驟",
              saveFor: "省錢",
              saveMoneyFor: "我需要存錢做什麼？",
              responsible: "誰是負責的人？",
              howOften: "多常？",
              important: "為什麼這很重要？",
              why: "為什麼？",
              contact: "接觸",
              me: "我",
              theyCanSupport: "他們可以支持我實現我的目標",
              thisWillHelpMe: "這將幫助我實現我的目標",
              deleteConfirm: "您確定要刪除此預算行嗎？",
              deleteSuccess: "好的,該行已被刪除",
            },
            summary: {
              workGoal: "我的工作目標",
              profession: "是什麼吸引我進入這個職業",
              excites: "最讓我興奮的是",
              goalLookLike: "當我想到我的目標時,它看起來像",
              haveNow: "什麼將幫助我實現我的未來",
              preferredWorkDays: "首選工作日",
              preferredWorkHours: "首選工作時間",
              salary: "期望薪水",
              haveReference: "你認識這個專業的人嗎？",
              referenceName: "他們是誰？",
              referenceWhere: "他們在哪里工作？",
              inLifeSupport: "你生命中誰能支持你實現這個目標？",
              support: "還有其他人可以幫助您實現目標嗎？",
              commit: "你能承諾做些什麼來幫助創造這個未來？",
            },
          },
          GTKYPage: {
            pageTitle: "開始了解你",
            GTKYVideo: "認識你的視頻",
            likeAdmire: "喜歡和欣賞",
            peopleInMyLife: "我生命中的人",
            thingsIDo: "我喜歡做的有趣的事情",
            favoriteThings: "我最喜歡的東西",
            myRoutines: "我的日常",
            historyMap: "歷史地圖",
            travel: "旅行",
            viewComments: "查看評論"
          },
          myRoutines: {
            sectionName: "我的日常",
            subHeader: "告訴我們你的日常生活是什麼樣的",
            videoTitle: "我的日常 - 有用的視頻",
            time: "時間",
            noTime: "沒時間",
            day: "天",
            noDay: "沒有一天",
            dailyError: "請輸入時間和描述",
            weeklyError: "請輸入日期和描述",
            successMessage: "謝謝！您的例程已添加",
            dailyRoutines: "你的日常生活是什麼？",
            weeklyRoutines: "你每週的例行公事或儀式是什麼？",
            dailyPlaceholder: "添加您每天以及何時執行的操作",
            weeklyPlaceholder: "添加你每週做的事情",
            dayStickyTitle: "我的一天",
            weekStickyTitle: "我的星期",
            deleteMessage: "您確定要刪除此例程嗎？",
            deleteSuccess: "好的，例程已刪除",
            contributor: {
              subHeader: "告訴我們他們的日常生活是什麼樣的",
              dailyRoutines: "他們的日常生活是什麼？",
              weeklyRoutines: "他們每週的例行活動或儀式是什麼？",
              dailyPlaceholder: "添加他們每天和何時做的事情",
              weeklyPlaceholder: "添加他們每週做的事情",
              dayStickyTitle: "{{name}} 節",
              weekStickyTitle: "{{name}} 週",
            },
            days: {
              mon: "週一",
              tues: "週二",
              wed: "週三",
              thurs: "週四",
              fri: "星期五",
              sat: "週六",
              sun: "星期日"
            }
          },
          thingsIDo: {
            sectionName: "我喜歡做的有趣的事情",
            subHeader: "選擇您的活動或創建一個新活動",
            indoors: "在室內",
            outdoors: "戶外",
            frequencyCard: "我喜歡這樣做：",
            likeMostCard: "我最喜歡這個活動的地方：",
            tellUsWhatYouDo: "告訴我們你喜歡做什麼來娛樂！",
            indoor: "室內的",
            outdoor: "戶外的",
            community: "在我的社區",
            whereToHaveFun: "你喜歡去哪裡玩？",
            yourActivity: "你有什麼喜歡做的事？",
            chosenActivity: "你選擇的活動是",
            howOften: "你喜歡多久做一次？",
            aloneOrWithOthers: "你喜歡單獨做這個活動還是和別人一起做這個活動？",
            attachedPeople: "你喜歡和誰一起做這個？",
            addThingIDo: "添加另一個活動",
            otherPeopleInvolved: "還有其他人想和你一起做這個活動嗎？",
            anyPhotos: "你有什麼有趣的{{activity}}圖片想分享嗎？",
            like: "你最喜歡 {{activity}} 的什麼？",
            youSelected: "您的活動：",
            saveMessage: "多麼冒險！你想再添加一個嗎？",
            deleteConfirm: "您確定要刪除此活動嗎？",
            deleteSuccess: "您的活動已成功刪除",
            daily: "日常的",
            weekly: "每週",
            monthly: "月刊",
            oncePerYear: "一年一次",
            fewTimes: "一年幾次",
            whenever: "只要我能",
            contributorQuestions: {
              frequencyCard: "他們喜歡這樣做：",
              likeMostCard: "他們最喜歡這項活動的地方：",
              tellUsWhatYouDo: "告訴我們他們喜歡做什麼來娛樂！",
              community: "在我的社區",
              whereToHaveFun: "他們喜歡在哪裡玩樂？",
              yourActivity: "他們喜歡做什麼？",
              chosenActivity: "他們選擇的活動是",
              howOften: "他們喜歡多久做一次？",
              aloneOrWithOthers: "他們喜歡單獨做這個活動還是和別人一起做這個活動？",
              attachedPeople: "他們喜歡和誰一起做這件事？",
              otherPeopleInvolved: "他們想和其他人一起做這個活動嗎？",
              anyPhotos: "他們有你想分享的 {{activity}} 的有趣圖片嗎？",
              like: "他們最喜歡 {{activity}} 的什麼？",
              youSelected: "他們的活動：",
              saveMessage: "",
            },
            activities: {
              reading: "閱讀",
              writing: "寫作",
              yoga: "瑜伽",
              pilates: "普拉提",
              videoGames: "視頻遊戲",
              cardGames: "紙牌遊戲",
              boardGames: "棋盤遊戲",
              painting: "繪畫",
              instruments: "儀器",
              drawing: "繪畫",
              art: "藝術",
              replicaModels: "複製模型",
              movies: "電影",
              tv: "電視",
              movieTheater: "電影院",
              diningOut: "外出就餐",
              yardSales: "庭院銷售",
              antiquing: "仿古",
              mall: "去商場",
              shopping: "購物",
              sports: "運動的",
              walks: "散步",
              birdWatching: "觀鳥",
              hiking: "遠足",
              fishing: "釣魚",
              gardening: "園藝",
              camping: "野營",
              boating: "划船",
              botany: "植物學"
            },
            summary: {
              activity: "我喜歡做什麼",
              howOften: "我喜歡做這個活動",
              withWho: "我喜歡這樣做",
              withWhoAlone: "我這樣做",
              byMyself: "我自己",
              like: "我最喜歡的是",
              buttonText: "去做我喜歡做的事",
            },
          },
          likeAndAdmire: {
            sectionName: "喜歡和欣賞",
            sectionTitle: "頭,心,手",
            subTitle: "將您的禮物拖放到您選擇的類別中或創建您自己的類別",
            videoTitle: "喜歡和欣賞 - 有用的視頻",
            filterAll: "所有喜歡和欽佩的東西（默認）",
            filterOthers: "別人喜歡和欽佩我的地方",
            filterMine: "我喜歡和欣賞自己的地方",
            resetAll: "重置全部",
            addNew: "添加新詞",
            saveMessage: "幹得好 {{firstName}},聽到關於你的所有偉大的事情真是太好了！",
            head: "頭",
            heart: "心",
            hand: "手",
            headExplanation: "頭腦、大腦或思想的禮物；你知道的那些禮物",
            heartExplanation: "你覺得有聯繫或熱愛的禮物",
            handExplanation: "您可以通過行動或製作實際提供的禮物",
            resetMessage: "您確定要全部重置嗎？",
            resetSuccess: "好的，項目已重置",
            deleteMessage: "確定要刪除此禮物嗎？",
            deleteSuccess: "禮品已成功刪除",
            contributor: {
              subTitle: "將他們的禮物拖放到您選擇的類別或創建您自己的類別中",
              filterOthers: "別人喜歡和欽佩他們的地方",
              filterMine: "他們喜歡和欣賞自己的地方",
            },
            summary: {
              head: "頭",
              heart: "心",
              hand: "手",
            },
          },
          peopleInMyLife: {
            sectionName: "我生命中的人",
            subHeader: "添加一個人或單擊該人的姓名以添加有關他們的更多詳細信息",
            adaptionDisclaimer: "關係圖的適應",
            acquaintance: "熟人",
            acquaintances: "熟人",
            add: "添加人",
            addAsContributor: "添加為我的計劃的貢獻者",
            close: "關",
            contributor: "貢獻者",
            contributorAdditions: "貢獻者補充",
            coWorker: "同事",
            coWorkers: "合作夥伴",
            deletePerson: "您確定要將此人從您生活中的人中刪除嗎？",
            edit: "編輯人",
            family: "家庭",
            favoritePerson: "本月最喜歡的人",
            friend: "朋友",
            friends: "朋友們",
            howClose: "你和這個人有多近？",
            importantWhy: "為什麼這個人對你很重要",
            involvedInSupport: "參與我的支持",
            mapPlace: "這將決定他們在你的人生地圖上的位置",
            notClose: "不熟",
            paidSupport: "這個人有報酬嗎？",
            paidSupportShort: "有償支持",
            personIs: "這個人是……",
            relationshipToYou: "與你的關係",
            removePerson: "刪除人",
            weAre: "我們是...",
            saveMessage: "你生命中的所有人都得救了,幹得好,",
            me: '我',
            contributorQuestions: {
              howClose: "他們離這個人有多近？",
              importantWhy: "為什麼這個人對他們很重要",
              mapPlace: "這將決定他們在我生活中的人物地圖上的位置",
              relationshipToYou: "與他們的關係",
              theyAre: "他們是...",
              involvedInSupport: "參與他們的支持",
            },
            dialog: {
              nameError: "請輸入姓名",
              personIsError: "請選擇一個選項",
              emailError: "必須輸入潛在貢獻者的電子郵件地址",
              emailValidError: "請輸入有效的電子郵件地址",
              sendInvitation: "發送邀請",
            },
          },
          favoriteThings: {
            sectionName: "我最喜歡的東西",
            subHeader: "首先選擇一個類別來添加您喜歡的東西",
            contributorSubHeader: "請選擇一個類別開始添加自己喜歡的東西",
            viewOnlySubHeader: "看看他們最喜歡的東西",
            addDialogTitle: "添加最喜歡的東西",
            editDialogTitle: "編輯你最喜歡的東西",
            categories: "類別",
            categoryList: "類別列表",
            myFavorites: "我最喜愛的",
            theirFavorites: "他們的最愛",
            place: "地方",
            placeToEat: "吃飯的地方",
            food: "食物",
            movie: "電影",
            book: "書",
            videoGame: "視頻遊戲",
            game: "遊戲",
            sport: "運動",
            animal: "動物",
            music: "音樂",
            memory: "記憶",
            other: "其他",
            placeLabel: "你最喜歡去的地方",
            placeToEatLabel: "你最喜歡吃的地方",
            foodLabel: "你最愛的食物",
            movieLabel: "你最喜歡的電影",
            bookLabel: "你最喜歡的書",
            videoGameLabel: "你最喜歡的電子遊戲",
            gameLabel: "你最喜歡的遊戲",
            sportLabel: "你最喜歡的運動或運動隊",
            animalLabel: "您最喜歡的寵物或動物類型",
            musicLabel: "你最喜歡的樂隊、歌曲或流派",
            memoryLabel: "你最喜歡的回憶之一",
            otherLabel: "任何喜歡的東西",
            chooseAView: "選擇一個視圖",
            allThings: "所有喜歡的東西（默認）",
            thingsIAdded: "我添加的最喜歡的東西",
            thingsOthersAdded: "其他人添加了什麼",
            treasureChestAlt: "最喜歡的東西寶箱",
            deleteConfirm: "你確定要刪除這個最喜歡的東西嗎？",
            deleteSuccess: "最喜歡的東西已被刪除",
            saveAlertText: "謝謝你{{name}},了解你最喜歡的東西真是太好了！",
            plurals: {
              place: "地方",
              placeToEat: "吃飯的地方",
              movie: "電影",
              book: "圖書",
              videoGame: "視頻遊戲",
              game: "遊戲",
              sport: "運動的",
              animal: "動物",
              memory: "回憶",
            }
          },
          travelPlan: {
            sectionName: "旅行",
            sectionHeader: "旅行",
            subHeader: "分享您最喜歡的旅行地點或您真正想去的地方",
            travelLocally: "你喜歡在美國境內旅行還是國際旅行？",
            local: "在美國境內",
            international: "國際",
            frequencyCard: "我喜歡這樣做：",
            likeMostCard: "我最喜欢这个地方的地方：",
            pageSubHeader: "告訴我們你喜歡去哪裡旅行",
            travelWhere: "你喜歡去哪裡旅遊？",
            likeMost: "你最喜歡去 {{location}} 的什麼地方？",
            likeToTravel: "你喜歡怎樣旅行？",
            alone: "你喜歡一個人做還是和別人一起做？",
            howOften: "你喜歡多久來這裡旅行一次？",
            anyoneElse: "還有其他人想和你一起旅行嗎？",
            anyPictures: "你有什麼有趣的{{location}}照片想要分享嗎？",
            deleteMessage: "你確定要刪除這個位置？",
            addTravelPlan: "新旅遊目的地",
            addTravelContributor: "添加另一個您認為他們想去的地方",
            attachedPeople: "你喜歡和誰一起旅行？",
            boat: "船",
            bus: "公共汽車",
            car: "車",
            plane: "飛機",
            train: "火車",
            saveText: "多麼冒險！你想再添加一個嗎？",
            deleteConfirm: "您確定要刪除此活動嗎？",
            deleteSuccess: "您的活動已成功刪除",
            contributorQuestions: {
              subHeader: "分享他們最喜歡的旅行地點或他們真正想去的地方",
              frequencyCard: "他們喜歡這樣做：",
              likeMostCard: "他们最喜欢这个地方的地方：",
              pageSubHeader: "告訴我們他們喜歡去哪裡旅行",
              travelLocally: "他們喜歡在美國境內旅行還是國際旅行？",
              travelWhere: "他們喜歡去哪裡旅行？",
              likeMost: "他們最喜歡去 {{location}} 的什麼地方？",
              likeToTravel: "他們喜歡怎樣旅行？",
              alone: "他們喜歡單獨做還是和別人一起做？",
              howOften: "他們喜歡多久來這裡旅行一次？",
              anyoneElse: "還有其他人想和他們一起旅行嗎？",
              anyPictures: "他們有你想分享的 {{location}} 的有趣照片嗎？",
              attachedPeople: "他們喜歡和誰一起旅行？",
            },
            summary: {
              place: "我喜歡去旅行",
              travelWithSolo: "我喜歡在這裡旅行",
              byMyself: "我自己",
              travelWith: "我喜歡和這裡一起旅行",
              frequency: "我去那裡",
              frequencyAnswer: "{{frequency}} 和我乘坐 {{transportation}}",
              favoriteThing: "我最喜歡去 {{place}} 旅遊的是",
              buttonText: "去旅行",
            },
          },
          historyMap: {
            sectionHeader: "我的歷史地圖",
            subHeader: "告訴我們您生活中的重要里程碑、紀念日或事件",
            addMilestone: "添加里程碑",
            editMilestone: "編輯里程碑",
            sectionName: "歷史地圖",
            category: "類別",
            familyLife: "家庭生活",
            school: "學校",
            activities: "活動",
            friends: "朋友們",
            housing: "住房",
            work: "工作",
            relationships: "關係",
            chooseDate: "選擇日期",
            milestone: "里程碑",
            details: "細節",
            back: "回到認識你",
            saveMessage: "很高興了解你,謝謝分享！",
            validDateError: "請輸入一個有效的日期",
            rangeDateError: "日期超出範圍",
            deleteMessage: "確定要刪除此里程碑嗎？",
            deleteSuccess: "您的里程碑已成功刪除",
          },
          contributor: {
            addToRelationshipMap: "添加到我的關係圖",
            thisPersonIs: "這個人是一個......",
            howClose: "你和這個人有多近？",
            close: "關",
            notClose: "不熟",
            setPermissions: "設置貢獻者權限",
            hasBeenEmailed: "已向您的投稿人發送了邀請",
          },
          happeningPage: {
            pageTitle: "發生在我的生活中",
            living: "住在我家",
            work: "工作",
            school: "學校",
            health: "健康和安全",
            happeningVideo: "發生在我的生活視頻",
          },
          happeningHome: {
            pageTitle: "住在我家",
            subTitle: "讓我們了解更多關於你住在哪裡的信息！",
            singleStory: "單層住宅",
            multiLevel: "多層住宅",
            apartment: "公寓",
            facility: "設施",
            homeless: "無家可歸",
            groupHome: "集團之家",
            car: "車",
            rvCamper: "房車/露營車",
            currentlyLive: "你現在住在哪？",
            currentlyLiveWith: "你現在和誰住在一起？",
            parents: "父母）",
            guardians: "監護人",
            siblings: "兄弟姐妹）",
            friends: "朋友們）",
            roommates: "室友",
            spouse: "伴侶",
            children: "小孩兒",
            alone: "我一個人住",
            dogs: "小狗）",
            cats: "貓）",
            other: "其他,請解釋",
            explain: "請解釋",
            tableTitle: "你喜歡和不喜歡你住的地方嗎？",
            amenityHeader: "設施",
            selectHeader: "請選擇喜歡或不喜歡",
            commentHeader: "註釋",
            commentsLikes: "關於我喜歡的評論",
            commentsDislikes: "關於我不喜歡的評論",
            tellUsWhy: "請告訴我們為什麼在這裡",
            whatILike: '我喜欢我目前居住的地方',
            whatIDislike: '我不喜欢目前居住的地方',
            amenities: {
              publicTransportation: "公共交通工具",
              accessibility: "可訪問性",
              neighborhood: "鄰里",
              friendsFamily: "親近親友",
              shopping: "靠近購物",
              furniture: "家具",
              homeType: "家的類型",
              squareFootage: "方形鏡頭",
              housemates: "室友",
              livingAlone: "一個人生活",
              neighbors: "鄰居",
              management: "物業管理",
              support: "技術支持人員",
              tellUsWhy: "請告訴我們原因",
            },
            summary: {
              currentlyLive: "我生活的地方",
              currentlyLiveWith: "我和誰住在一起",
            },
          },
          happeningSchool: {
            header: "學校",
            subHeader: "我們迫不及待想听到更多關於學校的信息,告訴我們你的經歷",
            enrolledInSchool: "你現在在學校嗎？",
            schoolNameOOS: "你上的是什麼學校？",
            gradeLevelOOS: "你讀了幾級？",
            gradYearOOS: "你什麼時候畢業的？",
            schoolLikeOOS: "你最喜歡學校的什麼？",
            schoolDislikeOOS: "你不喜歡學校的什麼地方？",
            volunteerOOS: "在學校期間,你在任何地方做過志願者嗎？",
            volunteerWhereOOS: "你在哪裡做志願者？",
            volunteerDislikeOOS: "你不喜歡什麼？",
            volunteerLikeOOS: "你喜歡志願服務的什麼？",
            graduationGoalOOS: "它以前如何？",
            gradYearOtherOOS: "",
            goalYesOOS: "畢業後有什麼想做的事情嗎？",
            schoolName: "你念哪所學校？",
            gradeLevel: "你幾年級？",
            gradYear: "你什麼時候放學？",
            schoolLike: "你最喜歡學校的什麼？",
            schoolDislike: "你不喜歡學校的什麼？",
            volunteer: "你在任何地方做志願者嗎？",
            volunteerWhere: "你在哪裡做志願者？",
            volunteerDislike: "你不喜歡什麼？",
            volunteerLike: "你喜歡志願服務的哪些方面？",
            graduationGoal: "它是什麼？",
            gradYearOther: "",
            goalYes: "畢業後有什麼想做的嗎？",
            summary: {
              schoolName: "我去的學校叫",
              schoolNameOOS: "我去的學校叫",
              gradeLevel: "我在年級",
              gradeLevelOOS: "我完成了年級",
              gradYear: "我將在學校完成",
              gradYearOOS: "我在學校完成了",
              schoolLike: "我喜歡學校的什麼",
              schoolLikeOOS: "我喜歡學校的什麼",
              schoolDislike: "我不喜歡學校的地方",
              schoolDislikeOOS: "我不喜歡學校的地方",
              volunteerWhere: "我在學校做志願者",
              volunteerWhereOOS: "在我自願參加的學校",
              volunteerLike: "我喜歡志願服務",
              volunteerLikeOOS: "我喜歡志願服務的原因",
              volunteerDislike: "我不喜歡志願服務",
              volunteerDislikeOOS: "我不喜歡志願服務",
              graduationGoal: "當我畢業時,我想",
              graduationGoalOOS: "當我畢業時,我想",
            },
          },
          happeningWork: {
            sectionName: "工作經驗",
            subHeader: "告訴我們更多關於你的工作經歷,我們迫不及待地想听到！",
            pastExperience: "請分享您過去的工作經驗",
            pastExperienceLike: "你喜歡你過去的工作經歷嗎？",
            pastExperienceDislike: "你不喜歡你過去的工作經歷的什麼地方？",
            hasCurrentEmployment: "你現在在工作嗎？",
            currentEmployment: "你現在在哪里工作？",
            currentDuration: "你在那里工作多久了？",
            currentLike: "你喜歡你目前的工作嗎？",
            currentDislike: "你不喜歡你目前的工作什麼？",
            summary: {
              pastExperience: "我過去工作過的地方",
              pastExperienceLike: "我喜歡我過去工作過的地方",
              pastExperienceDislike: "我不喜歡我過去工作過的地方",
              currentEmployment: "目前我在工作",
              currentDuration: "我在這里工作過",
              currentLike: "我喜歡我工作的地方",
              currentDislike: "我不喜歡我工作的地方",
            },
          },
          happeningHealth: {
            header: "健康和安全",
            subHeader: "讓我們多了解你一點,請分享你喜歡的東西",
            contributorAdditionsToApprove: "您的貢獻者已將 DME 和/或支持添加到您的計劃中,您要批准他們嗎？",
            medInfo: "有什麼與健康有關的事情會影響你的生活嗎？",
            allergies: "請列出您希望我們了解的過敏症",
            dmeSectionTitle: "您使用什麼類型的耐用醫療設備和支架？",
            dmeContributorTitle: "他們使用什麼類型的耐用醫療設備和支架？",
            sectionSubTitle: "選擇一個按鈕將其添加到您的列表中,單擊列表中的 'x' 將其刪除",
            supportsSectionTitle: "其他人如何支持你感覺和保持健康？",
            supportContributorTitle: "其他人如何支持他們感受和保持健康？",
            dmePostItTitle: "好的支持是什麼樣的",
            supportPostItTitle: "別人如何支持我",
            dmeOptions: {
              bloodSugarMonitors: "血糖監測儀",
              bloodSugarTestStrips: "血糖試紙",
              canes: "拐杖",
              commodeChairs: "馬桶椅",
              continuousPassiveDevices: "連續被動運動裝置",
              continuousPassiveAirwayPressureDevices: "持續氣道正壓通氣 (CPAP) 設備",
              crutches: "拐杖",
              hospitalBeds: "病床",
              infusionPumps: "輸液泵和用品",
              lancetDevices: "柳葉刀裝置和柳葉刀",
              nebulizerMedications: "霧化器和霧化藥物",
              oxygenEquipment: "氧氣設備及配件",
              patientLifts: "病人電梯",
              supportSurfaces: "減壓支撐面",
              suctionPumps: "吸泵",
              tractionEquipment: "牽引設備",
              walkers: "步行者",
              rollator: "滾輪",
              wheelchairMotorized: "輪椅 - 機動",
              wheelchairManual: "輪椅 - 手冊",
              wheelchairStanding: "輪椅 - 斜倚/站立",
              scooterMotorized: "踏板車 - 機動",
              scooterManual: "踏板車 - 手冊",
              assistiveTech: "輔助技術",
              translator: "翻譯",
              brailleKeyboard: "盲文鍵盤",
              hearingAid: "助聽器",
            },
            supportOptions: {
              cookMeals: "給我做飯",
              prepareMeals: "幫我準備飯菜",
              prepareMedication: "幫我準備藥物",
              medicationSchedule: "幫我制定用藥時間表",
              doctorTransportation: "幫我往返醫生",
              createBudget: "幫我制定預算",
              beActive: "幫助激勵我變得活躍",
            },
            summary: {
              medInfo: "我希望其他人了解的健康狀況包括",
              allergies: "我想讓別人知道的過敏包括",
              dmeSectionTitle: "我使用的持久醫療支持包括",
              supportsSectionTitle: "我生命中的人可以通過以下方式支持我",
            },
          },
          happeningGeneral: {
            sectionSave: "感謝您提供的信息 {{userFirstName}},您可以繼續閱讀您生活中發生的事情的另一部分！",
          },
          planSummary: {
            generatePdf: "生成 PDF",
            downloadPdf: "下載PDF",
            selectTravelPlan: "選擇您的旅行計劃",
            selectActivity: "選擇您的活動",
            selectGoal: "選擇一個目標",
            sections: {
              importantToMe: "什麼對我很重要",
              importantForMe: "什麼對我很重要",
              goals: "目標規劃",
            },
            cardTitles: {
              likeAdmire: "人們喜歡和欽佩我的地方",
              people: "我生命中的重要人物",
              milestones: "我生命中的重要里程碑",
              favoriteThings: "我最喜歡的一些東西",
              thingsIDo: "我喜歡做的事情",
              travelPlan: "我想去的地方",
              dailyRoutines: "我的日常",
              weeklyRoutines: "我的每週例行公事",
              happeningHome: "住在我家",
              happeningWork: "我的工作經歷",
              happeningSchool: "我的學校經歷",
              happeningHealth: "我的健康與安全",
              homeGoals: "我的住房目標",
              workGoals: "我的工作目標",
              healthGoals: "我的健康目標",
              funGoals: "我的有趣目標",
              relationshipGoals: "我的關係目標",
              travelGoals: "我的旅行目標",
            },
            cardDescriptions: {
              likeAdmire: "以下是我喜歡和欣賞自己的地方，以及我的投稿人欣賞我的地方",
              people: "這是我生命中最重要的人",
              milestones: "發現我一生中最引以為豪的所有重要里程碑！",
              favoriteThings: "這些只是我最喜歡的一些東西",
              thingsIDo: "以下是我喜歡做的一些有趣的事情！",
              travelPlan: "找出我喜歡去哪裡旅行，和誰一起旅行，以及我喜歡和什麼一起旅行",
              dailyRoutines: "發現什麼對我來說每天都很重要，什麼才是美好的一天！",
              weeklyRoutines: "了解更多關於我的偏好、儀式和慣例的信息！",
              happeningHome: "分享我目前的生活狀況，我喜歡和不喜歡什麼對我來說很重要。",
              happeningWork: "詳細了解我過去的工作經歷，我喜歡和不喜歡的地方！",
              happeningSchool: "了解更多關於我和我在學校的經歷！",
              happeningHealth: "以下是我覺得很舒服的關於我的健康、安全以及其他人可以做些什麼來支持我的分享。",
              homeGoals: "了解更多關於我想住在哪裡",
              workGoals: "了解我的夢想工作",
              healthGoals: "了解我想如何規劃我的健康",
              funGoals: "找出我將來想做的有趣的事情",
              relationshipGoals: "詳細了解我想如何與他人聯繫",
              travelGoals: "找出我希望去哪裡旅行",
            },
            noContent: {
              milestones: "沒有添加任何里程碑。點擊下面的按鈕開始吧！",
              favoriteThings: "",
              thingsIDo: "沒有創建我為樂趣而做的事情。單擊下面的按鈕創建一個！",
              travelPlan: "尚未創建任何旅行地點。單擊下面的按鈕創建一個！",
              dailyRoutines: "沒有添加每日例行程序。點擊下面的按鈕開始吧！",
              weeklyRoutines: "沒有添加每週例行程序。點擊下面的按鈕開始吧！",
              happeningHome: "沒有記錄住在我家的信息。點擊下面的按鈕開始吧！",
              happeningWork: "沒有記錄工作經驗信息。點擊下面的按鈕開始吧！",
              happeningSchool: "沒有記錄學校信息。點擊下面的按鈕開始吧！",
              happeningHealth: "未記錄任何健康與安全信息。點擊下面的按鈕開始吧！",
              homeGoals: "沒有創建住房目標。單擊下面的按鈕創建一個！",
              workGoals: "未創建任何工作目標。單擊下面的按鈕創建一個！",
              healthGoals: "未創建任何健康目標。單擊下面的按鈕創建一個！",
              funGoals: "沒有創建有趣的目標。單擊下面的按鈕創建一個！",
              relationshipGoals: "未創建任何關係目標。單擊下面的按鈕創建一個！",
              travelGoals: "未創建任何旅行目標。單擊下面的按鈕創建一個！",
            },
          },
          contributorGeneric: {
            entries: "貢獻者條目",
            noAccess: "您無權訪問此部分",
            includedByPlanOwner: '包括计划所有者',
            notIncluded: "計劃所有者不包括在內",
            sectionNotComplete: "計劃所有者尚未完成此部分",
            thankYou: "感謝您的貢獻！您的添加已發送給計劃所有者以供批准",
          },
          contributorComments: {
            addComment: "添加評論",
            allComments: "所有評論",
            commentPrompt: "根據你所看到的,你有什麼想要評論的嗎？給他們留下一個有趣的評論或註釋！",
            yourComment: "你的評論",
            approved: "批准的評論",
            pendingApproval: "感謝您的貢獻！您的評論已發送給計劃所有者審批",
            pendingNoThankYou: "您的評論已發送給計劃所有者以供批准",
            comments: "註釋",
            toCheck: '去检查',
            deleteConfirm: '你确定要删除此评论？',
            deleteSuccess: "好的，評論已刪除",
            notApprovedMessage: '您的评论未被计划所有者批准。您可以编辑评论并重新提交评论，或删除您的评论。',
            notIncluded: '不包含',
          },
          durationDropdown: {
            notSelected: "未選中的",
            zeroMonths: "0 - 6 個月",
            sixMonths: "6 - 12 個月",
            oneYear: "1 - 2年",
            twoYears: "2年以上",
          },
          navigationButtons: {
            gtky: "回到認識你",
            thingsIDo: "回到我做的事情",
            happening: "回到發生在我的生活中",
            planningFun: "返回規劃樂趣",
            planningHealth: "返回規劃健康",
            planningHome: "返回規劃主頁",
            planningRelationships: "返回規劃關係",
            planningTravel: "返回計劃旅行",
            planningWork: "返回規劃工作",
          },
          generic: {
            add: "添加",
            addAnother: "加上另一個",
            addComment: "添加評論",
            addNew: "添新",
            addNewEntry: "添加新條目",
            addYourOwn: "添加你自己的",
            addedBy: '添加',
            addressPrimary: "地址1",
            addressSecondary: "套房、公寓、單元#",
            alone: "獨自的",
            approve: "批准",
            back: "後退",
            backToDashboard: "返回計劃儀表板",
            cancel: "取消",
            cancelled: "取消",
            city: "城市",
            clear: "清除",
            country: "國家",
            comments: "貢獻者評論",
            createdBy: "由...製作",
            createdOn: "創建於",
            dailyWithCapital: "日常的",
            date: "日期",
            delete: "刪除",
            deleteGoalConfirm: "您確定要刪除此目標嗎？",
            deleteGoalSuccess: "您的目標已成功刪除",
            details: "細節",
            disapprove: "不贊成",
            dislike: "不喜歡",
            edit: "編輯",
            editGoal: "編輯目標",
            email: "電子郵件",
            emailWithCapitial: "電子郵件",
            error: '錯誤',
            faceTime: "視頻對話",
            firstName: "名",
            addGeneralComment: "點擊添加一般評論",
            generalComments: "普通的留言",
            generalComment: "點擊添加一般評論",
            goal: "目標",
            goToItem: "轉到項目",
            goToGoal: "去目標",
            goToSection: "轉到 {{section}}",
            inPerson: "親自",
            instructionalVideo: "教學視頻",
            language: "語言首選項",
            lastName: "姓",
            like: "喜歡",
            loading: "加載中...",
            middleName: "中間名字",
            name: "姓名",
            newAdditions: "貢獻者添加了新內容",
            no: "不",
            noNotifications: "沒有通知",
            noPeopleMessage: "將朋友和家人添加到您的“我生命中的人”中以在此處選擇他們！",
            noThanks: "不，謝謝",
            okay: "好的",
            other: "其他",
            pleaseExplain: "請解釋",
            phoneNumber: "電話號碼",
            remove: "消除",
            removeImage: "刪除圖像",
            review: "審查",
            save: "節省",
            saveAndSendUpdates: "保存並發送更新",
            saveAndSendInvite: "保存並發送邀請",
            saveAnother: "保存並添加另一個",
            saveNoUpdates: "不發送更新",
            search: "搜索",
            selectAGoal: "選擇一個目標",
            state: "狀態",
            submit: "提交",
            success: "成功",
            talking: "說",
            thankYou: '谢谢你',
            title: "標題",
            uploadImage: "上傳圖片",
            viewComment: "查看評論",
            weeklyWithCapitial: "每週",
            with: '和',
            withOthers: "和其他人",
            yes: "是的",
            zip: "郵政編碼",
            include: "包括",
            notInclude: "不包括",
            approveAll: "全部批准",
            denyAll: "全部拒絕",
            removeFromPlan: "從計劃中刪除",
            said: "說",
            errors: {
              errorPageTitle: '有一個錯誤！',
              errorPageSubTitle: '對於那個很抱歉。請待會再過來。，',
              error404: '404頁面不存在，',
              saveError: '抱歉，保存您的條目存在錯誤。請稍後再試。，',
              deleteError: '抱歉，刪除您的條目存在錯誤。請稍後再試。，',
              createPlanError: '抱歉，創建您的計劃存在錯誤。請稍後再試。',              
            },
          },
        },
      },

      korean: {
        translation: {
          contact: {
            contactUs: '문의하기',
            message: '메시지',
            send: '보내다',
            sayHello: '안녕하세요',
            saveTitle: '당신의 이메일이 성공적으로 전송되었습니다!',
            saveMessage: 'Center On Me 담당자가 곧 연락을 드리겠습니다.',
            emailError: '유효한 이메일 주소를 입력하세요',
            phoneError: '유효한 전화 번호를 입력하세요',
          },
          password: {
            lowercase: "소문자 포함",
            uppercase: "대문자 포함",
            eightCharacters: "최소 8자 이상",
            number: "숫자 포함",
            specialCharacter: "특수 문자가 포함되어 있습니다.",
            passwordsMatch: "비밀번호 일치",
            errorCannotMatch: "이전 비밀번호와 새 비밀번호는 일치하지 않습니다.",
          },
          saveProgress: {
            uploadPhoto: '이미지 업로드',
            updateProfile: '프로필 업데이트',
          },
          agreements: {
            pleaseRead: "귀하의 정보를 보호하는 것이 우리의 최우선 과제입니다. 읽어주세요",
            privacyTitle: "웹사이트 개인정보 보호",
            termsTitle: "이용약관",
            ifYouAgree: `동의하면 이름과 성을 입력하고 "동의함"을 클릭하십시오.`,
          },
          settings: {
            pageTitle: "설정",
            subTitle: "당신의 계획을 원하는 대로 맞춤화할 수 있는 곳!",
            saveSettings: "설정 저장",
            planVideo: "나의 기획 영상",
            contributorPage: "기여자 관리",
            colorPalette: "마이 컬러 팔레트",
            changePassword: {
              changePassword: "비밀번호 변경",
              subTitle: "그 비밀번호를 바꾸자!",
              currentPassword: "현재 비밀번호",
              newPassword: "새 비밀번호",
              confirmPassword: "비밀번호 확인",
              currentIncorrect: "입력한 비밀번호가 잘못되었습니다",
              samePasswordError: "아, 새 비밀번호를 사용하세요",
              successMessage: "비밀번호가 변경되었습니다!"
            },
            colorPalettePage: {
              pageTitle: "색상 팔레트",
              subTitle: "계획을 세울 때 보고 싶은 색상을 선택하세요!",
              myColorPalette: "마이 컬러 팔레트",
              select: "원하는 색상 팔레트를 선택하십시오",
              brainstormBlue: "브레인스토밍 블루",
              planningPurple: "보라색으로 계획",
              organizingOrange: "오렌지 정리",
              gamePlanGreen: "게임 플랜 그린",
              primaryColorTooltip: "텍스트, 버튼 및 아이콘",
              secondaryColorTooltip: "헤더, 선택된 버튼, 테이블",
              contributorColorTooltip: "기고자 항목",
              textColorTooltip: "텍스트",
              saveMessage: "색상 팔레트가 업데이트되었습니다!"
            },
            manageContributors: {
              sectionHeader: "내 참여자 및 알림 관리",
              subHeader: "기고자 카드를 클릭하여 권한을 설정하거나 편집하세요",
              myContributors: "내 기여자",
              dialogConfirm: "모든 것이 올바르게 보이나요?",
              dialogTitle: "기고자 정보",
              inviteSuccess: "기고자에게 초대장이 전송되었습니다",
              updateSuccess: "기고자 정보가 업데이트되었으며 기고자에게 알리는 이메일이 전송되었습니다",
              updateSuccessNoEmail: "기고자 정보가 업데이트되었습니다!",
              lastContribution: "마지막 기여:",
              noContributions: "아직 기여가 없습니다",
              addContributor: "기고자 추가",
            },
            contributorNotifications: {
              myNotifications: "내 알림",
              letsSetPreferece: "기본 설정을 지정합시다",
              howToNotify: "기고자가 계획에 추가할 때 어떻게 알림을 받고 싶습니까?",
              howOften: "얼마나 자주 알림을 받으시겠습니까?",
              saveAlert: "알림 설정이 업데이트되었습니다",
            },
            managementPage: {
              sectionHeader: "내 기여자",
              noContributorsContext:
                "기고자에게 계획의 다른 섹션에 대한 권한을 부여할 수 있습니다. 그런 다음 해당 섹션에 대한 의견 및 기타 추가 사항을 작성할 수 있습니다!",
            },
            profile: {
              pageTitle: "이름 및 세부 정보",
              sectionHeader: "센터 온 미에 오신 것을 환영합니다",
              subHeader: "잠시 시간을 내어 자기소개를 부탁드립니다",
              profilePicture: '프로필 사진',
              name: "이름",
              languageAndNumber: "언어 및 전화번호",
              address: "주소",
              firstNameError: "이름은 필수 항목입니다",
              lastNameError: "성은 필수 항목입니다",
              phoneError: "유효한 전화번호가 필요합니다",
              saveConfirmationMsg: "이게 맞나?",
              backToSettings: "설정으로 돌아가기",
              backToPlans: "내 계획으로 돌아가기",
              saveMessage: "정보를 추가해주셔서 감사합니다!",
              firstTimeSaveMessage: '당신을 알게되어 기쁩니다! 이제 계획을 시작하겠습니다.',
              notQuite: "좀 빠지는",
              speechToText: '텍스트로 연설',
              speechToTextEnabled: '예, 연설을 사용하여 텍스트를 사용하고 싶습니다!',
              speechToTextDisabled: '아니요, 나는 연설을 사용하여 문자를 사용하고 싶지 않습니다.',
              speechToTextWarning: '현재 영어로만 제공됩니다',              
              languages: {
                english: "영어",
                armenian: "아르메니아 사람",
                chineseSimple: "중국어 (간체",
                chineseTraditional: "중국 전통",
                farsi: "파르시",
                hmong: "몽족",
                korean: "한국인",
                spanish: "스페인의",
                tagalog: "타갈로그어",
                vietnamese: "베트남어",
              },
            },
            account: {
              pageTitle: '계정',
              subTitle: '구독 관리 장소',
              benefitsTitle: '유료 구독 혜택',
              manageTitle: '구독 관리',
              benefits: {
                multiplePlans: '여러 계획을 만듭니다',
                fullAccess: '계획 페이지에 대한 전체 액세스',
                printablePDF: '인쇄 가능한 PDF 계획 요약',
                uploadPhotos: '사진 업로드',
                planVideo: '기고자와 공유 할 계획 비디오를 만듭니다',
                backgroundImage: '사용자 정의 배경 이미지를 선택하십시오',
                themeColor: '프로필 테마 색상을 선택하십시오',
              },
              currentPlan: '현재 계획',
              trial: '30 일 시험',
              subscription: '12 개월 가입',
              noSubscription: '현재 구독이 없습니다',
              expirationDate: '만료일',
              expiresOn: '구독이 만료됩니다',
              paymentMethod: '지불 방법',
              supportContact: '지원 연락처',
              supportContactIs: '지원 연락처는 {{contactName}}입니다.',
              editSupportContact: '지원 연락처 편집',
              supportContactLabel: '지원 연락처',
              contactEmailLabel: '담당자 이메일 주소',
              contactPhoneLabel: '연락처 전화 번호',
              saveSupportMessage: '지원 연락처가 업데이트되었습니다.',
              supportContactError: '지원 연락이 필요합니다',
              contactEmailError: '지원 연락처 이메일이 필요합니다',
              contactPhoneError: '지원 연락처 전화 번호가 필요합니다',                         
              subscribeButton: '구독하다',
              renewButton: '구독 갱신',
              requestRenewal: '구독 갱신 요청',
              renewDialogTitle: '구독을 갱신하십시오',
              selectedPlan: '선택된 계획',
              billed: '청구',
              payment: '지불',
              howItWorks: '구독의 작동 방식',
              billingInformation: '청구 정보',
              billingStartPlanning: '결제가 수락 되 자마자 계획을 시작할 수 있습니다.',
              expirationExplanation: '가입 기간이 만료되면 구독은 어느 당사자가 요구하는 추가 통지 나 조치없이 자동으로 종료됩니다. 추가 요금이 부과되지 않으며 새 구독을 구매하지 않으면 구독과 관련된 혜택에 더 이상 액세스 할 수 없습니다.',
              termsConditions: '이용 약관',
              trialToMemberMessage: 'Center On Me에 오신 것을 환영합니다! 이제 ME Center On Me 계획의 모든 이점에 액세스 할 수 있습니다.',
              memberRenewalMessage: '귀하의 계획은 12 개월 동안 갱신되었습니다',
              contactEmail:  '담당자 이메일 주소',
              contactPhone: '연락처 전화 번호',
              contactSuccessMessage: '지원 연락처가 업데이트되었습니다',
              subscribeDialogTitle: 'Center On Me를 구독하십시오',
              trialExpireOn: '당신의 시험은 만료 될 것입니다',
              expiredOn: '구독이 만료되었습니다',
              paymentSuccessMessage: '감사합니다! 우리는 당신이 나에게 센터를 즐기기를 바랍니다',
              today: "오늘 ",
              todayDescription: "Center On Me에 대한 전체 액세스를 잠금 해제하십시오",
              tenMonths: "10 개월 ",
              tenMonthDescription: "계획이 만료 될 때 상기시켜주십시오.",
              twelveMonths: "12 개월 ",
              twelveMonthDescription: "구독이 종료됩니다",
              showPaymentOptions: '지불 옵션을 표시하십시오',
            },
            profilePictureDialog: {
              title: "프로필 사진 추가",
              uploadPhoto: "사진 업로드",
            },
            backgroundImage: {
              pageTitle: '배경 이미지',
              subTitle: '배경 이미지를 선택하거나 직접 업로드하십시오',
              hideGrid: '이미지 그리드를 숨기십시오',
              showGrid: '이미지 그리드 표시',
              selectImage: "배경 이미지 선택",
              noImage: "이미지가 없음",
              newImageWarning: "새 이미지를 선택하면 업로드한 이미지가 제거됩니다. 계속하시겠습니까?",
              warningConfirm: "예, 새로운 이미지를 원합니다.",
              warningCancel: "아니, 내 이미지를 유지",
              uploadError: "죄송합니다. 배경 이미지를 업데이트하는 중에 오류가 발생했습니다.",
            },
            planVideoPage: {
              sectionHeader: "나의 기획 영상",
              subHeader: "기고자에게 계획을 소개하는 비디오 만들기",
              saveAlertText: "계획 영상 올려주셔서 감사합니다!",
              explainTextGreeting: "안녕하세요 {{firstName}}님",
              explainTextP1: `이 공간은 기여자들이 동영상을 업로드할 수 있는 공간입니다.				
                 그들이 귀하의 계획에 기여하기 위해 Center On Me에 로그인하자마자 확인하십시오.				
                 이 비디오는 왜 이것을 만드는지 설명할 수 있는 기회입니다.				
                 당신이 상상하는 미래의 예를 계획하고 기여자에게 제공				
                이 계획을 작성하는 데 있어 자신을 위해.`,
              explainTextP2: `				
                 휴대전화, 컴퓨터 또는 태블릿에서 동영상을 녹화하고 업로드하세요.				
                 여기. 동영상은 10분 미만이어야 합니다. 팁				
                 비디오를 만드는 방법에 대해서는 예제 비디오를 보거나 선택하십시오.				
                "내 비디오를 만들기 위한 팁".`,
              explainTextEnding: "고맙습니다,",
              explainTextSignature: "나를 중심으로",
              tipsButton: "내 비디오를 만들기 위한 팁",
              exampleVideoTitle: "예시 비디오",
              alvaVideoTitle: "알바 예",
              uploadButton: "동영상 업로드",
              removeButton: "내 비디오 제거",
              videoError: "귀하의 브라우저는 비디오 태그를 지원하지 않습니다.",
              lengthError: "오류: 최대 비디오 길이는 10분입니다.",
              tips: {
                dialogTitle: "동영상 제작을 위한 팁",
                tipsTitle: "동영상 녹화 및 업로드를 위한 팁",
                tipOne: "얼굴 전체가 보이는 조명에서 동영상을 녹화하세요",
                tipTwo: "기록을 세우기 전에 하고 싶은 말을 생각해봐",
                tipThree: "비디오를 10분 미만으로 유지",
                tipFour: `동영상을 업로드한 후 시청하는 데 문제가 있는 경우 centeronme@mainsl.com으로 이메일을 보내주십시오.				
                사용자 이름(이메일 주소)과 업로드한 비디오 파일 이름(예: "Myplanvideo.mov) 을 제공합니다`,
                examplesTitle: "동영상에서 답변할 예시 질문:",
                exampleOne: "당신의 계획에 기여하도록 사람들을 초대하는 이유는 무엇입니까?",
                exampleTwo: "당신의 계획으로 무엇을 성취하기를 희망합니까?",
                exampleThree: "무엇을 계획하고 싶습니까?",
                exampleFour: "기고자들이 계획 작성을 어떻게 지원할 수 있습니까?",
              },
              contributor: {
                sectionHeader: "그들의 기획 영상",
                subHeader: "",
                noVideo: "계획 소유자가 계획 비디오를 추가하지 않았습니다.",
              },
            },
            knowledgeCenter: {
              pageTitle: '지식 센터',
              subTitle: '사이트 사용에 대해 자세히 알아보려면 링크를 클릭하십시오.',
              videoTab: '비디오',
              faqTab: '자주하는 질문',
              contactTab: '문의하기',
              videos: {
                gettingStartedTitle: '시작하기',
                profileSettings: '프로필 설정',
                landingPageAndBadges: '방문 페이지 및 배지',
                contributorsTitle: '기여자',
                addingContributors: '기여자 추가',
                managingContributors: '기여자 관리',
                howToContribute: '기여 방법',
                gettingToKnowYouTitle: '당신을 알아가는',
                likeAndAdmire: '좋아요 및 감탄',
                people: '내 인생의 사람들',
                favorites: '내가 좋아하는 것들',
                thingsIDo: '내가 재미를 위해 하는 일',
                routines: '내 루틴',
                historyMap: '역사 지도',
                travelPlan: '여행하다',
                happeningNowTitle: '지금 무슨 일이야',
                healthAndSafety: '건강 및 안전',
                livingInHome: '내 집에 살기',
                work: '일하다',
                school: '학교',
                startPlanningTitle: '계획을 시작합시다',
                workGoals: '작업 목표',
                healthGoals: '건강 목표',
                livingGoals: '생활 목표',
                travelGoals: '여행 목표',
                funGoals: '재미있는 목표',
                relationshipGoals: '관계 목표',
                planSummaryTitle: '계획 요약',
                webVersion: '웹 버전 및 PDF로 내보내기',
                dialogTitle: '교육용 비디오',
              },
              faq: {
                pageTitle: '자주 묻는 질문',
                usingTheKnowledgeCenter: '지식 센터 사용',
                passwordAndUsername: '비밀번호 및 사용자 이름',
                managingPlans: '계획 관리',
                planOwnersAndContributors: '계획 소유자 및 기여자',
                usingTheSite: '사이트 사용',
                accessingSupport: '지원 액세스',
                registration: '등록 및 구독',
                howToUse: '지식센터는 어떻게 이용하나요?',
                whoToContact: '비디오에 언급되지 않은 질문이 있는 경우 누구에게 연락할 수 있습니까?',
                videoLanguage: '이 비디오를 영어 이외의 언어로 볼 수 있습니까?',
                findVideo: '내가 찾고 있는 비디오를 어떻게 찾을 수 있습니까?',
                passwordRequirements: '암호 요구 사항은 무엇입니까?',
                changePassword: '비밀번호는 어떻게 변경합니까?',
                changeUsername: '로그인/이메일 주소를 변경하려면 어떻게 합니까?',
                howManyPlans: '한 번 등록하면 몇 개의 계획을 만들 수 있습니까?',
                sharePlan: '내 계획을 다른 사람과 공유할 수 있습니까? 플랫폼에서 내 계획을 이메일로 보낼 수 있는 방법이 있습니까?',
                deletePlan: '실수로 계획을 삭제한 경우 어떻게 해야 합니까?',
                whatIsPlanOwner: '"계획 소유자"는 누구/무엇입니까?',
                whatIsContributor: '"기여자"는 누구/무엇입니까?',
                contributorLimit: '내 계획에 추가할 수 있는 기여자 수에 제한이 있습니까?',
                approveComment: '기고자 댓글을 승인하면 알림을 받나요?',
                denyComment: '기고자 댓글을 거부하면 알림을 받나요?',
                updateProfile: '내 프로필 세부 정보를 언제든지 업데이트할 수 있습니까?',
                howManyActivities: '각 섹션에 몇 개의 활동을 추가할 수 있습니까?',
                listSomeoneAsResponsible: '목표 달성을 책임지는 사람을 나열하면 알림을 받게 됩니까?',
                editActivity: '활동을 편집하려면 어떻게 해야 합니까?',
                deleteActivity: '활동을 삭제하려면 어떻게 하나요?',
                accessibilityFeatures: '플랫폼은 어떤 접근성 기능을 제공합니까?',
                forgetToSave: '작업 중인 내용을 저장하는 것을 잊어버리면 어떻게 됩니까?',
                useOnTablet: '태블릿을 사용하여 계획을 실행할 수 있습니까?',
                useOnPhone: '내 전화를 사용하여 내 계획을 수행할 수 있습니까?',
                phoneApp: '다운로드해야 하는 전화 앱이 있습니까?',
                needInternet: '내 계획을 실행하려면 인터넷에 액세스할 수 있어야 합니까? 오프라인 모드가 있습니까?',
                whatAreBadges: '배지는 무엇입니까?',
                helpWithPlan: '내 계획에 도움이 필요하면 누구에게 연락해야 합니까?',
                issuesAccessingPlan: '내 계획에 액세스하는 데 문제가 있는 경우 누구에게 연락해야 합니까?',
                issueWithPlanVideo: '플랜 비디오를 업로드할 수 없는 경우 누구에게 연락해야 합니까?',
                forgotUsername: '로그인 사용자 이름을 잊어버리면 어떻게 합니까? 누구에게 연락합니까?',
                wasRegistrationSuccessful: '등록이 성공했는지 어떻게 알 수 있습니까?',
                renewPlan: '플랜을 갱신해야 하는지 어떻게 알 수 있습니까?',
                howToCancel: '구독을 취소하려면 어떻게 해야 하나요?',
              },
              faqAnswers: {
                howToUse: `더 자세히 알고 싶은 섹션이나 링크를 클릭하세요. 별도의 페이지로 이동하고 사이트의 해당 부분을 안내하는 비디오를 볼 수 있습니다.`,
                whoToContact: `문의하기' 탭을 통해 언제든지 연락주세요! 해당 탭에서 이메일을 보낼 양식을 작성할 수 있습니다. 이메일에 질문이 있는 페이지와 비디오를 포함하십시오. 곧 회신드리겠습니다.`,
                videoLanguage: `당분간 이 영상은 영어로만 제공됩니다. 모든 동영상에 자막이 제공되었습니다.`,
                findVideo: `검색창에 찾고자 하는 페이지를 입력하면 동영상 링크가 팝업됩니다. 비디오는 페이지 이름으로 구성됩니다. 찾고 있는 동영상을 찾을 수 없는 경우 "문의하기" 탭을 사용하여 문의해 주세요.`,
                passwordRequirements: `비밀번호는 소문자, 대문자, 숫자, 특수 문자를 포함해야 하며 8자 이상이어야 합니다.`,
                changePassword: ` 프로필 설정으로 이동하여 "비밀번호 변경" 버튼을 선택하면 언제든지 비밀번호를 변경할 수 있습니다.`,
                changeUsername: `이메일 주소를 변경할 수 없습니다.`,
                howManyPlans: `당신이 만들 수 있는 계획의 수에는 제한이 없습니다. 그러나 계획 기여자를 포함하여 계획에 입력한 정보는 한 계획에서 다른 계획으로 이월되지 않습니다.`,
                sharePlan: `플랜 소유자는 언제든지 누구와도 계획을 공유할 수 있습니다. 계획 요약 페이지의 오른쪽 상단에 "PDF로 내보내기"라는 버튼이 있습니다. 해당 버튼을 클릭하면 계획이 다운로드되고 선택한 사람에게 인쇄하거나 이메일로 보낼 수 있습니다.`,
                deletePlan: ` 플랜을 삭제하려고 하면 Center On Me에서 플랜을 정말로 삭제할 것인지 묻는 메시지를 표시합니다. 여전히 계획을 삭제하기로 선택한 경우 할 수 있는 일은 없으며 처음부터 계획을 다시 만들어야 합니다.`,
                whatIsPlanOwner: `"계획 소유자"는 계획의 초점을 맞추는 사람, 계획을 만드는 사람입니다. 계획 소유자는 항상 이 계획의 초점이 되어야 합니다!`,
                whatIsContributor: `기고자는 "계획 소유자"가 자신의 계획에 기여하도록 초대한 사람입니다. 계획 소유자는 기여자가 보고/또는 댓글을 달 수 있는 계획의 일부를 완전히 제어할 수 있습니다. 모든 기여자 의견은 계획에 포함되기 전에 검토 및 승인되어야 합니다.`,
                contributorLimit: `기고자 수에는 제한이 없습니다. 생성하는 각 계획에 대해 각 기여자를 별도로 추가해야 합니다.`,
                approveComment: `아니요, 댓글이 승인되면 기여자에게 알림이 전송되지 않습니다.`,
                denyComment: `아니요, 댓글이 거부되면 기여자에게 알림이 전송되지 않습니다.`,
                updateProfile: `프로필 설정으로 이동하여 언제든지 프로필 세부 정보를 업데이트할 수 있습니다. 프로필 설정 내에서 이름 및 세부 정보, 프로필 사진, 배경 및 색상 팔레트를 변경할 수 있습니다.`,
                howManyActivities: `각 섹션에 추가할 수 있는 활동이나 목표의 수에는 제한이 없습니다.`,
                listSomeoneAsResponsible: `목표 달성을 책임지는 사람을 나열해도 알림이 전송되지 않습니다. 계획 소유자가 목표를 가진 지원을 원하면 그 사람과 의사 소통하고 편안하다고 느끼면 계획을 공유해야합니다.`,
                editActivity: `카드를 클릭하면 언제든지 활동이나 목표를 수정할 수 있습니다. 양식이 열리고 모든 정보가 변경되거나 업데이트될 수 있습니다.`,
                deleteActivity: `카드를 클릭하면 언제든지 활동이나 목표를 삭제할 수 있습니다. 양식이 열리고 하단까지 스크롤하여 빨간색 "삭제" 버튼을 선택하여 목표를 삭제할 수 있습니다.`,
                accessibilityFeatures: `Center On Me는 사이트 내에서 접근성 기능을 제공하기 위해 Userway를 사용합니다. 이러한 접근성 기능 중 일부는 대비, 글꼴 크기, 커서 안내 및 텍스트 또는 공간 정렬을 변경하는 것입니다. 왼쪽의 모든 페이지에 있는 둥근 "사람" 아이콘을 선택하여 기능을 선택할 수 있습니다.`,
                forgetToSave: `사이트 내에서 다른 페이지를 생성하므로 저장을 누르는 것이 매우 중요합니다. 정보는 자동 저장되지 않습니다.`,
                useOnTablet: `네! Center On Me는 태블릿이나 스마트폰에서 접속할 수 있습니다.`,
                useOnPhone: `네! Center On Me는 스마트폰에서 접속할 수 있습니다. 사이트는 모바일 반응형으로 제작되었으므로 일부 데이터는 태블릿이나 컴퓨터를 사용하는 것처럼 쉽게 읽히지 않을 수 있습니다.`,
                phoneApp: `아니요, 센터온미에는 앱이 없습니다. 스마트폰에서 웹 브라우저 앱을 열고 www.centeronme.com으로 이동하면 Center On Me에 액세스할 수 있습니다.`,
                needInternet: `예, Center On Me를 사용하려면 인터넷이 필요합니다. Center On Me에는 오프라인 모드가 없습니다.`,
                whatAreBadges: `배지는 사용자와 Center On Me가 사이트 내에서 진행 상황을 추적하는 방법입니다. 페이지를 완료할 때마다 배지를 받게 되며 방문 페이지에서 배지를 볼 수 있습니다. 재미있는 놀라움을 위해 배지를 클릭하십시오!`,
                helpWithPlan: `지원이 필요한 경우 지식 센터 내 "문의하기" 탭을 방문하십시오. Center On Me 팀으로 이메일이 전송되며 곧 답변해 드리겠습니다.`,
                issuesAccessingPlan: `지원이 필요한 경우 지식 센터 내 "문의하기" 탭을 방문하십시오. Center On Me 팀으로 이메일이 전송되며 곧 답변해 드리겠습니다.`,
                issueWithPlanVideo: `먼저 동영상 업로드 팁을 확인하여 동영상 요구 사항을 충족하는지 확인하세요. 여전히 지원이 필요한 경우 지식 센터 내의 "문의하기" 탭을 방문하십시오. Center On Me 팀으로 이메일이 전송되며 곧 답변해 드리겠습니다.`,
                forgotUsername: `사용자 이름은 이메일 주소입니다. 지원이 필요한 경우 지식 센터 내의 "문의하기" 탭을 방문하십시오. Center On Me 팀으로 이메일이 전송되며 곧 답변해 드리겠습니다.`,
                wasRegistrationSuccessful: `결제가 성공하면 연결된 PayPal 계정으로 이메일 영수증을 받게 됩니다. PayPal 앱에서도 영수증을 찾을 수 있습니다. 아래의 결제 이메일 영수증 예시를 참조하세요.`,
                renewPlan: `사용자는 3개월, 6개월 및 10개월에 구독이 만료되면 사용자에게 알리기 위해 이메일 알림을 수신하고 요금제 갱신 방법에 대한 지침을 제공합니다.`,
                howToCancel: `1년 구독권을 구매합니다. 1년 구독은 취소할 수 없습니다. 요금제를 갱신할 시간이 되면 이메일이 전송되며 그 때 요금제를 갱신하지 않도록 선택할 수 있습니다.`,
              }
            },
          },
          lockedFeatures: {
            photoLock: '사진을 업로드하려면 구독이 필요합니다',
            videoLock: '비디오를 업로드하려면 구독이 필요합니다',
            pdfLock: 'PDF를 다운로드하려면 구독이 필요합니다',
            sectionLock: '이 기능에 액세스하려면 구독 옵션을 선택하십시오.',
            sectionLockSaveMessage: '이 섹션은 시험 사용자가 수정할 수 없습니다',
            createPlanLock: '여러 계획을 만들려면 구독 옵션을 선택하십시오',
            expiredLock: '이 기능에 액세스하려면 구독 옵션을 선택하십시오',
            expiredAlert:  '구독이 만료되었습니다. 이 섹션을 보려면 갱신하십시오.',            
          },
          permissions: {
            theContributor: "기고자",
            cannotView: "볼 수 없다",
            canView: "볼 수 있다",
            theSection: "그 영역",
            toTheSection: "섹션으로",
            and: "그리고",
            but: "하지만",
            cannotComment: "댓글을 추가할 수 없습니다",
            canComment: "의견을 추가할 수 있습니다",
            gtkyTitle: "당신을 알아가는",
            happeningTitle: "당신의 삶에 무슨 일이 일어나고 있습니까?",
            planningTitle: "계획",
          },
          breadcrumbs: {
            planning: "계획을 시작합시다",
            happening: "사고",
            gettingToKnowYou: "당신을 알아가는",
            myRoutines: "내 루틴",
            myPeople: "내 인생의 사람들",
            likeAndAdmire: "좋아요 및 감탄",
            work: "일하다",
            school: "학교",
            home: "집",
            fun: "재미있는",
            travel: "여행하다",
            health: "건강",
            relationships: "관계",
            goal: "내 목표",
            historyMap: "역사 지도",
            favoriteThings: "내가 좋아하는 것들",
            add: "새로운 걸 더하다",
            profile: "프로필",
            settings: "설정",
            contributors: "기여자 및 알림",
            thingsIDo: "내가 재미로 하는 일",
            planningVideo: "내 계획 비디오",
            planSummary: "계획 요약",
            changePassword: "비밀번호 변경",
            knowledgeBase: "지식 센터",
            colorPalette: "색상 팔레트",
            myActivity: "내 활동",
          },
          userMenu: {
            allPlans: "모든 계획",
            planDashboard: "계획 대시보드",
            planSummary: "계획 요약",
            settings: "설정",
            subscribe: "구독하다",
            logout: "로그 아웃"
          },
          timeoutAlert: {
            attention: "주목",
            description: "로그아웃하려고 합니다.",
            stillPlanning: "나는 아직 계획 중이야!",
            logMeOut: "로그아웃",
            warningOnLogout: "저장하지 않은 데이터는 손실됩니다",
          },
          allPlans: {
            pageTitle: "계획에 대한 간략한 개요",
            subTitle: "시작하려면 하나를 선택하세요.",
            iContributeTo: "내가 기여하는 계획",
            myPlans: "내 계획",
            newContributeTo: "새로운 계획에 기여",
            createNewPlan: "새 계획 만들기",
            planOwner: "계획 소유자",
            planCreated: "계획 수립",
            lastViewed: "마지막으로 본",
            planVideo: "플랜 비디오",
            manageContributors: "기고자 및 알림 관리",
            planSummary: "계획 요약",
            deletePlan: "계획 삭제",
            createPlanDialog: {
              dialogTitle: '새로운 계획을 만드십시오',
              explainerText: '시작하려는 새 계획의 이름을 선택하십시오.',
              inputLabel: '계획 이름',
              createMessage: '새로운 계획을 시작하면 이전 계획의 업무 나 기고자가 이어지지 않습니다.',
            },
            contributeToPlanDialog: {
              dialogTitle: '계획에 기여합니다',
              explainerText: '계획 소유자로부터받은 코드를 입력하십시오',
              inputLabel: '액세스 코드',
              errorMessage: '액세스 코드는 계획과 일치하지 않았습니다. 다시 시도하십시오',
            }
          },
          deletePlan: {
            dialogTitle: "내 계획 삭제",
            explanation: "계획을 삭제하려고 합니다.",
            confirmMessage: "이 계획을 삭제하시겠습니까?",
            noteToDownload: "참고: 계획은 복구할 수 없으므로 삭제하기 전에 다운로드하여 저장하는 것이 좋습니다.",
            downloadButton: "내 계획 다운로드",
            keepPlanButton: "내 계획 유지",
            deleteButton: "내 계획 삭제",
            deleteSuccessMessage: "삭제되었습니다!",
          },
          planLandingPage: {
            videoTitle: "센터 온 미 플랜에 오신 것을 환영합니다",
            gtky: "당신을 알아가는",
            whatIsHappening: "당신의 삶에서 일어나고 있는 일",
            startPlanning: "계획 시작",
            newBadge: "새 배지",
            getStarted: "시작하다",
            leftOff: "당신이 그만둔 곳",
            happeningButton: "사고",
            planningButton: "목표 계획",
            recentBadges: "최근 획득한 배지",
            gtkyNoCaps: "당신을 알아가는",
            happeningShort: "무슨 일이야",
            planning: "계획",
            noBadgeMessage: "아직 배지가 없습니다! 섹션을 완료하여 배지를 받으세요.",
            contributorGreeting: "환영하다",
            contributorMessage: `귀하가 기고자로 참여하는 것을 확인했습니다. 이는 누군가가 귀하를 매우 중요하게 생각한다는 것을 의미합니다! 
              귀하는 이 플레이에 기여하는 동안 필수적인 역할을 합니다. 이 사이트의 목적을 유지하고 플랜 소유자를 플랜의 중심에 두면서 시간을 
              활용하여 풍부하고 의미 있는 정보를 추가할 것을 요청합니다. 계획에 기여할 때 시간을 내어 그 사람이 누구인지, 그들에게 중요한 것이 무엇인지, 
              희망과 꿈을 이루도록 어떻게 도울 수 있는지에 대한 구체적인 정보를 반영하고 캡처해 보시기 바랍니다.
            `,
            contributorQuote: `"모든 사람은 능력, 능력 및 은사를 가지고 있습니다. 좋은 삶은 그 능력을 사용할 수 있는지, 능력이 표현되고 주어진 
              은사에 달려 있습니다. 그렇다면 그 사람은 가치가 있고 강력하다고 느끼며 주변 사람들과 잘 연결됩니다. 그들과 그 사람 주변의 커뮤니티는 그 
              사람이 하는 공헌으로 인해 더 강력하다고 느낄 것입니다."
            `,
            quote: `"당신의 목표는 당신을 안내하고 당신의 삶에 무엇이 가능한지를 보여주는 로드맵입니다." - 레 브라운`,
            profileBadges: {
              addContributor: '배지: 계획에 기여자 추가',
              colorPalette: '배지: 색상 팔레트 선택',
              approveContributor: '배지: 기여 승인',
              planningVideo: '배지: 기획 비디오 만들기',
              profilePicture: '배지: 프로필 사진 업로드',
              backgroundImage: '배지: 새 배경화면 업로드',
              profileComplete: '배지: 프로필 완료!',
            }
          },
          badges: {
            badgeSectionTitle: "내가 얻은 배지",
            badgesPlanningExplanation: `각 섹션을 완료하여 배지를 획득하세요! 섹션을 완료하면 배지가 여기에 표시됩니다.				
                당신의 미래를 계획하면서 즐거운 시간을 보내십시오!`,
            badgesHappeningExplanation: `각 섹션을 완료하여 배지 획득을 시작하세요! 섹션을 완료하면 배지가 여기에 표시됩니다.				
                당신의 인생에서 무슨 일이 일어나고 있는지 배우면서 재미를 느끼자!`,
            badgesGTKYExplanation: `각 섹션을 완료하여 배지를 획득하세요! 섹션을 완료하면 배지가 여기에 표시됩니다.				
                당신을 알아가는 동안 재미를 좀 보자!`,
          },
          sections: {
            likeAndAdmire: "좋아요 & 감탄",
            people: "내 인생의 사람들",
            historyMap: "이정표",
            favorites: "좋아하는 것들",
            travelPlan: "여행하다",
            thingsIDo: "재미로 하는 일",
            dailyRoutines: "일상 생활",
            weeklyRoutines: "주간 루틴",
            routines: "일과",
            happeningHome: "해프닝 홈",
            happeningWork: "해프닝 워크",
            happeningSchool: "해프닝 스쿨",
            happeningHealth: "행복한 건강",
            planningHome: "플래닝 홈",
            planningWork: "기획 작업",
            planningTravel: "여행 계획",
            planningFun: "재미를 계획하다",
            planningHealth: "건강 계획",
            planningRelationships: "관계 계획",
          },
          planningPage: {
            pageTitle: "계획을 시작합시다",
            planningVideo: "기획 영상",
            housing: "주택",
            work: "일하다",
            health: "건강",
            fun: "재미있는",
            relationships: "관계",
            travel: "여행하다",
            addAnotherGoal: "다른 목표 추가"
          },
          planningHealth: {
            sectionName: "건강 계획",
            sectionHeader: "건강 목표",
            subHeader: "건강한 미래를 위한 계획을 세우자",
            videoTitle: "건강 목표 - 유용한 비디오",
            goalTitle: "이 목표의 제목을 무엇으로 지정하시겠습니까?",
            goalAroundHealth: "건강에 대한 당신의 목표는 무엇입니까?",
            workedOnThisGoal: "이전에 이 목표를 달성한 적이 있습니까?",
            whatWorked: "이 목표를 달성할 때 어떤 효과가 있었나요?",
            didntWork: "이 목표를 달성할 때 효과가 없었던 것은 무엇입니까?",
            healthTableHeader: "이 목표에 대해 생각할 때, 당신은 무엇을 해야 한다고 생각합니까...",
            doYouHaveSupport: "당신의 인생에서 이 목표를 지지해줄 수 있는 사람이 있습니까?",
            attachedPeopleMessage: "지원할 수 있는 사람을 선택하세요",
            anyOneElseWhoCanSupport:
              "당신의 목표를 달성하는 데 도움을 줄 수 있는 다른 사람이 있습니까? 그리고 그 이유는 무엇입니까?",
            goalCommit: "이 미래를 만들기 위해 무엇을 할 수 있습니까?",
            increase: "증가하다",
            decrease: "감소하다",
            doMore: "더 많이",
            doLess: "더 적게",
            increaseEx: "예. 나 자신에게 집중하는 시간",
            decreaseEx: "예. Facebook에서 보내는 시간",
            doMoreEx: "예. 숨 쉬는 데 보내는 시간",
            doLessEx: "예. 단 음식 먹기",
            saveMessage: "대단합니다. 이 목표에 행운을 빕니다! 다른 건강 목표를 추가하시겠습니까?",
            deleteMessage: "이 테이블 행을 삭제하시겠습니까?",
            deleteConfirm: "알겠습니다. 행이 제거되었습니다.",
            summary: {
              goalTitle: "건강에 대한 나의 목표",
              whatWorked: "내가 전에 이 목표를 달성했을 때 여기가 효과가 있었다",
              didntWork: "내가 전에 이 목표에 대해 작업했을 때 여기에서 작동하지 않은 것이 있었습니다",
              healthTableHeader: "내 목표를 생각할 때 나는 나를 돕기 위해 다음과 같은 일을 할 수 있다고 생각한다",
              doYouHaveSupport: "이 목표를 달성하는 데 도움을 줄 수 있는 내 지원 서클의 사람들은",
              anyOneElseWhoCanSupport: "내가 도울 수 있다고 생각하는 다른 사람들",
              goalCommit: "약속할 수 있다",
            },
          },
          planningRelationships: {
            sectionName: "관계 계획",
            sectionHeader: "관계 목표",
            subHeader: "사람들과의 연결을 시작합시다!",
            newOrStrengthen: "새로운 관계를 계획하고 싶습니까, 아니면 현재 관계를 강화하고 싶습니까?",
            saveMessage: "훌륭한 관계 목표 같군요! 다른 목표를 추가하시겠습니까?",
            newText: "새로운",
            strengthenText: "강하게 하다",
            relationship: '관계',
            strengthen: {
              videoTitle: "관계 강화 - 유용한 비디오",
              goal: "누구와 관계를 강화하고 싶습니까?",
              currentStatus: "이 관계의 현재 상태는 무엇입니까?",
              romantic: "로맨틱",
              friend: "친구",
              professional: "직업적인",
              family: "가족",
              whatToStrengthen: "이 관계를 어떻게 강화하고 싶습니까?",
              frequency: "빈도",
              communication: "의사소통",
              desiredStatus: "원하는 상태",
              howOften: "현재 이 사람과 얼마나 자주 교류하십니까?",
              howToStrengthen: "주파수를 어떻게 강화하고 싶습니까",
              strengthenCommunication: "커뮤니케이션을 강화하고 싶은 것은 무엇입니까?",
              commExample1: "예: 더 자주 전화하려고 했어요",
              commExample2: "예: 내가 원할 때 그들과 이야기할 수 있었다",
              commExample3: "예: 들리고 연결되는 느낌이 들었다",
              commExample4: "예: 그들은 항상 대답하지 않을 것입니다",
              commTableHeader1: "커뮤니케이션을 강화할 때 어떤 노력을 했습니까?",
              statusTableHeader1: "이 관계의 상태를 변경하기 위해 무엇을 시도했습니까?",
              tableHeader2: "당신이 그것을 시도했을 때 당신은 무엇을 배웠습니까?",
              tableHeader3: "시도했을 때 어떤 점이 만족스러웠습니까?",
              tableHeader4: "무엇이 걱정되십니까?",
              commDoNext: "방금 나열한 내용을 보고 다음에 무엇을 할 수 있습니까?",
              preferredStatus: "이 사람의 상태를 어떻게 하시겠습니까?",
              theyAreCurrently: "현재:",
              statusExample1: "예: 데이트 신청을 해봤어요",
              statusExample2: "예: 그들은 매우 친절하고 나는 그들과 이야기하는 것을 즐긴다",
              statusExample3: "예: 그들은 예라고 말했습니다!",
              statusExample4: "예: 그 이후로 전화를 걸지 않았어요",
              finalDoNext: "방금 나열한 내용을 보고 다음으로 무엇을 할 수 있습니까?",
              deleteRowConfirm: "이 테이블 행을 제거하시겠습니까?",
              deleteRowSuccess: "알겠습니다. 행이 제거되었습니다.",
            },
            new: {
              videoTitle: "새로운 관계 계획 - 유용한 비디오",
              relationshipType: "어떤 유형의 관계를 찾고 있습니까?",
              characteristics: "연락하고 싶은 사람에게서 어떤 특성을 찾고 있습니까?",
              where: "이 사람을 어디서 만날 수 있습니까?",
              placeOfWorship: "예배 장소",
              family: "가족",
              friend: "친구",
              professional: "직업적인",
              romantic: "로맨틱",
              sharedInterests: "공동의 이익을 통해",
              online: "온라인",
              school: "학교",
              shops: "상점",
              work: "일하다",
              whenYouThinkAbout: "새로운 인연을 만들 생각을 할 때",
              atChurch: "예배 장소에서",
              throughFriend: "친구를 통해",
              throughInterests: "공동의 이익을 통해",
              atOnline: "온라인",
              atSchool: "학교에서",
              atLocalShop: "현지 상점에서",
              atWork: "직장에서",
              likeToStart: ", 어디서 시작하시겠습니까?",
              churchStart: "예배 장소에서 새로운 연결을 만드는 것에 대해 생각할 때 어디에서 시작하고 싶습니까?",
              friendStart: "친구를 통해 새로운 인연을 만들 생각이 들 때 어디서부터 시작하고 싶으신가요?",
              interestsStart: "공동 관심사를 통해 새로운 연결을 만드는 것에 대해 생각할 때 어디에서 시작하고 싶습니까?",
              onlineStart: "온라인에서 새로운 연결을 만드는 것에 대해 생각할 때 어디에서 시작하고 싶습니까?",
              schoolStart: "학교에서 새로운 연결을 만드는 것에 대해 생각할 때 어디서부터 시작하고 싶습니까?",
              shopsStart: "지역 상점에서 새로운 연결을 만드는 것에 대해 생각할 때 어디에서 시작하고 싶습니까?",
              workStart: "직장에서 새로운 연결을 만드는 것을 생각할 때 어디서부터 시작하고 싶습니까?",
              howOften: "얼마나 자주 이런 식으로 연결하시겠습니까?",
            },
            characteristics: {
              organized: "조직화",
              strong: "강한",
              dreamer: "몽상가",
              determined: "단호한",
              smart: "똑똑한",
              artist: "예술가",
              considerate: "사려 깊은",
              helpful: "도움이 되는",
              creative: "창의적인",
              thoughtful: "인정 있는",
              talented: "재간 있는",
              outgoing: "나가는",
              leader: "리더",
              intelligent: "지능적인",
              loving: "사랑하는",
              understanding: "이해",
              spiritual: "영적인",
              joyful: "즐거운",
              fixer: "해결사",
              liberal: "선심 쓰는",
              patient: "인내심 있는",
              respectful: "존경하는",
              willing: "자발적인",
              intuitive: "직관적",
              gentle: "온화한",
              generous: "관대한",
              spontaneous: "자발적인",
              independent: "독립적인",
              supportive: "지원",
              resourceful: "똑똑한",
              experiential: "체험",
              fun: "재미있는",
              funny: "재미있는",
              excited: "흥분한",
              accepting: "수락",
              busy: "바쁘다",
              pretty: "예쁜",
              openMinded: "열린 마음",
              hardWorking: "열심히 일하는",
              doer: "행동하는 사람",
              involved: "관련된",
              flexible: "유연한",
              happy: "행복하다",
              careful: "주의 깊은",
              hopeful: "유망한",
              tinkerer: "땜장이",
              riskTaker: "위험을 감수하는 사람",
              lucky: "운이 좋은",
              active: "활동적인",
              efficient: "효율적인",
              visionary: "선구자",
              explorer: "탐험가",
              responsible: "책임이 있는",
              driven: "중심의",
              confident: "자신감",
              connector: "커넥터",
              wise: "지혜로운",
              expressive: "나타내는",
              sensitive: "예민한",
              talkative: "수다쟁이",
              energized: "에너자이징",
              giving: "기부",
              observant: "관찰자",
              caring: "돌보는",
              peaceful: "평화로운",
              cool: "시원한",
              fashionable: "패셔너블",
              actor: "배우",
              writer: "작가",
              vibrant: "떠는",
              goodCook: "좋은 요리사",
              goodWithAnimals: "좋은 동물",
            },
            summary: {
              newGoalPdfTitle: "새로운 관계",
              strengthenGoalPdfTitle: "관계 강화",
              relationshipType: "내가 만들고 싶은 관계의 유형은",
              characteristics: "이 사람이 다음과 같은 특성을 갖기를 바랍니다",
              where: "나는 이 사람을 만날 수 있다",
              whereToStart: "시작하고 싶은 곳",
              howOften: "얼마나 자주",
              beingInPerson: '직접',
              talkingMore: '더 많은 이야기',
              facetiming: 'FACETIMING',
              otherMeans: '다른 수단',
              comments: "코멘트",
              strengthenGoal: "나의 관계를 강화하고 싶은 사람은",
              currentStatus: "우리 관계의 현재 상태는",
              whatToStrengthen: "강화하고 싶은 것",
              strengthenBy: "강화하고 싶다...",
              strengthenWhy: "왜?",
              whyAnswer: "이것은 내가 내 목표를 달성하는 데 도움이 될 것입니다",
              becoming: "어울리는",
            },
          },
          planningHome: {
            sectionName: "플래닝 홈",
            sectionHeader: "살아있는 목표",
            subHeader: "당신의 현재 생활 상황에 대해 당신이 좋아하거나 싫어하는 것을 보자",
            wantToLive: "어디에서 살고 싶니?",
            idealHome: "당신의 이상적인 집은 어떤 모습인가요?",
            homeAmenities: "당신의 집에 어떤 편의 시설이 있기를 원하십니까?",
            homeCloseTo: "당신의 집은 무엇을 가까이 하고 싶습니까?",
            liveWith: "함께 살고 싶은 사람이 있습니까?",
            roommateName: "누구?",
            roommateQuality: "왜 이 사람과 사는 게 잘 어울릴 것 같아?",
            askedRoommate: "동거에 대해 물었습니까?",
            liveWithPets: "당신의 미래에 애완 동물이 있습니까?",
            petType: "어떤 종류의 동물입니까?",
            finalThoughts: "우리가 논의하지 않은 이상적인 집에서 원하는 다른 것이 있습니까?",
            whatILike: "내가 좋아하는 것",
            whatIDislike: "내가 싫어하는 것",
            saveMessage: "좋습니다. 집을 계획해 주셔서 감사합니다! 다른 것을 추가하시겠습니까?",
            homeOptions: {
              singleStory: "싱글 스토리",
              apartment: "아파트",
              duplex: "듀플렉스",
              mobileHome: "이동식 주택",
              tinyHome: "작은 집",
            },
            amenities: {
              laundry: "빨래",
              internet: "무선 인터넷",
              recreation: "레크리에이션 시설",
              garden: "정원",
              deck: "갑판",
              patio: "안마당",
              land: "나라",
              ramp: "비탈길",
              pool: "수영장",
              bigDoors: "큰 문",
            },
            proximities: {
              shopping: "쇼핑",
              bus: "버스 정류장",
              friends: "친구들",
              restaurants: "레스토랑",
              entertainment: "오락",
              publicTransit: "대중교통",
              family: "가족",
            },
            summary: {
              wantToLive: "내가 살고 싶은 곳",
              wantToLiveAnswer: "{{homeType}} 의 {{place}}",
              homeAmenities: "내가 원하는 이상적인 집에서",
              otherAmenities: "이상적인 집의 다른 것들",
              homeCloseTo: "가까운 집이었으면 좋겠다",
              roommateName: "같이 살고 싶다",
              liveAlone: "혼자 살고 싶다",
              roommateBecause: "{{why}} 때문에",
              roommateQuality: "왜 이 사람과 사는 게 잘 어울릴 것 같아?",
              petType: "애완동물",
              finalThoughts: "이상적인 집의 다른 것들",
            },
          },
          planningFun: {
            sectionName: "재미를 계획하다",
            sectionHeader: "재미있는 목표",
            subHeader: "즐겁게 시작하자!",
            goal: "재미있는 일을 계획할 때 무엇을 하고 싶나요?",
            goalWhy: "이것이 당신에게 매력적인 이유는 무엇입니까? 또는 왜 이것을 하고 싶습니까?",
            goalWhen: "언제 하시겠습니까?",
            goalHowOften: "이 작업을 얼마나 자주 하시겠습니까?",
            otherPeople: "이 일을 함께 하고 싶은 사람이 있습니까?",
            otherPeopleWhoCanSupport: "지원할 수 있는 사람을 선택하세요",
            otherPeopleWhereToFind: "공유 관심사를 가진 사람들과 연결하는 데 도움이 될 수 있는 그룹이나 장소를 선택하세요.",
            needToSave: "이렇게 하려면 돈을 아껴야 합니까?",
            startedSaving: "당신은 저축을 시작 했습니까?",
            amountToSave: "아직 저축해야 하는 돈은 얼마입니까?",
            attachedPeople: "끈",
            saveMessage: "재미있을 것 같아! 또 다른 재미있는 목표를 추가하시겠습니까?",
            asap: "최대한 빨리",
            sixMonths: "앞으로 6개월",
            oneYear: "6개월에서 1년",
            twoYears: "1-2년",
            twoPlusYears: "2년 이상",
            oneTime: "일회성 이벤트",
            daily: "일일",
            weekly: "주간",
            monthly: "월간 간행물",
            quarterly: "계간지",
            facebook: "페이스북",
            groupMeet: "그룹 모임",
            newspaper: "지역 신문",
            coffeeShop: "커피숍 게시판",
            communityCenter: "커뮤니티 센터",
            summary: {
              goal: "재미있는 기획을 생각하면 하고 싶다",
              goalWhy: "이것이 나에게 매력적이기 때문에",
              goalWhen: "이렇게 하고 싶다",
              goalHowOften: "그리고 자주",
              otherPeople: "나는 이것을 하고 싶다",
              peopleInMyLife: "이 일을 함께 하고 싶은 내 인생의 사람들은",
              actionSteps: "조치 단계:",
              comments: "코멘트",
              helpMeAchieve: "이것은 내가 내 목표를 달성하는 데 도움이 될 것입니다",
              research: "이 활동을 계획하는 방법을 연구",
              needToSave: "이번 여행을 위해 저축을 해야 하나요?",
              needToSaveYes: "예, {{금액}}을(를) 저축해야 합니다.",
            },
          },
          planningTravel: {
            sectionName: "여행 계획",
            subHeader: "다음 모험 계획을 시작합시다",
            destination: "어디로 여행을 가고 싶니?",
            travelTo: "이전에 {{location}} 을(를) 여행한 적이 있습니까?",
            like: "{{location}} 에서 어떤 점이 마음에 드셨나요?",
            appealingWhy: "{{location}} 의 매력은 무엇인가요?",
            travelWhen: "언제 {{location}} 로 여행하고 싶으세요?",
            withOthers: "함께 여행하고 싶은 사람이 있습니까?",
            travelWithWho: "함께 여행하고 싶은 사람을 선택하세요",
            toDoAtDestination: "방문하는 동안 무엇을 하고 싶습니까?",
            toSave: "이렇게 하려면 돈을 아껴야 합니까?",
            startedToSave: "당신은 저축을 시작 했습니까?",
            amountToSave: "아직 저축해야 하는 돈은 얼마입니까?",
            accommodations: "여행 갈 때 필요한 숙소가 있나요?",
            needsAndWhy: "여행하고 싶거나 가지고 싶은 것이 있습니까? 있다면 무엇입니까?",
            goal: "이 목표의 이름을 무엇으로 지정하시겠습니까?",
            saveMessage: "모험이 기다리고 있습니다! 다른 여행 목표를 추가하시겠습니까?",
            summary: {
              destination: "나는 ~로 여행을 가고 싶다",
              appealingWhy: "내가 이곳을 여행하고 싶은 이유",
              travelWhen: "여기서 여행하고 싶다",
              withOthers: "나는 사람들과 여행을 하고 싶은가?",
              withOthersYes: "예, {{people}} 님과 여행하고 싶어요",
              withOthersAlone: "혼자 여행하고 싶다",
              toDoAtDestination: "방문하는 동안 하고 싶다",
              accommodations: "여행할 때 필요한 숙박 시설은 다음과 같습니다.",
              needsAndWhy: "여행 갈 때 가지고 가고 싶은 다른 것들 포함",
              needToSave: "이번 여행을 위해 저축을 해야 하나요?",
              needToSaveYes: "예, {{amount}} 을(를) 저축해야 합니다.",
            },
          },
          planningWork: {
            sectionName: "기획 작업",
            sectionHeader: "작업 목표",
            addSubHeader: "새로운 미래를 계획하자",
            whatILiked: "내가 좋아했던 것",
            whatIDisliked: "내가 싫어하는 것",
            formSubHeader: "당신의 직장 경험에 대해 당신이 좋아하거나 싫어하는 것을 보자",
            workGoal: "일의 목표는 무엇입니까?",
            profession: "이 직업에 끌리는 이유는 무엇입니까?",
            excites: "이 직업에서 가장 흥분되는 것은 무엇입니까?",
            goalLookLike: "당신의 목표에 대해 생각할 때, 그것은 어떤 모습입니까?",
            salary: "1년에 얼마를 벌고 싶습니까?",
            preferredWorkDays: "당신이 선호하는 근무일은 무엇입니까?",
            preferredWorkHours: "당신이 선호하는 근무 시간은 무엇입니까?",
            haveNow: "당신의 미래를 이끌어 줄 지금 당신은 무엇을 가지고 있습니까?",
            priorExperience: "어떤 경험이 미래에 도달하는 데 도움이 될까요?",
            haveReference: "이 직업에 아는 사람이 있습니까?",
            referenceName: "그들은 누구인가?",
            referenceWhere: "그들은 어디서 일하지?",
            inLifeSupport: "당신의 인생에서 누가 이 목표에서 당신을 지원할 수 있습니까?",
            support: "당신의 목표를 달성하는 데 도움을 줄 수 있는 다른 사람이 있습니까?",
            commit: "이 미래를 만들기 위해 무엇을 할 수 있습니까?",
            placeholderBudgetNeed: "예산에 필요한 항목 삽입",
            placeholderResponsible: "이름 입력",
            placeholderHowOften: "얼마나 자주 할 것인지 삽입",
            placeholderImportant: "이것이 중요한 이유를 입력하십시오",
            workPreferences: '내 작업 선호도',
            monday: "월요일",
            tuesday: "화요일",
            wednesday: "수요일",
            thursday: "목요일",
            friday: "금요일",
            saturday: "토요일",
            sunday: "일요일",
            morning: "아침",
            afternoon: "오후",
            evening: "저녁",
            overnight: "밤새",
            anytime: "언제든지",
            saveMessage: "훌륭한 작업 목표 같군요! 다른 작업 목표를 추가하시겠습니까?",
            budgetTable: {
              title: "목표 달성을 위해 돈을 저축하는 데 무엇이 필요합니까?",
              actionStep: "액션 단계",
              saveFor: "비용을 절약하십시오",
              saveMoneyFor: "무엇을 위해 돈을 저축해야 합니까?",
              responsible: "누가 책임져?",
              howOften: "얼마나 자주?",
              important: "이것이 왜 중요한가?",
              why: "왜?",
              contact: "연락하다",
              me: "나",
              theyCanSupport: "그들은 내 목표에서 나를 지원할 수 있습니다",
              thisWillHelpMe: "이것은 내가 내 목표를 달성하는 데 도움이 될 것입니다",
              deleteConfirm: "이 예산 행을 제거하시겠습니까?",
              deleteSuccess: "알겠습니다. 행이 제거되었습니다.",
            },
            summary: {
              workGoal: "내 일의 목표",
              profession: "이 직업에 나를 매료시키는 것",
              excites: "이 일에서 가장 나를 흥분시키는 것은",
              goalLookLike: "내 목표를 생각할 때",
              haveNow: "내 미래를 달성하는 데 도움이 될 것",
              preferredWorkDays: "원하는 근무일",
              preferredWorkHours: "희망 근무 시간",
              salary: "희망 급여",
              haveReference: "이 직업에 아는 사람이 있습니까?",
              referenceName: "그들은 누구인가?",
              referenceWhere: "그들은 어디서 일하지?",
              inLifeSupport: "당신의 인생에서 누가 이 목표에서 당신을 지원할 수 있습니까?",
              support: "당신의 목표를 달성하는 데 도움을 줄 수 있는 다른 사람이 있습니까?",
              commit: "이 미래를 만들기 위해 무엇을 할 수 있습니까?",
            },
          },
          GTKYPage: {
            pageTitle: "당신을 알아가는",
            GTKYVideo: "당신을 알아가는 비디오",
            likeAdmire: "좋아요 & 감탄",
            peopleInMyLife: "내 인생의 사람들",
            thingsIDo: "재미로 하고 싶은 일",
            favoriteThings: "내가 가장 좋아하는 것",
            myRoutines: "내 루틴",
            historyMap: "역사 지도",
            travel: "여행하다",
            viewComments: "댓글 보기"
          },
          myRoutines: {
            sectionName: "내 루틴",
            subHeader: "일과가 어떻게 생겼는지 모두 알려주세요",
            videoTitle: "내 루틴 - 유용한 비디오",
            time: "시간",
            noTime: "시간 없다",
            day: "낮",
            noDay: "하루 없음",
            dailyError: "시간과 설명을 입력하세요",
            weeklyError: "날짜 및 설명을 입력하세요.",
            successMessage: "고마워! 루틴이 추가되었습니다.",
            dailyRoutines: "당신의 일상은 무엇입니까?",
            weeklyRoutines: "주간 일과 또는 의식은 무엇입니까?",
            dailyPlaceholder: "매일 하는 일과 하는 일을 추가하세요",
            weeklyPlaceholder: "매주 하는 일 추가",
            dayStickyTitle: "내 하루",
            weekStickyTitle: "나의 일주일",
            deleteMessage: "이 루틴을 삭제하시겠습니까?",
            deleteSuccess: "알겠습니다. 루틴이 삭제되었습니다.",
            contributor: {
              subHeader: "일과가 어떻게 생겼는지 모두 알려주세요",
              dailyRoutines: "그들의 일상은?",
              weeklyRoutines: "그들의 주간 일과나 의식은 무엇입니까?",
              dailyPlaceholder: "그들이 매일 하는 일과 하는 일을 추가하십시오",
              weeklyPlaceholder: "매주 하는 일 추가",
              dayStickyTitle: "{{name}}의 날",
              weekStickyTitle: "{{name}}의 주",
            },
            days: {
              mon: "월요일",
              tues: "화요일",
              wed: "수요일",
              thurs: "목요일",
              fri: "금요일",
              sat: "토요일",
              sun: "일요일"
            }
          },
          thingsIDo: {
            sectionName: "재미로 하고 싶은 일",
            subHeader: "활동을 선택하거나 새로 만드십시오",
            indoors: "실내",
            outdoors: "옥외",
            frequencyCard: "나는 이것을 하는 것을 좋아한다:",
            likeMostCard: "이 활동에서 가장 마음에 드는 점:",
            tellUsWhatYouDo: "재미로 하고 싶은 일을 모두 알려주세요!",
            indoor: "실내",
            outdoor: "집 밖의",
            community: "내 커뮤니티에서",
            whereToHaveFun: "어디서 놀고 싶니?",
            yourActivity: "무엇을 하는 것을 좋아합니까?",
            chosenActivity: "당신이 선택한 활동은",
            howOften: "얼마나 자주 하고 싶니?",
            aloneOrWithOthers: "이 활동을 혼자 하시겠습니까, 아니면 다른 사람과 하시겠습니까?",
            attachedPeople: "누구랑 하는 걸 좋아해요?",
            addThingIDo: "새로운 활동",
            otherPeopleInvolved: "이 활동을 함께 하고 싶은 다른 사람이 있습니까?",
            anyPhotos: "공유하고 싶은 {{activity}}의 재미있는 사진이 있습니까?",
            like: "{{activity}}의 어떤 점이 가장 마음에 드시나요?",
            youSelected: "당신의 활동:",
            saveMessage: "모험이라니! 다른 모험을 추가하시겠습니까?",
            deleteConfirm: "이 활동을 삭제하시겠습니까?",
            deleteSuccess: "활동이 성공적으로 삭제되었습니다",
            daily: "일일",
            weekly: "주간",
            monthly: "월간 간행물",
            oncePerYear: "일년에 한번",
            fewTimes: "일 년에 몇 번",
            whenever: "할 수 있을 때마다",
            contributorQuestions: {
              frequencyCard: "그들은 이것을 하는 것을 좋아합니다:",
              likeMostCard: "이 활동에서 가장 좋아하는 것:",
              tellUsWhatYouDo: "재미로 무엇을 좋아하는지 모두 알려주세요!",
              community: "내 커뮤니티에서",
              whereToHaveFun: "그들은 어디에서 즐기기를 좋아합니까?",
              yourActivity: "그들은 무엇을 하기를 좋아합니까?",
              chosenActivity: "그들이 선택한 활동은",
              howOften: "얼마나 자주 그들이 이것을 하는 것을 좋아합니까?",
              aloneOrWithOthers: "이 활동을 혼자 하는 것을 좋아합니까, 아니면 다른 사람과 하는 것을 좋아합니까?",
              attachedPeople: "누구와 함께 하는 것을 좋아합니까?",
              otherPeopleInvolved: "이 활동을 함께 하고 싶은 다른 사람이 있습니까?",
              anyPhotos: "공유하고 싶은 {{activity}}의 재미있는 사진이 있나요?",
              like: "{{activity}}에서 가장 좋아하는 것은 무엇인가요?",
              youSelected: "그들의 활동:",
              saveMessage: "",
            },
            activities: {
              reading: "독서",
              writing: "글쓰기",
              yoga: "요가",
              pilates: "필라테스",
              videoGames: "비디오 게임",
              cardGames: "카드 게임",
              boardGames: "보드 게임",
              painting: "페인트 등",
              instruments: "악기",
              drawing: "그림",
              art: "미술",
              replicaModels: "복제 모델",
              movies: "영화 산업",
              tv: "텔레비전",
              movieTheater: "영화관",
              diningOut: "외식",
              yardSales: "마당 판매",
              antiquing: "골동품",
              mall: "쇼핑몰에 가다",
              shopping: "쇼핑",
              sports: "스포츠",
              walks: "산책",
              birdWatching: "들새 관찰",
              hiking: "등산",
              fishing: "어업",
              gardening: "원예",
              camping: "캠핑",
              boating: "주유",
              botany: "식물학"
            },
            summary: {
              activity: "내가 좋아하는 것",
              howOften: "나는 이 활동을 좋아한다",
              withWho: "나는 이것을 하는 것을 좋아한다",
              withWhoAlone: "나는 이것을한다",
              byMyself: "스스로",
              like: "내가 가장 좋아하는 것은",
              buttonText: "재미로 내가 좋아하는 일에 가십시오",
            },
          },
          likeAndAdmire: {
            sectionName: "좋아요 및 감탄",
            sectionTitle: "머리, 심장, 손",
            subTitle: "선물을 원하는 카테고리로 드래그 앤 드롭하거나 직접 만드세요",
            videoTitle: "좋아요 및 감탄 - 유용한 비디오",
            filterAll: "좋아하고 존경하는 모든 것(기본값)",
            filterOthers: "다른 사람들이 나를 좋아하고 존경하는 것",
            filterMine: "내가 나 자신에 대해 좋아하고 존경하는 것",
            resetAll: "모두 초기화",
            addNew: "새 단어 추가",
            saveMessage: "수고하셨습니다. {{firstName}}님, 당신에 대한 모든 좋은 소식을 들을 수 있어서 기뻤습니다!",
            head: "머리",
            heart: "마음",
            hand: "손",
            headExplanation: "마음, 두뇌 또는 생각의 선물, 당신이 알고 있는 그 선물",
            heartExplanation: "당신이 연결되거나 열정을 느끼는 선물",
            handExplanation: "행동하거나 만들어 물리적으로 제공할 수 있는 선물",
            resetMessage: "모두 재설정하시겠습니까?",
            resetSuccess: "알겠습니다. 항목이 재설정되었습니다.",
            deleteMessage: "이 선물을 삭제하시겠습니까?",
            deleteSuccess: "선물이 성공적으로 삭제되었습니다.",
            contributor: {
              subTitle: "선물을 원하는 카테고리로 드래그 앤 드롭하거나 직접 만드세요",
              filterOthers: "다른 사람들이 좋아하고 존경하는 것",
              filterMine: "자신에 대해 좋아하고 존경하는 것",
            },
            summary: {
              head: "머리",
              heart: "마음",
              hand: "손",
            },
          },
          peopleInMyLife: {
            sectionName: "내 인생의 사람들",
            subHeader: "사람을 추가하거나 사람의 이름을 클릭하여 자세한 내용을 추가하세요",
            adaptionDisclaimer: "관계 지도의 적응",
            acquaintance: "아는 사람",
            acquaintances: "지인",
            add: "사람 추가",
            addAsContributor: "내 계획에 기여자로 추가",
            close: "닫다",
            contributor: "기부자",
            contributorAdditions: "기고자 추가",
            coWorker: "동료",
            coWorkers: "동료",
            deletePerson: "인생에서 이 사람을 제거하시겠습니까?",
            edit: "사람 편집",
            family: "가족",
            favoritePerson: "이달의 좋아하는 인물",
            friend: "친구",
            friends: "친구들",
            howClose: "당신은 이 사람과 얼마나 가깝습니까?",
            importantWhy: "이 사람이 당신에게 중요한 이유",
            involvedInSupport: "내 지원에 참여",
            mapPlace: "이것은 People In My Life 지도에서 그들의 위치를 결정할 것입니다",
            notClose: "가깝지 않다",
            paidSupport: "이 사람은 유급 지원입니까?",
            paidSupportShort: "유료 지원",
            personIs: "이 사람은...",
            relationshipToYou: "당신과의 관계",
            removePerson: "사람 제거",
            weAre: "우리는...",
            saveMessage: "당신 인생의 모든 사람들이 구원받았습니다, 위대한 일입니다.",
            me: '나',
            contributorQuestions: {
              howClose: "이 사람과 얼마나 가깝습니까?",
              importantWhy: "이 사람이 그들에게 중요한 이유",
              mapPlace: "이것은 People In My Life 지도에서 그들의 위치를 결정할 것입니다",
              relationshipToYou: "그들과의 관계",
              theyAre: "그들은...",
              involvedInSupport: "그들의 지원에 참여",
            },
            dialog: {
              nameError: "이름을 입력하세요",
              personIsError: "옵션을 선택하세요",
              emailError: "잠재적 기여자의 이메일 주소를 입력해야 합니다",
              emailValidError: "유효한 이메일 주소를 입력하세요",
              sendInvitation: "초대장을 보내다",
            },
          },
          favoriteThings: {
            sectionName: "내가 가장 좋아하는 것",
            subHeader: "먼저 카테고리를 선택하여 좋아하는 것을 추가하십시오",
            contributorSubHeader: "좋아하는 것을 추가하려면 카테고리를 선택하세요.",
            viewOnlySubHeader: "그들이 좋아하는 것을 살펴보십시오",
            addDialogTitle: "좋아하는 것 추가",
            editDialogTitle: "좋아하는 것 편집",
            categories: "카테고리",
            categoryList: "카테고리 목록",
            myFavorites: "내가 좋아하는 것들",
            theirFavorites: "그들의 즐겨찾기",
            place: "장소",
            placeToEat: "먹을 곳",
            food: "음식",
            movie: "영화",
            book: "책",
            videoGame: "비디오 게임",
            game: "게임",
            sport: "스포츠",
            animal: "동물",
            music: "음악",
            memory: "메모리",
            other: "다른",
            placeLabel: "가장 좋아하는 곳",
            placeToEatLabel: "당신이 가장 좋아하는 식사 장소",
            foodLabel: "좋아하는 음식",
            movieLabel: "당신이 가장 좋아하는 영화",
            bookLabel: "좋아하는 책",
            videoGameLabel: "당신이 가장 좋아하는 비디오 게임",
            gameLabel: "좋아하는 게임",
            sportLabel: "좋아하는 스포츠 또는 스포츠 팀",
            animalLabel: "좋아하는 애완동물이나 동물 종류",
            musicLabel: "좋아하는 밴드, 노래 또는 장르",
            memoryLabel: "당신이 가장 좋아하는 추억 중 하나",
            otherLabel: "좋아하는 것",
            chooseAView: "보기 선택",
            allThings: "좋아하는 모든 것(기본값)",
            thingsIAdded: "내가 추가한 즐겨찾기 항목",
            thingsOthersAdded: "다른 사람들이 추가한 것",
            treasureChestAlt: "좋아하는 것 보물 상자",
            deleteConfirm: "이 즐겨찾기를 삭제하시겠습니까?",
            deleteSuccess: "좋아하는 것이 삭제되었습니다",
            saveAlertText: "{{name}}님, 감사합니다. 좋아하는 것에 대해 알게 되어 좋습니다!",
            plurals: {
              place: "장소",
              placeToEat: "먹을 곳",
              movie: "영화 산업",
              book: "서적",
              videoGame: "비디오 게임",
              game: "계략",
              sport: "스포츠",
              animal: "동물",
              memory: "추억",
            }
          },
          travelPlan: {
            sectionName: "여행하다",
            sectionHeader: "여행하다",
            subHeader: "가장 좋아하는 여행지나 가고 싶은 곳을 공유하세요",
            travelLocally: "미국 내 여행을 좋아하시나요, 아니면 해외 여행을 좋아하시나요?",
            local: "미국 내에서",
            international: "국제적으로",
            frequencyCard: "나는 이것을 하는 것을 좋아한다:",
            likeMostCard: "내가이 장소에서 가장 좋아하는 것 :",
            pageSubHeader: "여행하고 싶은 곳을 모두 알려주세요",
            travelWhere: "어디 여행을 좋아하세요?",
            likeMost: "{{location}} 여행에서 가장 마음에 드는 점은 무엇인가요?",
            likeToTravel: "당신은 여행을 어떻게 좋아합니까?",
            alone: "이 일을 혼자 하는 것을 좋아합니까, 아니면 다른 사람과 하는 것을 좋아합니까?",
            howOften: "여기를 얼마나 자주 여행하는 것을 좋아합니까?",
            anyoneElse: "같이 여행하고 싶은 사람이 또 있나요?",
            anyPictures: "공유하고 싶은 {{location}}의 재미있는 사진이 있습니까?",
            deleteMessage: "이 위치를 삭제하시겠습니까?",
            addTravelPlan: "새로운 여행지",
            addTravelContributor: "여행하고 싶은 다른 장소 추가",
            attachedPeople: "누구랑 여행하고 싶니?",
            boat: "보트",
            bus: "버스",
            car: "자동차",
            plane: "비행기",
            train: "기차",
            saveText: "모험이라니! 다른 모험을 추가하시겠습니까?",
            deleteConfirm: "이 활동을 삭제하시겠습니까?",
            deleteSuccess: "활동이 성공적으로 삭제되었습니다",
            contributorQuestions: {
              subHeader: "여행하고 싶은 곳이나 가고 싶은 곳을 공유하세요",
              frequencyCard: "그들은 이것을 하는 것을 좋아합니다:",
              likeMostCard: "그들이이 장소에서 가장 좋아하는 것 :",
              pageSubHeader: "여행을 좋아하는 곳을 모두 알려주세요",
              travelLocally: "미국 내 여행을 좋아합니까 아니면 국제 여행을 좋아합니까?",
              travelWhere: "그들은 어디 여행을 좋아합니까?",
              likeMost: "{{location}} 여행에서 그들이 가장 좋아하는 것은 무엇인가요?",
              likeToTravel: "그들은 어떻게 여행을 좋아합니까?",
              alone: "혼자서 하는 걸 좋아하나요, 아니면 다른 사람들과 함께 하는 걸 좋아하나요?",
              howOften: "얼마나 자주 이곳을 여행하는 것을 좋아합니까?",
              anyoneElse: "함께 여행하고 싶은 다른 사람이 있습니까?",
              anyPictures: "공유하고 싶은 {{location}}의 재미있는 사진이 있습니까?",
              attachedPeople: "그들은 누구와 여행하는 것을 좋아합니까?",
            },
            summary: {
              place: "나는 여행을 좋아한다",
              travelWithSolo: "나는 여기 여행을 좋아한다",
              byMyself: "스스로",
              travelWith: "여기서 여행하는 걸 좋아해요",
              frequency: "나는 거기에 간다",
              frequencyAnswer: "{frequency}} 및 저는 {{transportation}}으로 여행합니다",
              favoriteThing: "{{place}} 여행에서 가장 좋아하는 점은",
              buttonText: "여행을 가다",
            },
          },
          historyMap: {
            sectionHeader: "나의 역사 지도",
            subHeader: "당신의 인생에서 중요한 이정표, 기념일 또는 사건에 대해 알려주십시오",
            addMilestone: "마일스톤 추가",
            editMilestone: "마일스톤 편집",
            sectionName: "역사 지도",
            category: "범주",
            familyLife: "가족 생활",
            school: "학교",
            activities: "활동",
            friends: "친구들",
            housing: "주택",
            work: "일하다",
            relationships: "관계",
            chooseDate: "날짜 선택",
            milestone: "중요한 단계",
            details: "세부",
            back: "당신을 알기로 돌아가기",
            saveMessage: "당신에 대한 모든 것을 배울 수 있어서 좋았습니다. 공유해주셔서 감사합니다!",
            validDateError: "유효한 날짜를 입력하세요",
            rangeDateError: "날짜가 범위를 벗어났습니다.",
            deleteMessage: "이 마일스톤을 삭제하시겠습니까?",
            deleteSuccess: "이정표가 성공적으로 삭제되었습니다.",
          },
          contributor: {
            addToRelationshipMap: "내 관계 지도에 추가",
            thisPersonIs: "이 사람은....",
            howClose: "당신은 이 사람과 얼마나 가깝습니까?",
            close: "닫다",
            notClose: "가깝지 않다",
            setPermissions: "기여자 권한 설정",
            hasBeenEmailed: "기고자에게 초대장이 전송되었습니다",
          },
          happeningPage: {
            pageTitle: "내 인생에서 일어나는 일",
            living: "내 집에서 살기",
            work: "일하다",
            school: "학교",
            health: "건강 및 안전",
            happeningVideo: "해프닝 인 마이 라이프 비디오",
          },
          happeningHome: {
            pageTitle: "내 집에서 살기",
            subTitle: "당신이 사는 곳에 대해 더 알아보자!",
            singleStory: "싱글 스토리 홈",
            multiLevel: "다단계 주택",
            apartment: "아파트",
            facility: "시설",
            homeless: "노숙자",
            groupHome: "그룹 홈",
            car: "자동차",
            rvCamper: "RV/캠퍼",
            currentlyLive: "당신은 현재 어디에 살고 있습니까?",
            currentlyLiveWith: "현재 누구와 살고 있습니까?",
            parents: "부모(님)",
            guardians: "보호자",
            siblings: "형(제)",
            friends: "친구(들)",
            roommates: "룸메이트",
            spouse: "배우자",
            children: "어린이/어린이",
            alone: "난 혼자 사는",
            dogs: "개",
            cats: "고양이",
            other: "기타 설명 부탁드립니다",
            explain: "설명 해주십시오",
            tableTitle: "당신이 사는 곳에서 좋아하는 것과 싫어하는 것은 무엇입니까?",
            amenityHeader: "예의",
            selectHeader: "좋아요, 싫어요를 선택해주세요",
            commentHeader: "코멘트",
            commentsLikes: "좋아요에 대한 댓글",
            commentsDislikes: "내가 싫어하는 것에 대한 댓글",
            tellUsWhy: "여기에 이유를 알려주세요.",
            whatILike: '내가 현재 살고있는 곳에서 내가 좋아하는 것',
            whatIDislike: '내가 현재 살고있는 곳에서 내가 싫어하는 것',
            amenities: {
              publicTransportation: "대중교통",
              accessibility: "접근성",
              neighborhood: "이웃",
              friendsFamily: "친구와 가족과 가깝다",
              shopping: "쇼핑과 가까움",
              furniture: "가구",
              homeType: "집의 유형",
              squareFootage: "스퀘어 푸티지",
              housemates: "주부",
              livingAlone: "혼자 살기",
              neighbors: "이웃",
              management: "재산 관리",
              support: "지원 직원",
              tellUsWhy: "이유를 알려주세요",
            },
            summary: {
              currentlyLive: "어디에서 살고",
              currentlyLiveWith: "나는 누구와 함께 살고",
            },
          },
          happeningSchool: {
            header: "학교",
            subHeader: "학교에 대해 더 듣고 싶고, 경험에 대해 이야기해 주세요.",
            enrolledInSchool: "지금 학교에 다니세요?",
            schoolNameOOS: "어느 학교 다니셨어요?",
            gradeLevelOOS: "몇 학년을 마쳤습니까?",
            gradYearOOS: "졸업은 언제 하셨어요?",
            schoolLikeOOS: "학교에서 무엇을 가장 좋아했습니까?",
            schoolDislikeOOS: "학교에 대해 무엇을 좋아하지 않았습니까?",
            volunteerOOS: "학창시절 자원봉사는 어디에서 하셨나요?",
            volunteerWhereOOS: "어디서 자원봉사를 하셨나요?",
            volunteerDislikeOOS: "무엇이 마음에 들지 않았습니까?",
            volunteerLikeOOS: "자원봉사의 어떤 점이 좋았나요?",
            graduationGoalOOS: "뭐였지?",
            gradYearOtherOOS: "",
            goalYesOOS: "졸업하면 하고 싶은 일이 있나요?",
            schoolName: "무슨 학교 당신이 가야합니까?",
            gradeLevel: "너는 몇 학년 이니?",
            gradYear: "학교는 언제 끝나?",
            schoolLike: "학교에서 가장 좋아하는 것은 무엇입니까?",
            schoolDislike: "학교에 대해 무엇을 좋아하지 않습니까?",
            volunteer: "어디서나 자원봉사를 하시나요?",
            volunteerWhere: "어디서 자원봉사를 하시나요?",
            volunteerDislike: "무엇을 싫어합니까?",
            volunteerLike: "자원봉사의 어떤 점이 좋으세요?",
            graduationGoal: "뭔데?",
            gradYearOther: "",
            goalYes: "졸업하면 하고 싶은 일이 있나요?",
            summary: {
              schoolName: "내가 다니는 학교 이름은",
              schoolNameOOS: "내가 다니던 학교 이름이",
              gradeLevel: "나는 학년이다",
              gradeLevelOOS: "나는 학년을 마쳤다",
              gradYear: "나는 학교에서 끝낼 것이다",
              gradYearOOS: "나는 학교에서 끝났어",
              schoolLike: "내가 학교에서 좋아하는 것",
              schoolLikeOOS: "내가 학교에 대해 좋아했던 것",
              schoolDislike: "내가 학교에서 싫어하는 것",
              schoolDislikeOOS: "내가 학교에 대해 싫어하는 것",
              volunteerWhere: "나는 학교에서 자원 봉사",
              volunteerWhereOOS: "나는 학교에서 자원 봉사했다",
              volunteerLike: "내가 자원봉사에 대해 좋아하는 것",
              volunteerLikeOOS: "자원봉사를 하면서 좋았던 점",
              volunteerDislike: "자원봉사에서 내가 싫어하는 것",
              volunteerDislikeOOS: "자원봉사에 대해 내가 싫어하는 것",
              graduationGoal: "졸업하면 하고 싶다",
              graduationGoalOOS: "졸업할 때 하고 싶었다",
            },
          },
          happeningWork: {
            sectionName: "직장 경험",
            subHeader: "직장 경험에 대해 자세히 알려주세요. 빨리 듣고 싶습니다!",
            pastExperience: "과거 업무 경험을 공유해주세요",
            pastExperienceLike: "과거 업무 경험에서 어떤 점이 마음에 드셨나요?",
            pastExperienceDislike: "과거 직장 경험에서 어떤 점이 마음에 들지 않았습니까?",
            hasCurrentEmployment: "당신은 현재 일하고 있습니까?",
            currentEmployment: "현재 어디에서 일하고 있습니까?",
            currentDuration: "거기서 일한 지 얼마나 되셨어요?",
            currentLike: "현재 작업에서 어떤 점이 마음에 드시나요?",
            currentDislike: "지금 하고 있는 일 중 마음에 들지 않는 점은 무엇입니까?",
            summary: {
              pastExperience: "내가 과거에 일했던 곳",
              pastExperienceLike: "내가 과거에 일했던 곳에서 내가 좋아하는 것",
              pastExperienceDislike: "과거에 일했던 곳에서 내가 싫어하는 것",
              currentEmployment: "현재 일하고 있습니다",
              currentDuration: "나는 여기서 일했다",
              currentLike: "내가 일하는 곳에서 내가 좋아하는 것",
              currentDislike: "내가 일하는 곳에서 내가 싫어하는 것",
            },
          },
          happeningHealth: {
            header: "건강 및 안전",
            subHeader: "조금 더 친해지면 편하신 점 공유해주세요",
            contributorAdditionsToApprove: "기고자가 DME 및/또는 지원을 계획에 추가했습니다. 승인하시겠습니까?",
            medInfo: "당신의 삶에 영향을 미치는 건강과 관련된 것이 있습니까?",
            allergies: "알고 싶은 알레르기를 적어주세요",
            dmeSectionTitle: "어떤 유형의 내구성 의료 장비 및 지지대를 사용합니까?",
            dmeContributorTitle: "어떤 유형의 내구성 의료 장비 및 지지대를 사용합니까?",
            sectionSubTitle: "목록에 추가하려면 버튼을 선택하고, 제거하려면 목록에서 'x'를 클릭하세요.",
            supportsSectionTitle: "다른 사람들이 건강을 느끼고 유지하는 데 어떻게 도움을 줄 수 있습니까?",
            supportContributorTitle: "다른 사람들이 건강을 느끼고 유지하는 데 어떻게 도움을 줄 수 있습니까?",
            dmePostItTitle: "좋은 지원의 모습",
            supportPostItTitle: "다른 사람들이 나를 지원하는 방법",
            dmeOptions: {
              bloodSugarMonitors: "혈당 모니터",
              bloodSugarTestStrips: "혈당 테스트 스트립",
              canes: "지팡이",
              commodeChairs: "화장실 의자",
              continuousPassiveDevices: "연속 수동 운동 장치",
              continuousPassiveAirwayPressureDevices: "지속적 기도양압(CPAP) 장치",
              crutches: "협장",
              hospitalBeds: "병원 침대",
              infusionPumps: "주입 펌프 및 용품",
              lancetDevices: "란셋 장치 및 란셋",
              nebulizerMedications: "분무기 및 분무기 약물",
              oxygenEquipment: "산소 장비 및 액세서리",
              patientLifts: "환자 리프트",
              supportSurfaces: "감압 지지면",
              suctionPumps: "흡입 펌프",
              tractionEquipment: "트랙션 장비",
              walkers: "워커",
              rollator: "롤러",
              wheelchairMotorized: "휠체어 - 전동식",
              wheelchairManual: "휠체어 - 매뉴얼",
              wheelchairStanding: "휠체어 - 기대기/서기",
              scooterMotorized: "스쿠터 - 전동",
              scooterManual: "스쿠터 - 수동",
              assistiveTech: "보조 기술",
              translator: "역자",
              brailleKeyboard: "점자 키보드",
              hearingAid: "보청기",
            },
            supportOptions: {
              cookMeals: "내 식사를 요리하십시오",
              prepareMeals: "식사 준비 도와주세요",
              prepareMedication: "약 준비를 도와주세요",
              medicationSchedule: "약 복용 일정을 짜도록 도와주세요",
              doctorTransportation: "의사에게 갈 수 있도록 도와주세요",
              createBudget: "예산 작성을 도와주세요",
              beActive: "활동하도록 동기를 부여하십시오",
            },
            summary: {
              medInfo: "다른 사람들이 알았으면 하는 건강 상태는 다음과 같습니다.",
              allergies: "다른 사람들이 알았으면 하는 알레르기에는 다음이 포함됩니다.",
              dmeSectionTitle: "내가 사용하는 내구성 의료 지원은 다음과 같습니다.",
              supportsSectionTitle: "내 인생의 사람들은 다음과 같은 방식으로 나를 지원해 줄 수 있습니다.",
            },
          },
          happeningGeneral: {
            sectionSave: "{{userFirstName}} 정보를 제공해 주셔서 감사합니다. 의 다른 섹션으로 이동할 수 있습니다.",
          },
          planSummary: {
            generatePdf: "PDF 다운로드",
            downloadPdf: "PDF 생성",
            selectTravelPlan: "여행 계획을 선택하세요",
            selectActivity: "활동 선택",
            selectGoal: "목표 선택",
            sections: {
              importantToMe: "나에게 중요한 것은",
              importantForMe: "나에게 중요한 것은",
              goals: "목표 계획",
            },
            cardTitles: {
              likeAdmire: "사람들이 나를 좋아하고 존경하는 것",
              people: "내 인생의 소중한 사람들",
              milestones: "내 인생의 중요한 이정표",
              favoriteThings: "내가 좋아하는 몇 가지",
              thingsIDo: "재미로 하고 싶은 것",
              travelPlan: "여행하고 싶은 곳",
              dailyRoutines: "나의 일상",
              weeklyRoutines: "나의 주간 루틴",
              happeningHome: "내 집에서 살기",
              happeningWork: "나의 업무 경험",
              happeningSchool: "나의 학교 경험",
              happeningHealth: "나의 건강 및 안전",
              homeGoals: "나의 주거 목표",
              workGoals: "내 작업 목표",
              healthGoals: "나의 건강 목표",
              funGoals: "나의 재미있는 목표",
              relationshipGoals: "나의 관계 목표",
              travelGoals: "나의 여행 목표",
            },
            cardDescriptions: {
              likeAdmire: "다음은 내가 나 자신에 대해 좋아하고 존경하는 점과 기여자가 나에 대해 감사하는 점입니다.",
              people: "여기 내 인생에서 가장 중요한 사람들이 있습니다.",
              milestones: "내 인생에서 내가 가장 자랑스러워하는 모든 중요한 이정표를 발견하십시오!",
              favoriteThings: "이것들은 내가 좋아하는 것들 중 일부일뿐입니다.",
              thingsIDo: "여기 내가 재미로 하고 싶은 몇 가지가 있습니다!",
              travelPlan: "내가 여행하고 싶은 곳, 누구와, 무엇과 함께 여행하고 싶은지 알아보기",
              dailyRoutines: "나에게 매일 중요한 것과 좋은 하루를 만드는 것을 발견하십시오!",
              weeklyRoutines: "내 취향, 의식 및 루틴에 대해 자세히 알아보십시오!",
              happeningHome: "나의 현재 생활 상황, 내가 좋아하는 것과 싫어하는 것을 공유하는 것이 중요합니다.",
              happeningWork: "직장에 대한 나의 과거 경험, 내가 좋아하는 것과 싫어하는 것에 대해 자세히 알아보십시오!",
              happeningSchool: "나와 학교에서의 경험에 대해 조금 더 알아보세요!",
              happeningHealth: "내 건강, 안전 및 다른 사람들이 나를 지원하기 위해 할 수 있는 일에 대해 편안하게 공유할 수 있는 내용은 다음과 같습니다.",
              homeGoals: "내가 살고 싶은 곳에 대해 더 알아보기",
              workGoals: "내 꿈의 직업에 대해 알아보기",
              healthGoals: "내 건강을 위해 어떻게 계획하고 싶은지 알아보기",
              funGoals: "앞으로 내가 하고 싶은 재미를 찾아라",
              relationshipGoals: "내가 다른 사람들과 어떻게 소통하고 싶은지 자세히 알아보기",
              travelGoals: "내가 여행하고 싶은 곳 알아보기",
            },
            noContent: {
              milestones: "추가된 마일스톤이 없습니다. 시작하려면 아래 버튼을 클릭하세요!",
              favoriteThings: "",
              thingsIDo: "내가 재미로 하는 일들이 생성되지 않았습니다. 아래 버튼을 클릭하여 하나를 만드세요!",
              travelPlan: "여행할 장소가 생성되지 않았습니다. 아래 버튼을 클릭하여 하나를 만드세요!",
              dailyRoutines: "추가된 일상이 없습니다. 시작하려면 아래 버튼을 클릭하세요!",
              weeklyRoutines: "주간 루틴이 추가되지 않았습니다. 시작하려면 아래 버튼을 클릭하세요!",
              happeningHome: "등록된 마이홈 정보가 없습니다. 시작하려면 아래 버튼을 클릭하세요!",
              happeningWork: "등록된 경력정보가 없습니다. 시작하려면 아래 버튼을 클릭하세요!",
              happeningSchool: "등록된 학교정보가 없습니다. 시작하려면 아래 버튼을 클릭하세요!",
              happeningHealth: "등록된 건강 및 안전 정보가 없습니다. 시작하려면 아래 버튼을 클릭하세요!",
              homeGoals: "주택 목표가 생성되지 않았습니다. 아래 버튼을 클릭하여 하나를 만드세요!",
              workGoals: "작업 목표가 생성되지 않았습니다. 아래 버튼을 클릭하여 하나를 만드세요!",
              healthGoals: "건강 목표가 생성되지 않았습니다. 아래 버튼을 클릭하여 하나를 만드세요!",
              funGoals: "재미있는 목표가 생성되지 않았습니다. 아래 버튼을 클릭하여 하나를 만드세요!",
              relationshipGoals: "관계 목표가 생성되지 않았습니다. 아래 버튼을 클릭하여 하나를 만드세요!",
              travelGoals: "여행 목표가 생성되지 않았습니다. 아래 버튼을 클릭하여 하나를 만드세요!",
            },
          },
          contributorGeneric: {
            entries: "기고자 항목",
            noAccess: "이 섹션에 대한 액세스 권한이 없습니다.",
            includedByPlanOwner: '플랜 소유자가 포함시킵니다',
            notIncluded: "요금제 소유자가 포함하지 않음",
            sectionNotComplete: "계획 소유자가 이 섹션을 완료하지 않았습니다.",
            thankYou: "기고해 주셔서 감사합니다! 추가 사항이 승인을 위해 계획 소유자에게 전송되었습니다",
          },
          contributorComments: {
            addComment: "코멘트를 추가하다",
            allComments: "모든 댓글",
            commentPrompt: "본 내용을 바탕으로 하고 싶은 의견이 있으신가요? 재미있는 댓글이나 메모를 남겨주세요!",
            yourComment: "당신의 의견",
            approved: "승인된 댓글",
            pendingApproval: "기고해 주셔서 감사합니다! 귀하의 의견은 승인을 위해 계획 소유자에게 전송되었습니다.",
            pendingNoThankYou: "승인을 위해 귀하의 의견이 계획 소유자에게 전송되었습니다",
            comments: "코멘트",
            toCheck: '확인하다',
            deleteConfirm: '이 의견을 제거 하시겠습니까?',
            deleteSuccess: "넵 댓글 삭제했습니다",
            notApprovedMessage: '귀하의 의견은 계획 소유자가 승인하지 않았습니다. 주석을 편집하고 다시 제출하거나 의견을 제거 할 수 있습니다.',
            notIncluded: '포함되지',
          },
          durationDropdown: {
            notSelected: "선택되지 않은",
            zeroMonths: "0 - 6개월",
            sixMonths: "6~12개월",
            oneYear: "1 - 2 년",
            twoYears: "2년 이상",
          },
          navigationButtons: {
            gtky: "당신을 알기로 돌아가기",
            thingsIDo: "내가 하는 일으로 돌아가기",
            happening: "내 인생에서 해프닝으로 돌아가기",
            planningFun: "계획 재미로 돌아가기urn to Planning Fun",
            planningHealth: "건강 계획으로 돌아가기",
            planningHome: "계획 홈으로 돌아가기",
            planningRelationships: "계획 관계로 돌아가기",
            planningTravel: "여행 계획으로 돌아가기",
            planningWork: "계획 작업으로 돌아가기",
          },
          generic: {
            add: "추가하다",
            addAnother: "다른 항목 추가",
            addComment: "코멘트를 추가하다",
            addNew: "새로운 걸 더하다",
            addNewEntry: "새 항목 추가",
            addYourOwn: "자신의 추가",
            addedBy: '추가',
            addressPrimary: "주소 1",
            addressSecondary: "스위트, 아파트, 유닛 #",
            alone: "홀로",
            approve: "승인하다",
            back: "뒤",
            backToDashboard: "계획 대시보드로 돌아가기",
            cancel: "취소",
            cancelled: "취소 된",
            city: "도시",
            clear: "분명한",
            country: "국가",
            comments: "기고자 코멘트",
            createdBy: "만든 사람",
            createdOn: "에 만든",
            dailyWithCapital: "일일",
            date: "날짜",
            delete: "삭제",
            deleteGoalConfirm: "이 목표를 삭제하시겠습니까?",
            deleteGoalSuccess: "목표가 성공적으로 삭제되었습니다",
            details: "세부",
            disapprove: "안된다고 하다",
            dislike: "싫어함",
            edit: "편집하다",
            editGoal: "목표 수정",
            email: "이메일",
            emailWithCapitial: "이메일",
            error: '오류',
            faceTime: "화상 통화",
            firstName: "이름",
            addGeneralComment: "일반 댓글을 추가하려면 클릭하세요.",
            generalComments: "일반적인 답변",
            generalComment: "일반 댓글을 추가하려면 클릭하세요",
            goal: "목표",
            goToItem: "항목으로 이동",
            goToGoal: "목표로 이동",
            goToSection: "{{섹션}}으로 이동",
            inPerson: "직접",
            instructionalVideo: "교육 비디오",
            language: "언어 기본 설정",
            lastName: "성",
            like: "처럼",
            loading: "로딩...",
            middleName: "중간 이름",
            name: "이름",
            newAdditions: "기여자가 새로 추가함",
            no: "아니",
            noNotifications: "알림 없음",
            noPeopleMessage: "내 인생의 사람들에게 친구와 가족을 추가하여 여기에서 선택하십시오!",
            noThanks: "아니요 괜찮습니다",
            okay: "확인",
            other: "다른",
            pleaseExplain: "설명 해주십시오",
            phoneNumber: "전화 번호",
            remove: "제거하다",
            removeImage: "이미지 제거",
            review: "검토",
            save: "구하다",
            saveAndSendUpdates: "업데이트 저장 및 보내기",
            saveAndSendInvite: "초대장 저장 및 보내기",
            saveAnother: "다른 저장 및 추가",
            saveNoUpdates: "업데이트를 보내지 않음",
            search: "검색",
            selectAGoal: "목표 선택",
            state: "상태",
            submit: "제출하다",
            success: "성공",
            talking: "말하는",
            thankYou: '감사합니다',
            title: "제목",
            uploadImage: "사진 업로드",
            viewComment: "댓글 보기",
            weeklyWithCapitial: "주간",
            with: '~와 함께',
            withOthers: "다른 사람들과",
            yes: "예",
            zip: "우편 번호",
            include: "포함",
            notInclude: "포함하지 않음",
            approveAll: "모두 승인",
            denyAll: "모두 거부",
            removeFromPlan: "계획에서 제거",
            said: "말했다",
            errors: {
              errorPageTitle: '오류가있었습니다!,',
              errorPageSubTitle: '미안합니다. 나중에 다시 오시기 바랍니다.,',
              error404: '404 페이지를 찾을 수 없습니다,',
              saveError: '죄송합니다. 항목을 저장하는 오류가있었습니다. 나중에 다시 시도 해주십시오.,',
              deleteError: '죄송합니다. 항목을 삭제하는 오류가있었습니다. 나중에 다시 시도 해주십시오.,',
              createPlanError: '죄송합니다. 계획을 세우는 오류가있었습니다. 나중에 다시 시도 해주십시오.',              
            },
          },
        },
      },
      vietnamese: {
        translation: {
          contact: {
            contactUs: 'Liên hệ chúng tôi',
            message: 'Thông điệp',
            send: 'Gửi',
            sayHello: 'Nói xin chào',
            saveTitle: 'Email của bạn đã được gửi thành công!',
            saveMessage: 'Đại diện từ Center On Me sẽ liên hệ với bạn trong thời gian ngắn.',
            emailError: 'Vui lòng nhập một địa chỉ email hợp lệ',
            phoneError: 'Xin vui lòng nhập một số điện thoại hợp lệ',
          },
          password: {
            lowercase: "Chứa một chữ cái thường",
            uppercase: "Chứa một chữ cái viết hoa",
            eightCharacters: "có ít nhất 8 kí tự",
            number: "Chứa một số",
            specialCharacter: "Chứa một ký tự đặc biệt",
            passwordsMatch: "Mật khẩu phù hợp",
            errorCannotMatch: "Mật khẩu Cũ và Mật khẩu Mới không thể khớp",
          },
          saveProgress: {
            uploadPhoto: 'Tải lên hình ảnh',
            updateProfile: 'Cập nhật hồ sơ của bạn',
          },
          agreements: {
            pleaseRead: "Bảo vệ thông tin của bạn là ưu tiên của chúng tôi. Hãy đọc của chúng tôi",
            privacyTitle: "Bảo mật trang web",
            termsTitle: "Điều khoản sử dụng",
            ifYouAgree: `Nếu bạn đồng ý, hãy nhập Họ và Tên của bạn và nhấp vào "Tôi Đồng ý"`,
          },
          settings: {
            pageTitle: "Cài đặt",
            subTitle: "Nơi của bạn để tùy chỉnh kế hoạch của bạn theo cách bạn muốn!",
            saveSettings: "Lưu các thiết lập",
            planVideo: "Video lập kế hoạch của tôi",
            contributorPage: "Quản lý cộng tác viên",
            colorPalette: "Bảng màu của tôi",
            changePassword: {
              changePassword: "Đổi mật khẩu",
              subTitle: "Hãy thay đổi mật khẩu đó!",
              currentPassword: "Mật khẩu hiện tại",
              newPassword: "Mật khẩu mới",
              confirmPassword: "Xác nhận mật khẩu",
              currentIncorrect: "Mật khẩu đã nhập không chính xác",
              samePasswordError: "Rất tiếc, vui lòng sử dụng mật khẩu mới",
              successMessage: "Mật khẩu đã được thay đổi!"
            },
            colorPalettePage: {
              pageTitle: "Bảng màu",
              subTitle: "Chọn những màu bạn muốn thấy khi tạo kế hoạch của mình!",
              myColorPalette: "Bảng màu của tôi",
              select: "Chọn bảng màu mà bạn thích",
              brainstormBlue: "động não màu xanh lam",
              planningPurple: "Lập kế hoạch bằng màu Tím",
              organizingOrange: "Tổ chức màu da cam",
              gamePlanGreen: "Kế hoạch trò chơi màu xanh lá cây",
              primaryColorTooltip: "Văn bản, Nút và Biểu tượng",
              secondaryColorTooltip: "Tiêu đề, các nút đã chọn, bảng",
              contributorColorTooltip: "Bài viết của cộng tác viên",
              textColorTooltip: "Chữ",
              saveMessage: "Bảng màu của bạn đã được cập nhật!"
            },
            manageContributors: {
              sectionHeader: "Quản lý cộng tác viên và thông báo của tôi",
              subHeader: "Nhấp vào thẻ cộng tác viên để đặt hoặc chỉnh sửa quyền của họ",
              myContributors: "Cộng tác viên của tôi",
              dialogConfirm: "Mọi thứ có nhìn đúng không?",
              dialogTitle: "Thông tin về cộng tác viên",
              inviteSuccess: "Một lời mời đã được gửi đến cộng tác viên của bạn",
              updateSuccess:
                "Thông tin Người đóng góp đã được cập nhật và một email được gửi để thông báo cho người đóng góp",
              updateSuccessNoEmail: "Thông tin về cộng tác viên đã được cập nhật!",
              lastContribution: "Lần đóng góp cuối cùng:",
              noContributions: "Chưa có đóng góp nào",
              addContributor: "Thêm một cộng tác viên",
            },
            contributorNotifications: {
              myNotifications: "Thông báo của tôi",
              letsSetPreferece: "Hãy Đặt Tùy chọn của Bạn",
              howToNotify: "Khi một cộng tác viên thêm vào kế hoạch của bạn, bạn muốn được thông báo như thế nào?",
              howOften: "Bạn muốn được thông báo bao lâu một lần?",
              saveAlert: "Cài đặt thông báo của bạn đã được cập nhật",
            },
            managementPage: {
              sectionHeader: "Cộng tác viên của tôi",
              noContributorsContext:
                "Bạn có thể cấp quyền cho cộng tác viên vào các phần khác nhau trong kế hoạch của mình. Sau đó, họ có thể đưa ra nhận xét và các bổ sung khác cho các phần đó!",
            },
            profile: {
              pageTitle: "Tên & Chi tiết",
              sectionHeader: "Chào mừng đến với Center on Me",
              subHeader: "Vui lòng dành một chút thời gian để cho chúng tôi biết một chút về bản thân bạn",
              profilePicture: 'Ảnh đại diện',
              name: "Tên",
              languageAndNumber: "Ngôn ngữ & Số điện thoại",
              address: "Địa chỉ nhà",
              firstNameError: "Tên là bắt buộc",
              lastNameError: "Họ là bắt buộc",
              phoneError: "Số điện thoại hợp lệ là bắt buộc",
              saveConfirmationMsg: "Cái này có đúng không?",
              backToSettings: "Quay lại Cài đặt",
              backToPlans: "Quay lại (các) kế hoạch của tôi",
              saveMessage: "Cảm ơn vì đã thêm thông tin của bạn!",
              firstTimeSaveMessage: 'Thật tuyệt khi được làm quen với bạn! Bây giờ, hãy bắt đầu lập kế hoạch.',
              notQuite: "Không hẳn",
              speechToText: 'Bài phát biểu cho văn bản',
              speechToTextEnabled: 'Vâng, tôi muốn sử dụng bài phát biểu để văn bản!',
              speechToTextDisabled: 'Không, tôi không muốn sử dụng bài phát biểu để nhắn tin.',
              speechToTextWarning: 'Hiện chỉ có sẵn bằng tiếng Anh',              
              languages: {
                english: "Tiếng Anh",
                armenian: "Tiếng Armenia",
                chineseSimple: "Tiếng Trung, giản thể",
                chineseTraditional: "Truyền thống Trung Hoa",
                farsi: "Farsi",
                hmong: "Hmong",
                korean: "Hàn Quốc",
                spanish: "Người Tây Ban Nha",
                tagalog: "Tagalog",
                vietnamese: "Tiếng Việt",
              },
            },
            account: {
              pageTitle: 'Tài khoản',
              subTitle: 'Nơi của bạn để quản lý đăng ký của bạn',
              benefitsTitle: 'Lợi ích đăng ký trả tiền',
              manageTitle: 'Quản lý thuê bao',
              benefits: {
                multiplePlans: 'Tạo nhiều kế hoạch',
                fullAccess: 'Truy cập đầy đủ vào các trang kế hoạch',
                printablePDF: 'Tóm tắt kế hoạch PDF có thể in',
                uploadPhotos: 'Tải lên ảnh',
                planVideo: 'Tạo một video kế hoạch để chia sẻ với những người đóng góp của bạn',
                backgroundImage: 'Chọn một hình nền tùy chỉnh',
                themeColor: 'Chọn màu chủ đề hồ sơ của bạn',
              },
              currentPlan: 'Kế hoạch hiện tại',
              trial: 'Thử nghiệm 30 ngày',
              subscription: 'Đăng ký 12 tháng',
              noSubscription: 'Không có đăng ký hiện tại',
              expirationDate: 'Ngày hết hạn',
              expiresOn: 'Đăng ký của bạn sẽ hết hạn',
              paymentMethod: 'Phương thức thanh toán',
              supportContact: 'Hỗ trợ liên hệ',
              supportContactIs: 'Liên hệ hỗ trợ của bạn là {{contactName}}',
              editSupportContact: 'Chỉnh sửa liên hệ hỗ trợ',
              supportContactLabel: 'Hỗ trợ liên hệ',
              contactEmailLabel: 'Liên hệ địa chỉ email',
              contactPhoneLabel: 'Số điện thoại liên hệ',
              saveSupportMessage: 'Liên hệ hỗ trợ của bạn đã được cập nhật.',
              supportContactError: 'Liên hệ hỗ trợ là bắt buộc',
              contactEmailError: 'Yêu cầu email liên hệ hỗ trợ là bắt buộc',
              contactPhoneError: 'Cần số điện thoại liên hệ hỗ trợ',              
              subscribeButton: 'Đặt mua',
              renewButton: 'Gia hạn đăng ký',
              requestRenewal: 'Yêu cầu gia hạn đăng ký của bạn',
              renewDialogTitle: 'Gia hạn đăng ký của bạn',
              selectedPlan: 'Kế hoạch được chọn',
              billed: 'Hóa đơn',
              payment: 'Sự chi trả',
              howItWorks: 'Cách đăng ký của bạn hoạt động',
              billingInformation: 'Thông tin thanh toán',
              billingStartPlanning: 'Bạn có thể bắt đầu lập kế hoạch ngay khi khoản thanh toán của bạn được chấp nhận',
              expirationExplanation: 'Khi hết hạn của thời hạn đăng ký, đăng ký sẽ tự động chấm dứt mà không cần thêm thông báo hoặc hành động nào được yêu cầu bởi một trong hai bên. Sẽ không có khoản phí bổ sung nào và bạn sẽ không còn có quyền truy cập vào các lợi ích liên quan đến đăng ký trừ khi bạn mua đăng ký mới.',
              termsConditions: 'Điều khoản và điều kiện',
              trialToMemberMessage: 'Chào mừng bạn đến trung tâm với tôi! Bây giờ bạn có quyền truy cập vào tất cả các lợi ích của một trung tâm trong kế hoạch ME.',
              memberRenewalMessage: 'Kế hoạch của bạn đã được gia hạn thêm 12 tháng nữa',
              contactEmail:  'Liên hệ địa chỉ email',
              contactPhone: 'Số điện thoại liên hệ',
              contactSuccessMessage: 'Liên hệ hỗ trợ của bạn đã được cập nhật',
              subscribeDialogTitle: 'Đăng ký trung tâm trên tôi',
              trialExpireOn: 'Thử nghiệm của bạn sẽ hết hạn',
              expiredOn: 'Đăng ký của bạn đã hết hạn',
              paymentSuccessMessage: 'Cảm ơn! Chúng tôi hy vọng bạn thích trung tâm của tôi',
              today: "Hôm nay",
              todayDescription: "Mở khóa toàn bộ quyền truy cập vào trung tâm của tôi",
              tenMonths: "10 tháng",
              tenMonthDescription: "Hãy được nhắc nhở khi kế hoạch của bạn sắp hết hạn",
              twelveMonths: "12 tháng",
              twelveMonthDescription: "Đăng ký của bạn kết thúc",
              showPaymentOptions: 'Hiển thị các tùy chọn thanh toán',
            },
            profilePictureDialog: {
              title: "Thêm ảnh hồ sơ của bạn",
              uploadPhoto: "Tải ảnh lên",
            },
            backgroundImage: {
              pageTitle: 'Hình nền',
              subTitle: 'Chọn một hình ảnh nền hoặc tải lên của riêng bạn',
              hideGrid: 'Ẩn lưới hình ảnh',
              showGrid: 'Hiển thị lưới hình ảnh',
              selectImage: "Chọn hình nền",
              noImage: "Không có hình ảnh",
              newImageWarning: "Chọn một hình ảnh mới sẽ xóa hình ảnh đã tải lên của bạn. Bạn có muốn tiếp tục?",
              warningConfirm: "Có, tôi muốn một hình ảnh mới",
              warningCancel: "Không, hãy giữ hình ảnh của tôi",
              uploadError: "Xin lỗi, đã xảy ra lỗi khi cập nhật hình nền của bạn",
            },
            planVideoPage: {
              sectionHeader: "Video lập kế hoạch của tôi",
              subHeader: "Tạo video để giới thiệu kế hoạch của bạn với những người đóng góp",
              saveAlertText: "Cảm ơn bạn đã tải lên video kế hoạch của mình!",
              explainTextGreeting: "Xin chào {{firstName}},",
              explainTextP1: `
                "Không gian này dành cho bạn tải lên video mà những người đóng góp của bạn sẽ
                 xem ngay khi họ đăng nhập vào Center On Me để đóng góp vào kế hoạch của bạn.
                 Video này là cơ hội để bạn giải thích lý do tại sao bạn tạo ra video này
                 lập kế hoạch và cung cấp cho những người đóng góp một ví dụ về tương lai mà bạn tưởng tượng
                cho chính bạn trong việc tạo ra kế hoạch này.`,
              explainTextP2: `Quay video trên điện thoại, máy tính hoặc máy tính bảng của bạn và tải lên
                 nơi đây. Video của bạn cần có thời lượng dưới mười (10) phút. Những lời gợi ý
                 về cách tạo video của bạn, vui lòng xem video ví dụ hoặc chọn
                        "Mẹo tạo video của tôi" .`,
              explainTextEnding: "Cảm ơn bạn,",
              explainTextSignature: "Center On Me",
              tipsButton: "Mẹo tạo video của tôi",
              exampleVideoTitle: "Video mẫu",
              alvaVideoTitle: "Ví dụ Alva",
              uploadButton: "Tải lên video của bạn",
              removeButton: "Xóa video của tôi",
              videoError: "Trình duyệt của bạn không hỗ trợ thẻ video.",
              lengthError: "Lỗi: Thời lượng Video Tối đa là 10 phút",
              tips: {
                dialogTitle: "Mẹo tạo video của bạn",
                tipsTitle: "Mẹo để quay và tải lên video của bạn:",
                tipOne: "Quay video của bạn dưới ánh sáng nơi có thể nhìn thấy toàn bộ khuôn mặt của bạn",
                tipTwo: "Hãy nghĩ về những gì bạn muốn nói trước khi ghi lại",
                tipThree: "Giữ video dưới mười (10) phút",
                tipFour:
                  "Nếu sau khi tải lên video của bạn, bạn gặp sự cố khi xem nó, vui lòng gửi email đến centeronme@mainsl.com và cung cấp tên người dùng của bạn (địa chỉ email) và tên của tệp video bạn đã tải lên (ví dụ: “Myplanvideo.mov)",
                examplesTitle: "Các câu hỏi mẫu cần trả lời trong video của bạn:",
                exampleOne: "Tại sao bạn lại mời mọi người đóng góp vào kế hoạch của bạn?",
                exampleTwo: "Bạn đang hy vọng đạt được điều gì với kế hoạch của mình?",
                exampleThree: "Bạn muốn lập kế hoạch cho điều gì?",
                exampleFour: "Các cộng tác viên của bạn có thể hỗ trợ bạn lập kế hoạch như thế nào?",
              },
              contributor: {
                sectionHeader: "Video lập kế hoạch của họ",
                subHeader: "",
                noVideo: "Chủ sở hữu kế hoạch chưa thêm Video kế hoạch",
              },
            },
            knowledgeCenter: {
              pageTitle: 'Trung tâm kiến thức',
              subTitle: 'Nhấp vào liên kết để tìm hiểu thêm về cách sử dụng trang web của chúng tôi',
              videoTab: 'Video',
              faqTab: 'Câu hỏi thường gặp',
              contactTab: 'Liên hệ chúng tôi',
              videos: {
                gettingStartedTitle: 'Bắt đầu',
                profileSettings: 'Cài đặt cấu hình',
                landingPageAndBadges: 'Trang Đích và Huy hiệu',
                contributorsTitle: 'Người đóng góp',
                addingContributors: 'Thêm cộng tác viên',
                managingContributors: 'Quản lý cộng tác viên',
                howToContribute: 'Cách đóng góp',
                gettingToKnowYouTitle: 'Để hiểu bạn hơn',
                likeAndAdmire: 'Thích và ngưỡng mộ',
                people: 'Những người trong cuộc sống của tôi',
                favorites: 'Những điều yêu thích của tôi',
                thingsIDo: 'Những điều tôi làm vì niềm vui',
                routines: 'Quy trình của tôi',
                historyMap: 'Bản đồ lịch sử',
                travelPlan: 'Đi du lịch',
                happeningNowTitle: 'Chuyện gì đang xảy ra bây giờ',
                healthAndSafety: 'sức khỏe và an toàn',
                livingInHome: 'Sống trong nhà của tôi',
                work: 'Công việc',
                school: 'Trường học',
                startPlanningTitle: 'Hãy bắt đầu lập kế hoạch',
                workGoals: 'Mục tiêu công việc',
                healthGoals: 'Mục tiêu sức khỏe',
                livingGoals: 'Mục tiêu sống',
                travelGoals: 'Mục tiêu Du lịch',
                funGoals: 'Mục tiêu thú vị',
                relationshipGoals: 'Mục tiêu quan hệ',
                planSummaryTitle: 'Tóm tắt kế hoạch',
                webVersion: 'Phiên bản Web & Xuất sang PDF',
                dialogTitle: 'Video hướng dẫn',
              },
              faq: {
                pageTitle: 'Các câu hỏi thường gặp',
                usingTheKnowledgeCenter: 'Sử dụng Trung tâm Kiến thức',
                passwordAndUsername: 'Mật khẩu và Tên người dùng',
                managingPlans: 'Quản lý kế hoạch',
                planOwnersAndContributors: 'Chủ sở hữu và cộng tác viên kế hoạch',
                usingTheSite: 'Sử dụng trang web',
                accessingSupport: 'Tiếp cận hỗ trợ',
                registration: 'Đăng ký & Đăng ký',
                howToUse: 'Làm cách nào để sử dụng trung tâm kiến thức?',
                whoToContact: 'Tôi có thể liên hệ với ai nếu tôi có câu hỏi không được đề cập trong video?',
                videoLanguage: 'Tôi có thể xem những video này bằng ngôn ngữ khác ngoài tiếng Anh không?',
                findVideo: 'Làm cách nào tôi có thể tìm thấy video mà tôi đang tìm kiếm?',
                passwordRequirements: 'Yêu cầu mật khẩu là gì?',
                changePassword: 'Làm thế nào để thay đổi mật khẩu của tôi?',
                changeUsername: 'Nếu tôi muốn thay đổi thông tin đăng nhập / địa chỉ email của mình thì sao?',
                howManyPlans: 'Tôi có thể tạo bao nhiêu gói khi đã đăng ký?',
                sharePlan: 'Tôi có thể chia sẻ kế hoạch của mình với bất kỳ ai không? Có cách nào để gửi email kế hoạch của tôi trong nền tảng này không?',
                deletePlan: 'Tôi phải làm gì nếu vô tình xóa một gói?',
                whatIsPlanOwner: 'Ai / "Chủ sở hữu kế hoạch" là gì',
                whatIsContributor: 'Ai / "Người đóng góp" là gì?',
                contributorLimit: 'Có giới hạn nào cho những người đóng góp mà tôi có thể thêm vào kế hoạch của mình không?',
                approveComment: 'Nếu tôi chấp thuận một bình luận của người đóng góp, họ có được thông báo không?',
                denyComment: 'Nếu tôi từ chối nhận xét của người đóng góp, họ có được thông báo không?',
                updateProfile: 'Tôi có thể cập nhật Chi tiết Hồ sơ của mình bất cứ lúc nào không?',
                howManyActivities: 'Tôi có thể thêm bao nhiêu hoạt động dưới mỗi phần?',
                listSomeoneAsResponsible: 'Nếu tôi liệt kê ai đó chịu trách nhiệm đạt được mục tiêu, họ có được thông báo không?',
                editActivity: 'Làm cách nào để chỉnh sửa một hoạt động?',
                deleteActivity: 'Làm cách nào để xóa một hoạt động?',
                accessibilityFeatures: 'Nền tảng cung cấp những tính năng trợ năng nào?',
                forgetToSave: 'Điều gì xảy ra nếu tôi quên lưu những gì tôi đang làm?',
                useOnTablet: 'Tôi có thể thực hiện kế hoạch của mình bằng cách sử dụng máy tính bảng của mình không?',
                useOnPhone: 'Tôi có thể thực hiện kế hoạch của mình bằng điện thoại của mình không?',
                phoneApp: 'Có ứng dụng điện thoại nào tôi cần tải xuống không?',
                needInternet: 'Tôi có cần truy cập internet để thực hiện kế hoạch của mình không? Có chế độ ngoại tuyến không?',
                whatAreBadges: 'Phù hiệu là gì?',
                helpWithPlan: 'Tôi phải liên hệ với ai nếu tôi cần trợ giúp về kế hoạch của mình?',
                issuesAccessingPlan: 'Tôi phải liên hệ với ai nếu gặp sự cố khi truy cập gói của mình?',
                issueWithPlanVideo: 'Tôi phải liên hệ với ai nếu không tải được Video kế hoạch của mình lên?',
                forgotUsername: 'Điều gì sẽ xảy ra nếu tôi quên tên người dùng đăng nhập của mình? Tôi liên hệ với ai?',
                wasRegistrationSuccessful: 'Làm cách nào để biết đăng ký của tôi đã thành công?',
                renewPlan: 'Làm cách nào để biết tôi cần gia hạn gói?',
                howToCancel: 'Làm cách nào để hủy đăng ký của tôi?',
              },
              faqAnswers: {
                howToUse: `Nhấp vào phần hoặc liên kết bạn muốn tìm hiểu thêm. Bạn sẽ được đưa đến một trang riêng biệt và bạn có thể xem video hướng dẫn bạn qua phần đó của trang web. `,
                whoToContact: ` Vui lòng liên hệ với chúng tôi bất cứ lúc nào bằng cách sử dụng tab "Liên hệ với chúng tôi"! Trong tab đó, bạn sẽ tìm thấy một biểu mẫu để hoàn thành sẽ gửi email cho chúng tôi. Trong email của bạn, vui lòng bao gồm trang và video mà bạn có thắc mắc. Chúng tôi sẽ trả lời bạn trong thời gian ngắn. `,
                videoLanguage: ` Hiện tại, những video này chỉ có sẵn bằng tiếng Anh. Phụ đề chi tiết đã được cung cấp cho tất cả các video. `,
                findVideo: ` Vui lòng nhập trang bạn đang tìm kiếm vào thanh tìm kiếm và một liên kết đến video sẽ bật lên. Các video được sắp xếp theo tên của trang. Nếu bạn không thể tìm thấy video bạn đang tìm kiếm, vui lòng liên hệ với chúng tôi bằng cách sử dụng tab "Liên hệ với chúng tôi". `,
                passwordRequirements: ` Mật khẩu của bạn phải bao gồm: Một chữ cái thường, một chữ hoa, một số, một ký tự đặc biệt và có ít nhất 8 ký tự. `,
                changePassword: ` Bạn có thể thay đổi mật khẩu của mình bất kỳ lúc nào bằng cách đi tới cài đặt hồ sơ của bạn và chọn nút "Thay đổi mật khẩu". `,
                changeUsername: ` Bạn sẽ không thể thay đổi địa chỉ email của mình. `,
                howManyPlans: `Không có giới hạn về số lượng kế hoạch bạn có thể tạo. Tuy nhiên, thông tin bạn nhập vào các kế hoạch bao gồm cả những người đóng góp cho kế hoạch sẽ không được chuyển từ kế hoạch này sang kế hoạch khác. `,
                sharePlan: ` Chủ sở hữu kế hoạch có thể chia sẻ kế hoạch của họ với bất kỳ ai bất kỳ lúc nào. Ở góc trên bên phải của trang tóm tắt kế hoạch, có một nút có nội dung “Xuất sang PDF”. Khi nhấp vào nút đó, kế hoạch của bạn sẽ được tải xuống và có thể được in hoặc gửi qua email cho bất kỳ người nào bạn chọn. `,
                deletePlan: `Khi bạn cố gắng xóa một gói, Center On Me sẽ đưa ra lời nhắc để đảm bảo rằng bạn thực sự muốn xóa gói của mình. Nếu bạn vẫn chọn xóa kế hoạch của mình thì không thể làm gì được và bạn phải tạo lại kế hoạch của mình từ đầu. `,
                whatIsPlanOwner: `Chủ kế hoạch” là người trọng tâm của kế hoạch, người đang tạo ra kế hoạch. Chủ kế hoạch nên là trọng tâm của kế hoạch này mọi lúc! `,
                whatIsContributor: ` Người đóng góp là người mà "Chủ kế hoạch" đã mời đóng góp cho kế hoạch của họ. Chủ kế hoạch có toàn quyền kiểm soát đối với phần nào trong kế hoạch của họ mà người đóng góp có thể xem và / hoặc nhận xét. Tất cả các ý kiến đóng góp phải được xem xét và phê duyệt trước khi đưa vào kế hoạch. `,
                contributorLimit: `Không có giới hạn về số lượng cộng tác viên mà bạn có thể có. Đối với mỗi kế hoạch bạn tạo, bạn sẽ cần phải thêm từng cộng tác viên riêng biệt. `,
                approveComment: ` Không, Người đóng góp sẽ không được thông báo khi nhận xét đã được phê duyệt. `,
                denyComment: ` Không, Người đóng góp sẽ không được thông báo khi nhận xét bị từ chối. `,
                updateProfile: `Bạn có thể cập nhật chi tiết hồ sơ của mình bất kỳ lúc nào bằng cách đi tới cài đặt hồ sơ. Trong cài đặt hồ sơ, bạn sẽ có khả năng thay đổi Tên & Chi tiết, Ảnh Hồ sơ, hình nền và bảng màu của mình. `,
                howManyActivities: ` Không có giới hạn về số lượng hoạt động hoặc mục tiêu mà bạn có thể thêm trong mỗi phần. `,
                listSomeoneAsResponsible: ` Họ sẽ không được thông báo nếu bạn liệt kê ai đó chịu trách nhiệm về việc đạt được mục tiêu. Nếu chủ sở hữu kế hoạch muốn được hỗ trợ với một mục tiêu, họ sẽ cần phải trao đổi với người đó và chia sẻ kế hoạch của họ nếu họ cảm thấy thoải mái khi làm như vậy. `,
                editActivity: `Bạn có thể chỉnh sửa hoạt động hoặc mục tiêu bất kỳ lúc nào bằng cách nhấp vào thẻ. Biểu mẫu sẽ mở ra và mọi thông tin có thể được thay đổi hoặc cập nhật. `,
                deleteActivity: `Bạn có thể xóa một hoạt động hoặc mục tiêu bất kỳ lúc nào bằng cách nhấp vào thẻ. Biểu mẫu sẽ mở ra và mục tiêu có thể được xóa bằng cách cuộn xuống dưới cùng và chọn nút “Xóa” màu đỏ. `,
                accessibilityFeatures: `Center On Me sử dụng Userway để cung cấp các tính năng trợ năng trong trang web. Một số tính năng trợ năng đó đang thay đổi độ tương phản, kích thước phông chữ, hướng dẫn con trỏ và căn chỉnh văn bản hoặc khoảng trắng. Bạn có thể chọn các tính năng của mình bằng cách chọn biểu tượng “Người” tròn tồn tại trên mọi trang ở phía bên trái.`,
                forgetToSave: ` Điều rất quan trọng là nhấn lưu vì bạn đang tạo các trang khác nhau trong trang web. Thông tin sẽ không được lưu tự động. `,
                useOnTablet: ` Vâng! Center On Me có thể được truy cập từ Máy tính bảng hoặc từ Điện thoại thông minh. `,
                useOnPhone: ` Vâng! Center On Me có thể được truy cập từ Điện thoại thông minh. Trang web đã được tạo để đáp ứng trên thiết bị di động, vì vậy một số dữ liệu có thể không dễ đọc như trên máy tính bảng hoặc sử dụng máy tính của bạn. `,
                phoneApp: ` Không, Center On Me không có ứng dụng. Bạn có thể truy cập Center On Me từ điện thoại thông minh của mình bằng cách mở ứng dụng trình duyệt web và truy cập www.centeronme.com. `,
                needInternet: ` Đúng, Center On Me yêu cầu internet để có thể thực hiện một kế hoạch. Không có chế độ ngoại tuyến của Center On Me. `,
                whatAreBadges: ` Các huy hiệu là một cách để người dùng và Center On Me theo dõi tiến trình của bạn trong trang web. Bạn kiếm được huy hiệu mỗi khi hoàn thành một trang và bạn có thể xem huy hiệu trên các trang đích. Nhấp vào các huy hiệu để có một bất ngờ thú vị! `,
                helpWithPlan: `Nếu bạn cần hỗ trợ, vui lòng truy cập tab" Liên hệ với chúng tôi "trong trung tâm kiến thức. Một email sẽ được gửi đến nhóm Center On Me và chúng tôi sẽ sớm trả lời. `,
                issuesAccessingPlan: `Nếu bạn cần hỗ trợ, vui lòng truy cập tab “Liên hệ với chúng tôi” trong trung tâm kiến thức. Một email sẽ được gửi đến nhóm Center On Me và chúng tôi sẽ sớm trả lời. `,
                issueWithPlanVideo: `Trước tiên, hãy kiểm tra các mẹo tải video lên để đảm bảo bạn đáp ứng các yêu cầu về video. Nếu bạn vẫn cần hỗ trợ, vui lòng truy cập tab "Liên hệ với chúng tôi" trong trung tâm kiến thức. Một email sẽ được gửi đến nhóm Center On Me và chúng tôi sẽ sớm trả lời. `,
                forgotUsername: `Tên người dùng của bạn là địa chỉ email của bạn. Nếu bạn cần hỗ trợ, vui lòng truy cập tab "Liên hệ với chúng tôi" trong trung tâm kiến thức. Một email sẽ được gửi đến nhóm Center On Me và chúng tôi sẽ sớm trả lời. `,
                wasRegistrationSuccessful: ` Nếu thanh toán của bạn thành công, bạn sẽ nhận được biên nhận qua email đến tài khoản PayPal được liên kết của mình. Bạn cũng sẽ tìm thấy biên lai trong ứng dụng PayPal của mình. Xem ví dụ dưới đây về biên lai thanh toán qua email. `,
                renewPlan: ` Người dùng sẽ nhận được lời nhắc qua email sau 3 tháng, 6 tháng và 10 tháng để nhắc người dùng khi đăng ký của bạn hết hạn và sẽ được cung cấp hướng dẫn về cách gia hạn gói của bạn. `,
                howToCancel: ` Một gói đăng ký được mua trong một năm. Bạn không thể hủy đăng ký một năm. Một email sẽ được gửi cho bạn khi đến lúc gia hạn gói của bạn và bạn có thể chọn không gia hạn gói của mình tại thời điểm đó. `,
              }
            },
          },
          lockedFeatures: {
            photoLock: 'Cần đăng ký để tải lên ảnh',
            videoLock: 'Cần đăng ký để tải lên video',
            pdfLock: 'Cần đăng ký để tải xuống PDF',
            sectionLock: 'Chọn một tùy chọn đăng ký để truy cập tính năng này.',
            sectionLockSaveMessage: 'Phần này không thể được sửa đổi bởi người dùng dùng thử',
            createPlanLock: 'Chọn tùy chọn đăng ký để tạo nhiều kế hoạch',
            expiredLock: 'Chọn tùy chọn đăng ký để truy cập tính năng này',
            expiredAlert:  'Đăng ký của bạn đã hết hạn. Vui lòng gia hạn để xem phần này.',
          },
          permissions: {
            theContributor: "Người đóng góp",
            cannotView: "không thể xem",
            canView: "có thể xem",
            theSection: "phần",
            toTheSection: "đến phần",
            and: "và",
            but: "nhưng",
            cannotComment: "không thể thêm bình luận",
            canComment: "có thể thêm bình luận",
            gtkyTitle: "Để hiểu bạn hơn",
            happeningTitle: "Điều gì đang xảy ra trong cuộc sống của bạn",
            planningTitle: "Lập kế hoạch",
          },
          breadcrumbs: {
            planning: "Hãy bắt đầu lập kế hoạch",
            happening: "Đang xảy ra",
            gettingToKnowYou: "Để hiểu bạn hơn",
            myRoutines: "Quy trình của tôi",
            myPeople: "Những người trong cuộc sống của tôi",
            likeAndAdmire: "Thích và ngưỡng mộ",
            work: "Công việc",
            school: "Trường học",
            home: "Nhà",
            fun: "Vui vẻ",
            travel: "Đi du lịch",
            health: "Sức khỏe",
            relationships: "Các mối quan hệ",
            goal: "Mục tiêu của tôi",
            historyMap: "Bản đồ lịch sử",
            favoriteThings: "Những điều yêu thích của tôi",
            add: "Thêm mới",
            profile: "Hồ sơ",
            settings: "Cài đặt",
            contributors: "Cộng tác viên & Thông báo",
            thingsIDo: "Những điều tôi làm để giải trí",
            planningVideo: "Video lập kế hoạch của tôi",
            planSummary: "Tóm tắt kế hoạch",
            changePassword: "Đổi mật khẩu",
            knowledgeBase: "Trung tâm kiến thức",
            colorPalette: "Bảng màu",
            myActivity: "Hoạt động của tôi",
          },
          userMenu: {
            allPlans: "Tất cả các kế hoạch",
            planDashboard: "Bảng điều khiển kế hoạch",
            planSummary: "Tóm tắt kế hoạch",
            settings: "Cài đặt",
            subscribe: "Đặt mua",
            logout: "Đăng xuất"
          },
          timeoutAlert: {
            attention: "Chú ý",
            description: "Bạn sắp đăng xuất",
            stillPlanning: "Tôi vẫn đang lập kế hoạch!",
            logMeOut: "Thoát ra khỏi tài khoản của tôi",
            warningOnLogout: "Dữ liệu chưa lưu sẽ bị mất",
          },
          allPlans: {
            pageTitle: "Xem nhanh các kế hoạch của bạn",
            subTitle: "Chọn một để bắt đầu",
            iContributeTo: "Các kế hoạch tôi đóng góp vào",
            myPlans: "Những kế hoạch của tôi",
            newContributeTo: "Đóng góp vào một kế hoạch mới",
            createNewPlan: "Tạo một kế hoạch mới",
            planOwner: "Chủ sở hữu kế hoạch",
            planCreated: "Đã tạo kế hoạch",
            lastViewed: "Xem lần cuối",
            planVideo: "Lập kế hoạch video",
            manageContributors: "Quản lý cộng tác viên và thông báo",
            planSummary: "Tóm tắt kế hoạch",
            deletePlan: "Xóa kế hoạch",
            createPlanDialog: {
              dialogTitle: 'Tạo một kế hoạch mới',
              explainerText: 'Chọn tên cho kế hoạch mới của bạn để bắt đầu',
              inputLabel: 'Tên kế hoạch',
              createMessage: 'Bắt đầu một kế hoạch mới sẽ không thực hiện bất kỳ công việc hoặc người đóng góp nào của bạn từ các kế hoạch trước đó',
            },
            contributeToPlanDialog: {
              dialogTitle: 'Đóng góp cho một kế hoạch',
              explainerText: 'Nhập mã bạn nhận được từ chủ sở hữu kế hoạch',
              inputLabel: 'Mã truy cập',
              errorMessage: 'Mã truy cập không phù hợp với bất kỳ kế hoạch. Vui lòng thử lại',
            }
          },
          deletePlan: {
            dialogTitle: "Xóa kế hoạch của tôi",
            explanation: "Bạn đang cố gắng xóa kế hoạch của mình.",
            confirmMessage: "Bạn có chắc chắn muốn xóa gói này không?",
            noteToDownload: "Lưu ý: Bạn có thể muốn tải xuống và lưu gói của mình trước khi xóa vì không thể khôi phục được.",
            downloadButton: "Tải xuống Kế hoạch của tôi",
            keepPlanButton: "Giữ kế hoạch của tôi",
            deleteButton: "Xóa kế hoạch của tôi",
            deleteSuccessMessage: "Đã xóa!",
          },
          planLandingPage: {
            videoTitle: "Chào mừng bạn đến với Trung tâm của bạn về Kế hoạch của tôi",
            gtky: "Để hiểu bạn hơn",
            whatIsHappening: "Điều gì đang xảy ra trong cuộc sống của bạn",
            startPlanning: "Bắt đầu lập kế hoạch",
            newBadge: "Huy hiệu mới",
            getStarted: "Bắt đầu",
            leftOff: "Nơi bạn đã dừng lại",
            happeningButton: "Đang xảy ra",
            planningButton: "Lập kế hoạch Mục tiêu",
            recentBadges: "Huy hiệu gần đây đã kiếm được",
            gtkyNoCaps: "Để hiểu bạn hơn",
            happeningShort: "Chuyện gì đang xảy ra",
            planning: "Lập kế hoạch",
            noBadgeMessage: "Chưa có huy hiệu! Bắt đầu hoàn thành các phần để nhận huy hiệu",
            contributorGreeting: "Chào mừng",
            contributorMessage: `Chúng tôi thấy rằng bạn đang tham gia với tư cách cộng tác viên, điều đó có nghĩa là ai đó nghĩ rằng 
              bạn khá quan trọng! Bạn đóng một vai trò thiết yếu trong khi đóng góp vào vở kịch này, chúng tôi yêu cầu bạn giữ vững mục 
              đích của trang web này và giữ chủ sở hữu kế hoạch là trung tâm của kế hoạch của họ trong khi tận dụng thời gian của bạn 
              để thêm thông tin phong phú và có ý nghĩa. Khi đóng góp vào một kế hoạch, chúng tôi cũng yêu cầu bạn dành thời gian để 
              phản ánh và nắm bắt thông tin cụ thể về người đó là ai, điều gì quan trọng đối với họ và cách bạn có thể giúp họ đạt được 
              hy vọng và ước mơ của mình!
            `,
            contributorQuote: `"Mỗi người đều có năng lực, khả năng và năng khiếu. Sống một cuộc sống tốt đẹp phụ thuộc vào việc những 
              năng lực đó có thể được sử dụng, khả năng được thể hiện và những món quà được ban tặng hay không. Nếu có, người đó sẽ được 
              đánh giá cao, cảm thấy mạnh mẽ và kết nối tốt với những người xung quanh họ và cộng đồng xung quanh người đó sẽ cảm thấy 
              mạnh mẽ hơn vì những đóng góp mà người đó đang thực hiện. "
            `,
            quote: `"Mục tiêu của bạn là bản đồ đường hướng dẫn bạn và chỉ cho bạn những gì có thể cho cuộc sống của bạn." - Les Brown`,
            profileBadges: {
              addContributor: 'Huy hiệu: Thêm Người đóng góp vào kế hoạch của bạn',
              colorPalette: 'Huy hiệu: Chọn một bảng màu',
              approveContributor: 'Huy hiệu: Phê duyệt đóng góp',
              planningVideo: 'Huy hiệu: Tạo video lập kế hoạch',
              profilePicture: 'Huy hiệu: Tải lên Ảnh Hồ sơ',
              backgroundImage: 'Huy hiệu: Tải lên Hình nền mới',
              profileComplete: 'Huy hiệu: Hoàn thành hồ sơ!',
            }
          },
          badges: {
            badgeSectionTitle: "Huy hiệu tôi đã kiếm được",
            badgesPlanningExplanation: "Hãy vui vẻ khi lập kế hoạch cho tương lai của bạn!",
            badgesHappeningExplanation: "Hãy vui vẻ khi tìm hiểu những gì đang xảy ra trong cuộc sống của bạn!",
            badgesGTKYExplanation: `huy hiệuGTKYExplanation: Bắt đầu kiếm huy hiệu bằng cách hoàn thành từng phần! Khi bạn hoàn thành một phần, huy hiệu của bạn sẽ xuất hiện ở đây. Hãy vui vẻ khi làm quen với bạn!`,
          },
          sections: {
            likeAndAdmire: "Thích & ngưỡng mộ",
            people: "Những người trong cuộc đời tôi",
            historyMap: "Các cột mốc",
            favorites: "Những thứ yêu thích",
            travelPlan: "Đi du lịch",
            thingsIDo: "Những điều tôi làm để giải trí",
            dailyRoutines: "Thói quen hàng ngày",
            weeklyRoutines: "Quy trình hàng tuần",
            routines: "Quy trình",
            happeningHome: "Trang chủ đang xảy ra",
            happeningWork: "Công việc đang diễn ra",
            happeningSchool: "Trường học đang diễn ra",
            happeningHealth: "Sức khỏe đang diễn ra",
            planningHome: "Trang chủ Lập kế hoạch",
            planningWork: "Lập kế hoạch công việc",
            planningTravel: "Lập kế hoạch Du lịch",
            planningFun: "Lập kế hoạch thú vị",
            planningHealth: "Lập kế hoạch sức khỏe",
            planningRelationships: "Mối quan hệ lập kế hoạch",
          },
          planningPage: {
            pageTitle: "Hãy bắt đầu lập kế hoạch",
            planningVideo: "Lập kế hoạch Video",
            housing: "Nhà ở",
            work: "Công việc",
            health: "Sức khỏe",
            fun: "Vui vẻ",
            relationships: "Các mối quan hệ",
            travel: "Đi du lịch",
            addAnotherGoal: "Thêm mục tiêu khác"
          },
          planningHealth: {
            sectionName: "Lập kế hoạch sức khỏe",
            sectionHeader: "Mục tiêu sức khỏe",
            subHeader: "Hãy lập kế hoạch cho một tương lai lành mạnh",
            videoTitle: "Mục tiêu sức khỏe - Video hữu ích",
            goalTitle: "Bạn muốn đặt tiêu đề cho mục tiêu này là gì?",
            goalAroundHealth: "Mục tiêu của bạn xung quanh sức khỏe là gì?",
            workedOnThisGoal: "Bạn đã bao giờ thực hiện mục tiêu này trước đây chưa?",
            whatWorked: "Điều gì đã hiệu quả khi bạn thực hiện mục tiêu này?",
            didntWork: "Điều gì không hiệu quả khi bạn thực hiện mục tiêu này?",
            healthTableHeader: "Khi nghĩ về mục tiêu này, bạn nghĩ mình nên làm gì ...",
            doYouHaveSupport: "Có ai trong cuộc sống của bạn có thể hỗ trợ bạn đạt được mục tiêu này không?",
            attachedPeopleMessage: "Hãy chọn người có thể hỗ trợ bạn",
            anyOneElseWhoCanSupport: "Có ai khác có thể giúp bạn đạt được mục tiêu không và tại sao?",
            goalCommit: "Bạn có thể cam kết làm gì để giúp tạo ra tương lai này?",
            increase: "Tăng",
            decrease: "Giảm bớt",
            doMore: "Làm được nhiều việc hơn",
            doLess: "Làm ít hơn",
            increaseEx: "Ví dụ: Tôi tập trung vào bản thân mình mất bao nhiêu thời gian",
            decreaseEx: "Ví dụ: Tôi dành bao nhiêu thời gian trên Facebook",
            doMoreEx: "Ví dụ: Thời gian tôi dành cho việc thở của mình",
            doLessEx: "Ví dụ: Ăn thức ăn có đường",
            saveMessage: "Tuyệt vời, chúc bạn may mắn với mục tiêu này! Bạn có muốn thêm một mục tiêu sức khỏe khác không?",
            deleteMessage: "Bạn có chắc chắn muốn xóa hàng trong bảng này không?",
            deleteConfirm: "Ok, hàng đã bị xóa",
            summary: {
              goalTitle: "Mục tiêu của tôi về sức khỏe",
              whatWorked: "Khi tôi thực hiện mục tiêu này trước đây thì đây là điều đã hoạt động",
              didntWork: "Khi tôi thực hiện mục tiêu này trước đây thì đây là điều không hiệu quả",
              healthTableHeader: "Khi tôi nghĩ về mục tiêu của mình, tôi nghĩ tôi có thể làm những điều sau để giúp tôi",
              doYouHaveSupport: "Những người trong vòng hỗ trợ của tôi có thể giúp tôi đạt được mục tiêu này",
              anyOneElseWhoCanSupport: "Những người khác mà tôi nghĩ có thể giúp được",
              goalCommit: "Tôi có thể cam kết",
            },
          },
          planningRelationships: {
            sectionName: "Mối quan hệ lập kế hoạch",
            sectionHeader: "Mục tiêu quan hệ",
            subHeader: "Hãy bắt đầu tạo kết nối với mọi người!",
            newOrStrengthen: "Bạn muốn lên kế hoạch cho một mối quan hệ mới hay bạn muốn củng cố mối quan hệ hiện tại?",
            saveMessage: "Có vẻ như một mục tiêu trong mối quan hệ tuyệt vời! Bạn có muốn thêm một mục tiêu khác không?",
            newText: "MỚI",
            strengthenText: "CỦNG CỐ",
            relationship: 'Mối quan hệ',
            strengthen: {
              videoTitle: "Tăng cường các mối quan hệ - Video hữu ích",
              goal: "Bạn muốn tăng cường mối quan hệ với ai?",
              currentStatus: "Tình trạng hiện tại của mối quan hệ này là gì?",
              romantic: "Lãng mạn",
              friend: "Bạn",
              professional: "Chuyên nghiệp",
              family: "Gia đình",
              whatToStrengthen: "Còn mối quan hệ này bạn muốn củng cố?",
              frequency: "Tính thường xuyên",
              communication: "Liên lạc",
              desiredStatus: "Trạng thái mong muốn",
              howOften: "Bạn hiện tương tác với người này bao lâu một lần?",
              howToStrengthen: "Bạn muốn tăng cường tần số như thế nào",
              strengthenCommunication: "Bạn muốn tăng cường giao tiếp thì sao?",
              commExample1: "Ví dụ: Tôi đã thử gọi cho họ thường xuyên hơn",
              commExample2: "Ví dụ: Tôi đã có thể nói chuyện với họ khi tôi muốn",
              commExample3: "Ví dụ: Tôi cảm thấy được lắng nghe và kết nối",
              commExample4: "Ví dụ: Họ sẽ không trả lời mọi lúc",
              commTableHeader1: "Bạn đã thử điều gì khi tăng cường khả năng giao tiếp của mình?",
              statusTableHeader1: "Bạn đã cố gắng làm gì để thay đổi tình trạng của mối quan hệ này?",
              tableHeader2: "Bạn đã học được gì khi thử điều đó?",
              tableHeader3: "Bạn hài lòng về điều gì khi thử điều đó?",
              tableHeader4: "Bạn đang lo lắng về điều gì?",
              commDoNext: "Hãy xem những gì bạn vừa liệt kê, bạn có thể làm gì tiếp theo?",
              preferredStatus: "Bạn muốn trạng thái của người này là gì?",
              theyAreCurrently: "Họ hiện là:",
              statusExample1: "Ví dụ: Tôi đã thử hỏi họ vào một buổi hẹn hò",
              statusExample2: "Ví dụ: Rằng họ rất tử tế và tôi thích nói chuyện với họ",
              statusExample3: "Ví dụ: Họ đã nói có!",
              statusExample4: "Ví dụ: Tôi đã không gọi cho họ kể từ đó",
              finalDoNext: "Nhìn vào những gì bạn vừa liệt kê, bạn có thể làm gì tiếp theo?",
              deleteRowConfirm: "Bạn có chắc chắn muốn xóa hàng trong bảng này không?",
              deleteRowSuccess: "Ok, hàng đã bị xóa",
            },
            new: {
              videoTitle: "Lên kế hoạch cho mối quan hệ mới - Video hữu ích",
              relationshipType: "Bạn đang tìm kiếm kiểu quan hệ nào?",
              characteristics: "Bạn đang tìm kiếm những đặc điểm nào ở người mà bạn muốn kết nối?",
              where: "Bạn có thể gặp người này ở đâu?",
              placeOfWorship: "Nơi thờ phụng",
              family: "Gia đình",
              friend: "Bạn",
              professional: "Chuyên nghiệp",
              romantic: "Lãng mạn",
              sharedInterests: "Thông qua Sở thích được Chia sẻ",
              online: "Trực tuyến",
              school: "Trường học",
              shops: "Cửa hàng",
              work: "Công việc",
              whenYouThinkAbout: "Khi bạn nghĩ về việc tạo một kết nối mới",
              atChurch: "tại một nơi thờ phượng",
              throughFriend: "thông qua một người bạn",
              throughInterests: "thông qua sở thích được chia sẻ",
              atOnline: "Trực tuyến",
              atSchool: "ở trường",
              atLocalShop: "tại một cửa hàng địa phương",
              atWork: "tại nơi làm việc",
              likeToStart: ", bạn muốn bắt đầu từ đâu?",
              churchStart: "Khi bạn nghĩ về việc tạo ra một kết nối mới tại một nơi thờ cúng, bạn muốn bắt đầu từ đâu?",
              friendStart: "Khi bạn nghĩ về việc tạo một kết nối mới thông qua một người bạn, bạn muốn bắt đầu từ đâu?",
              interestsStart: "Khi bạn nghĩ về việc tạo ra một kết nối mới thông qua những sở thích được chia sẻ, bạn muốn bắt đầu từ đâu?",
              onlineStart: "Khi bạn nghĩ về việc tạo một kết nối mới trực tuyến, bạn muốn bắt đầu từ đâu?",
              schoolStart: "Khi bạn nghĩ về việc tạo một kết nối mới ở trường, bạn muốn bắt đầu từ đâu?",
              shopsStart: "Khi bạn nghĩ về việc tạo một kết nối mới tại một cửa hàng địa phương, bạn muốn bắt đầu từ đâu?",
              workStart: "Khi bạn nghĩ về việc tạo ra một kết nối mới tại nơi làm việc, bạn muốn bắt đầu từ đâu?",
              howOften: "Bạn muốn kết nối theo cách này bao lâu một lần?",
            },
            characteristics: {
              organized: "Được tổ chức",
              strong: "Mạnh",
              dreamer: "Một người mơ mộng",
              determined: "Xác định",
              smart: "Thông minh",
              artist: "Một nghệ sĩ",
              considerate: "Thận trọng",
              helpful: "Hữu ích",
              creative: "Sáng tạo",
              thoughtful: "Chu đáo",
              talented: "Có tài",
              outgoing: "Hướng ngoaị",
              leader: "Một nhà lãnh đạo",
              intelligent: "Thông minh",
              loving: "Thương",
              understanding: "Hiểu biết",
              spiritual: "Thuộc linh",
              joyful: "Hân hoan",
              fixer: "Một người sửa chữa",
              liberal: "Phóng khoáng",
              patient: "Bệnh nhân",
              respectful: "Kính cẩn",
              willing: "Sẵn lòng",
              intuitive: "Trực giác",
              gentle: "Dịu dàng",
              generous: "Rộng lượng",
              spontaneous: "Tự phát",
              independent: "Độc lập",
              supportive: "Ủng hộ",
              resourceful: "Tháo vát",
              experiential: "Dựa theo kinh nghiệm",
              fun: "Vui vẻ",
              funny: "Vui",
              excited: "Bị kích thích",
              accepting: "Chấp nhận",
              busy: "Bận",
              pretty: "Đẹp",
              openMinded: "Cởi mở",
              hardWorking: "làm việc chăm chỉ",
              doer: "Một người làm",
              involved: "Có liên quan",
              flexible: "Linh hoạt",
              happy: "Vui mừng",
              careful: "Cẩn thận",
              hopeful: "Hy vọng",
              tinkerer: "A Tinkerer",
              riskTaker: "Kẻ thách thức rủi ro",
              lucky: "May mắn",
              active: "Tích cực",
              efficient: "Có hiệu quả",
              visionary: "Một người nhìn xa trông rộng",
              explorer: "Một nhà thám hiểm",
              responsible: "Có tinh thần trách nhiệm",
              driven: "Thúc đẩy",
              confident: "Tin chắc",
              connector: "Một trình kết nối",
              wise: "Khôn ngoan",
              expressive: "Biểu cảm",
              sensitive: "Nhạy cảm",
              talkative: "Lắm lời",
              energized: "Tràn đầy sinh lực",
              giving: "Cho",
              observant: "Tinh ý",
              caring: "Chăm sóc",
              peaceful: "Bình yên",
              cool: "Mát mẻ",
              fashionable: "Hợp thời trang",
              actor: "Một diễn viên",
              writer: "Nhà văn",
              vibrant: "Sôi nổi",
              goodCook: "Một đầu bếp giỏi",
              goodWithAnimals: "Tốt với động vật",
            },
            summary: {
              newGoalPdfTitle: "Mối quan hệ mơi",
              strengthenGoalPdfTitle: "Tăng Cường Mối Quan Hệ",
              relationshipType: "Loại mối quan hệ mà tôi muốn tạo là",
              characteristics: "Tôi muốn người này có những đặc điểm sau",
              where: "Tôi có thể gặp người này",
              whereToStart: "Nơi tôi muốn bắt đầu",
              howOften: "Bao lâu",
              beingInPerson: 'Đang ở trong người',
              talkingMore: 'Nói nhiều hơn',
              facetiming: 'Mặt',
              otherMeans: 'Các phương tiện khác',
              comments: "Bình luận",
              strengthenGoal: "Người mà tôi muốn củng cố mối quan hệ của mình là",
              currentStatus: "Tình trạng hiện tại của mối quan hệ của chúng ta là",
              whatToStrengthen: "Điều tôi muốn củng cố",
              strengthenBy: "Tôi muốn tăng cường sức mạnh bằng ...",
              strengthenWhy: "Tại sao?",
              whyAnswer: "Điều này sẽ giúp tôi đạt được mục tiêu của mình",
              becoming: "Đang trở thành",
            },
          },
          planningHome: {
            sectionName: "Trang chủ Lập kế hoạch",
            sectionHeader: "Mục tiêu sống",
            subHeader: "Hãy xem những gì bạn thích hoặc không thích về hoàn cảnh sống hiện tại của bạn",
            wantToLive: "Bạn muốn sống ở đâu?",
            idealHome: "Ngôi nhà lý tưởng của bạn trông như thế nào?",
            homeAmenities: "Bạn muốn ngôi nhà của mình có những tiện nghi gì?",
            homeCloseTo: "Bạn muốn nhà của bạn gần với cái gì?",
            liveWith: "Có người nào bạn muốn sống cùng không?",
            roommateName: "Ai?",
            roommateQuality: "Tại sao bạn nghĩ rằng sống với người này sẽ là một sự phù hợp?",
            askedRoommate: "Bạn đã hỏi về việc sống chung chưa?",
            liveWithPets: "Có thú cưng nào trong tương lai của bạn không?",
            petType: "Loại động vật nào?",
            finalThoughts: "Còn điều gì bạn muốn trong ngôi nhà lý tưởng của mình mà chúng ta chưa thảo luận không?",
            whatILike: "Điều tôi thích",
            whatIDislike: "Những gì tôi không thích",
            saveMessage: "Tuyệt vời, cảm ơn bạn đã lên kế hoạch cho ngôi nhà của bạn! Bạn có muốn thêm một cái khác không?",
            homeOptions: {
              singleStory: "Một câu chuyện",
              apartment: "Căn hộ, chung cư",
              duplex: "Song công",
              mobileHome: "Nhà di động",
              tinyHome: "Ngôi nhà nhỏ",
            },
            amenities: {
              laundry: "Giặt ủi",
              internet: "Mạng không dây",
              recreation: "Cơ sở Giải trí",
              garden: "Sân vườn",
              deck: "Bộ bài",
              patio: "Sân trong",
              land: "Đất",
              ramp: "Con dốc",
              pool: "Hồ bơi",
              bigDoors: "Cửa lớn",
            },
            proximities: {
              shopping: "Mua sắm",
              bus: "Điểm dừng xe buýt",
              friends: "Bạn bè",
              restaurants: "Nhà hàng",
              entertainment: "Giải trí",
              publicTransit: "Phương tiện công cộng",
              family: "Gia đình",
            },
            summary: {
              wantToLive: "Nơi tôi muốn sống",
              wantToLiveAnswer: "{{place}} trong {{homeType}}",
              homeAmenities: "Trong ngôi nhà lý tưởng của tôi, tôi muốn",
              otherAmenities: "Những thứ khác trong ngôi nhà lý tưởng của tôi",
              homeCloseTo: "Tôi muốn nhà của tôi gần",
              roommateName: "Tôi muốn sống cùng",
              liveAlone: "Tôi muốn sống một mình",
              roommateBecause: "bởi vì {{why}}",
              roommateQuality: "Tại sao bạn nghĩ rằng sống với người này sẽ là một sự phù hợp?",
              petType: "Vật nuôi",
              finalThoughts: "Những thứ khác trong ngôi nhà lý tưởng của tôi",
            },
          },
          planningFun: {
            sectionName: "Lập kế hoạch thú vị",
            sectionHeader: "Mục tiêu vui vẻ",
            subHeader: "Hãy bắt đầu vui vẻ nào!",
            goal: "Khi bạn nghĩ về việc lên kế hoạch cho một điều gì đó vui vẻ, bạn muốn làm gì?",
            goalWhy: "Điều gì khiến điều này hấp dẫn bạn? Hay tại sao bạn muốn làm điều này?",
            goalWhen: "Bạn muốn làm điều này khi nào?",
            goalHowOften: "Bạn muốn làm điều này bao lâu một lần?",
            otherPeople: "Có ai mà bạn muốn làm điều này cùng không?",
            otherPeopleWhoCanSupport: "Hãy chọn người có thể hỗ trợ bạn",
            otherPeopleWhereToFind:
              "Vui lòng chọn các nhóm hoặc địa điểm có thể giúp bạn kết nối với những người có chung mối quan tâm",
            needToSave: "Bạn có cần tiết kiệm tiền để làm việc này không?",
            startedSaving: "Bạn đã bắt đầu tiết kiệm chưa?",
            amountToSave: "Bạn vẫn cần tiết kiệm bao nhiêu tiền?",
            attachedPeople: "sợi dây",
            saveMessage: "Nghe có vẻ thú vị! Bạn có muốn thêm một mục tiêu thú vị khác không?",
            asap: "Sớm nhất có thể",
            sixMonths: "Sáu tháng tới",
            oneYear: "Sáu tháng đến một năm",
            twoYears: "1-2 năm",
            twoPlusYears: "2 năm trở lên",
            oneTime: "Sự kiện một lần",
            daily: "Hằng ngày",
            weekly: "Hàng tuần",
            monthly: "Hàng tháng",
            quarterly: "Hàng quý",
            facebook: "Facebook",
            groupMeet: "Gặp mặt nhóm",
            newspaper: "Báo địa phương",
            coffeeShop: "Bảng tin Quán cà phê",
            communityCenter: "Trung tâm cộng đồng",
            summary: {
              goal: "Khi nghĩ đến việc lên kế hoạch cho một điều gì đó thú vị, tôi muốn",
              goalWhy: "Điều này hấp dẫn tôi bởi vì",
              goalWhen: "Tôi muốn làm điều này",
              goalHowOften: "và thường xuyên",
              otherPeople: "Tôi muốn làm điều này với",
              peopleInMyLife: "Những người trong cuộc sống của tôi mà tôi muốn làm điều này cùng với",
              actionSteps: "Các bước hành động:",
              comments: "Bình luận",
              helpMeAchieve: "Điều này sẽ giúp tôi đạt được mục tiêu của mình",
              research: "Nghiên cứu các cách lập kế hoạch cho hoạt động này thông qua",
              needToSave: "Tôi có cần tiết kiệm cho chuyến đi này không?",
              needToSaveYes: "Có, tôi cần tiết kiệm {{amount}}",
            },
          },
          planningTravel: {
            sectionName: "Lập kế hoạch Du lịch",
            subHeader: "Hãy bắt đầu lên kế hoạch cho chuyến phiêu lưu tiếp theo của bạn",
            destination: "Bạn thích đi du lịch ở đâu?",
            travelTo: "Bạn đã đi đến {{location}} trước đây chưa?",
            like: "Bạn thích điều gì về {{location}}",
            appealingWhy: "Điều gì khiến {{location}} hấp dẫn bạn?",
            travelWhen: "Khi nào bạn muốn đến {{location}}?",
            withOthers: "Có ai bạn muốn đi du lịch cùng không?",
            travelWithWho: "Vui lòng chọn người bạn muốn đi cùng",
            toDoAtDestination: "Bạn muốn làm gì khi đến thăm?",
            toSave: "Bạn có cần tiết kiệm tiền để làm việc này không?",
            startedToSave: "Bạn đã bắt đầu tiết kiệm chưa?",
            amountToSave: "Bạn vẫn cần tiết kiệm bao nhiêu tiền?",
            accommodations: "Bạn có nhu cầu về chỗ ở khi đi du lịch không?",
            needsAndWhy: "Có những thứ bạn muốn hoặc cần đi du lịch cùng không? Nếu có, chúng là gì?",
            goal: "Bạn muốn đặt tên cho mục tiêu này là gì?",
            saveMessage: "Cuộc phiêu lưu đang chờ đợi! Bạn có muốn thêm một mục tiêu du lịch khác không?",
            summary: {
              destination: "Tôi muốn đi du lịch tới",
              appealingWhy: "Tại sao tôi muốn đi du lịch ở đây",
              travelWhen: "Tôi muốn đi du lịch ở đây",
              withOthers: "Tôi có muốn đi du lịch với mọi người không",
              withOthersYes: "Có, tôi muốn đi du lịch với {{people}}",
              withOthersAlone: "Tôi muốn đi du lịch một mình",
              toDoAtDestination: "Trong khi tôi đến thăm, tôi muốn",
              accommodations: "Những chỗ ở tôi sẽ cần khi đi du lịch bao gồm",
              needsAndWhy: "Những thứ khác tôi muốn mang theo khi đi du lịch bao gồm",
              needToSave: "Tôi có cần tiết kiệm cho chuyến đi này không?",
              needToSaveYes: "Có, tôi cần tiết kiệm {{amount}}",
            },
          },
          planningWork: {
            sectionName: "Lập kế hoạch Công việc",
            sectionHeader: "Mục tiêu công việc",
            addSubHeader: "Hãy lập kế hoạch cho một tương lai mới",
            whatILiked: "Những gì tôi thích",
            whatIDisliked: "Điều tôi không thích",
            formSubHeader: "Hãy xem những gì bạn thích hoặc không thích về kinh nghiệm làm việc của bạn",
            workGoal: "Mục tiêu công việc của bạn là gì?",
            profession: "Điều gì thu hút bạn đến với nghề này?",
            excites: "Điều gì khiến bạn phấn khích nhất về sự nghiệp này?",
            goalLookLike: "Khi bạn nghĩ về mục tiêu của mình, nó trông như thế nào?",
            salary: "Bạn muốn kiếm bao nhiêu tiền một năm?",
            preferredWorkDays: "Ngày làm việc ưa thích của bạn là gì?",
            preferredWorkHours: "Giờ làm việc ưa thích của bạn là gì?",
            haveNow: "Bạn có gì bây giờ sẽ đưa bạn đến tương lai của bạn?",
            priorExperience: "Kinh nghiệm nào sẽ hỗ trợ bạn tiến tới tương lai của mình?",
            haveReference: "Bạn có biết ai đó trong nghề này không?",
            referenceName: "Họ là ai?",
            referenceWhere: "Họ làm việc ở đâu?",
            inLifeSupport: "Ai trong cuộc đời của bạn có thể hỗ trợ bạn trong mục tiêu này?",
            support: "Có ai khác có thể giúp bạn đạt được mục tiêu của mình không?",
            commit: "Bạn có thể cam kết làm gì để giúp tạo ra tương lai này?",
            placeholderBudgetNeed: "Chèn những gì bạn cần lập ngân sách cho",
            placeholderResponsible: "Chèn tên",
            placeholderHowOften: "Chèn tần suất nó sẽ được thực hiện",
            placeholderImportant: "Chèn lý do tại sao điều này quan trọng",
            workPreferences: 'Sở thích công việc của tôi',
            monday: "Thứ hai",
            tuesday: "Thứ ba",
            wednesday: "Thứ Tư",
            thursday: "Thứ năm",
            friday: "Thứ sáu",
            saturday: "Thứ bảy",
            sunday: "Chủ nhật",
            morning: "Buổi sáng",
            afternoon: "Buổi chiều",
            evening: "Buổi tối",
            overnight: "Qua đêm",
            anytime: "Bất cứ lúc nào",
            saveMessage:
              "Nghe có vẻ là một mục tiêu công việc tuyệt vời! Bạn có muốn thêm một mục tiêu công việc khác không?",
            budgetTable: {
              title: "Bạn cần tiết kiệm tiền để làm gì để đạt được mục tiêu?",
              actionStep: "Bước hành động",
              saveFor: "Tiết kiệm tiền cho",
              saveMoneyFor: "Tôi cần tiết kiệm tiền để làm gì?",
              responsible: "Ai chịu trách nhiệm?",
              howOften: "Bao lâu?",
              important: "Tại sao nó quan trọng?",
              why: "Tại sao?",
              contact: "Tiếp xúc",
              me: "Tôi",
              theyCanSupport: "Họ có thể hỗ trợ tôi trong mục tiêu của tôi",
              thisWillHelpMe: "Điều này sẽ giúp tôi đạt được mục tiêu của mình",
              deleteConfirm: "Bạn có chắc chắn muốn xóa hàng ngân sách này không?",
              deleteSuccess: "Ok, hàng đã bị xóa",
            },
            summary: {
              workGoal: "Mục tiêu của tôi cho công việc",
              profession: "Điều gì thu hút tôi đến với nghề này",
              excites: "Điều làm tôi phấn khích nhất về điều này là",
              goalLookLike: "Khi tôi nghĩ về mục tiêu của mình, nó trông giống như",
              haveNow: "Điều gì sẽ giúp tôi đạt được tương lai của mình",
              preferredWorkDays: "Ngày làm việc ưu tiên",
              preferredWorkHours: "Giờ làm việc ưu tiên",
              salary: "Mức lương mong muốn",
              haveReference: "Bạn có biết ai đó trong nghề này không?",
              referenceName: "Họ là ai?",
              referenceWhere: "Họ làm việc ở đâu?",
              inLifeSupport: "Ai trong cuộc đời của bạn có thể hỗ trợ bạn trong mục tiêu này?",
              support: "Có ai khác có thể giúp bạn đạt được mục tiêu của mình không?",
              commit: "Bạn có thể cam kết làm gì để giúp tạo ra tương lai này?",
            },
          },
          GTKYPage: {
            pageTitle: "Để hiểu bạn hơn",
            GTKYVideo: "Làm quen với bạn",
            likeAdmire: "Thích & ngưỡng mộ",
            peopleInMyLife: "Những người trong cuộc đời tôi",
            thingsIDo: "Những điều tôi thích làm để giải trí",
            favoriteThings: "Những điều yêu thích của tôi",
            myRoutines: "Quy trình của tôi",
            historyMap: "Bản đồ Lịch sử",
            travel: "Đi du lịch",
            viewComments: "Xem nhận xét"
          },
          myRoutines: {
            sectionName: "Quy trình của tôi",
            subHeader: "Hãy cho chúng tôi biết tất cả về quy trình của bạn trông như thế nào",
            videoTitle: "Các quy trình của tôi - Video hữu ích",
            time: "Thời gian",
            noTime: "Không có thời gian",
            day: "Ngày",
            noDay: "Không ngày nào",
            dailyError: "Vui lòng nhập thời gian và mô tả",
            weeklyError: "Vui lòng nhập ngày và mô tả",
            successMessage: "Cảm ơn! Các quy trình của bạn đã được thêm",
            dailyRoutines: "Các thói quen hàng ngày của bạn là gì?",
            weeklyRoutines: "Các thói quen hoặc nghi thức hàng tuần của bạn là gì?",
            dailyPlaceholder: "Thêm điều gì đó bạn làm mỗi ngày và khi bạn làm điều đó",
            weeklyPlaceholder: "Thêm việc bạn làm mỗi tuần",
            dayStickyTitle: "Ngày của tôi",
            weekStickyTitle: "Tuần của tôi",
            deleteMessage: "Bạn có chắc chắn muốn loại bỏ quy trình này không?",
            deleteSuccess: "Được rồi, quy trình đã bị xóa",
            contributor: {
              subHeader: "Hãy cho tất cả chúng tôi biết về thói quen của họ trông như thế nào",
              dailyRoutines: "Các thói quen hàng ngày của họ là gì?",
              weeklyRoutines: "Các thói quen hoặc nghi thức hàng tuần của họ là gì?",
              dailyPlaceholder: "Thêm điều gì đó họ làm mỗi ngày và khi nào họ làm điều đó",
              weeklyPlaceholder: "Thêm việc họ làm mỗi tuần",
              dayStickyTitle: "{{name}}",
              weekStickyTitle: "Tuần của {{name}}",
            },
            days: {
              mon: "Thứ hai",
              tues: "Thứ ba",
              wed: "Thứ Tư",
              thurs: "thứ năm",
              fri: "Thứ sáu",
              sat: "Thứ bảy",
              sun: "Chủ nhật"
            }
          },
          thingsIDo: {
            sectionName: "Những điều tôi thích làm để giải trí",
            subHeader: "Chọn hoạt động của bạn hoặc tạo một hoạt động mới",
            indoors: "Trong nhà",
            outdoors: "Ngoài trời",
            frequencyCard: "Tôi thích làm điều này:",
            likeMostCard: "Điều tôi thích nhất ở hoạt động này:",
            tellUsWhatYouDo: "Hãy cho chúng tôi biết tất cả những gì bạn thích làm để giải trí!",
            indoor: "Trong nhà",
            outdoor: "Ngoài trời",
            community: "Trong cộng đồng của tôi",
            whereToHaveFun: "Bạn thích vui chơi ở đâu?",
            yourActivity: "Bạn thích làm gì?",
            chosenActivity: "Hoạt động bạn đã chọn là",
            howOften: "Bạn thích làm việc này bao lâu một lần?",
            aloneOrWithOthers: "Bạn thích thực hiện hoạt động này một mình hay với những người khác?",
            attachedPeople: "Bạn thích làm điều này với ai?",
            addThingIDo: "Hoạt động mới",
            otherPeopleInvolved: "Có ai khác mà bạn muốn thực hiện hoạt động này cùng không?",
            anyPhotos: "Bạn có hình ảnh vui nhộn nào về {{activity}} muốn chia sẻ không?",
            like: "Bạn thích điều gì nhất về {{activity}}?",
            youSelected: "Hoạt động của bạn:",
            saveMessage: "Thật là một cuộc phiêu lưu! Bạn có muốn thêm một cuộc phiêu lưu khác không?",
            deleteConfirm: "Bạn có chắc chắn muốn xóa hoạt động này không?",
            deleteSuccess: "Hoạt động của bạn đã được xóa thành công",
            daily: "Hằng ngày",
            weekly: "Hàng tuần",
            monthly: "Hàng tháng",
            oncePerYear: "Mỗi năm một lần",
            fewTimes: "Vài lần một năm",
            whenever: "Bất cứ khi nào tôi có thể",
            contributorQuestions: {
              frequencyCard: "Họ thích làm điều này:",
              likeMostCard: "Điều họ thích nhất ở hoạt động này:",
              tellUsWhatYouDo: "Hãy cho chúng tôi biết tất cả những gì họ thích làm để giải trí!",
              community: "Trong cộng đồng của tôi",
              whereToHaveFun: "Họ thích vui chơi ở đâu?",
              yourActivity: "Họ thích làm gì?",
              chosenActivity: "Hoạt động họ đã chọn là",
              howOften: "Họ thích làm điều này bao lâu một lần?",
              aloneOrWithOthers: "Họ thích thực hiện hoạt động này một mình hay với những người khác?",
              attachedPeople: "Họ thích làm điều này với ai?",
              otherPeopleInvolved: "Có ai khác mà họ muốn thực hiện hoạt động này cùng không?",
              anyPhotos: "Họ có hình ảnh vui nhộn nào về {{activity}} mà bạn muốn chia sẻ không?",
              like: "Họ thích điều gì nhất về {{activity}}?",
              youSelected: "Hoạt động của họ:",
              saveMessage: "",
            },
            activities: {
              reading: "Đọc",
              writing: "Viết",
              yoga: "Yoga",
              pilates: "Pilates",
              videoGames: "Trò chơi điện tử",
              cardGames: "Trò chơi bài",
              boardGames: "Trò chơi trên bàn",
              painting: "Bức tranh",
              instruments: "Dụng cụ",
              drawing: "Đang vẽ",
              art: "Mỹ thuật",
              replicaModels: "Mô hình bản sao",
              movies: "Phim",
              tv: "TV",
              movieTheater: "Rạp chiếu phim",
              diningOut: "Ăn ngoài",
              yardSales: "Bán hàng tại bãi",
              antiquing: "Đồ cổ",
              mall: "Đi đến trung tâm mua sắm",
              shopping: "Mua sắm",
              sports: "Các môn thể thao",
              walks: "Đi bộ",
              birdWatching: "Ngắm chim",
              hiking: "Đi bộ đường dài",
              fishing: "Đánh bắt cá",
              gardening: "Làm vườn",
              camping: "Cắm trại",
              boating: "Chèo thuyền",
              botany: "Thực vật học"
            },
            summary: {
              activity: "Những gì tôi muốn làm",
              howOften: "Tôi thích làm hoạt động này",
              withWho: "Tôi thích làm điều này với",
              withWhoAlone: "Tôi làm việc này",
              byMyself: "một mình",
              like: "Điều tôi thích nhất là",
              buttonText: "Làm những gì tôi thích làm cho vui",
            },
          },
          likeAndAdmire: {
            sectionName: "Thích và ngưỡng mộ",
            sectionTitle: "Đầu, Trái tim, Bàn tay",
            subTitle: "Kéo và thả quà tặng của bạn vào danh mục bạn chọn hoặc tạo của riêng bạn",
            videoTitle: "Thích và ngưỡng mộ - Video hữu ích",
            filterAll: "Tất cả những thứ được yêu thích và ngưỡng mộ (Mặc định)",
            filterOthers: "Những gì người khác thích và ngưỡng mộ về tôi",
            filterMine: "Điều tôi thích và ngưỡng mộ về bản thân",
            resetAll: "Đặt lại tất cả",
            addNew: "Thêm từ mới",
            saveMessage: "Làm tốt lắm {{firstName}}, thật tuyệt khi nghe tất cả những điều tuyệt vời về bạn!",
            head: "Cái đầu",
            heart: "Trái tim",
            hand: "Tay",
            headExplanation: "Những món quà của trí óc, khối óc hoặc suy nghĩ; những món quà mà bạn biết",
            heartExplanation: "Những món quà mà bạn cảm thấy gắn bó hoặc đam mê",
            handExplanation: "Những món quà bạn có thể cung cấp một cách vật chất bằng hành động hoặc bằng cách làm",
            resetMessage: "Bạn có chắc chắn muốn đặt lại tất cả không?",
            resetSuccess: "Ok, các mục đã được đặt lại",
            deleteMessage: "Bạn có chắc chắn muốn xóa món quà này không?",
            deleteSuccess: "Quà tặng đã được xóa thành công",
            contributor: {
              subTitle: "Kéo và thả quà của họ vào danh mục bạn chọn hoặc tạo của riêng bạn",
              filterOthers: "Những gì người khác thích và ngưỡng mộ về họ",
              filterMine: "Điều họ thích và ngưỡng mộ về bản thân",
            },
            summary: {
              head: "Cái đầu",
              heart: "Trái tim",
              hand: "Tay",
            },
          },
          peopleInMyLife: {
            sectionName: "Những người trong cuộc đời tôi",
            subHeader: "Thêm một người hoặc nhấp vào tên của người đó để thêm thông tin chi tiết về họ",
            adaptionDisclaimer: "Điều chỉnh bản đồ mối quan hệ",
            acquaintance: "Người quen",
            acquaintances: "Những người quen",
            add: "Thêm người",
            addAsContributor: "Thêm với tư cách là người đóng góp cho kế hoạch của tôi",
            close: "Đóng",
            contributor: "Người đóng góp",
            contributorAdditions: "Bổ sung Người đóng góp",
            coWorker: "Đồng nghiệp",
            coWorkers: "Đồng nghiệp",
            deletePerson: "Bạn có chắc chắn muốn xóa người này khỏi Những người trong cuộc sống của bạn không?",
            edit: "Chỉnh sửa người",
            family: "Gia đình",
            favoritePerson: "Người được yêu thích trong tháng",
            friend: "Bạn",
            friends: "Bạn bè",
            howClose: "Bạn thân thiết với người này đến mức nào?",
            importantWhy: "Tại sao người này lại quan trọng với bạn",
            involvedInSupport: "Có sự hỗ trợ của tôi",
            mapPlace: "Điều này sẽ xác định vị trí của họ trên Bản đồ Mọi người Trong Cuộc sống của Tôi",
            notClose: "Không đóng",
            paidSupport: "Người này có được trả tiền hỗ trợ không?",
            paidSupportShort: "Hỗ trợ có trả phí",
            personIs: "Người này là...",
            relationshipToYou: "Mối quan hệ với bạn",
            removePerson: "Xóa người",
            weAre: "Chúng tôi là...",
            saveMessage: "Tất cả những người trong cuộc đời bạn đã được cứu, làm việc tuyệt vời.",
            me: 'Tôi',
            contributorQuestions: {
              howClose: "Họ thân thiết với người này đến mức nào?",
              importantWhy: "Tại sao người này lại quan trọng với họ",
              mapPlace: "Điều này sẽ xác định vị trí của họ trên Bản đồ Những người Trong Cuộc đời Tôi",
              relationshipToYou: "Mối quan hệ với họ",
              theyAre: "Họ đang...",
              involvedInSupport: "Có sự hỗ trợ của họ",
            },
            dialog: {
              nameError: "Vui lòng nhập tên",
              personIsError: "Vui lòng chọn một lựa chọn",
              emailError: "Phải nhập địa chỉ email cho những người đóng góp tiềm năng",
              emailValidError: "Vui lòng nhập một địa chỉ email hợp lệ",
              sendInvitation: "Gửi lời mời",
            },
          },
          favoriteThings: {
            sectionName: "Những điều yêu thích của tôi",
            subHeader: "Thêm những thứ yêu thích của bạn bằng cách chọn một danh mục trước",
            contributorSubHeader: "Hãy chọn một danh mục để bắt đầu thêm những thứ họ yêu thích",
            viewOnlySubHeader: "Hãy xem những thứ họ yêu thích",
            addDialogTitle: "Thêm một điều yêu thích",
            editDialogTitle: "Chỉnh sửa điều yêu thích của bạn",
            categories: "Thể loại",
            categoryList: "Danh sách chuyên mục",
            myFavorites: "Mục yêu thích của tôi",
            theirFavorites: "Mục yêu thích của họ",
            place: "Nơi",
            placeToEat: "Nơi để ăn",
            food: "Món ăn",
            movie: "Bộ phim",
            book: "Sách",
            videoGame: "Trò chơi điện tử",
            game: "Trò chơi",
            sport: "Thể thao",
            animal: "Động vật",
            music: "Âm nhạc",
            memory: "Kỉ niệm",
            other: "Khác",
            placeLabel: "Nơi yêu thích của bạn để đến",
            placeToEatLabel: "Địa điểm ăn uống yêu thích của bạn",
            foodLabel: "Món ăn ưa thích của bạn",
            movieLabel: "Bộ phim yêu thích của bạn",
            bookLabel: "Cuốn sách yêu thích của bạn",
            videoGameLabel: "Trò chơi điện tử yêu thích của bạn",
            gameLabel: "Trò chơi yêu thích của bạn",
            sportLabel: "Môn thể thao hoặc đội thể thao yêu thích của bạn",
            animalLabel: "Thú cưng hoặc loại động vật yêu thích của bạn",
            musicLabel: "Ban nhạc, bài hát hoặc thể loại yêu thích của bạn",
            memoryLabel: "Một trong những kỷ niệm yêu thích của bạn",
            otherLabel: "Bất kỳ điều gì yêu thích",
            chooseAView: "Chọn một chế độ xem",
            allThings: "Tất cả những điều yêu thích (Mặc định)",
            thingsIAdded: "Những thứ yêu thích tôi đã thêm",
            thingsOthersAdded: "Những gì người khác đã thêm",
            treasureChestAlt: "Rương Kho Báu Vật Yêu Thích",
            deleteConfirm: "Bạn có chắc chắn muốn xóa thứ yêu thích này không?",
            deleteSuccess: "Điều yêu thích đã bị xóa",
            saveAlertText: "Cảm ơn bạn {{name}}, thật là tuyệt khi học về những điều bạn yêu thích!",
            plurals: {
              place: "Vị trí",
              placeToEat: "Nơi để ăn",
              movie: "Phim",
              book: "Sách",
              videoGame: "Trò chơi điện tử",
              game: "Trò chơi",
              sport: "Các môn thể thao",
              animal: "Loài vật",
              memory: "Ký ức",
            }
          },
          travelPlan: {
            sectionName: "Đi du lịch",
            sectionHeader: "Đi du lịch",
            subHeader: "Chia sẻ địa điểm yêu thích của bạn để đi du lịch hoặc nơi nào đó bạn thực sự muốn đến",
            travelLocally: "Bạn thích đi du lịch trong Hoa Kỳ hay Quốc tế?",
            local: "Trong phạm vi Hoa Kỳ",
            international: "Quốc tế",
            frequencyCard: "Tôi thích làm điều này:",
            likeMostCard: "Điều tôi thích nhất ở nơi này:",
            pageSubHeader: "Hãy cho chúng tôi biết tất cả về nơi bạn muốn đi du lịch",
            travelWhere: "Bạn thích đi du lịch ở đâu?",
            likeMost: "Bạn thích điều gì nhất khi đi du lịch đến {{location}}?",
            likeToTravel: "Bạn thích đi du lịch như thế nào?",
            alone: "Bạn thích làm việc này một mình hay với người khác?",
            howOften: "Bạn thích đi du lịch ở đây bao lâu một lần?",
            anyoneElse: "Có ai khác mà bạn muốn đi du lịch cùng không?",
            anyPictures: "Bạn có hình ảnh vui nhộn nào về {{location}} muốn chia sẻ không?",
            deleteMessage: "Bạn có chắc chắn muốn xóa địa điểm này?",
            addTravelPlan: "Điểm đến Du lịch Mới",
            addTravelContributor: "Thêm một địa điểm khác mà bạn nghĩ họ muốn đến",
            attachedPeople: "Bạn thích đi du lịch với ai?",
            boat: "Con thuyền",
            bus: "Xe buýt",
            car: "Xe hơi",
            plane: "Chiếc máy bay",
            train: "Xe lửa",
            saveText: "Thật là một cuộc phiêu lưu! Bạn có muốn thêm một cuộc phiêu lưu khác không?",
            deleteConfirm: "Bạn có chắc chắn muốn xóa hoạt động này không?",
            deleteSuccess: "Hoạt động của bạn đã được xóa thành công",
            contributorQuestions: {
              subHeader: "Chia sẻ địa điểm yêu thích của họ để đi du lịch hoặc nơi nào đó họ thực sự muốn đến",
              frequencyCard: "Họ thích làm điều này:",
              likeMostCard: "Điều họ thích nhất ở nơi này:",
              pageSubHeader: "Hãy cho chúng tôi biết tất cả về nơi họ thích đi du lịch",
              travelLocally: "Họ thích đi du lịch trong Hoa Kỳ hay Quốc tế?",
              travelWhere: "Họ thích đi du lịch ở đâu?",
              likeMost: "Họ thích điều gì nhất khi đi du lịch đến {{location}}?",
              likeToTravel: "Họ thích đi du lịch như thế nào?",
              alone: "Họ thích làm điều này một mình hay với người khác?",
              howOften: "Họ thích đi du lịch ở đây bao lâu một lần?",
              anyoneElse: "Có ai khác mà họ muốn đi du lịch cùng không?",
              anyPictures: "Họ có bức ảnh vui nhộn nào về {{location}} mà bạn muốn chia sẻ không?",
              attachedPeople: "Họ thích đi du lịch với ai?",
            },
            summary: {
              place: "Tôi thích đi du lịch",
              travelWithSolo: "Tôi thích đi du lịch ở đây",
              byMyself: "Một mình",
              travelWith: "Tôi thích đi du lịch ở đây với",
              frequency: "Tôi đi đến đó",
              frequencyAnswer: "{{frequency}} và tôi đi du lịch bằng {{transportation}}",
              favoriteThing: "Điều yêu thích của tôi khi đi du lịch đến {{place}} là",
              buttonText: "Đi chơi",
            },
          },
          historyMap: {
            sectionHeader: "Bản đồ lịch sử của tôi",
            subHeader: "Hãy cho chúng tôi biết về những cột mốc, ngày kỷ niệm hoặc sự kiện quan trọng trong cuộc đời bạn",
            addMilestone: "Thêm cột mốc",
            editMilestone: "Chỉnh sửa cột mốc",
            sectionName: "Bản đồ Lịch sử",
            category: "Loại",
            familyLife: "Đời sống gia đình",
            school: "Trường học",
            activities: "Các hoạt động",
            friends: "Bạn bè",
            housing: "Nhà ở",
            work: "Công việc",
            relationships: "Các mối quan hệ",
            chooseDate: "Chọn một ngày",
            milestone: "Cột mốc",
            details: "Thông tin chi tiết",
            back: "Quay lại làm quen với bạn",
            saveMessage: "Thật tuyệt khi biết được tất cả về bạn, cảm ơn bạn đã chia sẻ!",
            validDateError: "Vui lòng nhập một ngày hợp lệ",
            rangeDateError: "Ngày nằm ngoài phạm vi",
            deleteMessage: "Bạn có chắc chắn muốn xóa cột mốc này không?",
            deleteSuccess: "Cột mốc quan trọng của bạn đã được xóa thành công",
          },
          contributor: {
            addToRelationshipMap: "Thêm vào Bản đồ Mối quan hệ của tôi",
            thisPersonIs: "Người này là một ....",
            howClose: "Bạn thân thiết với người này đến mức nào?",
            close: "Đóng",
            notClose: "Không đóng",
            setPermissions: "Đặt quyền cộng tác viên",
            hasBeenEmailed: "Một lời mời đã được gửi đến cộng tác viên của bạn",
          },
          happeningPage: {
            pageTitle: "Đang xảy ra trong cuộc sống của tôi",
            living: "Sống trong nhà tôi",
            work: "Công việc",
            school: "Trường học",
            health: "Sức khỏe và an toàn",
            happeningVideo: "Video xảy ra trong cuộc sống của tôi",
          },
          happeningHome: {
            pageTitle: "Sống Trong Nhà Tôi",
            subTitle: "Hãy cùng tìm hiểu thêm về nơi bạn sống!",
            singleStory: "Trang chủ một câu chuyện",
            multiLevel: "Nhà nhiều tầng",
            apartment: "Căn hộ, chung cư",
            facility: "Cơ sở",
            homeless: "Vô gia cư",
            groupHome: "Nơi sinh hoạt cộng đồng",
            car: "Xe hơi",
            rvCamper: "RV / Camper",
            currentlyLive: "Hiện tại bạn đang sống ở đâu?",
            currentlyLiveWith: "Bạn hiện đang sống với ai?",
            parents: "Cha mẹ",
            guardians: "Người giám hộ",
            siblings: "Anh chị em ruột",
            friends: "Bạn bè",
            roommates: "Bạn cùng phòng",
            spouse: "Vợ chồng",
            children: "Trẻ em / Trẻ em",
            alone: "Tôi sống một mình",
            dogs: "Chó",
            cats: "Những con mèo",
            other: "Khác, vui lòng giải thích",
            explain: "Vui lòng giải thích",
            tableTitle: "Bạn thích và không thích điều gì ở nơi bạn sống?",
            amenityHeader: "Tiện nghi",
            selectHeader: "Vui lòng chọn thích hoặc không thích",
            commentHeader: "Bình luận",
            commentsLikes: "Nhận xét về lượt thích của tôi",
            commentsDislikes: "Nhận xét về những điều tôi không thích",
            tellUsWhy: "Hãy cho chúng tôi biết lý do tại sao ở đây",
            whatILike: 'Điều tôi thích ở nơi tôi hiện đang sống',
            whatIDislike: 'Những gì tôi không thích về nơi tôi hiện đang sống',
            amenities: {
              publicTransportation: "Phương tiện công cộng",
              accessibility: "Khả năng tiếp cận",
              neighborhood: "Khu vực lân cận",
              friendsFamily: "Gần gũi với bạn bè và gia đình",
              shopping: "Gần mua sắm",
              furniture: "Đồ nội thất",
              homeType: "Kiểu nhà",
              squareFootage: "Đoạn Phim Vuông",
              housemates: "Bạn cùng nhà",
              livingAlone: "Sống một mình",
              neighbors: "Người hàng xóm",
              management: "Quản lý tài sản",
              support: "Nhân viên hỗ trợ",
              tellUsWhy: "Vui lòng cho chúng tôi biết lý do tại sao",
            },
            summary: {
              currentlyLive: "Nơi tôi sống",
              currentlyLiveWith: "Tôi sống với ai",
            },
          },
          happeningSchool: {
            header: "Trường học",
            subHeader:
              "Chúng tôi rất nóng lòng được nghe thêm về trường học, hãy kể cho chúng tôi nghe về trải nghiệm của bạn",
            enrolledInSchool: "Bạn hiện đang đi học?",
            schoolNameOOS: "Những trường bạn đã đi đến?",
            gradeLevelOOS: "Bạn học xong lớp mấy?",
            gradYearOOS: "Bạn tốt nghiệp khi nào?",
            schoolLikeOOS: "Bạn thích điều gì nhất ở trường?",
            schoolDislikeOOS: "Bạn không thích điều gì ở trường?",
            volunteerOOS: "Khi còn đi học, bạn có tình nguyện ở đâu không?",
            volunteerWhereOOS: "Bạn đã tình nguyện ở đâu?",
            volunteerDislikeOOS: "Bạn không thích điều gì?",
            volunteerLikeOOS: "Bạn thích điều gì về hoạt động tình nguyện?",
            graduationGoalOOS: "Nó là cái gì vậy?",
            gradYearOtherOOS: "",
            goalYesOOS: "Có điều gì bạn muốn làm sau khi tốt nghiệp không?",
            schoolName: "Bạn đi học trường nào?",
            gradeLevel: "Bạn học lớp mấy?",
            gradYear: "Khi nào bạn học xong?",
            schoolLike: "Bạn thích điều gì nhất ở trường?",
            schoolDislike: "Bạn không thích điều gì ở trường?",
            volunteer: "Bạn có tình nguyện ở đâu không?",
            volunteerWhere: "Bạn tình nguyện ở đâu?",
            volunteerDislike: "Bạn không thích cái gì?",
            volunteerLike: "Bạn thích điều gì về hoạt động tình nguyện?",
            graduationGoal: "Nó là gì?",
            gradYearOther: "",
            goalYes: "Có điều gì bạn muốn làm sau khi tốt nghiệp không?",
            summary: {
              schoolName: "Ngôi trường tôi đến có tên là",
              schoolNameOOS: "Ngôi trường tôi đã đến được gọi là",
              gradeLevel: "Tôi đang học lớp",
              gradeLevelOOS: "Tôi đã học xong",
              gradYear: "Tôi sẽ học xong trong",
              gradYearOOS: "Tôi đã hoàn thành việc học ở trường",
              schoolLike: "Điều tôi thích ở trường học",
              schoolLikeOOS: "Điều tôi thích ở trường học",
              schoolDislike: "Điều tôi không thích ở trường học",
              schoolDislikeOOS: "Điều tôi không thích ở trường học",
              volunteerWhere: "Tôi tình nguyện ở trường",
              volunteerWhereOOS: "Tôi tình nguyện ở trường",
              volunteerLike: "Điều tôi thích về hoạt động tình nguyện",
              volunteerLikeOOS: "Điều tôi thích về hoạt động tình nguyện",
              volunteerDislike: "Điều tôi không thích về hoạt động tình nguyện",
              volunteerDislikeOOS: "Điều tôi không thích về hoạt động tình nguyện",
              graduationGoal: "Khi tôi tốt nghiệp, tôi muốn",
              graduationGoalOOS: "Khi tôi tốt nghiệp, tôi muốn",
            },
          },
          happeningWork: {
            sectionName: "Kinh nghiệm làm việc",
            subHeader: "Hãy cho chúng tôi biết thêm về kinh nghiệm làm việc của bạn, chúng tôi rất nóng lòng được nghe!",
            pastExperience: "Hãy chia sẻ kinh nghiệm làm việc trong quá khứ của bạn",
            pastExperienceLike: "Bạn thích điều gì về kinh nghiệm làm việc trước đây của mình?",
            pastExperienceDislike: "Bạn không thích điều gì về kinh nghiệm làm việc trước đây của mình?",
            hasCurrentEmployment: "Có phải bạn đang làm việc?",
            currentEmployment: "Bạn hiện đang làm việc ở đâu?",
            currentDuration: "Bạn làm việc ở đó bao lâu rồi?",
            currentLike: "Bạn thích điều gì về công việc hiện tại của mình?",
            currentDislike: "Bạn không thích điều gì ở công việc hiện tại của mình?",
            summary: {
              pastExperience: "Nơi tôi đã làm việc trong quá khứ",
              pastExperienceLike: "Điều tôi thích ở nơi tôi đã làm việc trước đây",
              pastExperienceDislike: "Điều tôi không thích về nơi tôi đã làm việc trong quá khứ",
              currentEmployment: "Hiện tại tôi đang làm việc tại",
              currentDuration: "Tôi đã làm việc ở đây cho",
              currentLike: "Điều tôi thích ở nơi tôi làm việc",
              currentDislike: "Điều tôi không thích ở nơi tôi làm việc",
            },
          },
          happeningHealth: {
            header: "Sức khỏe và an toàn",
            subHeader: "Hãy tìm hiểu bạn nhiều hơn một chút, hãy chia sẻ những gì bạn cảm thấy thoải mái",
            contributorAdditionsToApprove:
              "Những người đóng góp của bạn đã thêm DME và / hoặc Hỗ trợ vào kế hoạch của bạn. Bạn có muốn phê duyệt chúng không?",
            medInfo: "Có điều gì liên quan đến sức khỏe ảnh hưởng đến cuộc sống của bạn không?",
            allergies: "Vui lòng liệt kê các bệnh dị ứng mà bạn muốn chúng tôi biết",
            dmeSectionTitle: "Bạn sử dụng loại Thiết bị Y tế Bền và hỗ trợ nào?",
            dmeContributorTitle: "Họ sử dụng loại Thiết bị Y tế Bền và hỗ trợ nào?",
            sectionSubTitle: "Chọn một nút để thêm nó vào danh sách của bạn, nhấp vào 'x' trong danh sách của bạn để xóa nó",
            supportsSectionTitle: "Làm thế nào những người khác có thể hỗ trợ bạn trong việc cảm thấy và giữ gìn sức khỏe?",
            supportContributorTitle:
              "Làm thế nào những người khác có thể hỗ trợ họ trong việc cảm thấy và giữ gìn sức khỏe?",
            dmePostItTitle: "Hỗ trợ tốt trông như thế nào",
            supportPostItTitle: "Làm thế nào những người khác có thể hỗ trợ tôi",
            dmeOptions: {
              bloodSugarMonitors: "Máy theo dõi lượng đường trong máu",
              bloodSugarTestStrips: "Que thử đường huyết",
              canes: "Canes",
              commodeChairs: "Ghế đi làm",
              continuousPassiveDevices: "Thiết bị chuyển động thụ động liên tục",
              continuousPassiveAirwayPressureDevices: "Thiết bị áp suất đường thở dương liên tục (CPAP)",
              crutches: "Nạng",
              hospitalBeds: "Giường bệnh",
              infusionPumps: "Máy bơm và vật tư truyền dịch",
              lancetDevices: "Thiết bị và lưỡi mác",
              nebulizerMedications: "Máy phun sương & thuốc phun sương",
              oxygenEquipment: "Thiết bị & phụ kiện oxy",
              patientLifts: "Thang máy dành cho bệnh nhân",
              supportSurfaces: "Bề mặt hỗ trợ giảm áp suất",
              suctionPumps: "Máy bơm hút",
              tractionEquipment: "Thiết bị kéo",
              walkers: "Người đi bộ",
              rollator: "Cuộn giấy",
              wheelchairMotorized: "Xe lăn - có động cơ",
              wheelchairManual: "Xe lăn - sách hướng dẫn",
              wheelchairStanding: "Xe lăn - Ngả / Đứng",
              scooterMotorized: "Xe tay ga - Có động cơ",
              scooterManual: "Xe tay ga - Hướng dẫn sử dụng",
              assistiveTech: "Công nghệ hỗ trợ",
              translator: "Người dịch",
              brailleKeyboard: "Bàn phím chữ nổi",
              hearingAid: "Trợ thính",
            },
            supportOptions: {
              cookMeals: "Nấu bữa ăn của tôi",
              prepareMeals: "Giúp tôi chuẩn bị bữa ăn của tôi",
              prepareMedication: "Giúp tôi chuẩn bị thuốc",
              medicationSchedule: "Giúp tôi tạo lịch dùng thuốc",
              doctorTransportation: "Giúp tôi đến và đi từ bác sĩ",
              createBudget: "Giúp tôi tạo ngân sách",
              beActive: "Giúp thúc đẩy tôi hoạt động",
            },
            summary: {
              medInfo: "Tình trạng sức khỏe mà tôi muốn người khác biết bao gồm",
              allergies: "Các bệnh dị ứng mà tôi muốn người khác biết bao gồm",
              dmeSectionTitle: "Hỗ trợ Y tế lâu bền mà tôi sử dụng bao gồm",
              supportsSectionTitle: "Những người trong cuộc sống của tôi có thể hỗ trợ tôi theo những cách sau đây",
            },
          },
          happeningGeneral: {
            sectionSave:
              "Cảm ơn bạn về thông tin {{userFirstName}}, bạn có thể chuyển sang phần khác của Điều gì đang xảy ra trong cuộc sống của bạn!",
          },
          planSummary: {
            generatePdf: "tải PDF",
            downloadPdf: "Tạo PDF",
            selectTravelPlan: "Chọn kế hoạch du lịch của bạn",
            selectActivity: "Chọn hoạt động của bạn",
            selectGoal: "Chọn một mục tiêu",
            sections: {
              importantToMe: "Điều gì là quan trọng đối với tôi",
              importantForMe: "Điều gì là quan trọng đối với tôi",
              goals: "Lập kế hoạch Mục tiêu",
            },
            cardTitles: {
              likeAdmire: "Những gì mọi người thích và ngưỡng mộ về tôi",
              people: "Những người quan trọng trong cuộc đời tôi",
              milestones: "Các cột mốc quan trọng trong cuộc đời tôi",
              favoriteThings: "Một vài trong số những điều yêu thích của tôi",
              thingsIDo: "Tôi thích làm gì để giải trí",
              travelPlan: "Những nơi tôi muốn đi du lịch",
              dailyRoutines: "Thói quen hàng ngày của tôi",
              weeklyRoutines: "Quy trình hàng tuần của tôi",
              happeningHome: "Sống Trong Nhà Tôi",
              happeningWork: "Kinh nghiệm làm việc của tôi",
              happeningSchool: "Trải nghiệm trường học của tôi",
              happeningHealth: "Sức khỏe & An toàn của tôi",
              homeGoals: "Mục tiêu về Nhà ở của tôi",
              workGoals: "Mục tiêu công việc của tôi",
              healthGoals: "Mục tiêu sức khỏe của tôi",
              funGoals: "Mục tiêu thú vị của tôi",
              relationshipGoals: "Mục tiêu mối quan hệ của tôi",
              travelGoals: "Mục tiêu du lịch của tôi",
            },
            cardDescriptions: {
              likeAdmire: "Dưới đây là những điều tôi thích và ngưỡng mộ về bản thân và những điều mà những người đóng góp của tôi đánh giá cao về tôi",
              people: "Đây là những người quan trọng nhất trong cuộc đời tôi.",
              milestones: "Khám phá tất cả các cột mốc quan trọng trong cuộc đời mà tôi tự hào nhất!",
              favoriteThings: "Đây chỉ là một vài trong số những điều yêu thích của tôi",
              thingsIDo: "Đây là một số điều tôi muốn làm để giải trí!",
              travelPlan: "Tìm hiểu nơi tôi thích đi du lịch, với ai và những gì tôi thích đi du lịch cùng",
              dailyRoutines: "Khám phá điều gì quan trọng đối với tôi mỗi ngày và điều gì tạo nên một ngày tốt lành!",
              weeklyRoutines: "Tìm hiểu thêm về sở thích, nghi lễ và thói quen của tôi!",
              happeningHome: "Điều quan trọng là tôi phải chia sẻ hoàn cảnh sống hiện tại của mình, những gì tôi thích và không thích về nó.",
              happeningWork: "Tìm hiểu thêm về những kinh nghiệm trong quá khứ của tôi với công việc, những gì tôi thích và không thích về nó!",
              happeningSchool: "Tìm hiểu thêm một chút về tôi và kinh nghiệm của tôi ở trường!",
              happeningHealth: "Dưới đây là những gì tôi cảm thấy thoải mái khi chia sẻ về sức khỏe, sự an toàn của mình và những gì người khác có thể làm để hỗ trợ tôi.",
              homeGoals: "Tìm hiểu thêm về nơi tôi muốn sống",
              workGoals: "Tìm hiểu về công việc mơ ước của tôi",
              healthGoals: "Tìm hiểu cách tôi muốn lập kế hoạch cho sức khỏe của mình",
              funGoals: "Tìm hiểu xem tôi muốn làm gì để giải trí trong tương lai",
              relationshipGoals: "Tìm hiểu thêm về cách tôi muốn kết nối với những người khác",
              travelGoals: "Tìm ra nơi tôi hy vọng sẽ đi du lịch",
            },
            noContent: {
              milestones: "Không có sự kiện quan trọng nào được thêm vào. Nhấn vào nút bên dưới để bắt đầu!",
              favoriteThings: "",
              thingsIDo: "Không có điều gì tôi làm vì niềm vui đã được tạo ra. Bấm vào nút bên dưới để tạo một cái!",
              travelPlan: "Không có Địa điểm để Du lịch nào được tạo. Bấm vào nút bên dưới để tạo một cái!",
              dailyRoutines: "Không có quy trình hàng ngày nào được thêm vào. Nhấn vào nút bên dưới để bắt đầu!",
              weeklyRoutines: "Không có quy trình hàng tuần nào được thêm vào. Nhấn vào nút bên dưới để bắt đầu!",
              happeningHome: "Không có thông tin Sống trong Nhà của Tôi đã được ghi lại. Nhấn vào nút bên dưới để bắt đầu!",
              happeningWork: "Không có thông tin Kinh nghiệm làm việc nào được ghi lại. Nhấn vào nút bên dưới để bắt đầu!",
              happeningSchool: "Không có thông tin trường học đã được ghi lại. Nhấn vào nút bên dưới để bắt đầu!",
              happeningHealth: "Không có thông tin Sức khỏe & An toàn đã được ghi lại. Nhấn vào nút bên dưới để bắt đầu!",
              homeGoals: "Không có mục tiêu Nhà ở nào được tạo. Bấm vào nút bên dưới để tạo một cái!",
              workGoals: "Không có mục tiêu công việc nào được tạo. Bấm vào nút bên dưới để tạo một cái!",
              healthGoals: "Không có mục tiêu Sức khỏe nào được tạo. Bấm vào nút bên dưới để tạo một cái!",
              funGoals: "Không có mục tiêu Vui vẻ nào đã được tạo. Bấm vào nút bên dưới để tạo một cái!",
              relationshipGoals: "Không có Mục tiêu Mối quan hệ nào được tạo. Bấm vào nút bên dưới để tạo một cái!",
              travelGoals: "Không có mục tiêu Du lịch nào được tạo. Bấm vào nút bên dưới để tạo một cái!",
            },
          },
          contributorGeneric: {
            entries: "Bài viết của Người đóng góp",
            noAccess: "Bạn không có quyền truy cập vào phần này",
            includedByPlanOwner: 'Bao gồm bởi chủ sở hữu kế hoạch',
            notIncluded: "Không được chủ sở hữu gói bao gồm",
            sectionNotComplete: "Chủ kế hoạch chưa hoàn thành phần này",
            thankYou: "Cảm ơn bạn đã đóng góp! Các bổ sung của bạn đã được gửi đến chủ sở hữu kế hoạch để phê duyệt",
          },
          contributorComments: {
            addComment: "Thêm nhận xét",
            allComments: "Tất cả nhận xét",
            commentPrompt:
              "Bạn có điều gì muốn nhận xét dựa trên những gì bạn thấy không? Hãy để lại cho họ một bình luận hoặc ghi chú thú vị!",
            yourComment: "Bình luận của bạn",
            approved: "Nhận xét được chấp thuận",
            pendingApproval: "Cảm ơn bạn đã đóng góp! Nhận xét của bạn đã được gửi đến chủ sở hữu kế hoạch để phê duyệt",
            pendingNoThankYou: "Nhận xét của bạn đã được gửi đến chủ sở hữu kế hoạch để phê duyệt",
            comments: "Bình luận",
            toCheck: 'để kiểm tra',
            deleteConfirm: 'Bạn có chắc mình muốn xóa bình luận này chứ?',
            deleteSuccess: "Ok, nhận xét đã bị xóa",
            notApprovedMessage: 'Nhận xét của bạn đã không được chủ sở hữu kế hoạch chấp thuận. Bạn có thể chỉnh sửa nhận xét của bạn và gửi lại nó, hoặc xóa bình luận của bạn.',
            notIncluded: 'Không bao gồm',
          },
          durationDropdown: {
            notSelected: "Không được chọn",
            zeroMonths: "0 - 6 tháng",
            sixMonths: "6 - 12 tháng",
            oneYear: "1 - 2 năm",
            twoYears: "2 năm trở lên",
          },
          navigationButtons: {
            gtky: "Quay lại làm quen với bạn",
            thingsIDo: "Quay lại những việc tôi làm",
            happening: "Quay trở lại diễn ra trong cuộc sống của tôi",
            planningFun: "Quay lại Lập kế hoạch Vui vẻ",
            planningHealth: "Trở lại Kế hoạch Sức khỏe",
            planningHome: "Quay lại Trang chủ Lập kế hoạch",
            planningRelationships: "Trở lại Lập kế hoạch Mối quan hệ",
            planningTravel: "Quay lại Lập kế hoạch Du lịch",
            planningWork: "Quay lại công việc lập kế hoạch",
          },
          generic: {
            add: "Cộng",
            addAnother: "Thêm cái khác",
            addComment: "Thêm nhận xét",
            addNew: "Thêm mới",
            addNewEntry: "Thêm mục nhập mới",
            addYourOwn: "Thêm của riêng bạn",
            addedBy: 'Được thêm bởi',
            addressPrimary: "Địa chỉ 1",
            addressSecondary: "Thượng hạng, Số nhà, Đơn vị #",
            alone: "Một mình",
            approve: "Chấp thuận",
            back: "Mặt sau",
            backToDashboard: "Quay lại bảng điều khiển kế hoạch",
            cancel: "Hủy bỏ",
            cancelled: "Đã hủy",
            city: "Thành phố",
            clear: "Xa lạ",
            country: "Quốc gia",
            comments: "Nhận xét của Người đóng góp",
            createdBy: "Được tạo bởi",
            createdOn: "Được tạo ra",
            dailyWithCapital: "Hằng ngày",
            date: "Ngày",
            delete: "Xóa bỏ",
            deleteGoalConfirm: "Bạn có chắc chắn muốn xóa mục tiêu này không?",
            deleteGoalSuccess: "Mục tiêu của bạn đã được xóa thành công",
            details: "Thông tin chi tiết",
            disapprove: "Không chấp thuận",
            dislike: "không thích",
            edit: "Chỉnh sửa",
            editGoal: "Chỉnh sửa Mục tiêu",
            email: "E-mail",
            emailWithCapitial: "E-mail",
            error: 'Lỗi',
            faceTime: "FaceTime",
            firstName: "Họ",
            addGeneralComment: "Nhấp để thêm nhận xét chung",
            generalComments: "nhận xét chung",
            generalComment: "Nhấp để thêm nhận xét chung",
            goal: "Mục tiêu",
            goToItem: "Tới mục",
            goToGoal: "Tới mục tiêu",
            goToSection: "Đi tới {{section}}",
            inPerson: "Mặt đối mặt",
            instructionalVideo: "Video hướng dẫn",
            language: "Tùy chọn Ngôn ngữ",
            lastName: "Họ",
            like: "giống",
            loading: "Đang tải...",
            middleName: "Tên đệm",
            name: "Tên",
            newAdditions: "Những người đóng góp đã có những bổ sung mới",
            no: "Không",
            noNotifications: "Không có thông báo",
            noPeopleMessage: "Thêm bạn bè và gia đình vào People In My Life của bạn để chọn họ ở đây!",
            noThanks: "Không cám ơn",
            okay: "ĐƯỢC RỒI",
            other: "Khác",
            pleaseExplain: "Vui lòng giải thích",
            phoneNumber: "Số điện thoại",
            remove: "Loại bỏ",
            removeImage: "Xóa hình ảnh",
            review: "Kiểm tra lại",
            save: "Tiết kiệm",
            saveAndSendUpdates: "Lưu và gửi cập nhật",
            saveAndSendInvite: "Lưu & Gửi lời mời",
            saveAnother: "Lưu & Thêm cái khác",
            saveNoUpdates: "Không gửi cập nhật",
            search: "Tìm kiếm",
            selectAGoal: "Chọn một mục tiêu",
            state: "Tiểu bang",
            submit: "Nộp",
            success: "Thành công",
            talking: "Đang nói",
            thankYou: 'Cảm ơn',
            title: "Tiêu đề",
            uploadImage: "Tải lên ảnh",
            viewComment: "Xem bình luận",
            weeklyWithCapitial: "Hàng tuần",
            with: 'với',
            withOthers: "Với những người khác",
            yes: "Đúng",
            zip: "Mã Bưu Chính",
            include: "Bao gồm",
            notInclude: "Không bao gồm",
            approveAll: "Phê duyệt tất cả",
            denyAll: "Phủ nhận tất cả",
            removeFromPlan: "Xóa khỏi kế hoạch",
            said: "nói",
            errors: {
              errorPageTitle: 'Hiện đã có một lỗi!,',
              errorPageSubTitle: 'Xin lỗi vì điều đó. Vui lòng quay lại sau.,',
              error404: 'Lôi 404 Không Tim Được Trang,',
              saveError: 'Xin lỗi, đã có một lỗi tiết kiệm mục nhập của bạn. Vui lòng thử lại sau.,',
              deleteError: 'Xin lỗi, đã có một lỗi xóa mục nhập của bạn. Vui lòng thử lại sau.,',
              createPlanError: 'Xin lỗi, đã có một lỗi tạo ra kế hoạch của bạn. Vui lòng thử lại sau.',              
            },
          },
        },
      },
      hmong: {
        translation: {
          contact: {
            contactUs: 'Tiv tauj peb',
            message: 'Xov xwm',
            send: 'Xa',
            sayHello: 'Hais nyob zoo',
            saveTitle: 'Koj email tau xa tiav lawm!',
            saveMessage: 'Ib tus neeg sawv cev ntawm Center On Me yuav hu rau koj sai sai.',
            emailError: 'Thov sau email chaw nyob uas siv tau',
            phoneError: 'Thov sau tus lej xov tooj siv tau',
          },
          password: {
            lowercase: "Muaj ib tsab ntawv me",
            uppercase: "Muaj ib tsab ntawv loj",
            eightCharacters: "Yam tsawg kawg yog 8 cim ntev",
            number: "Muaj tus lej",
            specialCharacter: "Muaj cov cim tshwj xeeb",
            passwordsMatch: "Cov passwords sib phim",
            errorCannotMatch: "Old Password thiab New Password tsis tuaj yeem sib xws",
          },
          saveProgress: {
            uploadPhoto: 'Uploading Duab',
            updateProfile: 'Hloov kho koj qhov profile',
          },
          agreements: {
            pleaseRead: "Tiv thaiv koj cov ntaub ntawv yog peb qhov tseem ceeb. Thov nyeem peb",
            privacyTitle: "Website Privacy",
            termsTitle: "Cov ntsiab lus ntawm kev siv",
            ifYouAgree: `Yog tias koj pom zoo, sau koj Lub Npe thiab Lub Xeem thiab nyem "Kuv Pom Zoo"`,
          },
          settings: {
            pageTitle: "Chaw",
            subTitle: "Koj qhov chaw los kho koj txoj kev npaj raws li koj xav tau!",
            saveSettings: "Txuag chaw",
            planVideo: "Kuv Txoj Kev Npaj Video",
            contributorPage: "Manage Contributors",
            colorPalette: "Kuv Xim Palette",
            changePassword: {
              changePassword: "Hloov Password",
              subTitle: "Cia peb hloov tus password!",
              currentPassword: "Tam sim no Password",
              newPassword: "Tus password tshiab",
              confirmPassword: "paub meej tus password",
              currentIncorrect: "Tus password tam sim no tsis raug",
              samePasswordError: "Woops, thov siv tus password tshiab",
              successMessage: "Hloov Password!"
            },
            colorPalettePage: {
              pageTitle: "Xim Palette",
              subTitle: "Xaiv cov xim uas koj xav pom thaum tsim koj txoj kev npaj!",
              myColorPalette: "Kuv Xim Palette",
              select: "Xaiv cov xim palette uas koj nyiam",
              brainstormBlue: "tswvyim xiav",
              planningPurple: "Kev npaj hauv Ntshav",
              organizingOrange: "Organizing Txiv kab ntxwv",
              gamePlanGreen: "kev ua si npaj ntsuab",
              primaryColorTooltip: "Cov ntawv nyeem, khawm, thiab Icons",
              secondaryColorTooltip: "taub hau, xaiv khawm, rooj",
              contributorColorTooltip: "Cov neeg koom tes nkag",
              textColorTooltip: "Ntawv nyeem",
              saveMessage: "Koj Cov Xim Palette tau hloov kho!"
            },
            manageContributors: {
              sectionHeader: "Tswj kuv cov neeg koom tes thiab cov ntawv ceeb toom",
              subHeader: "Nyem rau ntawm daim npav koom nrog los teeb lossis hloov kho lawv qhov kev tso cai",
              myContributors: "Kuv Cov Neeg Koom Tes",
              dialogConfirm: "Puas Txhua Yam Zoo Zoo?",
              dialogTitle: "Cov ntaub ntawv pab txhawb nqa",
              inviteSuccess: "Ib qho kev caw tau xa mus rau koj tus neeg koom tes",
              updateSuccess:
                "Cov ntaub ntawv tus neeg koom tes tau hloov kho thiab xa email mus ceeb toom rau tus neeg koom nrog",
              updateSuccessNoEmail: "Cov ntaub ntawv koom nrog tau raug hloov kho!",
              lastContribution: "Kev pab zaum kawg:",
              noContributions: "Tsis muaj kev koom tes tseem",
              addContributor: "Ntxiv ib tus neeg koom tes",
            },
            contributorNotifications: {
              myNotifications: "Kuv Cov Lus Qhia",
              letsSetPreferece: "Cia peb teeb koj nyiam",
              howToNotify: "Thaum ib tus neeg koom nrog ntxiv rau koj txoj kev npaj, koj xav kom ceeb toom li cas?",
              howOften: "Koj xav ceeb toom ntau npaum li cas?",
              saveAlert: "Koj cov ntawv ceeb toom tau hloov kho lawm",
            },
            managementPage: {
              sectionHeader: "Kuv Cov Neeg Koom Tes",
              noContributorsContext:
                "Koj tuaj yeem tso cai rau cov neeg koom nrog rau ntau ntu ntawm koj txoj kev npaj. Lawv tuaj yeem hais lus thiab lwm yam ntxiv rau cov ntu ntawd!",
            },
            profile: {
              pageTitle: "Npe & Paub meej",
              sectionHeader: "Zoo siab txais tos rau Center rau Kuv",
              subHeader: "Thov siv sijhawm los qhia peb me ntsis txog koj tus kheej",
              profilePicture: 'Daim duab profile',
              name: "Npe",
              languageAndNumber: "Lus & Xov tooj",
              address: "Chaw Nyob",
              firstNameError: "Npe npe yuav tsum tau",
              lastNameError: "Lub xeem yuav tsum tau",
              phoneError: "Yuav tsum muaj tus xov tooj siv tau",
              saveConfirmationMsg: "Qhov no puas zoo lawm?",
              backToSettings: "Rov qab mus rau Chaw",
              backToPlans: "Rov qab rau Kuv Txoj Kev Npaj",
              saveMessage: "Ua tsaug rau kev ntxiv koj cov ntaub ntawv!",
              firstTimeSaveMessage: 'Nws yog qhov zoo uas tau los paub koj! Tam sim no, cia peb npaj pib.',
              notQuite: "Tsis Zoo",
              speechToText: 'Hais lus rau cov ntawv nyeem',
              speechToTextEnabled: 'Yog lawm, Kuv xav siv lus hais lus rau cov lus!',
              speechToTextDisabled: 'Tsis yog, Kuv tsis xav siv hais lus los ntawm ntawv.',
              speechToTextWarning: 'Tam sim no tsuas muaj lus Askiv',              
              languages: {
                english: "English",
                armenian: "Armenian",
                chineseSimple: "Suav, Simplified",
                chineseTraditional: "Suav, tsoos",
                farsi: "Farsi",
                hmong: "Hmoob",
                korean: "Korean",
                spanish: "Spanish",
                tagalog: "Tagalog",
                vietnamese: "Vietnam",
              },
            },
            account: {
              pageTitle: 'Chaws ceev nyiaj',
              subTitle: 'Koj qhov chaw los tswj koj cov kev yuav khoom',
              benefitsTitle: 'Cov txiaj ntsig them nyiaj',
              manageTitle: 'Tswj kev teev npe',
              benefits: {
                multiplePlans: 'Tsim ntau txoj kev npaj',
                fullAccess: 'Puv nkag mus rau cov txheej txheem cov nplooj ntawv',
                printablePDF: 'Daim Ntawv Qhia Cov Ntawv PDF tau yooj yim',
                uploadPhotos: 'Muab cov duab caw',
                planVideo: 'Tsim cov phiaj xwm phiaj xwm los qhia rau koj cov neeg txhawb nqa',
                backgroundImage: 'Xaiv cov duab keeb kwm kev cai',
                themeColor: 'Xaiv koj cov ntsiab lus ntsiab lus xim',
              },
              currentPlan: 'Cov phiaj xwm tam sim no',
              trial: '30-hnub mus sib hais',
              subscription: '12-lub hli kev yuav khoom',
              noSubscription: 'Tsis muaj kev them nyiaj tam sim no',
              expirationDate: 'Hnub tag sijhawm',
              expiresOn: 'Koj cov npe yuav tas sijhawm',
              paymentMethod: 'Txoj Kev Them Nyiaj',
              supportContact: 'Txhawb Sib Tham',
              supportContactIs: 'Koj tus neeg sib cuag tau {{contactName}}',
              editSupportContact: 'Kho kom raug kev pab txhawb nqa',
              supportContactLabel: 'Txhawb Sib Tham',
              contactEmailLabel: 'Hu Rau Email Chaw Nyob',
              contactPhoneLabel: 'Hu rau tus xov tooj',
              saveSupportMessage: 'Koj txoj kev sib cuag tau raug hloov kho tshiab.',
              supportContactError: 'Kev txhawb nqa yuav tsum',
              contactEmailError: 'Kev txhawb nqa Hu rau email yuav tsum muaj',
              contactPhoneError: 'Cov Xov Tooj Txhawb Nqa yog yuav tsum tau',                           
              subscribeButton: 'Sau npe yuav',
              renewButton: 'Ua txuas hnub nyoog',
              requestRenewal: 'Thov kom nthuav qhia koj cov npe',
              renewDialogTitle: 'Txuas hnub nyoog koj cov npe',
              selectedPlan: 'Txoj Kev Npaj Xaiv',
              billed: 'Them nqi',
              payment: 'Nyiaj them',
              howItWorks: 'Cas koj cov kev ua haujlwm ntawm kev ua haujlwm',
              billingInformation: 'Cov Ntaub Ntawv Xa Nqi',
              billingStartPlanning: 'Koj tuaj yeem pib npaj sai li sai tau thaum koj cov nyiaj tau txais',
              expirationExplanation: 'Raws li tas sij hawm ntawm lub sij hawm muaj npe, yuav tsum tau txiav tsis muaj ntawv ceeb toom ntxiv lossis kev ua tau los ntawm tog. Yuav tsis muaj kev them nqi ntxiv, thiab koj yuav tsis tau txais kev pab cuam tshuam nrog tshwj tsis yog koj yuav cov npe tshiab.',
              termsConditions: 'Cov Lus Cog Tseg & Cov Zwj Ceeb',
              trialToMemberMessage: 'Txais tos rau qhov chaw rau kuv! Tam sim no koj muaj kev nkag tau rau tag nrho cov txiaj ntsig ntawm lub chaw ntawm kuv phiaj xwm.',
              memberRenewalMessage: 'Koj txoj kev npaj khomob tau rov ua dua tshiab rau lwm 12 lub hlis',
              contactEmail:  'Hu Rau Email Chaw Nyob',
              contactPhone: 'Hu rau tus xov tooj',
              contactSuccessMessage: 'Koj txoj kev sib cuag tau hloov tshiab',
              subscribeDialogTitle: 'Sau npe yuav mus Center rau kuv',
              trialExpireOn: 'Koj qhov kev sim siab yuav tas sijhawm',
              expiredOn: 'Koj cov npe yuav tsum tau ua tiav',
              paymentSuccessMessage: 'Ua tsaug! Peb cia siab tias koj txaus siab qhov chaw rau kuv',
              today: "Niaj hnub no",
              todayDescription: "Xauv puv nkag mus rau nruab nrab ntawm kuv",
              tenMonths: "10 lub hlis",
              tenMonthDescription: "Tau ceeb toom thaum koj txoj kev npaj yuav tas hnub",
              twelveMonths: "12 lub hlis",
              twelveMonthDescription: "koj cov npe yuav xaus",
              showPaymentOptions: 'Qhia Cov Kev Xaiv Them Nyiaj',
            },
            profilePictureDialog: {
              title: "Ntxiv Koj Daim Duab Profile",
              uploadPhoto: "Upload Duab",
            },
            backgroundImage: {
              pageTitle: 'Duab tom qab',
              subTitle: 'Xaiv cov duab keeb kwm yav dhau los lossis upload koj tus kheej',
              hideGrid: 'Nkaum duab daim duab',
              showGrid: 'Qhia Daim Phiaj Duab',
              selectImage: "Xaiv ib daim duab hauv qab",
              noImage: "Tsis muaj duab",
              newImageWarning: "Xaiv ib daim duab tshiab yuav tshem koj cov duab uploaded. Koj puas xav mus ntxiv?",
              warningConfirm: "Yog lawm, kuv xav tau daim duab tshiab",
              warningCancel: "Tsis yog, khaws kuv daim duab",
              uploadError: "Thov txim, muaj qhov yuam kev hloov kho koj cov duab tom qab",
            },
            planVideoPage: {
              sectionHeader: "Kuv Txoj Kev Npaj Video",
              subHeader: "Tsim ib daim vis dis aus los qhia koj txoj kev npaj rau cov neeg koom nrog",
              saveAlertText: "Ua tsaug rau koj upload koj cov phiaj xwm video!",
              explainTextGreeting: "Nyob zoo {{firstName}},",
              explainTextP1: `				
                Qhov chaw no yog rau koj mus upload ib tug yees duab uas koj contributors yuav				
                pom sai li sai tau lawv nkag rau hauv Center On Me los pab txhawb rau koj txoj kev npaj.				
                Daim video no yog lub caij nyoog rau koj piav qhia tias vim li cas koj thiaj tsim qhov no				
                npaj thiab muab piv txwv rau yav tom ntej uas koj xav txog				
                rau koj tus kheej hauv kev tsim cov phiaj xwm no.`,
              explainTextP2: `				
                Sau cov yees duab ntawm koj lub xov tooj, computer lossis ntsiav tshuaj thiab upload nws				
                ntawm no. Koj cov vis dis aus yuav tsum tsawg dua kaum (10) feeb. Rau cov lus qhia				
                ntawm yuav ua li cas rau koj yees duab, thov saib cov piv txwv video los yog xaiv				
                "Lub tswv yim tsim kuv daim video"`,
              explainTextEnding: "Ua tsaug,",
              explainTextSignature: "Center Rau Kuv",
              tipsButton: "Lub tswv yim tsim kuv daim video",
              exampleVideoTitle: "Piv txwv video",
              alvaVideoTitle: "Alva Example",
              uploadButton: "Upload koj video",
              removeButton: "Tshem Kuv Cov Video",
              videoError: "Koj tus browser tsis txhawb lub npe video.",
              lengthError: "Yuav ua li cas: Qhov siab tshaj Video Length yog 10 feeb",
              tips: {
                dialogTitle: "Lub tswv yim los tsim koj video",
                tipsTitle: "Lub tswv yim rau kaw & uploading koj video:",
                tipOne: "Sau koj daim vis dis aus hauv lub teeb pom kev uas koj lub ntsej muag pom tag nrho",
                tipTwo: "Xav txog qhov koj xav hais ua ntej ntaus cov ntaub ntawv",
                tipThree: "Kwv yees duab hauv qab kaum (10) feeb",
                tipFour: `'Yog tias tom qab upload koj cov yeeb yaj kiab koj muaj teeb meem saib nws, thov email centeronme@mainsl.com  thiab muab koj tus username (email chaw nyob) thiab lub npe ntawm cov ntaub ntawv video uas koj upload (xws li “Myplanvideo.mov)`,
                examplesTitle: "Piv txwv cov lus nug los teb hauv koj cov video:",
                exampleOne: "Vim li cas koj thiaj caw tib neeg los pab rau koj txoj kev npaj?",
                exampleTwo: "Koj cia siab tias yuav ua tiav li cas nrog koj txoj kev npaj?",
                exampleThree: "Koj xav npaj rau dab tsi?",
                exampleFour: "Koj cov neeg koom tes tuaj yeem pab txhawb koj li cas los tsim koj txoj kev npaj?",
              },
              contributor: {
                sectionHeader: "Lawv npaj ua video",
                subHeader: "",
                noVideo: "Tus tswv tsis tau ntxiv Npaj Video",
              },
            },
            knowledgeCenter: {
              pageTitle: 'Kev paub Center',
              subTitle: 'Nyem rau ntawm qhov txuas kom paub ntau ntxiv txog kev siv peb lub xaib',
              videoTab: 'Yeeb yaj duab',
              faqTab: 'FAQ',
              contactTab: 'Tiv tauj peb',
              videos: {
                gettingStartedTitle: 'Pib pib',
                profileSettings: 'Kev teeb tsa Profile',
                landingPageAndBadges: 'Nplooj ntawv thiab cov ntawv cim',
                contributorsTitle: 'Cov koom tes',
                addingContributors: 'Ntxiv Contributors',
                managingContributors: 'Tswj Cov Pab Koom Tes',
                howToContribute: 'Yuav Pab Li Cas',
                gettingToKnowYouTitle: 'Tau Paub Koj',
                likeAndAdmire: 'Like thiab qhuas',
                people: 'Cov neeg hauv kuv lub neej',
                favorites: 'Yam Kuv Nyiam',
                thingsIDo: 'Tej yam kuv ua rau kev lom zem',
                routines: 'Kuv Cov Lus Qhia',
                historyMap: 'Daim ntawv qhia keeb kwm',
                travelPlan: 'Mus ncig',
                happeningNowTitle: 'Tam sim no muaj dab tsi',
                healthAndSafety: 'Kev Noj Qab Haus Huv & Kev Nyab Xeeb',
                livingInHome: 'Nyob Hauv Kuv Lub Tsev',
                work: 'Ua haujlwm',
                school: 'Tsev kawm ntawv',
                startPlanningTitle: 'Cia peb pib npaj',
                workGoals: 'Lub Hom Phiaj Ua Haujlwm',
                healthGoals: 'Lub hom phiaj kev noj qab haus huv',
                livingGoals: 'Lub Hom Phiaj Nyob',
                travelGoals: 'Lub hom phiaj mus ncig',
                funGoals: 'Lub hom phiaj lom zem',
                relationshipGoals: 'Lub hom phiaj kev sib raug zoo',
                planSummaryTitle: 'Daim phiaj xwm txheej',
                webVersion: 'Web-Version & Exporting rau PDF',
                dialogTitle: 'Qhia Video',
              },
              faq: {
                pageTitle: 'Cov lus nug nquag',
                usingTheKnowledgeCenter: 'Siv Lub Tsev Kawm Txuj Ci',
                passwordAndUsername: 'Password thiab Username',
                managingPlans: 'Tswj Kev Npaj',
                planOwnersAndContributors: 'Plan tswv & koom',
                usingTheSite: 'Siv lub Site',
                accessingSupport: 'Nkag mus txhawb',
                registration: 'Sau npe & Subscription',
                howToUse: 'Kuv yuav siv qhov chaw paub li cas?',
                whoToContact: 'Kuv tuaj yeem tiv tauj leej twg yog tias kuv muaj lus nug uas tsis tau hais hauv daim vis dis aus?',
                videoLanguage: 'Kuv puas tuaj yeem saib cov yeeb yaj kiab no ua lwm hom lus tsis yog lus Askiv?',
                findVideo: 'Kuv yuav nrhiav tau cov video kuv tab tom nrhiav li cas?',
                passwordRequirements: 'Dab tsi yog qhov yuav tsum tau ua tus password?',
                changePassword: 'Kuv yuav hloov kuv tus password li cas?',
                changeUsername: 'Yuav ua li cas yog tias kuv xav hloov kuv tus ID nkag mus / email chaw nyob?',
                howManyPlans: 'Kuv tuaj yeem tsim pes tsawg lub phiaj xwm thaum kuv tso npe?',
                sharePlan: 'Kuv puas tuaj yeem qhia kuv txoj kev npaj rau leej twg? Puas muaj txoj hauv kev xa email rau kuv txoj kev npaj hauv lub platform?',
                deletePlan: 'Kuv yuav ua li cas yog tias kuv yuam kev deleted ib txoj kev npaj?',
                whatIsPlanOwner: 'Leej twg / dab tsi yog "Plan Owner"',
                whatIsContributor: 'Leej twg / dab tsi yog "Contributor?"',
                contributorLimit: 'Puas muaj kev txwv rau cov neeg koom nrog kuv tuaj yeem ntxiv rau kuv txoj kev npaj?',
                approveComment: 'Yog tias kuv pom zoo rau cov lus qhia, lawv puas yuav raug ceeb toom?',
                denyComment: 'Yog tias kuv tsis lees paub cov lus qhia, lawv puas yuav raug ceeb toom?',
                updateProfile: 'Kuv tuaj yeem hloov kho kuv Cov Ntaub Ntawv Profile txhua lub sijhawm?',
                howManyActivities: 'Kuv tuaj yeem ntxiv ntau yam dej num hauv txhua ntu?',
                listSomeoneAsResponsible: 'Yog tias kuv sau ib tus neeg ua lub luag haujlwm kom ua tiav lub hom phiaj, lawv puas yuav raug ceeb toom?',
                editActivity: 'Kuv yuav kho qhov kev ua si li cas?',
                deleteActivity: 'Kuv yuav rho tawm ib qho haujlwm li cas?',
                accessibilityFeatures: 'Lub Platform muab kev nkag tau yooj yim dab tsi?',
                forgetToSave: 'Yuav ua li cas yog tias kuv tsis nco qab khaws qhov kuv tab tom ua haujlwm?',
                useOnTablet: 'Kuv puas tuaj yeem ua haujlwm ntawm kuv txoj kev npaj siv kuv lub ntsiav tshuaj?',
                useOnPhone: 'Kuv puas tuaj yeem ua haujlwm ntawm kuv txoj kev npaj siv kuv lub xov tooj?',
                phoneApp: 'Puas muaj lub xov tooj app kuv xav tau rub tawm?',
                needInternet: 'Kuv puas yuav tsum muaj kev siv internet los ua haujlwm ntawm kuv txoj kev npaj? Puas muaj hom offline?',
                whatAreBadges: 'Cov paib yog dab tsi?',
                helpWithPlan: 'Kuv yuav hu rau leej twg yog kuv xav tau kev pab rau kuv txoj kev npaj?',
                issuesAccessingPlan: 'Kuv yuav hu rau leej twg yog tias kuv muaj teeb meem nkag mus rau kuv txoj kev npaj?',
                issueWithPlanVideo: 'Kuv yuav hu rau leej twg yog kuv tsis tuaj yeem tau txais kuv daim Video Plan rau upload?',
                forgotUsername: 'Yuav ua li cas yog tias kuv tsis nco qab kuv tus ID nkag mus username? Kuv yuav hu rau leej twg?',
                wasRegistrationSuccessful: 'Kuv yuav ua li cas thiaj paub tias kuv qhov kev tso npe ua tiav?',
                renewPlan: 'Yuav ua li cas kuv thiaj paub tias kuv yuav tsum tau rov qab kuv txoj kev npaj?',
                howToCancel: 'Kuv yuav rho kuv qhov kev tso npe li cas?',
              },
              faqAnswers: {
                howToUse: `Nias rau ntawm ntu lossis txuas koj xav paub ntxiv txog. Koj yuav raug coj mus rau ib nplooj ntawv cais thiab koj yuav tau saib ib daim vis dis aus uas yuav coj koj mus rau ntawm qhov chaw ntawd.'`,
                whoToContact: `Thov hu rau peb txhua lub sijhawm uas siv lub tab "Tsab Peb"! Hauv qhov tab koj yuav pom ib daim ntawv ua kom tiav uas yuav xa email rau peb. Hauv koj tus email thov suav nrog nplooj ntawv thiab video koj muaj lus nug txog. Peb yuav teb rau koj sai sai.`,
                videoLanguage: `Rau lub sijhawm tam sim no, cov yeeb yaj kiab no tsuas yog muaj ua lus Askiv xwb. Kaw captioning tau muab rau tag nrho cov yeeb yaj duab.`,
                findVideo: `Thov ntaus nplooj ntawv koj tab tom nrhiav rau hauv qhov tshawb nrhiav thiab qhov txuas mus rau cov yeeb yaj kiab yuav tshwm. Cov yeeb yaj kiab tau teeb tsa los ntawm lub npe ntawm nplooj ntawv. Yog tias koj nrhiav tsis tau ib daim vis dis aus uas koj tab tom nrhiav, thov hu rau peb siv lub, "Tsab Peb" tab.`,
                passwordRequirements: `Koj tus password yuav tsum suav nrog: Tsab ntawv me, tsab ntawv loj, tus lej, tus cim tshwj xeeb, thiab tsawg kawg yog 8 tus cim.`,
                changePassword: `Koj tuaj yeem hloov koj tus password txhua lub sijhawm los ntawm kev mus rau koj qhov profile chaw thiab xaiv qhov, "Hloov Password" khawm.`,
                changeUsername: `Koj yuav tsis muaj peev xwm hloov koj tus email chaw nyob.`,
                howManyPlans: `Tsis muaj kev txwv rau pes tsawg lub phiaj xwm koj tuaj yeem tsim tau. Txawm li cas los xij, cov ntaub ntawv koj nkag mus rau hauv cov phiaj xwm suav nrog cov neeg koom nrog cov phiaj xwm yuav tsis nqa los ntawm ib txoj kev npaj mus rau lwm qhov.`,
                sharePlan: `Tus tswv txoj kev npaj tuaj yeem qhia lawv txoj kev npaj rau txhua tus neeg txhua lub sijhawm. Nyob rau sab xis sab xis ntawm nplooj ntawv qhia txog cov phiaj xwm, muaj lub pob uas nyeem, "Export rau PDF". Thaum lub pob ntawd raug nyem, koj txoj kev npaj yuav raug rub tawm thiab tuaj yeem luam tawm lossis xa email rau txhua tus neeg ntawm koj xaiv.`,
                deletePlan: `Thaum koj sim rho tawm ib txoj kev npaj, Center On Me yuav muab cov lus qhia kom paub tseeb tias koj xav rho tawm koj txoj kev npaj tiag tiag. Yog tias koj tseem xaiv rho tawm koj txoj kev npaj yuav tsis muaj dab tsi ua tau thiab koj yuav tsum rov tsim koj txoj kev npaj txij thaum pib.`,
                whatIsPlanOwner: `Ib tug "Plan Owner" yog tus neeg tsom rau ntawm txoj kev npaj, tus neeg tsim txoj kev npaj. Tus tswv Plan yuav tsum yog lub hom phiaj ntawm txoj kev npaj no txhua lub sijhawm!`,
                whatIsContributor: `Ib tus neeg pab txhawb nqa yog ib tus neeg uas "Plan Owner" tau caw los pab rau lawv txoj kev npaj. Tus Tswv Tsev Npaj muaj kev tswj xyuas tag nrho ntawm lawv txoj kev npaj uas tus neeg koom tuaj yeem saib thiab / lossis tawm tswv yim rau. Tag nrho cov lus txhawb nqa yuav tsum tau tshuaj xyuas thiab pom zoo ua ntej muab tso rau hauv ib txoj kev npaj.`,
                contributorLimit: `Tsis muaj kev txwv rau pes tsawg tus neeg koom nrog koj tuaj yeem muaj. Rau txhua txoj kev npaj koj tsim koj yuav tsum tau ntxiv txhua tus neeg sib koom ua ke.`,
                approveComment: `Tsis yog, Cov neeg koom tes yuav tsis raug ceeb toom thaum muaj lus pom zoo.`,
                denyComment: `Tsis yog, Cov neeg koom tes yuav tsis raug ceeb toom thaum cov lus tsis pom zoo.`,
                updateProfile: `Koj tuaj yeem hloov kho koj cov ntaub ntawv profile txhua lub sijhawm los ntawm kev mus rau qhov profile. Hauv qhov profile chaw koj yuav muaj peev xwm hloov koj lub npe & Cov ntsiab lus, koj daim duab Profile, koj keeb kwm yav dhau thiab koj cov xim palette.`,
                howManyActivities: `Tsis muaj kev txwv rau tus naj npawb ntawm cov dej num lossis cov hom phiaj koj tuaj yeem ntxiv rau hauv txhua ntu.`,
                listSomeoneAsResponsible: `Lawv yuav tsis raug ceeb toom yog tias koj sau ib tus neeg ua lub luag haujlwm kom ua tiav lub hom phiaj. Yog tias tus tswv txoj kev npaj xav tau kev txhawb nqa nrog lub hom phiaj, lawv yuav tsum sib txuas lus nrog tus neeg ntawd thiab qhia lawv txoj kev npaj yog tias lawv nyiam ua li ntawd.`,
                editActivity: `Koj tuaj yeem hloov kho qhov haujlwm lossis lub hom phiaj txhua lub sijhawm los ntawm txhaj rau ntawm daim npav. Daim ntawv yuav qhib thiab txhua yam ntaub ntawv tuaj yeem hloov lossis hloov kho.`,
                deleteActivity: `Koj tuaj yeem rho tawm ib qho haujlwm lossis lub hom phiaj txhua lub sijhawm los ntawm txhaj rau ntawm daim npav. Daim ntawv yuav qhib thiab lub hom phiaj yuav raug tshem tawm los ntawm scrolling txhua txoj kev mus rau hauv qab thiab xaiv lub liab "Delete" khawm.`,
                accessibilityFeatures: `Center On Me siv Userway los muab kev nkag tau yooj yim hauv qhov chaw. Qee qhov kev siv tau yooj yim yog hloov qhov sib piv, qhov loj me, tus cursor taw qhia, thiab cov ntawv nyeem lossis qhov chaw sib dhos. Koj tuaj yeem xaiv koj cov yam ntxwv los ntawm kev xaiv qhov puag ncig "Tus neeg" icon uas muaj nyob rau txhua nplooj ntawv ntawm sab laug.`,
                forgetToSave: `Nws yog ib qho tseem ceeb heev kom ntaus txuag raws li koj tab tom tsim cov nplooj ntawv sib txawv hauv qhov chaw. Cov ntaub ntawv yuav tsis tau txais kev cawmdim.`,
                useOnTablet: `Yog! Center Ntawm Kuv tuaj yeem nkag mus tau los ntawm lub ntsiav tshuaj lossis los ntawm Smart Phone.`,
                useOnPhone: `Yog! Center On Me tuaj yeem nkag tau los ntawm Smart Phone. Lub vev xaib tau raug tsim los ua lub teb chaws mobile, yog li qee cov ntaub ntawv yuav tsis yooj yim pom zoo li hauv ntsiav tshuaj lossis siv koj lub computer.`,
                phoneApp: `Tsis yog, Center On Me tsis muaj App. Koj tuaj yeem nkag mus rau Center On Me los ntawm koj lub xov tooj ntse los ntawm qhib koj lub web browser app thiab mus rau www.centeronme.com.`,
                needInternet: `Yog lawm, Center On Me xav kom muaj internet kom muaj peev xwm ua haujlwm ntawm txoj kev npaj. Tsis muaj hom offline ntawm Center On Me.`,
                whatAreBadges: `Cov paib yog ib txoj hauv kev rau cov neeg siv thiab Center On Me txhawm rau taug qab koj qhov kev nce qib hauv qhov chaw. Koj khwv tau ib daim paib txhua zaus koj ua tiav ib nplooj ntawv, thiab cov paib tuaj yeem pom ntawm nplooj ntawv tsaws. Nyem rau ntawm cov ntawv cim rau kev lom zem surprise!`,
                helpWithPlan: `Yog tias koj xav tau kev txhawb nqa, thov mus saib ntawm, "Hu Hu Rau Peb" tab hauv qhov chaw paub. Ib qho email yuav raug xa mus rau Pab Pawg Pabcuam Ntawm Kuv thiab peb yuav teb sai sai.`,
                issuesAccessingPlan: `Yog tias koj xav tau kev txhawb nqa, thov mus saib ntawm, "Tsab Hu Rau Peb" tab hauv qhov chaw paub. Ib qho email yuav raug xa mus rau Pab Pawg Pabcuam Ntawm Kuv thiab peb yuav teb sai sai.`,
                issueWithPlanVideo: `Ua ntej, tshawb xyuas cov lus qhia upload video kom paub meej tias koj ua tau raws li qhov xav tau video. Yog tias koj tseem xav tau kev txhawb nqa, thov mus saib hauv, "Tsab Hu Rau Peb" tab hauv qhov chaw paub. Ib qho email yuav raug xa mus rau Pab Pawg Pabcuam Ntawm Kuv thiab peb yuav teb sai sai.`,
                forgotUsername: `Koj tus username yog koj email chaw nyob. Yog tias koj xav tau kev txhawb nqa, thov mus saib hauv, "Tsab Hu Rau Peb" tab hauv qhov chaw paub. Ib qho email yuav raug xa mus rau Pab Pawg Pabcuam Ntawm Kuv thiab peb yuav teb sai sai.`,
                wasRegistrationSuccessful: `Yog tias koj qhov kev them nyiaj ua tiav, koj yuav tau txais daim ntawv txais email rau koj tus lej PayPal txuas. Koj tseem yuav pom daim ntawv txais nyiaj hauv koj li PayPal app. Saib hauv qab piv txwv ntawm daim ntawv txais email rau kev them nyiaj.`,
                renewPlan: `Cov neeg siv yuav tau txais email ceeb toom ntawm 3 lub hlis, 6 lub hlis thiab 10 lub hlis kom ceeb toom rau tus neeg siv thaum koj qhov kev tso npe tawm thiab cov lus qhia yuav muab qhia txog yuav ua li cas txuas ntxiv koj txoj kev npaj.`,
                howToCancel: `Ib daim ntawv yuav khoom rau ib xyoos. Koj tsis tuaj yeem thim qhov kev tso npe ib xyoos. Ib tsab email yuav raug xa tuaj rau koj thaum nws txog sijhawm rov ua dua koj txoj kev npaj thiab koj tuaj yeem xaiv tsis txuas ntxiv koj txoj kev npaj rau lub sijhawm ntawd.`,
              },
            },
          },
          lockedFeatures: {
            photoLock: 'Cov ntawv yuav tsum tau muab cov duab tso rau',
            videoLock: 'Kev ua ntaub ntawv yuav tsum tau upload cov yeeb yaj kiab',
            pdfLock: 'Kev Sau Npe yuav tsum tau rub tawm PDF',
            sectionLock: 'Xaiv kev xaiv cov kev xaiv yuav nkag mus rau cov yam ntxwv no.',
            sectionLockSaveMessage: 'Seem no tsis tuaj yeem hloov kho los ntawm kev sim cov neeg siv',
            createPlanLock: 'Xaiv cov kev xaiv yuav xaiv los tsim ntau txoj kev npaj',
            expiredLock: 'Xaiv cov kev xaiv npe yuav nkag mus rau cov yam ntxwv no',
            expiredAlert:  'Koj cov npe yuav tas sijhawm. Thov txuas ntxiv mus saib seem no.',            
          },
          permissions: {
            theContributor: "Tus pab txhawb nqa",
            cannotView: "saib tsis tau",
            canView: "tau saib",
            theSection: "ntu",
            toTheSection: "mus rau ntu",
            and: "thiab",
            but: "tab sis",
            cannotComment: "tsis tuaj yeem ntxiv cov lus pom",
            canComment: "tuaj yeem ntxiv cov lus pom",
            gtkyTitle: "Tau Paub Koj",
            happeningTitle: "Dab tsi tshwm sim hauv Koj Lub Neej",
            planningTitle: "Kev npaj",
          },
          breadcrumbs: {
            planning: "Cia peb pib npaj",
            happening: "Tshwm sim",
            gettingToKnowYou: "Tau Paub Koj",
            myRoutines: "Kuv Cov Lus Qhia",
            myPeople: "Cov Neeg Hauv Kuv Lub Neej",
            likeAndAdmire: "Nyob zoo & qhuas",
            work: "Ua haujlwm",
            school: "Tsev kawm ntawv",
            home: "Tsev",
            fun: "Lom zem",
            travel: "Mus ncig",
            health: "Kev noj qab haus huv",
            relationships: "Kev sib raug zoo",
            goal: "Kuv Lub Hom Phiaj",
            historyMap: "Daim ntawv qhia keeb kwm",
            favoriteThings: "Yam Kuv Nyiam",
            add: "Ntxiv Tshiab",
            profile: "Profile",
            settings: "Chaw",
            contributors: "Cov neeg koom tes & Kev Ceeb Toom",
            thingsIDo: "Tej Yam Kuv Ua Rau Kev Lom Zem",
            planningVideo: "Kuv txoj kev npaj video",
            planSummary: "Daim phiaj xwm txheej",
            changePassword: "Hloov Password",
            knowledgeBase: "Kev paub Center",
            colorPalette: "Xim Palette",
            myActivity: "Kuv Kev Ua Haujlwm",
          },
          userMenu: {
            allPlans: "Txhua Txoj Kev Npaj",
            planDashboard: "Npaj dashboard",
            planSummary: "Daim phiaj xwm txheej",
            settings: "Chaw",
            subscribe: "Sau npe",
            logout: "Nkag mus"
          },
          timeoutAlert: {
            attention: "Nco ntsoov",
            description: "Koj tab tom yuav raug rho tawm",
            stillPlanning: "Kuv tseem npaj!",
            logMeOut: "Log kuv tawm",
            warningOnLogout: "Cov ntaub ntawv tsis tau khaws tseg yuav ploj",
          },
          allPlans: {
            pageTitle: "Saib sai sai ntawm koj cov phiaj xwm",
            subTitle: "Xaiv ib qho los pib",
            iContributeTo: "Cov phiaj xwm kuv pab txhawb rau",
            myPlans: "Kuv Cov Kev Npaj",
            newContributeTo: "Pab txhawb rau txoj kev npaj tshiab",
            createNewPlan: "Tsim ib txoj kev npaj tshiab",
            planOwner: "Tus tswv npaj",
            planCreated: "Npaj tsim",
            lastViewed: "Kawg saib",
            planVideo: "Npaj Video",
            manageContributors: "Tswj Cov Pab Koom Tes & Kev Ceeb Toom",
            planSummary: "Daim phiaj xwm txheej",
            deletePlan: "Rho tawm Plan",
            createPlanDialog: {
              dialogTitle: 'Tsim ib txoj kev npaj tshiab',
              explainerText: 'Xaiv lub npe rau koj cov phiaj xwm tshiab los pib',
              inputLabel: 'Lub Npe',
              createMessage: 'Pib txoj kev npaj tshiab yuav tsis nqa koj txoj haujlwm lossis cov koom tes los ntawm cov phiaj xwm yav dhau los',
            },
            contributeToPlanDialog: {
              dialogTitle: 'Pab Txhawb Ib Txoj Kev Npaj',
              explainerText: 'Sau tus lej koj tau txais los ntawm tus tswv phiaj xwm phiaj xwm',
              inputLabel: 'Kev nkag mus',
              errorMessage: 'Cov lej nkag tsis phim cov phiaj xwm. Thov sim dua',
            }
          },
          deletePlan: {
            dialogTitle: "Rho tawm Kuv Txoj Kev Npaj",
            explanation: "Koj sim rho tawm koj txoj kev npaj.",
            confirmMessage: "Koj puas paub tseeb tias koj xav rho tawm txoj kev npaj no?",
            noteToDownload: "Nco tseg: Tej zaum koj yuav xav rub tawm thiab txuag koj txoj kev npaj ua ntej rho tawm vim nws tsis tuaj yeem rov qab tau.",
            downloadButton: "Download Kuv Txoj Kev Npaj",
            keepPlanButton: "Khaws Kuv Txoj Kev Npaj",
            deleteButton: "Rho tawm Kuv Txoj Kev Npaj",
            deleteSuccessMessage: "Rho tawm!",
          },
          planLandingPage: {
            videoTitle: "Zoo siab txais tos rau koj qhov Center ntawm Me Plan",
            gtky: "Tau Paub Koj",
            whatIsHappening: "Dab tsi tshwm sim hauv Koj Lub Neej",
            startPlanning: "Pib Kev Npaj",
            newBadge: "Daim paib tshiab",
            getStarted: "Pib pib",
            leftOff: "Qhov twg koj tso tseg",
            happeningButton: "tshwm sim",
            planningButton: "Lub Hom Phiaj Npaj",
            recentBadges: "Tsis ntev los no Daim Ntawv Pov Thawj Tau Txais",
            gtkyNoCaps: "Tau paub koj",
            happeningShort: "Dab tsi tshwm sim",
            planning: "Kev npaj",
            noBadgeMessage: "Tseem tsis tau muaj npe! Pib ua kom tiav cov ntu kom tau cov paib",
            contributorGreeting: "Zoo siab txais tos",
            contributorMessage: `Peb pom tias koj tab tom koom nrog kev koom tes, uas txhais tau tias ib tus neeg xav 
              tias koj tseem ceeb heev! Koj ua lub luag haujlwm tseem ceeb thaum pab txhawb rau qhov kev ua si no, peb 
              thov kom koj tuav lub hom phiaj ntawm lub vev xaib no thiab khaws tus tswv txoj kev npaj nyob hauv nruab 
              nrab ntawm lawv txoj kev npaj thaum siv koj lub sijhawm los ntxiv cov ntaub ntawv nplua nuj thiab muaj txiaj ntsig. 
              Thaum pab txhawb rau txoj kev npaj, peb kuj thov kom koj siv sijhawm los xav txog thiab khaws cov ntaub ntawv tshwj xeeb 
              ntawm tus neeg yog leej twg, dab tsi tseem ceeb rau lawv, thiab koj tuaj yeem pab lawv mus txog lawv txoj kev cia siab thiab kev npau suav!
            `,
            contributorQuote: `"Txhua tus tib neeg muaj peev xwm, muaj peev xwm, thiab khoom plig. Kev ua neej nyob zoo nyob ntawm seb cov peev 
              txheej no puas tuaj yeem siv tau, muaj peev xwm qhia tau thiab khoom plig muab. lawv, thiab cov zej zog nyob ib ncig ntawm tus neeg 
              yuav xav tias muaj zog ntau dua vim yog kev koom tes uas tus neeg tau ua. "
            `,
            quote: `"Koj lub hom phiaj yog txoj hauv kev qhia koj thiab qhia koj tias dab tsi ua tau rau koj lub neej." - Les Brown`,
            profileBadges: {
              addContributor: 'paib: Ntxiv ib tus neeg koom nrog rau koj txoj kev npaj',
              colorPalette: 'paib: Xaiv xim palette',
              approveContributor: 'paib: Pom zoo ib qho kev koom tes',
              planningVideo: 'paib: Tsim Kev Npaj Video',
              profilePicture: 'paib: upload ib daim duab profile',
              backgroundImage: 'paib: upload keeb kwm tshiab',
              profileComplete: 'paib: profile tiav!',
            }
          },
          badges: {
            badgeSectionTitle: "Cov ntawv cim kuv tau khwv tau",
            badgesPlanningExplanation: `Pib khwv tau cov paib los ntawm kev ua tiav txhua ntu! Thaum koj ua tiav nrog ib ntu, koj daim paib yuav tshwm ntawm no.	Cia peb muaj kev lom zem thaum npaj rau koj lub neej yav tom ntej!`,
            badgesHappeningExplanation: `Pib tau cov paib ua kom tiav los ntawm kev ua tiav txhua ntu! Thaum koj ua tiav nrog ib ntu, koj daim paib yuav tshwm ntawm no.				
            Cia peb muaj kev lom zem thaum kawm dab tsi tshwm sim hauv koj lub neej!`,
            badgesGTKYExplanation: `Pib khwv tau cov paib los ntawm kev ua tiav txhua ntu! Thaum koj ua tiav nrog ib ntu, koj daim paib yuav tshwm ntawm no.				
            Cia peb muaj kev lom zem thaum ntsib koj!`,
          },
          sections: {
            likeAndAdmire: "Nyob zoo & qhuas",
            people: "Cov neeg hauv kuv lub neej",
            historyMap: "tseem ceeb",
            favorites: "Tej yam uas nyiam",
            travelPlan: "Mus ncig",
            thingsIDo: "Tej Yam Kuv Ua Rau Kev Lom Zem",
            dailyRoutines: "Txoj Cai Txhua Hnub",
            weeklyRoutines: "Lub Limtiam Routines",
            routines: "Cov xwm txheej",
            happeningHome: "Nyob Tsev",
            happeningWork: "Hloov ua haujlwm",
            happeningSchool: "Tsev kawm ntawv tshwm sim",
            happeningHealth: "Kev Noj Qab Haus Huv tshwm sim",
            planningHome: "Npaj tsev",
            planningWork: "Kev npaj ua haujlwm",
            planningTravel: "Npaj kev mus ncig",
            planningFun: "Npaj kev lom zem",
            planningHealth: "Npaj kev noj qab haus huv",
            planningRelationships: "Kev npaj kev sib raug zoo",
          },
          planningPage: {
            pageTitle: "Cia peb pib npaj",
            planningVideo: "Kev npaj video",
            housing: "Lub tsev",
            work: "Ua haujlwm",
            health: "Kev noj qab haus huv",
            fun: "Kev lom zem",
            relationships: "Kev sib raug zoo",
            travel: "Mus ncig",
            addAnotherGoal: "Ntxiv Lwm Lub Hom Phiaj"
          },
          planningHealth: {
            sectionName: "Planning Health",
            sectionHeader: "Lub Hom Phiaj Noj Qab Haus Huv",
            subHeader: "Cia peb tsim ib txoj kev npaj rau yav tom ntej noj qab nyob zoo",
            videoTitle: "Lub Hom Phiaj Kev Noj Qab Haus Huv - Pab Video",
            goalTitle: "Koj xav li cas rau lub hom phiaj no?",
            goalAroundHealth: "Koj lub hom phiaj ntawm kev noj qab haus huv yog dab tsi?",
            workedOnThisGoal: "Koj puas tau ua haujlwm ntawm lub hom phiaj no ua ntej?",
            whatWorked: "Dab tsi ua haujlwm thaum koj ua haujlwm ntawm lub hom phiaj no?",
            didntWork: "Dab tsi tsis ua haujlwm thaum koj ua haujlwm ntawm lub hom phiaj no?",
            healthTableHeader: "Raws li koj xav txog lub hom phiaj no, koj xav li cas koj yuav tsum ...",
            doYouHaveSupport: "Puas muaj leej twg hauv koj lub neej uas koj tuaj yeem txhawb koj lub hom phiaj no?",
            attachedPeopleMessage: "Thov xaiv leej twg tuaj yeem txhawb nqa koj",
            anyOneElseWhoCanSupport:
              "Puas muaj lwm tus neeg uas tuaj yeem pab koj ua tiav koj lub hom phiaj thiab vim li cas?",
            goalCommit: "Koj tuaj yeem cog lus ua dab tsi los pab tsim lub neej yav tom ntej?",
            increase: "Ntau",
            decrease: "Txo",
            doMore: "Ua ntau ntawm",
            doLess: "Ua Tsis Tau",
            increaseEx: "Ex. Ntau npaum li cas lub sij hawm kuv tsom rau kuv tus kheej",
            decreaseEx: "Ex. Kuv siv sijhawm ntau npaum li cas hauv Facebook",
            doMoreEx: "Ex. Lub sij hawm kuv siv rau kuv ua pa",
            doLessEx: "Ex. Noj cov zaub mov qab zib",
            saveMessage:
              "Zoo heev, hmoov zoo ntawm lub hom phiaj no! Koj puas xav ntxiv lwm lub hom phiaj kev noj qab haus huv?",
            deleteMessage: "Koj puas paub tseeb tias koj xav rho tawm kab lus no?",
            deleteConfirm: "Ok, kab tau raug tshem tawm",
            summary: {
              goalTitle: "Kuv lub hom phiaj ntawm kev noj qab haus huv",
              whatWorked: "Thaum kuv ua haujlwm ntawm lub hom phiaj no ua ntej ntawm no yog qhov ua haujlwm",
              didntWork: "Thaum kuv ua haujlwm rau lub hom phiaj no ua ntej ntawm no yog qhov tsis ua haujlwm",
              healthTableHeader:
                "Thaum kuv xav txog kuv lub hom phiaj, kuv xav tias kuv tuaj yeem ua cov hauv qab no los pab kuv",
              doYouHaveSupport:
                "Cov neeg hauv kuv lub voj voog ntawm kev txhawb nqa uas tuaj yeem pab kuv nrog lub hom phiaj no yog",
              anyOneElseWhoCanSupport: "Lwm tus neeg uas kuv xav tias tuaj yeem pab tau",
              goalCommit: "Kuv tuaj yeem cog lus rau",
            },
          },
          planningRelationships: {
            sectionName: "Planning Relationships",
            sectionHeader: "Cov hom phiaj kev sib raug zoo",
            subHeader: "Cia peb pib tsim kev sib txuas nrog tib neeg!",
            newOrStrengthen:
              "Koj puas xav npaj rau kev sib raug zoo tshiab lossis koj puas xav ntxiv dag zog rau kev sib raug zoo tam sim no?",
            saveMessage: "Lub suab zoo li lub hom phiaj kev sib raug zoo! Koj puas xav ntxiv lwm lub hom phiaj?",
            newText: "Tshiab",
            strengthenText: "Ua kom muaj zog",
            relationship: 'Kev sib txheeb',
            strengthen: {
              videoTitle: "Txhim khu kev sib raug zoo - Pab tau Video",
              goal: "Koj xav ntxiv dag zog rau kev sib raug zoo nrog leej twg?",
              currentStatus: "Qhov xwm txheej tam sim no ntawm kev sib raug zoo yog dab tsi?",
              romantic: "Romantic",
              friend: "Tus phooj ywg",
              professional: "Cov kws tshaj lij",
              family: "Tsev neeg",
              whatToStrengthen: "Dab tsi txog qhov kev sib raug zoo no koj puas xav ntxiv dag zog?",
              frequency: "Ntau zaus",
              communication: "Kev sib txuas lus",
              desiredStatus: "Cov xwm txheej xav tau",
              howOften: "Yuav ua li cas koj tam sim no cuam tshuam nrog tus neeg no?",
              howToStrengthen: "Yuav ua li cas koj xav kom ntxiv dag zog rau cov zaus",
              strengthenCommunication: "Yuav ua li cas txog kev sib txuas lus koj xav kom ntxiv dag zog?",
              commExample1: "Ex: Kuv sim hu lawv ntau zaus",
              commExample2: "Ex: Kuv tuaj yeem tham nrog lawv thaum kuv xav tau",
              commExample3: "Ex: Kuv hnov thiab txuas nrog",
              commExample4: "Ex: Lawv yuav tsis teb txhua lub sijhawm",
              commTableHeader1: "Koj tau sim dab tsi thaum txhawb koj txoj kev sib txuas lus?",
              statusTableHeader1: "Koj tau sim ua dab tsi los hloov qhov xwm txheej ntawm kev sib raug zoo no?",
              tableHeader2: "Koj kawm dab tsi thaum koj sim qhov ntawd?",
              tableHeader3: "Koj txaus siab dab tsi thaum koj sim qhov ntawd?",
              tableHeader4: "Koj txhawj xeeb dab tsi?",
              commDoNext: "Saib yam koj nyuam qhuav teev tseg, koj tuaj yeem ua dab tsi ntxiv?",
              preferredStatus: "Koj xav li cas rau tus neeg no txoj cai?",
              theyAreCurrently: "Lawv tam sim no:",
              statusExample1: "Ex: Kuv sim nug lawv ntawm ib hnub",
              statusExample2: "Ex: Tias lawv zoo siab heev thiab kuv nyiam tham nrog lawv",
              statusExample3: "Ex: Lawv hais tias!",
              statusExample4: "Ex: Kuv tsis tau hu lawv txij thaum",
              finalDoNext: "Saib yam koj nyuam qhuav teev tseg, koj tuaj yeem ua dab tsi ntxiv?",
              deleteRowConfirm: "Koj puas paub tseeb tias koj xav tshem tawm kab lus no?",
              deleteRowSuccess: "Ok, kab tau raug tshem tawm",
            },
            new: {
              videoTitle: "Npaj Kev Sib Tham Tshiab - Pab Pawg Video",
              relationshipType: "Koj tab tom nrhiav kev sib raug zoo li cas?",
              characteristics: "Dab tsi yog koj tab tom nrhiav rau hauv ib tus neeg koj xav txuas nrog?",
              where: "Koj tuaj yeem ntsib tus neeg no qhov twg?",
              placeOfWorship: "Qhov chaw pe hawm",
              family: "Tsev neeg",
              friend: "Tus phooj ywg",
              professional: "Cov kws tshaj lij",
              romantic: "Romantic",
              sharedInterests: "Los ntawm Kev Sib Koom",
              online: "Online",
              school: "Tsev kawm ntawv",
              shops: "Cov khw muag khoom",
              work: "Ua haujlwm",
              whenYouThinkAbout: "Thaum koj xav txog kev tsim kev sib txuas tshiab",
              atChurch: "ntawm ib qho chaw pe hawm",
              throughFriend: "los ntawm ib tug phooj ywg",
              throughInterests: "los ntawm kev nyiam sib koom",
              atOnline: "online",
              atSchool: "hauv tsev kawm ntawv",
              atLocalShop: "ntawm lub khw hauv zos",
              atWork: "ua haujlwm",
              likeToStart: ", koj xav pib qhov twg?",
              churchStart: "Thaum koj xav txog kev tsim kev sib txuas tshiab ntawm qhov chaw pe hawm, koj xav pib qhov twg?",
              friendStart: "Thaum koj xav txog kev tsim kev sib txuas tshiab los ntawm ib tug phooj ywg, koj xav pib qhov twg?",
              interestsStart: "Thaum koj xav txog kev tsim kev sib txuas tshiab los ntawm kev nyiam sib koom, koj xav pib qhov twg?",
              onlineStart: "Thaum koj xav txog kev tsim kev sib txuas tshiab hauv online, koj xav pib qhov twg?",
              schoolStart: "Thaum koj xav txog kev tsim kev sib txuas tshiab hauv tsev kawm ntawv, koj xav pib qhov twg?",
              shopsStart: "Thaum koj xav txog kev tsim kev sib txuas tshiab ntawm lub khw hauv zos, koj xav pib qhov twg?",
              workStart: "Thaum koj xav txog kev tsim kev sib txuas tshiab ntawm kev ua haujlwm, koj xav pib qhov twg?",
              howOften: "Ntau npaum li cas koj xav txuas rau hauv txoj kev no?",
            },
            characteristics: {
              organized: "Npaj",
              strong: "Muaj zog",
              dreamer: "Ib tug npau suav",
              determined: "Kev txiav txim",
              smart: "Ntse",
              artist: "Ib tug Artist",
              considerate: "Nco ntsoov",
              helpful: "Pab tau",
              creative: "Muaj tswv yim",
              thoughtful: "Kev xav",
              talented: "Muaj txuj ci",
              outgoing: "Tawm",
              leader: "Ib tug thawj coj",
              intelligent: "Txawj ntse",
              loving: "Kev hlub",
              understanding: "Kev nkag siab",
              spiritual: "Sab ntsuj plig",
              joyful: "Zoo siab",
              fixer: "Ib kho",
              liberal: "kev ywj pheej",
              patient: "Tus neeg mob",
              respectful: "Kev hwm",
              willing: "Zoo siab",
              intuitive: "Intuitive",
              gentle: "Maj mam",
              generous: "Siab dav",
              spontaneous: "Kev mob tshwm sim",
              independent: "Kev ywj pheej",
              supportive: "Txhawb nqa",
              resourceful: "Muaj peev txheej",
              experiential: "Kev paub",
              fun: "Lom zem",
              funny: "lom zem",
              excited: "Zoo siab heev",
              accepting: "Txais",
              busy: "Tsis khoom",
              pretty: "Zoo nkauj",
              openMinded: "Qhib siab",
              hardWorking: "nyuaj ua hauj lwm",
              doer: "Ib tug ua",
              involved: "Koom nrog",
              flexible: "Yooj yim",
              happy: "Zoo siab",
              careful: "Ceev faj",
              hopeful: "Cia siab",
              tinkerer: "Ib tug Tinkerer",
              riskTaker: "Ib tug Risk-Taker",
              lucky: "Muaj hmoo",
              active: "nquag",
              efficient: "Muaj txiaj ntsig",
              visionary: "Lub zeem muag",
              explorer: "Ib tug explorer",
              responsible: "Lub luag haujlwm",
              driven: "Tsav",
              confident: "Ntseeg",
              connector: "Ib txuas",
              wise: "Txawj ntse",
              expressive: "Qhia tawm",
              sensitive: "rhiab heev",
              talkative: "Hais lus",
              energized: "Ua kom muaj zog",
              giving: "Muab",
              observant: "Kev soj ntsuam",
              caring: "Kev saib xyuas",
              peaceful: "Kev thaj yeeb",
              cool: "Txias",
              fashionable: "zam",
              actor: "Tus neeg ua yeeb yam",
              writer: "Ib tug kws sau ntawv",
              vibrant: "Vibrant",
              goodCook: "Ib tug kws sau ntawv",
              goodWithAnimals: "Zoo Nrog Tsiaj",
            },
            summary: {
              newGoalPdfTitle: "Kev sib raug zoo tshiab",
              strengthenGoalPdfTitle: "Txhim khu kev sib raug zoo",
              relationshipType: "Txoj kev sib raug zoo kuv xav tsim yog",
              characteristics: "Kuv xav kom tus neeg no muaj cov yam ntxwv hauv qab no",
              where: "Kuv tuaj yeem ntsib tus neeg no",
              whereToStart: "Qhov twg kuv xav pib",
              howOften: "Ntau npaum li cas",
              beingInPerson: 'Ua tus neeg',
              talkingMore: 'Tham ntau ntxiv',
              facetiming: 'Facetiming',
              otherMeans: 'Lwm txoj kev',
              comments: "Cov lus",
              strengthenGoal: "Tus neeg uas kuv xav kom ntxiv dag zog rau kuv txoj kev sib raug zoo nrog yog",
              currentStatus: "Cov xwm txheej tam sim no ntawm peb txoj kev sib raug zoo yog",
              whatToStrengthen: "Dab tsi kuv xav kom ntxiv dag zog",
              strengthenBy: "Kuv xav kom ntxiv dag zog los ntawm ...",
              strengthenWhy: "Vim li cas?",
              whyAnswer: "Qhov no yuav pab kuv ua tiav kuv lub hom phiaj",
              becoming: "ua",
            },
          },
          planningHome: {
            sectionName: "Planning Home",
            sectionHeader: "Lub Hom Phiaj Nyob",
            subHeader: "Cia li saib seb koj nyiam lossis tsis nyiam txog koj qhov xwm txheej tam sim no",
            wantToLive: "Koj xav nyob qhov twg?",
            idealHome: "Koj lub tsev zoo li cas zoo li?",
            homeAmenities: "Dab tsi amenities koj xav kom koj lub tsev muaj?",
            homeCloseTo: "Koj xav kom koj lub tsev nyob ze li cas?",
            liveWith: "Puas muaj ib tug neeg koj xav nyob nrog?",
            roommateName: "leej twg?",
            roommateQuality: "Vim li cas koj xav tias nyob nrog tus neeg no yuav yog qhov zoo?",
            askedRoommate: "Koj puas tau nug txog kev nyob ua ke?",
            liveWithPets: "Puas muaj tsiaj nyob hauv koj lub neej yav tom ntej?",
            petType: "Tus tsiaj twg?",
            finalThoughts: "Puas muaj lwm yam uas koj xav tau hauv koj lub tsev zoo tshaj plaws uas peb tsis tau tham txog?",
            whatILike: "Qhov kuv nyiam",
            whatIDislike: "Qhov kuv Tsis nyiam",
            saveMessage: "Zoo heev, ua tsaug rau koj npaj rau koj lub tsev! Koj puas xav ntxiv lwm tus?",
            homeOptions: {
              singleStory: "Ib Zaj Dab Neeg",
              apartment: "Chav tsev",
              duplex: "Duplex",
              mobileHome: "Txawb Tsev",
              tinyHome: "Lub Tsev me me",
            },
            amenities: {
              laundry: "Khaub ncaws",
              internet: "Wireless Internet",
              recreation: "Chaw ua si lom zem",
              garden: "Zaj",
              deck: "lawj",
              patio: "Patio",
              land: "Lav",
              ramp: "txoj kev",
              pool: "Pab",
              bigDoors: "Qhov Rooj Loj",
            },
            proximities: {
              shopping: "Kev yuav khoom",
              bus: "nres tsheb npav",
              friends: "Cov phooj ywg",
              restaurants: "Tsev noj mov",
              entertainment: "Kev lom zem",
              publicTransit: "Public Tsheb thauj mus los",
              family: "Tsev neeg",
            },
            summary: {
              wantToLive: "Qhov twg kuv xav nyob",
              wantToLiveAnswer: "{{place}} hauv ib {{homeType}}",
              homeAmenities: "Hauv kuv lub tsev zoo tshaj plaws kuv xav tau",
              otherAmenities: "Lwm yam hauv kuv lub tsev zoo",
              homeCloseTo: "Kuv xav kom kuv lub tsev nyob ze",
              roommateName: "Kuv xav nyob nrog",
              liveAlone: "Kuv xav nyob ib leeg",
              roommateBecause: "vim {{vim}}",
              roommateQuality: "Vim li cas koj xav tias nyob nrog tus neeg no yuav yog qhov zoo?",
              petType: "Cov tsiaj",
              finalThoughts: "Lwm yam hauv kuv lub tsev zoo",
            },
          },
          planningFun: {
            sectionName: "Planning Fun",
            sectionHeader: "Lub hom phiaj lom zem",
            subHeader: "Cia peb pib muaj kev lom zem!",
            goal: "Thaum koj xav txog kev npaj tej yam lom zem, koj xav ua dab tsi?",
            goalWhy: "Dab tsi ua rau qhov no txaus siab rau koj? Los yog vim li cas koj xav ua qhov no?",
            goalWhen: "Thaum twg koj xav ua qhov no?",
            goalHowOften: "Koj xav ua qhov no ntau npaum li cas?",
            otherPeople: "Puas muaj leej twg koj xav ua qhov no nrog?",
            otherPeopleWhoCanSupport: "Thov xaiv leej twg tuaj yeem txhawb nqa koj",
            otherPeopleWhereToFind:
              "Thov xaiv cov pab pawg lossis qhov chaw uas yuav pab koj txuas rau cov neeg uas muaj kev sib koom siab",
            needToSave: "Koj puas xav txuag nyiaj los ua qhov no?",
            startedSaving: "Koj puas tau pib txuag?",
            amountToSave: "Koj tseem yuav txuag nyiaj npaum li cas?",
            attachedPeople: "txoj hlua",
            saveMessage: "Zoo li kev lom zem! Koj puas xav ntxiv lwm lub hom phiaj lom zem?",
            asap: "Raws li sai tau",
            sixMonths: "Tom ntej no rau lub hlis",
            oneYear: "6 hli mus rau ib xyoos",
            twoYears: "1-2 xyoos",
            twoPlusYears: "2+ xyoo",
            oneTime: "Ib zaug tshwm sim",
            daily: "Txhua hnub",
            weekly: "Lub lis piam",
            monthly: "Lub hli",
            quarterly: "Peb lub hlis twg",
            facebook: "Facebook",
            groupMeet: "Pab pawg ntsib",
            newspaper: "ntawv xov xwm hauv zos",
            coffeeShop: "Khaub Caw Lub Rooj Tswjhwm Saib Xyuas",
            communityCenter: "zej zog center",
            summary: {
              goal: "Thaum xav txog kev npaj ib yam dab tsi lom zem, kuv xav",
              goalWhy: "Qhov no txaus siab rau kuv vim",
              goalWhen: "Kuv xav ua qhov no",
              goalHowOften: "thiab ntau npaum li cas",
              otherPeople: "Kuv xav ua qhov no nrog",
              peopleInMyLife: "Cov neeg hauv kuv lub neej kuv xav ua qhov no nrog",
              actionSteps: "Cov kauj ruam ua haujlwm:",
              comments: "Cov lus",
              helpMeAchieve: "Qhov no yuav pab kuv ua tiav kuv lub hom phiaj",
              research: "Kev tshawb nrhiav txoj hauv kev los npaj rau txoj haujlwm no los ntawm",
              needToSave: "Kuv puas yuav tsum tau txuag rau qhov kev mus ncig no?",
              needToSaveYes: "Yog, Kuv yuav tsum tau txuag {{amount}}",
            },
          },
          planningTravel: {
            sectionName: "Npaj kev mus ncig",
            subHeader: "Cia peb pib npaj koj qhov kev lom zem tom ntej",
            destination: "Koj xav mus qhov twg?",
            travelTo: "Koj puas tau mus rau {{location}} ua ntej?",
            like: "Koj nyiam dab tsi txog {{location}}",
            appealingWhy: "Dab tsi ua rau {{location}} txaus siab rau koj?",
            travelWhen: "Thaum twg koj xav mus rau {{location}}?",
            withOthers: "Puas muaj leej twg koj xav mus nrog?",
            travelWithWho: "Thov xaiv leej twg koj xav mus nrog",
            toDoAtDestination: "Koj xav ua dab tsi thaum koj tuaj xyuas?",
            toSave: "Koj puas xav txuag nyiaj los ua qhov no?",
            startedToSave: "Koj puas tau pib txuag?",
            amountToSave: "Koj tseem yuav txuag nyiaj npaum li cas?",
            accommodations: "Koj puas muaj chaw nyob xav tau thaum koj mus ncig?",
            needsAndWhy: "Puas muaj tej yam koj xav tau lossis xav tau mus ncig nrog? Yog tias muaj, lawv yog dab tsi?",
            goal: "Koj xav sau lub hom phiaj no dab tsi?",
            saveMessage: "Kev lom zem tos! Koj puas xav ntxiv lwm lub hom phiaj mus ncig?",
            summary: {
              destination: "Kuv xav mus ncig",
              appealingWhy: "Vim li cas kuv thiaj xav mus ncig ntawm no",
              travelWhen: "Kuv xav mus ncig ntawm no",
              withOthers: "Kuv puas xav mus ncig nrog tib neeg",
              withOthersYes: "Yog, kuv xav mus nrog {{people}}",
              withOthersAlone: "Kuv xav mus ncig ib leeg",
              toDoAtDestination: "Thaum kuv tuaj xyuas kuv xav tau",
              accommodations: "Cov chaw pw kuv yuav xav tau thaum mus ncig suav nrog",
              needsAndWhy: "Lwm yam uas kuv xav nqa thaum kuv mus ncig suav nrog",
              needToSave: "Kuv puas yuav tsum tau txuag rau qhov kev mus ncig no?",
              needToSaveYes: "Yog, Kuv yuav tsum tau txuag {{amount}}",
            },
          },
          planningWork: {
            sectionName: "Planning Work",
            sectionHeader: "Cov Hom Phiaj Ua Haujlwm",
            addSubHeader: "Cia peb npaj rau yav tom ntej tshiab",
            whatILiked: "Qhov kuv nyiam",
            whatIDisliked: "Qhov kuv tsis nyiam",
            formSubHeader: "Cia li saib seb koj nyiam lossis tsis nyiam txog koj qhov kev ua haujlwm",
            workGoal: "Koj lub hom phiaj rau kev ua haujlwm yog dab tsi?",
            profession: "Dab tsi attracts koj rau txoj haujlwm no?",
            excites: "Dab tsi ua rau koj zoo siab tshaj plaws txog txoj haujlwm no?",
            goalLookLike: "Thaum koj xav txog koj lub hom phiaj, nws zoo li cas?",
            salary: "Koj puas xav ua ib xyoos ntau npaum li cas?",
            preferredWorkDays: "Dab tsi yog hnub koj nyiam ua haujlwm?",
            preferredWorkHours: "Koj lub sijhawm ua haujlwm nyiam dab tsi?",
            haveNow: "Koj muaj dab tsi tam sim no uas yuav coj koj mus rau koj lub neej yav tom ntej?",
            priorExperience: "Qhov kev paub dab tsi yuav pab txhawb koj mus rau koj lub neej yav tom ntej?",
            haveReference: "Koj puas paub ib tug neeg hauv txoj haujlwm no?",
            referenceName: "Leej twg yog lawv?",
            referenceWhere: "Lawv ua haujlwm qhov twg?",
            inLifeSupport: "Leej twg hauv koj lub neej tuaj yeem txhawb nqa koj hauv lub hom phiaj no?",
            support: "Puas muaj lwm tus neeg uas tuaj yeem pab koj ua tiav koj lub hom phiaj?",
            commit: "Koj tuaj yeem cog lus ua dab tsi los pab tsim lub neej yav tom ntej?",
            placeholderBudgetNeed: "Ntxig dab tsi koj xav tau rau pob nyiaj siv",
            placeholderResponsible: "Ntxig lub npe",
            placeholderHowOften: "Ntxig ntau npaum li cas nws yuav ua",
            placeholderImportant: "Ntxig vim li cas qhov no tseem ceeb",
            workPreferences: 'Kuv Nyiam Ua Haujlwm',
            monday: "Hnub Monday",
            tuesday: "Hnub Tuesday",
            wednesday: "Hnub Wednesday",
            thursday: "Hnub Thursday",
            friday: "Hnub Friday",
            saturday: "Hnub Saturday",
            sunday: "Hnub Sunday",
            morning: "Muaj",
            afternoon: "Tom qab",
            evening: "Hmo ntuj",
            overnight: "Ntawm hmo",
            anytime: "Txhua lub sijhawm",
            saveMessage: "Zoo li lub hom phiaj ua haujlwm zoo! Koj puas xav ntxiv lwm lub hom phiaj ua haujlwm?",
            budgetTable: {
              title: "Koj yuav tsum txuag nyiaj dab tsi los pab koj ua tiav koj lub hom phiaj?",
              actionStep: "Kauj ruam ua",
              saveFor: "Txuag nyiaj rau",
              saveMoneyFor: "Kuv yuav tsum txuag nyiaj li cas?",
              responsible: "Leej twg yog lub luag haujlwm?",
              howOften: "Ntau npaum li cas?",
              important: "Vim li cas qhov no tseem ceeb?",
              why: "Vim li cas?",
              contact: "Kev sib cuag",
              me: "Kuv",
              theyCanSupport: "Lawv tuaj yeem txhawb nqa kuv hauv kuv lub hom phiaj",
              thisWillHelpMe: "Qhov no yuav pab kuv ua tiav kuv lub hom phiaj",
              deleteConfirm: "Koj puas paub tseeb tias koj xav tshem tawm kab nyiaj txiag no?",
              deleteSuccess: "Ok, kab tau raug tshem tawm",
            },
            summary: {
              workGoal: "Kuv lub hom phiaj rau kev ua haujlwm",
              profession: "Dab tsi attracts kuv rau txoj hauj lwm no",
              excites: "Dab tsi excites kuv tshaj plaws txog qhov no yog",
              goalLookLike: "Thaum kuv xav txog kuv lub hom phiaj nws zoo li",
              haveNow: "Dab tsi yuav pab kuv ua tiav kuv lub neej yav tom ntej",
              preferredWorkDays: "Nyob zoo hnub ua hauj lwm",
              preferredWorkHours: "Xyoo ua hauj lwm nyiam",
              salary: "Xav tau nyiaj hli",
              haveReference: "Koj puas paub ib tug neeg hauv txoj haujlwm no?",
              referenceName: "Leej twg yog lawv?",
              referenceWhere: "Lawv ua haujlwm qhov twg?",
              inLifeSupport: "Leej twg hauv koj lub neej tuaj yeem txhawb nqa koj hauv lub hom phiaj no?",
              support: "Puas muaj lwm tus neeg uas tuaj yeem pab koj ua tiav koj lub hom phiaj?",
              commit: "Koj tuaj yeem cog lus ua dab tsi los pab tsim lub neej yav tom ntej?",
            },
          },
          GTKYPage: {
            pageTitle: "Tau Paub Koj",
            GTKYVideo: "Tau Paub Koj Video",
            likeAdmire: "Nyob zoo & qhuas",
            peopleInMyLife: "Cov neeg hauv kuv lub neej",
            thingsIDo: "Tej yam kuv nyiam ua kom lom zem",
            favoriteThings: "Kuv Nyiam Yam",
            myRoutines: "Kuv Txoj Cai",
            historyMap: "Daim ntawv qhia keeb kwm",
            travel: "Mus ncig",
            viewComments: "Saib cov lus"
          },
          myRoutines: {
            sectionName: "Kuv Txoj Cai",
            subHeader: "Qhia rau peb txhua tus paub tias koj txoj haujlwm zoo li cas",
            videoTitle: "Kuv Lub Sij Hawm - Pab Video",
            time: "Sijhawm",
            noTime: "Tsis muaj sij hawm",
            day: "Hnub",
            noDay: "Tsis Muaj Hnub",
            dailyError: "Thov sau lub sijhawm & piav qhia",
            weeklyError: "Thov sau ib hnub & piav qhia",
            successMessage: "Ua tsaug! Koj cov txheej txheem tau ntxiv lawm",
            dailyRoutines: "Koj txoj haujlwm niaj hnub yog dab tsi?",
            weeklyRoutines: "Koj cov kev ua txhua lub limtiam lossis kev ua yeeb yam dab tsi?",
            dailyPlaceholder: "Ntxiv qee yam koj ua txhua hnub thiab thaum koj ua",
            weeklyPlaceholder: "Ntxiv ib yam dab tsi koj ua txhua lub lim tiam",
            dayStickyTitle: "Kuv Hnub",
            weekStickyTitle: "Kuv Lub Limtiam",
            deleteMessage: "Koj puas paub tseeb tias koj xav tshem tawm qhov niaj hnub no?",
            deleteSuccess: "Ok, qhov niaj hnub raug tshem tawm",
            contributor: {
              subHeader: "Qhia rau peb txhua tus paub txog lawv txoj haujlwm zoo li cas",
              dailyRoutines: "Lawv niaj hnub ua dab tsi?",
              weeklyRoutines: "Yuav ua li cas yog lawv txhua lub lim tiam los yog rituals?",
              dailyPlaceholder: "Ntxiv qee yam lawv ua txhua hnub thiab thaum lawv ua",
              weeklyPlaceholder: "Ntxiv ib yam dab tsi uas lawv ua txhua lub lim tiam",
              dayStickyTitle: "{{name}}'s Day",
              weekStickyTitle: "{{name}}'s Week",
            },
            days: {
              mon: "Hnub Monday",
              tues: "Hnub Tuesday",
              wed: "Hnub Wednesday",
              thurs: "Hnub Thursday",
              fri: "Hnub Friday",
              sat: "Hnub Saturday",
              sun: "Hnub Sunday"
            }
          },
          thingsIDo: {
            sectionName: "Tej yam kuv nyiam ua kom lom zem",
            subHeader: "Xaiv koj cov haujlwm lossis ua ib qho tshiab",
            indoors: "Hauv tsev",
            outdoors: "Sab nraum zoov",
            frequencyCard: "Kuv nyiam ua qhov no:",
            likeMostCard: "Qhov kuv nyiam tshaj plaws txog qhov haujlwm no:",
            tellUsWhatYouDo: "Qhia peb txhua tus txog qhov koj nyiam ua lom zem!",
            indoor: "Indoor",
            outdoor: "Sab nraum zoov",
            community: "Nyob hauv kuv lub zej zog",
            whereToHaveFun: "Koj nyiam nyob qhov twg?",
            yourActivity: "Koj nyiam ua dab tsi?",
            chosenActivity: "Cov haujlwm uas koj xaiv yog",
            howOften: "Koj nyiam ua qhov no ntau npaum li cas?",
            aloneOrWithOthers: "Koj puas nyiam ua qhov haujlwm no ib leeg lossis nrog lwm tus?",
            attachedPeople: "Koj nyiam ua qhov no nrog leej twg?",
            addThingIDo: "Txoj haujlwm tshiab",
            otherPeopleInvolved: "Puas muaj lwm tus koj xav ua qhov haujlwm no nrog?",
            anyPhotos: "Koj puas muaj cov duab lom zem ntawm {{activity}} koj xav qhia?",
            like: "Koj nyiam dab tsi ntawm {{activity}}?",
            youSelected: "Koj qhov haujlwm:",
            saveMessage: "Yuav ua li cas taug txuj kev nyuaj! Koj puas xav ntxiv lwm?",
            deleteConfirm: "Koj puas paub tseeb tias koj xav rho tawm qhov haujlwm no?",
            deleteSuccess: "Koj qhov haujlwm tau raug tshem tawm lawm",
            daily: "Txhua hnub",
            weekly: "Lub lis piam",
            monthly: "Lub hli",
            oncePerYear: "Ib zaug ib xyoos",
            fewTimes: "Ob peb zaug hauv ib xyoos",
            whenever: "Thaum twg kuv tuaj yeem",
            contributorQuestions: {
              frequencyCard: "Lawv nyiam ua qhov no:",
              likeMostCard: "Qhov lawv nyiam tshaj plaws txog qhov haujlwm no:",
              tellUsWhatYouDo: "Qhia rau peb txhua tus paub txog yam lawv nyiam ua lom zem!",
              community: "Nyob hauv kuv lub zej zog",
              whereToHaveFun: "Lawv nyiam lom zem nyob qhov twg?",
              yourActivity: "Lawv nyiam ua dab tsi?",
              chosenActivity: "Cov haujlwm uas lawv xaiv yog",
              howOften: "Puas yog lawv nyiam ua qhov no?",
              aloneOrWithOthers: "Lawv puas nyiam ua qhov haujlwm no ib leeg lossis nrog lwm tus?",
              attachedPeople: "Leej twg lawv nyiam ua qhov no nrog?",
              otherPeopleInvolved: "Puas muaj lwm tus lawv xav ua qhov haujlwm no nrog?",
              anyPhotos: "Lawv puas muaj cov duab lom zem ntawm {{activity}} koj xav qhia?",
              like: "Lawv nyiam dab tsi ntawm {{activity}}?",
              youSelected: "Lawv ua haujlwm:",
              saveMessage: "",
            },
            activities: {
              reading: "Nyeem ntawv",
              writing: "Kev sau ntawv",
              yoga: "Yoga",
              pilates: "Pilates",
              videoGames: "Video ua si",
              cardGames: "Daim npav ua si",
              boardGames: "Board ua si",
              painting: "Kev pleev xim",
              instruments: "Cov cuab yeej",
              drawing: "Kev kos duab",
              art: "kos duab",
              replicaModels: "Cov qauv luam tawm",
              movies: "tsos",
              tv: "TV",
              movieTheater: "Movie ua yeeb yam",
              diningOut: "Noj hmo",
              yardSales: "vaj muag",
              antiquing: "cov khoom qub",
              mall: "Mus tom khw",
              shopping: "Khw",
              sports: "Kev ua kis las",
              walks: "Taug kev",
              birdWatching: "Saib noog",
              hiking: "taug kev",
              fishing: "Nuv ntses",
              gardening: "Kev ua vaj",
              camping: "Mus pw hav zoov",
              boating: "Nkoj",
              botany: "kev kawm txog nroj tsuag"
            },
            summary: {
              activity: "Kuv nyiam ua dab tsi",
              howOften: "Kuv nyiam ua qhov haujlwm no",
              withWho: "Kuv nyiam ua qhov no nrog",
              withWhoAlone: "Kuv ua qhov no",
              byMyself: "ntawm kuv tus kheej",
              like: "Qhov kuv nyiam tshaj plaws yog",
              buttonText: "Mus rau qhov kuv nyiam ua kom lom zem",
            },
          },
          likeAndAdmire: {
            sectionName: "Zoo thiab qhuas",
            sectionTitle: "Lub taub hau, lub plawv, tes",
            subTitle: "Lag thiab tso koj cov khoom plig rau hauv qeb koj xaiv lossis tsim koj tus kheej",
            videoTitle: "Zoo thiab qhuas - Pab Video",
            filterAll: "Txhua yam nyiam & qhuas (Default)",
            filterOthers: "Yuav ua li cas lwm tus nyiam & qhuas txog kuv",
            filterMine: "Qhov kuv nyiam & qhuas txog kuv tus kheej",
            resetAll: "Reset Txhua",
            addNew: "Ntxiv lo lus tshiab",
            saveMessage: "Zoo heev {{firstName}}, nws tau hnov txhua yam zoo txog koj!",
            head: "Lub taub hau",
            heart: "Lub plawv",
            hand: "tes",
            headExplanation: "Cov khoom plig ntawm lub siab, lub hlwb, lossis kev xav; cov khoom plig uas koj paub",
            heartExplanation: "Cov khoom plig uas koj xav tias txuas nrog lossis mob siab rau",
            handExplanation: "Cov khoom plig koj tuaj yeem muab tau los ntawm kev ua lossis los ntawm kev ua",
            resetMessage: "Koj puas paub tseeb tias koj xav rov pib dua tag nrho?",
            resetSuccess: "Ok, cov khoom tau rov pib dua",
            deleteMessage: "Koj puas paub tseeb tias koj xav rho tawm qhov khoom plig no?",
            deleteSuccess: "Khoom plig tau tiav lawm",
            contributor: {
              subTitle: "Lag thiab tso lawv cov khoom plig rau hauv qeb koj xaiv lossis tsim koj tus kheej",
              filterOthers: "Yuav ua li cas lwm tus nyiam & qhuas txog lawv",
              filterMine: "Qhov lawv nyiam & qhuas txog lawv tus kheej",
            },
            summary: {
              head: "Lub taub hau",
              heart: "Lub plawv",
              hand: "tes",
            },
          },
          peopleInMyLife: {
            sectionName: "Cov neeg hauv kuv lub neej",
            subHeader: "Ntxiv ib tus neeg lossis nyem rau ntawm tus neeg lub npe kom ntxiv cov ntsiab lus ntxiv txog lawv",
            adaptionDisclaimer: "Kev kho kom haum ntawm Daim Ntawv Qhia Kev Sib Raug Zoo",
            acquaintance: "Kev paub",
            acquaintances: "Cov neeg paub",
            add: "Ntxiv neeg",
            addAsContributor: "Ntxiv ua tus pab txhawb rau kuv txoj kev npaj",
            close: "Kaum",
            contributor: "Pab txhawb nqa",
            contributorAdditions: "Pab txhawb ntxiv",
            coWorker: "Cov neeg ua haujlwm",
            coWorkers: "Cov neeg ua haujlwm",
            deletePerson: "Koj puas paub tseeb tias koj xav tshem tus neeg no tawm ntawm Cov Neeg hauv koj lub neej?",
            edit: "Kho kom raug neeg",
            family: "Tsev neeg",
            favoritePerson: "Tus neeg nyiam lub hli",
            friend: "Tus phooj ywg",
            friends: "Cov phooj ywg",
            howClose: "Koj nyob ze tus neeg no npaum li cas?",
            importantWhy: "Vim li cas tus neeg no tseem ceeb rau koj",
            involvedInSupport: "Kev koom tes hauv kuv qhov kev txhawb nqa",
            mapPlace: "Qhov no yuav txiav txim siab lawv qhov chaw ntawm koj Cov Neeg Hauv Kuv Lub Neej Daim Ntawv Qhia",
            notClose: "Tsis Kaw",
            paidSupport: "Tus neeg no puas them nyiaj yug?",
            paidSupportShort: "Kev them nyiaj yug",
            personIs: "Tus neeg no yog...",
            relationshipToYou: "Kev sib raug zoo rau koj",
            removePerson: "Tshem tus neeg",
            weAre: "Peb yog...",
            saveMessage: "Txhua tus neeg hauv koj lub neej tau txais kev cawmdim, ua haujlwm zoo.",
            me: 'Kuv',
            contributorQuestions: {
              howClose: "Lawv ze npaum li cas rau tus neeg no?",
              importantWhy: "Vim li cas tus neeg no tseem ceeb rau lawv",
              mapPlace: "Qhov no yuav txiav txim siab lawv qhov chaw ntawm Cov Neeg Hauv Kuv Lub Neej Daim Ntawv Qhia",
              relationshipToYou: "Kev sib raug zoo rau lawv",
              theyAre: "Lawv yog...",
              involvedInSupport: "Kev koom tes hauv lawv txoj kev txhawb nqa",
            },
            dialog: {
              nameError: "Thov sau lub npe",
              personIsError: "Thov xaiv ib qho kev xaiv",
              emailError: "Yuav tsum sau email chaw nyob rau cov neeg muaj peev xwm koom nrog",
              emailValidError: "Thov sau qhov chaw nyob email siv tau",
              sendInvitation: "Xa lus caw",
            },
          },
          favoriteThings: {
            sectionName: "Kuv Nyiam Yam",
            subHeader: "Ntxiv cov khoom koj nyiam los ntawm kev xaiv thawj qeb",
            contributorSubHeader: "Thov xaiv ib qeb los pib ntxiv cov khoom uas lawv nyiam",
            viewOnlySubHeader: "Cia li saib lawv cov khoom nyiam",
            addDialogTitle: "Ntxiv Ib Yam Nyiam",
            editDialogTitle: "Kho kom raug koj nyiam yam",
            categories: "Pawg",
            categoryList: "Qeb lis",
            myFavorites: "Kuv Nyiam",
            theirFavorites: "Lawv Favorites",
            place: "Qhov chaw",
            placeToEat: "Qhov chaw noj",
            food: "Cov khoom noj",
            movie: "tsos",
            book: "Phau ntawv",
            videoGame: "Video ua si",
            game: "Kev ua si",
            sport: "Kev ua si nawv",
            animal: "Tsiaj",
            music: "Nkauj",
            memory: "Nco",
            other: "Lwm yam",
            placeLabel: "Koj qhov chaw nyiam mus",
            placeToEatLabel: "Koj qhov chaw nyiam noj",
            foodLabel: "Koj nyiam zaub mov",
            movieLabel: "Koj nyiam movie",
            bookLabel: "Koj nyiam phau ntawv",
            videoGameLabel: "Koj nyiam video ua si",
            gameLabel: "Koj nyiam kev ua si",
            sportLabel: "Koj nyiam ntaus kis las lossis pab pawg kis las",
            animalLabel: "Koj nyiam tsiaj lossis hom tsiaj",
            musicLabel: "Koj nyiam band, nkauj lossis ib hom ntawv",
            memoryLabel: "Ib qho ntawm koj nyiam nco",
            otherLabel: "Txhua yam uas nyiam",
            chooseAView: "Xaiv qhov pom",
            allThings: "Txhua Yam Nyiam (Default)",
            thingsIAdded: "Cov khoom nyiam kuv tau ntxiv",
            thingsOthersAdded: "Dab tsi lwm tus tau ntxiv",
            treasureChestAlt: "Favorite Yam Treasure Chest",
            deleteConfirm: "Koj puas paub tseeb tias koj xav rho tawm qhov nyiam tshaj plaws no?",
            deleteSuccess: "Yam uas nyiam tau raug muab tshem tawm",
            saveAlertText: "Ua tsaug rau koj {{npe}}, nws yog qhov zoo kawm txog yam koj nyiam!",
            plurals: {
              place: "Qhov chaw",
              placeToEat: "Qhov chaw noj mov",
              movie: "tsos",
              book: "Phau ntawv",
              videoGame: "Video ua si",
              game: "Ua si",
              sport: "Kev ua kis las",
              animal: "Tsiaj",
              memory: "Nco",
            }
          },
          travelPlan: {
            sectionName: "Mus ncig",
            sectionHeader: "Mus ncig",
            subHeader: "Qhia koj qhov chaw nyiam mus ncig lossis qhov chaw koj xav mus",
            travelLocally: "Koj puas nyiam mus ncig hauv Tebchaws Meskas lossis Thoob Ntiaj Teb?",
            local: "Hauv Tebchaws Meskas",
            international: "Thoob ntiaj teb",
            frequencyCard: "Kuv nyiam ua qhov no:",
            likeMostCard: "Qhov kuv nyiam tshaj plaws txog cov chaw no:",
            pageSubHeader: "Qhia peb txhua tus txog qhov koj nyiam mus ncig",
            travelWhere: "Koj nyiam mus qhov twg?",
            likeMost: "Koj nyiam dab tsi txog kev mus rau {{location}}?",
            likeToTravel: "Koj nyiam mus ncig li cas?",
            alone: "Koj puas nyiam ua qhov no ib leeg lossis nrog lwm tus?",
            howOften: "Koj nyiam mus ncig ntawm no ntau npaum li cas?",
            anyoneElse: "Puas muaj lwm tus koj xav mus ncig nrog?",
            anyPictures: "Koj puas muaj cov duab lom zem ntawm {{location}} uas koj xav qhia?",
            deleteMessage: "Koj puas paub tseeb tias koj xav rho tawm qhov chaw no?",
            addTravelPlan: "Ntxiv lwm qhov chaw koj nyiam mus ncig",
            addTravelContributor: "Ntxiv lwm qhov chaw koj xav tias lawv xav mus ncig",
            attachedPeople: "Koj nyiam mus nrog leej twg?",
            boat: "Nkoj",
            bus: "Tsheb npav",
            car: "Tsheb",
            plane: "Dav hlau",
            train: "Tsheb ciav hlau",
            saveText: "Yuav ua li cas taug txuj kev nyuaj! Koj puas xav ntxiv lwm?",
            deleteConfirm: "Koj puas paub tseeb tias koj xav rho tawm qhov haujlwm no?",
            deleteSuccess: "Koj qhov haujlwm tau raug tshem tawm lawm",
            contributorQuestions: {
              subHeader: "Qhia lawv qhov chaw nyiam mus ncig lossis qhov chaw lawv xav mus",
              frequencyCard: "Lawv nyiam ua qhov no:",
              likeMostCard: "Dab tsi lawv nyiam tshaj plaws txog cov chaw no:",
              pageSubHeader: "Qhia rau peb txhua tus txog qhov lawv nyiam mus ncig",
              travelLocally: "Lawv puas nyiam mus ncig hauv Tebchaws Meskas lossis Thoob Ntiaj Teb?",
              travelWhere: "Lawv nyiam mus ncig qhov twg?",
              likeMost: "Lawv nyiam dab tsi txog kev mus ncig rau {{location}}?",
              likeToTravel: "Lawv nyiam mus ncig li cas?",
              alone: "Lawv puas nyiam ua qhov no ib leeg lossis nrog lwm tus?",
              howOften: "Puas yog lawv nyiam mus ncig ntawm no?",
              anyoneElse: "Puas muaj lwm tus uas lawv xav mus nrog?",
              anyPictures: "Lawv puas muaj cov duab lom zem ntawm {{location}} uas koj xav qhia?",
              attachedPeople: "Lawv nyiam mus ncig nrog leej twg?",
            },
            summary: {
              place: "Kuv nyiam mus ncig ua si",
              travelWithSolo: "Kuv nyiam mus ncig ntawm no",
              byMyself: "Los ntawm kuv tus kheej",
              travelWith: "Kuv nyiam mus ncig ntawm no nrog",
              frequency: "Kuv mus qhov ntawd",
              frequencyAnswer: "{{frequency}} thiab kuv mus los ntawm {{transportation}}",
              favoriteThing: "Kuv nyiam tshaj plaws txog kev mus rau {{place}} yog",
              buttonText: "Nyob Mus Ib Txhis",
            },
          },
          historyMap: {
            sectionHeader: "Kuv Daim Ntawv Qhia Keeb Kwm",
            subHeader: "Qhia rau peb txog qhov tseem ceeb, hnub tseem ceeb lossis cov xwm txheej hauv koj lub neej",
            addMilestone: "Ntxiv qhov tseem ceeb",
            editMilestone: "Kho kom raug qhov tseem ceeb",
            sectionName: "Daim ntawv qhia keeb kwm",
            category: "Qeb",
            familyLife: "Tsev Neeg Lub Neej",
            school: "Tsev kawm ntawv",
            activities: "Kev ua si",
            friends: "Cov phooj ywg",
            housing: "Lub tsev",
            work: "Ua haujlwm",
            relationships: "Kev sib raug zoo",
            chooseDate: "Xaiv hnub",
            milestone: "tseem ceeb",
            details: "Cov ntsiab lus",
            back: "Rov qab los Paub Koj",
            saveMessage: "Nws yog ib qho kev kawm zoo rau koj, ua tsaug rau kev sib koom!",
            validDateError: "Thov sau hnub tim uas siv tau",
            rangeDateError: "Hnub tim tsis muaj ntau",
            deleteMessage: "Koj puas paub tseeb tias koj xav rho tawm qhov tseem ceeb no?",
            deleteSuccess: "Koj qhov tseem ceeb tau ua tiav tiav lawm",
          },
          contributor: {
            addToRelationshipMap: "Ntxiv rau kuv daim ntawv qhia kev sib raug zoo",
            thisPersonIs: "Tus neeg no yog ib tug....",
            howClose: "Koj nyob ze tus neeg no npaum li cas?",
            close: "Kaum",
            notClose: "Tsis Kaw",
            setPermissions: "Teeb tsa kev tso cai rau tus neeg koom tes",
            hasBeenEmailed: "Ib qho kev caw tau xa mus rau koj tus neeg koom tes",
          },
          happeningPage: {
            pageTitle: "Zoo Nyob Hauv Kuv Lub Neej",
            living: "Nyob Hauv Kuv Lub Tsev",
            work: "Ua haujlwm",
            school: "Tsev kawm ntawv",
            health: "Kev Noj Qab Haus Huv & Kev Nyab Xeeb",
            happeningVideo: "Nyob Hauv Kuv Lub Neej Video",
          },
          happeningHome: {
            pageTitle: "Nyob Hauv Kuv Lub Tsev",
            subTitle: "Cia peb paub ntxiv txog qhov koj nyob qhov twg!",
            singleStory: "Ib zaj dab neeg hauv tsev",
            multiLevel: "Ntau theem tsev",
            apartment: "Chav tsev",
            facility: "Chaw",
            homeless: "Tsis muaj tsev nyob",
            groupHome: "Pab pawg tsev",
            car: "Tsheb",
            rvCamper: "Lub tsheb ua si",
            currentlyLive: "Koj nyob qhov twg tam sim no?",
            currentlyLiveWith: "Koj tam sim no nyob nrog leej twg?",
            parents: "Niam txiv",
            guardians: "Tus Saib Xyuas",
            siblings: "Cov kwv tij",
            friends: "Tus phooj ywg",
            roommates: "Cov phooj ywg",
            spouse: "Tus txij nkawm",
            children: "Me nyuam/Me nyuam",
            alone: "Kuv nyob ib leeg",
            dogs: "Tus dev",
            cats: "miv",
            other: "Lwm yam, thov piav qhia",
            explain: "Thov piav",
            tableTitle: "Koj nyiam thiab tsis nyiam dab tsi txog qhov koj nyob?",
            amenityHeader: "khoom siv",
            selectHeader: "Thov xaiv nyiam lossis tsis nyiam",
            commentHeader: "Cov lus",
            commentsLikes: "Cov lus hais txog kuv nyiam",
            commentsDislikes: "Cov lus hais txog kuv qhov tsis nyiam",
            tellUsWhy: "Thov qhia rau peb paub vim li cas ntawm no",
            whatILike: 'Kuv nyiam dab tsi txog qhov uas kuv nyob tam sim no',
            whatIDislike: 'Qhov kuv tsis nyiam txog qhov chaw uas kuv nyob tam sim no',
            amenities: {
              publicTransportation: "Kev thauj mus los rau pej xeem",
              accessibility: "Kev siv tau",
              neighborhood: "Zej zog",
              friendsFamily: "Zoo rau Cov Phooj Ywg thiab Tsev Neeg",
              shopping: "Zoo rau kev yuav khoom",
              furniture: "Rooj tog",
              homeType: "Tom Tsev",
              squareFootage: "Square Footage",
              housemates: "Cov phooj ywg",
              livingAlone: "Nyob ntawm kuv tus kheej",
              neighbors: "Cov neeg zej zog",
              management: "Kev tswj vaj tse",
              support: "Cov neeg ua haujlwm txhawb nqa",
              tellUsWhy: "Thov qhia peb vim li cas",
            },
            summary: {
              currentlyLive: "Kuv nyob qhov twg",
              currentlyLiveWith: "Kuv nyob nrog leej twg",
            },
          },
          happeningSchool: {
            header: "Tsev kawm ntawv",
            subHeader: "Peb tos tsis tau hnov txog tsev kawm ntawv ntxiv, qhia peb txog koj qhov kev paub",
            enrolledInSchool: "Koj puas nyob hauv tsev kawm ntawv tam sim no?",
            schoolNameOOS: "Koj mus kawm ntawv twg?",
            gradeLevelOOS: "Koj kawm tau qib twg?",
            gradYearOOS: "Thaum twg koj kawm tiav tsev kawm ntawv?",
            schoolLikeOOS: "Koj nyiam dab tsi txog tsev kawm ntawv?",
            schoolDislikeOOS: "Koj tsis nyiam tsev kawm ntawv dab tsi?",
            volunteerOOS: "Thaum nyob hauv tsev kawm ntawv koj puas ua haujlwm pub dawb nyob qhov twg?",
            volunteerWhereOOS: "Koj tuaj yeem pab dawb nyob qhov twg?",
            volunteerDislikeOOS: "Dab tsi koj tsis nyiam?",
            volunteerLikeOOS: "Koj nyiam dab tsi txog kev ua haujlwm pub dawb?",
            graduationGoalOOS: "Nws yog dab tsi?",
            gradYearOtherOOS: "",
            goalYesOOS: "Puas muaj qee yam koj xav ua thaum koj kawm tiav?",
            schoolName: "Koj mus kawm ntawv twg?",
            gradeLevel: "Koj nyob qib twg?",
            gradYear: "Thaum twg koj ua tiav nrog tsev kawm ntawv?",
            schoolLike: "Koj nyiam dab tsi txog tsev kawm ntawv?",
            schoolDislike: "Koj tsis nyiam tsev kawm ntawv dab tsi?",
            volunteer: "Koj puas tuaj yeem pab dawb nyob qhov twg?",
            volunteerWhere: "Koj tuaj yeem pab dawb nyob qhov twg?",
            volunteerDislike: "Koj tsis nyiam dab tsi?",
            volunteerLike: "Koj nyiam dab tsi txog kev ua haujlwm pub dawb?",
            graduationGoal: "Nws yog dab tsi?",
            gradYearOther: "",
            goalYes: "Puas muaj qee yam koj xav ua thaum koj kawm tiav?",
            summary: {
              schoolName: "Lub tsev kawm ntawv kuv mus yog hu ua",
              schoolNameOOS: "Lub tsev kawm ntawv kuv mus yog hu ua",
              gradeLevel: "Kuv nyob qib",
              gradeLevelOOS: "Kuv kawm tiav qib",
              gradYear: "Kuv yuav ua tiav nrog tsev kawm ntawv hauv",
              gradYearOOS: "Kuv tau ua tiav nrog tsev kawm ntawv hauv",
              schoolLike: "Kuv nyiam dab tsi txog tsev kawm ntawv",
              schoolLikeOOS: "Kuv nyiam dab tsi txog tsev kawm ntawv",
              schoolDislike: "Qhov kuv tsis nyiam tsev kawm ntawv",
              schoolDislikeOOS: "Dab tsi kuv tsis nyiam txog tsev kawm ntawv",
              volunteerWhere: "Hauv tsev kawm ntawv kuv tuaj yeem pab dawb ntawm",
              volunteerWhereOOS: "Hauv tsev kawm ntawv kuv ua haujlwm pub dawb ntawm",
              volunteerLike: "Qhov kuv nyiam ua haujlwm pub dawb",
              volunteerLikeOOS: "Qhov kuv nyiam ua haujlwm pub dawb",
              volunteerDislike: "Qhov kuv tsis nyiam ua haujlwm pub dawb",
              volunteerDislikeOOS: "Dab tsi kuv tsis nyiam txog kev ua haujlwm pub dawb",
              graduationGoal: "Thaum kuv kawm tiav, kuv xav tau",
              graduationGoalOOS: "Thaum kuv kawm tiav, kuv xav tau",
            },
          },
          happeningWork: {
            sectionName: "Kev Ua Haujlwm",
            subHeader: "Qhia rau peb ntxiv txog koj qhov kev paub ua haujlwm, peb tos tsis tau hnov!",
            pastExperience: "Thov qhia koj qhov kev paub dhau los ua haujlwm",
            pastExperienceLike: "Koj nyiam dab tsi txog koj qhov kev ua haujlwm yav dhau los?",
            pastExperienceDislike: "Koj tsis nyiam dab tsi txog koj qhov kev ua haujlwm yav dhau los?",
            hasCurrentEmployment: "Koj puas ua haujlwm tam sim no?",
            currentEmployment: "Koj nyob qhov twg tam sim no ua haujlwm?",
            currentDuration: "Koj tau ua haujlwm ntev npaum li cas?",
            currentLike: "Koj nyiam dab tsi txog koj txoj haujlwm tam sim no?",
            currentDislike: "Koj tsis nyiam dab tsi txog koj txoj haujlwm tam sim no?",
            summary: {
              pastExperience: "Qhov twg kuv tau ua haujlwm yav dhau los",
              pastExperienceLike: "Kuv nyiam qhov twg kuv tau ua haujlwm yav dhau los",
              pastExperienceDislike: "Qhov kuv tsis nyiam qhov twg kuv tau ua haujlwm yav dhau los",
              currentEmployment: "Tam sim no kuv ua haujlwm ntawm",
              currentDuration: "Kuv tau ua haujlwm ntawm no rau",
              currentLike: "Kuv nyiam qhov twg kuv ua haujlwm",
              currentDislike: "Qhov kuv tsis nyiam qhov twg kuv ua haujlwm",
            },
          },
          happeningHealth: {
            header: "Kev Noj Qab Haus Huv & Kev Nyab Xeeb",
            subHeader: "Cia peb paub koj me ntsis ntxiv, thov qhia qhov koj nyiam",
            contributorAdditionsToApprove:
              "Koj cov neeg koom nrog tau ntxiv DME's thiab / lossis Kev Txhawb Nqa rau koj txoj kev npaj. Koj puas xav pom zoo rau lawv?",
            medInfo: "Puas muaj dab tsi txog kev noj qab haus huv cuam tshuam rau koj lub neej?",
            allergies: "Thov sau cov kev ua xua uas koj xav kom peb paub txog",
            dmeSectionTitle: "Yuav ua li cas hom khoom siv kho mob ntev thiab kev txhawb nqa koj siv?",
            dmeContributorTitle: "Yuav ua li cas hom khoom siv kho mob ntev thiab kev txhawb nqa lawv siv?",
            sectionSubTitle:
              "Xaiv ib lub pob ntxiv rau koj daim ntawv teev npe, nyem qhov 'x' hauv koj daim ntawv kom tshem nws",
            supportsSectionTitle: "Yuav ua li cas lwm tus tuaj yeem txhawb koj hauv kev xav thiab noj qab haus huv?",
            supportContributorTitle: "Yuav ua li cas lwm tus tuaj yeem txhawb nqa lawv hauv kev xav thiab noj qab haus huv?",
            dmePostItTitle: "Qhov kev txhawb nqa zoo zoo li cas",
            supportPostItTitle: "Yuav ua li cas lwm tus tuaj yeem txhawb nqa kuv",
            dmeOptions: {
              bloodSugarMonitors: "Kev ntsuas ntshav qab zib",
              bloodSugarTestStrips: "Ntshav qab zib kuaj strips",
              canes: "cov kab",
              commodeChairs: "Cov rooj zaum",
              continuousPassiveDevices: "Nruam passive motion devices",
              continuousPassiveAirwayPressureDevices: "Nruam Cov Cuab Yeej Zoo Tshaj Plaws (CPAP) cov cuab yeej",
              crutches: "ntoo ntoo",
              hospitalBeds: "pw tsev kho mob",
              infusionPumps: "Infusion twj & khoom siv",
              lancetDevices: "Lancet devices & lancets",
              nebulizerMedications: "Nebulizers & nebulizer tshuaj",
              oxygenEquipment: "Oxygen khoom & accessories",
              patientLifts: "Tus neeg mob nqa",
              supportSurfaces: "Pressure-txo cov chaw txhawb nqa",
              suctionPumps: "Suction twj",
              tractionEquipment: "Cov khoom siv traction",
              walkers: "Walkers",
              rollator: "Rollator",
              wheelchairMotorized: "Lub rooj zaum - tsav tsheb",
              wheelchairManual: "Lub rooj zaum - phau ntawv",
              wheelchairStanding: "Lub rooj zaum - Reclining / Sawv",
              scooterMotorized: "Scooter - tsav tsheb",
              scooterManual: "Scooter - Phau ntawv",
              assistiveTech: "pab Technology",
              translator: "Tus txhais lus",
              brailleKeyboard: "Cov keyboard Braille",
              hearingAid: "Kev pab hnov lus",
            },
            supportOptions: {
              cookMeals: "Ua rau kuv noj mov",
              prepareMeals: "Pab kuv npaj kuv pluas noj",
              prepareMedication: "Pab kuv npaj kuv cov tshuaj",
              medicationSchedule: "Pab kuv tsim ib lub sijhawm noj tshuaj",
              doctorTransportation: "Pab kuv mus thiab los ntawm cov kws kho mob",
              createBudget: "Pab kuv tsim ib pob nyiaj siv",
              beActive: "Pab txhawb kuv kom nquag plias",
            },
            summary: {
              medInfo: "Txoj kev noj qab haus huv uas kuv xav kom lwm tus paub txog suav nrog",
              allergies: "Cov kev tsis haum kuv xav kom lwm tus paub txog suav nrog",
              dmeSectionTitle: "Cov Kev Pab Kho Mob Ntev uas kuv siv suav nrog",
              supportsSectionTitle: "Cov neeg hauv kuv lub neej tuaj yeem txhawb nqa kuv raws li hauv qab no",
            },
          },
          happeningGeneral: {
            sectionSave:
              "Ua tsaug rau cov ntaub ntawv {{userFirstName}}, koj tuaj yeem txav mus rau lwm ntu ntawm Dab tsi tshwm sim hauv Koj Lub Neej!",
          },
          planSummary: {
            generatePdf: "Tsim PDF",
            downloadPdf: "Download tau PDF",
            selectTravelPlan: "Xaiv koj txoj kev npaj mus ncig",
            selectActivity: "Xaiv koj txoj haujlwm",
            selectGoal: "Xaiv lub hom phiaj",
            sections: {
              importantToMe: "Dab tsi tseem ceeb rau kuv",
              importantForMe: "Dab tsi tseem ceeb rau kuv",
              goals: "Lub Hom Phiaj Npaj",
            },
            cardTitles: {
              likeAdmire: "Dab tsi tib neeg nyiam thiab qhuas txog kuv",
              people: "Cov neeg tseem ceeb hauv kuv lub neej",
              milestones: "tseem ceeb Tseem Ceeb hauv Kuv Lub Neej",
              favoriteThings: "Ib txhia ntawm Kuv Nyiam Yam",
              thingsIDo: "Kuv nyiam ua dab tsi rau kev lom zem",
              travelPlan: "Qhov chaw kuv xav mus ncig",
              dailyRoutines: "Kuv Lub Neej Txhua Hnub",
              weeklyRoutines: "Kuv Cov Lus Qhia Txhua Lub Limtiam",
              happeningHome: "Nyob Hauv Kuv Lub Tsev",
              happeningWork: "Kuv Txoj Kev Ua Haujlwm",
              happeningSchool: "Kuv Tsev Kawm Ntawv Kev Paub",
              happeningHealth: "Kuv Kev Noj Qab Haus Huv & Kev Nyab Xeeb",
              homeGoals: "Kuv Lub Hom Phiaj Nyob",
              workGoals: "Kuv Lub Hom Phiaj Ua Haujlwm",
              healthGoals: "Kuv Lub Hom Phiaj Kev Noj Qab Haus Huv",
              funGoals: "Kuv Lub Hom Phiaj Lom Zem",
              relationshipGoals: "Kuv Lub Hom Phiaj Kev Sib Raug Zoo",
              travelGoals: "Kuv Lub Hom Phiaj Mus Los",
            },
            cardDescriptions: {
              likeAdmire: "Nov yog yam uas kuv nyiam thiab qhuas txog kuv tus kheej thiab qhov uas kuv cov neeg koom siab txaus siab rau kuv",
              people: "Nov yog cov neeg tseem ceeb tshaj plaws hauv kuv lub neej.",
              milestones: "Tshawb nrhiav txhua qhov tseem ceeb hauv kuv lub neej uas kuv txaus siab tshaj plaws!",
              favoriteThings: "Cov no tsuas yog ob peb yam uas kuv nyiam tshaj",
              thingsIDo: "Nov yog qee yam uas kuv nyiam ua kom lom zem!",
              travelPlan: "Nrhiav seb qhov twg kuv nyiam mus ncig, nrog leej twg, thiab qhov kuv nyiam mus nrog dab tsi",
              dailyRoutines: "Tshawb nrhiav qhov tseem ceeb rau kuv txhua hnub thiab dab tsi ua rau hnub zoo!",
              weeklyRoutines: "Nrhiav kom paub ntau ntxiv txog kuv qhov kev nyiam, kab lis kev cai, thiab kev niaj hnub!",
              happeningHome: "Nws yog ib qho tseem ceeb rau kuv qhia kuv qhov xwm txheej tam sim no, qhov kuv nyiam thiab tsis nyiam txog nws.",
              happeningWork: "Nrhiav kom paub ntau ntxiv txog kuv cov kev paub dhau los nrog kev ua haujlwm, qhov kuv nyiam thiab tsis nyiam txog nws!",
              happeningSchool: "Tshawb nrhiav me ntsis ntxiv txog kuv thiab kuv qhov kev paub hauv tsev kawm ntawv!",
              happeningHealth: "Nov yog yam uas kuv xav kom sib qhia txog kuv txoj kev noj qab haus huv, kev nyab xeeb, thiab lwm tus tuaj yeem ua tau los txhawb kuv.",
              homeGoals: "Xav paub ntxiv txog qhov kuv xav nyob",
              workGoals: "Kawm txog kuv txoj haujlwm npau suav",
              healthGoals: "Nrhiav seb kuv xav npaj li cas rau kuv txoj kev noj qab haus huv",
              funGoals: "Nrhiav seb kuv xav ua dab tsi rau kev lom zem yav tom ntej",
              relationshipGoals: "Kawm ntxiv txog seb kuv xav txuas nrog lwm tus li cas",
              travelGoals: "Nrhiav seb kuv vam tias yuav mus qhov twg",
            },
            noContent: {
              milestones: "Tsis muaj Milestones tau ntxiv lawm. Nyem lub pob hauv qab no kom pib!",
              favoriteThings: "",
              thingsIDo: "Tsis Muaj Yam Kuv Ua Rau Kev Lom Zem tau raug tsim. Nyem lub pob hauv qab no los tsim ib qho!",
              travelPlan: "Tsis muaj Chaw Mus Ncig tau tsim. Nyem lub pob hauv qab no los tsim ib qho!",
              dailyRoutines: "Tsis muaj Cov Txheej Txheem Txhua Hnub tau ntxiv lawm. Nyem lub pob hauv qab no kom pib!",
              weeklyRoutines: "Tsis muaj Cov Txheej Txheem Txhua Lub Limtiam tau ntxiv lawm. Nyem lub pob hauv qab no kom pib!",
              happeningHome: "Tsis muaj cov ntaub ntawv nyob hauv Kuv Lub Tsev tau raug kaw lawm. Nyem lub pob hauv qab no kom pib!",
              happeningWork: "Tsis muaj ntaub ntawv qhia txog Kev Ua Haujlwm tau raug kaw. Nyem lub pob hauv qab no kom pib!",
              happeningSchool: "Tsis muaj tsev kawm ntawv cov ntaub ntawv raug kaw. Nyem lub pob hauv qab no kom pib!",
              happeningHealth: "Tsis muaj cov ntaub ntawv kho mob & kev nyab xeeb tau raug kaw. Nyem lub pob hauv qab no kom pib!",
              homeGoals: "Tsis muaj lub hom phiaj vaj tse tau tsim. Nyem lub pob hauv qab no los tsim ib qho!",
              workGoals: "Tsis muaj lub hom phiaj ua haujlwm tau tsim. Nyem lub pob hauv qab no los tsim ib qho!",
              healthGoals: "Tsis muaj cov hom phiaj kev noj qab haus huv tau tsim. Nyem lub pob hauv qab no los tsim ib qho!",
              funGoals: "Tsis muaj lub hom phiaj lom zem tau tsim. Nyem lub pob hauv qab no los tsim ib qho!",
              relationshipGoals: "Tsis muaj Lub Hom Phiaj Kev Sib Raug Zoo tau raug tsim. Nyem lub pob hauv qab no los tsim ib qho!",
              travelGoals: "Tsis muaj lub hom phiaj mus ncig tau tsim. Nyem lub pob hauv qab no los tsim ib qho!",
            },
          },
          contributorGeneric: {
            entries: "Contributor Nkag",
            noAccess: "Koj tsis muaj kev nkag mus rau ntu no",
            includedByPlanOwner: 'Nrog tus tswv cuab',
            notIncluded: "Tsis suav los ntawm tus tswv txoj kev npaj",
            sectionNotComplete: "Tus Tswv Txoj Haujlwm tseem tsis tau ua tiav seem no",
            thankYou:
              "Ua tsaug rau koj cov nyiaj pab! Koj cov khoom ntxiv tau raug xa mus rau tus tswv txoj kev npaj kom pom zoo",
          },
          contributorComments: {
            addComment: "Add a comment",
            allComments: "Tag nrho cov lus",
            commentPrompt:
              "Koj puas muaj ib yam dab tsi uas koj xav tawm tswv yim raws li qhov koj pom? Cia lawv ib qho kev lom zem los yog ceeb toom!",
            yourComment: "Koj Cov Lus Qhia",
            approved: "Cov Lus Pom Zoo",
            pendingApproval:
              "Ua tsaug rau koj cov nyiaj pab! Koj cov lus tau raug xa mus rau tus tswv txoj kev npaj kom pom zoo",
            pendingNoThankYou: "Koj cov lus tau raug xa mus rau tus tswv txoj haujlwm kom pom zoo",
            comments: "Cov lus",
            toCheck: 'mus kuaj',
            deleteConfirm: 'Koj puas paub tseeb tias koj xav tshem tawm cov lus no?',
            deleteSuccess: "Ok, cov lus tau muab tshem tawm",
            notApprovedMessage: 'Koj cov lus pom tsis raug pom zoo los ntawm tus tswv phiaj xwm phiaj xwm. Koj tuaj yeem hloov kho koj cov lus thiab rov xa nws, lossis tshem koj cov lus pom.',
            notIncluded: 'Tsis suav nrog',
          },
          durationDropdown: {
            notSelected: "Tsis Xaiv",
            zeroMonths: "0-6 hli",
            sixMonths: "6-12 Lub Hlis",
            oneYear: "1-2 xyoos",
            twoYears: "2+ xyoo",
          },
          navigationButtons: {
            gtky: "Rov qab mus Pom Koj",
            thingsIDo: "Rov qab mus rau Tej Yam Kuv Ua",
            happening: "Rov qab mus rau qhov tshwm sim hauv Kuv Lub Neej",
            planningFun: "Rov qab mus rau Kev Npaj Kev Lom Zem",
            planningHealth: "Rov qab mus rau Kev Npaj Kho Mob",
            planningHome: "Rov qab mus rau Kev Npaj Tsev",
            planningRelationships: "Rov qab mus rau Kev Npaj Kev Sib Raug Zoo",
            planningTravel: "Rov qab mus rau Kev Npaj Mus Ncig",
            planningWork: "Rov qab mus rau Kev Npaj Ua Haujlwm",
          },
          generic: {
            add: "Ntxiv",
            addAnother: "Ntxiv Lwm",
            addComment: "Ntxiv lus",
            addNew: "Ntxiv Tshiab",
            addNewEntry: "Ntxiv Tshiab Nkag",
            addYourOwn: "Ntxiv Koj Tus Kheej",
            addedBy: 'Ntxiv los ntawm',
            addressPrimary: "Chaw nyob 1",
            addressSecondary: "Suite，chav tsev #",
            alone: "Ib leeg",
            approve: "Pom zoo",
            back: "Rov qab",
            backToDashboard: "Rov qab mus rau kev npaj dashboard",
            cancel: "Tso tseg",
            cancelled: "Tso tseg",
            city: "Lub nroog",
            clear: "Ntshiab",
            country: "Lub teb chaws",
            comments: "Cov lus qhia",
            createdBy: "Tsim los ntawm",
            createdOn: "Tsim rau",
            dailyWithCapital: "Txhua hnub",
            date: "Hnub tim",
            delete: "Rho tawm",
            deleteGoalConfirm: "Koj puas paub tseeb tias koj xav rho tawm lub hom phiaj no?",
            deleteGoalSuccess: "Koj lub hom phiaj tau ua tiav tiav lawm",
            details: "Cov ntsiab lus",
            disapprove: "Tsis pom zoo",
            dislike: "tsis nyiam",
            edit: "Kho kom raug",
            editGoal: "Kho kom raug lub hom phiaj",
            email: "Email",
            emailWithCapitial: "E-Mail",
            error: 'Yuam kev',
            faceTime: "FaceTime",
            firstName: "Lub Npe",
            addGeneralComment: "Nyem rau ntxiv cov lus qhia dav dav",
            generalComments: "cov lus hais dav dav",
            generalComment: "Nyem ntxiv cov lus qhia dav dav",
            goal: "Lub hom phiaj",
            goToItem: "Mus rau yam khoom",
            goToGoal: "Mus Rau Lub Hom Phiaj",
            goToSection: "Mus rau {{section}}",
            inPerson: "Nyob rau hauv tus neeg",
            instructionalVideo: "Qhia Video",
            language: "Xaiv hom lus",
            lastName: "Lub Npe",
            like: "zoo",
            loading: "thauj khoom...",
            middleName: "Lub npe nruab nrab",
            name: "Npe",
            newAdditions: "Cov neeg koom tes tau tsim cov tshiab ntxiv",
            no: "Tsis muaj",
            noNotifications: "Tsis muaj ntawv ceeb toom",
            noPeopleMessage: "Ntxiv cov phooj ywg thiab tsev neeg rau koj Cov Neeg Hauv Kuv Lub Neej los xaiv lawv ntawm no!",
            noThanks: "Tsis Ua Tsaug",
            okay: "OK",
            other: "Lwm yam",
            pleaseExplain: "Thov piav",
            phoneNumber: "Xov tooj",
            remove: "Tshem tawm",
            removeImage: "Tshem tawm Duab",
            review: "Saib xyuas",
            save: "Txuag",
            saveAndSendUpdates: "Txuag & Xa cov tshiab",
            saveAndSendInvite: "Txuag & Xa Caw",
            saveAnother: "Txuag & Ntxiv Lwm",
            saveNoUpdates: "Tsis txhob xa cov tshiab",
            search: "Nrhiav",
            selectAGoal: "Xaiv lub hom phiaj",
            state: "Xeev",
            submit: "Xa",
            success: "Kev vam meej",
            talking: "Hais lus",
            thankYou: 'Ua tsaug',
            title: "lub npe",
            uploadImage: "Upload ib daim duab",
            viewComment: "Saib Comment",
            viewComments: "Saib Cov Lus Qhia",
            weeklyWithCapitial: "Lub lis piam",
            with: 'nrog',
            withOthers: "Nrog lwm tus",
            yes: "Yog",
            zip: "Zip Code",
            include: "suav nrog",
            notInclude: "Tsis suav nrog",
            approveAll: "Pom zoo tag nrho",
            denyAll: "Txhua yam",
            removeFromPlan: "Tshem tawm ntawm Kev Npaj",
            said: "hais tias",
            errors: {
              errorPageTitle: 'Muaj ib qho yuam kev!,',
              errorPageSubTitle: 'Thov txim txog qhov ntawd. Thov rov qab los tom qab.,',
              error404: '404 - nplooj ntawv tsis pom,',
              saveError: 'Thov txim, muaj ib qho yuam kev txuag koj nkag. Thov sim dua tom qab.,',
              deleteError: 'Thov txim, muaj ib qho yuam kev tshem tawm koj nkag. Thov sim dua tom qab.,',
              createPlanError: 'Thov txim, muaj qhov yuam kev tsim koj txoj kev npaj. Thov sim dua tom qab.',              
            },
          },
        },
      },
      tagalog: {
        translation: {
          contact: {
            contactUs: 'Makipag-ugnayan sa amin',
            message: 'Mensahe',
            send: 'Ipadala',
            sayHello: 'Bumati ka',
            saveTitle: 'Matagumpay na naipadala ang iyong email!',
            saveMessage: 'Makikipag-ugnayan sa iyo ang isang kinatawan mula sa Center On Me.',
            emailError: 'Mangyaring magpasok ng wastong email address',
            phoneError: 'Mangyaring magpasok ng wastong numero ng telepono',
          },
          password: {
            lowercase: "Naglalaman ng maliit na titik",
            uppercase: "Naglalaman ng malaking titik",
            eightCharacters: "Hindi bababa sa 8 character ang haba",
            number: "Naglalaman ng numero",
            specialCharacter: "Naglalaman ng isang espesyal na karakter",
            passwordsMatch: "Tugma ang mga password",
            errorCannotMatch: "Hindi magkatugma ang Lumang Password at Bagong Password",
          },
          saveProgress: {
            uploadPhoto: 'Pag -upload ng imahe',
            updateProfile: 'Pag -update ng iyong profile',
          },
          agreements: {
            pleaseRead: "Ang pagprotekta sa iyong impormasyon ay aming priyoridad. Mangyaring basahin ang aming",
            privacyTitle: "Privacy ng Website",
            termsTitle: "Mga Tuntunin ng Paggamit",
            ifYouAgree: `Kung sumasang-ayon ka, ilagay ang iyong Pangalan at Apelyido at i-click ang "Sumasang-ayon ako"`,
          },
          settings: {
            pageTitle: "Mga setting",
            subTitle: "Iyong lugar para i-customize ang iyong plano sa paraang gusto mo!",
            saveSettings: "I-save ang Mga Setting",
            planVideo: "Ang Aking Pagpaplanong Video",
            contributorPage: "Pamahalaan ang mga Contributor",
            colorPalette: "Aking Color Palette",
            changePassword: {
              changePassword: "Palitan ANG password",
              subTitle: "Palitan natin yan password!",
              currentPassword: "Kasalukuyang Password",
              newPassword: "Bagong Password",
              confirmPassword: "Kumpirmahin ang Password",
              currentIncorrect: "Mali ang inilagay na password",
              samePasswordError: "Whoops, mangyaring gumamit ng bagong password",
              successMessage: "Napalitan ang Password!"
            },
            colorPalettePage: {
              pageTitle: "Palette ng Kulay",
              subTitle: "Piliin kung aling mga kulay ang gusto mong makita kapag gumagawa ng iyong plano!",
              myColorPalette: "Aking Color Palette",
              select: "Piliin ang color palette na gusto mo",
              brainstormBlue: "Brainstorming asul",
              planningPurple: "Pagpaplano sa Lila",
              organizingOrange: "Pag-aayos ng kulay kahel",
              gamePlanGreen: "Ang plano ng laro ay berde",
              primaryColorTooltip: "Teksto, mga pindutan, at mga icon",
              secondaryColorTooltip: "Mga header, napiling mga pindutan, mga talahanayan",
              contributorColorTooltip: "Mga entry ng contributor",
              textColorTooltip: "Teksto",
              saveMessage: "Ang Iyong Color Palette ay Na-update na!",
            },
            manageContributors: {
              sectionHeader: "Pamahalaan ang Aking Mga Contributor at Notification",
              subHeader: "Mag-click sa isang contributor card upang itakda o i-edit ang kanilang pahintulot",
              myContributors: "Aking mga Contributor",
              dialogConfirm: "Mukhang Tama ba ang Lahat?",
              dialogTitle: "Impormasyon ng Contributor",
              inviteSuccess: "Isang imbitasyon ay ipinadala sa iyong kontribyutor",
              updateSuccess:
                "Na-update ang Impormasyon ng Contributor at ipinadala ang isang email upang ipaalam sa contributor",
              updateSuccessNoEmail: "Na-update ang Impormasyon ng Contributor!",
              lastContribution: "Huling kontribusyon:",
              noContributions: "Wala pang kontribusyon",
              addContributor: "Magdagdag ng Contributor",
            },
            contributorNotifications: {
              myNotifications: "Aking Mga Notification",
              letsSetPreferece: "Itakda Natin ang Iyong Mga Kagustuhan",
              howToNotify: "Kapag nagdagdag ang isang contributor sa iyong plano, paano mo gustong maabisuhan?",
              howOften: "Gaano kadalas mo gustong maabisuhan?",
              saveAlert: "Na-update na ang iyong mga setting ng notification",
            },
            managementPage: {
              sectionHeader: "Aking mga Contributor",
              noContributorsContext:
                "Maaari mong bigyan ang mga contributor ng pahintulot sa iba't ibang seksyon ng iyong plano. Pagkatapos ay maaari silang gumawa ng mga komento at iba pang mga karagdagan sa mga seksyong iyon!",
            },
            profile: {
              pageTitle: "Pangalan at Mga Detalye",
              sectionHeader: "Maligayang pagdating sa Center on Me",
              subHeader: "Mangyaring maglaan ng ilang sandali upang sabihin sa amin ang kaunti tungkol sa iyong sarili",
              profilePicture: 'Larawan ng profile',
              name: "Pangalan",
              languageAndNumber: "Wika at Numero ng Telepono",
              address: "Address",
              firstNameError: "Kailangan ang Pangalan",
              lastNameError: "Kinakailangan ang Apelyido",
              phoneError: "Kailangan ang wastong numero ng telepono",
              saveConfirmationMsg: "Mukhang tama ba ito?",
              backToSettings: "Bumalik sa Mga Setting",
              backToPlans: "Bumalik sa Aking (mga) Plano",
              saveMessage: "Salamat sa pagdagdag ng iyong impormasyon!",
              firstTimeSaveMessage: 'Napakagandang makilala ka! Ngayon, simulan natin ang pagpaplano.',
              notQuite: "Hindi masyado",
              speechToText: 'Pagsasalita sa teksto',
              speechToTextEnabled: 'Oo, nais kong gumamit ng pagsasalita sa teksto!',
              speechToTextDisabled: 'Hindi, ayokong gumamit ng pagsasalita sa teksto',
              speechToTextWarning: 'Kasalukuyan lamang magagamit sa Ingles',              
              languages: {
                english: "Ingles",
                armenian: "Armenian",
                chineseSimple: "Intsik, Pinasimple",
                chineseTraditional: "Intsik, Tradisyonal",
                farsi: "Farsi",
                hmong: "Hmong",
                korean: "Koreano",
                spanish: "Espanyol",
                tagalog: "Tagalog",
                vietnamese: "Vietnamese",
              },
            },
            account: {
              pageTitle: 'Account',
              subTitle: 'Ang iyong lugar upang pamahalaan ang iyong subscription',
              benefitsTitle: 'Bayad na mga benepisyo sa subscription',
              manageTitle: 'Pamahalaan ang subscription',
              benefits: {
                multiplePlans: 'Lumikha ng maraming mga plano',
                fullAccess: 'Buong pag -access sa mga pahina ng plano',
                printablePDF: 'Mai -print na buod ng plano ng PDF',
                uploadPhotos: 'Mag -upload ng mga larawan',
                planVideo: 'Lumikha ng isang video video upang ibahagi sa iyong mga nag -aambag',
                backgroundImage: 'Pumili ng isang pasadyang imahe sa background',
                themeColor: 'Piliin ang iyong kulay ng tema ng profile',
              },
              currentPlan: 'Kasalukuyang plano',
              trial: '30-araw na pagsubok',
              subscription: '12-buwan na subscription',
              noSubscription: 'Walang kasalukuyang subscription',
              expirationDate: 'Petsa ng pagkawalang bisa',
              expiresOn: 'Mag -e -expire na ang iyong subscription',
              paymentMethod: 'Paraan ng Pagbayad',
              supportContact: 'Suportahan ang contact',
              supportContactIs: 'Ang iyong contact sa suporta ay {{contactName}}',
              editSupportContact: 'I -edit ang contact ng suporta',
              supportContactLabel: 'Suportahan ang contact',
              contactEmailLabel: 'Makipag -ugnay sa email address',
              contactPhoneLabel: 'Makipag -ugnay sa numero ng telepono',
              saveSupportMessage: 'Ang iyong contact sa suporta ay na -update.',
              supportContactError: 'Kinakailangan ang contact contact',
              contactEmailError: 'Kinakailangan ang suporta sa contact ng email',
              contactPhoneError: 'Kinakailangan ang numero ng contact contact',                
              subscribeButton: 'Mag -subscribe',
              renewButton: 'I -renew ang subscription',
              requestRenewal: 'Humiling na i -renew ang iyong subscription',
              renewDialogTitle: 'I -renew ang iyong subscription',
              selectedPlan: 'Napiling plano',
              billed: 'Sinisingil',
              payment: 'Pagbabayad',
              howItWorks: 'Paano gumagana ang iyong subscription',
              billingInformation: 'Impormasyon sa pagsingil',
              billingStartPlanning: 'Maaari mong simulan ang pagpaplano sa sandaling tanggapin ang iyong pagbabayad',
              expirationExplanation: 'Sa pag -expire ng termino ng subscription, ang subscription ay awtomatikong magtatapos nang walang karagdagang paunawa o pagkilos na hinihiling ng alinman sa partido. Hindi magkakaroon ng karagdagang mga singil, at hindi ka na magkakaroon ng access sa mga benepisyo na nauugnay sa subscription maliban kung bumili ka ng isang bagong subscription.',
              termsConditions: 'Mga Tuntunin at Kundisyon',
              trialToMemberMessage: 'Maligayang pagdating sa Center sa akin! Mayroon ka na ngayong pag -access sa lahat ng mga pakinabang ng isang plano sa akin ng Center On Me.',
              memberRenewalMessage: 'Ang iyong plano ay na -update para sa isa pang 12 buwan',
              contactEmail:  'Makipag -ugnay sa email address',
              contactPhone: 'Makipag -ugnay sa numero ng telepono',
              contactSuccessMessage: 'Ang iyong contact sa suporta ay na -update',
              subscribeDialogTitle: 'Mag -subscribe sa Center sa akin',
              trialExpireOn: 'Mag -e -expire na ang iyong pagsubok',
              expiredOn: 'Nag -expire ang iyong subscription',
              paymentSuccessMessage: 'Salamat! Inaasahan namin na nasiyahan ka sa akin sa akin',
              today: "Ngayon",
              todayDescription: "I -unlock ang buong pag -access sa sentro sa akin",
              tenMonths: "10 buwan",
              tenMonthDescription: "maalalahanan kapag malapit nang mag -expire ang iyong plano",
              twelveMonths: "12 buwan",
              twelveMonthDescription: "Nagtatapos ang iyong subscription",
              showPaymentOptions: 'Ipakita ang mga pagpipilian sa pagbabayad',
            },
            profilePictureDialog: {
              title: "Idagdag ang Iyong Larawan sa Profile",
              uploadPhoto: "Mag-upload ng Larawan",
            },
            backgroundImage: {
              pageTitle: 'Imahe ng background',
              subTitle: 'Pumili ng isang imahe sa background o mag -upload ng iyong sarili',
              hideGrid: 'Itago ang grid ng imahe',
              showGrid: 'Ipakita ang grid ng imahe',
              selectImage: "Pumili ng Larawan sa Background",
              noImage: "Walang Larawan",
              newImageWarning: "Aalisin ng pagpili ng bagong larawan ang iyong na-upload na larawan. Gusto mo bang magpatuloy?",
              warningConfirm: "Oo, gusto ko ng bagong imahe",
              warningCancel: "Hindi, panatilihin ang aking imahe",
              uploadError: "Paumanhin, nagkaroon ng error sa pag-update ng iyong larawan sa background",
            },
            planVideoPage: {
              sectionHeader: "Ang Aking Pagpaplanong Video",
              subHeader: "Gumawa ng video para ipakilala ang iyong plano sa mga nag-aambag",
              saveAlertText: "Salamat sa iyong pag-upload ng iyong planong video!",
              explainTextGreeting: "Kamusta {{firstName}},",
              explainTextP1: `Ang espasyong ito ay para sa iyo na mag-upload ng video na gagawin ng iyong mga contributor
                 tingnan sa sandaling mag-log in sila sa Center On Me para mag-ambag sa iyong plano.
                 Ang video na ito ay isang pagkakataon para ipaliwanag mo kung bakit mo ito ginagawa
                 magplano at magbigay sa mga kontribyutor ng isang halimbawa ng hinaharap na iyong naiisip
                para sa iyong sarili sa paggawa ng planong ito.`,
              explainTextP2: `Mag-record ng video sa iyong telepono, computer o tablet at i-upload ito
                 dito. Kailangang wala pang sampung (10) minuto ang iyong video. Para sa mga tip
                 sa kung paano gawin ang iyong video, mangyaring panoorin ang halimbawang video o piliin
                     "Mga tip para sa paggawa ng aking video"`,
              explainTextEnding: "Salamat,",
              explainTextSignature: "Center On Me",
              tipsButton: "Mga tip para sa paggawa ng aking video",
              exampleVideoTitle: "Halimbawang Video",
              alvaVideoTitle: "Halimbawa ng Alva",
              uploadButton: "I-upload ang iyong video",
              removeButton: "Alisin ang Aking Video",
              videoError: "Hindi sinusuportahan ng iyong browser ang video tag.",
              lengthError: "Error: Ang Pinakamataas na Haba ng Video ay 10 minuto",
              tips: {
                dialogTitle: "Mga tip sa paggawa ng iyong video",
                tipsTitle: "Mga tip para sa pag-record at pag-upload ng iyong video:",
                tipOne: "I-record ang iyong video sa liwanag kung saan makikita ang iyong buong mukha",
                tipTwo: "Pag-isipan kung ano ang gusto mong sabihin bago mag-record",
                tipThree: "Panatilihin ang video sa ilalim ng sampung (10) minuto",
                tipFour: `Kung pagkatapos i-upload ang iyong video ay makakaranas ka ng mga isyu sa panonood nito, mangyaring mag-email sa centeronme@mainsl.com
                at ibigay ang iyong username (email address) at pangalan ng video file na iyong na-upload (hal. “Myplanvideo.mov")`,
                examplesTitle: "Mga halimbawang tanong na sasagutin sa iyong video:",
                exampleOne: "Bakit mo iniimbitahan ang mga tao na mag-ambag sa iyong plano?",
                exampleTwo: "Ano ang inaasahan mong matupad sa iyong plano?",
                exampleThree: "Ano ang gusto mong plano?",
                exampleFour: "Paano ka masusuportahan ng iyong mga kontribyutor sa paggawa ng iyong plano?",
              },
              contributor: {
                sectionHeader: "Video ng pagpaplano nila",
                subHeader: "",
                noVideo: "Ang May-ari ng Plano ay hindi nagdagdag ng Plano na Video",
              },
            },
            knowledgeCenter: {
              pageTitle: 'Sentro ng Kaalaman',
              subTitle: 'Mag-click sa isang link upang matuto nang higit pa tungkol sa paggamit ng aming site',
              videoTab: 'Mga video',
              faqTab: 'FAQ',
              contactTab: 'Makipag-ugnayan sa amin',
              videos: {
                gettingStartedTitle: 'Nagsisimula',
                profileSettings: 'Mga Setting ng Profile',
                landingPageAndBadges: 'Landing Page at Mga Badge',
                contributorsTitle: 'Mga kontribyutor',
                addingContributors: 'Pagdaragdag ng mga Contributor',
                managingContributors: 'Pamamahala ng mga Contributor',
                howToContribute: 'Paano Mag-ambag',
                gettingToKnowYouTitle: 'Pagkilala sa Iyo',
                likeAndAdmire: 'Like & Admire',
                people: 'Mga Tao sa Buhay ko',
                favorites: 'Aking Mga Paboritong Bagay',
                thingsIDo: 'Mga bagay na ginagawa ko para sa Kasayahan',
                routines: 'Aking Mga Gawain',
                historyMap: 'Mapa ng Kasaysayan',
                travelPlan: 'Paglalakbay',
                happeningNowTitle: 'Ano ang nangyayari ngayon',
                healthAndSafety: 'Kalusugan at kaligtasan',
                livingInHome: 'Nakatira sa Aking Tahanan',
                work: 'Trabaho',
                school: 'Paaralan',
                startPlanningTitle: 'Simulan Natin ang Pagpaplano',
                workGoals: 'Mga Layunin sa Trabaho',
                healthGoals: 'Mga Layunin sa Kalusugan',
                livingGoals: 'Buhay na Layunin',
                travelGoals: 'Mga Layunin sa Paglalakbay',
                funGoals: 'Mga Masayang Layunin',
                relationshipGoals: 'Mga Layunin ng Relasyon',
                planSummaryTitle: 'Buod ng Plano',
                webVersion: 'Bersyon ng Web at Pag-export sa PDF',
                dialogTitle: 'Instructional Video',
              },
              faq: {
                pageTitle: 'Mga Madalas Itanong',
                usingTheKnowledgeCenter: 'Gamit ang Knowledge Center',
                passwordAndUsername: 'Password at Username',
                managingPlans: 'Pamamahala ng mga Plano',
                planOwnersAndContributors: 'Mga May-ari at Contributor ng Plano',
                usingTheSite: 'Gamit ang Site',
                accessingSupport: 'Pag-access sa Suporta',
                registration: 'Pagpaparehistro at Subscription',
                howToUse: 'Paano ko magagamit ang sentro ng kaalaman?',
                whoToContact: 'Sino ang maaari kong kontakin kung mayroon akong mga tanong na hindi naka-reference sa video?',
                videoLanguage: 'Maaari ko bang panoorin ang mga video na ito sa isang wika maliban sa English?',
                findVideo: 'Paano ko mahahanap ang video na hinahanap ko?',
                passwordRequirements: 'Ano ang mga kinakailangan sa password?',
                changePassword: 'Paano ko babaguhin ang aking password?',
                changeUsername: 'Paano kung gusto kong baguhin ang aking login / email address?',
                howManyPlans: 'Ilang plano ang magagawa ko kapag nakarehistro na ako?',
                sharePlan: 'Maaari ko bang ibahagi ang aking plano sa sinuman? Mayroon bang paraan upang i-email ang aking plano sa platform?',
                deletePlan: 'Ano ang gagawin ko kung hindi ko sinasadyang natanggal ang isang plano?',
                whatIsPlanOwner: 'Sino / ano ang isang "May-ari ng Plano"',
                whatIsContributor: 'Sino / ano ang isang "Contributor?"',
                contributorLimit: 'Mayroon bang limitasyon sa mga kontribyutor na maaari kong idagdag sa aking plano?',
                approveComment: 'Kung aaprubahan ko ang komento ng contributor, aabisuhan ba sila?',
                denyComment: 'Kung tatanggihan ko ang komento ng nag-ambag, aabisuhan ba sila?',
                updateProfile: 'Maaari ko bang i-update ang aking Mga Detalye ng Profile anumang oras?',
                howManyActivities: 'Ilang aktibidad ang maaari kong idagdag sa ilalim ng bawat seksyon?',
                listSomeoneAsResponsible: 'Kung ililista ko ang isang tao bilang responsable para sa pagkamit ng isang layunin, aabisuhan ba sila?',
                editActivity: 'Paano ako mag-e-edit ng isang aktibidad?',
                deleteActivity: 'Paano ko tatanggalin ang isang aktibidad?',
                accessibilityFeatures: 'Anong mga feature ng accessibility ang inaalok ng Platform?',
                forgetToSave: 'Ano ang mangyayari kung makalimutan kong i-save ang ginagawa ko?',
                useOnTablet: 'Maaari ba akong magtrabaho sa aking plano gamit ang aking tablet?',
                useOnPhone: 'Maaari ko bang gawin ang aking plano gamit ang aking telepono?',
                phoneApp: 'Mayroon bang phone app na kailangan kong i-download?',
                needInternet: 'Kailangan ko bang magkaroon ng internet access para magawa ang aking plano? Mayroon bang offline mode?',
                whatAreBadges: 'Ano ang mga badge?',
                helpWithPlan: 'Sino ang kokontakin kung kailangan ko ng tulong sa aking plano?',
                issuesAccessingPlan: 'Sino ang kokontakin kung mayroon akong mga isyu sa pag-access sa aking plano?',
                issueWithPlanVideo: 'Sino ang kokontakin kung hindi ko mai-upload ang aking Plano na Video?',
                forgotUsername: 'Paano kung makalimutan ko ang aking login username? Sino ang kokontakin?',
                wasRegistrationSuccessful: 'Paano ko malalaman na matagumpay ang aking pagpaparehistro?',
                renewPlan: 'Paano ko malalaman na kailangan kong i-renew ang aking plano?',
                howToCancel: 'Paano ko kakanselahin ang aking subscription?',
              },
              faqAnswers: {
                howToUse: `Mag-click sa seksyon o link na gusto mong malaman pa. Dadalhin ka sa isang hiwalay na pahina at mapapanood mo ang isang video na magtuturo sa iyo sa bahaging iyon ng site.`,
                whoToContact: `Mangyaring makipag-ugnayan sa amin anumang oras gamit ang tab na "Makipag-ugnayan sa Amin"! Sa tab na iyon ay makikita mo ang isang form na kukumpletuhin na magpapadala sa amin ng isang email. Sa iyong email mangyaring isama ang pahina at video na mayroon kang mga katanungan tungkol sa. Sasagutin ka namin sa lalong madaling panahon.`,
                videoLanguage: `Sa ngayon, available lang ang mga video na ito sa English. Ang closed captioning ay ibinigay para sa lahat ng mga video.`,
                findVideo: `Paki-type ang pahinang iyong hinahanap sa search bar at isang link sa video ay lalabas. Ang mga video ay nakaayos ayon sa pangalan ng pahina. Kung hindi mo mahanap ang isang video na iyong hinahanap, mangyaring makipag-ugnayan sa amin gamit ang tab na "Makipag-ugnayan sa Amin".`,
                passwordRequirements: `Dapat kasama sa iyong password ang: Isang maliit na titik, isang malaking titik, isang numero, isang espesyal na character, at hindi bababa sa 8 mga character.`,
                changePassword: `Maaari mong baguhin ang iyong password anumang oras sa pamamagitan ng pagpunta sa setting ng iyong profile at pagpili sa, "Baguhin ang Password" na buton.`,
                changeUsername: `Wala kang kakayahang baguhin ang iyong email address.`,
                howManyPlans: `Walang limitasyon sa kung gaano karaming mga plano ang maaari mong gawin. Gayunpaman, ang impormasyong ipinasok mo sa mga plano kasama ang mga tagapag-ambag ng plano ay hindi madadala mula sa isang plano patungo sa isa pa.`,
                sharePlan: `Maaaring ibahagi ng may-ari ng plan ang kanilang plano sa sinuman anumang oras. Sa kanang sulok sa itaas ng pahina ng buod ng plano, mayroong isang button na nagsasabing, "I-export sa PDF." Kapag na-click ang button na iyon, mada-download ang iyong plano at maaaring i-print o i-email sa sinumang tao na iyong pipiliin.`,
                deletePlan: `Kapag sinubukan mong tanggalin ang isang plano, ang Center On Me ay magbibigay ng prompt upang matiyak na gusto mo talagang tanggalin ang iyong plano. Kung pipiliin mo pa ring tanggalin ang iyong plano, walang magagawa at kailangan mong muling likhain ang iyong plano mula sa simula.`,
                whatIsPlanOwner: `Ang "May-ari ng Plano" ay ang nakatutok na tao ng plano, ang taong gumagawa ng plano. Ang May-ari ng Plano ay dapat na ang focus ng planong ito sa lahat ng oras!`,
                whatIsContributor: `Ang contributor ay isang tao na inimbitahan ng isang “May-ari ng Plano” na mag-ambag sa kanilang plano. Ang May-ari ng Plano ay may kumpletong kontrol sa kung aling bahagi ng kanilang plano ang maaaring tingnan at/o pagkomentohan ng isang kontribyutor. Dapat suriin at aprubahan ang lahat ng komento ng nag-ambag bago isama sa isang plano.`,
                contributorLimit: `Walang limitasyon sa kung gaano karaming mga kontribyutor ang maaari mong makuha. Para sa bawat planong gagawin mo, kakailanganin mong idagdag nang hiwalay ang bawat kontribyutor.`,
                approveComment: `Hindi, hindi aabisuhan ang mga Contributor kapag naaprubahan ang isang komento.`,
                denyComment: `Hindi, ang mga Contributor ay hindi aabisuhan kapag ang isang komento ay tinanggihan.`,
                updateProfile: `Maaari mong i-update ang mga detalye ng iyong profile anumang oras sa pamamagitan ng pagpunta sa mga setting ng profile. Sa loob ng mga setting ng profile magkakaroon ka ng kakayahang baguhin ang iyong Pangalan at Mga Detalye, ang iyong Larawan sa Profile, ang iyong background at ang iyong paleta ng kulay.`,
                howManyActivities: `Walang limitasyon sa bilang ng mga aktibidad o layunin na maaari mong idagdag sa ilalim ng bawat seksyon.`,
                listSomeoneAsResponsible: `Hindi sila aabisuhan kung ililista mo ang isang tao bilang responsable para sa pagkamit ng isang layunin. Kung gusto ng may-ari ng plano ng suporta na may layunin, kakailanganin nilang makipag-usap sa taong iyon at ibahagi ang kanilang plano kung kumportable silang gawin iyon.`,
                editActivity: `Maaari kang mag-edit ng aktibidad o layunin anumang oras sa pamamagitan ng pag-click sa card. Magbubukas ang form at maaaring baguhin o i-update ang anumang impormasyon.`,
                deleteActivity: `Maaari kang magtanggal ng aktibidad o layunin anumang oras sa pamamagitan ng pag-click sa card. Magbubukas ang form at maaaring tanggalin ang layunin sa pamamagitan ng pag-scroll hanggang sa ibaba at pagpili sa pulang button na "Tanggalin".`,
                accessibilityFeatures: `Gumagamit ang Center On Me ng Userway para mag-alok ng mga feature ng accessibility sa loob ng site. Binabago ng ilan sa mga feature ng accessibility na iyon ang contrast, laki ng font, gabay ng cursor, at text o space alignment. Maaari mong piliin ang iyong mga tampok sa pamamagitan ng pagpili sa bilog na icon na "Tao" na umiiral sa bawat pahina sa kaliwang bahagi.`,
                forgetToSave: `Napakahalagang pindutin ang save habang gumagawa ka ng iba't ibang mga pahina sa loob ng site. Ang impormasyon ay hindi awtomatikong ise-save.`,
                useOnTablet: `Oo! Maaaring ma-access ang Center On Me mula sa isang Tablet o mula sa isang Smart Phone.`,
                useOnPhone: `Oo! Maaaring ma-access ang Center On Me mula sa isang Smart Phone. Ang site ay ginawa upang maging mobile na tumutugon, kaya ang ilang data ay maaaring hindi madaling mabasa gaya ng sa isang tablet o gamit ang iyong computer.`,
                phoneApp: `Hindi, walang App ang Center On Me. Maa-access mo ang Center On Me mula sa iyong smart phone sa pamamagitan ng pagbubukas ng iyong web browser app at pagpunta sa www.centeronme.com.`,
                needInternet: `Oo, ang Center On Me ay nangangailangan ng internet upang magawa ang isang plano. Walang offline mode ng Center On Me.`,
                whatAreBadges: `Ang mga badge ay isang paraan para masubaybayan ng user at Center On Me ang iyong pag-unlad sa loob ng site. Makakakuha ka ng badge sa bawat oras na makumpleto mo ang isang page, at ang mga badge ay maaaring tingnan sa mga landing page. Mag-click sa mga badge para sa isang masayang sorpresa!`,
                helpWithPlan: `Kung kailangan mo ng suporta, pakibisita ang tab na "Makipag-ugnayan sa Amin" sa loob ng sentro ng kaalaman. Magpapadala ng email sa Center On Me team at tutugon kami kaagad.`,
                issuesAccessingPlan: `Kung kailangan mo ng suporta, pakibisita ang tab na "Makipag-ugnayan sa Amin" sa loob ng sentro ng kaalaman. Magpapadala ng email sa Center On Me team at tutugon kami kaagad.`,
                issueWithPlanVideo: `Una, tingnan ang mga tip sa pag-upload ng video upang matiyak na natutugunan mo ang mga kinakailangan sa video. Kung kailangan mo pa rin ng suporta, pakibisita ang tab na "Makipag-ugnayan sa Amin" sa loob ng sentro ng kaalaman. Magpapadala ng email sa Center On Me team at tutugon kami kaagad.`,
                forgotUsername: `Ang iyong username ay ang iyong email address. Kung kailangan mo ng suporta, pakibisita ang tab na "Makipag-ugnayan sa Amin" sa loob ng sentro ng kaalaman. Magpapadala ng email sa Center On Me team at tutugon kami kaagad.`,
                wasRegistrationSuccessful: `Kung matagumpay ang iyong pagbabayad, makakatanggap ka ng email na resibo sa iyong naka-link na PayPal account. Makakakita ka rin ng resibo sa iyong PayPal app. Tingnan sa ibaba ang halimbawa ng isang email na resibo para sa pagbabayad.`,
                renewPlan: `Makakatanggap ang mga user ng mga paalala sa email sa 3 buwan, 6 na buwan at 10 buwan upang paalalahanan ang isang user kapag tapos na ang iyong subscription at ibibigay ang mga tagubilin kung paano i-renew ang iyong plano.`,
                howToCancel: `Ang isang subscription ay binili para sa isang taon. Hindi mo maaaring kanselahin ang isang taong subscription. Isang email ang ipapadala sa iyo kapag oras na para i-renew ang iyong plano at maaari mong piliin na huwag i-renew ang iyong plano sa oras na iyon.`,
              }
            },
          },
          lockedFeatures: {
            photoLock: 'Kinakailangan ang subscription upang mag -upload ng mga larawan',
            videoLock: 'Kinakailangan ang subscription upang mag -upload ng mga video',
            pdfLock: 'Kinakailangan ang subscription upang i -download ang PDF',
            sectionLock: 'Pumili ng isang pagpipilian sa subscription upang ma -access ang tampok na ito.',
            sectionLockSaveMessage: 'Ang seksyong ito ay hindi mababago ng mga gumagamit ng pagsubok',
            createPlanLock: 'Pumili ng isang pagpipilian sa subscription upang lumikha ng maraming mga plano',
            expiredLock: 'Pumili ng isang pagpipilian sa subscription upang ma -access ang tampok na ito',
            expiredAlert:  'Nag -expire na ang iyong subscription. Mangyaring i -renew upang tingnan ang seksyong ito.',
          },
          permissions: {
            theContributor: "Ang kontribyutor",
            cannotView: "hindi matingnan",
            canView: "pwedeng makita",
            theSection: "ang seksyon",
            toTheSection: "sa seksyon",
            and: "at",
            but: "ngunit",
            cannotComment: "hindi maaaring magdagdag ng mga komento",
            canComment: "maaaring magdagdag ng mga komento",
            gtkyTitle: "Pagkilala sa Iyo",
            happeningTitle: "Ano ang Nangyayari sa Iyong Buhay",
            planningTitle: "Pagpaplano",
          },
          breadcrumbs: {
            planning: "Simulan Natin ang Pagpaplano",
            happening: "Nangyayari",
            gettingToKnowYou: "Pagkilala sa Iyo",
            myRoutines: "Aking Mga Gawain",
            myPeople: "Mga Tao sa Aking Buhay",
            likeAndAdmire: "I-like at humanga",
            work: "Trabaho",
            school: "Paaralan",
            home: "Bahay",
            fun: "Masaya",
            travel: "Paglalakbay",
            health: "Kalusugan",
            relationships: "Mga relasyon",
            goal: "Aking Layunin",
            historyMap: "Mapa ng Kasaysayan",
            favoriteThings: "Aking Mga Paboritong Bagay",
            add: "Magdagdag ng bago",
            profile: "Profile",
            settings: "Mga setting",
            contributors: "Mga Contributor at Notification",
            thingsIDo: "Mga Bagay na Ginagawa Ko Para Masaya",
            planningVideo: "Ang Aking Pagpaplanong Video",
            planSummary: "Buod ng Plano",
            changePassword: "Palitan ANG password",
            knowledgeBase: "Sentro ng Kaalaman",
            colorPalette: "Palette ng Kulay",
            myActivity: "Aking Aktibidad",
          },
          userMenu: {
            allPlans: "Lahat ng Plano",
            planDashboard: "Dashboard ng Plano",
            planSummary: "Buod ng Plano",
            settings: "Mga setting",
            subscribe: "Mag-subscribe",
            logout: "Mag-logout"
          },
          timeoutAlert: {
            attention: "Pansin",
            description: "Malapit ka nang mag-log out",
            stillPlanning: "Nagpaplano Pa Ako!",
            logMeOut: "Log Out Ako",
            warningOnLogout: "Mawawala ang hindi na-save na data",
          },
          allPlans: {
            pageTitle: "Isang Mabilis na Sulyap sa Iyong Mga Plano",
            subTitle: "Pumili ng isa para makapagsimula",
            iContributeTo: "Mga Plano na Ibinibigay Ko",
            myPlans: "Ang aking mga plano",
            newContributeTo: "Mag-ambag sa isang bagong plano",
            createNewPlan: "Gumawa ng bagong plano",
            planOwner: "May-ari ng Plano",
            planCreated: "Ginawa ang Plano",
            lastViewed: "Huling Tiningnan",
            planVideo: "Plano na Video",
            manageContributors: "Pamahalaan ang Mga Contributor at Notification",
            planSummary: "Buod ng Plano",
            deletePlan: "Tanggalin ang Plano",
            createPlanDialog: {
              dialogTitle: 'Gumawa ng Bagong Plano',
              explainerText: 'Pumili ng pangalan para sa iyong bagong plano upang makapagsimula',
              inputLabel: 'Pangalan ng Plano',
              createMessage: 'Ang pagsisimula ng bagong plano ay hindi magdadala sa alinman sa iyong trabaho o mga kontribyutor mula sa mga nakaraang plano',
            },
            contributeToPlanDialog: {
              dialogTitle: 'Mag-ambag sa isang Plano',
              explainerText: 'Ilagay ang code na natanggap mo mula sa May-ari ng Plano',
              inputLabel: 'Access Code',
              errorMessage: 'Ang access code ay hindi tumugma sa anumang mga plano. Pakisubukang muli',
            }
          },
          deletePlan: {
            dialogTitle: "Tanggalin ang Aking Plano",
            explanation: "Sinusubukan mong tanggalin ang iyong plano.",
            confirmMessage: "Sigurado ka bang gusto mong tanggalin ang planong ito?",
            noteToDownload: "Tandaan: Maaaring gusto mong i-download at i-save ang iyong plano bago ito tanggalin dahil hindi na ito mababawi.",
            downloadButton: "I-download ang Aking Plano",
            keepPlanButton: "Panatilihin ang Aking Plano",
            deleteButton: "Tanggalin ang Aking Plano",
            deleteSuccessMessage: "Tinanggal!",
          },
          planLandingPage: {
            videoTitle: "Maligayang pagdating sa iyong Center on Me plano",
            gtky: "Pagkilala sa Iyo",
            whatIsHappening: "Ano ang Nangyayari sa Iyong Buhay",
            startPlanning: "Simulan ang Pagpaplano",
            newBadge: "Bagong Badge",
            getStarted: "Magsimula",
            leftOff: "Kung saan ka tumigil",
            happeningButton: "Nangyayari",
            planningButton: "Pagpaplano ng Layunin",
            recentBadges: "Kamakailang Badge na Nakuha",
            gtkyNoCaps: "Pagkilala sayo",
            happeningShort: "Anong nangyayari",
            planning: "Pagpaplano",
            noBadgeMessage: "Wala pang Badge! Simulan ang pagkumpleto ng mga seksyon upang makakuha ng mga badge",
            contributorGreeting: "Maligayang pagdating",
            contributorMessage: `Nakikita namin na sumasali ka bilang isang kontribyutor, na nangangahulugang may nag-iisip na mahalaga ka! Gumaganap ka ng mahalagang papel habang nag-aambag sa dulang ito, 
              hinihiling namin na itaguyod mo ang layunin ng site na ito at panatilihin ang may-ari ng plano sa gitna ng kanilang plano habang ginagamit ang iyong oras upang magdagdag ng mayaman at 
              makabuluhang impormasyon. Kapag nag-aambag sa isang plano, hinihiling din namin na maglaan ka ng oras upang pag-isipan at makuha ang partikular na impormasyon kung sino ang tao, kung ano ang 
              mahalaga sa kanila, at kung paano mo sila matutulungang maabot ang kanilang mga pag-asa at pangarap!
            `,
            contributorQuote: `"Ang bawat solong tao ay may mga kapasidad, kakayahan, at mga regalo. Ang pamumuhay ng isang magandang buhay ay nakasalalay sa kung ang mga kapasidad na iyon ay magagamit, mga 
              kakayahan na ipinahayag at mga regalo na ibinibigay. Kung sila, ang tao ay pahalagahan, pakiramdam na makapangyarihan at mahusay na konektado sa mga tao sa paligid sila, at ang komunidad sa 
              paligid ng tao ay magiging mas malakas dahil sa mga kontribusyon na ginagawa ng tao."
            `,
            quote: `"Ang iyong mga layunin ay ang mga mapa ng daan na gumagabay sa iyo at nagpapakita sa iyo kung ano ang posible para sa iyong buhay." - Les Brown`,
            profileBadges: {
              addContributor: 'Badge: Magdagdag ng Contributor sa iyong plano',
              colorPalette: 'Badge: Pumili ng Color Palette',
              approveContributor: 'Badge: Aprubahan ang isang Kontribusyon',
              planningVideo: 'Badge: Gumawa ng Planong Video',
              profilePicture: 'Badge: Mag-upload ng Larawan sa Profile',
              backgroundImage: 'Badge: Mag-upload ng bagong Wallpaper',
              profileComplete: 'Badge: kumpleto ang profile!',
            }
          },
          badges: {
            badgeSectionTitle: "Mga Badge na Nakuha Ko",
            badgesPlanningExplanation: `Magsimulang kumita ng mga badge sa pamamagitan ng pagkumpleto sa bawat seksyon! Kapag tapos ka na sa isang seksyon, lalabas dito ang iyong badge. Magsaya tayo habang nagpaplano para sa iyong kinabukasan!`,
            badgesHappeningExplanation: `Magsimulang kumita ng mga badge sa pamamagitan ng pagkumpleto sa bawat seksyon! Kapag tapos ka na sa isang seksyon, lalabas dito ang iyong badge. Magsaya tayo habang pinag-aaralan ang mga nangyayari sa iyong buhay!`,
            badgesGTKYExplanation: `Simulan ang pagkamit ng mga badge sa pamamagitan ng pagkumpleto sa bawat seksyon! Kapag tapos ka na sa isang seksyon, lalabas dito ang iyong badge.
                Magsaya tayo habang nakikilala ka!`,
          },
          sections: {
            likeAndAdmire: "I-like at Humanga",
            people: "Mga Tao sa Aking Buhay",
            historyMap: "Mga Milestone",
            favorites: "Mga Paboritong Bagay",
            travelPlan: "Paglalakbay",
            thingsIDo: "Mga Bagay na Ginagawa Ko Para Masaya",
            dailyRoutines: "Pang araw-araw na gawain",
            weeklyRoutines: "Lingguhang Routine",
            routines: "Mga gawain",
            happeningHome: "Nangyayari sa bahay",
            happeningWork: "Nagaganap na Trabaho",
            happeningSchool: "Nangyayaring paaralan",
            happeningHealth: "Nangyayari na Kalusugan",
            planningHome: "Pagpaplano ng Tahanan",
            planningWork: "Pagpaplano ng Trabaho",
            planningTravel: "Pagpaplano ng Paglalakbay",
            planningFun: "Masaya sa Pagpaplano",
            planningHealth: "Kalusugan sa Pagpaplano",
            planningRelationships: "Mga Relasyon sa Pagpaplano",
          },
          planningPage: {
            pageTitle: "Simulan Natin Magplano",
            planningVideo: "Pagpaplanong Video",
            housing: "Pabahay",
            work: "Trabaho",
            health: "Kalusugan",
            fun: "Masaya",
            relationships: "Relasyon",
            travel: "Paglalakbay",
            addAnotherGoal: "Magdagdag ng Isa pang Layunin"
          },
          planningHealth: {
            sectionName: "Kalusugan sa Pagpaplano",
            sectionHeader: "Mga Layunin sa Pangkalusugan",
            subHeader: "Gumawa tayo ng plano para sa isang malusog na kinabukasan",
            videoTitle: "Mga Layunin sa Kalusugan - Nakatutulong na Video",
            goalTitle: "Ano ang gusto mong pamagat ng layuning ito?",
            goalAroundHealth: "Ano ang iyong layunin sa paligid ng kalusugan?",
            workedOnThisGoal: "Nagawa mo na ba ang layuning ito dati?",
            whatWorked: "Ano ang nagtrabaho noong ginawa mo ang layuning ito?",
            didntWork: "Ano ang hindi gumana noong ginawa mo ang layuning ito?",
            healthTableHeader: "Habang iniisip mo ang layuning ito, ano sa palagay mo ang dapat mong...",
            doYouHaveSupport: "Mayroon bang sinuman sa iyong buhay na maaari mong suportahan ka sa layuning ito?",
            attachedPeopleMessage: "Pakipili kung sino ang makakasuporta sa iyo",
            anyOneElseWhoCanSupport:
              "Mayroon pa bang ibang tao na makakatulong sa iyo na makamit ang iyong layunin at bakit?",
            goalCommit: "Ano ang maaari mong ipangako na gawin upang makatulong sa paglikha ng hinaharap na ito?",
            increase: "Pagtaas",
            decrease: "Bumaba",
            doMore: "Gawin ang Higit Pa",
            doLess: "Gawin ang Mas Kaunti",
            increaseEx: "Ex. Ang tagal kong nakatutok sa sarili ko",
            decreaseEx: "Hal. Ilang oras ang ginugugol ko sa Facebook",
            doMoreEx: "Hal. Oras na ginugugol ko sa aking paghinga",
            doLessEx: "Hal. Pagkain ng matamis na pagkain",
            saveMessage: "Galing, good luck sa layuning ito! Gusto mo bang magdagdag ng isa pang layunin sa kalusugan?",
            deleteMessage: "Sigurado ka bang gusto mong tanggalin itong table row?",
            deleteConfirm: "Ok, naalis na ang row",
            summary: {
              goalTitle: "Ang aking layunin sa kalusugan",
              whatWorked: "Noong nagtrabaho ako sa layuning ito bago ito ay kung ano ang nagtrabaho",
              didntWork: "Noong nagtrabaho ako sa layuning ito bago, narito ang hindi gumana",
              healthTableHeader:
                "Kapag iniisip ko ang aking layunin, sa palagay ko magagawa ko ang mga sumusunod upang matulungan ako",
              doYouHaveSupport: "Ang mga tao sa aking circle of support na makakatulong sa akin sa layuning ito ay",
              anyOneElseWhoCanSupport: "Ang iba pang mga tao na sa tingin ko ay makakatulong",
              goalCommit: "Kaya kong mag-commit",
            },
          },
          planningRelationships: {
            sectionName: "Mga Relasyon sa Pagpaplano",
            sectionHeader: "Mga Layunin ng Relasyon",
            subHeader: "Magsimula tayong lumikha ng mga koneksyon sa mga tao!",
            newOrStrengthen:
              "Gusto mo bang magplano para sa isang bagong relasyon o gusto mong palakasin ang isang kasalukuyang relasyon?",
            saveMessage: "Mukhang magandang layunin ng relasyon! Gusto mo bang magdagdag ng isa pang layunin?",
            newText: "Bago",
            strengthenText: "Palakas",
            relationship: 'Relasyon',
            strengthen: {
              videoTitle: "Palakasin ang Relasyon - Nakatutulong na Video",
              goal: "Sino ang gusto mong palakasin ang isang relasyon?",
              currentStatus: "Ano ang kasalukuyang katayuan ng relasyong ito?",
              romantic: "Romantikong",
              friend: "Kaibigan",
              professional: "Propesyonal",
              family: "Pamilya",
              whatToStrengthen: "Ano naman ang relasyong ito na gusto mong patibayin?",
              frequency: "Dalas",
              communication: "Komunikasyon",
              desiredStatus: "Nais na Katayuan",
              howOften: "Gaano ka kadalas nakikipag-ugnayan sa taong ito sa kasalukuyan?",
              howToStrengthen: "Paano mo gustong palakasin ang dalas",
              strengthenCommunication: "Paano ang komunikasyon na gusto mong palakasin?",
              commExample1: "Hal: Sinubukan kong tawagan sila ng mas madalas",
              commExample2: "Ex: Nakausap ko sila kapag gusto ko",
              commExample3: "Hal: Naramdaman kong narinig at konektado",
              commExample4: "Hal: Hindi sila sasagot sa lahat ng oras",
              commTableHeader1: "Ano ang sinubukan mo kapag pinalakas ang iyong komunikasyon?",
              statusTableHeader1: "Ano ang sinubukan mong gawin upang baguhin ang katayuan ng relasyon na ito?",
              tableHeader2: "Ano ang natutunan mo noong sinubukan mo iyon?",
              tableHeader3: "Ano ang ikinatuwa mo noong sinubukan mo iyon?",
              tableHeader4: "Ano bang pinagkakaabalahan mo?",
              commDoNext: "Tingnan mo ang kakalista mo, ano ang susunod mong gagawin?",
              preferredStatus: "Ano ang gusto mong maging status ng taong ito?",
              theyAreCurrently: "Sila ay kasalukuyang:",
              statusExample1: "Hal: Sinubukan kong tanungin sila sa isang date",
              statusExample2: "Ex: Na napakabait nila at natutuwa akong kausap sila",
              statusExample3: "Ex: Sabi nila oo!",
              statusExample4: "Ex: Hindi ko na sila tinatawag simula noon",
              finalDoNext: "Pagtingin sa inilista mo, ano ang susunod mong magagawa?",
              deleteRowConfirm: "Sigurado ka bang gusto mong tanggalin itong table row?",
              deleteRowSuccess: "Ok, naalis na ang row",
            },
            new: {
              videoTitle: "Pagpaplano ng Bagong Relasyon - Nakatutulong na Video",
              relationshipType: "Anong klaseng relasyon ang hinahanap mo?",
              characteristics: "Anong mga katangian ang hinahanap mo sa isang taong gusto mong makasama?",
              where: "Saan mo maaaring makilala ang taong ito?",
              placeOfWorship: "Lugar ng Pagsamba",
              family: "Pamilya",
              friend: "Kaibigan",
              professional: "Propesyonal",
              romantic: "Romantikong",
              sharedInterests: "Sa pamamagitan ng Nakabahaging Interes",
              online: "Online",
              school: "Paaralan",
              shops: "Mga tindahan",
              work: "Trabaho",
              whenYouThinkAbout: "Kapag iniisip mong lumikha ng bagong koneksyon",
              atChurch: "sa isang lugar ng pagsamba",
              throughFriend: "sa pamamagitan ng isang kaibigan",
              throughInterests: "sa pamamagitan ng ibinahaging interes",
              atOnline: "online",
              atSchool: "sa paaralan",
              atLocalShop: "sa isang lokal na tindahan",
              atWork: "nasa trabaho",
              likeToStart: "saan mo gustong magsimula?",
              churchStart: "Kapag naisipan mong lumikha ng bagong koneksyon sa isang lugar ng pagsamba, saan mo gustong magsimula?",
              friendStart: "Kapag iniisip mong lumikha ng bagong koneksyon sa pamamagitan ng isang kaibigan, saan mo gustong magsimula?",
              interestsStart: "Kapag naisipan mong gumawa ng bagong koneksyon sa pamamagitan ng mga nakabahaging interes, saan mo gustong magsimula?",
              onlineStart: "Kapag naisipan mong gumawa ng bagong koneksyon online, saan mo gustong magsimula?",
              schoolStart: "Kapag iniisip mong lumikha ng bagong koneksyon sa paaralan, saan mo gustong magsimula?",
              shopsStart: "Kapag naisipan mong gumawa ng bagong koneksyon sa isang lokal na tindahan, saan mo gustong magsimula?",
              workStart: "Kapag iniisip mong lumikha ng bagong koneksyon sa trabaho, saan mo gustong magsimula?",
              howOften: "Gaano kadalas mo gustong kumonekta sa ganitong paraan?",
            },
            characteristics: {
              organized: "Organisado",
              strong: "Malakas",
              dreamer: "Ang nangangarap",
              determined: "Determinado",
              smart: "Matalino",
              artist: "Isang artista",
              considerate: "Maalalahanin",
              helpful: "Matulungin",
              creative: "Malikhain",
              thoughtful: "Maalalahanin",
              talented: "may talento",
              outgoing: "Papalabas",
              leader: "Ang pinuno",
              intelligent: "Matalino",
              loving: "Nagmamahal",
              understanding: "Pag-unawa",
              spiritual: "Espirituwal",
              joyful: "Masaya",
              fixer: "Isang Fixer",
              liberal: "Liberal",
              patient: "Pasyente",
              respectful: "Magalang",
              willing: "Payag",
              intuitive: "Intuitive",
              gentle: "Malumanay",
              generous: "Mapagbigay",
              spontaneous: "Kusang-loob",
              independent: "Malaya",
              supportive: "Sumusuporta",
              resourceful: "Mapamaraan",
              experiential: "Karanasan",
              fun: "Masaya",
              funny: "Nakakatawa",
              excited: "Nasasabik",
              accepting: "Pagtanggap",
              busy: "Abala",
              pretty: "Maganda",
              openMinded: "Bukas ang isipan",
              hardWorking: "masipag",
              doer: "Isang Gawa",
              involved: "Kasangkot",
              flexible: "Nababaluktot",
              happy: "Masaya",
              careful: "Mag-ingat",
              hopeful: "Umaasa",
              tinkerer: "Isang Tinkerer",
              riskTaker: "Isang Risk-Taker",
              lucky: "Maswerte",
              active: "Aktibo",
              efficient: "Mahusay",
              visionary: "Isang Visionary",
              explorer: "Ang manunuklas",
              responsible: "Responsable",
              driven: "Itinulak",
              confident: "Tiwala",
              connector: "Isang Konektor",
              wise: "Matalino",
              expressive: "Nagpapahayag",
              sensitive: "Sensitibo",
              talkative: "Madaldal",
              energized: "Pinasigla",
              giving: "Pagbibigay",
              observant: "Mapagmasid",
              caring: "nagmamalasakit",
              peaceful: "Mapayapa",
              cool: "Malamig",
              fashionable: "Naka-istilong",
              actor: "Isang Artista",
              writer: "Isang manunulat",
              vibrant: "Masigla",
              goodCook: "Isang Magaling Magluto",
              goodWithAnimals: "Magaling Sa Mga Hayop",
            },
            summary: {
              newGoalPdfTitle: "Bagong Relasyon",
              strengthenGoalPdfTitle: "Palakasin ang Relasyon",
              relationshipType: "Ang uri ng relasyon na gusto kong likhain ay",
              characteristics: "Gusto kong magkaroon ang taong ito ng mga sumusunod na katangian",
              where: "Makikilala ko ang taong ito",
              whereToStart: "Kung saan ko gustong magsimula",
              howOften: "Gaano kadalas",
              beingInPerson: 'Ang pagiging personal',
              talkingMore: 'Nag-uusap pa',
              facetiming: 'Facetiming',
              otherMeans: 'Iba pang paraan',
              comments: "Mga komento",
              strengthenGoal: "Ang taong gusto kong palakasin ang aking relasyon ay",
              currentStatus: "Ang kasalukuyang katayuan ng ating relasyon ay",
              whatToStrengthen: "Ang gusto kong palakasin",
              strengthenBy: "Gusto kong palakasin ng...",
              strengthenWhy: "Bakit?",
              whyAnswer: "Ito ay makakatulong sa akin na makamit ang aking layunin",
              becoming: "Nagiging",
            },
          },
          planningHome: {
            sectionName: "Pagpaplano ng Tahanan",
            sectionHeader: "Mga Buhay na Layunin",
            subHeader: "Tingnan natin kung ano ang gusto o ayaw mo sa iyong kasalukuyang sitwasyon sa pamumuhay",
            wantToLive: "Saan mo gustong tumira?",
            idealHome: "Ano ang hitsura ng iyong perpektong tahanan?",
            homeAmenities: "Anong amenities ang gusto mong magkaroon ng bahay mo?",
            homeCloseTo: "Ano ang gusto mong maging malapit sa bahay mo?",
            liveWith: "May gusto ka bang makasama?",
            roommateName: "WHO?",
            roommateQuality: "Bakit sa palagay mo magiging angkop ang pamumuhay kasama ang taong ito?",
            askedRoommate: "Nagtanong ka ba tungkol sa pagsasama-sama?",
            liveWithPets: "Mayroon bang anumang mga alagang hayop sa iyong hinaharap?",
            petType: "Anong uri ng hayop?",
            finalThoughts: "May iba ka pa bang gusto sa ideal home mo na hindi natin napag-usapan?",
            whatILike: "Ang gusto ko",
            whatIDislike: "Ang Hindi Ko Gusto",
            saveMessage: "Mahusay, salamat sa pagpaplano para sa iyong tahanan! Gusto mo bang magdagdag ng isa pa?",
            homeOptions: {
              singleStory: "iisang palapag",
              apartment: "Apartment",
              duplex: "Duplex",
              mobileHome: "Mobile Home",
              tinyHome: "Maliit na Bahay",
            },
            amenities: {
              laundry: "Labada",
              internet: "Wireless Internet",
              recreation: "Pasilidad ng Libangan",
              garden: "Hardin",
              deck: "kubyerta",
              patio: "Patio",
              land: "Lupa",
              ramp: "Rampa",
              pool: "Palanguyan",
              bigDoors: "Malalaking Pinto",
            },
            proximities: {
              shopping: "Pamili",
              bus: "Sakayan ng bus",
              friends: "Magkaibigan",
              restaurants: "Mga Restaurant",
              entertainment: "Aliwan",
              publicTransit: "Pampublikong transportasyon",
              family: "Pamilya",
            },
            summary: {
              wantToLive: "Kung saan ko gustong manirahan",
              wantToLiveAnswer: "{{place}} sa isang {{homeType}}",
              homeAmenities: "Sa aking perpektong tahanan gusto ko",
              otherAmenities: "Iba pang mga bagay sa aking perpektong tahanan",
              homeCloseTo: "Gusto kong malapit ang bahay ko",
              roommateName: "Gusto kong makasama",
              liveAlone: "Gusto kong mamuhay mag-isa",
              roommateBecause: "dahil bakit}}",
              roommateQuality: "Bakit sa palagay mo magiging angkop ang pamumuhay kasama ang taong ito?",
              petType: "Mga alagang hayop",
              finalThoughts: "Iba pang mga bagay sa aking perpektong tahanan",
            },
          },
          planningFun: {
            sectionName: "Masaya sa Pagpaplano",
            sectionHeader: "Masayang Layunin",
            subHeader: "Magsimula na tayong magsaya!",
            goal: "Kapag naisipan mong magplano ng isang bagay na masaya, ano ang gusto mong gawin?",
            goalWhy: "Ano ang nakakaakit nito sa iyo? O bakit mo gustong gawin ito?",
            goalWhen: "Kailan mo gustong gawin ito?",
            goalHowOften: "Gaano kadalas mo gustong gawin ito?",
            otherPeople: "May gusto ka bang makasama?",
            otherPeopleWhoCanSupport: "Pakipili kung sino ang makakasuporta sa iyo",
            otherPeopleWhereToFind:
              "Mangyaring pumili ng mga grupo o lugar na maaaring makatulong sa iyong kumonekta sa mga taong may kaparehong interes",
            needToSave: "Kailangan mo bang mag-ipon ng pera para magawa ito?",
            startedSaving: "Nagsimula ka na bang mag-ipon?",
            amountToSave: "Magkano pa ba ang kailangan mong ipon?",
            attachedPeople: "string",
            saveMessage: "Mukhang masaya! Gusto mo bang magdagdag ng isa pang nakakatuwang layunin?",
            asap: "Sa madaling panahon",
            sixMonths: "Susunod na anim na buwan",
            oneYear: "Anim na buwan hanggang isang taon",
            twoYears: "1-2 taon",
            twoPlusYears: "2+ taon",
            oneTime: "Isang Oras na Kaganapan",
            daily: "Araw-araw",
            weekly: "Lingguhan",
            monthly: "Buwanang",
            quarterly: "Kada quarter",
            facebook: "Facebook",
            groupMeet: "Pagkikita ng Grupo",
            newspaper: "Lokal na diyaryo",
            coffeeShop: "Coffee Shop Bulletin Board",
            communityCenter: "Sentro ng Komunidad",
            summary: {
              goal: "Kapag nag-iisip tungkol sa pagpaplano ng isang bagay na masaya, gusto ko",
              goalWhy: "Ito ay nakakaakit sa akin dahil",
              goalWhen: "Gusto kong gawin ito",
              goalHowOften: "at kung gaano kadalas",
              otherPeople: "Gusto kong gawin ito kasama",
              peopleInMyLife: "Ang mga tao sa aking buhay na gusto kong gawin ito kasama ay",
              actionSteps: "Mga Hakbang sa Pagkilos:",
              comments: "Mga komento",
              helpMeAchieve: "Ito ay makakatulong sa akin na makamit ang aking layunin",
              research: "Magsaliksik ng mga paraan upang magplano para sa aktibidad na ito sa pamamagitan ng",
              needToSave: "Kailangan ko bang mag-ipon para sa biyaheng ito?",
              needToSaveYes: "Oo, kailangan kong makatipid ng {{amount}}",
            },
          },
          planningTravel: {
            sectionName: "Pagpaplano ng Paglalakbay",
            subHeader: "Simulan natin ang pagpaplano ng iyong susunod na pakikipagsapalaran",
            destination: "Saan mo gustong pumunta?",
            travelTo: "Nakabiyahe ka na ba sa {{location}} dati?",
            like: "Ano ang nagustuhan mo tungkol sa {{location}}",
            appealingWhy: "Ano ang nakakaakit sa iyo sa {{location}}?",
            travelWhen: "Kailan mo gustong maglakbay sa {{location}}?",
            withOthers: "May gusto ka bang makasama sa paglalakbay?",
            travelWithWho: "Pakipili kung sino ang gusto mong makasama sa paglalakbay",
            toDoAtDestination: "Anong gusto mong gawin habang bumibisita ka?",
            toSave: "Kailangan mo bang mag-ipon ng pera para magawa ito?",
            startedToSave: "Nagsimula ka na bang mag-ipon?",
            amountToSave: "Magkano pa ba ang kailangan mong ipon?",
            accommodations: "Mayroon ka bang anumang pangangailangan sa tirahan kapag naglalakbay ka?",
            needsAndWhy:
              "Mayroon bang mga bagay na gusto mo o kailangan mong kasama sa paglalakbay? Kung gayon, ano ang mga ito?",
            goal: "Ano ang gusto mong pangalanan ang layuning ito?",
            saveMessage: "Naghihintay ang pakikipagsapalaran! Gusto mo bang magdagdag ng isa pang layunin sa paglalakbay?",
            summary: {
              destination: "Gusto kong maglakbay sa",
              appealingWhy: "Bakit gusto kong maglakbay dito",
              travelWhen: "Gusto kong maglakbay dito",
              withOthers: "Gusto ko bang maglakbay kasama ang mga tao",
              withOthersYes: "Oo, gusto kong maglakbay kasama ang {{people}}",
              withOthersAlone: "Gusto kong maglakbay mag-isa",
              toDoAtDestination: "Habang bumibisita ako gusto ko",
              accommodations: "Kabilang ang mga tirahan na kakailanganin ko kapag naglalakbay",
              needsAndWhy: "Ang iba pang mga bagay na gusto kong dalhin kapag naglalakbay ako ay kasama",
              needToSave: "Kailangan ko bang mag-ipon para sa biyaheng ito?",
              needToSaveYes: "Oo, kailangan kong makatipid ng {{amount}}",
            },
          },
          planningWork: {
            sectionName: "Pagpaplano ng Trabaho",
            sectionHeader: "Mga Layunin sa Trabaho",
            addSubHeader: "Magplano tayo para sa isang bagong hinaharap",
            whatILiked: "Ang nagustuhan ko",
            whatIDisliked: "Ang hindi ko nagustuhan",
            formSubHeader: "Tingnan natin kung ano ang nagustuhan mo o hindi mo nagustuhan sa iyong karanasan sa trabaho",
            workGoal: "Ano ang iyong layunin para sa trabaho?",
            profession: "Ano ang umaakit sa iyo sa propesyon na ito?",
            excites: "Ano ang pinaka nasasabik sa iyo tungkol sa karerang ito?",
            goalLookLike: "Kapag iniisip mo ang iyong layunin, ano ang hitsura nito?",
            salary: "Magkano ang gusto mong kumita sa isang taon?",
            preferredWorkDays: "Ano ang gusto mong araw ng trabaho?",
            preferredWorkHours: "Ano ang gusto mong oras ng trabaho?",
            haveNow: "Ano ang mayroon ka ngayon na magdadala sa iyo sa iyong kinabukasan?",
            priorExperience: "Anong karanasan ang tutulong sa iyo na makarating sa iyong kinabukasan?",
            haveReference: "May kilala ka ba sa propesyon na ito?",
            referenceName: "Sino sila?",
            referenceWhere: "Saan sila nagtatrabaho?",
            inLifeSupport: "Sino sa iyong buhay ang maaaring sumuporta sa iyo sa layuning ito?",
            support: "Mayroon pa bang ibang makakatulong sa iyo na makamit ang iyong layunin?",
            commit: "Ano ang maaari mong ipangako na gawin upang makatulong sa paglikha ng hinaharap na ito?",
            placeholderBudgetNeed: "Ipasok ang kailangan mong i-budget",
            placeholderResponsible: "Maglagay ng pangalan",
            placeholderHowOften: "Ipasok kung gaano kadalas ito gagawin",
            placeholderImportant: "Ilagay kung bakit ito mahalaga",
            workPreferences: 'Aking Mga Kagustuhan sa Trabaho',
            monday: "Lunes",
            tuesday: "Martes",
            wednesday: "Miyerkules",
            thursday: "Huwebes",
            friday: "Biyernes",
            saturday: "Sabado",
            sunday: "Linggo",
            morning: "Umaga",
            afternoon: "Hapon",
            evening: "Gabi",
            overnight: "Magdamag",
            anytime: "Kahit kailan",
            saveMessage: "Mukhang magandang layunin sa trabaho! Gusto mo bang magdagdag ng isa pang layunin sa trabaho?",
            budgetTable: {
              title: "Ano ang kailangan mong makatipid para matulungan kang makamit ang iyong layunin?",
              actionStep: "Hakbang ng Aksyon",
              saveFor: "Mag-ipon ng pera para sa",
              saveMoneyFor: "Ano ang kailangan kong mag-ipon ng pera?",
              responsible: "Sino ang may pananagutan?",
              howOften: "Gaano kadalas?",
              important: "Bakit ito mahalaga?",
              why: "Bakit?",
              contact: "Contact",
              me: "Ako",
              theyCanSupport: "Maaari nila akong suportahan sa aking layunin",
              thisWillHelpMe: "Ito ay makakatulong sa akin na makamit ang aking layunin",
              deleteConfirm: "Sigurado ka bang gusto mong alisin ang hilera ng badyet na ito?",
              deleteSuccess: "Ok, naalis na ang row",
            },
            summary: {
              workGoal: "Ang layunin ko sa trabaho",
              profession: "Ano ang umaakit sa akin sa propesyon na ito",
              excites: "Ang pinaka ikinatuwa ko tungkol dito ay",
              goalLookLike: "Kapag iniisip ko ang aking layunin ay parang",
              haveNow: "Ano ang makakatulong sa akin na makamit ang aking kinabukasan",
              preferredWorkDays: "Mga Ginustong Araw ng Trabaho",
              preferredWorkHours: "Pinugustuhang Oras ng Trabaho",
              salary: "Nais na suweldo",
              haveReference: "May kilala ka ba sa propesyon na ito?",
              referenceName: "Sino sila?",
              referenceWhere: "Saan sila nagtatrabaho?",
              inLifeSupport: "Sino sa iyong buhay ang maaaring sumuporta sa iyo sa layuning ito?",
              support: "Mayroon pa bang ibang makakatulong sa iyo na makamit ang iyong layunin?",
              commit: "Ano ang maaari mong ipangako na gawin upang makatulong sa paglikha ng hinaharap na ito?",
            },
          },
          GTKYPage: {
            pageTitle: "Pagkilala sa Iyo",
            GTKYVideo: "Video ng Pagkilala sa Iyo",
            likeAdmire: "I-like at Humanga",
            peopleInMyLife: "Mga Tao sa Aking Buhay",
            thingsIDo: "Mga Bagay na Gusto Kong Gawin Para Masaya",
            favoriteThings: "Aking Mga Paboritong Bagay",
            myRoutines: "Aking Mga Gawain",
            historyMap: "Mapa ng Kasaysayan",
            travel: "Paglalakbay",
            viewComments: "Tingnan ang Mga Komento"
          },
          myRoutines: {
            sectionName: "Aking Mga Gawain",
            subHeader: "Sabihin sa amin ang lahat tungkol sa hitsura ng iyong mga gawain",
            videoTitle: "Mga routine ko - Nakatutulong na video",
            time: "Oras",
            noTime: "Walang Oras",
            day: "Araw",
            noDay: "Walang Araw",
            dailyError: "Mangyaring maglagay ng oras at paglalarawan",
            weeklyError: "Mangyaring maglagay ng araw at paglalarawan",
            successMessage: "Salamat! Idinagdag na ang iyong mga gawain",
            dailyRoutines: "Ano ang iyong pang-araw-araw na gawain?",
            weeklyRoutines: "Ano ang iyong lingguhang gawain o ritwal?",
            dailyPlaceholder: "Magdagdag ng isang bagay na ginagawa mo bawat araw at kapag ginawa mo ito",
            weeklyPlaceholder: "Magdagdag ng isang bagay na ginagawa mo bawat linggo",
            dayStickyTitle: "Araw Ko",
            weekStickyTitle: "Ang linggo ko",
            deleteMessage: "Sigurado ka bang gusto mong alisin ang routine na ito?",
            deleteSuccess: "Ok, inalis na ang routine",
            contributor: {
              subHeader: "Sabihin sa amin ang lahat tungkol sa hitsura ng kanilang mga gawain",
              dailyRoutines: "Ano ang kanilang pang-araw-araw na gawain?",
              weeklyRoutines: "Ano ang kanilang lingguhang gawain o ritwal?",
              dailyPlaceholder: "Magdagdag ng isang bagay na ginagawa nila sa bawat araw at kapag ginawa nila ito",
              weeklyPlaceholder: "Magdagdag ng isang bagay na ginagawa nila bawat linggo",
              dayStickyTitle: "Araw ni {{name}}",
              weekStickyTitle: "Linggo ni {{name}}",
            },
            days: {
              mon: "Lunes",
              tues: "Martes",
              wed: "Miyerkules",
              thurs: "Huwebes",
              fri: "Biyernes",
              sat: "Sabado",
              sun: "Linggo"
            }
          },
          thingsIDo: {
            sectionName: "Mga Bagay na Gusto Kong Gawin Para Masaya",
            subHeader: "Piliin ang iyong aktibidad o gumawa ng bago",
            indoors: "Sa loob ng bahay",
            outdoors: "Sa labas",
            frequencyCard: "Gusto Kong Gawin Ito:",
            likeMostCard: "Ang pinakagusto ko sa aktibidad na ito:",
            tellUsWhatYouDo: "Sabihin sa amin ang lahat tungkol sa kung ano ang gusto mong gawin para sa kasiyahan!",
            indoor: "Sa loob ng bahay",
            outdoor: "Sa labas",
            community: "Sa aking komunidad",
            whereToHaveFun: "Saan mo gustong magsaya?",
            yourActivity: "Ano ang gusto mong gawin?",
            chosenActivity: "Ang aktibidad na iyong pinili ay",
            howOften: "Gaano kadalas mo gustong gawin ito?",
            aloneOrWithOthers: "Gusto mo bang gawin ang aktibidad na ito nang mag-isa o kasama ang iba?",
            attachedPeople: "Sino ang gusto mong gawin ito kasama?",
            addThingIDo: "Bagong aktibidad",
            otherPeopleInvolved: "May iba ka bang gustong gawin ang aktibidad na ito?",
            anyPhotos: "Mayroon ka bang anumang nakakatuwang larawan ng {{activity}} na gusto mong ibahagi?",
            like: "Ano ang pinakagusto mo sa {{activity}}?",
            youSelected: "Ang iyong aktibidad:",
            saveMessage: "Anong pakikipagsapalaran! Gusto mo bang magdagdag ng isa pa?",
            deleteConfirm: "Sigurado ka bang gusto mong tanggalin ang aktibidad na ito?",
            deleteSuccess: "Ang iyong aktibidad ay matagumpay na natanggal",
            daily: "Araw-araw",
            weekly: "Lingguhan",
            monthly: "Buwanang",
            oncePerYear: "Isang beses sa isang taon",
            fewTimes: "Ilang beses sa isang taon",
            whenever: "Kung kailan ko kaya",
            contributorQuestions: {
              frequencyCard: "Gusto Nila Ito:",
              likeMostCard: "Ang pinakagusto nila sa aktibidad na ito:",
              tellUsWhatYouDo: "Sabihin sa amin ang lahat tungkol sa kung ano ang gusto nilang gawin para masaya!",
              community: "Sa aking komunidad",
              whereToHaveFun: "Saan nila gustong magsaya?",
              yourActivity: "Ano ang gusto nilang gawin?",
              chosenActivity: "Ang aktibidad na kanilang pinili ay",
              howOften: "Gaano kadalas nila gustong gawin ito?",
              aloneOrWithOthers: "Gusto ba nilang gawin ang aktibidad na ito nang mag-isa o kasama ang iba?",
              attachedPeople: "Sino ang gusto nilang gawin ito?",
              otherPeopleInvolved: "May iba pa ba silang gustong gawin ang aktibidad na ito?",
              anyPhotos: "Mayroon ba silang nakakatuwang larawan ng {{activity}} na gusto mong ibahagi?",
              like: "Ano ang pinakagusto nila sa {{activity}}?",
              youSelected: "Ang kanilang aktibidad:",
              saveMessage: "",
            },
            activities: {
              reading: "Nagbabasa",
              writing: "Pagsusulat",
              yoga: "Yoga",
              pilates: "Pilates",
              videoGames: "Mga Video ua si",
              cardGames: "Mga Larong Card",
              boardGames: "Mga board ua si",
              painting: "Pagpipinta",
              instruments: "Mga instrumento",
              drawing: "Pagguhit",
              art: "Sining",
              replicaModels: "Mga Replikang Modelo",
              movies: "Mga pelikula",
              tv: "TV",
              movieTheater: "Sinehan",
              diningOut: "Kakain sa Labas",
              yardSales: "Benta ng Yard",
              antiquing: "Antiquing",
              mall: "Papunta sa Mall",
              shopping: "Pamimili",
              sports: "Laro",
              walks: "Naglalakad",
              birdWatching: "Pagmamasid ng Ibon",
              hiking: "Hiking",
              fishing: "Pangingisda",
              gardening: "Paghahalaman",
              camping: "Camping",
              boating: "Pamamangka",
              botany: "Botanika"
            },
            summary: {
              activity: "Ang gusto kong gawin",
              howOften: "Gusto kong gawin ang aktibidad na ito",
              withWho: "Gusto kong gawin ito kasama",
              withWhoAlone: "Ginagawa ko ito",
              byMyself: "sa aking sarili",
              like: "Ang pinaka gusto ko ay",
              buttonText: "Pumunta sa Gusto Kong Gawin Para Masaya",
            },
          },
          likeAndAdmire: {
            sectionName: "I-like at humanga",
            sectionTitle: "Ulo, Puso, Kamay",
            subTitle: "I-drag at i-drop ang iyong mga regalo sa kategoryang iyong pinili o lumikha ng iyong sarili",
            videoTitle: "I-like at Humanga - Nakatutulong na Video",
            filterAll: "Lahat ng bagay na nagustuhan at hinahangaan (Default)",
            filterOthers: "Ano ang gusto at hinahangaan ng iba tungkol sa akin",
            filterMine: "Ang gusto at hinahangaan ko sa sarili ko",
            resetAll: "Ulitin lahat",
            addNew: "Magdagdag ng bagong salita",
            saveMessage:
              "Magandang trabaho {{firstName}}, napakagandang marinig ang lahat ng magagandang bagay tungkol sa iyo!",
            head: "Ulo",
            heart: "Puso",
            hand: "Kamay",
            headExplanation: "Mga regalo ng isip, utak, o pag-iisip; mga regalong alam mo",
            heartExplanation: "Mga regalong sa tingin mo ay konektado o hilig mo",
            handExplanation: "Mga regalong pisikal mong maibibigay sa pamamagitan ng pagkilos o paggawa",
            resetMessage: "Sigurado ka bang gusto mong i-reset ang lahat?",
            resetSuccess: "Ok, na-reset ang mga item",
            deleteMessage: "Sigurado ka bang gusto mong tanggalin ang regalong ito?",
            deleteSuccess: "Matagumpay na natanggal ang regalo",
            contributor: {
              subTitle: "I-drag at i-drop ang kanilang mga regalo sa kategoryang iyong pinili o lumikha ng iyong sarili",
              filterOthers: "Ano ang gusto at hinahangaan ng iba sa kanila",
              filterMine: "Kung ano ang gusto at hinahangaan nila sa kanilang sarili",
            },
            summary: {
              head: "Ulo",
              heart: "Puso",
              hand: "Kamay",
            },
          },
          peopleInMyLife: {
            sectionName: "Mga Tao sa Aking Buhay",
            subHeader:
              "Magdagdag ng isang tao o mag-click sa pangalan ng tao upang magdagdag ng higit pang mga detalye tungkol sa kanila",
            adaptionDisclaimer: "Adaption of Relationship Map",
            acquaintance: "Kakilala",
            acquaintances: "Kakilala",
            add: "Magdagdag ng Tao",
            addAsContributor: "Idagdag bilang isang kontribyutor sa aking plano",
            close: "Malapit",
            contributor: "Contributor",
            contributorAdditions: "Mga Pagdaragdag ng Contributor",
            coWorker: "Katrabaho",
            coWorkers: "Mga Katrabaho",
            deletePerson: "Sigurado ka bang gusto mong tanggalin ang taong ito sa mga Tao sa buhay mo?",
            edit: "I-edit ang Tao",
            family: "Pamilya",
            favoritePerson: "Paboritong Tao ng Buwan",
            friend: "Kaibigan",
            friends: "Magkaibigan",
            howClose: "Gaano ka ka-close sa taong ito?",
            importantWhy: "Bakit mahalaga sa iyo ang taong ito",
            involvedInSupport: "Kasangkot sa aking suporta",
            mapPlace: "Ito ang tutukuyin ang kanilang lugar sa iyong People In My Life Map",
            notClose: "Hindi malapit",
            paidSupport: "Ang taong ito ba ay binayaran ng suporta?",
            paidSupportShort: "Bayad na suporta",
            personIs: "Ang taong ito ay...",
            relationshipToYou: "Relasyon sa Iyo",
            removePerson: "Alisin ang Tao",
            weAre: "Tayo ay...",
            saveMessage: "Ang lahat ng mga tao sa iyong buhay ay naligtas, mahusay na gawain.",
            me: 'Ako',
            contributorQuestions: {
              howClose: "Gaano sila kalapit sa taong ito?",
              importantWhy: "Bakit mahalaga sa kanila ang taong ito",
              mapPlace: "Ito ang tutukuyin ang kanilang lugar sa People In My Life Map",
              relationshipToYou: "Relasyon sa kanila",
              theyAre: "Sila ay...",
              involvedInSupport: "Kasangkot sa kanilang suporta",
            },
            dialog: {
              nameError: "Mangyaring maglagay ng pangalan",
              personIsError: "Mangyaring pumili ng isang opsyon",
              emailError: "Dapat maglagay ng email address para sa mga potensyal na kontribyutor",
              emailValidError: "Mangyaring magpasok ng wastong email address",
              sendInvitation: "Magpadala ng imbitasyon",
            },
          },
          favoriteThings: {
            sectionName: "Aking Mga Paboritong Bagay",
            subHeader: "Idagdag ang iyong mga paboritong bagay sa pamamagitan ng unang pagpili ng kategorya",
            contributorSubHeader:
              "Mangyaring pumili ng isang kategorya upang simulan ang pagdaragdag ng kanilang mga paboritong bagay",
            viewOnlySubHeader: "Tingnan ang kanilang mga paboritong bagay",
            addDialogTitle: "Magdagdag ng Paboritong Bagay",
            editDialogTitle: "I-edit ang Iyong Paboritong Bagay",
            categories: "Mga Kategorya",
            categoryList: "Listahan ng kategorya",
            myFavorites: "Aking mga Paborito",
            theirFavorites: "Ang kanilang mga Paborito",
            place: "Lugar",
            placeToEat: "Lugar na Kainan",
            food: "Pagkain",
            movie: "Pelikula",
            book: "Aklat",
            videoGame: "Mga Video Game",
            game: "Laro",
            sport: "Isport",
            animal: "Hayop",
            music: "Musika",
            memory: "Memorya",
            other: "Iba pa",
            placeLabel: "Paborito mong puntahan",
            placeToEatLabel: "Paborito mong kainan",
            foodLabel: "Iyong paboritong pagkain",
            movieLabel: "Ang iyong paboritong pelikula",
            bookLabel: "Ang iyong paboritong libro",
            videoGameLabel: "Ang iyong paboritong video game",
            gameLabel: "Ang iyong paboritong laro",
            sportLabel: "Ang iyong paboritong sport o sports team",
            animalLabel: "Ang iyong paboritong alagang hayop o uri ng hayop ",
            musicLabel: "Ang iyong paboritong banda, kanta o genre ",
            memoryLabel: "Isa sa iyong mga paboritong alaala",
            otherLabel: "Anumang paboritong bagay",
            chooseAView: "Pumili ng view",
            allThings: "Lahat ng Paboritong Bagay (Default)",
            thingsIAdded: "Mga paboritong bagay na idinagdag ko",
            thingsOthersAdded: "Kung ano ang idinagdag ng iba",
            treasureChestAlt: "Paboritong Bagay Treasure Chest",
            deleteConfirm: "Sigurado ka bang gusto mong tanggalin ang paboritong bagay na ito?",
            deleteSuccess: "Ang paboritong bagay ay tinanggal",
            saveAlertText: "Salamat {{name}}, napakagandang pag-aaral tungkol sa iyong mga paboritong bagay!",
            plurals: {
              place: "Mga lugar",
              placeToEat: "Lugar na makakainan",
              movie: "Mga pelikula",
              book: "Mga libro",
              videoGame: "Mga Video Game",
              game: "Mga laro",
              sport: "laro",
              animal: "Mga hayop",
              memory: "Mga alaala",
            }
          },
          travelPlan: {
            sectionName: "Paglalakbay",
            sectionHeader: "Paglalakbay",
            subHeader: "Ibahagi ang iyong paboritong lugar para maglakbay o kung saan mo gustong pumunta",
            travelLocally: "Gusto mo bang maglakbay sa loob ng Estados Unidos o Internasyonal?",
            local: "Sa loob ng Estados Unidos",
            international: "Internasyonal",
            frequencyCard: "Gusto Kong Gawin Ito:",
            likeMostCard: "Ang pinakagusto ko sa lugar na ito:",
            pageSubHeader: "Sabihin sa amin ang lahat tungkol sa kung saan mo gustong maglakbay",
            travelWhere: "Saan mo gustong maglakbay?",
            likeMost: "Ano ang pinakagusto mo sa paglalakbay sa {{location}}?",
            likeToTravel: "Paano mo gustong maglakbay?",
            alone: "Gusto mo bang gawin ito nang mag-isa o kasama ang iba?",
            howOften: "Gaano kadalas mo gustong maglakbay dito?",
            anyoneElse: "May iba ka pa bang gustong makasama?",
            anyPictures: "Mayroon ka bang anumang nakakatuwang larawan ng {{location}} na gusto mong ibahagi?",
            deleteMessage: "Sigurado ka bang gusto mong tanggalin ang lokasyong ito?",
            addTravelPlan: "Bagong Destinasyon ng Paglalakbay",
            addTravelContributor: "Magdagdag ng isa pang lugar na sa tingin mo ay gusto nilang lakbayin",
            attachedPeople: "Sino ang gusto mong kasama sa paglalakbay?",
            boat: "Bangka",
            bus: "Bus",
            car: "Kotse",
            plane: "Eroplano",
            train: "Tren",
            saveText: "Anong pakikipagsapalaran! Gusto mo bang magdagdag ng isa pa?",
            deleteConfirm: "Sigurado ka bang gusto mong tanggalin ang aktibidad na ito?",
            deleteSuccess: "Ang iyong aktibidad ay matagumpay na natanggal",
            contributorQuestions: {
              subHeader:
                "Ibahagi ang kanilang paboritong lugar upang maglakbay o sa isang lugar na talagang gusto nilang puntahan",
              frequencyCard: "Gusto Nila Ito:",
              likeMostCard: "Ang pinakagusto nila sa lugar na ito:",
              pageSubHeader: "Sabihin sa amin ang lahat tungkol sa kung saan nila gustong maglakbay",
              travelLocally: "Gusto ba nilang maglakbay sa loob ng Estados Unidos o Internasyonal?",
              travelWhere: "Saan nila gustong maglakbay?",
              likeMost: "Ano ang pinakagusto nila sa paglalakbay sa {{location}}?",
              likeToTravel: "Paano nila gustong maglakbay?",
              alone: "Gusto ba nilang gawin ito nang mag-isa o kasama ang iba?",
              howOften: "Gaano kadalas nila gustong maglakbay dito?",
              anyoneElse: "May iba pa ba silang gustong makasama?",
              anyPictures: "Mayroon ba silang nakakatuwang larawan ng {{location}} na gusto mong ibahagi?",
              attachedPeople: "Sino ang gusto nilang kasama sa paglalakbay?",
            },
            summary: {
              place: "Gusto kong maglakbay sa",
              travelWithSolo: "Gusto kong maglakbay dito",
              byMyself: "Sa Aking Sarili",
              travelWith: "Gusto kong maglakbay dito kasama",
              frequency: "Pupunta ako diyan",
              frequencyAnswer: "{{frequency}} at ako ay naglalakbay sa pamamagitan ng {{transportation}}",
              favoriteThing: "Ang paborito kong bagay tungkol sa paglalakbay sa {{place}} ay",
              buttonText: "Maglakbay",
            },
          },
          historyMap: {
            sectionHeader: "Ang aking mapa ng kasaysayan",
            subHeader: "Sabihin sa amin ang tungkol sa mahahalagang milestone, anibersaryo o kaganapan sa iyong buhay",
            addMilestone: "Magdagdag ng Milestone",
            editMilestone: "I-edit ang Milestone",
            sectionName: "Mapa ng Kasaysayan",
            category: "Kategorya",
            familyLife: "Buhay pamilya",
            school: "Paaralan",
            activities: "Mga Aktibidad",
            friends: "Magkaibigan",
            housing: "Pabahay",
            work: "Trabaho",
            relationships: "Relasyon",
            chooseDate: "Pumili ng isang petsa",
            milestone: "Milestone",
            details: "Mga Detalye",
            back: "Bumalik sa Pagkilala sa Iyo",
            saveMessage: "Ito ay mahusay na natutunan ang lahat tungkol sa iyo, salamat sa pagbabahagi!",
            validDateError: "Mangyaring maglagay ng wastong petsa",
            rangeDateError: "Ang petsa ay wala sa saklaw",
            deleteMessage: "Sigurado ka bang gusto mong tanggalin ang milestone na ito?",
            deleteSuccess: "Matagumpay na natanggal ang iyong milestone",
          },
          contributor: {
            addToRelationshipMap: "Idagdag sa aking Relationship Map",
            thisPersonIs: "Ang taong ito ay isang....",
            howClose: "Gaano ka ka-close sa taong ito?",
            close: "Malapit",
            notClose: "Hindi malapit",
            setPermissions: "Itakda ang Mga Pahintulot sa Contributor",
            hasBeenEmailed: "Isang imbitasyon ay ipinadala sa iyong kontribyutor",
          },
          happeningPage: {
            pageTitle: "Nangyayari sa Buhay Ko",
            living: "Naninirahan sa Aking Tahanan",
            work: "Trabaho",
            school: "Paaralan",
            health: "Kalusugan at kaligtasan",
            happeningVideo: "Video na Nangyayari Sa Aking Buhay",
          },
          happeningHome: {
            pageTitle: "Naninirahan sa Aking Tahanan",
            subTitle: "Alamin pa natin kung saan ka nakatira!",
            singleStory: "Iisang kwento na bahay",
            multiLevel: "Dalawang palapag na bahay",
            apartment: "Apartment",
            facility: "Pasilidad",
            homeless: "Walang tirahan",
            groupHome: "Tahanan ng Grupo",
            car: "Kotse",
            rvCamper: "RV/Camper",
            currentlyLive: "Saan ka nakatira ngayon?",
            currentlyLiveWith: "Sino ang iyong kasalukuyang nakatira?",
            parents: "(mga) Magulang",
            guardians: "(mga) tagapag-alaga",
            siblings: "(mga) kapatid",
            friends: "(mga) kaibigan",
            roommates: "(mga) ka-roommate",
            spouse: "Asawa",
            children: "Bata/Mga Bata",
            alone: "Namumuhay akong mag isa",
            dogs: "(mga) aso",
            cats: "(mga) pusa",
            other: "Iba pa, paki explain",
            explain: "Pakipaliwanag",
            tableTitle: "Ano ang gusto at ayaw mo sa tinitirhan mo?",
            amenityHeader: "Amenities",
            selectHeader: "Pakipili ng gusto o hindi gusto",
            commentHeader: "Mga komento",
            commentsLikes: "Mga komento tungkol sa aking mga gusto",
            commentsDislikes: "Mga komento tungkol sa mga hindi ko gusto",
            tellUsWhy: "Mangyaring sabihin sa amin kung bakit dito",
            whatILike: 'Ano ang gusto ko tungkol sa kung saan ako nakatira',
            whatIDislike: 'Ang hindi ko gusto tungkol sa kung saan ako nakatira',
            amenities: {
              publicTransportation: "Pampublikong transportasyon",
              accessibility: "Accessibility",
              neighborhood: "Kapitbahayan",
              friendsFamily: "Malapit sa Kaibigan at Pamilya",
              shopping: "Malapit sa Shopping",
              furniture: "Muwebles",
              homeType: "Uri ng Tahanan",
              squareFootage: "Square Footage",
              housemates: "Mga kasambahay",
              livingAlone: "Nabubuhay mag-isa",
              neighbors: "Mga kapitbahay",
              management: "Pamamahala ng Ari-arian",
              support: "kawani ng suporta",
              tellUsWhy: "Pakisabi sa amin kung bakit",
            },
            summary: {
              currentlyLive: "Kung saan ako nakatira",
              currentlyLiveWith: "Sino ang kasama ko",
            },
          },
          happeningSchool: {
            header: "Paaralan",
            subHeader:
              "Hindi na kami makapaghintay na marinig ang higit pa tungkol sa paaralan, sabihin sa amin ang tungkol sa iyong karanasan",
            enrolledInSchool: "Nasa school ka ba ngayon?",
            schoolNameOOS: "Saang school ka pumasok?",
            gradeLevelOOS: "Anong grade natapos mo?",
            gradYearOOS: "Kailan ka nagtapos ng pag-aaral?",
            schoolLikeOOS: "Ano ang pinaka nagustuhan mo sa paaralan?",
            schoolDislikeOOS: "Ano ang hindi mo nagustuhan sa paaralan?",
            volunteerOOS: "Habang nasa paaralan, nagboluntaryo ka ba kahit saan?",
            volunteerWhereOOS: "Saan ka nagboluntaryo?",
            volunteerDislikeOOS: "Anong hindi mo nagustuhan?",
            volunteerLikeOOS: "Ano ang nagustuhan mo sa pagboboluntaryo?",
            graduationGoalOOS: "Ano iyon?",
            gradYearOtherOOS: "",
            goalYesOOS: "May gusto ka bang gawin kapag naka-graduate ka na?",
            schoolName: "Saang paaralan ka pumapasok?",
            gradeLevel: "Nasa anong antas ka?",
            gradYear: "Kailan ka tapos sa school?",
            schoolLike: "Ano ang pinakagusto mo sa paaralan?",
            schoolDislike: "Anong ayaw mo sa school?",
            volunteer: "Nagboluntaryo ka ba kahit saan?",
            volunteerWhere: "Saan ka nagvo-volunteer?",
            volunteerDislike: "Ano ang hindi mo gusto?",
            volunteerLike: "Ano ang gusto mo sa pagboboluntaryo?",
            graduationGoal: "Ano ito?",
            gradYearOther: "",
            goalYes: "May gusto ka bang gawin kapag naka-graduate ka na?",
            summary: {
              schoolName: "Ang tawag sa school na pinapasukan ko",
              schoolNameOOS: "Ang tawag sa paaralang pinasukan ko",
              gradeLevel: "Nasa grade na ako",
              gradeLevelOOS: "Tapos na ako ng grade",
              gradYear: "Tapos na ako sa school",
              gradYearOOS: "Tapos na ako sa school",
              schoolLike: "Ang gusto ko sa paaralan",
              schoolLikeOOS: "Ang nagustuhan ko sa paaralan",
              schoolDislike: "Ang ayaw ko sa school",
              schoolDislikeOOS: "Ang hindi ko nagustuhan sa paaralan",
              volunteerWhere: "Sa paaralan ako nagboluntaryo sa",
              volunteerWhereOOS: "Sa paaralan ako nagboluntaryo sa",
              volunteerLike: "Ang gusto ko sa pagboboluntaryo",
              volunteerLikeOOS: "Ang nagustuhan ko sa pagboboluntaryo",
              volunteerDislike: "Ang hindi ko gusto sa pagboboluntaryo",
              volunteerDislikeOOS: "Ang hindi ko nagustuhan sa pagboboluntaryo",
              graduationGoal: "Kapag nakatapos ako, gusto ko",
              graduationGoalOOS: "Nung nagtapos ako, gusto ko",
            },
          },
          happeningWork: {
            sectionName: "Karanasan sa trabaho",
            subHeader:
              "Sabihin sa amin ang higit pa tungkol sa iyong karanasan sa trabaho, hindi na kami makapaghintay na marinig!",
            pastExperience: "Mangyaring ibahagi ang iyong nakaraang karanasan sa trabaho",
            pastExperienceLike: "Ano ang nagustuhan mo sa iyong nakaraang karanasan sa trabaho?",
            pastExperienceDislike: "Ano ang hindi mo nagustuhan sa iyong nakaraang karanasan sa trabaho?",
            hasCurrentEmployment: "Kasalukuyan ka bang nagtatrabaho?",
            currentEmployment: "Saan ka kasalukuyang nagtatrabaho?",
            currentDuration: "Gaano katagal ka nang nagtatrabaho doon?",
            currentLike: "Ano ang gusto mo sa iyong kasalukuyang trabaho?",
            currentDislike: "Ano ang ayaw mo sa iyong kasalukuyang trabaho?",
            summary: {
              pastExperience: "Kung saan ako nagtrabaho sa nakaraan",
              pastExperienceLike: "Ano ang gusto ko tungkol sa kung saan ako nagtrabaho sa nakaraan",
              pastExperienceDislike: "Ano ang hindi ko gusto tungkol sa kung saan ako nagtrabaho sa nakaraan",
              currentEmployment: "Kasalukuyan akong nagtatrabaho sa",
              currentDuration: "Nagtatrabaho ako dito",
              currentLike: "Ano ang gusto ko tungkol sa kung saan ako nagtatrabaho",
              currentDislike: "Ang ayaw ko sa pinagtatrabahuhan ko",
            },
          },
          happeningHealth: {
            header: "Kalusugan at kaligtasan",
            subHeader: "Kilalanin pa kita ng kaunti, pakibahagi kung ano ang komportable mo",
            contributorAdditionsToApprove:
              "Idinagdag ng iyong mga kontribyutor ang mga DME at/o Mga Suporta sa iyong plano. Gusto mo bang aprubahan sila?",
            medInfo: "Mayroon bang anumang bagay na nauugnay sa kalusugan na nakakaapekto sa iyong buhay?",
            allergies: "Pakilista ang mga allergy na gusto mong malaman namin",
            dmeSectionTitle: "Anong uri ng Matibay na Kagamitang Medikal at mga suporta ang ginagamit mo?",
            dmeContributorTitle: "Anong uri ng Matibay na Kagamitang Medikal at mga suporta ang ginagamit nila?",
            sectionSubTitle:
              "Pumili ng isang pindutan upang idagdag ito sa iyong listahan, i-click ang 'x' sa iyong listahan upang alisin ito",
            supportsSectionTitle: "Paano ka masusuportahan ng iba sa pakiramdam at pananatiling malusog?",
            supportContributorTitle: "Paano sila masusuportahan ng iba sa pakiramdam at pananatiling malusog?",
            dmePostItTitle: "Ano ang hitsura ng magandang suporta",
            supportPostItTitle: "Paano ako susuportahan ng iba",
            dmeOptions: {
              bloodSugarMonitors: "Mga monitor ng asukal sa dugo",
              bloodSugarTestStrips: "Mga strip ng pagsubok ng asukal sa dugo",
              canes: "Mga tungkod",
              commodeChairs: "Mga upuan ng Commode",
              continuousPassiveDevices: "Patuloy na passive motion device",
              continuousPassiveAirwayPressureDevices: "Continuous Positive Airway Pressure (CPAP) device",
              crutches: "Mga saklay",
              hospitalBeds: "Mga kama sa ospital",
              infusionPumps: "Mga infusion pump at supply",
              lancetDevices: "Mga lancet na device at lancet",
              nebulizerMedications: "Mga Nebulizer at nebulizer na gamot",
              oxygenEquipment: "Mga kagamitan at accessories ng oxygen",
              patientLifts: "Pag-angat ng pasyente",
              supportSurfaces: "Mga ibabaw ng suportang nagpapababa ng presyon",
              suctionPumps: "Mga suction pump",
              tractionEquipment: "Mga kagamitan sa traksyon",
              walkers: "Mga naglalakad",
              rollator: "Rollator",
              wheelchairMotorized: "Wheelchair - nakamotor",
              wheelchairManual: "Wheelchair - manwal",
              wheelchairStanding: "Wheelchair - Naka-reclining/Standing",
              scooterMotorized: "Scooter - nakamotor",
              scooterManual: "Scooter - manwal",
              assistiveTech: "Pantulong na teknolohiya",
              translator: "Tagasalin",
              brailleKeyboard: "Braille Keyboard",
              hearingAid: "Tulong pandinig",
            },
            supportOptions: {
              cookMeals: "Magluto ka ng pagkain ko",
              prepareMeals: "Tulungan mo akong maghanda ng mga pagkain ko",
              prepareMedication: "Tulungan mo akong ihanda ang gamot ko",
              medicationSchedule: "Tulungan akong gumawa ng iskedyul ng gamot",
              doctorTransportation: "Tulungan mo akong makapunta at makaalis sa mga doktor",
              createBudget: "Tulungan akong gumawa ng badyet",
              beActive: "Tulungan mo akong maging aktibo",
            },
            summary: {
              medInfo: "Kabilang sa mga kondisyong pangkalusugan na gusto kong malaman ng iba",
              allergies: "Ang mga allergy na gusto kong malaman ng iba ay kasama",
              dmeSectionTitle: "Kabilang ang Matibay na Suporta sa Medikal na ginagamit ko",
              supportsSectionTitle: "Ang mga tao sa aking buhay ay maaaring sumuporta sa akin sa mga sumusunod na paraan",
            },
          },
          happeningGeneral: {
            sectionSave:
              "Salamat sa impormasyon {{userFirstName}}, maaari kang lumipat sa isa pang seksyon ng Ano ang Nangyayari sa Iyong Buhay!",
          },
          planSummary: {
            generatePdf: "Bumuo ng PDF",
            downloadPdf: "Mag-download ng PDF",
            selectTravelPlan: "Piliin ang iyong plano sa paglalakbay",
            selectActivity: "Piliin ang iyong aktibidad",
            selectGoal: "Pumili ng layunin",
            sections: {
              importantToMe: "Ano ang Mahalaga sa Akin",
              importantForMe: "Ano ang Mahalaga Para sa Akin",
              goals: "Pagplano ng Layunin",
            },
            cardTitles: {
              likeAdmire: "Kung Ano ang Gusto at Hinahangaan ng mga Tao Tungkol sa Akin",
              people: "Mahahalagang Tao sa Aking Buhay",
              milestones: "Mahahalagang Milestones sa Aking Buhay",
              favoriteThings: "Ilan sa Aking Mga Paboritong Bagay",
              thingsIDo: "Ang Gusto kong gawin para sa Kasiyahan",
              travelPlan: "Mga Lugar na Gusto Kong Maglakbay",
              dailyRoutines: "Aking Pang-araw-araw na Gawain",
              weeklyRoutines: "Aking Lingguhang Gawain",
              happeningHome: "Naninirahan sa Aking Tahanan",
              happeningWork: "Aking Karanasan sa Trabaho",
              happeningSchool: "Aking Karanasan sa Paaralan",
              happeningHealth: "Aking Kalusugan at Kaligtasan",
              homeGoals: "Aking Mga Layunin sa Pabahay",
              workGoals: "Aking Mga Layunin sa Trabaho",
              healthGoals: "Aking Mga Layunin sa Kalusugan",
              funGoals: "Aking Mga Kasayahan na Layunin",
              relationshipGoals: "Aking Mga Layunin sa Relasyon",
              travelGoals: "Aking Mga Layunin sa Paglalakbay",
            },
            cardDescriptions: {
              likeAdmire: "Narito ang mga bagay na gusto ko at hinahangaan tungkol sa aking sarili at kung ano ang pinahahalagahan ng aking mga kontribyutor tungkol sa akin",
              people: "Narito ang mga pinakamahalagang tao sa aking buhay.",
              milestones: "Tuklasin ang lahat ng mahahalagang milestone sa aking buhay na pinakapinagmamalaki ko!",
              favoriteThings: "Ilan lang ito sa mga paborito kong bagay",
              thingsIDo: "Narito ang ilan sa mga bagay na gusto kong gawin para masaya!",
              travelPlan: "Alamin kung saan ko gustong maglakbay, kung kanino, at kung ano ang gusto kong kasama sa paglalakbay",
              dailyRoutines: "Tuklasin kung ano ang mahalaga sa akin sa bawat araw at kung ano ang ginagawang isang magandang araw!",
              weeklyRoutines: "Alamin ang higit pa tungkol sa aking mga kagustuhan, ritwal, at gawain!",
              happeningHome: "Mahalaga para sa akin na ibahagi ang aking kasalukuyang sitwasyon sa pamumuhay, kung ano ang gusto at hindi ko gusto tungkol dito.",
              happeningWork: "Alamin ang higit pa tungkol sa aking mga nakaraang karanasan sa trabaho, kung ano ang nagustuhan at hindi ko nagustuhan tungkol dito!",
              happeningSchool: "Alamin ang kaunti pa tungkol sa akin at sa aking karanasan sa paaralan!",
              happeningHealth: "Narito ang kumportable kong ibahagi ang tungkol sa aking kalusugan, kaligtasan, at kung ano ang magagawa ng iba para suportahan ako.",
              homeGoals: "Alamin ang higit pa tungkol sa kung saan ko gustong tumira",
              workGoals: "Alamin ang tungkol sa pangarap kong trabaho",
              healthGoals: "Alamin kung paano ko gustong magplano para sa aking kalusugan",
              funGoals: "Alamin kung ano ang gusto kong gawin para masaya sa hinaharap",
              relationshipGoals: "Matuto pa tungkol sa kung paano ko gustong kumonekta sa iba",
              travelGoals: "Alamin kung saan ako umaasa na maglakbay",
            },
            noContent: {
              milestones: "Walang mga Milestone na naidagdag. I-click ang button sa ibaba para makapagsimula!",
              favoriteThings: "",
              thingsIDo: "Walang Nalikhang Mga Bagay na Ginagawa Ko Para sa Kasayahan. I-click ang button sa ibaba upang lumikha ng isa!",
              travelPlan: "Walang mga Lugar na Paglalakbay ang nalikha. I-click ang button sa ibaba upang lumikha ng isa!",
              dailyRoutines: "Walang idinagdag na Pang-araw-araw na Routine. I-click ang button sa ibaba para makapagsimula!",
              weeklyRoutines: "Walang naidagdag na Lingguhang Routine. I-click ang button sa ibaba para makapagsimula!",
              happeningHome: "Walang naitalang impormasyon sa Living in My Home. I-click ang button sa ibaba para makapagsimula!",
              happeningWork: "Walang impormasyon sa Karanasan sa Trabaho ang naitala. I-click ang button sa ibaba para makapagsimula!",
              happeningSchool: "Walang naitala na impormasyon sa Paaralan. I-click ang button sa ibaba para makapagsimula!",
              happeningHealth: "Walang impormasyon sa Kalusugan at Kaligtasan ang naitala. I-click ang button sa ibaba para makapagsimula!",
              homeGoals: "Walang mga layunin sa Pabahay ang nalikha. I-click ang button sa ibaba upang lumikha ng isa!",
              workGoals: "Walang nagawang layunin sa Trabaho. I-click ang button sa ibaba upang lumikha ng isa!",
              healthGoals: "Walang nagawang layunin sa Kalusugan. I-click ang button sa ibaba upang lumikha ng isa!",
              funGoals: "Walang nagawang mga layunin sa Kasayahan. I-click ang button sa ibaba upang lumikha ng isa!",
              relationshipGoals: "Walang nalikhang Mga Layunin sa Pakikipag-ugnayan. I-click ang button sa ibaba upang lumikha ng isa!",
              travelGoals: "Walang nagawang layunin sa Paglalakbay. I-click ang button sa ibaba upang lumikha ng isa!",
            },
          },

          contributorGeneric: {
            entries: "Mga Entri ng Contributor",
            noAccess: "Wala kang access sa seksyong ito",
            includedByPlanOwner: 'Kasama sa may -ari ng plano',
            notIncluded: "Hindi kasama ng may-ari ng plano",
            sectionNotComplete: "Hindi pa nakumpleto ng May-ari ng Plano ang seksyong ito",
            thankYou:
              "Salamat sa iyong kontribusyon! Ang iyong mga karagdagan ay ipinadala sa may-ari ng plano para sa pag-apruba",
          },
          contributorComments: {
            addComment: "Magdagdag ng komento",
            allComments: "Lahat ng Komento",
            commentPrompt:
              "Mayroon ka bang anumang nais mong ikomento batay sa iyong nakikita? Mag-iwan sa kanila ng isang nakakatuwang komento o tala!",
            yourComment: "Ang iyong komento",
            approved: "Inaprubahang Komento",
            pendingApproval:
              "Salamat sa iyong kontribusyon! Ang iyong komento ay ipinadala sa may-ari ng plano para sa pag-apruba",
            pendingNoThankYou: "Ang iyong komento ay ipinadala sa may-ari ng plano para sa pag-apruba",
            comments: "Mga komento",
            toCheck: 'upang suriin',
            deleteConfirm: 'Sigurado ka bang nais mong alisin ang komentong ito?',
            deleteSuccess: "Ok, inalis na ang komento",
            notApprovedMessage: 'Ang iyong puna ay hindi naaprubahan ng may -ari ng plano. Maaari mong i-edit ang iyong puna at muling isumite ito, o alisin ang iyong puna.',
            notIncluded: 'Hindi kasama',
          },
          durationDropdown: {
            notSelected: "Hindi Napili",
            zeroMonths: "0 - 6 na Buwan",
            sixMonths: "6 - 12 Buwan",
            oneYear: "1 - 2 taon",
            twoYears: "2+ taon",
          },
          navigationButtons: {
            gtky: "Bumalik sa Pagkilala sa Iyo",
            thingsIDo: "Bumalik sa Mga Bagay na Ginagawa Ko",
            happening: "Balikan ang Nangyayari Sa Buhay Ko",
            planningFun: "Bumalik sa pagpaplano ng masaya",
            planningHealth: "Bumalik sa pagpaplano ng kalusugan",
            planningHome: "Bumalik sa pagpaplano sa bahay",
            planningRelationships: "Bumalik sa pagpaplano ng mga relasyon",
            planningTravel: "Bumalik sa pagpaplano ng paglalakbay",
            planningWork: "Bumalik sa pagpaplano ng trabaho",
          },
          generic: {
            add: "Idagdag",
            addAnother: "Magdagdag ng Isa pa",
            addComment: "Magdagdag ng komento",
            addNew: "Magdagdag ng bago",
            addNewEntry: "Magdagdag ng Bagong Entry",
            addYourOwn: "Idagdag ang Iyong Sariling",
            addedBy: 'Idinagdag ni',
            addressPrimary: "Address 1",
            addressSecondary: "Suite, Apt, Yunit #",
            alone: "Mag-isa",
            approve: "Aprubahan",
            back: "Bumalik",
            backToDashboard: "Bumalik sa dashboard ng plano",
            cancel: "Kanselahin",
            cancelled: "Kinansela",
            city: "Lungsod",
            clear: "Malinaw",
            country: "Bansa",
            comments: "Mga Komento ng Contributor",
            createdBy: "Ginawa ni",
            createdOn: "Nilikha noong",
            dailyWithCapital: "Araw-araw",
            date: "Petsa",
            delete: "Tanggalin",
            deleteGoalConfirm: "Sigurado ka bang gusto mong tanggalin ang layuning ito?",
            deleteGoalSuccess: "Ang iyong layunin ay matagumpay na natanggal",
            details: "Mga Detalye",
            disapprove: "Hindi aprubahan",
            dislike: "ayaw",
            edit: "I-edit",
            editGoal: "I-edit ang Layunin",
            email: "Email",
            emailWithCapitial: "E-Mail",
            error: 'Error',
            faceTime: "FaceTime",
            firstName: "Pangalan",
            addGeneralComment: "I-click upang magdagdag ng pangkalahatang komento",
            generalComments: "Pangkalahatang komento",
            generalComment: "I-click upang magdagdag ng pangkalahatang komento",
            goal: "Layunin",
            goToItem: "Pumunta sa Item",
            goToGoal: "Pumunta sa Layunin",
            goToSection: "Pumunta sa {{section}}",
            inPerson: "Sa personal",
            instructionalVideo: "Video ng pagtuturo",
            language: "Pagpipilian sa Wika",
            lastName: "Huling pangalan",
            like: "gusto",
            loading: "Naglo-load...",
            middleName: "Gitnang pangalan",
            name: "Pangalan",
            newAdditions: "Ang mga nag-ambag ay gumawa ng mga bagong karagdagan",
            no: "Hindi",
            noNotifications: "Walang Notification",
            noPeopleMessage: "Magdagdag ng mga kaibigan at pamilya sa iyong People In My Life para piliin sila dito!",
            noThanks: "Hindi, salamat",
            okay: "Sige",
            other: "Iba pa",
            pleaseExplain: "Pakipaliwanag",
            phoneNumber: "Numero ng telepono",
            remove: "Alisin",
            removeImage: "Alisin ang Larawan",
            review: "Pagsusuri",
            save: "I-save",
            saveAndSendUpdates: "I-save at Ipadala ang Mga Update",
            saveAndSendInvite: "I-save at Ipadala ang Imbitasyon",
            saveAnother: "I-save at Magdagdag ng Iba",
            saveNoUpdates: "Huwag Magpadala ng Mga Update",
            search: "Maghanap",
            selectAGoal: "Pumili ng layunin",
            state: "Estado",
            submit: "Ipasa",
            success: "Tagumpay",
            talking: "Nag-uusap",
            thankYou: 'Salamat',
            title: "Pamagat",
            uploadImage: "Mag-upload ng Larawan",
            viewComment: "Tingnan ang Komento",
            weeklyWithCapitial: "Lingguhan",
            with: 'kasama',
            withOthers: "Sa Iba",
            yes: "Oo",
            zip: "Zip Code",
            include: "Isama",
            notInclude: "Huwag Isama",
            approveAll: "Aprubahan Lahat",
            denyAll: "Tanggihan ang Lahat",
            removeFromPlan: "Alisin Mula sa Plano",
            said: "sabi",
            errors: {
              errorPageTitle: 'Nagkaroon ng isang error!,',
              errorPageSubTitle: 'Pasensya na. Mangyaring bumalik sa ibang pagkakataon.,',
              error404: 'Hindi Natagpuan ang 404 Page,',
              saveError: 'Paumanhin, mayroong isang error na nagse -save ng iyong pagpasok. Subukang muli mamaya.,',
              deleteError: 'Paumanhin, mayroong isang error na tinanggal ang iyong pagpasok. Subukang muli mamaya.,',
              createPlanError: 'Paumanhin, mayroong isang error sa paglikha ng iyong plano. Subukang muli mamaya.',              
            },
          },
        },
      },
      farsi: {
        translation: {
          settings: {
            pageTitle: "تنظیمات نمایه",
            subTitle: "محلی برای شما تا برنامه خود را مطابق میلتان سفارشی کنید!",
            saveSettings: "ذخیره تنظیمات",
            planVideo: "ویدیوی برنامه​ریزی من",
            contributorPage: "مدیریت مشارکت​کنندگان",
            colorPalette: "پالت رنگ من",
            changePassword: {
              changePassword: "تغییر رمز ورود",
              currentPassword: "رمز ورود فعلی",
              newPassword: "رمز ورود جدید",
              confirmPassword: "تأیید رمز ورود",
              currentIncorrect: "رمز ورود فعلی نادرست است",
              samePasswordError: "متأسفیم، لطفاً از رمز ورود جدیدی استفاده کنید",
            },
            colorPalettePage: {
              pageTitle: 'پالت رنگ',
              subTitle: 'هنگام ایجاد برنامه خود ، کدام رنگ ها را دوست دارید ببینید!',
              myColorPalette: 'پالت رنگ من',
              select: 'پالت رنگی را که دوست دارید انتخاب کنید',
              brainstormBlue: 'طوفان مغزی آبی',
              planningPurple: 'برنامه ریزی در بنفش',
              organizingOrange: 'سازماندهی نارنجی',
              gamePlanGreen: 'برنامه بازی سبز',
              primaryColorTooltip: 'متن ، دکمه ها و نمادها',
              secondaryColorTooltip: 'هدر ، دکمه های انتخاب شده ، میزها',
              contributorColorTooltip: 'ورودی های مشارکت کننده',
              textColorTooltip: 'متن ،',
              saveMessage: 'پالت رنگ شما به روز شده است!',
            },
            manageContributors: {
              sectionHeader: "مدیریت مشارکت​کنندگان و اعلان​های من",
              subHeader: "روی یک کارت مشارکت​کننده کلیک کنید تا مجوز آنها را تنظیم یا ویرایش کنید",
              myContributors: "مشارکت​کنندگان من",
              dialogConfirm: "همه چیز درست به نظر می​رسد؟",
              dialogTitle: "اطلاعات مشارکت​کننده",
              inviteSuccess: "دعوت​نامه​ای برای مشارکت​کننده شما ارسال شده است",
              updateSuccess: "اطلاعات مشارکت​کننده به​روز شده است و ایمیلی برای اطلاع دادن به مشارکت​کننده ارسال شده است",
              updateSuccessNoEmail: "اطلاعات مشارکت​کننده به​روز شده است!",
              lastContribution: "آخرین مشارکت:",
              noContributions: "هنوز هیچ مشارکتی وجود ندارد",
            },
            contributorNotifications: {
              myNotifications: "اعلان​های من",
              letsSetPreferece: "بیایید ترجیحات شما را تنظیم کنیم",
              howToNotify: "وقتی مشارکت​کننده​ای به برنامه شما موردی اضافه می​کند، می​خواهید چگونه به شما اطلاع داده شود؟",
              howOften: "دوست دارید چند وقت یکبار به شما اطلاع داده شود؟",
              saveAlert: "تنظیمات اعلان شما به​روز شده است",
            },
            managementPage: {
              sectionHeader: "مشارکت​کنندگان من",
              noContributorsContext:
                "You می​توانید مجوز بخش​های مختلف برنامه خود را به مشارکت​کنندگان بدهید. سپس، آنها می​توانند نظرات و اضافات دیگری را به آن بخش​ها بیفزایند!",
            },
            profile: {
              pageTitle: "نام و جزئیات",
              sectionHeader: "به Center on Me خوش آمدید",
              subHeader: "لطفاً چند لحظه وقت بگذارید و کمی در مورد خودتان به ما بگویید",
              profilePicture: 'عکس مشخصه',
              name: "نام",
              languageAndNumber: "زبان و شماره تلفن",
              address: "نشانی",
              firstNameError: "نام الزامی است",
              lastNameError: "نام خانوادگی الزامی است",
              phoneError: "شماره تلفن معتبر الزامی است",
              saveConfirmationMsg: "آیا این مورد درست به نظر می​رسد؟",
              backToSettings: "بازگشت به تنظیمات",
              backToPlans: "بازگشت به برنامه(های) من",
              saveMessage: "از اینکه اطلاعات خود را اضافه کردید سپاسگزاریم!",
              firstTimeSaveMessage: 'عالی است که شما را بشناسید! حال ، بیایید برنامه ریزی را شروع کنیم.',
              speechToText: 'گفتار به متن',
              speechToTextEnabled: 'بله ، من می خواهم از گفتار برای متن استفاده کنم!',
              speechToTextDisabled: 'نه ، من نمی خواهم از گفتار برای متن استفاده کنم',
              speechToTextWarning: 'در حال حاضر فقط به زبان انگلیسی موجود است',              
              languages: {
                english: "انگلیسی",
                armenian: "ارمنی",
                chineseSimplified: "چینی ساده​شده",
                chineseTraditional: "چینی سنتی",
                farsi: "فارسی",
                hmong: "همونگ",
                korean: "کره​ای",
                spanish: "اسپانیایی",
                tagalog: "تاگالوگ",
                vietnamese: "ویتنامی",
              },
            },
            account: {
              pageTitle: 'حساب',
              subTitle: 'مکان شما برای مدیریت اشتراک خود',
              benefitsTitle: 'مزایای اشتراک پرداخت شده',
              manageTitle: 'مدیریت را مدیریت کنید',
              benefits: {
                multiplePlans: 'چندین برنامه ایجاد کنید',
                fullAccess: 'دسترسی کامل به صفحات برنامه',
                printablePDF: 'خلاصه برنامه PDF قابل چاپ',
                uploadPhotos: 'بارگذاری عکس ها',
                planVideo: 'برای به اشتراک گذاشتن با مشارکت کنندگان خود یک ویدیوی برنامه ایجاد کنید',
                backgroundImage: 'یک تصویر پس زمینه سفارشی را انتخاب کنید',
                themeColor: 'رنگ تم پروفایل خود را انتخاب کنید',
              },
              currentPlan: 'نقشه جاری',
              trial: 'محاکمه 30 روزه',
              subscription: 'اشتراک 12 ماهه',
              noSubscription: 'اشتراک فعلی',
              expirationDate: 'تاریخ انقضا',
              expiresOn: 'اشتراک شما منقضی می شود',
              paymentMethod: 'روش پرداخت',
              supportContact: 'تماس پشتیبانی',
              supportContactIs: 'تماس پشتیبانی شما {{contactName}} است',
              editSupportContact: 'ویرایش تماس با پشتیبانی',
              supportContactLabel: 'تماس پشتیبانی',
              contactEmailLabel: 'با آدرس ایمیل تماس بگیرید',
              contactPhoneLabel: 'با شماره تلفن تماس بگیرید',
              saveSupportMessage: 'تماس پشتیبانی شما به روز شده است.',
              supportContactError: 'تماس پشتیبانی لازم است',
              contactEmailError: 'ایمیل پشتیبانی پشتیبانی لازم است',
              contactPhoneError: 'شماره تلفن تماس پشتیبانی لازم است',                        
              subscribeButton: 'اشتراک در',
              renewButton: 'اشتراک را تجدید کنید',
              requestRenewal: 'درخواست تجدید اشتراک خود را درخواست کنید',
              renewDialogTitle: 'اشتراک خود را تجدید کنید',
              selectedPlan: 'طرح انتخاب شده',
              billed: 'صورتی',
              payment: 'پرداخت',
              howItWorks: 'اشتراک شما چگونه کار می کند',
              billingInformation: 'اطلاعات صورت حساب',
              billingStartPlanning: 'به محض پذیرش پرداخت می توانید برنامه ریزی را شروع کنید',
              expirationExplanation: 'پس از انقضا اصطلاح اشتراک ، اشتراک به طور خودکار بدون هیچ گونه اخطار یا اقدامات مورد نیاز هر یک از طرفین به طور خودکار خاتمه می یابد. هیچ هزینه اضافی وجود نخواهد داشت ، و شما دیگر به مزایای مرتبط با اشتراک دسترسی نخواهید داشت مگر اینکه اشتراک جدیدی را خریداری کنید.',
              termsConditions: 'شرایط و ضوابط',
              trialToMemberMessage: 'به مرکز من خوش آمدید! اکنون به تمام مزایای یک مرکز در برنامه من دسترسی دارید.',
              memberRenewalMessage: 'برنامه شما 12 ماه دیگر تمدید شده است',
              contactEmail:  'با آدرس ایمیل تماس بگیرید',
              contactPhone: 'با شماره تلفن تماس بگیرید',
              contactSuccessMessage: 'تماس پشتیبانی شما به روز شده است',
              subscribeDialogTitle: 'مشترک شدن در مرکز من',
              trialExpireOn: 'دادگاه شما منقضی می شود',
              expiredOn: 'اشتراک شما منقضی شده است',
              paymentSuccessMessage: 'متشکرم! امیدواریم از مرکز من لذت ببرید',
              today: 'امروز',
              todayDescription: 'دسترسی کامل به مرکز من را باز کنید',
              tenMonths: '10 ماه',
              tenMonthDescription: 'یادآوری کنید که برنامه شما در حال انقضا است',
              twelveMonths: '12 ماه',
              twelveMonthDescription: 'اشتراک شما به پایان می رسد',
              showPaymentOptions: 'گزینه های پرداخت را نشان دهید',
            },
            backgroundImage: {
              pageTitle: 'تصویر پس زمینه',
              subTitle: 'یک تصویر پس زمینه را انتخاب کنید یا خود را بارگذاری کنید',
              hideGrid: 'پنهان کردن شبکه تصویر',
              showGrid: 'نمایش شبکه تصویر',
              selectImage: 'یک تصویر پس زمینه را انتخاب کنید ،',
              noImage: 'بدون تصویر،',
              newImageWarning: 'انتخاب تصویر جدید ، تصویر بارگذاری شده شما را حذف می کند. می خواهید ادامه دهید؟،',
              warningConfirm: 'بله ، من یک تصویر جدید می خواهم ،',
              warningCancel: 'نه ، تصویر من را نگه دارید ،',
              successMessage: 'شما تصویر پس زمینه به روز شده اید ،',
              uploadError: 'با عرض پوزش ، خطایی در به روزرسانی تصویر پس زمینه شما وجود داشت ،',
            },
            planVideoPage: {
              sectionHeader: "ویدیوی برنامه​ریزی من",
              subHeader: "ویدیویی برای معرفی برنامه خود به مشارکت​کنندگان ایجاد کنید",
              saveAlertText: "از اینکه ویدیوی برنامه خود را آپلود کردید سپاسگزاریم!",
              explainTextGreeting: "سلام {{firstName}},",
              explainTextP1: `
      This این فضا برای آپلود ویدیویی است که مشارکت​کنندگان شما
      see به محض ورود به Center On Me برای مشارکت در برنامه شما خواهند دید. 
      This این ویدیو فرصتی برای شماست تا توضیح دهید که چرا این برنامه را 
      plan ایجاد می​کنید و نمونه​ای به مشارکت​کنندگان ارائه می​دهد از آینده​ای که در تصور شما 
      for در ایجاد این برنامه وجود دارد.`,
              explainTextP2: `
      Record ویدیویی را در تلفن، رایانه یا تبلت خود ضبط و آن را 
      here. مدت ویدیوی شما باید کمتر از ده (10) دقیقه باشد. برای راهنمایی 
      on در مورد نحوه ساخت ویدیوی خود، لطفاً ویدیوی نمونه را تماشا کنید یا گزینه زیر را انتخاب کنید 
      "Tips "نکاتی برای ایجاد ویدیوی من".`,
              explainTextEnding: "سپاسگزاریم!",
              explainTextSignature: "Center on Me",
              tipsButton: "نکاتی برای ایجاد ویدیوی من",
              exampleVideoTitle: "نمونه ویدیو",
              alvaVideoTitle: "نمونه آلوا",
              uploadButton: "آپلود ویدیوی خود",
              removeButton: "حذف ویدیوی من",
              videoError: "مرورگر شما از برچسب ویدیو پشتیبانی نمی​کند.",
              lengthError: "خطا: حداکثر مدت مجاز ویدیو 10 دقیقه است",
              tips: {
                dialogTitle: "نکاتی برای ایجاد ویدیوی خود",
                tipsTitle: "نکاتی برای ضبط و آپلود ویدیوی خود:",
                tipOne: "ویدیوی خود را در شرایط نوری​ای ضبط کنید که تمام صورت شما دیده شود",
                tipTwo: "قبل از زدن دکمه ضبط، به آنچه می​خواهید بگویید فکر کنید",
                tipThree: "مدت ویدیو باید کمتر از ده (10) دقیقه باشد",
                tipFour: `اگر پس از آپلود ویدیوی خود با مشکلی در مشاهده آن مواجه شدید، لطفاً به centeronme@mainsl.com ایمیل بزنید 
      and و نام کاربری (نشانی ایمیل) و نام فایل ویدیویی​ای را که آپلود کرده​اید (مثلاً Myplanvideo.mov) ارائه دهید`,
                examplesTitle: "نمونه سوالاتی که باید در ویدیوی خود پاسخ دهید:",
                exampleOne: "چرا از مردم دعوت می​کنید تا در برنامه شما مشارکت کنند؟",
                exampleTwo: "امیدوارید با برنامه خود به چه دستاوردی برسید؟",
                exampleThree: "برای چه چیزی می​خواهید برنامه ارائه کنید؟",
                exampleFour: "چگونه مشارکت​کنندگان شما می​توانند در ایجاد برنامه از شما حمایت کنند؟",
              },
              contributor: {
                sectionHeader: "ویدیوی برنامه​ریزی آنها",
                subHeader: "",
                noVideo: "مالک طرح ویدیو طرح را اضافه نکرده است",
              },
            },
          },
          lockedFeatures: {
            photoLock: 'اشتراک مورد نیاز برای بارگذاری عکس',
            videoLock: 'اشتراک مورد نیاز برای بارگذاری فیلم ها',
            pdfLock: 'اشتراک مورد نیاز برای بارگیری PDF',
            sectionLock: 'برای دسترسی به این ویژگی یک گزینه اشتراک را انتخاب کنید.',
            sectionLockSaveMessage: 'این بخش توسط کاربران آزمایشی قابل اصلاح نیست',
            createPlanLock: 'برای ایجاد چندین برنامه یک گزینه اشتراک را انتخاب کنید',
            expiredLock: 'برای دسترسی به این ویژگی یک گزینه اشتراک را انتخاب کنید',
            expiredAlert:  'اشتراک شما منقضی شده است لطفاً برای مشاهده این بخش تجدید کنید.',            
          },
          allPlans: {
            iContributeTo: "برنامه هایی که من در آنها مشارکت دارم",
            myPlans: "برنامه​های من",
            newContributeTo: "مشارکت در برنامه​ای جدید",
            createNewPlan: "ایجاد یک برنامه جدید",
            planOwner: "صاحب برنامه",
            planCreated: "برنامه ایجاد شد",
            lastViewed: "آخرین مشاهده",
            planVideo: "ویدیوی برنامه",
            manageContributors: "مدیریت مشارکت​کنندگان و اعلان​ها",
            planSummary: "خلاصه برنامه",
            deletePlan: "حذف برنامه",
          },
          planLandingPage: {
            videoTitle: "به برنامه Center on Me خود خوش آمدید",
          },
          badges: {
            badgeSectionTitle: "نشان​هایی که به دست آورده​ام",
            badgesPlanningExplanation: `با تکمیل هر بخش، شروع به کسب نشان کنید! وقتی کار با بخش تمام شد، نشان شما در اینجا ظاهر می شود. 
      Let's بیایید در حین برنامه​ریزی برای آینده شما، کمی سرگرم شویم!`,
            badgesHappeningExplanation: `با تکمیل هر بخش، شروع به کسب نشان کنید! وقتی کار با بخش تمام شد، نشان شما در اینجا ظاهر می شود. 
      Let's بیایید در حین اطلاع از اتفاقات زندگی شما کمی سرگرم شویم!`,
            badgesGTKYExplanation: `با تکمیل هر بخش، شروع به کسب نشان کنید! وقتی کار با بخش تمام شد، نشان شما در اینجا ظاهر می شود. 
      Let's بیایید در حین آشنایی با شما، کمی سرگرم شویم!`,
          },
          sections: {
            likeAndAdmire: "پسند و تحسین",
            people: "افراد زندگی من",
            historyMap: "نقاط عطف",
            favorites: "علاقه​مندی​ها",
            travelPlan: "سفر",
            thingsIDo: "کارهایی که برای سرگرمی انجام می​دهم",
            dailyRoutines: "روال​های روزمره",
            weeklyRoutines: "روال​های هفتگی",
            routines: "روال​ها",
            happeningHome: "اتفاقات خانه",
            happeningWork: "اتفاقات کاری",
            happeningSchool: "اتفاقات مدرسه",
            happeningHealth: "اتفاقات سلامتی",
            planningHome: "برنامه​ریزی خانه",
            planningWork: "برنامه​ریزی کاری",
            planningTravel: "برنامه​ریزی سفر",
            planningFun: "برنامه​ریزی تفریح",
            planningHealth: "برنامه​ریزی سلامت",
            planningRelationships: "برنامه​ریزی روابط",
          },
          planningPage: {
            pageTitle: "بیایید برنامه​ریزی را شروع کنیم",
            planningVideo: "ویدیوی برنامه​ریزی",
            housing: "مسکن",
            work: "کار",
            health: "سلامتی",
            fun: "سرگرمی",
            relationships: "روابط",
            travel: "سفر",
          },
          planningHealth: {
            sectionName: "برنامه​ریزی سلامت",
            sectionHeader: "اهداف سلامت",
            subHeader: "بیایید برای آینده​ای سالم برنامه​ای ایجاد کنیم",
            goalTitle: "دوست دارید چه عنوانی برای این هدف انتخاب کنید؟",
            goalAroundHealth: "هدف شما در مورد سلامت چیست؟",
            workedOnThisGoal: "قبلاً برای تحقق این هدف تلاش کرده​اید؟",
            whatWorked: "در تلاش برای تحقق این هدف، چه عاملی نتیجه​بخش بوده است؟",
            didntWork: "در تلاش برای تحقق این هدف، چه عاملی نتیجه​بخش نبوده است؟",
            healthTableHeader: "این هدف را در نظر بگیرید، فکر می​کنید چه چیزی باید...",
            doYouHaveSupport: "آیا کسی در زندگی​تان وجود دارد که در تحقق این هدف از شما حمایت کند؟",
            attachedPeopleMessage: "لطفاً انتخاب کنید چه کسی می​تواند از شما حمایت کند",
            anyOneElseWhoCanSupport: "آیا شخص دیگری وجود دارد که بتواند به شما در رسیدن به هدفتان کمک کند و چرا؟",
            goalCommit: "برای کمک به خلق این آینده، چه تعهداتی می​توانید بدهید؟",
            increase: "افزایش",
            decrease: "کاهش",
            doMore: "بیشتر انجام دادن",
            doLess: "کمتر انجام دادن",
            increaseEx: `مثال چه مدت زمانی روی خودم تمرکز می​کنم`,
            decreaseEx: `مثال چقدر در فیس​بوک وقت می​گذرانم`,
            doMoreEx: ` مثال زمانی که صرف نفس کشیدن می​کنم`,
            doLessEx: ` مثال خوردن غذاهای شیرین`,
            saveMessage: "عالی است، در رسیدن به این هدف موفق باشید!  مایل به افزودن هدف سلامتی دیگری هستید؟",
            deleteMessage: `از حذف این ردیف جدول مطمئن هستید؟ `,
            deleteConfirm: "بسیار خوب، ردیف حذف شده است",
            summary: {
              goalTitle: "هدف من حول محور سلامتی",
              whatWorked: "زمانی که قبلاً برای رسیدن به این هدف تلاش می​کردم، این موارد مؤثر بود",
              didntWork: "زمانی که قبلاً برای رسیدن به این هدف تلاش می​کردم، این موارد مؤثر نبود",
              healthTableHeader: "با در نظر گرفتن هدفم، فکر می​کنم می​توانم کارهای زیر را برای کمک به خودم انجام دهم",
              doYouHaveSupport:
                "افرادی که در حلقه حمایتی من هستند و می​توانند در رسیدن به این هدف به من کمک کنند عبارت​اند از:",
              anyOneElseWhoCanSupport: "افراد دیگری که فکر می​کنم می​توانند کمکم کنند عبارت​اند از:",
              goalCommit: "می​توانم متعهد به انجام دادن این کارها باشم:",
            },
          },
          planningRelationships: {
            sectionName: "برنامه​ریزی روابط",
            sectionHeader: "اهداف مربوط به رابطه",
            subHeader: "بیایید ایجاد ارتباط با دیگران را شروع کنیم!",
            newOrStrengthen: "می​خواهید برای رابطه جدیدی برنامه​ریزی کنید یا مایلید یکی از روابط فعلی را تقویت کنید؟",
            saveMessage: "هدفی عالی برای رابطه است! می​خواهید هدف دیگری را اضافه کنید؟",
            strengthen: {
              goal: "رابطه خود با چه کسی را می​خواهید تقویت کنید؟",
              currentStatus: "وضعیت فعلی این رابطه چگونه است؟",
              romantic: "عاشقانه",
              friend: "دوستانه",
              professional: "کاری",
              family: "خانوادگی",
              whatToStrengthen: "در مورد این رابطه چه چیزی را می​خواهید تقویت کنید؟",
              frequency: "دفعات تعامل",
              communication: "برقراری ارتباط",
              desiredStatus: "وضعیت مطلوب",
              howOften: "در حال حاضر، چند وقت یک​بار با این شخص تعامل دارید؟",
              howToStrengthen: "چگونه می​خواهید تعداد دفعات تعامل را تقویت کنید",
              strengthenCommunication: "در مورد برقراری ارتباط چه چیزی را می​خواهید تقویت کنید؟",
              commExample1: "مثال: سعی کردم بیشتر با آنها تماس بگیرم",
              commExample2: "مثال: هر وقت می​خواستم می​توانستم با آنها صحبت کنم",
              commExample3: "مثال: احساس کردم حرف​هایم شنیده می​شود و ارتباط برقرار کرده​ام",
              commExample4: "مثال: آنها همیشه جواب نمی​دهند",
              commTableHeader1: "در هنگام تقویت ارتباطات خود، چه چیزی را امتحان کرده​اید؟",
              statusTableHeader1: "برای تغییر این وضعیت این رابطه چه تلاشی کرده​اید؟",
              tableHeader2: "وقتی آن را امتحان کردید، چه چیزی یاد گرفتید؟",
              tableHeader3: "وقتی آن را امتحان کردید، از چه چیزی راضی بودید؟",
              tableHeader4: "درباره چه چیزی نگران هستید؟",
              commDoNext: "به مواردی که ذکر کرده​اید نگاه کنید، اقدام بعدی​تان چه می​تواند باشد؟",
              preferredStatus: "دوست دارید وضعیت این شخص چگونه باشد؟",
              theyAreCurrently: "او در حال حاضر:",
              statusExample1: "مثال: تلاش کردم او را به قرار آشنایی دعوت کنم",
              statusExample2: "مثال: او بسیار مهربان است و از صحبت کردن با او لذت می​برم.",
              statusExample3: "مثال: گفت: بله!",
              statusExample4: "مثال: از آن زمان با او تماس نگرفته​ام",
              finalDoNext: "با نگاه کردن به مواردی که ذکر کرده​اید، اقدام بعدی​تان چه می​تواند باشد؟",
              deleteRowConfirm: "از حذف این ردیف جدول مطمئن هستید؟",
              deleteRowSuccess: "بسیار خوب، ردیف حذف شده است",
            },
            new: {
              relationshipType: "به دنبال چه نوع رابطه​ای هستید؟",
              characteristics: "به دنبال چه ویژگی​هایی در شخصی هستید که می​خواهید با او ارتباط برقرار کنید؟",
              where: "کجا ممکن است این شخص را ملاقات کنید؟",
              placeOfWorship: "عبادت​گاه​ها",
              family: "خانوادگی",
              friend: "دوستانه",
              professional: "کاری",
              romantic: "عاشقانه",
              sharedInterests: "از طریق علایق مشترک",
              online: "آنلاین",
              school: "مدرسه",
              shops: "فروشگاه​ها",
              work: "کار",
              whenYouThinkAbout: "وقتی به ایجاد ارتباط جدیدی فکر می​کنید",
              atChurch: "در عبادت​گاه",
              throughFriend: "از طریق یک دوست",
              throughInterests: "از طریق علایق مشترک",
              atOnline: "آنلاین",
              atSchool: "در مدرسه",
              atLocalShop: "در فروشگاه محلی",
              atWork: "در محل کار",
              likeToStart: "دوست دارید از کجا شروع کنید؟",
              howOften: "چند وقت یکبار می​خواهید از این طریق ارتباط برقرار کنید؟",
            },
            summary: {
              relationshipType: "نوع رابطه​ای که می​خواهم ایجاد کنم عبارت است از:",
              characteristics: "می​خواهم این شخص دارای ویژگی​های زیر باشد",
              where: "می​توانم این شخص را ملاقات کنم",
              whereToStart: "از کجا می​خواهم شروع کنم",
              howOften: "چند وقت یکبار",
              comments: "نظرات",
              strengthenGoal: "کسی که می​خواهم رابطه​ام را با او تقویت کنم:",
              currentStatus: "وضعیت فعلی رابطه ما:",
              whatToStrengthen: "موردی که می​خواهم تقویت کنم",
              strengthenBy: "می​خواهم توسط... تقویت کنم",
              strengthenWhy: "چرا؟",
              whyAnswer: "این کار به من کمک می​کند به هدفم برسم",
              becoming: "در حال تبدیل شدن به",
            },
          },
          planningHome: {
            sectionName: "برنامه​ریزی خانه",
            sectionHeader: "اهداف زندگی",
            subHeader: "بیایید ببینیم در مورد وضعیت فعلی زندگی خود چه چیزی را دوست دارید و چه چیزی را دوست ندارید",
            wantToLive: "کجا می​خواهید زندگی کنید؟",
            idealHome: "خانه ایده آل از نظر شما چگونه است؟",
            homeAmenities: "می​خواهید خانه​تان چه امکاناتی داشته باشد؟",
            homeCloseTo: "دوست دارید خانه​تان در نزدیکی چه چیزی باشد؟",
            liveWith: "آیا کسی هست که بخواهید با او زندگی کنید؟",
            roommateName: "چه کسی؟",
            roommateQuality: "به نظر شما چرا زندگی با این شخص مناسب است؟",
            askedRoommate: "آیا در مورد زندگی مشترک از او پرسیده​اید؟",
            liveWithPets: "آیا حیوان خانگی در آینده زندگی شما جایی دارد؟",
            petType: "چه نوع حیوانی؟",
            finalThoughts: "آیا موضوع دیگری درباره خانه ایده​آل خود دوست دارید که در مورد آن صحبت نکرده باشیم؟",
            homeOptions: {
              singleStory: "یک​طبقه",
              apartment: "آپارتمان",
              duplex: "دوبلکس",
              mobileHome: "خانه سیار",
              tinyHome: "خانه نقلی",
            },
            amenities: {
              laundry: "لباسشویی",
              internet: "اینترنت بی​سیم",
              recreation: "مرکز تفریحی",
              garden: "باغ",
              deck: "ایوان",
              patio: "پاسیو",
              land: "زمین",
              ramp: "سطح شیب​دار",
              pool: "استخر",
              bigDoors: "درهای بزرگ",
            },
            proximities: {
              shopping: "فروشگاه",
              bus: "ایستگاه اتوبوس",
              friends: "دوستان",
              restaurants: "رستوران​ها",
              entertainment: "مراکز سرگرمی",
              publicTransit: "حمل​ونقل عمومی",
              family: "خانوادگی",
            },
            summary: {
              wantToLive: "جایی که می​خواهم در آن زندگی کنم",
              wantToLiveAnswer: "{{place}} در {{homeType}}",
              homeAmenities: "در خانه ایده​آلم این موارد را می​خواهم:",
              otherAmenities: "چیزهای دیگر در خانه ایده آل من",
              homeCloseTo: "می​خواهم خانه​ام در نزدیکی این موارد باشد",
              roommateName: "می​خواهم با این شخص زندگی کنم:",
              liveAlone: "می​خواهم تنها زندگی کنم",
              roommateBecause: "زیرا {{why}}",
              roommateQuality: "به نظر شما چرا زندگی با این شخص مناسب است؟",
              petType: "حیوانات خانگی",
              finalThoughts: "چیزهای دیگر در خانه ایده آل من",
            },
          },
          planningFun: {
            sectionName: "برنامه​ریزی تفریح",
            sectionHeader: "اهداف سرگرمی",
            subHeader: "بیایید کمی خوش بگذرانیم!",
            goal: "با در نظر گرفتن موضوع برنامه​ریزی برای کارهای سرگرم​کننده، چه کاری می​خواهید انجام دهید؟",
            goalWhy: "چه چیزی باعث می​شود این کار برای شما جذاب باشد؟  یا چرا می​خواهید این کار را انجام دهید؟",
            goalWhen: "چه زمانی می​خواهید این کار را انجام دهید؟",
            goalHowOften: "چند وقت یکبار دوست دارید این کار را انجام دهید؟",
            otherPeople: "آیا کسی هست که بخواهید این کار را با او انجام دهید؟",
            otherPeopleWhoCanSupport: "لطفاً انتخاب کنید چه کسی می​تواند از شما حمایت کند",
            otherPeopleWhereToFind: `Please لطفاً گروه​ها یا مکان​هایی را انتخاب کنید که می​توانند در برقراری ارتباط با افرادی که علایق مشترک با شما دارند به شما کمک کنند`,
            needToSave: "آیا برای انجام دادن این کار باید پول پس​انداز کنید؟",
            startedSaving: "آیا پس​انداز کردن را شروع کرده​اید؟",
            amountToSave: "چقدر پول هنوز باید پس​انداز کنید؟",
            attachedPeople: "رشته",
            saveMessage: "به نظر جالب است!  می​خواهید هدف سرگرم​کننده دیگری را اضافه کنید؟",
            asap: "در اسرع وقت",
            sixMonths: "شش ماه آینده",
            oneYear: "شش ماه تا یک سال",
            twoYears: "1 تا 2 سال",
            twoPlusYears: "بیش از 2 سال",
            oneTime: "رویداد یک​باره",
            daily: "روزانه",
            weekly: "هفتگی",
            monthly: "ماهانه",
            quarterly: "سه​ماه یک بار",
            facebook: "فیس​بوک",
            groupMeet: "دیدار گروهی",
            newspaper: "روزنامه محلی",
            coffeeShop: `تابلوی اعلانات کافی​شاپ`,
            communityCenter: "مرکز اجتماع",
            summary: {
              goal: "با در نظر گرفتن موضوع برنامه​ریزی برای کارهای سرگرم​کننده، می​خواهم این کارها را انجام دهم",
              goalWhy: "برایم جذاب است، زیرا",
              goalWhen: "دوست دارم این کار را انجام دهم",
              goalHowOften: "و به این تعداد دفعات آن را انجام دهم:",
              otherPeople: "می​خواهم این کار را با این شخص انجام دهم:",
              peopleInMyLife: "افراد زندگی من که می​خواهم با آنها این کار را انجام دهم:",
              actionSteps: "مراحل اقدام:",
              comments: "نظرات",
              helpMeAchieve: "این کار به من کمک می​کند به هدفم برسم",
              research: "راه​های تحقیق جهت برنامه​ریزی برای این فعالیت از طریق",
              needToSave: "آیا باید برای این سفر پس​انداز کنم؟",
              needToSaveYes: "بله، باید {{amount}} پس​انداز کنم",
            },
          },
          planningTravel: {
            sectionName: "برنامه​ریزی سفر",
            subHeader: "بیایید برنامه​ریزی برای ماجراجویی بعدی​تان را شروع کنیم",
            destination: "دوست دارید​ به کجا سفر کنید؟",
            travelTo: "آیا قبلاً به {{location}} سفر کرده​اید؟",
            like: "درباره {{location}} چه چیزی را دوست داشتید",
            appealingWhy: "چه چیزی {{location}} را برای شما جذاب می​کند؟",
            travelWhen: "چه زمانی می​خواهید به {{location}} سفر کنید؟",
            withOthers: "آیا کسی هست که بخواهید با او سفر کنید؟",
            travelWithWho: "لطفاً انتخاب کنید با چه کسی می​خواهید سفر کنید",
            toDoAtDestination: "در حین سفر چه کاری می​خواهید انجام دهید؟",
            toSave: "آیا برای انجام دادن این کار باید پول پس​انداز کنید؟",
            startedToSave: "آیا پس​انداز کردن را شروع کرده​اید؟",
            amountToSave: "چقدر پول هنوز باید پس​انداز کنید؟",
            accommodations: "آیا هنگام سفر نیاز به امکاناتی دارید؟",
            needsAndWhy:
              "آیا چیزهایی وجود دارد که می​خواهید یا لازم است هنگام سفر همراه داشته باشید؟  در صورت وجود، چه چیزی؟",
            goal: "می​خواهید چه نامی برای این هدف بگذارید؟",
            saveMessage: "ماجراجویی در انتظار شماست! آیا می​خواهید هدف سفر دیگری را اضافه کنید؟",
            summary: {
              destination: "می​خواهم به این مکان سفر کنم:",
              appealingWhy: "چرا می​خواهم به اینجا سفر کنم",
              travelWhen: "می​خواهم به اینجا سفر کنم",
              withOthers: "می​خواهم همراه دیگران سفر کنم",
              withOthersYes: "بله، می​خواهم با {{people}} سفر کنم",
              withOthersAlone: "می​خواهم تنهایی سفر کنم",
              toDoAtDestination: "هنگامی که در سفر هستم، می​خواهم:",
              accommodations: "امکاناتی که در سفر به آنها نیاز خواهم داشت شامل این موارد است:",
              needsAndWhy: "چیزهای دیگری که می​خواهم در سفر همراه داشته باشم شامل این موارد است:",
              needToSave: "آیا باید برای این سفر پس​انداز کنم؟",
              needToSaveYes: "بله، باید {{amount}} پس​انداز کنم",
            },
          },
          planningWork: {
            sectionName: "برنامه​ریزی کاری",
            sectionHeader: "اهداف کاری",
            addSubHeader: "بیایید برای آینده​ای جدید برنامه​ریزی کنیم",
            whatILiked: "چیزی که دوست داشتم",
            whatIDisliked: "چیزی که دوست نداشتم",
            formSubHeader: "بیایید ببینیم در مورد تجربه کاری خود چه چیزی را دوست داشتید و چه چیزی را دوست نداشتید",
            workGoal: "هدف کاری شما چیست؟",
            profession: "چه چیزی شما را جذب این کار می​کند؟",
            excites: "چه چیزی شما را بیشتر در مورد این مسیر کاری هیجان​زده می​کند؟",
            goalLookLike: "وقتی در مورد هدفتان فکر می​کنید، چگونه به نظر می​رسد؟",
            salary: "دوست دارید در سال چقدر درآمد داشته باشید؟",
            preferredWorkDays: "روزهای کاری ترجیحی شما چیست؟",
            preferredWorkHours: "ساعات کاری ترجیحی شما چیست؟",
            haveNow: "اکنون چه چیزی دارید که شما را به آینده مدنظرتان می​رساند؟",
            priorExperience: "چه تجربه​ای پشتیبان شما برای رسیدن به آینده مدنظرتان است؟",
            haveReference: "آیا کسی را در این حرفه می​شناسید؟",
            referenceName: "او چه کسی است؟",
            referenceWhere: "کجا کار می​کند؟",
            inLifeSupport: "چه کسی در زندگی​تان می​تواند حامی شما در راه رسیدن به این هدف باشد؟",
            support: "آیا شخص دیگری وجود دارد که بتواند به شما در رسیدن به هدفتان کمک کند؟",
            commit: "برای کمک به خلق این آینده، چه تعهداتی می​توانید بدهید؟",
            placeholderBudgetNeed: "آنچه را که نیاز به بودجه​بندی دارد وارد کنید",
            placeholderResponsible: "نام را وارد کنید",
            placeholderHowOften: "تعداد دفعات انجام شدن آن را وارد کنید",
            placeholderImportant: "علت اهمیت آن را وارد کنید",
            monday: "دوشنبه",
            tuesday: "سه​شنبه",
            wednesday: "چهارشنبه",
            thursday: "پنجشنبه",
            friday: "جمعه",
            saturday: "شنبه",
            sunday: "یکشنبه",
            morning: "صبح",
            afternoon: "بعدازظهر",
            evening: "عصر",
            overnight: "شب",
            anytime: "هر زمان",
            saveMessage: "به نظر می​رسد یک هدف کاری عالی است! می​خواهید هدف کاری دیگری را اضافه کنید؟",
            budgetTable: {
              title: "برای چه چیزی باید پول پس​انداز کنید تا به شما کمک کند به هدفتان برسید؟",
              actionStep: "مرحله اقدام",
              saveFor: "پس​انداز پول برای",
              saveMoneyFor: "برای چه چیزی باید پول پس​انداز کنم؟",
              responsible: "چه کسی مسئول است؟",
              howOften: "چند وقت یک​بار؟",
              important: "چرا این موضوع مهم است؟",
              why: "چرا؟",
              contact: "تماس",
              me: "من",
              theyCanSupport: "آنها می​توانند در رسیدن به هدفم حمایتم کنند",
              thisWillHelpMe: "این کار به من کمک می​کند به هدفم برسم",
              deleteConfirm: "آیا مطمئن هستید که می​خواهید این ردیف بودجه را حذف کنید؟",
              deleteSuccess: "بسیار خوب، ردیف حذف شده است",
            },
            summary: {
              workGoal: "هدف من برای کار",
              profession: "چه چیزی من را جذب این کار می​کند؟",
              excites: "چیزی که من را بیشتر از همه در این مورد هیجان زده می​کند",
              goalLookLike: "وقتی به هدفم فکر می​کنم، ... به نظر می​رسد",
              haveNow: "چیزی که به من کمک می​کند تا به آینده مدنظرم برسم",
              preferredWorkDays: "روزهای کاری ترجیحی",
              preferredWorkHours: "ساعات کاری ترجیحی",
              salary: "حقوق مطلوب",
              haveReference: "آیا کسی را در این حرفه می​شناسید؟",
              referenceName: "او چه کسی است؟",
              referenceWhere: "کجا کار می​کند؟",
              inLifeSupport: "چه کسی در زندگی​تان می​تواند حامی شما در راه رسیدن به این هدف باشد؟",
              support: "آیا شخص دیگری وجود دارد که بتواند به شما در رسیدن به هدفتان کمک کند؟",
              commit: "برای کمک به خلق این آینده، چه تعهداتی می​توانید بدهید؟",
            },
          },
          GTKYPage: {
            pageTitle: "آشنایی با شما",
            GTKYVideo: "ویدیوی آشنایی با شما",
            likeAdmire: "پسند و تحسین",
            peopleInMyLife: "افراد زندگی من",
            thingsIDo: "کارهایی که دوست دارم برای سرگرمی انجام دهم",
            favoriteThings: "علاقه​مندی​های من",
            myRoutines: "روال​های من",
            historyMap: "نقشه پیشینه",
            travel: "سفر",
          },
          myRoutines: {
            sectionName: "روال​های من",
            subHeader: "همه چیز را درباره چگونگی روال​های همیشگی​تان به ما بگویید",
            videoTitle: "روال​های من - ویدیوی مفید",
            successMessage: "سپاسگزاریم!  روال​های شما اضافه شده است",
            dailyRoutines: "روال​های روزانه شما چیست؟",
            weeklyRoutines: "روال​ها یا تشریفات هفتگی شما چیست؟",
            dailyPlaceholder: "کاری را که هر روز انجام می​دهید و زمان انجام دادن آن را اضافه کنید",
            weeklyPlaceholder: "کاری را که هر هفته انجام می​دهید اضافه کنید",
            dayStickyTitle: "روز من",
            weekStickyTitle: "هفته من",
            contributor: {
              subHeader: "همه چیز را درباره چگونگی روال​های همیشگی او به ما بگویید",
              dailyRoutines: "روال​های روزانه او چیست؟",
              weeklyRoutines: "روال​ها یا تشریفات هفتگی او چیست؟",
              dailyPlaceholder: "کاری را که هر روز انجام می​دهد و زمان انجام دادن آن را اضافه کنید",
              weeklyPlaceholder: "کاری را که هر هفته انجام می​دهد اضافه کنید",
              dayStickyTitle: "روز {{name}}",
              weekStickyTitle: "هفته {{name}}",
            },
          },
          thingsIDo: {
            sectionName: "کارهایی که دوست دارم برای سرگرمی انجام دهم",
            subHeader: "فعالیت خود را انتخاب کنید یا فعالیت جدیدی ایجاد کنید",
            frequencyCard: "من این کار را دوست دارم:",
            likeMostCard: "چیزی که در مورد این فعالیت بیشتر دوست دارم:",
            tellUsWhatYouDo: "در مورد همه کارهایی که دوست دارید برای سرگرمی انجام دهید به ما بگویید!",
            indoor: "فضای بسته",
            outdoor: "فضای باز",
            community: "در جامعه من",
            whereToHaveFun: "کجا دوست دارید تفریح کنید؟",
            yourActivity: "دوست دارید چه کاری انجام دهید؟",
            chosenActivity: "فعالیتی که انتخاب کردید این بود:",
            howOften: "چند وقت یک بار دوست دارید این کار را انجام دهید؟",
            aloneOrWithOthers: "دوست دارید این فعالیت را به تنهایی انجام دهید یا با دیگران؟",
            attachedPeople: "دوست دارید با چه کسی این کار را انجام دهید؟",
            addThingIDo: "افزودن فعالیتی دیگر",
            otherPeopleInvolved: "آیا شخص دیگری وجود دارد که بخواهید این فعالیت را با او انجام دهید؟",
            anyPhotos: "آیا عکس جالبی از {{activity}} دارید که بخواهید به اشتراک بگذارید؟",
            like: "چه چیزی را در مورد {{activity}} بیشتر دوست دارید؟",
            youSelected: "فعالیت​های شما:",
            saveMessage: "چه ماجراجویی جالبی!  می​خواهید مورد دیگری را اضافه کنید؟",
            deleteConfirm: "از حذف این فعالیت مطمئن هستید؟",
            deleteSuccess: "فعالیت شما با موفقیت حذف شده است",
            daily: "روزانه",
            weekly: "هفتگی",
            monthly: "ماهانه",
            oncePerYear: "یک​بار در سال",
            fewTimes: "چند بار در سال",
            whenever: "هر وقت بتوانم",
            contributorQuestions: {
              frequencyCard: "آنها انجام دادن این کار را دوست دارند:",
              likeMostCard: "چیزی که آنها در مورد این فعالیت بیشتر دوست دارند:",
              tellUsWhatYouDo: "در مورد همه کارهایی که دوست دارند برای سرگرمی انجام دهند به ما بگویید!",
              community: "در جامعه من",
              whereToHaveFun: "کجا دوست دارند تفریح کنند؟",
              yourActivity: "دوست دارند چه کاری انجام دهند؟",
              chosenActivity: "فعالیتی که انتخاب کردند این بود:",
              howOften: "چند وقت یک بار دوست دارند این کار را انجام دهند؟",
              aloneOrWithOthers: "دوست دارند این فعالیت را به تنهایی انجام دهند یا با دیگران؟",
              attachedPeople: "دوست دارند با چه کسی این کار را انجام دهند؟",
              otherPeopleInvolved: "آیا شخص دیگری وجود دارد که بخواهند این فعالیت را با او انجام دهند؟",
              anyPhotos: "آیا عکس جالبی از {{activity}} دارند که بخواهید به اشتراک بگذارید؟",
              like: "چه چیزی را در مورد {{activity}} بیشتر دوست دارند؟",
              youSelected: "فعالیت آنها:",
              saveMessage: "",
            },
            summary: {
              activity: "آنچه دوست دارم انجام دهم",
              howOften: "دوست دارم این فعالیت را انجام دهم",
              withWho: "دوست دارم این کار را با این شخص انجام دهم:",
              withWhoAlone: "این کار را انجام می​دهم",
              byMyself: "خودم",
              like: "چیزی که بیشتر دوست دارم این است:",
              buttonText: "رفتن به «کاری که دوست دارم برای سرگرمی انجام دهم»",
            },
          },
          likeAndAdmire: {
            sectionName: "پسند و تحسین",
            sectionTitle: "سر، قلب، دست",
            subTitle: "هدایای خود را به دسته دلخواه خود بکشید و رها کنید یا دسته خود را بسازید",
            videoTitle: "پسند و تحسین - ویدیوی مفید",
            filterAll: "همه چیزهایی که پسندیده و تحسین شده​اند (پیش​فرض)",
            filterOthers: "آنچه دیگران در مورد من پسندیده​اند و تحسین می​کنند",
            filterMine: "آنچه در مورد خودم پسندیده​ام و تحسین می​کنم",
            resetAll: "بازنشانی همه موارد",
            addNew: "افزودن کلمه جدید",
            saveMessage: "کارتان عالی بود {{firstName}}, شنیدن این همه نظر عالی در مورد شما فوق​العاده بود!",
            head: "سر",
            heart: "قلب",
            hand: "دست",
            headExplanation: "هدایای مرتبط با ذهن، هوش یا فکر;  هدایایی که می​دانی",
            heartExplanation: "هدایایی که احساس می​کنید به آنها مرتبط هستید یا علاقه دارید",
            handExplanation: "هدایایی که می​توانید به​صورت عملی یا ساختن به​صورت فیزیکی ارائه دهید",
            contributor: {
              subTitle: "هدایای آنها را به دسته دلخواه خود بکشید و رها کنید یا دسته خود را بسازید",
              filterOthers: "آنچه دیگران در مورد آنها پسندیده​اند و تحسین می​کنند",
              filterMine: "آنچه دیگران در مورد خود پسندیده​اند و تحسین می​کنند",
            },
            summary: {
              head: "سر",
              heart: "قلب",
              hand: "دست",
            },
          },
          peopleInMyLife: {
            sectionName: "افراد زندگی من",
            subHeader: "فردی را اضافه کنید یا روی نام آن فرد کلیک کنید تا جزئیات بیشتری درباره او اضافه کنید",
            adaptionDisclaimer: "انطباق نقشه رابطه",
            acquaintance: "آشنا",
            acquaintances: "آشنایان",
            add: "افزودن شخص",
            addAsContributor: "افزودن به​عنوان مشارکت​کننده برنامه من",
            close: "بستن",
            contributor: "مشارکت​کننده",
            contributorAdditions: "موارد افزوده​شده توسط مشارکت​کننده",
            coWorker: "همکار",
            coWorkers: "همکاران",
            deletePerson: "مطمئن هستید که می​خواهید این شخص را از افراد زندگی خود حذف کنید؟",
            edit: "ویرایش شخص",
            family: "خانوادگی",
            favoritePerson: "شخص محبوب ماه",
            friend: "دوستانه",
            friends: "دوستان",
            howClose: "چقدر این شخص به شما نزدیک است؟",
            importantWhy: "چرا این شخص برای شما مهم است",
            involvedInSupport: "دخیل در حمایت از من",
            mapPlace: "این مورد جایگاه آنها را در «نقشه افراد زندگی من» شما تعیین می​کند.",
            notClose: "نزدیک نیست",
            paidSupport: "آیا این شخص حمایت پولی کرده است؟",
            paidSupportShort: "حمایت پولی",
            personIs: "این شخص...",
            relationshipToYou: "نسبت با شما",
            removePerson: "حذف شخص",
            weAre: "ما...",
            saveMessage: "همه افراد زندگی شما ذخیره شده​اند، کارتان عالی بود.",
            contributorQuestions: {
              howClose: "آنها چقدر به این شخص نزدیک هستند؟",
              importantWhy: "چرا این شخص برای آنها مهم است",
              mapPlace: "این مورد جایگاه آنها را در «نقشه افراد زندگی من» تعیین می​کند.",
              relationshipToYou: "نسبت با آنها",
              theyAre: "آنها...",
              involvedInSupport: "دخیل در حمایت از آنها",
            },
            dialog: {
              nameError: "لطفاً نام را وارد کنید",
              personIsError: "لطفاً حالتی را انتخاب کنید",
              emailError: "باید نشانی ایمیلی را برای مشارکت​کنندگان بالقوه وارد کنید",
              emailValidError: "لطفاً نشانی ایمیل معتبری را وارد کنید",
            },
          },
          favoriteThings: {
            sectionName: "علاقه​مندی​های من",
            subHeader: "برای افزودن علاقه​مندی​های خود، ابتدا دسته​ای را انتخاب کنید",
            contributorSubHeader: "لطفاً دسته​ای را انتخاب کنید تا شروع به اضافه کردن علاقه​مندی​های آنها کنید",
            viewOnlySubHeader: "به علاقه​مندی​های آنها نگاهی بیندازید",
            categories: "دسته​ها",
            categoryList: "فهرست دسته​ها",
            myFavorites: "علاقه​مندی​های من",
            theirFavorites: "علاقه​مندی​های آنها",
            place: "مکان",
            placeToEat: "مکانی برای غذا خوردن",
            food: "غذا",
            movie: "فیلم",
            book: "کتاب",
            videoGame: "بازی ویدیویی",
            game: "بازی",
            sport: "ورزش",
            animal: "حیوان",
            music: "موسیقی",
            memory: "خاطره",
            other: "سایر",
            placeLabel: "محل مورد علاقه شما برای رفتن",
            placeToEatLabel: "محل مورد علاقه شما برای غذا خوردن",
            foodLabel: "غذای مورد علاقه شما",
            movieLabel: "فیلم مورد علاقه شما",
            bookLabel: "کتاب مورد علاقه شما",
            videoGameLabel: "بازی ویدیویی مورد علاقه شما",
            gameLabel: "بازی مورد علاقه شما",
            sportLabel: "ورزش یا تیم ورزشی مورد علاقه شما",
            animalLabel: "حیوان خانگی یا نوع حیوان مورد علاقه شما",
            musicLabel: "گروه، آهنگ یا ژانر مورد علاقه شما",
            memoryLabel: "یکی از خاطرات مورد علاقه شما",
            otherLabel: "هرگونه علاقه​مندی",
            chooseAView: "نمایی را انتخاب کنید",
            allThings: "همه علاقه​مندی​ها (پیش​فرض)",
            thingsIAdded: "علاقه​مندی​هایی که اضافه کرده​ام",
            thingsOthersAdded: "آنچه دیگران اضافه کرده​اند",
            treasureChestAlt: "صندوق گنج علاقه​مندی​ها",
            deleteConfirm: "آیا مطمئن هستید که می​خواهید این علاقه​مندی را حذف کنید؟",
            deleteSuccess: "علاقه​مندی حذف شده است",
            saveAlertText: "متشکرم {{name}}, آگاهی از علاقه​مندی​های شما فوق​العاده است!",
          },
          travelPlan: {
            sectionName: "سفر",
            sectionHeader: "سفر",
            subHeader: "مکان مورد علاقه خود برای سفر یا جایی را که واقعاً مشتاقید به آن بروید به اشتراک بگذارید",
            travelLocally: "سفر داخل ایالات متحده را دوست دارید یا سفر بین​المللی؟",
            local: "داخل ایالات متحده",
            international: "بین​المللی",
            frequencyCard: "من این کار را دوست دارم:",
            likeMostCard: "چیزی که در مورد {{place}} بیشتر دوست دارم:",
            pageSubHeader: "در مورد همه جاهایی که دوست دارید به آنها سفر کنید به ما بگویید",
            travelWhere: "دوست دارید به کجا سفر کنید؟",
            likeMost: "چه چیزی را در سفر به {{location}} بیشتر دوست دارید؟",
            likeToTravel: "دوست دارید چگونه سفر کنید؟",
            alone: "دوست دارید این کار را به تنهایی انجام دهید یا با دیگران؟",
            howOften: "چند وقت یک بار دوست دارید به اینجا سفر کنید؟",
            anyoneElse: "آیا شخص دیگری هست که بخواهید با او سفر کنید؟",
            anyPictures: "آیا عکس جالبی از {{location}} دارید که بخواهید به اشتراک بگذارید؟",
            deleteMessage: "از حذف این مکان مطمئن هستید؟",
            addTravelPlan: "مکان دیگری را که دوست دارید به آن سفر کنید اضافه کنید",
            addTravelContributor: "مکان دیگری را که فکر می​کنید آنها دوست دارند به آن سفر کنند اضافه کنید",
            attachedPeople: "دوست دارید با چه کسی سفر کنید؟",
            boat: "قایق",
            bus: "اتوبوس",
            car: "خودرو",
            plane: "هواپیما",
            saveText: "چه ماجراجویی جالبی!  می​خواهید مورد دیگری را اضافه کنید؟",
            deleteConfirm: "از حذف این فعالیت مطمئن هستید؟",
            deleteSuccess: "فعالیت شما با موفقیت حذف شده است",
            contributorQuestions: {
              subHeader: "مکان مورد علاقه آنها برای سفر یا جایی را که واقعاً مشتاقند به آن بروند به اشتراک بگذارید",
              frequencyCard: "آنها انجام دادن این کار را دوست دارند:",
              likeMostCard: "چیزی که آنها در مورد {{place}} بیشتر دوست دارند:",
              pageSubHeader: "در مورد همه جاهایی که آنها دوست دارند به آنها سفر کنند به ما بگویید",
              travelLocally: "آنها سفر داخل ایالات متحده را دوست دارند یا سفر بین​المللی؟",
              travelWhere: "آنها دوست دارند به کجا سفر کنند؟",
              likeMost: "آنها چه چیزی را در سفر به {{location}} بیشتر دوست دارند؟",
              likeToTravel: "چگونه می​خواهند سفر کنند؟",
              alone: "دوست دارند این کار را به تنهایی انجام دهند یا با دیگران؟",
              howOften: "چند وقت یک بار دوست دارند به اینجا سفر کنند؟",
              anyoneElse: "آیا شخص دیگری هست که بخواهند با او سفر کنند؟",
              anyPictures: "آیا آنها عکس جالبی از {{location}} دارند که بخواهید به اشتراک بگذارید؟",
              attachedPeople: "آنها دوست دارند با چه کسی سفر کنند؟",
            },
            summary: {
              place: "دوست دارم به این مکان سفر کنم:",
              travelWithSolo: "دوست دارم به اینجا سفر کنم",
              byMyself: "خودم",
              travelWith: "دوست دارم به اینجا با این شخص سفر کنم:",
              frequency: "به آنجا می​روم",
              frequencyAnswer: "{{frequency}} و من با {{transportation}} سفر می​کنم",
              favoriteThing: "علاقه​مندی من در سفر به {{place}} عبارت است از:",
              buttonText: "رفتن به سفر",
            },
          },
          historyMap: {
            sectionHeader: "نقشه پیشینه من",
            subHeader: "در مورد نقاط عطف مهم، سالگردها یا رویدادهای مهم زندگی خود به ما بگویید",
            addMilestone: "افزودن نقطه عطف",
            editMilestone: "ویرایش نقطه عطف",
            sectionName: "نقشه پیشینه",
            category: "دسته",
            familyLife: "زندگی خانوادگی",
            school: "مدرسه",
            activities: "فعالیت​ها",
            friends: "دوستان",
            housing: "مسکن",
            work: "کار",
            relationships: "روابط",
            chooseDate: "تاریخی را انتخاب کنید",
            milestone: "نقطه عطف",
            details: "جزئیات",
            back: "بازگشت به «آشنایی با شما»",
            saveMessage: "دانستن همه این موارد درباره شما فوق​العاده است، ممنون که به اشتراک گذاشتید!",
          },
          contributor: {
            addToRelationshipMap: "افزودن به نقشه رابطه من",
            thisPersonIs: "این شخص ... است",
            howClose: "چقدر این شخص به شما نزدیک است؟",
            close: "بستن",
            notClose: "نزدیک نیست",
            setPermissions: "تنظیم مجوزهای مشارکت​کننده",
            hasBeenEmailed: "دعوت​نامه​ای برای مشارکت​کننده شما ارسال شده است",
          },
          happeningPage: {
            pageTitle: "اتفاقات زندگی من",
            living: "زندگی در خانه من",
            work: "کار",
            school: "مدرسه",
            health: "سلامت و ایمنی",
            happeningVideo: "ویدیوی «اتفاقات زندگی من»",
          },
          happeningHome: {
            pageTitle: "زندگی در خانه من",
            subTitle: "بیایید در مورد محل زندگی شما بیشتر بدانیم!",
            singleStory: "خانه یک​طبقه",
            multiLevel: "خانه چندطبقه",
            apartment: "آپارتمان",
            facility: "مرکز",
            homeless: "بی​خانمان",
            groupHome: "خانه گروهی",
            car: "خودرو",
            rvCamper: "ماشین کاروان/کمپ",
            currentlyLive: "در حال حاضر، کجا زندگی می​کنید؟",
            currentlyLiveWith: "در حال حاضر، با چه کسی زندگی می​کنید؟",
            parents: "والدین",
            guardians: "سرپرست(ها)",
            siblings: "خواهر یا برادر",
            friends: "دوست (دوستان)",
            roommates: "هم​اتاقی(ها)",
            spouse: "همسر",
            children: "کودک/کودکان",
            alone: "تنها زندگی می​کنم",
            dogs: "سگ(ها)",
            cats: "گربه(ها)",
            other: "سایر، لطفاً توضیح دهید",
            explain: "لطفاً توضیح دهید",
            tableTitle: "در مورد جایی که زندگی می​کنید، چه چیزی را دوست دارید و چه چیزی را دوست ندارید؟",
            amenityHeader: "امکانات",
            selectHeader: "لطفاً پسندیدن یا نپسندیدن را انتخاب کنید",
            commentHeader: "نظرات",
            commentsLikes: "نظرات درباره پسندهای من",
            commentsDislikes: "نظرات درباره نپسندیدن​های من",
            amenities: {
              publicTransportation: "حمل​ونقل عمومی",
              accessibility: "دسترس​پذیری",
              neighborhood: "محله",
              friendsFamily: "نزدیک به دوستان و خانواده",
              shopping: "نزدیک به فروشگاه",
              furniture: "اثاثیه",
              homeType: "نوع خانه",
              squareFootage: "فوت مربع",
              housemates: "هم​خانه",
              livingAlone: "زندگی به​تنهایی",
              neighbors: "همسایه​ها",
              management: "مدیریت ساختمان",
              support: "کارکنان پشتیبانی",
              tellUsWhy: "لطفاً علت را به ما بگویید",
            },
            summary: {
              currentlyLive: "جایی که زندگی می​کنم",
              currentlyLiveWith: "شخصی که با او زندگی می​کنم",
            },
          },
          happeningSchool: {
            header: "مدرسه",
            subHeader: "بی​صبرانه منتظر شنیدن موارد بیشتر در مورد مدرسه هستیم، در مورد تجربه خود به ما بگویید",
            enrolledInSchool: "آیا در حال حاضر به مدرسه می​روید؟",
            schoolNameOOS: "به کدام مدرسه می​رفتید؟",
            gradeLevelOOS: "در چه مقطعی آن را به پایان رساندید؟",
            gradYearOOS: "چه زمانی فارغ​التحصیل شدید؟",
            schoolLikeOOS: "چه چیزی را در مدرسه بیشتر دوست داشتید؟",
            schoolDislikeOOS: "چه چیزی را در مدرسه دوست نداشتید؟",
            volunteerOOS: "هنگامی که در مدرسه بودید، در جایی فعالیت داوطلبانه داشتید؟",
            volunteerWhereOOS: "در کجا فعالیت داوطلبانه داشتید؟",
            volunteerDislikeOOS: "چه چیزی را دوست نداشتید؟",
            volunteerLikeOOS: "چه چیزی را در مورد فعالیت داوطلبانه دوست داشتید؟",
            graduationGoalOOS: "چه بود؟",
            gradYearOtherOOS: "",
            goalYesOOS: "آیا کاری بود که دوست داشتید بعد از فارغ​التحصیلی انجام دهید؟",
            schoolName: "به کدام مدرسه می​روید؟",
            gradeLevel: "در چه مقطعی تحصیل می​کنید؟",
            gradYear: "چه زمانی دوران مدرسه​تان به پایان می​رسد؟",
            schoolLike: "چه چیزی را در مدرسه بیشتر دوست دارید؟",
            schoolDislike: "چه چیزی را در مدرسه دوست ندارید؟",
            volunteer: "در جایی فعالیت داوطلبانه دارید؟",
            volunteerWhere: "در کجا فعالیت داوطلبانه دارید؟",
            volunteerDislike: "چه چیزی را نمی​پسندید؟",
            volunteerLike: "چه چیزی را در مورد فعالیت داوطلبانه دوست دارید؟",
            graduationGoal: "چیست؟",
            gradYearOther: "",
            goalYes: "آیا کاری هست که دوست دارید بعد از فارغ​التحصیلی انجام دهید؟",
            summary: {
              schoolName: "نام مدرسه​ای که به آن می​روم:",
              schoolNameOOS: "نام مدرسه​ای که به آن می​رفتم:",
              gradeLevel: "در حال تحصیل در این مقطع هستم:",
              gradeLevelOOS: "تحصیل را در این مقطع به پایان رسانده​ام:",
              gradYear: "تحصیل من در مدرسه در این زمان به پایان می​رسد:",
              gradYearOOS: "تحصیل من در مدرسه در این زمان به پایان رسید:",
              schoolLike: "آنچه در مدرسه دوست دارم",
              schoolLikeOOS: "آنچه در مدرسه دوست داشتم",
              schoolDislike: "آنچه در مدرسه دوست ندارم",
              schoolDislikeOOS: "آنچه در مدرسه دوست نداشتم",
              volunteerWhere: "در مدرسه در این زمینه فعالیت داوطلبانه دارم:",
              volunteerWhereOOS: "در مدرسه در این زمینه فعالیت داوطلبانه داشتم:",
              volunteerLike: "آنچه در مورد فعالیت داوطلبانه دوست دارم",
              volunteerLikeOOS: "آنچه در مورد فعالیت داوطلبانه دوست داشتم",
              volunteerDislike: "آنچه در مورد فعالیت داوطلبانه دوست ندارم",
              volunteerDislikeOOS: "آنچه در مورد فعالیت داوطلبانه دوست نداشتم",
              graduationGoal: "وقتی فارغ​التحصیل شدم، می​خواهم",
              graduationGoalOOS: "وقتی فارغ​التحصیل شدم، می​خواستم",
            },
          },
          happeningWork: {
            sectionName: "تجربه کاری",
            subHeader: "در مورد تجربه کاری خود بیشتر به ما بگویید، مشتاق شنیدنش هستیم!",
            pastExperience: "لطفاً تجربه کاری گذشته خود را به اشتراک بگذارید",
            pastExperienceLike: "در مورد تجربه کاری گذشته خود چه چیزی را دوست داشتید؟",
            pastExperienceDislike: "در مورد تجربه کاری گذشته خود چه چیزی را دوست نداشتید؟",
            hasCurrentEmployment: "هم اکنون مشغول به کار هستید؟",
            currentEmployment: "در حال حاضر، کجا کار می​کنید؟",
            currentDuration: "چه مدت در آنجا کار کرده​اید؟",
            currentLike: "در مورد کار فعلی خود چه چیزی را دوست دارید؟",
            currentDislike: "در مورد کار فعلی خود چه چیزی را دوست ندارید؟",
            summary: {
              pastExperience: "جایی که در گذشته در آن کار کرده​ام",
              pastExperienceLike: "آنچه در مورد محل کار گذشته​ام دوست داشتم",
              pastExperienceDislike: "آنچه در مورد محل کار گذشته​ام دوست نداشتم",
              currentEmployment: "محل کار فعلی من:",
              currentDuration: "مدت کار من در اینجا:",
              currentLike: "آنچه در مورد محل کار فعلی​ام دوست دارم",
              currentDislike: "آنچه در مورد محل کار فعلی​ام دوست ندارم",
            },
          },
          happeningHealth: {
            header: "سلامت و ایمنی",
            subHeader: "بیایید کمی بیشتر با شما آشنا شویم، لطفاً آنچه را که با مطرح کردنش راحت هستید به اشتراک بگذارید",
            contributorAdditionsToApprove:
              "مشارکت​کنندگان شما DME (تجهیزات پزشکی بادوام) و/یا حمایت​ها را به برنامه شما اضافه کرده​اند. می​خواهید آنها را تأیید کنید؟",
            medInfo: "آیا موضوعی مرتبط با سلامتی وجود دارد که بر زندگی شما تأثیر بگذارد؟",
            allergies: "لطفاً آلرژی​هایی را که مایلید ما در مورد آنها بدانیم ذکر کنید",
            dmeSectionTitle: "از چه نوع حمایت و «تجهیزات پزشکی بادوام» استفاده می​کنید؟",
            dmeContributorTitle: "آنها از چه نوع حمایت و «تجهیزات پزشکی بادوام» استفاده می​کنند؟",
            sectionSubTitle: "برای افزودن آن به فهرست خود، دکمه را انتخاب کنید، برای حذف آن، روی 'x' در فهرست خود کلیک کنید",
            supportsSectionTitle: "چگونه دیگران می​توانند از شما حمایت کنند تا احساس سلامتی کنید و سالم بمانید؟",
            supportContributorTitle: "چگونه دیگران می​توانند از آنها حمایت کنند تا احساس سلامتی کنند و سالم بمانند؟",
            dmePostItTitle: "چه حمایت​های خوبی به نظر می​رسد",
            supportPostItTitle: "چگونه دیگران می​توانند از من حمایت کنند",
            dmeOptions: {
              bloodSugarMonitors: "پایشگرهای قند خون",
              bloodSugarTestStrips: "نوارهای آزمایش قند خون",
              canes: "عصا",
              commodeChairs: "صندلی لگن​دار",
              continuousPassiveDevices: "دستگاه​های حرکت غیرفعال پیوسته",
              continuousPassiveAirwayPressureDevices: "دستگاه​های فشار جریان هوای همواره مثبت (CPAP)",
              crutches: "عصای زیر بغل",
              hospitalBeds: "تخت​های بیمارستانی",
              infusionPumps: "لوازم و پمپ​های تزریق",
              lancetDevices: "لنست​ها و دستگاه​های لنست",
              nebulizerMedications: "نبولایزرها و داروهای نبولایزر",
              oxygenEquipment: "تجهیزات و لوازم جانبی اکسیژن",
              patientLifts: "بالابر بیمار",
              supportSurfaces: "سطوح پشتیبان کاهنده فشار",
              suctionPumps: "پمپ​های مکش",
              tractionEquipment: "تجهیزات کششی",
              walkers: "واکر",
              rollator: "رولیتر",
              wheelchairMotorized: "صندلی چرخ​دار - موتوری",
              wheelchairManual: "صندلی چرخ​دار - دستی",
              wheelchairStanding: "صندلی چرخدار - پشتی متحرک/ایستاده",
              scooterMotorized: "اسکوتر - موتوری",
              scooterManual: "اسکوتر - دستی",
              assistiveTech: "فناوری کمکی",
              translator: "مترجم",
              brailleKeyboard: "صفحه​کلید بریل",
              hearingAid: "سمعک",
            },
            supportOptions: {
              cookMeals: "پختن غذاهای من",
              prepareMeals: "کمک به من در آماده کردن غذا",
              prepareMedication: "کمک به من در آماده کردن دارو",
              medicationSchedule: "کمک به من در ایجاد برنامه زمانی مصرف دارو",
              doctorTransportation: "کمک به من در رفت​وآمد به مطب پزشک",
              createBudget: "کمک به من در ایجاد بودجه",
              beActive: "انگیزه دادن به من برای فعال بودن",
            },
            summary: {
              medInfo: "شرایط سلامتی که مایلم دیگران درباره آنها بدانند عبارت است از:",
              allergies: "آلرژی​هایی که مایلم دیگران درباره آنها بدانند عبارت است از:",
              dmeSectionTitle: "تجهیزات پزشکی بادوام که من استفاده می​کنم شامل این موارد است:",
              supportsSectionTitle: "افراد زندگی من می​توانند به روش​های زیر از من حمایت کنند",
            },
          },
          happeningGeneral: {
            sectionSave:
              "Thank {{userFirstName}} از شما بابت ارائه این اطلاعات تشکر می​کنیم، می​توانید به بخش دیگری از «اتفاقات زندگی شما» بروید!",
          },
          planSummary: {
            sections: {
              importantToMe: "آنچه برای من مهم است",
              importantForMe: "آنچه برایم مهم است",
              goals: "برنامه​ریزی هدف",
            },
            cardTitles: {
              likeAdmire: "آنچه مردم درباره من می​پسندند و تحسین می​کنند",
              people: "افراد مهم زندگی من",
              milestones: "نقاط عطف مهم زندگی من",
              favoriteThings: "چند مورد از علاقه​مندی​های من",
              thingsIDo: "کاری که دوست دارم برای سرگرمی انجام دهم",
              travelPlan: "سفر",
              dailyRoutines: "روال​های روزانه من",
              weeklyRoutines: "روال​های هفتگی من",
              gtkyHome: "زندگی در خانه من",
              gtkyWork: "کار",
              gtkySchool: "مدرسه",
              gtkyHealth: "سلامت و ایمنی",
              homeGoals: "اهداف مسکن",
              workGoals: "اهداف کاری",
              healthGoals: "اهداف سلامت",
              funGoals: "اهداف سرگرمی",
              relationshipGoals: "اهداف مربوط به رابطه",
              travelGoals: "اهداف سفر",
            },
          },
          contributorGeneric: {
            thankYou: "از مشارکت شما سپاسگزاریم!  اضافات شما برای تأیید به صاحب برنامه ارسال شده است.",
            entries: "مدخل​های مشارکت​کننده",
          },
          contributorComments: {
            addComment: "افزودن نظر",
            allComments: "همه نظرات",
            commentPrompt:
              "آیا براساس آنچه می​بینید موضوعی هست که بخواهید درباره آن نظر دهید؟ نظر یا یادداشت جالبی برای آنها بگذارید!",
            yourComment: "نظر شما",
            approved: "نظر تأییدشده",
            pendingApproval: "از مشارکت شما سپاسگزاریم! نظر شما برای تأیید به صاحب برنامه ارسال شده است",
            pendingNoThankYou: "نظر شما برای تأیید به صاحب برنامه ارسال شده است",
            comments: "نظرات",
          },
          durationDropdown: {
            notSelected: "انتخاب نشده است",
            zeroMonths: "‎0 تا 6 ماه",
            sixMonths: "‎6 تا 12 ماه",
            oneYear: "1 تا 2 سال",
            twoYears: "بیش از 2 سال",
          },
          generic: {
            add: "افزودن",
            addComment: "افزودن نظر",
            addNew: "افزودن مورد جدید",
            addNewEntry: "افزودن ورودی جدید",
            addYourOwn: "افزودن مورد سفارشی",
            addressPrimary: "نشانی 1",
            addressSecondary: "سوئیت، آپارتمان، شماره واحد",
            alone: "تنها",
            approve: "تأیید",
            back: "بازگشت",
            backToDashboard: "بازگشت به داشبورد برنامه",
            cancel: "لغو",
            city: "شهر",
            clear: "پاک کردن",
            country: "کشور",
            comments: "نظرات مشارکت​کننده",
            dailyWithCapital: "روزانه",
            date: "تاریخ",
            delete: "حذف",
            deleteGoalConfirm: "از حذف این هدف مطمئن هستید؟",
            deleteGoalSuccess: "هدف شما با موفقیت حذف شده است",
            details: "جزئیات",
            disapprove: "تأیید نکردن",
            dislike: "نپسندیدن",
            edit: "ویرایش",
            editGoal: "ویرایش هدف",
            email: "ایمیل",
            error: 'خطا',
            emailWithCapitial: "ایمیل",
            faceTime: "FaceTime",
            firstName: "نام",
            generalComment: "برای افزودن نظر کلی، کلیک کنید",
            goToItem: "رفتن به مورد",
            goToGoal: "رفتن به هدف",
            goToSection: "رفتن به {{section}}",
            inPerson: "حضوری",
            instructionalVideo: "ویدیوی آموزشی",
            language: "اولویت زبانی",
            lastName: "نام خانوادگی",
            like: "پسندیدن",
            middleName: "نام میانی",
            name: "نام",
            no: "خیر",
            noNotifications: "بدون اعلان",
            other: "سایر",
            pleaseExplain: "لطفاً توضیح دهید",
            phoneNumber: "شماره تلفن",
            review: "بازبینی",
            save: "ذخیره",
            saveAndSendUpdates: "ذخیره و ارسال به​روزرسانی​ها",
            saveAndSendInvite: "ذخیره و ارسال دعوت",
            saveAnother: "ذخیره و افزودن دیگری",
            saveNoUpdates: "به​روزرسانی​ها ارسال نشود",
            search: "جستجو",
            selectAGoal: "انتخاب هدف",
            state: "ایالت",
            submit: "ارسال",
            success: "موفقیت",
            talking: "صحبت کردن",
            title: "عنوان",
            uploadImage: "آپلود عکس",
            viewComment: "مشاهده نظر",
            weeklyWithCapitial: "هفتگی",
            withOthers: "با دیگران",
            yes: "بله",
            zip: "کد پستی",
            include: "شامل",
            notInclude: "فاقد",
            approveAll: "تأیید همه موارد",
            denyAll: "رد کردن همه موارد",
            removeFromPlan: "حذف از برنامه",
            said: "گفته​شده",
            errors: {
              errorPageTitle: 'خطایی رخ داده است!',
              errorPageSubTitle: 'با عرض پوزش در مورد آن لطفا بعدا بیا.،',
              error404: '404 - صفحه یافت نشد ،',
              saveError: 'با عرض پوزش ، خطایی برای ذخیره ورود شما وجود داشت. لطفا بعداً دوباره امتحان کنید ،',
              deleteError: 'با عرض پوزش ، خطایی در حذف ورود شما وجود داشت. لطفا بعداً دوباره امتحان کنید ،',
              createPlanError: 'با عرض پوزش ، ایجاد خطایی در ایجاد برنامه شما رخ داد. لطفا بعداً دوباره امتحان کنید.',              
            },
          },
        },
      },
    },
  });
