import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import Loading from "./Components/StyledComponents/Loading";
import './i18n/i18n';
const Root = lazy(() => import("./Root"));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <Root />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
